import styled, { css } from "styled-components";

import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";

import { useEffect, useState } from "react";
import parse from "html-react-parser";
import { getLanguage } from "../../services/common";
import { useSelector } from "react-redux";
import { selectSystem } from "../../slices/systemSlice";

import Step_1 from "../../images/apikeyGuide/Binance/Step_1.jpg";
import Step_2_1 from "../../images/apikeyGuide/Binance/Step_2-1.jpg";
import Step_2_2 from "../../images/apikeyGuide/Binance/Step_2-2.jpg";
import Step_3 from "../../images/apikeyGuide/Binance/Step_3.jpg";
import Step_4 from "../../images/apikeyGuide/Binance/Step_4.jpg";
import Step_5_1 from "../../images/apikeyGuide/Binance/Step_5-1.jpg";
import Step_5_2 from "../../images/apikeyGuide/Binance/Step_5-2.jpg";
import Step_6_1 from "../../images/apikeyGuide/Binance/Step_6-1.jpg";
import Step_6_2 from "../../images/apikeyGuide/Binance/Step_6-2.jpg";
import Step_7_1 from "../../images/apikeyGuide/Binance/Step_7-1.jpg";
import Step_7_2 from "../../images/apikeyGuide/Binance/Step_7-2.jpg";
import Step_7_3 from "../../images/apikeyGuide/Binance/Step_7-3.jpg";

import { useOutletContext } from "react-router-dom";

const Wrapper = styled.div`
  height: 100%;
  padding-bottom: 100px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 20px;
`;

const Title = styled.div`
  font-size: min(max(1.25vw, 22px), 24px);
  color: #fcfcfc;
  padding-right: 10px;
  text-transform: capitalize;

  ${RWD_MD(css`
    font-size: min(max(1.145vw, 20px), 22px);
  `)};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const InstructionsWrapper = styled.div`
  /* text-align: left; */
  padding-bottom: 20px;
  color: #d3d3d3;
`;

const StepWrapper = styled.div`
  width: 100%;
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #22254a;
  margin-bottom: 20px;
`;

const StepBox = styled.div`
  padding: 30px 40px;
  width: 100%;
`;

const StepNumber = styled.div`
  font-size: min(max(2.5vw, 40px), 48px);
  color: #0aecd1;
  padding-right: 10px;
`;

const StepNumberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const StepNumberContainer = styled.div`
  font-size: 13px;
  position: absolute;
  left: 0;
  top: -10px;
  color: rgba(10, 236, 209, 0.5);
`;

const StepImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
`;

const StepImg = styled.div`
  display: flex;
  background-color: #fcfcfc;
  padding: 10px;

  img {
    width: 100%;
  }
`;

const StepImgDescription = styled.div`
  font-size: 15px;
  padding-top: 20px;
  color: #54587b;
`;

const StepTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: baseline;
  border-bottom: 2px solid #3a3d5a;
  font-family: Montserrat;
  color: #fcfcfc;
`;

const StepNote = styled.div`
  padding: 10px 0;
  color: #d3d3d3;
`;

const StepRemark = styled.div``;

const ApiKeyBinance = () => {
  // 關閉Onboarding
  const { onboardingStatus } = useOutletContext();
  onboardingStatus[0]();

  const lang = useSelector(selectSystem).language;
  const [language, setLanguage] = useState("");

  useEffect(() => {
    console.log(lang);
    setLanguage(lang);
  }, [lang]);

  const TitleData = [
    {
      id: 1,
      en: "How to add your Binance API key to your AVA account",
      ch: "如何將您的Binance API密鑰添加到您的AVA帳戶",
    },
  ];

  const instructionsData = [
    {
      id: 1,
      en: "You can now launch your strategies from the AVA platform using your Binance exchange. But before you start, you will have to link your Binance API key to our platform, you will find below the steps to follow, and in a few minutes you will be ready to launch your first strategies.",
      ch: "您現在可以使用您的Binance交易所於AVA平台啟動您的策略。但在您開始之前，您需要將您的Binance API密鑰連接到我們的平台，下面是綁定的步驟，幾分鐘內您就可以啟動您的第一個策略。",
    },
    {
      id: 2,
      en: "So you want to link your Binance API to your AVA account? Nothing could be easier, just create an API key. All the steps are explained in this tutorial.",
      ch: "所以，您想將您的Binance API連接到您的AVA帳戶嗎？沒有什麼比創建一個API密鑰更簡單的了，所有步驟都將在這個教程中與您解釋。",
    },
  ];

  const stepData = [
    {
      id: 1,
      title: [
        {
          en: 'Go to Binance (Exchange) (1) under profile options, find "API" (2)',
          ch: '轉到Binance（交易所）（1）在個人資料選項下，找到 "API"（2）。',
        },
      ],

      note: [
        {
          en: "Note : To add an API key, your 2FA authentication must be enabled",
          ch: "注意：要添加API密鑰，您的2FA身份驗證必須啟用",
        },
      ],
      imgData: [
        {
          img: Step_1,
          imgDescription: [
            {
              en: "Go to binance.com and access the API section of your profile",
              ch: "前往binance.com並訪問您個人資料的API部分",
            },
          ],
        },
      ],

      remark: "",
    },
    {
      id: 2,
      title: [
        {
          en: 'Then click on "Create New Key" (3) and choose "System -generated API Keys" (4)',
          ch: '然後點擊 "創建新API"（3）並選擇 "系統生成"（4）',
        },
      ],
      note: "",
      imgData: [
        {
          img: Step_2_1,
          imgDescription: [
            {
              en: 'Click on "Create API"',
              ch: '點擊 "創建新API"',
            },
          ],
        },
        {
          img: Step_2_2,
          imgDescription: [
            {
              en: 'Click on "System -generated API Keys"',
              ch: '點擊 "系統生成"',
            },
          ],
        },
      ],
      remark: "",
    },
    {
      id: 3,
      title: [
        {
          en: 'Enter API Key label "AVA" (5)',
          ch: '輸入API密鑰標籤 "AVA"（5）',
        },
      ],
      note: "",
      imgData: [
        {
          img: Step_3,
          imgDescription: "",
        },
      ],
      remark: "",
    },
    {
      id: 4,
      title: [
        {
          en: "Validate the security checks (Code by email and 2FA) (6)",
          ch: "驗證安全檢查（通過電子郵件的代碼和2FA）（6）",
        },
      ],
      note: "",
      imgData: [
        {
          img: Step_4,
          imgDescription: [
            {
              en: "Copy the code received by email and your 2FA",
              ch: "複製貼上通過電子郵件收到的代碼和您的2FA",
            },
          ],
        },
      ],
      remark: "",
    },
    {
      id: 5,
      title: [
        {
          en: 'Click on "Edit restrictions" (7) , fill in the following rights(8)',
          ch: '點擊 "編輯權限"（7），填寫以下權限（8）',
        },
      ],
      note: "",
      imgData: [
        {
          img: Step_5_1,
          imgDescription: [
            {
              en: 'Click on "Edit restrictions"',
              ch: '點擊 "編輯權限"',
            },
          ],
        },
        {
          img: Step_5_2,
          imgDescription: [
            {
              en: "Check the above rights carefully",
              ch: "仔細檢查上述權限",
            },
          ],
        },
      ],
      remark: "",
    },
    {
      id: 6,
      title: [
        {
          en: "Copy your API Key and API Secret to a text file (9), click on “Save”,then validate the security checks (Code by email and 2FA)",
          ch: '將您的API密鑰和API秘密複製到一個文本文件中（9），點擊 "保存"，然後驗證安全檢查（通過電子郵件的代碼和2FA）',
        },
      ],
      note: "",
      imgData: [
        {
          img: Step_6_1,
          imgDescription: [
            {
              en: 'Once your keys are copied to a safe place, you can click on "Save"',
              ch: '一旦您的密鑰被複製到一個安全的地方，您可以點擊 "保存"',
            },
          ],
        },
        {
          img: Step_6_2,
          imgDescription: [
            {
              en: "Copy the code received by email and your 2FA again",
              ch: "再次複製通過電子郵件收到的代碼和您的2FA進行驗證",
            },
          ],
        },
      ],
      remark: "",
    },
    {
      id: 7,
      title: [
        {
          en: 'Now go to your AVA account in the "Setting" section',
          ch: '現在轉到 AVA 帳戶的 "設置" 部分',
        },
      ],
      note: [
        {
          en: 'In the section "API Management" select "Binance" and indicate your "API Key" and "API Secret" that you just copied in a text file. And click on "OK"',
          ch: '在 "API管理" 部分選擇 "Binance" 並將您剛剛在文本文件中複製的 "API密鑰" 和 "API密鑰密碼" 貼至此處。然後點擊 "確定"',
        },
      ],
      imgData: [
        {
          img: Step_7_1,
          imgDescription: "",
        },
        {
          img: Step_7_2,
          imgDescription: "",
        },
      ],
      remark: "",
    },
    {
      id: 8,
      title: [
        {
          en: "If all went well, you will see your Binance key added",
          ch: "如果一切順利，您將看到您的Binance密鑰已添加",
        },
      ],
      note: "",
      imgData: [
        {
          img: Step_7_3,
          imgDescription: [
            {
              en: "The Binance API key has been added. Congratulations!",
              ch: "Binance密鑰已添加成功。恭喜！",
            },
          ],
        },
      ],
      remark: "",
    },
  ];

  return (
    <Wrapper>
      <Container>
        <TitleWrapper>
          <Title>{TitleData[0]?.[language?.toLocaleLowerCase()]}</Title>
        </TitleWrapper>

        <ContentWrapper>
          {instructionsData.map((data) => (
            <InstructionsWrapper>
              <p>{data?.[language?.toLocaleLowerCase()]}</p>
            </InstructionsWrapper>
          ))}

          <StepWrapper>
            {stepData.map((data) => (
              <StepContainer>
                <StepBox>
                  <StepTitle>
                    <StepNumberWrapper>
                      <StepNumberContainer>STEP</StepNumberContainer>
                      <StepNumber>
                        {String(data.id).padStart(2, "0")}
                      </StepNumber>
                    </StepNumberWrapper>

                    <p>{data.title[0]?.[language?.toLocaleLowerCase()]}</p>
                  </StepTitle>
                  <StepNote>
                    {data.note?.[0]?.[language?.toLocaleLowerCase()]}
                  </StepNote>
                  {data.imgData.map((img) => (
                    <StepImgContainer>
                      <StepImg>
                        <img src={img.img} />
                      </StepImg>
                      <StepImgDescription>
                        {
                          img.imgDescription?.[0]?.[
                            language?.toLocaleLowerCase()
                          ]
                        }
                      </StepImgDescription>
                    </StepImgContainer>
                  ))}
                  <StepRemark>
                    {data.remark?.[0]?.[language?.toLocaleLowerCase()]}
                  </StepRemark>
                </StepBox>
              </StepContainer>
            ))}
          </StepWrapper>
        </ContentWrapper>
      </Container>
    </Wrapper>
  );
};

export default ApiKeyBinance;
