import styled, { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../../utils/rwd";

import { useGetPersonalDataQuery } from "../../../../services/datacenterApi";
import Loading from "../../../../components/Loading/Loading";
import { animated, useSpring } from "react-spring";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";

import ChartDataLabels from "chartjs-plugin-datalabels";

import { Doughnut, getElementAtEvent } from "react-chartjs-2";
import { useState, useEffect, useRef, startTransition } from "react";

import * as d3 from "d3";

import Lang from "../../../../global/Lang";
import Icons from "../../../../global/icons";

import BCH from "../../../../images/crypto/bch.png";
import BNB from "../../../../images/crypto/bnb.png";
import BTC from "../../../../images/crypto/btc.png";
import CAKE from "../../../../images/crypto/cake.png";
import ETH from "../../../../images/crypto/eth.png";
import IOTA from "../../../../images/crypto/iota.png";
import NEO from "../../../../images/crypto/neo.png";
import USDT from "../../../../images/crypto/usdt.png";
import EOS from "../../../../images/crypto/eos.png";
import ETC from "../../../../images/crypto/etc.png";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const Wrapper = styled.div`
  color: #fcfcfc;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;

  ${RWD_SM(css`
    flex-direction: column;
  `)};
`;

const ChartWrapper = styled.div`
  padding: 20px 0;
  background-color: #22254a;

  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  width: 40%;
  margin-right: 5px;

  ${RWD_SM(css`
    /* width: 100%;

    margin-right: 0;
    margin-bottom: 5px; */
    display: none;
  `)};
`;

const ChartContainer = styled.div`
  /* border: 1px solid red; */

  height: clamp(250px, 20vw, 360px);
  width: clamp(250px, 20vw, 360px);
  border-radius: 50%;
  position: relative;
  background: #151944;

  display: flex;
  align-items: center;
  justify-content: center;

  ${RWD_SM(css`
    width: 200px;
    height: 200px;
  `)};
`;

const PercentageNum = styled.div`
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ChartInnerWrapper = styled.div`
  height: clamp(150px, 12vw, 260px);
  width: clamp(150px, 12vw, 260px);
  border-radius: 50%;
  background: #22254a;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  color: #fcfcfc;
  font-family: Montserrat;
  font-weight: bold;
  flex-direction: column;

  ${RWD_SM(css`
    width: 150px;
    height: 150px;
  `)};
`;

const ChartInnerUnit = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

const TagWrapper = styled.div`
  width: 85%;
  padding-top: 20px;

  ${RWD_SM(css`
    width: 95%;
  `)};
`;

const TagContainer = styled.div`
  display: grid;
  grid-gap: 20px;
  justify-content: center;
  align-items: center;

  grid-template-columns: repeat(auto-fit, minmax(150px, auto));

  ${RWD_SM(css`
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(100px, auto));
  `)};
`;

const Tag = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* border: 1px solid pink; */

  /* border: 1px solid red; */
`;

const TagText = styled.div`
  padding-left: 10px;
  margin: 0 auto;
`;

const TagCircle = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
`;

const ContentWrapper = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;

  ${RWD_SM(css`
    width: 100%;
  `)};
`;

const TotalAmountWrapper = styled.div`
  align-items: center;
  margin-bottom: 5px;
  background-color: #22254a;
`;

const TotalAmountContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px;
`;

const TotalAmountTitle = styled.div`
  color: #0aecd1;
  font-size: min(max(0.9375vw, 16px), 18px);
  padding-bottom: 12px;
`;

const Total = styled.div`
  color: #fcfcfc;
  font-size: min(max(1.875vw, 34px), 36px);
  font-family: Montserrat;
  font-weight: bold;
  display: flex;
`;

const Dolar = styled.div`
  padding-right: 10px;
`;

const ListWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: #22254a;
  flex: 1;
`;

const ListContainer = styled.div`
  width: 95%;
  padding: 10px 0 20px 0;
`;

const AssetAndBarWrapper = styled.div`
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${RWD_SM(css`
    width: 60%;
  `)};
`;

const PercentageWrapper = styled.div`
  /* width: 70%; */
  flex: 1;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );

  ${RWD_SM(css`
    order: 3;
    width: 100%;
  `)};
`;

const PercentageContainer = styled.div`
  /* height: 100%; */
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0aecd1;
`;

const Percentage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const List = styled.div`
  display: flex;
  border-bottom: 1px solid #54587b;
  padding: 15px 0;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  position: relative;

  ${RWD_SM(css`
    flex-direction: column;
    align-items: flex-start;
  `)};
`;

const ListContent = styled.div`
  width: 25%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  flex-wrap: wrap;

  ${RWD_SM(css`
    width: auto;
    position: absolute;
    right: 0;
  `)};
`;

const ListAmount = styled.div`
  color: #54587b;
  font-size: 14px;
  text-align: end;
`;

const ListAsset = styled.div`
  width: 15%;
  padding: 0 10px;

  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  font-size: 12px;

  ${RWD_SM(css`
    width: auto;
    padding: 0 0 10px 0;
  `)};
`;

const ListAssetImg = styled.div`
  padding-bottom: 5px;
  img {
    width: 30px;
  }

  ${RWD_SM(css`
    align-self: flex-start;
  `)};
`;

const ListQtyWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

const ListQty = styled.div`
  color: #fcfcfc;
  text-align: end;
`;

const ListInnerAsset = styled.div`
  font-size: 13px;
  padding-left: 5px;
  opacity: 0.7;
`;

const NumberWrapper = styled.div`
  width: 100%;
  margin-top: 5px;
  background-color: #22254a;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 12px;
`;

const NumberContainer = styled.div`
  display: flex;
  width: 95%;

  ${RWD_SM(css`
    flex-direction: column;
  `)};
`;

const SubTitle = styled.div`
  font-size: min(max(1.0416vw, 18px), 20px);
  color: #fcfcfc;
  padding: 15px 0 20px 10px;
  width: 95%;

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 16px), 18px);
  `)};
`;

const NumberBox = styled.div`
  width: 33%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  ${RWD_SM(css`
    width: 100%;
    padding: 25px 0;
    border-bottom: 1px solid #54587b;

    :last-child {
      border-bottom: 1px solid transparent;
    }
  `)};
`;

const NumberTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 12px;
`;

const InfoIcon = styled.div`
  display: inline-flex;
  width: 15px;
  cursor: pointer;

  svg {
    width: 15px;
    height: 15px;
  }

  & path {
    stroke: #54587b;
  }

  ${RWD_SM(css`
    display: none;
  `)};
`;

const InfoText = styled.div`
  font-size: 12px;
  color: #54587b;
  width: 85%;
  text-align: center;
  opacity: 0;
`;

const NumberTitle = styled.div`
  color: #0aecd1;
  font-size: min(max(0.9375vw, 16px), 18px);
  padding-right: 5px;
`;

const Number = styled.div`
  color: #fcfcfc;
  font-size: min(max(1.875vw, 34px), 36px);
  font-family: Montserrat;
  font-weight: bold;
  width: 100%;
  padding-bottom: 12px;
  border-right: 1px solid #54587b;
  display: flex;
  align-items: center;
  justify-content: center;

  ${RWD_SM(css`
    border-right: 1px solid transparent;
  `)};
`;

const Mark = styled.div`
  align-self: flex-end;
`;

const TooltipWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 3px;
  cursor: help;

  &:hover span {
    visibility: visible;
  }
`;

const TooltipContainer = styled.div`
  width: 100%;
  height: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 1px;
  display: flex;

  svg {
    width: 20px;
    height: 20px;
  }

  & path {
    stroke: #c0c0c0;
  }
`;

const TooltipText = styled.span`
  visibility: hidden;
  width: auto;
  background-color: #54587b;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: min(max(0.833vw, 14px), 16px);
  white-space: nowrap;
  position: absolute;
  bottom: 110%;
  right: 0;
  z-index: 1;
`;

const Nodata = styled.div`
  font-size: min(max(1.0416vw, 18px), 20px);
  padding: 20px;
  background-color: #22254a;
  display: flex;
  color: #54587b;

  align-items: center;
  justify-content: center;
  flex: 1;
`;

const D3Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NumberMove = ({ n }) => {
  const { number } = useSpring({
    from: { number: 0 },
    number: n,
    delay: 100,
    config: { mass: 1, tension: 170, friction: 26 },
  });
  return <animated.div>{number.to((n) => n.toFixed(2))}</animated.div>;
};

const WidthMove = ({ n, background }) => {
  const { width } = useSpring({
    from: { width: "0%" },
    to: { width: `${n}%` },
    delay: 200,
    config: { mass: 1, tension: 170, friction: 26, duration: 500 },
  });
  return (
    <animated.div
      style={{
        width,
        height: "25px",
        display: "flex",
        justifyContent: "center",
        background,
      }}
    />
  );
};

const MiningData = () => {
  const [overviewList, setOverviewList] = useState([]);
  const [chartData, setChartData] = useState([]);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    if (windowSize[0] <= "1024") {
      if (windowSize[0] <= "430") {
        setInfoIsOpen(
          infoIsShow.map((data) => {
            return {
              ...data,
              open: true,
            };
          })
        );
      }
    } else {
      setInfoIsOpen(
        infoIsShow.map((data) => {
          return {
            ...data,
            open: false,
          };
        })
      );
    }
  }, [windowSize]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const { data, error, isError, isSuccess, isLoading, refetch } =
    useGetPersonalDataQuery();

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setOverviewList(data?.MiningOverviewList);
      setChartData(data?.MiningOverviewList?.MiningAssetList);
    }

    if (isError) {
      console.log(error?.data?.Message);
    }
  }, [data, isError]);

  const backgroundColor = [
    "rgba(255, 99, 132, 0.2)",
    "rgba(255, 159, 64, 0.2)",
    "rgba(153, 102, 255, 0.2)",
    "rgba(54, 162, 235, 0.2)",
    "rgba(201, 203, 207, 0.2)",
    "rgba(255, 205, 86, 0.2)",
    "rgba(75, 192, 192, 0.2)",
    "rgba(0, 85, 255, 0.2)",
    "rgba(85, 0, 255, 0.2)",
  ];

  const hightLightBackgroundColor = [
    "rgba(255, 99, 132, 1)",
    "rgba(255, 159, 64, 1)",
    "rgba(153, 102, 255, 1)",
    "rgba(54, 162, 235, 1)",
    "rgba(201, 203, 207, 1)",
    "rgba(255, 205, 86, 1)",
    "rgba(75, 192, 192, 1)",
    "rgba(0, 85, 255, 1)",
    "rgba(85, 0, 255, 1)",
  ];

  const doughnutData = (data) => {
    const list = chartData.map(
      (data) => (data.UsdtAmount / overviewList.TotalUsdtAmount) * 100
    );

    console.log(list);
    const percentage = list.map((item, index, array) => {
      let sub = array.slice(0, index + 1);
      let sum = sub.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      return sum;
    });

    console.log(percentage);

    const deg = percentage
      .map((data) => (data * 360) / 100)
      .map((item, index) => {
        if (index === percentage.length - 1) {
          return 360;
        } else {
          return item;
        }
      });

    return data.map((item, index) => {
      if (data.length > 1) {
        return `${
          active === index
            ? hightLightBackgroundColor[index]
            : backgroundColor[index]
        } ${deg[index - 1] ?? 0}deg ${deg[index]}deg`;
      } else {
        return `${backgroundColor[index]} 0 ${deg[index]}deg`;
      }
    });
  };

  const chatData = {
    labels: chartData.map((data) => data.Asset),
    datasets: [
      {
        data: chartData.map(
          (data) => (data.UsdtAmount / overviewList.TotalUsdtAmount) * 100
        ),
        backgroundColor: Array.from(
          { length: chartData.length },
          (v, i) => backgroundColor[i]
        ),
        hoverOffset: 1,
        borderWidth: 0,
      },
    ],
  };

  const [active, setActive] = useState(0);

  const chartOptions = {
    responsive: true,
    legend: {
      display: false,
    },

    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        labels: {
          color: "#ccc",
        },
      },
      datalabels: {
        formatter: (value, context) => {
          return `${value.toFixed(2)}%`;
        },
        font: {
          size: 14,
        },
        color: "#fcfcfc",
      },
    },
  };

  const textCenter = {
    id: "textCenter",
    beforeDraw(chart, args, pluginOptions) {
      const { ctx, data } = chart;

      ctx.restore();
      ctx.font = "bold 30px sans-serif";
      ctx.fillStyle = "white";
      ctx.textAlign = "center";

      console.log(active);

      ctx.fillText(
        data.datasets[0].data[active]?.toFixed(2),
        chart.getDatasetMeta(0).data[0]?.x,
        chart.getDatasetMeta(0).data[0]?.y
      );
      ctx.save();
    },
  };

  const cryptologos = [
    { Src: BCH, Target: "BCH" },
    { Src: BNB, Target: "BNB" },
    { Src: BTC, Target: "BTC" },
    { Src: CAKE, Target: "CAKE" },
    { Src: ETH, Target: "ETH" },
    { Src: IOTA, Target: "IOTA" },
    { Src: NEO, Target: "NEO" },
    { Src: USDT, Target: "USDT" },
    { Src: EOS, Target: "EOS" },
    { Src: ETC, Target: "ETC" },
  ];

  const chartRef = useRef();

  const [infoIsShow, setInfoIsOpen] = useState([
    { id: 1, open: false },
    { id: 2, open: false },
    { id: 3, open: false },
  ]);

  const handleInfo = (index) => {
    setInfoIsOpen(
      infoIsShow.map((data) => {
        if (data.id === index) {
          return {
            ...data,
            open: !data.open,
          };
        } else {
          return data;
        }
      })
    );
  };

  const Chart = ({ data, active }) => {
    const screenWidth =
      window.innerWidth * 0.2 < 250 ? 250 : window.innerWidth * 0.2;

    const paint = () => {
      const g = d3
        .select(".basicDonut")
        .append("g")
        .attr("class", "text-group")
        .attr("font-size", 25)
        .attr("fill", "#fcfcfc")
        .attr("font-family", "Montserrat")
        .attr("font-weight", "Bold")
        .append("text")
        .attr("transform", `translate(${screenWidth / 2}, ${screenWidth / 2})`)
        .attr("text-anchor", "middle");

      // // tooltip title setting

      g.transition()
        .duration(1200)
        .tween("number", function () {
          const num =
            data.length > 0 ? data.map((num) => num.UsdtAmount)[active] : 0;
          var i = d3.interpolateNumber(0, num);
          return function (t) {
            this.textContent = i(t).toFixed(2);
          };
        });
    };

    useEffect(() => {
      paint();
    }, [active]);

    useEffect(() => {
      const list =
        data.length > 0
          ? chartData.map(
              (data) => (data.UsdtAmount / overviewList.TotalUsdtAmount) * 100
            )
          : [100];

      // 建立svg
      const svg = d3
        .select(".basicDonut")
        .append("svg")
        .attr("width", screenWidth)
        .attr("height", screenWidth);

      // 用 pie()建立圓餅圖 generator
      const pie = d3.pie().value((d) => {
        if (list.length < 1) {
          return 100;
        } else {
          return d;
        }
      })(list);

      // 建立圓弧
      const arc = d3
        .arc()
        .innerRadius(screenWidth / 3.5)
        .outerRadius(screenWidth / 2);

      // 綁定資料
      const arcs = svg
        .append("g")
        .attr("transform", `translate(${screenWidth / 2}, ${screenWidth / 2})`)
        .selectAll("arc")
        .data(pie)
        .enter()
        .append("g");

      // 加上路徑
      arcs
        .append("path")
        .attr("fill", (list, i) => {
          if (data.length < 1) {
            return "#151944";
          } else {
            return active === i
              ? hightLightBackgroundColor[i]
              : backgroundColor[i];
          }
        })
        .attr("d", arc);

      // 加上內部文字標示
      // arcs
      //   .append("text")
      //   .attr("transform", (d) => {
      //     return "translate(" + arc.centroid(d) + ")";
      //   })
      //   .text(function (d) {
      //     return d.value;
      //   });

      const ava = d3
        .select(".basicDonut")
        .append("g")
        .attr("class", "text-group")
        .attr("font-size", 16)
        .attr("fill", "#fcfcfc")
        .attr("font-family", "Montserrat");

      ava
        .append("text")
        .attr(
          "transform",
          `translate(${screenWidth / 2}, ${screenWidth / 2 + 30} )`
        )
        .text("AVA Point")
        .attr("text-anchor", "middle");

      // tooltip content setting

      // function basicDonut() {

      // }

      // basicDonut();
    }, []);

    return (
      <D3Wrapper>
        <svg
          className="basicDonut"
          style={{ width: screenWidth, height: screenWidth }}
        ></svg>
      </D3Wrapper>
    );
  };

  return (
    <Wrapper>
      {isLoading && <Loading />}

      <Container>
        <Content>
          <ChartWrapper>
            <Chart data={chartData} active={active} />
            {/* <ChartContainer
              style={{
                background: `conic-gradient(${doughnutData(
                  chartData.map(
                    (data) =>
                      (data.UsdtAmount / overviewList.TotalUsdtAmount) * 100
                  )
                )}) `,
              }}
            >
              <ChartInnerWrapper>
                <NumberMove n={chartData[active]?.UsdtAmount} />
                <ChartInnerUnit>AVA Point</ChartInnerUnit>
              </ChartInnerWrapper>
              <Doughnut
                data={chatData}
                options={chartOptions}
                // plugins={[textCenter]}
                ref={chartRef}
                plugins={[ChartDataLabels, textCenter]}
              />
            </ChartContainer> */}
            {chartData.length > 0 && (
              <TagWrapper>
                <TagContainer>
                  {chartData.map((data, index) => (
                    <Tag key={index}>
                      <TagCircle
                        style={{ background: hightLightBackgroundColor[index] }}
                      />
                      <TagText>{data.Asset}</TagText>
                    </Tag>
                  ))}
                </TagContainer>
              </TagWrapper>
            )}
          </ChartWrapper>
          <ContentWrapper>
            <TotalAmountWrapper>
              <TotalAmountContainer>
                <TotalAmountTitle>
                  <Lang datakey="total" defaultText="Total" />
                </TotalAmountTitle>
                <Total>
                  <Dolar>$</Dolar>
                  <NumberMove n={overviewList.TotalUsdtAmount} />
                </Total>
                <Mark>
                  <TooltipWrapper>
                    <TooltipContainer>AVA Point</TooltipContainer>
                    <TooltipText>目前 AVA Point：USDT = 1：1</TooltipText>
                  </TooltipWrapper>
                </Mark>
              </TotalAmountContainer>
            </TotalAmountWrapper>
            {overviewList.MiningAssetList?.length > 0 ? (
              <ListWrapper>
                <ListContainer>
                  {overviewList.MiningAssetList?.map((data, index) => (
                    <List onClick={() => setActive(index)} key={index}>
                      <ListAsset>
                        <ListAssetImg>
                          <img
                            src={
                              cryptologos.filter(
                                (logo) =>
                                  logo.Target === data.Asset ||
                                  data.Asset.includes(logo.Target)
                              )[0]?.Src
                            }
                          />
                        </ListAssetImg>
                        {data.Asset}
                      </ListAsset>
                      <PercentageWrapper>
                        {/* <PercentageContainer
                        style={{
                          width: `${
                            (data.UsdtAmount / overviewList.TotalUsdtAmount) *
                            100
                          }%`,
                        }}
                        >
                        </PercentageContainer> */}
                        <WidthMove
                          n={
                            (data.UsdtAmount / overviewList.TotalUsdtAmount) *
                            100
                          }
                          background={hightLightBackgroundColor[index]}
                        />
                        <Percentage>
                          {(
                            (data.UsdtAmount / overviewList.TotalUsdtAmount) *
                            100
                          ).toFixed(2)}{" "}
                          %
                        </Percentage>
                      </PercentageWrapper>

                      <ListContent>
                        <ListQtyWrapper>
                          <ListQty>{data.Qty?.toFixed(4)}</ListQty>
                          <ListInnerAsset>{data.Asset}</ListInnerAsset>
                        </ListQtyWrapper>

                        <ListAmount>$ {data.UsdtAmount?.toFixed(4)}</ListAmount>
                      </ListContent>
                    </List>
                  ))}
                </ListContainer>
              </ListWrapper>
            ) : (
              <Nodata>
                <Lang datakey="nodata" defaultText="No data available" />
              </Nodata>
            )}
          </ContentWrapper>
        </Content>

        <NumberWrapper>
          <SubTitle>
            <Lang
              datakey="datacenter_personaldata_withdrawal"
              defaultText="Withdrawal Data"
            />
          </SubTitle>
          <NumberContainer>
            <NumberBox>
              <NumberTitleWrapper>
                <NumberTitle>
                  <Lang
                    datakey="datacenter_personaldata_relatedprincipal"
                    defaultText="Related Principal"
                  />
                </NumberTitle>
                <InfoIcon onClick={() => handleInfo(1)}>
                  <Icons.Tooltip />
                </InfoIcon>
              </NumberTitleWrapper>

              <Number>$ {overviewList.RelatedCapital}</Number>

              <InfoText style={{ opacity: infoIsShow[0].open ? 1 : 0 }}>
                <Lang
                  datakey="datacenter_personaldata_relatedprincipal_info"
                  defaultText="Total principal related to the withdrawn profits."
                />
              </InfoText>
            </NumberBox>
            <NumberBox>
              <NumberTitleWrapper>
                <NumberTitle>
                  <Lang
                    datakey="datacenter_personaldata_withdrawnprofits"
                    defaultText="Withdrawn Profits"
                  />
                </NumberTitle>
                <InfoIcon onClick={() => handleInfo(2)}>
                  <Icons.Tooltip />
                </InfoIcon>
              </NumberTitleWrapper>

              <Number>$ {overviewList.SumReturn}</Number>

              <InfoText style={{ opacity: infoIsShow[1].open ? 1 : 0 }}>
                <Lang
                  datakey="datacenter_personaldata_withdrawnprofits_info"
                  defaultText="The profits you've withdrawn. Excluded: Principal. Included: Principal Value Growth, Profits, Profit Value Growth."
                />
                {/* 您提出的利潤。<br></br>
                不包含：本金。包含：本金價值增長，利潤，利潤價值增長。 */}
              </InfoText>
            </NumberBox>
            <NumberBox>
              <NumberTitleWrapper>
                <NumberTitle>
                  <Lang
                    datakey="datacenter_personaldata_rateofreturn"
                    defaultText="Rate of Return"
                  />
                </NumberTitle>
                <InfoIcon onClick={() => handleInfo(3)}>
                  <Icons.Tooltip />
                </InfoIcon>
              </NumberTitleWrapper>

              <Number style={{ borderRight: "1px solid transparent" }}>
                {(overviewList.ProfitRate * 100).toFixed(2)} %
              </Number>
              {/* <Mark>%</Mark> */}

              <InfoText style={{ opacity: infoIsShow[2].open ? 1 : 0 }}>
                {/* 提出利潤 / 相關本金 * 100% */}
                <Lang
                  datakey="datacenter_personaldata_rateofreturn_info"
                  defaultText="Withdrawn Profits / Related Principal * 100%"
                />
              </InfoText>
            </NumberBox>
          </NumberContainer>
        </NumberWrapper>
      </Container>
    </Wrapper>
  );
};

export default MiningData;
