import styled, { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";
import { useState, useEffect } from "react";
import Icons from "../../../global/icons";

import Lottie from "lottie-react";

import medalAnimation from "../../../lotties/medal.json";
import Lang from "../../../global/Lang";
import Stamp from "./Stamp";
import RuleModal from "./RuleModal";

const Wrapper = styled.div`
  height: 100%;
  padding-bottom: 100px;
  color: #fcfcfc;
  padding: 20px 0;
  width: 100%;
  display: flex;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const QuestWrapper = styled.div``;

const QuestContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 40px;
`;

const QusetCard = styled.div`
  padding: 20px 20px;
  border: 1px solid;
  border-radius: 8px;
  border-color: rgba(255, 255, 255, 0.15);
  display: flex;
  justify-content: flex-start;
  position: relative;
  /* background: #22254a; */
`;

const QuestCardTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;

  ${RWD_SM(css`
    width: 100%;
    justify-content: flex-start;
  `)};
`;

const QuestCardTitle = styled.div`
  font-weight: bold;
  font-size: min(max(1.5625vw, 18px), 30px);
  padding-right: 10px;
`;

const QuesetIcon = styled.div`
  width: 150px;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  ${RWD_LL(css`
    display: none;
    /* padding: 8px 18px;
    font-size: min(max(0.833vw, 14px), 16px); */
  `)};
`;

const QuestMobIcon = styled.div`
  display: none;
  width: 35px;
  height: 30px;

  ${RWD_LL(css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: -5px;
  `)};
`;

const QusetCardInfoIcon = styled.div`
  width: 25px;
  height: 25px;
  display: inline-flex;
  cursor: pointer;

  svg {
    width: 25px;
    height: 25px;
  }

  & path {
    stroke: #54587b;
  }

  ${RWD_SM(css`
    margin-left: auto;
  `)};
`;

const QusetCardTime = styled.div`
  color: #54587b;
  font-size: 14px;
`;

const QuesetCardContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const QuesetCardContent = styled.div`
  /* color: #54587b; */
  padding-bottom: 20px;
  color: #d3d3d3;
  text-align: justify;

  ${RWD_LL(css`
    width: 80%;
  `)};

  ${RWD_MD(css`
    width: 75%;
  `)};

  ${RWD_SM(css`
    width: 100%;
  `)};
`;

const QuestCardRewardWrapper = styled.div`
  display: flex;

  width: 85%;
  justify-content: space-between;
  align-items: baseline;

  ${RWD_LL(css`
    width: 80%;
  `)};

  ${RWD_SM(css`
    width: 100%;
    flex-direction: column;
  `)};
`;

const QuestCardReward = styled.div`
  display: flex;
  align-items: baseline;
  width: auto;
`;

const QuestCardRewardPoint = styled.div`
  font-size: min(max(2.8vw, 50px), 55px);
  font-family: Montserrat;
  font-weight: bold;
  color: #0aecd1;
  padding-right: 10px;
`;

const QuesetCardRewardTitle = styled.div``;

const StampWrapper = styled.div`
  position: absolute;
  top: -30px;
  right: -30px;
  transform: rotate(-45deg);
  z-index: 1;
  opacity: 0.5;

  ${RWD_LL(css`
    top: -35px;
    right: -10px;
  `)};

  ${RWD_SM(css`
    top: 70%;
    right: 8px;
  `)};
`;

const Completed = ({ completedData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [questData, setQuestData] = useState([
    {
      AccountQuestId: 1,
      Title: "Create a New ALGO",
      Content:
        "Create a new ALGO and achieve a 3% profit to earn free bonus points.",
      Point: 100,
      GeneralRuleKey:
        "Create a new Mining Package for the first time and choose 'Compound Package (CP)' as the Return Type. Upon successfully depositing the specified amount, you will be eligible for the quest reward.",
      RewardKey:
        "Reward Type: 100 AVA Points (Free)Note: Free Ava Points can only be used for store redemption or as a fee discount and cannot be withdrawn.",
    },
    {
      AccountQuestId: 2,
      Title: "LET’S MINING!",
      Content: "Deposit your first Mining Package for 100 FREE AVA Points!",
      Point: 200,
      GeneralRuleKey:
        "Create a new Mining Package for the first time and choose 'Compound Package (CP)' as the Return Type. Upon successfully depositing the specified amount, you will be eligible for the quest reward.",
      RewardKey:
        "Reward Type: 100 AVA Points (Free)Note: Free Ava Points can only be used for store redemption or as a fee discount and cannot be withdrawn.",
    },
  ]);

  const handleRuleModal = (id) => {
    console.log(questData?.filter((data) => data.AccountQuestId === id));
    setIsOpen(true);

    setModalData([
      {
        GeneralRuleKey: completedData?.filter(
          (data) => data.AccountQuestId === id
        )[0].GeneralRuleKey,
        RewardKey: completedData?.filter(
          (data) => data.AccountQuestId === id
        )[0].RewardKey,
      },
    ]);

    console.log(modalData);
  };

  const handleFakeRuleModal = (id) => {
    console.log(questData?.filter((data) => data.AccountQuestId === id));
    setIsOpen(true);

    setModalData([
      {
        GeneralRuleKey: questData?.filter(
          (data) => data.AccountQuestId === id
        )[0].GeneralRuleKey,
        RewardKey: questData?.filter((data) => data.AccountQuestId === id)[0]
          .RewardKey,
      },
    ]);

    console.log(modalData);
  };

  const Nodata = styled.div`
    font-size: min(max(1.0416vw, 18px), 20px);
    padding: 20px;
    background-color: #22254a;
    display: flex;
    color: #54587b;

    align-items: center;
    justify-content: center;
    flex: 1;
  `;

  return (
    <Wrapper>
      <Container>
        {completedData.length > 0 ? (
          <QuestWrapper>
            <QuestContainer>
              {completedData?.map((data) => (
                <QusetCard>
                  <QuesetIcon>
                    <Lottie animationData={medalAnimation} loop={false} />
                  </QuesetIcon>

                  <QuesetCardContentWrapper>
                    <QuestCardTitleWrapper>
                      <QuestMobIcon>
                        <Lottie animationData={medalAnimation} loop={false} />
                      </QuestMobIcon>
                      <QuestCardTitle>
                        <Lang
                          datakey={data.TitleKey}
                          defaultText={data.TitleKey}
                        />
                      </QuestCardTitle>
                      <QusetCardInfoIcon
                        onClick={() => handleRuleModal(data.AccountQuestId)}
                      >
                        <Icons.Tooltip />
                      </QusetCardInfoIcon>
                    </QuestCardTitleWrapper>
                    <QuesetCardContent>
                      <Lang
                        datakey={data.ContentKey}
                        defaultText={data.ContentKey}
                      />
                    </QuesetCardContent>

                    <QuestCardRewardWrapper>
                      <QuestCardReward>
                        <QuestCardRewardPoint>
                          {data.Points}
                        </QuestCardRewardPoint>
                        <QuesetCardRewardTitle>AVA Coins</QuesetCardRewardTitle>
                      </QuestCardReward>
                      <QusetCardTime>
                        <Lang
                          datakey="quest_completion_date"
                          defaultText="Completion Date"
                        />{" "}
                        {data.CompletionDate}
                      </QusetCardTime>
                    </QuestCardRewardWrapper>
                  </QuesetCardContentWrapper>
                  <StampWrapper>
                    <Stamp time={data.CompletionDate} />
                  </StampWrapper>
                </QusetCard>
              ))}

              {/* {questData.map((data) => (
                <QusetCard>
                  <QuesetIcon>
                    <Lottie animationData={medalAnimation} loop={false} />
                  </QuesetIcon>

                  <QuesetCardContentWrapper>
                    <QuestCardTitleWrapper>
                      <QuestMobIcon>
                        <Lottie animationData={medalAnimation} loop={false} />
                      </QuestMobIcon>
                      <QuestCardTitle>{data.Title}</QuestCardTitle>
                      <QusetCardInfoIcon
                        onClick={() => handleRuleModal(data.AccountQuestId)}
                      >
                        <Icons.Tooltip />
                      </QusetCardInfoIcon>
                    </QuestCardTitleWrapper>
                    <QuesetCardContent>{data.Content}</QuesetCardContent>

                    <QuestCardRewardWrapper>
                      <QuestCardReward>
                        <QuestCardRewardPoint>
                          {data.Point}
                        </QuestCardRewardPoint>
                        <QuesetCardRewardTitle>AVA Coins</QuesetCardRewardTitle>
                      </QuestCardReward>
                      <QusetCardTime>Completion Date 2023.09.20</QusetCardTime>
                    </QuestCardRewardWrapper>
                  </QuesetCardContentWrapper>
                  <StampWrapper>
                    <Stamp time={"2023.09.20"} />
                  </StampWrapper>
                </QusetCard>
              ))} */}
            </QuestContainer>
          </QuestWrapper>
        ) : (
          // <QuestWrapper>
          //   <QuestContainer>
          //     {questData.map((data) => (
          //       <QusetCard>
          //         <QuesetIcon>
          //           <Lottie animationData={medalAnimation} loop={false} />
          //         </QuesetIcon>

          //         <QuesetCardContentWrapper>
          //           <QuestCardTitleWrapper>
          //             <QuestMobIcon>
          //               <Lottie animationData={medalAnimation} loop={false} />
          //             </QuestMobIcon>
          //             <QuestCardTitle>{data.Title}</QuestCardTitle>
          //             <QusetCardInfoIcon
          //               onClick={() => handleFakeRuleModal(data.AccountQuestId)}
          //             >
          //               <Icons.Tooltip />
          //             </QusetCardInfoIcon>
          //           </QuestCardTitleWrapper>
          //           <QuesetCardContent>{data.Content}</QuesetCardContent>

          //           <QuestCardRewardWrapper>
          //             <QuestCardReward>
          //               <QuestCardRewardPoint>
          //                 {data.Point}
          //               </QuestCardRewardPoint>
          //               <QuesetCardRewardTitle>AVA Coins</QuesetCardRewardTitle>
          //             </QuestCardReward>
          //             <QusetCardTime>Completion Date 2023-09-20</QusetCardTime>
          //           </QuestCardRewardWrapper>
          //         </QuesetCardContentWrapper>
          //         <StampWrapper>
          //           <Stamp time={"2023-09-20"} />
          //         </StampWrapper>
          //       </QusetCard>
          //     ))}
          //   </QuestContainer>
          // </QuestWrapper>

          <Nodata>
            <Lang datakey="nodata" defaultText="No data available" />
          </Nodata>
        )}
      </Container>
      {isOpen && <RuleModal data={modalData} setIsOpen={setIsOpen} />}
    </Wrapper>
  );
};

export default Completed;
