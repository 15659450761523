import { useEffect, useState } from 'react';

// --- components ---
import Alert from '../Global/Alert';
import Loading from '../Global/Loading';

// --- plugins ---
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { StyledInput, StyledSelect } from '../Global/Ui';

const Amount = ({
  currentStep,
  totalStep,
  transferPkg,
  investRestrict,
  setValid,
  projectInformation,
  setProjectInfomation,
  className
}) => {
  // --- handler ---
  const handleUpdateData = () => {
    setProjectInfomation({
      ...projectInformation,
      name: projectName,
      type: type,
      amount: investmentAmount,
    });
  };

  // --- states ---
  // status
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  // api data
  const [maxAmount, setMaxAmount] = useState(0);
  const [minAmount, setMinAmount] = useState(0);
  // user input data
  const [projectName, setProjectName] = useState(() => {
    // return !!projectInformation.name ? projectInformation.name : '';
    return !!projectInformation.name ? projectInformation.name : 'MyProject';
  });
  const [investmentAmount, setInvestmentAmount] = useState(() => {
    return !!projectInformation.amount ? projectInformation.amount : 0;
  });
  const [type, setType] = useState(() => {
    return !!projectInformation.type ? projectInformation.type : null;
  });
  
  // --- effect ---
  useEffect(() => {
    if (!!investRestrict) {
      if (type === null) setType(investRestrict[0].Type);
      setLoading(false);
    }
  }, [investRestrict]);

  useEffect(() => {
    if (!!error.name) return setValid(false);
    if (!!error.amount) return setValid(false);
    setValid(true);
  }, [error]);

  useEffect(() => {
    if (!projectName) return setError({ ...error, name: 'Please Enter Project Name' });
    handleUpdateData();
    setError({ ...error, name: '' });
  }, [projectName]);

  useEffect(() => {
    if (!type) return;
    const typeOnSelected = investRestrict.find(({ Type }) => Type === type);
    const min = typeOnSelected?.SingleInvestMin;
    const max = typeOnSelected?.SingleInvestMax
                + ((!!transferPkg && projectInformation?.oriType === type)
                    ? projectInformation?.amount : 0);
    setMaxAmount(max);
    setMinAmount(min);

    if (!!transferPkg) {
      if (investmentAmount < min || investmentAmount > max) return Swal.fire('This package Deposit Amount is out of transfer range.');
    }

    if (investmentAmount < min) setInvestmentAmount(min);
    if (investmentAmount > max) setInvestmentAmount(max);
    handleUpdateData();
  }, [type]);

  useEffect(() => {
    if (!minAmount || !maxAmount) return;
    if (investmentAmount < minAmount) return setError({ ...error, amount: `Min Investment Amount is $ ${minAmount}` });
    if (investmentAmount > maxAmount) return setError({ ...error, amount: `Max Investment Amount is $ ${maxAmount}` });
    handleUpdateData();
    setError({ ...error, amount: ''});
  }, [investmentAmount]);
  
  // --- component ---
  if (loading) return <Loading />

  return (
    <>
      {/* title */}
      <h3 className='tracking-[-0.015em] text-center'>
        Mining Package Information and Risk ({`${currentStep}/${totalStep}`})
      </h3>

      {error.name && <Alert className={`max-w-[650px] mx-auto transition duration-300 opacity-${error ? 1 : 0}`} message={error.name} />}
      {error.amount && <Alert className={`max-w-[650px] mx-auto transition duration-300 opacity-${error ? 1 : 0}`} message={error.amount} />}

      {/* name & risk */}
      <div className={`mt-10 mx-auto ${className}`}>
        {/* project name */}
        <div className='flex justify-between items-center'>
          <label>Mining Package Name</label>
          <StyledInput
            value={projectName}
            placeholder='MyProject'
            onChange={e => setProjectName(e.target.value)}
          />
        </div>

        {/* selection */}
        <div className='mt-7 flex justify-between'>
          <label className='flex-1 whitespace-nowrap'>Mining Package Risk</label>
          <StyledSelect
            options={investRestrict?.map(({ Type }) => ({ value: Type, text: Type }))}
            selectedValue={type}
            onChange={e => setType(e.target.value)}
          />
        </div>

        {/* profit range */}
        <div className='mt-7 pr-4 flex justify-between'>
          <label>Estimated growth %</label>
          <span className='text-blue font-bold'>N/A</span>
        </div>
      </div>

      {/* Deposit Amount */}
      <div className={`my-20 flex flex-wrap justify-between ${className} mx-auto`}>
        <label>Deposit Amount</label>
        <span className='text-blue'>Range $ {minAmount.toFixed(0)} ~ {maxAmount.toFixed(0)}</span>
        <div className='
          relative w-full
          after:content-["$"] after:absolute
          after:left-2 after:top-1/2 after:translate-y-[-50%]
          after:text-[#666]
        '>
          <StyledInput
            value={!!transferPkg ? Math.floor(investmentAmount) : investmentAmount}
            onChange={e => {
              !isNaN(Number(e.target.value)) && setInvestmentAmount((Number(e.target.value)));
            }}
          />
        </div>
      </div>
    </>
  )
};

export default Amount;