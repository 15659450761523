import styled, { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";

import { Select } from "../../../global/Selects";

import { useEffect, useState } from "react";

import { driver } from "driver.js";
import "driver.js/dist/driver.css";

import JoyrideComponent from "../../../global/JoyrideComponent";
import { useLocation } from "react-router-dom";
import Lang from "../../../global/Lang";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  /* height: 100%;
  min-height: 45vh; */
`;

const Title = styled.div`
  font-size: min(max(1.25vw, 22px), 24px);
  color: #fcfcfc;
  padding-bottom: 20px;

  ${RWD_MD(css`
    font-size: min(max(1.0416vw, 18px), 20px);
  `)};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;
  color: #fcfcfc;

  ${RWD_SM(css`
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0px;
  `)};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${RWD_SM(css`
    padding-bottom: 10px;
  `)};
`;

const AmountContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  ${RWD_SM(css`
    padding-bottom: 40px;
  `)};
`;

const InputEl = styled.input`
  font-size: min(max(1.0416vw, 18px), 20px);
  text-align: center;
  /* padding: 1.0416vw 1.5625vw; */
  padding: 10px 20px;
  border: 1px solid;
  border-radius: 8px;
  width: 100%;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  color: #fcfcfc;

  border-color: ${(props) =>
    props.error ? "#F05D2F" : "rgba(255, 255, 255, 0.15)"};

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 16px), 18px);
  `)};
`;

const Label = styled.label`
  padding: 10px 0;
  font-size: min(max(1.0416vw, 18px), 20px);

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 16px), 18px);
  `)};
`;

const ErrorMsgWrapper = styled.div`
  position: absolute;
  color: #f05d2f;
  font-size: min(max(0.833vw, 14px), 16px);
  text-align: justify;
  /* height: 40px; */
  padding-top: 5px;
  top: 100%;
  ${RWD_SM(css`
    padding: 0;
    top: 75%;
  `)};
`;

const Information = ({
  setName,
  name,
  setAmount,
  amount,
  error,
  returnType,
  setReturnType,
  setActiveStep,
  asset,
}) => {
  const { search } = useLocation();
  useEffect(() => {
    const query = new URLSearchParams(search);
    const tutorial = query.get("tutorial");
    if (tutorial === "mining") {
      setJoyrideIsRun(true);
    }
  }, [search]);

  const handleJoyrideCallback = () => {
    setActiveStep(2);
  };

  const [joyrideIsRun, setJoyrideIsRun] = useState(false);
  const joyrideState = [
    {
      target: ".createMining_InputName",
      content: (
        <Lang
          datakey="mining_tutorial_3-1"
          defaultText="Please enter a name for this Mining Package here."
        />
      ),
      placement: "right",
      disableBeacon: true,
      disableOverlayClose: true,
      callbackAction: false,
    },
    {
      target: ".createMining_InputDeposit",
      content: (
        <Lang
          datakey="mining_tutorial_3-2"
          defaultText="Please enter the amount you wish to use to execute the Mining Package. The number must not be less than 1000."
        />
      ),
      placement: "right",
      disableBeacon: true,
      disableOverlayClose: true,
      callbackAction: false,
    },
    {
      target: ".createMining_SelectReturnType",
      content: (
        <Lang
          datakey="mining_tutorial_3-3"
          defaultText="Please choose the Return type you would like."
        />
      ),
      placement: "top",
      disableBeacon: true,
      disableOverlayClose: true,
      callbackAction: true,
      function: handleJoyrideCallback,
    },
  ];

  const handleAmmountValidate = (e) => {
    if (asset === "USDT") {
      setAmount(e.target.value.replace(/^(0+)|[^\d]+/g, ""));
    } else {
      setAmount(e.target.value.replace(/^\D*(\d*(?:\.\d{0,4})?).*$/g, "$1"));
    }
  };

  return (
    <Wrapper>
      <JoyrideComponent
        joyrideState={joyrideState}
        run={joyrideIsRun}
        joyrideType={"mining"}
      />
      <Title>
        <Lang
          datakey="mining_package_inforisk"
          defaultText="Mining Package Information and Risk"
        />
      </Title>

      <Container>
        <InputWrapper>
          <InputContainer className="createMining_InputName">
            <Label>
              <Lang
                datakey="mining_package_name"
                defaultText="Mining Package Name"
              />
            </Label>
            <InputEl value={name} onChange={(e) => setName(e.target.value)} />
          </InputContainer>

          <AmountContainer className="createMining_InputDeposit">
            <Label>
              <Lang datakey="deposit_amount" defaultText="Deposit Amount" />
            </Label>
            <InputEl
              value={amount}
              error={error}
              placeholder={asset === "USDT" ? "$1000 - " : "$0.0001 - "}
              onChange={
                (e) => handleAmmountValidate(e)
                // setAmount(e.target.value.replace(/^(0+)|[^\d]+/g, ""))
              }
            />
            <ErrorMsgWrapper>{error}</ErrorMsgWrapper>
          </AmountContainer>
        </InputWrapper>
        <Select
          group={"ReturnType"}
          title={<Lang datakey="return_type" defaultText="Return Type" />}
          styled={"column"}
          onChangeFun={setReturnType}
          nowValue={returnType}
          placeholder={"Please select a return type"}
          className={"createMining_SelectReturnType"}
        />
      </Container>
    </Wrapper>
  );
};

export default Information;
