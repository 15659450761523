import styled, { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";
import Icons from "../../global/icons";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../components/Router";
import { useDispatch } from "react-redux";
import { setAccountProfile } from "../../slices/accountSlice";

import SelectKycType from "./IdentityStep/SelectKycType";
import PersonalInfo from "./IdentityStep/PersonalInfo";
import UploadImg from "./IdentityStep/UploadImg";
import UploadSelfiePhoto from "./IdentityStep/UploadSelfiePhoto";

import Lang from "../../global/Lang";

import { handleColorType } from "../../utils/ButtonStyle";
// api
import { useUploadKycMutation } from "../../services/authApi";
import { CalendarFormat } from "../../utils";

import { useGetAccountProfileQuery } from "../../services/accountApi";

// sweetAlert
import { SuccessMySwal, ErrorMySwal } from "../../global/MySwal";

import Loading from "../../components/Loading/Loading";

import { getLanguage } from "../../services/common";
import { LangString } from "../../utils";

const Wrapper = styled.div`
  width: 100%;
  /* min-height: calc(100vh - 80px); */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Title = styled.div`
  font-size: min(max(1.25vw, 22px), 24px);
  color: #fcfcfc;
  padding-right: 10px;
  text-transform: capitalize;
  padding-bottom: 20px;

  ${RWD_MD(css`
    font-size: min(max(1.145vw, 20px), 22px);
  `)};
`;

const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  ${RWD_MD(css`
    padding: 20px 25px;
  `)};

  ${RWD_SM(css`
    padding: 20px 15px;
    margin-bottom: 50px;
  `)};
`;

const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 1.5625vw;

  width: 100%;
  overflow: hidden;

  ${RWD_LL(css`
    /* order: -1; */
    width: 100%;
    position: absolute;
    top: 0;
    margin-right: 0;
  `)};
`;

const ProgressContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  ${RWD_LL(css`
    width: 100%;
  `)};
`;

const ProgressItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${RWD_LL(css`
    width: 100%;
  `)};
`;

const ProgressItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  /* transform: translateX(-25%); */

  ${RWD_LL(css`
    align-items: center;
  `)};

  ::before {
    position: absolute;
    content: "";
    border-bottom: 4px solid;
    border-color: ${(props) => (props.status ? "#0AECD1" : "#3A3D5A")};
    width: 50%;
    top: 15px;
    left: 0;
    z-index: 1;

    /* ${RWD_LL(css`
      width: 50%;
    `)}; */
  }

  ::after {
    position: absolute;
    content: "";
    border-bottom: 4px solid;
    border-color: ${(props) => (props.active ? "#0AECD1" : "#3A3D5A")};
    width: 50%;
    top: 15px;
    right: 0%;
    z-index: 1;

    ${RWD_LL(css`
      width: 50%;
    `)};
  }

  :first-child::before {
    content: none;

    ${RWD_LL(css`
      content: "";
    `)};
  }

  :last-child::after {
    content: none;

    ${RWD_LL(css`
      content: "";
    `)};
  }
`;
const ProgressItemText = styled.div`
  padding-top: 5px;
  /* position: absolute; */
  /* top: 100%; */
  text-align: center;
  /* position: absolute; */

  /* position: absolute; */
  /* white-space: nowrap; */
  /* transform: translateX(calc(50% - 15px)); */

  font-size: min(max(0.833vw, 12px), 16px);
  /* top: 50%;
  left: 50%;
  z-index: 5;
  transform: translate(-50%, -50%); */
  color: ${(props) => (props.status ? "#0AECD1" : "#FCFCFC")};
`;

const ProgressItemNumber = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: ${(props) => (props.status ? "#3A3D5A" : "#FCFCFC")};
  background: ${(props) => (props.status ? "#0AECD1" : "#3A3D5A")};
`;

const StepActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
  background-color: #151944;
  padding-bottom: 3.854vw;
  padding-top: 3.854vw;
  margin-top: auto;

  position: relative;

  ${RWD_LL(css`
    padding: 100px 0 20px 0;
    /* flex-direction: column; */
    /* height: 200px; */
    align-items: flex-end;
  `)};
`;

const ButtonWrapper = styled.div`
  display: flex;
  cursor: pointer;

  button:first-child {
    margin-right: 1.5625vw;
  }
`;

const Button = styled.button`
  border: ${({ type }) => handleColorType(type).border};
  border-radius: 60px;
  width: min(max(7.0833vw, 100px), 135px);
  background: ${({ type }) => handleColorType(type).bg};
  text-align: center;
`;

const ButtonText = styled.span`
  font-size: min(max(1.0416vw, 16px), 20px);
  color: #fcfcfc;
  display: inline-flex;
  padding: 0.52vw 1.3vw;
  ${RWD_LG(css`
    padding: 8px 18px;
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const NoticeWrapper = styled.div`
  color: #fcfcfc;
  padding: 20px 0;
  opacity: 0.7;
`;

const NoticeContainer = styled.div`
  display: flex;
`;

const NoticeContent = styled.div`
  padding-left: 10px;
  font-size: min(max(0.7291vw, 12px), 14px);
`;

const NoticeIcon = styled.div`
  width: 30px;
  height: 30px;

  svg {
    width: 30px;
    height: 20px;
  }

  & path {
    fill: #fcfcfc;
  }
`;

const Identity = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stepDirection = [
    // "選擇證件類型",
    // "輸入身份資料",
    // "上傳證件照片",
    // "上傳本人照片",
    <Lang
      datakey="identity_step_selectdocument"
      defaultText="Select Document Type"
    />,
    <Lang
      datakey="identity_step_enterpersonalinfo"
      defaultText="Enter Your Personal Information"
    />,
    <Lang
      datakey="identity_step_uploadidphoto"
      defaultText="Upload Identification Photo"
    />,
    <Lang
      datakey="identity_step_uploadselfphoto"
      defaultText="Upload A Photo Of Yourself"
    />,
  ];
  const [activeStep, setActiveStep] = useState(0);
  // selectkryctype step
  const [country, setCountry] = useState("");
  const [kycType, setkycType] = useState("");
  // personalinfo step
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birth, setBirth] = useState(null);
  const [identityNumber, setIdentityNumber] = useState("");
  // uploadimg step
  const [idBack, setIdBack] = useState("");
  const [idFront, setIdFront] = useState("");
  const [passport, setPassport] = useState("");
  // uploadselfiephoto stap
  const [selfie, setSelfie] = useState("");
  // error msg
  const [errorMsg, setErrorMsg] = useState("");

  const selectKycTypeIsValid = country !== "" && kycType !== "";
  const personalInfoIsValid =
    firstName !== "" &&
    lastName !== "" &&
    birth !== null &&
    identityNumber !== "" &&
    errorMsg === "";

  const uploadImgIsValid =
    kycType == 1 ? idFront !== "" && idBack !== "" : passport !== "";

  const uploadSelfieIsValid = selfie !== "";

  // api
  const [_uploadKycData, { data, isSuccess, error, isError, isLoading }] =
    useUploadKycMutation();

  useEffect(() => {
    if (isSuccess) {
      // 多國語言
      const title = LangString("alert_upload_success", "Upload successful");
      const text = LangString(
        "alert_identity_review",
        "Please wait patiently for the result."
      );
      const confirmText = LangString("confirm", "Confirm");

      SuccessMySwal({
        title: title,
        text: text,
        showCancelButton: false,
        confirmButtonText: confirmText,
      }).then((result) => {
        if (result.isConfirmed) {
          refetch();
          navigate(routes.setting_v2);
        }
      });
    }

    if (isError) {
      ErrorMySwal({
        title:
          error?.data?.ResultLanguages[getLanguage()] || error?.data?.Message,
      });
    }
  }, [data, isError]);

  const {
    data: profileData,
    error: profileDataError,
    isError: profileDataIsError,
    isSuccess: profileDataIsSuccess,
    refetch,
    isFetching,
  } = useGetAccountProfileQuery();

  useEffect(() => {
    if (profileDataIsSuccess) {
      dispatch(setAccountProfile(profileData));
    }
  }, [profileData, profileDataIsSuccess]);

  //  cancel button
  const handleCancel = () => {
    navigate(routes.setting_v2);
  };

  const handleBackStep = () => {
    if (activeStep === 0) {
      return;
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  const handleNextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const handleUploadKycData = () => {
    const form = new FormData();
    form.append("Country", country);
    form.append("KycType", kycType);
    form.append("FirstName", firstName);
    form.append("MiddleName", middleName);
    form.append("LastName", lastName);
    form.append("Birth", CalendarFormat(birth));
    form.append("IdentityNumber", identityNumber);
    form.append("IdBack", idBack);
    form.append("IdFront", idFront);
    form.append("Passport", passport);
    form.append("Selfie", selfie);

    console.log(form);

    for (var pair of form.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    _uploadKycData(form);
  };

  // 換頁時回到頂部
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  return (
    <Wrapper>
      {(isLoading || isFetching) && <Loading />}
      <Title>
        <Lang
          datakey="setting_subTitle_identity"
          defaultText="Identity Verification"
        />
      </Title>
      <ContentWrapper>
        {activeStep === 0 && (
          <SelectKycType
            setCountry={setCountry}
            setkycType={setkycType}
            kycType={kycType}
            country={country}
          />
        )}
        {activeStep === 1 && (
          <PersonalInfo
            setFirstName={setFirstName}
            firstName={firstName}
            setMiddleName={setMiddleName}
            middleName={middleName}
            setLastName={setLastName}
            lastName={lastName}
            setBirth={setBirth}
            birth={birth}
            identityNumber={identityNumber}
            setIdentityNumber={setIdentityNumber}
            kycType={kycType}
            country={country}
            errorMsg={errorMsg}
            setErrorMsg={setErrorMsg}
          />
        )}
        {activeStep === 2 && (
          <UploadImg
            idFront={idFront}
            setIdFront={setIdFront}
            idBack={idBack}
            setIdBack={setIdBack}
            setPassport={setPassport}
            passport={passport}
            kycType={kycType}
          />
        )}
        {activeStep === 3 && (
          <UploadSelfiePhoto selfie={selfie} setSelfie={setSelfie} />
        )}
        <NoticeWrapper>
          <NoticeContainer>
            <NoticeIcon>
              <Icons.Warning />
            </NoticeIcon>
            <NoticeContent>
              <Lang
                datakey="kyc_valid_document_info"
                defaultText="This information is used for personal verication only, and is kept
              private and confidential by AVA."
              />
            </NoticeContent>
          </NoticeContainer>
        </NoticeWrapper>
      </ContentWrapper>

      <StepActionWrapper>
        {activeStep !== 0 && (
          <ButtonWrapper style={{ marginRight: "auto" }}>
            <Button type="stroke" onClick={() => handleBackStep()}>
              <ButtonText>
                <Lang datakey="laststep" defaultText="Back" />
              </ButtonText>
            </Button>
          </ButtonWrapper>
        )}

        <ProgressWrapper>
          <ProgressContainer>
            {Array.from({ length: 4 }, (v, i) => (
              <ProgressItem
                key={i}
                status={activeStep >= i}
                active={
                  activeStep > i || activeStep == stepDirection.length - 1
                }
              >
                <ProgressItemWrapper>
                  <ProgressItemNumber status={activeStep >= i}>
                    {i + 1}
                  </ProgressItemNumber>
                  <ProgressItemText status={activeStep >= i}>
                    {stepDirection[i]}
                  </ProgressItemText>
                </ProgressItemWrapper>
              </ProgressItem>
            ))}
          </ProgressContainer>
        </ProgressWrapper>

        {(() => {
          switch (activeStep) {
            case 0:
              return (
                <ButtonWrapper>
                  <Button type="storke" onClick={() => handleCancel()}>
                    <ButtonText>
                      <Lang datakey="cancel" defaultText="Cancel" />
                    </ButtonText>
                  </Button>
                  <Button
                    disabled={!selectKycTypeIsValid}
                    type={selectKycTypeIsValid ? "fill" : "disabled"}
                    onClick={() => handleNextStep()}
                  >
                    <ButtonText>
                      <Lang datakey="nextstep" defaultText="Next" />
                    </ButtonText>
                  </Button>
                </ButtonWrapper>
              );
            case 1:
              return (
                <ButtonWrapper>
                  <Button type="storke" onClick={() => handleCancel()}>
                    <ButtonText>
                      <Lang datakey="cancel" defaultText="Cancel" />
                    </ButtonText>
                  </Button>
                  <Button
                    disabled={!personalInfoIsValid}
                    type={personalInfoIsValid ? "fill" : "disabled"}
                    onClick={() => handleNextStep()}
                  >
                    <ButtonText>
                      <Lang datakey="nextstep" defaultText="Next" />
                    </ButtonText>
                  </Button>
                </ButtonWrapper>
              );
            case 2:
              return (
                <ButtonWrapper>
                  <Button type="storke" onClick={() => handleCancel()}>
                    <ButtonText>
                      <Lang datakey="cancel" defaultText="Cancel" />
                    </ButtonText>
                  </Button>
                  <Button
                    disabled={!uploadImgIsValid}
                    type={uploadImgIsValid ? "fill" : "disabled"}
                    onClick={() => handleNextStep()}
                  >
                    <ButtonText>
                      <Lang datakey="nextstep" defaultText="Next" />
                    </ButtonText>
                  </Button>
                </ButtonWrapper>
              );

            case 3:
              return (
                <ButtonWrapper>
                  <Button type="storke" onClick={() => handleCancel()}>
                    <ButtonText>
                      <Lang datakey="cancel" defaultText="Cancel" />
                    </ButtonText>
                  </Button>
                  <Button
                    onClick={() => handleUploadKycData()}
                    disabled={!uploadSelfieIsValid}
                    type={uploadSelfieIsValid ? "fill" : "disabled"}
                  >
                    <ButtonText>
                      <Lang datakey="done" defaultText="Done" />
                    </ButtonText>
                  </Button>
                </ButtonWrapper>
              );
          }
        })()}
      </StepActionWrapper>
    </Wrapper>
  );
};

export default Identity;
