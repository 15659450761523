import styled, { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";

import Lottie from "lottie-react";
import checkAnimation from "../../lotties/check.json";

const CompletedWrapper = styled.div``;

const CompletedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
`;

const Complete = styled.div`
  font-size: min(max(1.875vw, 28px), 36px);
  padding: 10px 0;
  text-align: center;

  ${RWD_SM(css`
    font-size: 22px;
  `)};
`;

const CompletedIcon = styled.div`
  display: flex;
  align-items: center;
  width: clamp(100px, 8vw, 150px);
  height: clamp(100px, 8vw, 150px);

  /* & path {
    fill: #1cac2a;
  }

  svg {
    width: 100px;
    height: 100px;
  } */
`;

const CompletedDescription = styled.div`
  color: #d3d3d3;
  text-align: center;
  padding: 10px 0;

  font-size: clamp(14px, 0.8333vw, 16px);
`;

const TaskCompleted = () => {
  return (
    <CompletedWrapper>
      <CompletedContainer>
        <CompletedIcon>
          {/* <Icons.Success /> */}
          <Lottie animationData={checkAnimation} loop={false} />
        </CompletedIcon>
        <Complete>You have completed this task.</Complete>
        <CompletedDescription>
          If you wish to edit, you can do so on the Settings page.
        </CompletedDescription>
      </CompletedContainer>
    </CompletedWrapper>
  );
};

export default TaskCompleted;
