import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectSystem } from "../slices/systemSlice";

import parse from "html-react-parser";

const Lang = ({ datakey, defaultText = "", toolTip }) => {
  const lang = useSelector(selectSystem).language;
  const langData = useSelector(selectSystem).langData;
  const [showText, setShowText] = useState("");
  useEffect(() => {
    setShowText(getHeaderText());
  }, [datakey, lang, langData]);

  const getHeaderText = () => {
    if (!langData || langData?.length === 0) return defaultText;
    const data = langData.filter(({ Key }) => Key === datakey);
    if (data.length === 0) return defaultText;
    if (toolTip) return data[0][`Tooltip${lang}`];
    return data[0][lang];
  };

  return <>{parse(showText ?? "")}</>;
};

export default Lang;
