import styled from "styled-components";
import { getDataCenterKline } from "../../../../../services/klineApi";
import {
  BarChart,
  Brush,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
  Line,
  ResponsiveContainer,
  ReferenceLine,
  Text,
} from "recharts";
import { useEffect, useState } from "react";

const TooltipWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  font-size: 12px;
  color: #fcfcfc;
  font-family: Montserrat;
  align-items: center;
`;

const TooltipIcon = styled.div`
  width: 10px;
  height: 10px;
  background: ${({ $color }) => $color};
`;

const TooltipLabelWrapper = styled.div`
  padding-bottom: 5px;
  text-align: center;
`;

const TooltipValueWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

const TooltipValueContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const TooltipTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TooltipValueTitle = styled.div`
  padding: 0 8px;
`;

const TooltipValue = styled.div`
  padding-left: 10px;
`;

const Candlestick = (props) => {
  const {
    fill,
    x,
    y,
    width,
    height,
    low,
    high,
    openClose: [open, close],
  } = props;
  const isGrowing = open < close;
  const color = isGrowing ? "green" : "red";
  const ratio = Math.abs(height / (open - close));

  return (
    <g stroke={color} fill="none" strokeWidth="1">
      <path
        d={`
            M ${x},${y}
            L ${x},${y + height}
            L ${x + width},${y + height}
            L ${x + width},${y}
            L ${x},${y}
          `}
        fill={color}
      />
      {/* bottom line */}
      {isGrowing ? (
        <path
          d={`
              M ${x + width / 2}, ${y + height}
              v ${(open - low) * ratio}
            `}
        />
      ) : (
        <path
          d={`
              M ${x + width / 2}, ${y}
              v ${(close - low) * ratio}
            `}
        />
      )}
      {/* top line */}
      {isGrowing ? (
        <path
          d={`
              M ${x + width / 2}, ${y}
              v ${(close - high) * ratio}
            `}
        />
      ) : (
        <path
          d={`
              M ${x + width / 2}, ${y + height}
              v ${(open - high) * ratio}
            `}
        />
      )}
    </g>
  );
};

export const CandleChart = ({ target, marketData, interval }) => {
  const [klineData, setKlineData] = useState([]);
  const [avgPrice, setAvgPrice] = useState();

  const prepareData = (data) => {
    return data.map(({ open, close, ...other }) => {
      return {
        ...other,
        openClose: [open, close],
      };
    });
  };

  const transformDateTime = (timestamp) => {
    const time = Number(timestamp);

    return `${new Date(time).getFullYear()}-${
      new Date(time).getMonth() + 1
    }-${new Date(time).getDate()} ${String(new Date(time).getHours()).padStart(
      2,
      "0"
    )}:${String(new Date(time).getMinutes()).padStart(2, "0")}`;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <TooltipWrapper className="custom-tooltip">
          <TooltipLabelWrapper className="label">
            {transformDateTime(label?.split(" ")[0])}
          </TooltipLabelWrapper>
          <TooltipValueWrapper className="intro">
            <TooltipValueContainer>
              <TooltipTitleWrapper>
                <TooltipIcon $color={payload[0]?.fill}></TooltipIcon>
                <TooltipValueTitle> Open Price</TooltipValueTitle>
              </TooltipTitleWrapper>
              <TooltipValue>{payload[0].value?.[0]}</TooltipValue>
            </TooltipValueContainer>

            <TooltipValueContainer>
              <TooltipTitleWrapper>
                <TooltipIcon $color={payload[0]?.fill}></TooltipIcon>
                <TooltipValueTitle>Highest Price</TooltipValueTitle>
              </TooltipTitleWrapper>
              <TooltipValue>{payload[0].payload?.high}</TooltipValue>
            </TooltipValueContainer>

            <TooltipValueContainer>
              <TooltipTitleWrapper>
                <TooltipIcon $color={payload[0]?.fill}></TooltipIcon>
                <TooltipValueTitle>Lowest Price</TooltipValueTitle>
              </TooltipTitleWrapper>
              <TooltipValue>{payload[0]?.payload?.low}</TooltipValue>
            </TooltipValueContainer>

            <TooltipValueContainer>
              <TooltipTitleWrapper>
                <TooltipIcon $color={payload[0]?.fill}></TooltipIcon>
                <TooltipValueTitle>Close Price</TooltipValueTitle>
              </TooltipTitleWrapper>
              <TooltipValue>{payload[0].value?.[1]}</TooltipValue>
            </TooltipValueContainer>
          </TooltipValueWrapper>
        </TooltipWrapper>
      );
    }

    return null;
  };

  const formatXAxisDate = (date) => {
    return `${date?.split(" ")[0].split("-")[1]}/${
      date?.split(" ")[0].split("-")[2]
    }`;
  };

  useEffect(() => {
    getDataCenterKline(target, interval)
      .then((res) => {
        if (res.data.retMsg === "OK") {
          setKlineData(
            res.data.result?.list.reverse().map((item) => {
              return {
                high: item[2],
                low: item[3],
                open: item[1],
                close: item[4],
                day: item[0],
                // day: transformDateTime(Number(item[0])),
                avgPrice: marketData.filter(
                  (item) => item.TargetKey === target
                )[0]?.AvgPrice,
              };
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
        // MySwal.fire({
        //   title: error.message,
        //   icon: "error",
        //   confirmButtonText: "確定",
        // });
      });
  }, [target, interval]);

  useEffect(() => {
    setAvgPrice(
      marketData.filter((item) => item.TargetKey === target)[0]?.AvgPrice
    );
  }, [marketData, target]);

  const data = prepareData(klineData);
  const minValue = data.reduce(
    (minValue, { low, openClose: [open, close] }) => {
      const currentMin = Math.min(low, open, close);
      return minValue === null || currentMin < minValue ? currentMin : minValue;
    },
    null
  );
  const maxValue = data.reduce(
    (maxValue, { high, openClose: [open, close] }) => {
      const currentMax = Math.max(high, open, close);
      return currentMax > maxValue ? currentMax : maxValue;
    },
    minValue
  );

  const customTick = (tickObject) => {
    const {
      payload: { value },
    } = tickObject;
    tickObject["fill"] = value === 0 ? "red" : "#666";
    console.log(value);

    return <Text {...tickObject}>{value}</Text>;
  };

  const formatYAxisValue = (value) => {
    console.log(value);
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={1000}
        height={250}
        data={data}
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
      >
        <XAxis
          dataKey="day"
          dy={10}
          stroke="rgba(255, 255, 255, 0.2)"
          tickFormatter={transformDateTime}
          style={{
            fontSize: "13px",
            fontFamily: "Montserrat",
          }}
        />
        <YAxis
          domain={[minValue, maxValue]}
          stroke="rgba(255, 255, 255, 0.1)"
          style={{
            fontSize: "13px",
            fontFamily: "Montserrat",
          }}
          dataKey="openClose"
        />

        {/* <YAxis
          dataKey="avgPrice"
          stroke="rgba(255, 255, 255, 0.1)"
          style={{
            fontSize: "13px",
            fontFamily: "Montserrat",
          }}
          type="number"
        /> */}

        <Legend
          verticalAlign="top"
          height={25}
          wrapperStyle={{ fontSize: "11px", fontFamily: "Montserrat" }}
        />
        <Tooltip
          cursor={{ fill: "rgba(255, 255, 255, 0.2)" }}
          content={<CustomTooltip />}
        />
        <CartesianGrid opacity={0.1} />
        <ReferenceLine
          y={avgPrice}
          stroke="#0aecd1"
          //   alwaysShow={true}
          ifOverflow="extendDomain"
          strokeDasharray="3"
          //   label={avgPrice}
          label={{
            position: "left",
            value: avgPrice,
            fill: "#0aecd1",
            fontSize: 11,
            fontFamily: "Montserrat",
          }}
        />

        {/* <Brush dataKey="day" height={30} stroke="green" /> */}
        <Bar
          dataKey="openClose"
          fill="#2F80ED"
          shape={<Candlestick />}
          barSize={5}

          // label={{ position: 'top' }}
        >
          {/* {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % 20]} />
          ))} */}
        </Bar>

        {/* <Line
          type="monotone"
          dataKey="avgPrice"
          stroke="#0aecd1"
          activeDot={{ r: 2 }}
        /> */}
      </BarChart>
    </ResponsiveContainer>
  );
};
