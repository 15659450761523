import { useState, useEffect } from 'react';

// --- components ---
import Loading from '../../Global/Loading';

// --- plugins ---
// icon
import { FiAlertCircle } from 'react-icons/fi';
import { MdArrowForwardIos } from 'react-icons/md';
// sweetalert
import Swal from 'sweetalert2/dist/sweetalert2.js'

// --- api ---
import { API_GetAnnouncement } from '../../../utils/api';

// must outer Announcement for clearTimout
let timer;

const Announcement = () => {
  // --- call api ---
  const getAnnouncement = async () => {
    const res = await API_GetAnnouncement();
    // if (res.error) return setError(res.error);
    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return Swal.fire(res.Message);
    setAnnouncement(res.ListAnnouncement);
    setLoading(false);
  };

  // --- handler ---
  const handleAnnouncementOnClick = () => {
    setAnnounceOnClick(true);
    Swal.fire({
      customClass: {
        title: 'text-[1.2rem]',
        html: 'leading-[1rem] overflow-scroll'
      },
      title: announcement[currentIndex].Subject.replace('】', '】<br />'),
      html: announcement[currentIndex].Content.split('\r\n').join('<br />')
    })
    .then(() => {
      setAnnounceOnClick(false);
      setPauseTimer(false);
    });
  };

  // --- states ---
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [announcement, setAnnouncement] = useState(null);
  const [announceOnClick, setAnnounceOnClick] = useState(false);
  const [pauseTimer, setPauseTimer] = useState(false);

  // --- effect ---
  useEffect(() => {
    if (!announcement) setAnnouncement('get');
  }, []);

  useEffect(() => {
    if (!announcement) return;
    if (announcement === 'get') getAnnouncement();
    setCurrentIndex(0);
  }, [announcement]);

  useEffect(() => {
    if (currentIndex === -1) return;

    if (pauseTimer) {
      clearTimeout(timer);
      return;
    }

    const count = currentIndex > (announcement.length - 2) ? 0 : currentIndex + 1;
    timer = setTimeout(() => setCurrentIndex(count), 5000);
  }, [currentIndex, pauseTimer]);

  // --- component ---
  if (loading) return <Loading />
  return (
    <div
      className='
        w-[100%] py-2 max-w-[520px] flex justify-between md:justify-start items-start cursor-pointer
        text-sub-word hover:text-blue
      '
      onClick={handleAnnouncementOnClick}
      onMouseEnter={e => setPauseTimer(true)}
      onMouseLeave={e => !announceOnClick && setPauseTimer(false)}
    >
      <FiAlertCircle className='text-inherit text-[1.3rem]' />
      
      <span className='flex-1 md:flex-initial px-2 indent-[-8px] text-inherit'>
        {announcement[currentIndex].Subject}
        ({announcement[currentIndex].DateTime.split('T')[0].split('-').join('/')})
      </span>

      <MdArrowForwardIos className='mt-1' />

    </div>
  )
}

export default Announcement
