import { useData } from '../../utils/context';

// --- images ---
import light from '../../img/light.png';
import dark from '../../img/dark.png';

const ToggleTheme = () => {
  const { isDarkMode, setIsDarkMode } = useData();
  
  return (
    <img
      className='w-[30px]'
      onClick={() => setIsDarkMode(!isDarkMode)}
      src={isDarkMode ? light : dark}
      alt=''
      loading='lazy'
    />
  )
}

export default ToggleTheme;
