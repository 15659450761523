import styled, { css } from "styled-components";
import { RWD_XL, RWD_LG, RWD_MD, RWD_SM } from "../../../constants/rwd";
import { BsStarFill } from "react-icons/bs";
import { useState, useEffect } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectCoverflow, Pagination, Navigation, Autoplay } from "swiper";
import { getLanguage } from "../../../v2/services/common";
import parse from "html-react-parser";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 7.8125vw 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 81.88vw;
  gap: 20px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: ${(props) => props.theme.colors.White};
  padding: clamp(80px, 8.333vw, 160px) 0 clamp(30px, 2.604167vw, 50px) 0;
  width: 81.88vw;
  text-align: center;
`;

const Title = styled.h2`
  margin: 0;
  font-size: clamp(30px, 2.0833vw, 40px);
  font-weight: 500;

  ${RWD_MD(css`
    font-size: clamp(20px, 4.557vw, 35px);
  `)};
`;

const SubTitle = styled.h3`
  color: ${(props) => props.theme.colors.Green};
  margin: 0;
  font-size: clamp(22px, 1.25vw, 24px);
  font-weight: 400;
  margin: 5px 0 10px 0;

  ${RWD_MD(css`
    font-size: clamp(16px, 2.6vw, 20px);
  `)};
`;

const Description = styled.h4`
  text-align: left;
  font-size: clamp(18px, 1.041666vw, 20px);
  text-align: center;
  color: ${(props) => props.theme.colors.Grey};
  width: 80%;

  ${RWD_MD(css`
    font-size: clamp(16px, 2.6vw, 20px);
    width: 100%;
  `)};
`;

const TestimonialCardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const TestimonialCard = styled.div`
  color: ${(props) => props.theme.colors.White};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;

  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  filter: drop-shadow(0px 4px 49px rgba(0, 7, 72, 0.12));
  backdrop-filter: blur(12.5px);
  padding: 20px;
  ${RWD_SM(css`
    flex-direction: column;
  `)};
`;

const TestimonialCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`;

const TestimonialContent = styled.div`
  /* font-style: italic; */
  /* height: 100%; */

  font-size: clamp(14px, 0.9375vw, 18px);
  text-align: left;
  display: flex;
  position: relative;
  padding-top: clamp(
    calc(30px / 2 + 5px),
    calc(2.604167vw / 2 + 5px),
    calc(50px / 2 + 5px)
  );

  span {
    font-size: clamp(30px, 2.604167vw, 50px);
    line-height: 0;
    position: absolute;
    top: clamp(calc(30px / 2), calc(2.604167vw / 2), calc(50px / 2));
  }

  span:last-child {
    align-self: flex-end;
    bottom: clamp(-30px, -2.604167vw, -50px);
    right: 0;
  }
`;

const TestimonialUser = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TestimonialUserImage = styled.div`
  width: 20%;
  display: inline-flex;
  min-width: 180px;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  /* ${RWD_MD(css`
    width: 100%;
  `)}; */
`;

const TestimonialEvaluate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
`;

const TestimonialUserName = styled.div`
  font-size: clamp(14px, 1.041667vw, 20px);
  text-align: left;
`;

const TestimonialsStarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const TestimonialsStar = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.colors.Green};
`;

const SlideNum = styled.div`
  display: flex;

  right: 20px;
  justify-content: flex-end;
  align-items: last baseline;
  gap: 5px;
  color: ${(props) => props.theme.colors.Grey};
  font-family: Montserrat;
  span {
    font-size: 20px;
  }
`;

const SlideCurrentNum = styled.div`
  font-size: clamp(30px, 2.34375vw, 45px);
  color: ${(props) => props.theme.colors.White};
`;

const SlideTotalNum = styled.div`
  font-size: clamp(16px, 1.041667vw, 20px);
`;

// const TestimonialsBox = styled.div`
//   border: 1px solid red;
//   width: 100%;
// `;

const Testimonials = ({ windowSize }) => {
  const [swiperIndex, setSwiperIndex] = useState(1);
  const [swiperView, setSwiperView] = useState(2);
  const titleData = [
    {
      main: [
        {
          ch: "用戶見證",
          en: "Testimonials",
        },
      ],
      sub: [
        {
          ch: "真實用戶見證，見證我們的成功",
          en: "Real User Testimonials, Witness Our Success",
        },
      ],
      content: [
        {
          ch: "我們的加密貨幣自動交易機器人幫助全球數千名用戶實現了投資收益的最大化。<br />以下是一些用戶的真實體驗和成功故事，他們的見證展示了我們產品的卓越性能和優秀服務。<br />這些聲音代表了他們對我們產品的信任和滿意度，也證明了我們在市場上的領先地位。",
          en: "Our cryptocurrency automated trading robot has helped thousands of users worldwide maximize their investment returns.<br />Below are some real experiences and success stories from our users.<br />Their testimonials showcase the outstanding performance and excellent service of our product, representing their trust and satisfaction. These voices are a testament to our leading position in the market.",
        },
      ],
    },
  ];
  const userData = [
    {
      id: 1,
      name: "Jason",
      src: "https://randomuser.me/api/portraits/men/80.jpg",
      content: [
        {
          ch: "使用這款交易機器人後，我的投資回報率顯著提高，讓我能夠在市場波動中依然獲利。",
          en: "Since using this trading robot, my investment returns have significantly increased, allowing me to profit even during market volatility.",
        },
      ],
    },
    {
      id: 2,
      name: "Emily",
      src: "https://randomuser.me/api/portraits/women/90.jpg",
      content: [
        {
          ch: "簡單易用的界面和強大的功能讓我能夠輕鬆管理帳戶，省時又高效。",
          en: "The simple and user-friendly interface, combined with powerful features, allows me to easily manage my accounts, saving time and increasing efficiency.",
        },
      ],
    },
    {
      id: 3,
      name: "Michael",
      src: "https://randomuser.me/api/portraits/men/81.jpg",
      content: [
        {
          ch: "全天候自動交易讓我再也不用熬夜盯盤，輕鬆享受被動收入的快感。",
          en: "24/7 automated trading means I no longer have to stay up all night monitoring the market. I can easily enjoy the benefits of passive income.",
        },
      ],
    },
    {
      id: 4,
      name: "David",
      src: "https://randomuser.me/api/portraits/men/85.jpg",
      content: [
        {
          ch: "交易記錄清晰明了，讓我放心使用。",
          en: "The clear transaction records give me peace of mind.",
        },
      ],
    },
    {
      id: 5,
      name: "Sophia",
      src: "https://randomuser.me/api/portraits/women/91.jpg",
      content: [
        {
          ch: "團隊非常專業，隨時為我解決問題，讓我感受到真正的服務。",
          en: "The team is very professional and always ready to solve my problems, making me feel truly supported.",
        },
      ],
    },
  ];

  useEffect(() => {
    if (windowSize[0] <= "1024") {
      setSwiperView(2);
      if (windowSize[0] <= "768") {
        setSwiperView(1);
      }
    } else {
      setSwiperView(2);
    }
  }, [windowSize]);

  const templateHandler = (data) => {
    if (getLanguage()?.toLocaleLowerCase() === "ch") {
      return parse(data.ch);
    } else {
      return parse(data.en);
    }
  };
  return (
    <Wrapper>
      <TitleWrapper>
        <Title data-aos="fade-up" data-aos-duration="1000">
          {titleData[0].main[0][getLanguage()?.toLocaleLowerCase()]}
        </Title>
        <SubTitle data-aos="fade-up" data-aos-duration="1000">
          {titleData[0].sub[0][getLanguage()?.toLocaleLowerCase()]}
        </SubTitle>
        <Description data-aos="fade-up" data-aos-duration="1000">
          {templateHandler(titleData[0].content[0])}
        </Description>
      </TitleWrapper>

      <Container data-aos="fade-up" data-aos-duration="1000">
        <Swiper
          spaceBetween={30}
          slidesPerView={swiperView}
          loop={true}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          modules={[Pagination, Autoplay]}
          onRealIndexChange={(swiper) => setSwiperIndex(swiper.realIndex)}
        >
          {userData.map((user) => (
            <SwiperSlide key={user.id}>
              <TestimonialCard>
                <TestimonialUserImage>
                  <img src={user.src} alt={user.name} />
                </TestimonialUserImage>
                <TestimonialCardContainer>
                  <TestimonialContent>
                    <span>“</span>
                    <p>
                      {user.content[0]?.[getLanguage()?.toLocaleLowerCase()]}
                    </p>
                    <span>”</span>
                  </TestimonialContent>

                  <TestimonialUser>
                    <TestimonialUserName>{user.name}</TestimonialUserName>
                    <TestimonialEvaluate>
                      <TestimonialsStarWrapper>
                        {Array.from({ length: 5 }, (num, i) => (
                          <TestimonialsStar key={i}>
                            <BsStarFill />
                          </TestimonialsStar>
                        ))}
                      </TestimonialsStarWrapper>
                    </TestimonialEvaluate>
                  </TestimonialUser>
                </TestimonialCardContainer>
              </TestimonialCard>
            </SwiperSlide>
          ))}
        </Swiper>
        {/* <SlideNum>
          <SlideCurrentNum>0{swiperIndex + 1}</SlideCurrentNum>
          <span>/</span>
          <SlideTotalNum>0{userData.length}</SlideTotalNum>
        </SlideNum> */}
      </Container>
    </Wrapper>
  );
};

export default Testimonials;
