import styled from "styled-components";
import { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";

import Lang from "../../../global/Lang";
import Icons from "../../../global/icons";
import { useEffect, useState } from "react";

import {
  getBinanceBalance,
  getBybitBalance,
  getTestBybitBalance,
  getIgBalance,
  refreshIgToken,
} from "../../../services/balanceApi";
import { useSelector } from "react-redux";
import { selectAccount } from "../../../slices/accountSlice";

// sweetAlert
import { ErrorMySwal } from "../../../global/MySwal";

import { driver } from "driver.js";
import "driver.js/dist/driver.css";

import { useLocation } from "react-router-dom";
import JoyrideComponent from "../../../global/JoyrideComponent";

import Loading from "../../../components/Loading/Loading";

import { useGetAlgoQuery } from "../../../services/algoApi";
import { LangString } from "../../../utils";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const Title = styled.div`
  font-size: min(max(1.25vw, 22px), 24px);
  color: #fcfcfc;
  padding-bottom: 20px;
  align-self: flex-start;
  text-transform: capitalize;

  ${RWD_MD(css`
    font-size: min(max(1.0416vw, 18px), 20px);
  `)};
`;

const SubTitleWrapper = styled.div`
  display: flex;
  border: 1px solid red;
  width: 100%;
  justify-content: space-around;
`;

const SubTitle = styled.div`
  font-size: min(max(1.875vw, 34px), 36px);
  font-weight: bold;
  color: #fcfcfc;
  padding-bottom: 10px;
  text-align: left;
  width: 100%;
  display: flex;
  align-items: center;

  ${RWD_MD(css`
    font-size: min(max(1.145vw, 20px), 22px);
  `)};
`;

const TooltipWrapper = styled.div`
  position: relative;
  width: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 3px;
  cursor: help;

  &:hover span {
    visibility: visible;
  }
`;

const TooltipContainer = styled.div`
  width: 1.5vw;
  height: 1.5vw;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #c0c0c0;
  padding-top: 1px;
  display: flex;

  svg {
    width: 1.5vw;
    height: 1.5vw;
  }

  & path {
    stroke: #c0c0c0;
  }
`;

const TooltipText = styled.span`
  visibility: hidden;
  width: auto;
  background-color: #54587b;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 0.833vw;
  white-space: nowrap;
  position: absolute;
  left: calc(1.5vw + 15px);
  z-index: 1;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;

  ${RWD_LG(css`
    flex-direction: column;
    align-items: center;
  `)};
`;

const TargetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 30%;
`;

const TargetContainer = styled.div`
  border: 1px solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 12vw;
  border-radius: 14.57px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
  border-color: ${(props) =>
    props.selected ? "#0AECD1" : "rgba(255, 255, 255, 0.15)"};
  opacity: ${(props) => (props.selected ? 1 : 0.8)};
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );

  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

const TargetIcon = styled.div`
  width: 4.166vw;
  height: 4.166vw;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 4.166vw;
    height: 4.166vw;
  }

  & path {
    fill: ${(props) => (props.selected ? "#0AECD1" : "#fcfcfc")};
  }
`;

const TargeText = styled.div`
  font-size: 1.145vw;
  padding-top: 12px;
  color: ${(props) => (props.selected ? "#0AECD1" : "#fcfcfc")};
`;

const BalanceWrapper = styled.div`
  padding-bottom: 20px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* padding-top: 30px; */
`;

const BalanceContainer = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  border-radius: 14.57px;
  /* width: 18.75vw;
  height: 9.375vw; */
  width: 100%;
  padding: 2.694vw 0;
  height: 250px;
  position: relative;

  ${RWD_XL(css`
    width: 100%;
  `)};

  ${RWD_LG(css`
    width: 400px;
    height: 200px;
  `)};

  ${RWD_MD(css`
    width: min(max(60%, 320px), 600px);
  `)};

  ${RWD_SM(css`
    width: 80%;
    height: 150px;
  `)};
`;

const BalanceText = styled.div`
  color: #fcfcfc;
  font-size: min(max(1.875vw, 34px), 36px);
  font-family: Montserrat;
  font-weight: bold;

  span {
    font-size: min(max(0.833vw, 14px), 16px);
    position: absolute;
    bottom: 15px;
    right: 20px;
  }

  ${RWD_SM(css`
    font-size: min(max(1.5625vw, 28px), 30px);
  `)};
`;

const BalanceBoxContainer = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  /* border: 1px solid red; */
  width: 85%;
  justify-content: space-between;
`;

const BalanceTitle = styled.div`
  color: #0aecd1;
  font-size: min(max(0.9375vw, 16px), 18px);
  padding-right: 5px;
  /* padding: 6px 0; */

  ${RWD_SM(css`
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const AmountWrapper = styled.div`
  color: #fcfcfc;
  display: flex;
  width: auto;
  justify-content: center;
  width: min(max(30%, 450px), 600px);

  ${RWD_XL(css`
    width: 40%;
  `)};

  ${RWD_LG(css`
    width: 80%;
  `)};

  ${RWD_SM(css`
    width: 100%;
  `)};
`;

const AmountContainer = styled.div`
  width: 100%;
`;

const AmountTitle = styled.div`
  /* font-size: 1.0416vw; */
  font-size: min(max(1.145vw, 20px), 22px);
  padding-bottom: 10px;
  /* padding: 10px 0; */

  &:last-of-type {
    padding-top: 0px;
  }

  ${RWD_MD(css`
    font-size: min(max(1.0416vw, 18px), 20px);
  `)};
`;

const AmountInput = styled.input`
  font-size: min(max(1.0416vw, 18px), 20px);
  text-align: center;
  /* padding: 1.0416vw 1.5625vw; */
  padding: 10px 20px;
  border: 1px solid;
  border-radius: 8px;
  width: 100%;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  border-color: ${(props) =>
    props.error ? "#F05D2F" : "rgba(255, 255, 255, 0.15)"};

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 16px), 18px);
  `)};
`;

const NickNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  color: #fcfcfc;
  width: auto;
  min-width: 30%;

  ${RWD_XL(css`
    width: 40%;
  `)};

  ${RWD_LG(css`
    width: 80%;
    padding-top: 20px;
  `)};

  ${RWD_SM(css`
    width: 100%;
  `)};
`;

const NickNameInput = styled.input`
  font-size: min(max(1.0416vw, 18px), 20px);
  text-align: center;
  /* padding: 1.0416vw 1.5625vw; */
  padding: 10px 20px;
  border: 1px solid;
  border-radius: 8px;
  width: auto;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  border-color: rgba(255, 255, 255, 0.15);
  color: #fcfcfc;

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 16px), 18px);
  `)};
`;

const ErrorMsgWrapper = styled.div`
  padding-top: 5px;
  color: #f05d2f;
  font-size: min(max(0.833vw, 14px), 16px);
  height: 40px;

  ${RWD_SM(css`
    padding: 10px 0;
  `)};
`;

const InputBox = styled.div``;

const StepThree = ({
  amount,
  setAmount,
  setBalance,
  balance,
  target,
  setTarget,
  nickname,
  setNickname,
  exchangeId,
  setActiveStep,
}) => {
  const { search } = useLocation();

  const [balanceReady, setBalanceReady] = useState(false);

  // 取得algo
  const {
    data: algoResponse,
    isSuccess: algoResponseIsSuccess,
    isError: algoResponseIsError,
    error: algoResponseError,
    refetch,
    isLoading,
    isFetching,
  } = useGetAlgoQuery();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const tutorial = query.get("tutorial");
    if (tutorial === "algo") {
      setJoyrideIsRun(true);
    }
  }, [search]);

  const handleJoyrideCallback = () => {
    setActiveStep(3);
  };
  const [joyrideIsRun, setJoyrideIsRun] = useState(false);
  const joyrideState = [
    {
      target: ".createAlgo_InputAmount",
      content: (
        <Lang
          datakey="algo_tutorial_3-1"
          defaultText="Please enter the amount you wish to use to execute the Algo. The number must not be less than 500."
        />
      ),
      placement: "right",
      disableBeacon: true,
      disableOverlayClose: true,
    },
    {
      target: ".createAlgo_InputName",
      content: (
        <Lang
          datakey="algo_tutorial_3-2"
          defaultText="Please enter a name for this Algo here."
        />
      ),
      placement: "right",
      disableBeacon: true,
      disableOverlayClose: true,
      callbackAction: true,
      function: handleJoyrideCallback,
    },
  ];

  const apiKeyData = useSelector(selectAccount).accountApikey;

  //取得錢包餘額
  useEffect(() => {
    const data = {
      apikey: apiKeyData.filter(
        ({ ExchangeId }) => ExchangeId === exchangeId
      )[0]?.ApiKey,
      secretkey: apiKeyData.filter(
        ({ ExchangeId }) => ExchangeId === exchangeId
      )[0]?.SecretKey,
      password: apiKeyData.filter(
        ({ ExchangeId }) => ExchangeId === exchangeId
      )[0]?.Password,
      account: apiKeyData.filter(
        ({ ExchangeId }) => ExchangeId === exchangeId
      )[0]?.Account,
    };

    switch (exchangeId) {
      case 1: // binance
        getBinanceBalance(data)
          .then((res) => {
            if (res.status === 200) {
              const USDT = res.data.filter(({ asset }) => asset === "USDT");
              setBalance(USDT[0]?.balance);
              setBalanceReady(true);
            } else {
              ErrorMySwal({
                title: res.data.msg,
              });
            }
          })
          .catch((error) => {
            ErrorMySwal({
              title: error.message,
            });
          });
        break;

      case 2: // bybit
        getBybitBalance(data)
          .then((res) => {
            if (res.data.retMsg === "OK") {
              setBalance(res.data.result?.list[0]?.coin[0]?.walletBalance);
              setBalanceReady(true);
            } else {
              // 暫時關閉alert 新手教學
              // ErrorMySwal({
              //   title: res.data.retMsg,
              // });
            }
          })
          .catch((error) => {
            // 暫時關閉alert 新手教學
            // ErrorMySwal({
            //   title: error.message,
            // });
          });
        break;

      case 3: // test bybit
        getTestBybitBalance(data)
          .then((res) => {
            if (res.data.retMsg === "OK") {
              setBalance(res.data.result?.list[0]?.coin[0]?.walletBalance);
              setBalanceReady(true);
            } else {
              ErrorMySwal({
                title: res.data.retMsg,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            ErrorMySwal({
              title: error.message,
            });
          });
        break;

      case 4: // ig
        getIgBalance(data)
          .then((res) => {
            if (res?.status === 200) {
              setBalance(res.data.accounts[0]?.balance?.available);
              setBalanceReady(true);
            }
          })
          .catch((error) => {
            if (error.response.status === 401) {
              console.log("刷新igtoken");
              refreshIgToken(data).then((res) => {
                if (res.status === 200) {
                  console.log(res.data);
                  setBalanceReady(true);
                  setBalance(res.data.accountInfo.available);
                }
              });
            } else {
              ErrorMySwal({
                title: error.message,
              });
            }
          });
        break;
    }
  }, [exchangeId]);

  const [error, setError] = useState("");
  useEffect(() => {
    if (Number(amount) < 500 && amount !== "") {
      setError("minimum");
    } else {
      if (
        Number(amount) >
        handleFreeBalance(exchangeId, Math.floor(balance * 10000) / 10000)
      ) {
        setError("insufficient");
      } else {
        setError("");
      }
    }
  }, [amount]);

  const handleFreeBalance = (exchangeId, walletBalance) => {
    const sum = algoResponse?.Algos.filter(
      (data) => data.Status === "running" && data.ExchangeId === exchangeId
    )
      .map((item) => item.CurrentAmount)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    return (walletBalance - sum).toFixed(4) > 0
      ? (walletBalance - sum).toFixed(4)
      : Number(0).toFixed(4);
  };

  const enterAmount = LangString(
    "algo_enter_number",
    "Please enter a number of"
  );

  return (
    <Wrapper>
      {!balanceReady && <Loading />}

      <JoyrideComponent
        joyrideState={joyrideState}
        run={joyrideIsRun}
        joyrideType={"algo"}
      />
      <Title>
        <Lang
          datakey="addalgo_set_amount_title"
          defaultText="Set Your AlgoTrade Amount"
        />
      </Title>

      <Container>
        {/* <TargetWrapper>
          <SubTitle>
            <Lang
              datakey="addalgo_select_target"
              defaultText="Select Your Algo Asset"
            />
            <TooltipWrapper>
              <TooltipContainer>
                <Icons.Tooltip />
              </TooltipContainer>
              <TooltipText>
                <Lang
                  datakey="addalgo_select_target"
                  defaultText="Select Your Algo Asset"
                  toolTip={true}
                />
              </TooltipText>
            </TooltipWrapper>
          </SubTitle>

          <TargetContainer
            selected={target === "BTC"}
            onClick={() => setTarget("BTC")}
          >
            <TargetIcon selected={target === "BTC"}>
              <Icons.BTC_STROKE />
            </TargetIcon>
            <TargeText selected={target === "BTC"}>Bitcoin</TargeText>
          </TargetContainer>
          <TargetContainer
            selected={target === "ETH"}
            onClick={() => setTarget("ETH")}
          >
            <TargetIcon selected={target === "ETH"}>
              <Icons.ETH_STROKE />
            </TargetIcon>
            <TargeText selected={target === "ETH"}>Ethereum </TargeText>
          </TargetContainer>
        </TargetWrapper> */}

        <AmountWrapper>
          <AmountContainer>
            <SubTitle>
              {/* 設定金額 */}
              <Lang
                datakey="addalgo_title_amount"
                defaultText="Set Your AlgoTrade Amount"
              />
            </SubTitle>
            <BalanceWrapper>
              <BalanceContainer>
                <BalanceBoxContainer>
                  <BalanceTitle>
                    <Lang
                      datakey="addalgo_your_balance"
                      defaultText="your balance"
                    />
                  </BalanceTitle>
                  <BalanceText>
                    $ {(Math.floor(balance * 10000) / 10000).toFixed(4) ?? 0}
                    <span>USDT</span>
                  </BalanceText>
                </BalanceBoxContainer>

                <BalanceBoxContainer>
                  <BalanceTitle>
                    {" "}
                    <Lang
                      datakey="addalgo_free_balance"
                      defaultText="free balance"
                    />
                  </BalanceTitle>
                  <BalanceText>
                    ${" "}
                    {handleFreeBalance(
                      exchangeId,
                      Math.floor(balance * 10000) / 10000
                    )}
                    <span>USDT</span>
                  </BalanceText>
                </BalanceBoxContainer>
              </BalanceContainer>
            </BalanceWrapper>

            <InputBox className="createAlgo_InputAmount">
              <AmountTitle>
                <Lang datakey="input_amount" defaultText="Input Amount" />
              </AmountTitle>
              <AmountInput
                error={error}
                placeholder={
                  `${enterAmount} 500 - ${handleFreeBalance(
                    exchangeId,
                    Math.floor(balance * 10000) / 10000
                  )}.`
                  //   `500 - ${(
                  //   Math.floor(balance * 10000) / 10000
                  // ).toFixed(4)}`
                }
                onChange={(e) =>
                  setAmount(
                    e.target.value
                      .replace(/[^0-9.]/g, "")
                      .replace(/(\..*?)\..*/g, "$1")
                      .replace(/^0[^.]/, "0")
                      .replace(/^(\-)*(\d+)\.(\d\d\d\d\d\d).*$/, "$1$2.$3")
                  )
                }
                value={amount}
              />
            </InputBox>
            <ErrorMsgWrapper>
              {(() => {
                switch (error) {
                  case "minimum":
                    return (
                      <Lang
                        datakey="addalgo_error_minimum"
                        defaultText="Minimum transaction amount is 500. Please adjust."
                      />
                    );

                  case "insufficient":
                    return (
                      <Lang
                        datakey="addalgo_error_insufficient"
                        defaultText="Insufficient wallet balance."
                      />
                    );

                  default:
                    return;
                }
              })()}
            </ErrorMsgWrapper>
          </AmountContainer>
        </AmountWrapper>
        <NickNameWrapper className="createAlgo_InputName">
          <SubTitle>
            <Lang
              datakey="addalgo_title_name"
              defaultText="Customize The Algo Name"
            />
          </SubTitle>
          <AmountTitle>
            <Lang datakey="algo_name_algo" defaultText="Name Your Algo" />
          </AmountTitle>
          <NickNameInput
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
          ></NickNameInput>
        </NickNameWrapper>
      </Container>
    </Wrapper>
  );
};

export default StepThree;
