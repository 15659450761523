import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { API_GetAccountVerifyInfo } from '../../../utils/api';
import { Button } from '@mui/material';

const InfoItem = styled.div`
  padding: .3rem 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-align: left;

  > *:first-child {
    width: 8rem;
    color: #888;
  }
  > *:last-child {
    flex: 1;
  }

  img {
    width: 100%;
    object-fit: contain;
  }
  input {
    border: 1px solid #ccc;
    padding: .2rem .5rem;
    border-radius: 4px;
  }
`;

const VerifyCode = styled.div`
  display: inline-block;
  padding-right: 1rem;
`;

const InfoAction = styled.div`
  padding-top: 1rem;
  > *:last-child {
    margin-left: 1rem;
  }
`;

const TelegramInfo = ({ AccountId }) => {
  const handleGetInfo = async () => {
    const body = {
      AccountId,
      Type: "Telegram",
    };
    const res = await API_GetAccountVerifyInfo(body);
    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return UseSwal(res.Message);
    setData(res?.Info);
  };

  const getTelegramStatus = () => {
    if (!data) return;
    if (data.TelegramId === null) return "未申請";
    if (data.VerifyStatus === 0) return "待驗證";
    if (data.VerifyStatus === 1) return "驗證通過";
    return "";
  };

  const [data, setData] = useState();

  useEffect(() => {
    handleGetInfo();
  }, [AccountId]);

  return (
    <div>
      <div>
        <InfoItem>
          <div>帳號(信箱)</div>
          <div>{AccountId}</div>
        </InfoItem>
        <InfoItem>
          <div>Telegram ID</div>
          <div>
            {
              data?.TelegramId
              ??
              <>
                <input />
                <Button>設定</Button>
              </>
            }
          </div>
        </InfoItem>
        <InfoItem>
          <div>目前驗證狀態</div>
          <div>{getTelegramStatus()}</div>
        </InfoItem>
        <InfoItem>
          <div>驗證碼</div>
          <div>
            <VerifyCode>{data?.VerifyCode ?? "無"}</VerifyCode>
            <Button variant="contained" disabled={data?.VerifyStatus !== 0}>發送驗證碼</Button>
          </div>
        </InfoItem>
      </div>
    </div>
  )
}

export default TelegramInfo
