import { useEffect, useState } from 'react';
import styled from 'styled-components'
import { API_GetRobotDataList } from '../../../utils/api';
import  EditGridRobot from './EditGridRobot';

//#region 
const Wrapper = styled.div`
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
    font-family: Arial, sans-serif;
    position: absolute;
    right: 0;
    left: 0;
`;

const GridRobotContainer = styled.div`
    margin: 40px;
    padding: 0px 50px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-collapse: collapse;
    border-radius: 20px;
    overflow-x: auto;
`;

const Robot_List_Title = styled.div`
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    padding: 20px 0;
    font-size: 30px;
    font-weight: bold;
    color: #000000;
`
const GridRobotListContainer = styled.div``

const GridRobotTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    padding: 10px;

`
const GridRobotThead = styled.thead``

const TableTr = styled.tr`
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
`;

const TheadTh = styled.th`
    background-color: #f0f0f0;
`;

const TableTd = styled.td`
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
`

const GridRobotTbody = styled.tbody`
    margin: 10px 0;
`

const BaseStatusDiv = styled.div`
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  transition: background-color 0.3s, opacity 0.3s;
  background-color: #1467ff;
  color: white;
  text-align: center;
`;

const StatusDiv = styled(BaseStatusDiv)``;

const CloseStatusDiv = styled(BaseStatusDiv)`
background-color: #e72a4d;
`;

const RunningStatusDiv = styled(BaseStatusDiv)`
  opacity: 0.5;
`;


const EditButton = styled.button`
    border: none;
    padding: 10px 20px;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, opacity 0.3s;
    background-color: #1467ff;
    color: white;
` ;

//#endregion

const ManageGridRobots = () => {

    const [gridRobotDataList, setGridRobotDataList] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [currentRobot, setCurrentRobot] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
          const data = await API_GetRobotDataList();
          if (data) {
            setGridRobotDataList(data.GridRobotDatas);
          }
        };
        fetchData();
    }, []);

    const handleEditClick = (robot) => {
        setCurrentRobot(robot); // 设置当前要编辑的robot
        setIsEditing(true); // 显示编辑窗口
    };

    // 关闭编辑窗口的函数
    const closeEditModal = () => {
        setIsEditing(false);
        setCurrentRobot(null); // 也清除当前robot状态
    };
      
    const handleDataUpdate = (updatedRobotParams) => {
        const robotIndex = gridRobotDataList.findIndex(robot => robot.GridRobotId === updatedRobotParams.GridRobotId);
        if (robotIndex !== -1){
            const newGridRobotDataList = [...gridRobotDataList];
            newGridRobotDataList[robotIndex] = updatedRobotParams;
            setGridRobotDataList(newGridRobotDataList);
        }
    };



    const GridRobotTheadList = [
        { title : "Robot ID" },
        { title : "Algo ID"},
        { title : "Robot Types" },
        { title : "Target" },
        { title : "Price range high" },
        { title : "price range low" },
        { title : "Count Numbers" },
        { title : "Take Profit Prices" },
        { title : "Stop Loss Prices" },
        { title : "Status" },
        { title : "Edit"}
    ]

    return(
        <Wrapper>
            <GridRobotContainer>
                <Robot_List_Title>Grid Robot List</Robot_List_Title>
                <GridRobotListContainer>
                    <GridRobotTable>
                        <GridRobotThead>
                            <TableTr>
                                {GridRobotTheadList.map((item, index) => (
                                    <TheadTh key={index}>{item.title}</TheadTh>
                                ))}
                            </TableTr>
                        </GridRobotThead>
                        <GridRobotTbody>
                            {   gridRobotDataList && gridRobotDataList.length > 0 ? (
                                    gridRobotDataList.map((robot, index) => (
                                    <TableTr key={index}>
                                        <TableTd>{robot.GridRobotId}</TableTd>
                                        <TableTd>{robot.AIRobotId}</TableTd>
                                        <TableTd>{robot.GridRobotParams.SideStatus}</TableTd>
                                        <TableTd>{robot.GridRobotParams.TargetKey}</TableTd>
                                        <TableTd>{robot.GridRobotParams.EntryPrice}</TableTd>
                                        <TableTd>{robot.GridRobotParams.ExitPrice}</TableTd>
                                        <TableTd>{robot.GridRobotParams.GridCount}</TableTd>
                                        <TableTd>{robot.GridRobotParams.TakeProfitPrice == 0 || robot.GridRobotParams.TakeProfitPrice === null ? "n/a" : robot.GridRobotParams.TakeProfitPrice}</TableTd>
                                        <TableTd>{robot.GridRobotParams.StopLossPrice == 0 || robot.GridRobotParams.StopLossPrice === null? "n/a" : robot.GridRobotParams.StopLossPrice}</TableTd>
                                        <TableTd style={{ paddingLeft: '0px' }}>
                                            {  
                                                robot.GridRobotParams.GridRobotStatus === 1 ? 
                                                    ( <StatusDiv>Open</StatusDiv>) : 
                                                    robot.GridRobotParams.GridRobotStatus === 2 ? (<CloseStatusDiv>Close</CloseStatusDiv>) 
                                                    : (<RunningStatusDiv>Runnimg</RunningStatusDiv>)
                                            }
                                        </TableTd>
                                        <TableTd style={{ paddingLeft: '0px' }}>
                                            {
                                                robot.GridRobotParams.GridRobotStatus === 3 
                                                ?(<EditButton onClick={() => handleEditClick(robot)}>Detail</EditButton> ) 
                                                :<EditButton onClick={() => handleEditClick(robot)}>Edit</EditButton>
                                            }
                                        </TableTd>
                                    </TableTr>
                                    ) )):
                                    (    
                                    <TableTr>
                                        <TableTd colSpan="全部列数">No GridRobot Data</TableTd>
                                    </TableTr>)
                            }
                        </GridRobotTbody>
                    </GridRobotTable>
                </GridRobotListContainer>
            </GridRobotContainer>
            {isEditing && <EditGridRobot robot={currentRobot} close={closeEditModal} onDataUpdate={handleDataUpdate}/>}
        </Wrapper>
    );
}

export default ManageGridRobots;