import styled from "styled-components";
import { useState, useEffect } from "react";
import { SlArrowDown } from "react-icons/sl";

import { useSpring, animated } from "react-spring";

import { css } from "styled-components";
import { RWD_LG } from "../../../constants/rwd";

// import { useGetQaQuery } from "../../../servives/qaApi";

// AOS
import AOS from "aos";
import "aos/dist/aos.css";

const FaqTab = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  border-bottom: 1px solid rgba(252, 252, 252, 0.2);
`;

const FaqTabItem = styled.li`
  margin-right: 3vw;
  cursor: pointer;

  padding-bottom: 5px;
  border-bottom: 3px solid #48d6d2;
  border-color: ${(props) =>
    props.activeTab === props.id ? "#48d6d2" : "transparent"};
  color: ${(props) =>
    props.activeTab === props.id ? "#48d6d2" : "rgba(252, 252, 252, 0.7)"};

  font-size: clamp(12px, 0.8vw, 18px);

  ${RWD_LG(css`
    margin-right: 5vw;
  `)};
`;

const FaqSlideWrapper = styled.div`
  min-height: 40vh;
`;

const FaqContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const FaqTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 2vh;

  ${RWD_LG(css`
    align-items: center;
  `)};
`;

const FaqTitle = styled.h2`
  margin: 0;
  color: white;
`;

const FaqSubTitle = styled.h4`
  margin: 0;
  text-align: left;
  color: white;
  padding: 1vh 0;

  ${RWD_LG(css`
    text-align: center;
  `)};
`;

const FaqSlideContainer = styled.div`
  border: 1px solid royalblue;
  text-align: left;
  padding: 1.5vw 1vw;
  color: white;
  border-radius: 15px;
  margin-bottom: 2vh;
  cursor: pointer;
  transition: all 0.3s;
  background-size: 1px 200px;
  border-radius: 10px;
  transition: all 0.5s;

  &:hover {
    border-color: #48d6d2;
  }

  ${RWD_LG(css`
    padding: 3vw 4vw;
  `)};
`;

const FaqSlideTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: clamp(14px, 0.8vw, 20px);

  ${RWD_LG(css`
    align-items: flex-start;
  `)};
`;

const FaqSlideIcon = styled.div`
  transition: all 0.5s;
  transform-origin: center;
  transform: ${(props) => (props.isOpen ? "scaleY(-1)" : " scaleY(1)")};
  color: ${(props) => (props.isOpen ? "#48d6d2" : " white")};
  padding-left: 4vw;
`;

const FaqSlideContent = styled.div`
  /* padding-top: 2vh; */
  transition: all 0.5s;
  padding: ${(props) => (props.isOpen ? "2vh 0" : " 0")};
  height: ${(props) => (props.isOpen ? "auto" : "0")};
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  font-size: clamp(13px, 0.8vw, 18px);
`;

const Tab = ({ language }) => {
  useEffect(() => {
    AOS.init();
  }, []);

  const [flip, set] = useState(false);
  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    reset: true,
    // onRest: () => set(!flip),
  });
  const [tabs, setTabs] = useState([
    {
      value: "General",
      id: 1,
    },
    {
      value: "Token Sales",
      id: 2,
    },
    {
      value: "Legal",
      id: 3,
    },
    {
      value: "Road Map",
      id: 4,
    },
  ]);

  const [enData, setEnData] = useState([
    {
      id: 1,
      dataList: [
        {
          num: 1,
          title: "What Is ICO crypto?",
          content:
            " A protocol that facilitates cost-efficient and secure atomic transactions by utilizing a wide range of protocols and performing argument validation and execution verification.",
          isOpen: true,
        },
        {
          num: 2,
          title: "What Cryptocurrencies Can I Use To Purchase?",
          content:
            " A protocol that facilitates cost-efficient and secure atomic transactions by utilizing a wide range of protocols and performing argument validation and execution verification.",
          isOpen: false,
        },
        {
          num: 3,
          title: "What Can I Participate In The ICO Token Sale?",
          content:
            " A protocol that facilitates cost-efficient and secure atomic transactions by utilizing a wide range of protocols and performing argument validation and execution verification.",
          isOpen: false,
        },
        {
          num: 4,
          title: "What Do I Benefit From The ICO Token?",
          content:
            " A protocol that facilitates cost-efficient and secure atomic transactions by utilizing a wide range of protocols and performing argument validation and execution verification.",
          isOpen: false,
        },
      ],
    },
    {
      id: 2,
      dataList: [
        {
          num: 1,
          title: "What Is ICO crypto?",
          content:
            " A protocol that facilitates cost-efficient and secure atomic transactions by utilizing a wide range of protocols and performing argument validation and execution verification.",
          isOpen: true,
        },
        {
          num: 2,
          title: "What Cryptocurrencies Can I Use To Purchase?",
          content:
            " A protocol that facilitates cost-efficient and secure atomic transactions by utilizing a wide range of protocols and performing argument validation and execution verification.",
          isOpen: false,
        },
        {
          num: 3,
          title: "What Can I Participate In The ICO Token Sale?",
          content:
            " A protocol that facilitates cost-efficient and secure atomic transactions by utilizing a wide range of protocols and performing argument validation and execution verification.",
          isOpen: false,
        },
      ],
    },
    {
      id: 3,
      dataList: [
        {
          num: 1,
          title: "What Is ICO crypto?",
          content:
            " A protocol that facilitates cost-efficient and secure atomic transactions by utilizing a wide range of protocols and performing argument validation and execution verification.",
          isOpen: true,
        },
        {
          num: 2,
          title: "What Cryptocurrencies Can I Use To Purchase?",
          content:
            " A protocol that facilitates cost-efficient and secure atomic transactions by utilizing a wide range of protocols and performing argument validation and execution verification.",
          isOpen: false,
        },
      ],
    },
    {
      id: 4,
      dataList: [
        {
          num: 1,
          title: "What Is ICO crypto?",
          content:
            " A protocol that facilitates cost-efficient and secure atomic transactions by utilizing a wide range of protocols and performing argument validation and execution verification.",
          isOpen: true,
        },
        {
          num: 2,
          title: "What Cryptocurrencies Can I Use To Purchase?",
          content:
            " A protocol that facilitates cost-efficient and secure atomic transactions by utilizing a wide range of protocols and performing argument validation and execution verification.",
          isOpen: false,
        },
        {
          num: 3,
          title: "What Can I Participate In The ICO Token Sale?",
          content:
            " A protocol that facilitates cost-efficient and secure atomic transactions by utilizing a wide range of protocols and performing argument validation and execution verification.",
          isOpen: false,
        },
        {
          num: 4,
          title: "What Do I Benefit From The ICO Token?",
          content:
            " A protocol that facilitates cost-efficient and secure atomic transactions by utilizing a wide range of protocols and performing argument validation and execution verification.",
          isOpen: false,
        },
      ],
    },
  ]);

  const [chData, setChData] = useState([
    {
      id: 1,
      dataList: [
        {
          num: 1,
          title: "為什麼需要綁定API金鑰?",
          content:
            "系統之追蹤機器人以及投資機器人，是輔助您操盤的工具，因為需要實時獲取錢包內的訊息，所以需要綁定APIKey。",
          isOpen: true,
        },
        {
          num: 2,
          title: "提供APIKey是否有可能被提領?",
          content:
            "目前各大平台提供之APIKey均有權限的相關設定，可以親自關閉APIKey之提領功能。為避免不必要的糾紛，我們亦會於綁定APIKey時請客戶確認是否關閉權限。",
          isOpen: false,
        },
        {
          num: 3,
          title: "我要如何獲得分潤獎勵?",
          content:
            "基於利益共享原則，本平台提供分潤獎勵。用戶(推薦人)讓其他用戶(被推薦人)輸入推薦碼加入平台後，被推薦人若提升為Lv1，當被推薦人結算利潤時，推薦人會獲得相應的利潤分成。",
          isOpen: false,
        },
        {
          num: 4,
          title: "我要如何加入雲端挖礦?",
          content: "至雲端挖礦頁面點選加入即可。",
          isOpen: false,
        },
      ],
    },
    {
      id: 2,
      dataList: [
        {
          num: 1,
          title: "TXID是什麼? 我填錯TXID要怎麼辦?",
          content:
            "TXID為區塊鏈特有之轉帳ID，每筆轉帳都有其惟一的TXID，主要用作系統自動對帳。如若填錯TXID，系統會在對帳之後提示您【TXID異常】，此時您就可以再次填入TXID。",
          isOpen: true,
        },
        {
          num: 2,
          title: "我要更改返還地址要如何操作?",
          content:
            "返還地址為轉帳重要依據，不可任意更動，如需更動請來信 【service@avairobot.com】申請。申請格式：1.申請人帳號：2.申請人手機：3.原始地址：4.更換地址：5.更換地址申請時間：",
          isOpen: false,
        },
      ],
    },
    {
      id: 3,
      dataList: [
        {
          num: 1,
          title: "TXID是什麼? 我填錯TXID要怎麼辦?",
          content:
            "TXID為區塊鏈特有之轉帳ID，每筆轉帳都有其惟一的TXID，主要用作系統自動對帳。如若填錯TXID，系統會在對帳之後提示您【TXID異常】，此時您就可以再次填入TXID。",
          isOpen: true,
        },
        {
          num: 2,
          title: "我要更改返還地址要如何操作?",
          content:
            "返還地址為轉帳重要依據，不可任意更動，如需更動請來信 【service@avairobot.com】申請。申請格式： 1.申請人帳號：2.申請人手機：3.原始地址：4.更換地址：5.更換地址申請時間：",
          isOpen: false,
        },
      ],
    },
    {
      id: 4,
      dataList: [
        {
          num: 1,
          title: "為什麼需要綁定API金鑰?",
          content:
            "系統之追蹤機器人以及投資機器人，是輔助您操盤的工具，因為需要實時獲取錢包內的訊息，所以需要綁定APIKey。",
          isOpen: true,
        },
        {
          num: 2,
          title: "提供APIKey是否有可能被提領?",
          content:
            "目前各大平台提供之APIKey均有權限的相關設定，可以親自關閉APIKey之提領功能。為避免不必要的糾紛，我們亦會於綁定APIKey時請客戶確認是否關閉權限。",
          isOpen: false,
        },
        {
          num: 3,
          title: "我要如何獲得分潤獎勵?",
          content:
            "基於利益共享原則，本平台提供分潤獎勵。用戶(推薦人)讓其他用戶(被推薦人)輸入推薦碼加入平台後，被推薦人若提升為Lv1，當被推薦人結算利潤時，推薦人會獲得相應的利潤分成。",
          isOpen: false,
        },
        {
          num: 4,
          title: "我要如何加入雲端挖礦?",
          content: "至雲端挖礦頁面點選加入即可。",
          isOpen: false,
        },
      ],
    },
  ]);

  // const { data, isError, isSuccess } = useGetQaQuery();

  // useEffect(() => {
  //   if (isSuccess) {
  //     console.log(data);
  //   }
  // }, [data, isError]);

  const [activeTab, setActiveTab] = useState(1);

  const handleTab = (id) => {
    setActiveTab(id);
  };

  const toggelSlide = (num) => {
    if (language === "EN") {
      setEnData(
        enData.map((data) => {
          if (data.id === activeTab) {
            data.dataList.map((list) => {
              if (list.num === num) {
                list.isOpen = !list.isOpen;
              } else {
                list.isOpen = false;
              }
              return data;
            });

            return data;
          }

          return data;
        })
      );
    } else {
      setChData(
        chData.map((data) => {
          if (data.id === activeTab) {
            data.dataList.map((list) => {
              if (list.num === num) {
                list.isOpen = !list.isOpen;
              } else {
                list.isOpen = false;
              }
              return data;
            });

            return data;
          }

          return data;
        })
      );
    }
  };

  return (
    <FaqContent>
      <FaqTitleWrapper>
        <FaqTitle
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="2000"
        >
          Frequently asked questions
        </FaqTitle>
        <FaqSubTitle
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="2000"
          data-aos-delay="300"
        >
          Below we've provided a bit of ICO, ICO Token, cryptocurrencies, and
          few others.
        </FaqSubTitle>
      </FaqTitleWrapper>
      <FaqTab
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="2000"
        data-aos-delay="300"
      >
        {tabs.map((data) => (
          <FaqTabItem
            key={data.id}
            id={data.id}
            activeTab={activeTab}
            onClick={() => handleTab(data.id)}
          >
            {data.value}
          </FaqTabItem>
        ))}
      </FaqTab>
      <FaqSlideWrapper
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="2000"
        data-aos-delay="300"
      >
        {language === "EN"
          ? enData
              .filter((data) => data.id === activeTab)[0]
              .dataList.map((list) => (
                <FaqSlideContainer
                  key={list.num}
                  onClick={() => toggelSlide(list.num)}
                >
                  <FaqSlideTitle>
                    <span>{list.title}</span>
                    <FaqSlideIcon isOpen={list.isOpen}>
                      <SlArrowDown />
                    </FaqSlideIcon>
                  </FaqSlideTitle>
                  {/* {list.isOpen && (
                  
                )} */}
                  <FaqSlideContent isOpen={list.isOpen}>
                    {list.content}
                  </FaqSlideContent>
                </FaqSlideContainer>
              ))
          : chData
              .filter((data) => data.id === activeTab)[0]
              .dataList.map((list) => (
                <FaqSlideContainer
                  key={list.num}
                  onClick={() => toggelSlide(list.num)}
                >
                  <FaqSlideTitle>
                    <span>{list.title}</span>
                    <FaqSlideIcon isOpen={list.isOpen}>
                      <SlArrowDown />
                    </FaqSlideIcon>
                  </FaqSlideTitle>
                  {/* {list.isOpen && (
                  
                )} */}
                  <FaqSlideContent isOpen={list.isOpen}>
                    {list.content}
                  </FaqSlideContent>
                </FaqSlideContainer>
              ))}
      </FaqSlideWrapper>
    </FaqContent>
  );
};

export default Tab;
