import { StyledCard } from '../../Global/Ui';

const Invite = ({ data }) => {
  const { user, invite } = data;
  if (!user) return;

  return (
    <StyledCard
      title='Total Invite'
      titleClass='text-center'
    >
      {/* title */}
      {/* <h3 className='
        relative py-2 text-[0.875rem] font-bold text-center text-blue
        before:absolute before:block before:w-full before:h-[3px] before:bottom-0 before:left-0
        before:bg-sub-blue before:rounded
      '>
        Total Invite
      </h3> */}
      {/* sub title */}
      <p className='pt-5 pb-3 text-[0.875rem] font-bold text-blue'>
        Person
      </p>
      <span className='text-[1.5rem] font-bold'>
        {invite}
      </span>
    </StyledCard>
  )
}

export default Invite
