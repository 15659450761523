import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useData } from '../../utils/context';
import { routes } from '../Router';

// --- components ---
import UserInfo from './UserInfo/Main';
import Distribution from './Distribution/Main';
import Shortcut from './Shortcut/Main';
import Chart from './Chart/Main';
import Loading from '../Global/Loading';

// --- plugins ---
// sweetalert
import Swal from 'sweetalert2/dist/sweetalert2.js'
// gsap
import gsap from 'gsap';

// --- api ---
import { API_InvestAHFT } from '../../utils/api';
import { StyledAlert } from '../Global/Ui';

const Main = () => {
  // --- call api ---
  const getInvestAHFT = async () => {
    const res = await API_InvestAHFT({ mode: 'Query' });
    // const newError = { ...error, invest: res.error}
    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return Swal.fire(res.Message);

    setInvestData(res);
    if (res.AssetDistributions.length > 0) handleGetTypeGroup(res.AssetDistributions);

    getPKGTypeChartStatic();
  };
  const getPKGTypeChartStatic = async () => {
    const res = await API_InvestAHFT({ mode: 'PKGTypeChartStatic' });
    setLoading(false);
    setError('');

    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return setError({ recently: res.Message });
    
    if (res?.GroupTypeChartData === null) return;
    setRecentlyInvestData(res.GroupTypeChartData);
    setRecentlyChartTabs(res.GroupTypeChartData.map(({ Type }) => Type));
  };

  // --- handler ---
  const handleGetTypeGroup = (data) => {
    // group by 'type' & count total USDT
    const initTotalUSDT = 0;
    const types = Array.from(new Set(data.map(({ Type }) => Type)));
    const typeGroup = types.map(type => {
      return {
        type,
        USDT: data.filter(({ Type }) => Type === type).reduce((prev, { toUSDTValue }) => prev + toUSDTValue, initTotalUSDT)
      };
    });

    // sort by 'USDT' & combine USDT except top three types
    typeGroup.sort((a, b) => a.USDT < b.USDT ? 1 : -1);
    const combineTypeGroup = 
      typeGroup.length > 4
        ? [
            ...typeGroup.slice(0, 3),
            {
              type: 'Others',
              USDT: typeGroup.slice(3).reduce((prev, { USDT }) => prev + USDT, initTotalUSDT)
            }
          ]
        : typeGroup;

    setTotalUSDT(combineTypeGroup.reduce((prev, { USDT }) => prev + USDT, initTotalUSDT));
    setDistribution(combineTypeGroup);
  };
  const handleCheckStatus = () => {
    if (!investData) return;
    if (!investData.ListInvestData) return;

    if (!!userData && !userData.AvaPointWithdrawAddress) {
      Swal.fire({
        title: 'Please Set Your Withdraw Address First',
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) return navigate(routes.setting);
      })
      return;
    }
    
    const waitingInvest = investData.ListInvestData.filter(({ Status }) => Status === 'waiting');
    if (waitingInvest.length > 0) return Swal.fire('存在一筆正在等待轉帳或審核中的AHFT');

    navigate(routes.addpkg);
  };
  const handleGetRecentlyChart = () => {
    const pkgData = recentlyInvestData[recentlyChartTabCurrentSelected].idDatas[recentlyChartCurrentSelected].PkgDatas;
    
    const periodGroupData = [];
    pkgData.map(({ asset, endAmount, groupDate, periods }) => {
      const rate = handleGetRate(asset, groupDate);
      const periodData = periodGroupData.filter(({ Period }) => Period === periods);

      if (periodData.length === 0) {
        periodGroupData.push({
          Period: periods,
          EndDate: +groupDate,
          totalUSDT: rate * endAmount
        });
      }
      else {
        periodData.totalUSDT += rate * endAmount;
      }
    });

    setRecentlyBarChartData(periodGroupData.slice(-12));
  };
  const handleGetRate = (asset, groupDate) => {
    const compareTime = parseInt(groupDate);
    const today = new Date();
    const date = today.getFullYear()+'/'+(today.getMonth()+1)+'/'+today.getDate();
    const todayTimeStamp = new Date(date).valueOf();

    if (asset === 'USDT')
      return 1;

    const rates = yearRates.filter(data => data.asset === asset);
    if (rates.length === 0) return console.error(`Asset '${asset}' doesn't exist in yearRates, please check API.`);

    // groupDate after today, get today close value.
    if (compareTime >= todayTimeStamp)
      return parseFloat(yearRates.filter(data => data.asset === asset)[0].info[364].closeValue);
    
    return parseFloat(rates[0].info.filter(({ openTime, closeTime }) => (openTime <= compareTime && closeTime > compareTime))[0].closeValue);
  };

  // --- router ---
  const navigate = useNavigate();

  // --- context ---
  const { userData, yearRates } = useData();

  // --- states ---
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [initData, setInitData] = useState(false);
  const [investData, setInvestData] = useState(null);
  const [recentlyInvestData, setRecentlyInvestData] = useState([]);
  const [recentlyBarCharData, setRecentlyBarChartData] = useState([]);
  const [totalUSDT, setTotalUSDT] = useState(0);
  const [distribution, setDistribution] = useState([]);
  const [recentlyChartTabs, setRecentlyChartTabs] = useState();
  const [recentlyChartSelections, setRecentlyChartSelections] = useState();
  const [recentlyChartTabCurrentSelected, setRecentlyChartTabCurrentSelected] = useState(0);
  const [recentlyChartCurrentSelected, setRecentlyChartCurrentSelected] = useState(0);
  
  // --- useEffect ---
  useEffect(() => {
    setInitData(true);
  }, []);
  
  useEffect(() => {
    if (!initData) return;
    getInvestAHFT();
  }, [initData]);

  useEffect(() => {
    if (recentlyInvestData.length === 0) return;
    handleGetRecentlyChart();
  }, [recentlyInvestData]);

  useEffect(() => {
    if (recentlyInvestData.length === 0) return;
    setRecentlyChartCurrentSelected(-1);
    setRecentlyChartSelections(recentlyInvestData[recentlyChartTabCurrentSelected].idDatas.map(({ Name }) => Name));
  }, [recentlyChartTabs, recentlyChartTabCurrentSelected]);

  useEffect(() => {
    if (recentlyInvestData.length === 0) return;
    if (recentlyChartCurrentSelected === -1) return setRecentlyChartCurrentSelected(0);
    handleGetRecentlyChart();
  }, [recentlyChartCurrentSelected]);

  // bar animate
  useEffect(() => {
    const databars = Array.from(document.querySelectorAll('.databar'));
    if (databars.length === 0) return;
    gsap.fromTo('.databar', { translateY: '0' }, { translateY: '-100%', duration: 1 });
  }, [recentlyBarCharData]);

  // --- components ---
  if (loading) return <Loading />
  return (
    <div className='w-limit-max'>
      <div>
        <div>
          <UserInfo name={userData.Name} />
        </div>

        <div className='md:flex'>
          <Shortcut handleCheckStatus={handleCheckStatus}/>

          <Distribution
            distribution={distribution}
            totalUSDT={totalUSDT}
          />
        </div>
      </div>

      <div className='pb-20'>
        <Chart data={{
          error,
          recentlyChartTabs,
          setRecentlyChartTabCurrentSelected,
          recentlyChartSelections,
          recentlyChartCurrentSelected,
          recentlyBarCharData,
          setRecentlyChartCurrentSelected,
          recentlyChartTabCurrentSelected,
        }} />
      </div>
    </div>
  )
}

export default Main