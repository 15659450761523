import React from "react";
import styled from "styled-components";

const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const PageButton = styled.button`
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  color: white;
  border: none;
  padding: 4px 12px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 4px;

  &.active {
    border: 1px solid #0aecd1;
  }
`;

const Pagination = ({ currentPage, totalPages, changePage }) => {
  const pageLimit = 5; // pagination 不超過 5
  let startPage, endPage;

  if (totalPages <= pageLimit) {
    startPage = 1;
    endPage = totalPages;
  } else {
    if (currentPage <= 3) {
      startPage = 1;
      endPage = pageLimit;
    } else if (currentPage + 2 >= totalPages) {
      startPage = totalPages - 4;
      endPage = totalPages;
    } else {
      startPage = currentPage - 2;
      endPage = currentPage + 2;
    }
  }

  const changePageHandler = (page) => {
    if (page < 1 || page > totalPages) return;
    changePage(page);
  };

  console.log();

  return (
    <PaginationContainer>
      <PageButton onClick={() => changePageHandler(1)}>&laquo;</PageButton>
      {startPage > 1 && (
        <PageButton onClick={() => changePageHandler(1)}>1</PageButton>
      )}
      {startPage > 2 && <PageButton disabled>...</PageButton>}
      {Array.from({ length: endPage - startPage + 1 }, (_, i) => (
        <PageButton
          key={startPage + i}
          onClick={() => changePageHandler(startPage + i)}
          className={currentPage === startPage + i ? "active" : ""}
        >
          {startPage + i}
        </PageButton>
      ))}
      {endPage < totalPages - 1 && <PageButton disabled>...</PageButton>}
      {endPage < totalPages && (
        <PageButton onClick={() => changePageHandler(totalPages)}>
          {totalPages}
        </PageButton>
      )}
      <PageButton onClick={() => changePageHandler(totalPages)}>
        &raquo;
      </PageButton>
    </PaginationContainer>
  );
};

export default Pagination;
