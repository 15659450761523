import { useEffect, useState } from 'react';

const PolicyCheck = ({ data='', setValid=null }) => {
  // --- handler ---
  const handleCheck = (index) => {
    const newChecks = [...isChecked];
    newChecks[index] = !isChecked[index];

    newChecks.filter(checked => !checked).length === 0
      ? setValid(true)
      :setValid(false);

    setIsChecked(newChecks);
  }

  // --- states ---
  const [isChecked, setIsChecked] = useState(() => {
    return data.map(() => false);
  });

  // --- effect ---
  useEffect(() => {
    setValid(false);
  }, []);

  return (
    <div className='max-w-[650px] mx-auto font-["Roboto"] text-[1rem] font-bold'>
      {data.map((policy, index) => (
        <label key={index} className='flex items-start pb-4'>
          <div>
            <input
              type='checkbox'
              className='mr-2 rounded text-[1rem]'
              value={isChecked[index]}
              onChange={() => handleCheck(index)}
            />
          </div>
          <p className='cursor-pointer text-gray'>
            {policy}
          </p>
        </label>
      ))}
    </div>
  )
};

export default PolicyCheck;