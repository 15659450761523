import styled, { css, keyframes } from "styled-components";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../v2/utils/rwd";

import Icons from "../../../v2/global/icons";
import { AlgoProfitChart } from "./AlgoProfitChart";

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.8);
  color: #151944;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  /* padding: 40px; */
  width: 80vw;
  max-width: 1200px;
  border-radius: 8px;
  position: relative;
  animation: mymove 5s alternate infinite;
  overflow: hidden;

  ${RWD_LG(css`
    width: 85%;
  `)};

  ${RWD_MD(css`
    width: 70%;
    height: 85vh;
    /* padding: 20px 40px; */
  `)};

  ${RWD_SM(css`
    width: 90%;
    height: 88vh;
    /* padding: 20px; */
  `)};
`;

const AlgoSlideWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const AlgoWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const AlgoContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;

  ${RWD_MD(css`
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  `)};
`;

const AlgoClose = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;

    & path {
      stroke: rgba(255, 255, 255, 0.5);
    }
  }
`;

const AlgoImgWrapper = styled.div`
  width: 50%;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  ${RWD_MD(css`
    width: 100%;
    height: 40%;
  `)};

  ${RWD_SM(css`
    height: 25vh;
    /* height: 30vh; */

    img {
      object-position: bottom;
    }
  `)};
`;

const AlgoContentWrapper = styled.div`
  padding: 30px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 50%;

  ${RWD_MD(css`
    width: 100%;
    height: 100%;
    padding: 10px 10px;
    justify-content: space-evenly;
  `)};
`;

const AlgoTitle = styled.div`
  color: #fcfcfc;
  font-size: clamp(25px, 3.125vw, 60px);
  padding-bottom: 10px;
  text-shadow: 0px 0px 5px #fcfcfc;
  font-weight: 500;

  ${RWD_MD(css`
    padding-bottom: 5px;
  `)};
`;

const AlgoDetailWrapper = styled.div`
  color: #fcfcfc;

  width: 100%;
  text-align: center;
  padding-bottom: 40px;
  font-size: 16px;

  ${RWD_MD(css`
    padding-bottom: 10px;
    font-size: clamp(12px, 0.1822917vw, 14px);
  `)};
`;

const AlgoDetail = styled.div``;

const AlgoDataWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  width: 70%;
  padding-bottom: 10px;

  ${RWD_MD(css`
    width: 80%;
    padding-bottom: 10px;
  `)};
`;

const AlgoProfitWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  width: 70%;
  padding-bottom: 20px;

  ${RWD_MD(css`
    width: 80%;
    padding-bottom: 10px;
  `)};
`;

const AlgoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AlgoDataTitle = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-family: Montserrat;
  font-size: clamp(11px, 0.833vw, 16px);
`;

const AlgoDataNum = styled.div`
  font-weight: 900;
  color: #b8fb3c;
  text-shadow: ${({ $minus }) =>
    $minus ? "0px 0px 5px #ce0000" : "0px 0px 5px #12b296"};
  font-family: Montserrat;
  font-size: clamp(20px, 2.0833vw, 40px);

  color: ${({ $minus }) => ($minus ? "#f85125" : "#b8fb3c")};
`;

const AlgoDataContent = styled.div`
  font-weight: 900;
  color: #01ffff;
  text-shadow: 0px 0px 5px #00c2cb;
  font-family: Montserrat;
  font-size: clamp(20px, 2.0833vw, 40px);
`;

const AlgoChartWrapper = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 10px 10px 10px;

  ${RWD_MD(css`
    height: 150px;
    padding: 5px 0 0 0;
  `)};
`;

const AlgoPageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;

  ${RWD_MD(css`
    padding-top: 15px;
  `)};
`;

const AlgoPageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const ActionButtonWrapper = styled.div`
  padding-top: 20px;

  ${RWD_SM(css`
    padding-top: 10px;
  `)};
`;

const ActionButton = styled.button`
  border-radius: 60px;
  min-width: 125px;
  /* width: 140px; */
  color: #fcfcfc;
  text-shadow: 0 0 2px #fcfcfc;
  border-color: #0aecd1;
  box-shadow: 0 0 2px #0aecd1, inset 0 0 2px #0aecd1;
  text-align: center;
  transition: 0.5s;

  &:hover {
    /*Green*/
    color: #0aecd1;
    text-shadow: 0 0 5px #0aecd1;
    border-color: #0aecd1;
    box-shadow: 0 0 5px #0aecd1, inset 0 0 5px #0aecd1;
  }

  ${RWD_LG(css`
    min-width: 110px;
  `)};
`;

const ActionButtonText = styled.span`
  font-size: min(max(1.0416vw, 16px), 20px);
  color: #fcfcfc;
  display: inline-flex;
  padding: 10px 20px;
  ${RWD_LG(css`
    padding: 8px 18px;
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const breatheAnimation = keyframes`
    0% {
        box-shadow: 0px 0px 0px 0px rgba(10, 236, 209, 0.8);
    }

    50% {
        box-shadow: 0px 0px 0px 8px rgba(10, 236, 209, 0.2);
    }

    100% {
        box-shadow: 0px 0px 0px 12px transparent;
    }
`;

const AlgoPage = styled.div`
  width: 10px;
  height: 10px;

  border-radius: 50%;
  text-align: center;

  overflow: hidden;
  cursor: pointer;
  z-index: 2;
  position: relative;

  background-color: ${({ $active }) => ($active ? "#0aecd1" : "#ccc")};

  overflow: hidden;

  &:hover {
    animation: ${breatheAnimation} 1500ms linear infinite;
  }
`;

const AlgoArrowWrapper = styled.button`
  width: 8vw;
  padding: 10px 0;
  cursor: ${({ $status }) => ($status ? "pointer" : "default")};
  visibility: ${({ $status }) => ($status ? "visible" : "hidden")};

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 30px;
    height: 30px;

    & path {
      stroke: rgba(255, 255, 255, 0.5);
      transition: all 0.3s linear;
    }
  }

  &:hover svg path {
    stroke: #0aecd1;
  }

  ${RWD_SM(css`
    svg {
      width: 15px;
      height: 15px;
    }
  `)};
`;

const AlgoIntroduceModal = ({
  algoData,
  activeAlgo,
  setAlgoModalIsOpen,
  setActiveAlgo,
}) => {
  const navigate = useNavigate();

  const handleCloseModal = () => {
    setAlgoModalIsOpen(false);
  };

  const handleActiveAlgo = (id, type) => {
    switch (type) {
      case "page":
        setActiveAlgo(id);
        break;
      case "next":
        setActiveAlgo(activeAlgo + 1);
        break;
      case "prev":
        setActiveAlgo(activeAlgo - 1);
        break;
      default:
        break;
    }
  };

  const handleLogin = () => {
    navigate("/v2/login");
  };

  // screen size function
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Wrapper>
      <AlgoSlideWrapper>
        <AlgoArrowWrapper
          $status={activeAlgo > 1}
          onClick={() => handleActiveAlgo("", "prev")}
          disabled={activeAlgo === 1}
        >
          <Icons.Arrow_left />
        </AlgoArrowWrapper>

        <Container>
          <AlgoClose onClick={handleCloseModal}>
            <Icons.Close />
          </AlgoClose>

          <AlgoWrapper>
            {algoData
              .filter((algo) => algo.id === activeAlgo)
              .map((data) => (
                <AlgoContainer>
                  <AlgoImgWrapper>
                    <img
                      src={windowSize[0] <= 768 ? data.mobileSrc : data.src}
                    />
                  </AlgoImgWrapper>
                  <AlgoContentWrapper>
                    <AlgoTitle>{data.title}</AlgoTitle>
                    <AlgoDetailWrapper>
                      {data.content.map((text) => (
                        <AlgoDetail>{text}</AlgoDetail>
                      ))}
                    </AlgoDetailWrapper>
                    <AlgoProfitWrapper>
                      <AlgoDataContainer>
                        <AlgoDataTitle>{data.PeriodDays}D 收益率</AlgoDataTitle>
                        <AlgoDataNum $minus={data.TotalProfitRate < 0}>
                          {data.TotalProfitRate.toFixed(2)}%
                        </AlgoDataNum>
                      </AlgoDataContainer>
                      {/* <AlgoDataContainer>
                        <AlgoDataTitle>{data.PeriodDays}D 盈虧</AlgoDataTitle>
                        <AlgoDataNum $minus={data.TotalProfit < 0}>
                          {data.TotalProfit > 0 && "+"}
                          {data.TotalProfit.toFixed(2)}
                        </AlgoDataNum>
                      </AlgoDataContainer> */}
                      <AlgoDataContainer>
                        <AlgoDataTitle>標的</AlgoDataTitle>
                        <AlgoDataContent>{data.Target}</AlgoDataContent>
                      </AlgoDataContainer>
                    </AlgoProfitWrapper>
                    <AlgoDataWrapper>
                      <AlgoDataContainer>
                        <AlgoDataTitle>交易天數</AlgoDataTitle>
                        <AlgoDataContent>{data.Days}</AlgoDataContent>
                      </AlgoDataContainer>
                      <AlgoDataContainer>
                        <AlgoDataTitle>勝率</AlgoDataTitle>
                        <AlgoDataContent>
                          {(data.WinRate * 100).toFixed(2)}%
                        </AlgoDataContent>
                      </AlgoDataContainer>
                    </AlgoDataWrapper>
                    <AlgoChartWrapper>
                      <AlgoProfitChart
                        chartData={data.Data}
                        target={data.Target}
                        periodDays={data.PeriodDays}
                      />
                    </AlgoChartWrapper>
                    <ActionButtonWrapper>
                      <ActionButton onClick={handleLogin}>
                        <ActionButtonText>立即前往</ActionButtonText>
                      </ActionButton>
                    </ActionButtonWrapper>
                  </AlgoContentWrapper>
                </AlgoContainer>
              ))}
          </AlgoWrapper>
        </Container>

        <AlgoArrowWrapper
          $status={activeAlgo < algoData.length}
          onClick={() => handleActiveAlgo("", "next")}
          disabled={activeAlgo === algoData.length}
        >
          <Icons.Arrow_right />
        </AlgoArrowWrapper>
      </AlgoSlideWrapper>
      <AlgoPageWrapper>
        <AlgoPageContainer>
          {Array.from({ length: algoData.length }, (number, index) => (
            <AlgoPage
              $active={index + 1 == activeAlgo}
              onClick={() => handleActiveAlgo(index + 1, "page")}
            ></AlgoPage>
          ))}
        </AlgoPageContainer>
      </AlgoPageWrapper>
    </Wrapper>
  );
};

export default AlgoIntroduceModal;
