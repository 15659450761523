import { Link, useLocation, matchPath } from 'react-router-dom';
import { useData } from '../../utils/context';

// --- components ---
import { routes } from '../Router';

// --- landing page ---
export const Navbar = () => {
  const { langData } = useData();

  return (
    <nav className='
      relative w-full z-20 uppercase items-center
    '>
      {/* --- link --- */}
      <div className='w-full'>
        {langData.nav.map(({label, path}, index) => (
          <a
            key={index}
            // to={path}
            href={`#${label}`}
            className='block w-full 2xl:w-auto 2xl:inline-block px-3 py-4 sm:py-8 3xl:py-3 mr-5'
          >
            {label}
          </a>
        ))}
      </div>
    </nav>
  )
}

// --- dashboard page ---
export const DashboardNavbar = () => {
  const { pathname } = useLocation();
  const isCurrentPathMatch = path => Boolean(matchPath(path, pathname));

  // const dashboardPath = ['dashboard', 'mining', 'algotrade', 'memberships'];
  const dashboardPath = ['performance', 'dashboard', 'mining', 'algotrade', 'memberships'];
  // const dashboardPath = ['dashboard', 'mining', 'memberships', 'algotrade', 'tradingView'];

  return (
    <nav className='flex-grow'>
      {/* --- dashboard paths --- */}
      {dashboardPath.map((label, index) => {
        return (
          <Link
            key={index}
            to={routes[label]}
            className={`
              block pl-20 lg:pl-5 py-3
              border-l border-l-[12px] lg:border-l-[7px] border-transparent
              uppercase
              hover:bg-white hover:dark:bg-black hover:border-l-primary
              ${isCurrentPathMatch(routes[label]) && 'bg-white dark:bg-black border-l-primary'}
            `}
          >
            {label}
          </Link>
        )
      })}
    </nav>
  );
}