import { useEffect, useRef, useState } from "react";
import {
  API_CreateOrders,
  API_GetCurrentPrice,
  API_GetRobotTargetDetail,
  API_GetRobots,
  API_UpdateRobotTargetDetail,
} from "../../../utils/api";
import UseSwal from "../../hooks/Lang"; // swal confirm button background color
import Loading from "../../../components/Global/Loading";
import styled from "styled-components";
import AlgoTargets from "./AlgoTargets";
import Swal from "sweetalert2/dist/sweetalert2";

const Container = styled.div`
  padding: 3rem 1rem 1rem;
  background: #fcfcfc;

  *::-webkit-scrollbar {
    display: none;
  }

  input,
  select,
  textarea {
    border: 1px solid #48489180;
    border-radius: 4px;
    background: #fffa;
    outline: none;

    &:focus,
    &:hover {
      border: 1px solid #484891;
    }
  }

  input:read-only {
    border: 1px solid #ccc;
    background: #eee5;
    color: #0005;
    pointer-events: none;
  }

  input[type="checkbox"] {
    accent-color: #7373b9;
  }

  button {
    background: #484891d0;
    color: #fff;
    border: 1px solid #484891d0;
    padding: 0.5rem 0.8rem;
    border-radius: 4px;
    transition: 200ms;
    box-shadow: 3px 3px 8px 0px #0003;

    &:hover {
      background: #484891bb;
    }
  }

  button.outline {
    color: #484891d0;
    background: #fff;
    border: 1px solid #484891d0;
    outline: none;
    box-shadow: none;
    &:hover {
      background: #48489130;
    }
  }

  @media screen and (min-width: 1024px) {
    padding: 2rem 4rem;
  }
`;

const InfoContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  width: 100%;
`;
const TableContainer = styled.div`
  width: 100%;
`;
const TableHeader = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 2rem;

  button {
    margin-left: 1rem;
  }
`;
const TableWrapper = styled.div`
  width: 100%;
  overflow: auto;
  box-shadow: -5px 5px 10px 0px #0003;
`;
const TableTitle = styled.h3`
  color: #484891;
`;
const Table = styled.table`
  width: 100%;
  border: 1px solid #f3f3fa;
  background: #fff;
  border-radius: 4px;
  overflow: hidden;

  thead {
    background: #a6a6d2;
  }
  tbody tr {
    &.selected {
      background: #c7c7e2;
    }
    &:hover {
      background: #c7c7e2;
      cursor: pointer;
    }
  }
  tr,
  th,
  td {
    border: 1px solid #f3f3fa;
  }
  th,
  td {
    text-align: center;
    padding: 0.3rem 0.5rem;
  }
`;

const DetailContainer = styled.div`
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 2rem;
  background: #fffa;
  box-shadow: -5px 5px 10px 0px #0003;
  backdrop-filter: blur(10px);
  transform: translateX(100%);
  transition: 500ms;
  width: 100%;

  display: flex;
  flex-direction: column;

  &.show {
    transform: translate(0);
    left: auto;
  }

  @media screen and (min-width: 1024px) {
    width: 80%;
  }
`;
const DetailWrapper = styled.div`
  flex: 1;
  overflow: auto;
`;
const DetailItem = styled.div`
  padding: 0.5rem;

  > *:first-child {
    font-weight: 700;
    color: #484891;
    min-width: 10rem;
  }
  > *:last-child {
    flex: 1;
  }

  input,
  select,
  textarea {
    width: 100%;
    padding: 0.2rem 0.5rem;
  }

  textarea {
    height: 7rem;
    width: 100%;

    @media screen and (min-width: 1024) {
      min-width: 30rem;
    }
  }

  @media screen and (min-width: 1024px) {
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
  }
`;
const ConfirmWrapper = styled.div`
  text-align: center;
  padding-top: 1rem;

  button.outline {
    margin-right: 1rem;
  }
`;
const ToggleRobot = styled.label`
  width: 3.5rem;
  height: 2rem;
  border-radius: 500px;
  background: #ccca;
  display: flex;
  align-items: center;
  padding: 0 0.25rem;
  cursor: pointer;

  &.enabled {
    background: #484891a0;

    ::before {
      transform: translateX(1.5rem);
      box-shadow: -3px 0px 8px 0px #0003;
    }
  }

  &::before {
    content: "";
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100%;
    background: #fff;
    box-shadow: 3px 0px 8px 0px #0003;
    transition: 200ms;
  }
`;
const EntrustContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;

  @media screen and (min-width: 1024px) {
    flex-direction: row;

    > *:first-child {
      width: 45%;
    }
    > *:last-child {
      flex: 1;
    }
  }
`;
const EntrustTableContainer = styled.div`
  padding: 1rem;
`;

const Algo = () => {
  // api: 取得 robot 組別 algo 列表
  const getAlgoList = async () => {
    const res = await API_GetRobots();
    setLoading(false);
    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return UseSwal(res.Message);
    setData(res.Entrusts.filter(({ TargetKey }) => !!TargetKey));
  };

  // api: 取得 robot target entrust 清單
  const getRobotTargetEntrusts = async () => {
    // setLoading(true);
    const body = {
      RobotId: data[selectedRobotIndex].RobotId,
      TargetKey: data[selectedRobotIndex].TargetKey,
    };
    const res = await API_GetRobotTargetDetail(body);
    // setLoading(false);
    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return UseSwal(res.Message);

    setRobotTargetEntrusts(res.RobotTargetEntrusts);
  };

  // api: 更新機器人幣種
  const updateRobotTargetDetail = async () => {
    setLoading(true);

    // 若修改方向限制，判斷是否需要先平倉
    if (data[selectedRobotIndex].SideStatus !== sideStatus) {
      const confirmOperation = await checkOrderSide();
      if (!confirmOperation) return;
    }

    const body = {
      RobotId: data[selectedRobotIndex].RobotId,
      TargetKey: targetKey,
      IsAvailable: isAvailable,
      IsVisible: visible,
      SideStatus: sideStatus,
      MaxLaverage: maxLaverage,
      Laverage: laverage,
      RobotData: robotData,
      Description: description,
      DescriptionEn: descriptionEn,
      Slogan: slogan,
      SloganEn: sloganEn,
    };
    console.log(body);
    const res = await API_UpdateRobotTargetDetail(body);
    setLoading(false);
    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return UseSwal(res.Message);
    UseSwal("更新成功！");
    // setSelectedRobotIndex(-1);
    await getAlgoList();
    await getRobotTargetEntrusts();
  };

  // 若修改限制下單方向，確認是否有需平倉帳號
  const checkOrderSide = async () => {
    const needConfirm = robotTargetEntrusts.some(
      ({ EntrustStatus, LastOrderAction, LastOrderSide }) =>
        EntrustStatus?.toLowerCase() === "running" && // 選擇 僅做空，目前仍有 open long 的艙位
        ((LastOrderAction?.toLowerCase() !== "close" &&
          LastOrderSide?.toLowerCase() === "long" &&
          sideStatus === "OnlyShort") ||
          // 選擇 僅做多，目前仍有 open short 的艙位
          (LastOrderAction?.toLowerCase() !== "close" &&
            LastOrderSide?.toLowerCase() === "short" &&
            sideStatus === "OnlyLong") ||
          // 選擇 不可下單，目前仍有 open 的艙位
          (LastOrderAction?.toLowerCase() !== "close" &&
            sideStatus === "Disabled"))
    );
    if (!needConfirm) return true;

    const currentRobotId = data[selectedRobotIndex].RobotId;
    const currentTargetKey = data[selectedRobotIndex].TargetKey;
    const currentPrice = await API_GetCurrentPrice();
    return UseSwal({
      title: `是否 Close 所有 R${currentRobotId}(${currentTargetKey}) Entrust?`,
      text: `目前價格 ${currentPrice?.result?.list[0]?.markPrice}`,
      showCancelButton: true,
    }).then(async (result) => {
      if (!result.isConfirmed) return false;

      // 下平倉單
      const body = {
        RobotId: currentRobotId,
        TargetKey: currentTargetKey,
        Side: "Long", // Close 方向於 API 判斷，不另外取值
        Action: "Close",
      };
      const jsonContent = JSON.stringify(body);
      const res = await API_CreateOrders(jsonContent);
      if (res.ResultCode === 999) {
        console.error(res.Message);
        return false;
      }
      if (res.ResultCode !== 0) {
        UseSwal(res.Message);
        return false;
      }
      return true;
    });
  };

  // 開關機器人幣種
  const toggleRobot = (e) => {
    if (selectedRobotIndex === -1) return;
    setIsAvailable(e.target.checked);
  };

  // 切換機器人幣種
  const setRobotTargetDetail = () => {
    if (selectedRobotIndex === -1) {
      setVisible(true);
      setTargetKey("");
      setSideStatus("All");
      setIsAvailable(false);
      setLaverage(0);
      setMaxLaverage(0);
      setDescription("");
      setDescriptionEn("");
      setSlogan("");
      setSloganEn("");
      setRobotData("");
    } else {
      const {
        IsVisible,
        TargetKey,
        SideStatus,
        IsAvailable,
        RobotTargetDescription,
        RobotTargetDescriptionEn,
        RobotTargetSlogan,
        RobotTargetSloganEn,
        RobotTargetData,
        Laverage,
        MaxLaverage,
      } = data[selectedRobotIndex];
      setVisible(IsVisible);
      setTargetKey(TargetKey);
      setSideStatus(SideStatus ?? "All");
      setIsAvailable(IsAvailable);
      setLaverage(Laverage ?? 10);
      setMaxLaverage(MaxLaverage ?? 3);
      setDescription(RobotTargetDescription);
      setDescriptionEn(RobotTargetDescriptionEn);
      setSlogan(RobotTargetSlogan);
      setSloganEn(RobotTargetSloganEn);
      setRobotData(RobotTargetData);
    }
  };

  // 開啟幣種設定
  const handleOpenTargetBox = () => {
    setOpenTargetBox(true);
    setSelectedRobotIndex(-1);
  };

  const detailContainer = useRef();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const [selectedRobotIndex, setSelectedRobotIndex] = useState(-1);
  const [robotTargetEntrusts, setRobotTargetEntrusts] = useState([]);

  // 編輯幣種資訊
  const [openTargetBox, setOpenTargetBox] = useState(false);

  // 編輯機器人資訊
  const [openRobotBox, setOpenRobotBox] = useState(false);

  // 編輯機器人幣種資訊
  const [visible, setVisible] = useState(true);
  const [targetKey, setTargetKey] = useState("");
  const [sideStatus, setSideStatus] = useState("");
  const [laverage, setLaverage] = useState(0);
  const [maxLaverage, setMaxLaverage] = useState(0);
  const [isAvailable, setIsAvailable] = useState(false);
  const [description, setDescription] = useState("");
  const [descriptionEn, setDescriptionEn] = useState("");
  const [slogan, setSlogan] = useState("");
  const [sloganEn, setSloganEn] = useState("");
  const [robotData, setRobotData] = useState("");

  useEffect(() => {
    if (!data) setData("get");
  }, []);

  useEffect(() => {
    if (!data) return;
    if (data === "get") getAlgoList();
  }, [data]);

  useEffect(() => {
    if (selectedRobotIndex === -1) return;
    setOpenTargetBox(false);
    setRobotTargetDetail();
    setRobotTargetEntrusts([]);

    getRobotTargetEntrusts(); // api
  }, [selectedRobotIndex]);

  if (loading) return <Loading />;
  return (
    <Container>
      {/* 機器人幣種資訊 */}
      <DetailContainer
        ref={detailContainer}
        className={selectedRobotIndex === -1 ? "" : "show"}
      >
        <DetailWrapper>
          {/* IsAvailavle */}
          <DetailItem>
            <div>啟用</div>
            <input
              id="isAvailable"
              type="checkbox"
              checked={isAvailable}
              onChange={toggleRobot}
              hidden
            />
            <div>
              <ToggleRobot
                htmlFor="isAvailable"
                className={isAvailable ? "enabled" : ""}
              />
            </div>
          </DetailItem>

          {isAvailable && (
            <EntrustContainer>
              <div>
                {/* 在前台顯示 */}
                <DetailItem>
                  <div>在前台顯示</div>
                  <input
                    id="isVisible"
                    type="checkbox"
                    checked={visible}
                    onChange={() => setVisible(!visible)}
                    hidden
                  />
                  <div>
                    <ToggleRobot
                      htmlFor="isVisible"
                      className={visible ? "enabled" : ""}
                    />
                  </div>
                </DetailItem>
                {/* RobotId */}
                <DetailItem>
                  <div>機器人 ID</div>
                  <input
                    value={data[selectedRobotIndex]?.RobotId ?? ""}
                    readOnly
                  />
                </DetailItem>
                {/* TargetKey */}
                <DetailItem>
                  <div>幣種 (key)</div>
                  <input
                    value={data[selectedRobotIndex]?.TargetKey ?? ""}
                    readOnly
                  />
                  {/* <select value={targetKey} onChange={(e) => setTargetKey(e.target.value)}>
                    <option value="BTC">BTC</option>
                    <option value="ETH">ETH</option>
                    <option value="HANGSENG">HANGSENG</option>
                  </select> */}
                </DetailItem>
                {/* SideStatus */}
                <DetailItem>
                  <div>下單方向限制</div>
                  <select
                    value={sideStatus}
                    onChange={(e) => setSideStatus(e.target.value)}
                  >
                    <option value="All">不限制</option>
                    <option value="OnlyLong">僅做多</option>
                    <option value="OnlyShort">僅做空</option>
                    <option value="Disabled">不可下單</option>
                  </select>
                </DetailItem>
                {/* MaxLaverage */}
                <DetailItem>
                  <div>最大槓桿倍數</div>
                  <input
                    type="number"
                    value={maxLaverage}
                    onChange={(e) => setMaxLaverage(e.target.value)}
                  />
                </DetailItem>
                {/* Laverage */}
                <DetailItem>
                  <div>機器人槓桿倍數</div>
                  <input
                    type="number"
                    value={laverage}
                    onChange={(e) => setLaverage(e.target.value)}
                  />
                </DetailItem>
                {/* Robot 名稱 */}
                <DetailItem>
                  <div>機器人名稱（中）</div>
                  <input
                    value={data[selectedRobotIndex]?.RobotName ?? ""}
                    readOnly
                  />
                </DetailItem>
                <DetailItem>
                  <div>機器人名稱（英）</div>
                  <input
                    value={data[selectedRobotIndex]?.RobotNameEn ?? ""}
                    readOnly
                  />
                </DetailItem>
                {/* Robot 描述 */}
                <DetailItem>
                  <div>機器人幣種描述（中）</div>
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </DetailItem>
                <DetailItem>
                  <div>機器人幣種描述（英）</div>
                  <textarea
                    value={descriptionEn}
                    onChange={(e) => setDescriptionEn(e.target.value)}
                  />
                </DetailItem>
                {/* Robot 標語 */}
                <DetailItem>
                  <div>機器人幣種標語（中）</div>
                  <textarea
                    value={slogan}
                    onChange={(e) => setSlogan(e.target.value)}
                  />
                </DetailItem>
                <DetailItem>
                  <div>機器人幣種標語（英）</div>
                  <textarea
                    value={sloganEn}
                    onChange={(e) => setSloganEn(e.target.value)}
                  />
                </DetailItem>
                {/* Robot 資料 */}
                <DetailItem>
                  <div>機器人幣種資料</div>
                  <textarea
                    value={robotData}
                    onChange={(e) => setRobotData(e.target.value)}
                  />
                </DetailItem>
              </div>

              <EntrustTableContainer>
                <TableWrapper>
                  <Table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Account</th>
                        <th>Status</th>
                        <th>Action</th>
                        <th>Side</th>
                        <th>AvgPrice</th>
                        <th>Ratio</th>
                      </tr>
                    </thead>
                    <tbody>
                      {robotTargetEntrusts.map(
                        (
                          {
                            EntrustId,
                            AccountId,
                            EntrustStatus,
                            LastOrderAction,
                            LastOrderSide,
                            CurrentAvgPrice,
                            HoldRatio,
                          },
                          index
                        ) => (
                          <tr key={index}>
                            <td>{EntrustId}</td>
                            <td>{AccountId.split("@")[0]}</td>
                            <td>{EntrustStatus}</td>
                            <td>{LastOrderAction}</td>
                            <td>{LastOrderSide}</td>
                            <td>{CurrentAvgPrice}</td>
                            <td>{HoldRatio}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                </TableWrapper>
              </EntrustTableContainer>
            </EntrustContainer>
          )}
        </DetailWrapper>

        <ConfirmWrapper>
          <button className="outline" onClick={() => setSelectedRobotIndex(-1)}>
            取消
          </button>
          <button onClick={updateRobotTargetDetail}>儲存</button>
        </ConfirmWrapper>
      </DetailContainer>

      {/* 機器人幣種列表 */}
      <InfoContainer>
        <TableContainer>
          <TableHeader>
            <TableTitle>機器人幣種列表</TableTitle>
            <div>
              <button className="outline" onClick={handleOpenTargetBox}>
                幣種設定
              </button>
              {/* <button className="outline">機器人設定</button> */}
            </div>
          </TableHeader>

          <TableWrapper>
            <Table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>RobotName</th>
                  <th>TargetKey</th>
                  <th>SideStatus</th>
                  <th>Laverage</th>
                  <th>IsAvailable</th>
                </tr>
              </thead>
              <tbody>
                {data !== "get" &&
                  !!data?.length &&
                  data?.map(
                    (
                      {
                        RobotName,
                        TargetKey,
                        Laverage,
                        IsAvailable,
                        SideStatus,
                      },
                      index
                    ) => (
                      <tr
                        key={index}
                        onClick={() => setSelectedRobotIndex(index)}
                        className={
                          index === selectedRobotIndex ? "selected" : ""
                        }
                      >
                        <td>{index + 1}</td>
                        <td>{RobotName}</td>
                        <td>{TargetKey}</td>
                        <td>{SideStatus}</td>
                        <td>{Laverage}</td>
                        <td>
                          <input
                            type="checkbox"
                            checked={IsAvailable}
                            onChange={() => false}
                          />
                        </td>
                      </tr>
                    )
                  )}
              </tbody>
            </Table>
          </TableWrapper>
        </TableContainer>
      </InfoContainer>
      <AlgoTargets
        isOpen={openTargetBox}
        closeBox={() => setOpenTargetBox(false)}
      />
    </Container>
  );
};

export default Algo;
