import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectSystem } from "../../slices/systemSlice";
import styled, { css } from "styled-components";

import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";

import Lang from "../../global/Lang";
import Icons from "../../global/icons";
import {
  useGetAlgoDetailMutation,
  useStartAccountAlgoMutation,
  useStopAccountAlgoMutation,
  useToggleAlgoSubscribeMutation,
  useCloseAccountAlgoMutation,
  useDeleteInitAlgoMutation,
} from "../../services/algoApi";
import { useGetAlgoOrderHistoryMutation } from "../../services/algoOrderApi";
import { useUpdateWatchingTutorialMutation } from "../../services/accountApi";
import ErrorModal from "./ErrorModal";
import TelegramModal from "./TelegramModal";
import HistoryModal from "./HistoryModal";
import { dateTimeFormat } from "../../utils";

import Binance from "../../images/binance.png";
import Bybit from "../../images/bybit.png";
import Ig from "../../images/ig.png";

import { handleSortData } from "../../utils";

import { InfoMySwal } from "../../global/MySwal";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

// sweetAlert
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
  WarningMySwal,
  ErrorMySwal,
  SuccessMySwal,
  ToggleAlgoMySwal,
} from "../../global/MySwal";
import { getLanguage } from "../../services/common";
import { LangString } from "../../utils";

import SettingAmountModal from "./SettingAmountModal";

import { useNavigate, useLocation } from "react-router-dom";
import { routes } from "../../../components/Router";
import JoyrideComponent from "../../global/JoyrideComponent";

import { useOutletContext } from "react-router-dom";

const MySwal = withReactContent(Swal).mixin({
  confirmButtonColor: "#4643CA", // 系統平倉
  denyButtonColor: "#E89924", // 使用者持倉
});

// #region styled
const handleColorType = (type) => {
  switch (type) {
    case "initial":
      return {
        bg: "#54587B",
      };
    case "running":
      return {
        bg: "#4643CA",
      };
    case "stop":
      return {
        bg: "#E89924",
      };

    case "help":
      return {
        bg: "#F05D2F",
      };

    default:
      return {
        bg: "#54587B",
      };
  }
};

const handleRwdColorType = (type) => {
  switch (type) {
    case "init":
      return {
        bg: "#4643CA",
      };
    case "pending":
      return {
        bg: "#dcdcdc",
      };
    case "running":
      return {
        bg: "#54587B",
      };
    case "stop":
      return {
        bg: "#E89924",
      };

    case "help":
      return {
        bg: "#F05D2F",
      };

    default:
      return {
        bg: "#4643CA",
      };
  }
};

const handleStatusType = (type) => {
  switch (type) {
    case "init":
      return {
        color: "#0AECD1",
      };
    case "running":
      return {
        color: "#54587B",
      };
    case "stop":
      return {
        color: "#FCFCFC",
      };
    case "pending":
      return {
        color: "#dcdcdc",
      };
    case "help":
      return {
        color: "transparent",
      };
    default:
      return {
        color: "#0AECD1",
      };
  }
};

const Wrapper = styled.div`
  /* padding-bottom: 50px; */
  /* border: 1px solid red; */
`;

const Title = styled.div`
  font-size: min(max(1.145vw, 20px), 22px);
  padding-bottom: 24px;
  color: #fcfcfc;

  ${RWD_MD(css`
    font-size: min(max(0.9375vw, 18px), 20px);
  `)};
`;

const TableWrapper = styled.div`
  background-color: #22254a;
  padding: 1.5625vw 2vw;
  border-radius: 0 8px 8px 8px;

  ${RWD_LG(css`
    padding: 0;
    background-color: transparent;
  `)};
`;

const TableEl = styled.div`
  width: 100%;
  height: 100%;
  white-space: nowrap;
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: column;

  ${RWD_LG(css`
    overflow: hidden;
  `)};
`;

const Thead = styled.div`
  font-size: min(max(0.9375vw, 14px), 18px);
  color: #54587b;
  display: flex;
  padding-bottom: 1.5625vw;
  white-space: pre-wrap;

  ${RWD_LG(css`
    display: none;
  `)};
`;

const Th = styled.div`
  font-weight: 400;
  display: flex;
  align-items: center;
`;

const Tr = styled.div`
  border-bottom: 1px solid #3a3d5a;
  display: flex;
  position: relative;

  ${RWD_LG(css`
    align-items: center;
    flex-direction: column;
  `)};
`;

const Td = styled.div`
  color: #fcfcfc;
  padding: 1.041vw 0px;
  font-size: min(max(0.833vw, 14px), 16px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;

  width: 100%;

  &:last-child {
    justify-content: center;
  }
  overflow: ${(props) =>
    props.type === "StatusAction" ? "inherit" : "hidden"};

  ${RWD_LG(css`
    width: 60%;
  `)};
`;

const TextWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Tbody = styled.div`
  height: 100%;
  width: 100%;

  ${RWD_LG(css`
    display: none;
  `)};
`;

const TbodyContainer = styled.div`
  width: 100%;
  padding-top: 10px;
  ${RWD_LG(css`
    display: flex;
    flex-direction: column;
    padding: 10px;
    background: linear-gradient(
      155.14deg,
      rgba(255, 255, 255, 0) -2.13%,
      rgba(255, 255, 255, 0.15) 136.58%
    );
  `)};
`;

const StatusButton = styled.div`
  width: 100%;
  width: min(max(4.5833vw, 75px), 88px);
  padding: 0.3125vw 0.78125vw;
  border-radius: 100px;
  background-color: ${({ type }) => handleColorType(type).bg};
  display: flex;
  align-items: center;
  justify-content: center;

  ${RWD_MD(css`
    padding: 5px 10px;
  `)};
`;

const TargetIcon = styled.div`
  width: 25px;
  height: 25px;
  display: inline-flex;
  align-items: center;

  svg {
    width: 25px;
    height: 25px;
  }

  ${RWD_SM(css`
    width: 25px;
    height: 25px;

    svg {
      width: 25px;
      height: 25px;
    }
  `)};
`;

const TargetText = styled.div`
  padding-top: 5px;

  /* ${RWD_LG(css`
    padding: 0;
  `)}; */
`;

const TargetContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const DetailContainer = styled.div`
  /* background-color: #3a3d5a; */
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);

  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 3vw 1.5625vw 3vw 1.5625vw;
  font-family: Montserrat;
  position: relative;
  height: 100%;

  ${RWD_LG(css`
    padding: 40px 0px;
  `)};
`;

const DetailItem = styled.div`
  /* max-width: 16%; */
  width: 100%;
  /* border: 1px solid rgba(255, 255, 255, 0.15); */
  /* background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  ); */

  background-color: #3a3d5a;
  border-radius: 8px;
  padding: 20px;
  /* height: 120px; */
  display: flex;
  flex-direction: column;

  ${RWD_LG(css`
    padding: 10px;
  `)};
`;

const DetailItemTitle = styled.div`
  color: #0aecd1;
  font-size: min(max(0.833vw, 14px), 16px);
  padding-bottom: 12px;

  ${RWD_LG(css`
    align-self: flex-start;
    padding-bottom: 0;
  `)};
`;

const DetailItemContent = styled.div`
  color: #fcfcfc;
  /* font-size: 3.125vw; */
  font-size: 2.5vw;
  font-weight: bold;
  span {
    font-size: 1.0416vw;
  }

  ${RWD_LG(css`
    font-size: min(max(0.9375vw, 16px), 18px);
  `)};
`;

const DetailItemContentText = styled.div``;

const DetailItemContainer = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-between;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 20px;

  ${RWD_LG(css`
    width: 100%;
    grid-gap: 10px;
  `)};
`;

const DetailChartWrapper = styled.div`
  /* height: 120px; */
  min-width: 16%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${RWD_LG(css`
    display: none;
  `)};
`;

const DetailChart = styled.div`
  /* width: auto; */
  width: 100%;
  height: 6.25vw;
  /* background: #fcfcfc; */
`;

const DetailHistoryWrapper = styled.div`
  position: absolute;
  right: 1.5625vw;
  bottom: 1.0416vw;
  color: #fcfcfc;
  font-size: min(max(0.833vw, 14px), 16px);
  cursor: pointer;

  ${RWD_LG(css`
    right: 10px;
    bottom: 10px;
    font-size: 12px;
  `)};
`;

const CloseWrapper = styled.div`
  position: absolute;
  padding: 0.52vw 1.3vw;
  border-radius: 60px;
  /* width: min(max(7.0833vw, 80px), 100px); */
  background: linear-gradient(to bottom, #2f80ed 0%, #5620f0 100%);
  right: 1vw;
  top: 1vw;
  text-align: center;
  color: #fcfcfc;
  font-size: min(max(0.833vw, 14px), 16px);
  cursor: pointer;

  ${RWD_LG(css`
    /* right: 10px;
    bottom: 10px; */
    padding: 5px;
    top: 5px;
    font-size: 12px;
  `)};
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${(props) => (props.status ? "scaleY(-1)" : "scaleY(1)")};
  cursor: pointer;
  transition: all 0.3s;
  svg {
    & path {
      stroke: #54587b;
    }
  }
`;

const DeleteInitAlgoButton = styled.div`
  border: 1px solid #54587b;
  padding: 8px 0px;
  border-radius: 8px;
  width: 100%;
  text-align: center;
  color: #54587b;
`;

const ExchangeIcon = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  /* max-width: 125px; */
  height: 1.8vw;
  width: 100%;

  img {
    height: 1.8vw;
    object-fit: contain;
  }
`;

const TooltipWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  &:hover div {
    visibility: visible;
  }
`;

const TooltipContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const TooltipText = styled.div`
  visibility: hidden;
  /* width: auto; */
  background-color: #3a3d5a;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 0.833vw;
  white-space: nowrap;
  position: absolute;
  left: calc(1.2vw + 20px);
  z-index: 10;

  ${RWD_LG(css`
    right: calc(55px);
    left: initial;
  `)};
`;

const StatusWrapper = styled.div`
  display: flex;
`;

const StatusActionIcon = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: all 0.3s;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }

  svg {
    width: 30px;
    height: 30px;
  }
  & path {
    fill: ${({ type }) => handleStatusType(type).color};
    stroke: ${(props) => (props.type === "help" ? "#F05D2F" : "")};
  }
  &:hover {
    opacity: 0.8;
  }

  ${RWD_LG(css`
    width: 40px;
    height: 40px;

    svg {
      width: 40px;
      height: 40px;
    }
  `)};
`;

const SortContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${(props) => (props.status ? "scaleY(-1)" : "scaleY(1)")};
  transition: all 0.3s;
  padding-left: 10px;

  svg {
    & path {
      fill: #54587b;
    }
  }
`;

// ZoomIn
const SlideZoomInWrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.8);
`;

const SlideZoomInClose = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;

  & path {
    stroke: #fcfcfc;
  }
`;

const SlideZoomInContainer = styled.div`
  background-color: #22254a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  min-height: 85vh;
  height: auto;
  padding: 40px 45px;
  width: 85vw;
  position: relative;
  border-radius: 10px;
`;

const SlideZoomInItemWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SlideZoomInItemContainer = styled.div`
  height: calc(85vh - 80px);
  width: 100%;
`;

const AlgoActionModalWrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
`;

const AlgoActionModalContainer = styled.div`
  background-color: #fcfcfc;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100px;
  padding: 20px;
  /* min-height: 35.416vw; */
  height: auto;
  padding: 2.088vw 4.166vw;
  width: 41.66vw;
  border-radius: 8px;
`;

const AlgoActionModalTitle = styled.div`
  font-size: min(max(1.145vw, 20px), 22px);
`;

const AlgoActionModalInfoWrapper = styled.div`
  padding: 20px 0 30px 0;
  font-size: min(max(0.9375vw, 16px), 18px);
`;

const AlgoActionModalInfoList = styled.ul``;

const AlgoActionModalInfoItem = styled.li`
  display: flex;
  align-items: flex-start;
  padding: 5px 0;
`;

const AlgoActionModalInfoIcon = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;

  svg {
    width: 20px;
    height: 20px;
  }
`;

const AlgoActionModalInfoText = styled.div`
  padding-left: 5px;
`;

const AlgoActionModalButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AlgoActionModalButton = styled.button`
  width: 30%;
  padding: 1vw 0;
  background-color: #151944;
  color: #fcfcfc;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 60px;
  color: #fcfcfc;
  padding: 0.52vw 1.3vw;
`;

const CheckboxWrapper = styled.div`
  color: #151944;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CheckBox = styled.div`
  /* accent-color: #151944;
  width: 20px;
  height: 20px;
  margin: 0; */
  cursor: pointer;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;

  svg {
    width: 30px;
    height: 30px;
  }

  & path {
    fill: ${(props) => (props.isSubscribe ? "#1CAC2A" : "#54587b")};
  }

  /* ${RWD_SM(css`
    width: 30px;
    height: 30px;

    svg {
      width: 30px;
      height: 30px;
    }
  `)}; */
`;

const CurrentAmountWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Amount = styled.div`
  color: ${(props) => (props.type === "true" ? "#1CAC2A" : "#F05D2F")};
  /* padding-left: 5px; */
`;

const RwdHeader = styled.div`
  color: #fcfcfc;
  width: 40%;
  display: none;
  align-items: center;

  ${RWD_LG(css`
    display: flex;
  `)};
`;

const RwdHeaderItem = styled.div`
  font-size: min(max(0.833vw, 14px), 16px);
`;

const RwdBodyWrapper = styled.div`
  color: white;
  display: none;
  padding: 20px;

  ${RWD_LG(css`
    display: flex;
    width: 100%;
    background-color: #22254a;
    margin-bottom: 10px;
    border-radius: 8px;
  `)};

  ${RWD_SM(css`
    padding: 10px;
  `)};
`;

const RwdBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const RwdContentWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid #3a3d5a;
  padding: 10px 0;
`;

const RwdNotification = styled.div`
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const RwdContent = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  font-size: min(max(0.833vw, 14px), 16px);
  /* border: 1px solid red; */

  :last-child {
    width: 60%;
  }
`;

const RwdContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const RwdNickName = styled.div`
  font-size: min(max(0.833vw, 14px), 16px);
`;
const RwdTarget = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
const RwdAction = styled.div`
  width: 20%;

  display: flex;
  align-items: center;

  justify-content: flex-end;
`;

const RwdTimeInheritedWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RwdTime = styled.div`
  padding: 5px 0;
  font-size: 14px;
  color: #54587b;
`;

const RwdInherited = styled.div`
  padding: 5px 0;
  font-size: 14px;
  color: #fcfcfc;
`;

const RwdDetail = styled.div`
  padding: 8px 0px;
  width: 100%;
  text-align: center;
  color: #fcfcfc;
`;

const RwdAmount = styled.div`
  text-align: right;
  /* font-size: 12px; */
  color: #fcfcfc;
  font-size: 16px;
`;

const RwdAmountWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const RwdAmountSetting = styled.div`
  text-align: left;
  /* font-size: 12px; */
  color: #54587b;
  padding-right: 5px;
`;

const RwdCurrentStatus = styled.div`
  text-align: right;
  font-size: 16px;
  color: ${(props) => (props.type === "true" ? "#1CAC2A" : "#F05D2F")};
`;

const RwdButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
`;

const RwdButtonContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;
`;

const RwdButton = styled.div`
  padding: 8px 0px;
  width: 100%;
  border-radius: 8px;
  text-align: center;
  /* color: #0aecd1; */
  background-color: ${({ type }) => handleRwdColorType(type).bg};
  /* opacity: 0.5; */
  color: #fcfcfc;
  /* color: #fcfcfc; */
  cursor: pointer;
`;

const TabWrapper = styled.div`
  display: flex;
`;

const TabItem = styled.div`
  width: 110px;
  background-color: ${(props) => (props.activeTab ? "#22254a" : "")};
  text-align: center;
  padding: 8px 0px;
  border-radius: 8px 8px 0 0;
  color: ${(props) => (props.activeTab ? "#fcfcfc" : "#54587b")};
  margin-right: 5px;
  cursor: pointer;
  font-size: min(max(0.833vw, 14px), 16px);

  border-bottom: ${(props) =>
    props.activeTab ? "2px solid #0aecd1" : "1px solid trasparent"};

  ${RWD_SM(css`
    width: 33%;
  `)};
`;

const NoDataWrapper = styled.div`
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #54587b;
  font-size: min(max(1.145vw, 20px), 22px);
`;

const ZoomIn = ({ data, setIsOpen }) => {
  return (
    <SlideZoomInWrapper>
      <SlideZoomInContainer>
        <SlideZoomInClose onClick={() => setIsOpen(false)}>
          <Icons.Close />
        </SlideZoomInClose>
        <SlideZoomInItemWrapper>
          <SlideZoomInItemContainer>
            <AlgoDetailChart chartData={data} detail={true} />
          </SlideZoomInItemContainer>
        </SlideZoomInItemWrapper>
      </SlideZoomInContainer>
    </SlideZoomInWrapper>
  );
};

const ButtonType = (status) => {
  switch (status) {
    case "init":
      return (
        <StatusButton type={status}>
          <span>inital</span>
        </StatusButton>
      );
    case "running":
      return (
        <StatusButton type={status}>
          <span>running</span>
        </StatusButton>
      );
    case "stop":
      return (
        <StatusButton type={status}>
          <span>stop</span>
        </StatusButton>
      );
  }
};

// #endregion

// Chart
const AlgoDetailChart = ({ chartData, detail }) => {
  const detailOption = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },

    elements: {
      point: {
        radius: 1,
      },
    },

    scales: {
      x: {
        grid: {
          color: function () {
            return "transparent";
          },
        },
        ticks: {
          color: "#fcfcfc",
        },
      },
      y: {
        border: {
          display: true,
        },
        ticks: {
          color: "#fcfcfc",
        },

        grid: {
          color: function () {
            return "#54587B";
          },
        },
      },
    },
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },

    elements: {
      point: {
        radius: 1,
      },
    },

    scales: {
      x: {
        ticks: {
          display: false,
        },

        grid: {
          color: function () {
            return "transparent";
          },
        },
      },
      y: {
        border: {
          display: true,
        },
        ticks: {
          display: false,
        },

        grid: {
          color: function () {
            return "rgba(255, 255, 255, 0.3)";
          },
        },
      },
    },
  };
  const labels = chartData?.map(({ id }) => id);
  let totalProfit = 0;
  let profitColor = 0;
  const data = {
    labels,
    datasets: [
      {
        data: chartData?.map(({ profit }) => {
          totalProfit += profit;
          return totalProfit;
        }),
        backgroundColor: chartData?.map(({ profit }) => {
          profitColor += profit;
          if (profitColor > 0) {
            return "#0AECD1";
          } else {
            return "#E89924";
          }
        }),
      },
    ],
  };

  return <Bar options={detail ? detailOption : options} data={data} />;
};

const AlgoTable = ({ algoData, isTelegramVerified, getTargetUsdt }) => {
  const { addAlgoStatus } = useOutletContext();

  useEffect(() => {
    if (addAlgoStatus[0]) {
      setActiveTab("init");
    }
  }, [addAlgoStatus]);

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const [errorAlgo, setErrorAlgo] = useState([]);
  const [telegramModalIsOpen, setTelegramModalIsOpen] = useState(false);
  const [telegramModalType, setTelegramModalType] = useState("default");
  const [historyModalIsOpen, setHistoryModalIsOpen] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [entrustId, setEntrustId] = useState(null);
  const [subscribe, setSubscribe] = useState(null);
  const [activeTab, setActiveTab] = useState("running");
  const [detailData, setDetailData] = useState([]);

  const [joyrideIsRun, setJoyrideIsRun] = useState(false);
  const { search } = useLocation();

  const [_updateWatchingTutorial, { data, isSuccess, isError, error }] =
    useUpdateWatchingTutorialMutation();

  useEffect(() => {
    if (isSuccess) {
      // 多國語言
      const confirmText = LangString("confirm", "Confirm");
      const titleText = LangString("tutorial_complete", "Tutorial complete!");
      SuccessMySwal({
        title: titleText,
        showCancelButton: false,
        confirmButtonText: confirmText,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(`/v2/algo`);
        }
      });
    }
    if (isError) {
      ErrorMySwal({
        title:
          error?.data?.ResultLanguages[getLanguage()] || error?.data?.Message,
      });
    }
  }, [data, isError]);

  const handleJoyrideCallback = () => {
    _updateWatchingTutorial({
      body: {
        TutorialKey: "Algo",
      },
    });
  };

  const joyrideState = [
    {
      target: ".createAlgo_initTable",
      content: (
        <Lang
          datakey="algo_tutorial_5-1"
          defaultText="You can see the Algo you just created in the 'Initial' tab. Click the activate button to start this Algo."
        />
      ),
      placement: "right",
      disableBeacon: true,
      disableOverlayClose: true,
      callbackAction: true,
      finished: true,
      // function: handleJoyrideCallback,
    },
  ];

  useEffect(() => {
    const query = new URLSearchParams(search);
    const tutorial = query.get("tutorial");
    if (tutorial === "algo") {
      setActiveTab("init");
      setJoyrideIsRun(true);
    }
  }, [search]);

  // 複製時修改Amount Target Exchange
  const [copyAmount, setCopyAmount] = useState("");
  const [copyTarget, setCopyTarget] = useState("");
  const [copyExchange, setCopyExchange] = useState("");
  const [amountSettingModalIsOpen, setAmountSettingModalIsOpen] =
    useState(false);
  const [copyAlgoId, setCopyAlgoId] = useState("");
  const [copyRobotId, setCopyRobotId] = useState("");

  const tabStatus = [
    {
      value: "running",
      text: "Running",
    },
    {
      value: "init",
      text: "Inital",
    },
    {
      value: "stop",
      text: "Stop",
    },
  ];

  const [
    _startAccountAlgo,
    {
      data: startAccountAlgoData,
      isSuccess: startAccountAlgoIsSuccess,
      isError: startAccountAlgoIsError,
      error: startAccountAlgoError,
      isLoading: startAccountAlgoIsLoading,
    },
  ] = useStartAccountAlgoMutation();

  const [
    _stopAccountAlgo,
    {
      data: stopAccountAlgoData,
      isSuccess: stopAccountAlgoIsSuccess,
      isError: stopAccountAlgoIsError,
      error: stopAccountAlgoError,
    },
  ] = useStopAccountAlgoMutation();

  const [
    _closeAccountAlgo,
    {
      data: closeAccountAlgoData,
      isSuccess: closeAccountAlgoIsSuccess,
      isError: closeAccountAlgoIsError,
      error: closeAccountAlgoError,
    },
  ] = useCloseAccountAlgoMutation();

  const [
    _getAlgoDetail,
    {
      data: getAlgoDetailResponse,
      isSuccess: getAlgoDetailResponseIsSuccess,
      isError: getAlgoDetailResponseIsError,
      error: getAlgoDetailResponseError,
    },
  ] = useGetAlgoDetailMutation();

  const [
    _toggleAlgoSubscribe,
    {
      data: toggleAlgoSubscribeResponse,
      isSuccess: toggleAlgoSubscribeIsSuccess,
      isError: toggleAlgoSubscribeIsError,
      error: toggleAlgoSubscribeError,
    },
  ] = useToggleAlgoSubscribeMutation();

  const [
    _getAlgoOrderHistory,
    {
      data: getAlgoOrderHistoryData,
      isSuccess: getAlgoOrderHistoryDataIsSuccess,
      isError: getAlgoOrderHistoryDataIsError,
      error: getAlgoOrderHistoryDataError,
    },
  ] = useGetAlgoOrderHistoryMutation();

  const [
    _deleteInitAlgo,
    {
      data: deleteInitAlgoData,
      isSuccess: deleteInitAlgoIsSuccess,
      isError: deleteInitAlgoIsError,
      error: deleteInitAlgoError,
    },
  ] = useDeleteInitAlgoMutation();

  useEffect(() => {
    if (startAccountAlgoIsSuccess) {
      if (telegramModalType === "copy") {
        // 多國語言
        const title = LangString(
          "alert_algo_copy_success",
          "Algo Successfully Copied"
        );
        const confirmText = LangString("confirm", "Confirm");

        SuccessMySwal({
          title: title,
          showCancelButton: false,
          confirmButtonText: confirmText,
        });
      }

      if (telegramModalType === "success") {
        // 多國語言
        const title = LangString(
          "alert_algo_launch_success",
          "Algo Successfully Launched"
        );
        const text = LangString(
          "alert_algo_tg",
          "Enable Telegram Notifications?"
        );

        const confirmText = LangString("yes", "Yes");
        const cancelText = LangString("no", "No");

        SuccessMySwal({
          title: title,
          text: text,
          showCancelButton: true,
          cancelButtonText: cancelText,
          confirmButtonText: confirmText,
        }).then((result) => {
          if (result.isConfirmed) {
            _toggleAlgoSubscribe({
              body: {
                EntrustId: entrustId,
              },
            });
          }
        });
      }
      // setTelegramModalIsOpen(true);
    }

    if (startAccountAlgoIsError) {
      ErrorMySwal({
        title:
          startAccountAlgoError?.data?.ResultLanguages[getLanguage()] ||
          startAccountAlgoError?.data?.Message,
      });
    }
  }, [
    startAccountAlgoData,
    startAccountAlgoIsError,
    startAccountAlgoIsLoading,
  ]);

  useEffect(() => {
    if (getAlgoDetailResponseIsSuccess) {
      setDetailData(getAlgoDetailResponse);
    }

    if (getAlgoDetailResponseIsError) {
      console.log(getAlgoDetailResponseError?.data?.Message);
    }
  }, [getAlgoDetailResponse, getAlgoDetailResponseIsError]);

  useEffect(() => {
    if (stopAccountAlgoIsSuccess) {
      // Algo 停止成功

      // 多國語言
      const title = LangString("alert_update_success", "Update successful");
      const confirmText = LangString("confirm", "Confirm");

      SuccessMySwal({
        title: title,
        showCancelButton: false,
        confirmButtonText: confirmText,
      });
    }

    if (stopAccountAlgoIsError) {
      ErrorMySwal({
        title:
          stopAccountAlgoError?.data?.ResultLanguages[getLanguage()] ||
          stopAccountAlgoError?.data?.Message,
      });
    }
  }, [stopAccountAlgoData, stopAccountAlgoIsError]);

  useEffect(() => {
    if (closeAccountAlgoIsSuccess) {
      // MySwal.fire({
      //   icon: "success",
      //   title: "close Algo",
      //   showConfirmButton: false,
      //   timer: 1500,
      // });

      const title = LangString("alert_update_success", "Update successful");
      const confirmText = LangString("confirm", "Confirm");

      SuccessMySwal({
        title: title,
        showCancelButton: false,
        confirmButtonText: confirmText,
      });
    }

    if (closeAccountAlgoIsError) {
      ErrorMySwal({
        title:
          closeAccountAlgoError?.data?.ResultLanguages[getLanguage()] ||
          closeAccountAlgoError?.data?.Message,
      });
    }
  }, [closeAccountAlgoData, closeAccountAlgoIsError]);

  useEffect(() => {
    if (toggleAlgoSubscribeIsSuccess) {
      setTelegramModalIsOpen(false);
      // 多國語言
      const title = LangString("alert_update_success", "Update successful");
      const confirmText = LangString("confirm", "Confirm");

      SuccessMySwal({
        title: title,
        showCancelButton: false,
        confirmButtonText: confirmText,
      });
    }

    if (toggleAlgoSubscribeIsError) {
      ErrorMySwal({
        title:
          toggleAlgoSubscribeError?.data?.ResultLanguages[getLanguage()] ||
          toggleAlgoSubscribeError?.data?.Message,
      });
    }
  }, [toggleAlgoSubscribeResponse, toggleAlgoSubscribeIsError]);

  useEffect(() => {
    if (getAlgoOrderHistoryDataIsSuccess) {
      setHistoryData(getAlgoOrderHistoryData?.HistoryGroup);
    }
  }, [getAlgoOrderHistoryData, getAlgoOrderHistoryDataIsError]);

  useEffect(() => {
    if (deleteInitAlgoIsSuccess) {
      // 刪除Init Algo
      // 多國語言
      const title = LangString("alert_update_success", "Update successful");
      const confirmText = LangString("confirm", "Confirm");

      SuccessMySwal({
        title: title,
        showCancelButton: false,
        confirmButtonText: confirmText,
      });
    }

    if (deleteInitAlgoIsError) {
      ErrorMySwal({
        title:
          deleteInitAlgoError?.data?.ResultLanguages[getLanguage()] ||
          deleteInitAlgoError?.data?.Message,
      });
    }
  }, [deleteInitAlgoData, deleteInitAlgoIsError]);

  const closeAlgo = ({ Id }) => {
    const title = LangString(
      "alert_algo_closePosition",
      "Are you sure you want to close the position?"
    );

    const confirmText = LangString("confirm", "Confirm");
    const cancelText = LangString("cancel", "Cancel");

    WarningMySwal({
      title: title,
      showCancelButton: true,
      cancelButtonText: cancelText,
      confirmButtonText: confirmText,
    }).then((result) => {
      if (result.isConfirmed) {
        _closeAccountAlgo({
          body: {
            EntrustId: Id,
          },
        });
      }
    });
  };

  const toggleAlgo = ({
    Id,
    Status,
    IsClosed,
    IsSubscribe,
    AmountSetting,
    RobotId,
    ExchangeId,
    Target,
  }) => {
    if (Status === "running") {
      if (IsClosed) {
        // 多國語言
        const title = LangString(
          "alert_algo_stop",
          "Are you certain about deactivating your Algo?"
        );

        const confirmText = LangString("confirm", "Confirm");
        const cancelText = LangString("cancel", "Cancel");

        WarningMySwal({
          title: title,
          showCancelButton: true,
          cancelButtonText: cancelText,
          confirmButtonText: confirmText,
        }).then((result) => {
          if (result.isConfirmed) {
            _stopAccountAlgo({
              body: {
                EntrustId: Id,
                Mode: "Clear",
              },
            });
          }
        });
      } else {
        // 多國語言
        const title = LangString(
          "alert_algo_stopInfo",
          "You wish to stop your Algo, which has detected that it currently has active positions. Please select the termination method you prefer."
        );
        // 系統平倉
        const confirmText = LangString(
          "alert_algo_systemClose",
          "System Close Position"
        );

        const cancelText = LangString(
          "alert_algo_cancelOperation",
          "Cancel Operation"
        );

        // 使用者持倉
        const denyText = LangString(
          "alert_algo_userClose",
          "User Close Position"
        );
        ToggleAlgoMySwal({
          title: title,
          cancelButtonText: cancelText,
          confirmButtonText: confirmText,
          denyButtonText: denyText,
        }).then((result) => {
          if (result.isConfirmed) {
            _stopAccountAlgo({
              body: {
                EntrustId: Id,
                Mode: "Clear",
              },
            });
          } else if (result.isDenied) {
            _stopAccountAlgo({
              body: {
                EntrustId: Id,
                Mode: "Keep",
              },
            });
          }
        });
      }

      // MySwal.fire({
      //   icon: "info",
      //   title:
      //     "您希望停止您的Algo，偵測到該Algo目前還有持倉，請選擇您希望終止的方式",
      //   showDenyButton: true,
      //   showCancelButton: true,
      //   confirmButtonText: "系統平倉",
      //   cancelButtonText: "取消操作",
      //   denyButtonText: "使用者持倉",
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     _stopAccountAlgo({
      //       body: {
      //         EntrustId: Id,
      //         Mode: "Clear",
      //       },
      //     });
      //   } else if (result.isDenied) {
      //     _stopAccountAlgo({
      //       body: {
      //         EntrustId: Id,
      //         Mode: "Keep",
      //       },
      //     });
      //   }
      // });
    } else {
      if (Status === "stop") {
        setCopyAmount(AmountSetting);
        setAmountSettingModalIsOpen(true);
        setTelegramModalType("copy");
        setCopyAlgoId(Id);
        setCopyRobotId(RobotId);
        setCopyExchange(ExchangeId);
        setCopyTarget(Target);
      } else {
        setEntrustId(Id);
        setTelegramModalType("success");
        setSubscribe(IsSubscribe);
        _startAccountAlgo({
          body: {
            EntrustId: Id,
          },
        });
      }
    }
  };

  const copyAlgo = () => {
    // 新增 TargetKey ExchangeId
    _startAccountAlgo({
      body: {
        EntrustId: copyAlgoId,
        Amount: copyAmount,
        TargetKey: copyTarget,
        ExchangeId: copyExchange,
      },
    });

    setAmountSettingModalIsOpen(false);
  };

  const lang = useSelector(selectSystem).language;
  const robot = useSelector(selectSystem).airobots;

  const [language, setLanguage] = useState("");

  useEffect(() => {
    setLanguage(lang);
  }, [lang]);

  const handleDeleteInitAlgo = (Id, nickName, robotName) => {
    // 多國語言

    const chTitle = `您即將刪除一個未啟動的Algo： ${
      nickName ?? robotName
    } 。請仔細考慮這個操作，因為一旦刪除，將無法恢復。`;
    const enTitle = `You are about to delete an inactive Algo: ${
      nickName ?? robotName
    }. Please consider this action carefully, as it is irreversible once deleted.`;

    const confirmText = LangString("confirm", "Confirm");
    const cancelText = LangString("cancel", "Cancel");

    WarningMySwal({
      title: language === "En" ? enTitle : chTitle,
      showCancelButton: true,
      cancelButtonText: cancelText,
      confirmButtonText: confirmText,
    }).then((result) => {
      if (result.isConfirmed) {
        //  刪除Init Algo
        _deleteInitAlgo({
          body: {
            EntrustId: Id,
          },
        });
      }
    });
  };

  // table data
  const [tableData, setTableData] = useState([]);
  // const data = useSelector(selectAccount).accountAlgo;
  useEffect(() => {
    if (!algoData) return;
    setTableData(() => algoData.map((item) => ({ ...item, isOpen: false })));
  }, [algoData]);

  // table header
  const [tableHeader, setTableHeader] = useState([
    {
      id: 1,
      defaultText: "Action",
      datakey: "algo_table_statusaction",
      value: "StatusAction",
      width: "5%",
      styled: "center",
    },
    {
      id: 2,
      defaultText: "Status",
      datakey: "algo_table_status",
      value: "Status",
      width: "8%",
      styled: "center",
    },
    {
      id: 3,
      defaultText: "Notification",
      datakey: "algo_table_notification",
      value: "IsSubscribe",
      width: "8%",
      styled: "center",
    },
    {
      id: 4,
      defaultText: "Exchange",
      datakey: "algo_table_exchange",
      value: "Exchange",
      width: "10%",
    },

    {
      id: 5,
      defaultText: "AlgoTrade Name",
      datakey: "algo_table_algoname",
      value: "AlgoTradeName",
      width: "10%",
      sort: true,
      ascending: false,
    },
    {
      id: 6,
      defaultText: "Target",
      datakey: "algo_table_target",
      value: "Target",
      width: "8%",
      styled: "center",
    },
    {
      id: 7,
      defaultText: "Amount Setting",
      datakey: "algo_table_amount",
      value: "AmountSetting",
      width: "10%",
    },
    {
      id: 8,
      defaultText: "Current Amount",
      datakey: "algo_table_current",
      value: "CurrentAmount",
      width: "12%",
    },
    {
      id: 9,
      defaultText: "Inherited Loss",
      datakey: "algo_table_inheritedLoss",
      value: "InheritedLoss",
      width: "10%",
    },
    {
      id: 10,
      defaultText: "History MaxProfit",
      datakey: "algo_table_historymaxprofit",
      value: "HistoryMaxProfit",
      width: "12%",
    },

    {
      id: 11,
      defaultText: "Creation Time",
      datakey: "algo_table_createtime",
      value: "CreationTime",
      width: "12%",
      sort: true,
      ascending: false,
    },
    {
      id: 12,
      defaultText: "Action",
      datakey: "algo_table_action",
      value: "Action",
      width: "3%",
    },
  ]);

  const handleDataOpen = (id) => {
    _getAlgoDetail({
      body: {
        EntrustId: id,
      },
    });

    setTableData(
      tableData.filter((data) => {
        data.IsOpen = data.Id === id ? !data.IsOpen : false;
        return data;
      })
    );
  };

  const handleZoomIn = () => {
    setIsOpen(true);
  };

  // const getOpenavg = (data) => {
  //   if (!data || data?.length === 0) return false;
  //   return data[data.length - 1].openAvg;
  // };

  // const getSide = (data) => {
  //   if (!data || data?.length === 0) return false;
  //   return data[data.length - 1].side === "long" ? 1 : -1;
  // };

  const handleNotification = ({ Id, IsSubscribe }) => {
    // 有沒有驗證過Telegram
    if (isTelegramVerified) {
      // 多國語言
      const enableTitle = LangString(
        "alert_tg_enable",
        "Enable the Telegram push notification feature?"
      );

      const disableTitle = LangString(
        "alert_tg_disable",
        "Disable the Telegram push notification feature?"
      );

      const confirmText = LangString("confirm", "Confirm");
      const cancelText = LangString("cancel", "Cancel");

      InfoMySwal({
        title: IsSubscribe ? disableTitle : enableTitle,
        showCancelButton: true,
        cancelButtonText: cancelText,
        confirmButtonText: confirmText,
      }).then((result) => {
        if (result.isConfirmed) {
          _toggleAlgoSubscribe({
            body: {
              EntrustId: Id,
            },
          });
        }
      });
    } else {
      const title = LangString(
        "alert_tg_notVerified",
        "Not yet verified on Telegram"
      );

      const confirmText = LangString("alert_verify_now", "Verify now");
      const cancelText = LangString("alert_understand", "I understand");

      WarningMySwal({
        title: title,
        showCancelButton: true,
        cancelButtonText: cancelText,
        confirmButtonText: confirmText,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate({
            pathname: routes.setting_v2,
            search: "?verified=telegram",
          });
        }
      });

      // setTelegramModalIsOpen(true);
    }
  };

  const handleErrorModal = (data) => {
    setErrorAlgo(data);
    setErrorModalIsOpen(true);
  };

  const handleOpenHistory = ({ Id }) => {
    setHistoryModalIsOpen(true);
    _getAlgoOrderHistory({
      body: {
        EntrustId: Id,
      },
    });
  };

  const handleUpdateNickName = () => {};

  // RWD

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const [tableRwd, setTableRwd] = useState(false);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (windowSize[0] <= "1024") {
      setTableRwd(true);
    } else {
      setTableRwd(false);
    }
  }, [windowSize]);

  const handleSort = (type, ascending, sortable) => {
    if (sortable) {
      if (type === "AlgoTradeName") {
        const sortedData = handleSortData("NickName", ascending, algoData);
        setTableData(() =>
          sortedData.map((item) => ({
            ...item,
            isOpen: false,
          }))
        );
      } else {
        const sortedData = handleSortData(type, ascending, algoData);
        setTableData(() =>
          sortedData.map((item) => ({
            ...item,
            isOpen: false,
          }))
        );
      }

      setTableHeader(
        tableHeader.map((data) => {
          if (data.value === type) {
            return {
              ...data,
              ascending: !data.ascending,
            };
          } else {
            return data;
          }
        })
      );
    } else {
      return;
    }
  };

  const hanedleOpenAmount = () => {
    if (detailData) {
      const index = detailData?.CompleteTrades?.length;
      const lastData = detailData?.CompleteTrades?.[index - 1];
      if (!lastData?.profit) {
        return lastData?.openAmount;
      } else {
        return "N/A";
      }
    }
  };

  const handleOpenAvg = () => {
    if (detailData) {
      const index = detailData?.CompleteTrades?.length;
      const lastData = detailData?.CompleteTrades?.[index - 1];
      if (!lastData?.profit) {
        return lastData?.openAvg;
      } else {
        return "N/A";
      }
    }
  };

  const handleROI = (target) => {
    if (detailData) {
      const index = detailData?.CompleteTrades?.length;
      const lastData = detailData?.CompleteTrades?.[index - 1];
      if (!lastData?.profit) {
        return (
          ((getTargetUsdt(target) - lastData?.openAvg) / lastData?.openAvg) *
          lastData?.average *
          100 *
          Number(lastData?.side.toLowerCase() === "short" ? -1 : 1)
        ).toFixed(2);
      } else {
        return "N/A";
      }
    }

    // 現在價格從你那邊 websocket 抓的
    // 目前 MaxLaverage 我放 average
    // ROI 取 CompleteTrades 最後一筆
    // profit 等於 null 的時候才有
    // amount 和 avg_trading_price 和 ROI
    // 應該都是  profit = null 的時候有
    // 就是還沒有結倉的時候
    // 和手動平倉一樣
    // 照理來說 isClosed = false
    // CompleteTrades 最後一筆的 profit 會是 null
    // 如果有看到不符的再跟我
    //  ROI = ((現在價格-開倉均價)/開倉均價) * MaxLaverage * 100%
    //  (做多 1 |做空 -1) * (目前價格 - 開倉價格) / (目前價格)
    // CompleteTrades 最後一筆若為 null，表示尚未平倉
  };

  if (!algoData) return <div>no data</div>;
  return (
    <Wrapper className="createAlgo_initTable">
      <JoyrideComponent
        joyrideState={joyrideState}
        run={joyrideIsRun}
        joyrideType={"algo"}
      />
      {tableData.length > 0 && (
        <Title>
          <Lang datakey="algo_table_title" defaultText="AlgoTrade Details" />
        </Title>
      )}
      <TabWrapper>
        {tabStatus.map((data) => (
          <TabItem
            key={data.value}
            activeTab={activeTab === data.value}
            onClick={() => setActiveTab(data.value)}
          >
            {data.text}
          </TabItem>
        ))}
      </TabWrapper>
      {tableData.length > 0 && (
        <TableWrapper>
          {tableData.filter((algo) => algo.Status === activeTab).length > 0 ? (
            <TableEl>
              <Thead>
                {tableHeader.map((data) => (
                  <Th
                    // key={data.id}
                    style={{
                      width: `${data.width}`,
                      justifyContent: `${data.styled}`,
                      cursor: data.sort ? "pointer" : "default",
                    }}
                    key={data.value}
                    onClick={() => {
                      handleSort(data.value, !data.ascending, data.sort);
                    }}
                  >
                    {data.value?.toLowerCase() !== "action" && (
                      <Lang
                        datakey={data.datakey}
                        defaultText={data.defaultText}
                      />
                    )}
                    {data.sort && (
                      <SortContainer status={data.ascending}>
                        <Icons.Sort />
                      </SortContainer>
                    )}
                  </Th>
                ))}
              </Thead>
              {tableData
                .filter((algo) => algo.Status === activeTab)
                .map((data) => (
                  <RwdBodyWrapper key={data.Id}>
                    <RwdBodyContainer>
                      <RwdContentContainer>
                        <RwdNickName>
                          {data.NickName !== "null" ? (
                            <TextWrapper>
                              {data.NickName} (
                              <Lang
                                datakey={data.AlgoTradeName}
                                defaultText={""}
                              />
                              )
                            </TextWrapper>
                          ) : (
                            <Lang
                              datakey={data.AlgoTradeName}
                              defaultText={""}
                            />
                          )}
                        </RwdNickName>
                        <RwdNotification>
                          <CheckBox
                            type="checkbox"
                            onClick={() => handleNotification(data)}
                            isSubscribe={data.IsSubscribe}
                          >
                            <Icons.Telegram />
                          </CheckBox>
                        </RwdNotification>
                      </RwdContentContainer>

                      <RwdContentWrapper>
                        <RwdContentContainer>
                          <RwdContent>
                            <RwdTarget>
                              <TargetContainer>
                                <TargetIcon>
                                  {/* {(() => {
                                    switch (data.Target) {
                                      case "ETH":
                                        return <Icons.ETH />;
                                      case "BTC":
                                        return <Icons.BTC />;
                                      default:
                                        return;
                                    }
                                  })()} */}

                                  {robot
                                    .filter((el) => el.Id === data.RobotId)?.[0]
                                    ?.Targets.filter(
                                      (item) => item.Target === data.Target
                                    )?.[0]?.Icon ? (
                                    <img
                                      src={
                                        robot
                                          .filter(
                                            (el) => el.Id === data.RobotId
                                          )?.[0]
                                          ?.Targets.filter(
                                            (item) =>
                                              item.Target === data.Target
                                          )?.[0]?.Icon
                                      }
                                    />
                                  ) : (
                                    (() => {
                                      switch (data.Target) {
                                        case "ETH":
                                          return <Icons.ETH />;
                                        case "BTC":
                                          return <Icons.BTC />;
                                        default:
                                          return;
                                      }
                                    })()
                                  )}
                                  {/* <img
                                    src={
                                      robot
                                        .filter(
                                          (el) => el.Id === data.RobotId
                                        )?.[0]
                                        ?.Targets.filter(
                                          (item) => item.Target === data.Target
                                        )?.[0]?.Icon
                                    }
                                  /> */}
                                </TargetIcon>
                                <TargetText>{data.Target}</TargetText>
                              </TargetContainer>
                            </RwdTarget>
                          </RwdContent>
                          <RwdContent>
                            <RwdAmount>{data.CurrentAmount}</RwdAmount>

                            <RwdAmountWrapper>
                              <RwdAmountSetting>
                                $ {data.AmountSetting}
                              </RwdAmountSetting>

                              <RwdCurrentStatus
                                type={
                                  ((data.CurrentAmount - data.AmountSetting) /
                                    data.AmountSetting) *
                                    100 >
                                  0
                                    ? "true"
                                    : "false"
                                }
                              >
                                (
                                {(
                                  ((data.CurrentAmount - data.AmountSetting) /
                                    data.AmountSetting) *
                                  100
                                ).toFixed(3)}{" "}
                                %)
                              </RwdCurrentStatus>
                            </RwdAmountWrapper>
                          </RwdContent>
                        </RwdContentContainer>
                      </RwdContentWrapper>
                      <RwdTimeInheritedWrapper>
                        <RwdTime>{data.CreationTime}</RwdTime>
                        <RwdInherited>
                          {data.InheritedLoss ?? "N/A"}
                        </RwdInherited>
                      </RwdTimeInheritedWrapper>

                      {data.IsOpen && (
                        <DetailContainer key={data.Id}>
                          <DetailItemContainer>
                            <DetailItem>
                              <DetailItemTitle>
                                <Lang
                                  datakey="algo_inout"
                                  defaultText="in and out"
                                />
                              </DetailItemTitle>
                              <DetailItemContent>
                                <DetailItemContentText>
                                  {getAlgoDetailResponse?.CompleteTradesCount ??
                                    "N/A"}
                                </DetailItemContentText>
                              </DetailItemContent>
                            </DetailItem>
                            <DetailItem>
                              <DetailItemTitle>
                                <Lang
                                  datakey="algo_winning"
                                  defaultText="winning"
                                />
                              </DetailItemTitle>
                              <DetailItemContent>
                                <DetailItemContentText>
                                  {getAlgoDetailResponse?.WinRate !== null ? (
                                    <>
                                      {getAlgoDetailResponse?.WinRate.toFixed(
                                        1
                                      )}
                                      <span>%</span>
                                    </>
                                  ) : (
                                    "N/A"
                                  )}
                                </DetailItemContentText>
                              </DetailItemContent>
                            </DetailItem>
                            <DetailItem>
                              <DetailItemTitle>
                                <Lang datakey="add_roi" defaultText="ROI" />
                              </DetailItemTitle>
                              <DetailItemContent>
                                {!data.IsClosed ? (
                                  <>
                                    {handleROI(data.Target)}
                                    <span>%</span>
                                  </>
                                ) : (
                                  "N/A"
                                )}
                              </DetailItemContent>
                            </DetailItem>

                            <DetailItem>
                              <DetailItemTitle>OpenAmount</DetailItemTitle>
                              <DetailItemContent>
                                {!data.IsClosed ? (
                                  <>{hanedleOpenAmount()}</>
                                ) : (
                                  "N/A"
                                )}
                              </DetailItemContent>
                            </DetailItem>

                            <DetailItem>
                              <DetailItemTitle>OpenAvg</DetailItemTitle>
                              <DetailItemContent>
                                {!data.IsClosed ? (
                                  <>{handleOpenAvg()}</>
                                ) : (
                                  "N/A"
                                )}
                              </DetailItemContent>
                            </DetailItem>

                            <DetailChartWrapper
                              style={{
                                cursor:
                                  getAlgoDetailResponse?.CompleteTrades.length >
                                  0
                                    ? "pointer"
                                    : "default",
                              }}
                              onClick={() =>
                                getAlgoDetailResponse?.CompleteTrades.length >
                                  0 && handleZoomIn()
                              }
                            >
                              <DetailChart>
                                <AlgoDetailChart
                                  chartData={
                                    getAlgoDetailResponse?.CompleteTrades
                                  }
                                />
                              </DetailChart>
                            </DetailChartWrapper>

                            <DetailHistoryWrapper
                              onClick={() => handleOpenHistory(data)}
                            >
                              <Lang datakey="detail" defaultText="detail" />
                            </DetailHistoryWrapper>
                            {!data.IsClosed &&
                              data.Status.toLowerCase() === "running" && (
                                <CloseWrapper onClick={() => closeAlgo(data)}>
                                  <Lang
                                    datakey="algo_closePosition"
                                    defaultText="Close Position"
                                  />
                                </CloseWrapper>
                              )}
                          </DetailItemContainer>
                        </DetailContainer>
                      )}

                      <RwdButtonWrapper>
                        <RwdButtonContainer>
                          {(() => {
                            switch (data.Status) {
                              case "stop":
                                return (
                                  // <StatusActionIcon
                                  //   type={data.Status}
                                  //   onClick={() => toggleAlgo(data)}
                                  // >
                                  //   <Icons.Copy />
                                  // </StatusActionIcon>

                                  <>
                                    {data.ErrorStatus?.toLowerCase() !==
                                    undefined ? (
                                      // <TooltipWrapper
                                      //   onClick={() => handleErrorModal(data)}
                                      // >
                                      //   <TooltipContainer>
                                      //     <StatusActionIcon type={"help"}>
                                      //       <Icons.Tooltip />
                                      //     </StatusActionIcon>
                                      //   </TooltipContainer>
                                      //   <TooltipText>
                                      //     {data.ErrorStatus}
                                      //   </TooltipText>
                                      // </TooltipWrapper>
                                      <RwdButton
                                        type={"help"}
                                        onClick={() => handleErrorModal(data)}
                                      >
                                        Help
                                      </RwdButton>
                                    ) : (
                                      <RwdButton
                                        type={data.Status}
                                        onClick={() => toggleAlgo(data)}
                                      >
                                        Copy
                                      </RwdButton>
                                    )}
                                  </>
                                );
                              case "running":
                                return (
                                  <RwdButton
                                    type={data.Status}
                                    onClick={() => toggleAlgo(data)}
                                  >
                                    Stop
                                  </RwdButton>
                                );
                              case "init":
                                return (
                                  <>
                                    {data.ErrorStatus?.toLowerCase() !==
                                    undefined ? (
                                      <RwdButton
                                        type={"help"}
                                        onClick={() => handleErrorModal(data)}
                                      >
                                        Help
                                      </RwdButton>
                                    ) : (
                                      // <RwdButton
                                      //   type={data.Status}
                                      //   onClick={() => toggleAlgo(data)}
                                      // >
                                      //   Start
                                      // </RwdButton>

                                      <>
                                        {tableData.filter(
                                          (el) =>
                                            el.Status.toLowerCase() ===
                                              "running" &&
                                            el.RobotId === data.RobotId
                                        ).length > 0 ? (
                                          <RwdButton
                                            type={"pending"}
                                            style={{
                                              pointerEvents: "none",
                                            }}
                                            // onClick={() =>
                                            //   toggleAlgo(data)
                                            // }
                                          >
                                            Start
                                          </RwdButton>
                                        ) : (
                                          <RwdButton
                                            type={data.Status}
                                            onClick={() => toggleAlgo(data)}
                                          >
                                            Start
                                          </RwdButton>
                                        )}
                                      </>
                                    )}
                                  </>
                                );
                            }
                          })()}
                          {data.Status === "init" && (
                            <DeleteInitAlgoButton
                              onClick={() =>
                                handleDeleteInitAlgo(
                                  data.Id,
                                  data.NickName,
                                  data.AlgoTradeName
                                )
                              }
                            >
                              Delete
                            </DeleteInitAlgoButton>
                          )}
                        </RwdButtonContainer>
                      </RwdButtonWrapper>
                      {data.Status !== "init" && (
                        <RwdDetail>
                          <ActionContainer
                            status={data.IsOpen}
                            onClick={() => handleDataOpen(data.Id)}
                          >
                            <Icons.Arrow />
                          </ActionContainer>
                        </RwdDetail>
                      )}
                    </RwdBodyContainer>
                  </RwdBodyWrapper>
                ))}

              <Tbody>
                {tableData
                  .filter((algo) => algo.Status === activeTab)
                  .map((data, index) => (
                    <TbodyContainer key={index}>
                      <Tr key={index}>
                        {tableHeader.map((item) => (
                          <Td
                            key={item.value}
                            type={item.value}
                            // key={item.id}
                            style={{
                              width: `${item.width}`,
                              justifyContent: `${item.styled}`,
                            }}
                          >
                            {(() => {
                              switch (item.value?.toLowerCase()) {
                                case "statusaction":
                                  switch (data.Status) {
                                    case "stop":
                                      return (
                                        // <StatusActionIcon
                                        //   type={data.Status}
                                        //   onClick={() => toggleAlgo(data)}
                                        // >
                                        //   <Icons.Copy />
                                        // </StatusActionIcon>

                                        <div>
                                          {data.ErrorStatus?.toLowerCase() !==
                                          undefined ? (
                                            <TooltipWrapper
                                              onClick={() =>
                                                handleErrorModal(data)
                                              }
                                            >
                                              <TooltipContainer>
                                                <StatusActionIcon type={"help"}>
                                                  <Icons.Tooltip />
                                                </StatusActionIcon>
                                              </TooltipContainer>
                                              <TooltipText>
                                                {data.ErrorStatus}
                                              </TooltipText>
                                            </TooltipWrapper>
                                          ) : (
                                            <StatusActionIcon
                                              type={data.Status}
                                              onClick={() => toggleAlgo(data)}
                                            >
                                              <Icons.Copy />
                                            </StatusActionIcon>
                                          )}
                                        </div>
                                      );
                                    case "running":
                                      return (
                                        <StatusActionIcon
                                          type={data.Status}
                                          onClick={() => toggleAlgo(data)}
                                        >
                                          <Icons.Stop />
                                        </StatusActionIcon>
                                      );
                                    case "init":
                                      return (
                                        <div>
                                          {data.ErrorStatus?.toLowerCase() !==
                                          undefined ? (
                                            <TooltipWrapper
                                              onClick={() =>
                                                handleErrorModal(data)
                                              }
                                            >
                                              <TooltipContainer>
                                                <StatusActionIcon type={"help"}>
                                                  <Icons.Tooltip />
                                                </StatusActionIcon>
                                              </TooltipContainer>
                                              <TooltipText>
                                                {data.ErrorStatus}
                                              </TooltipText>
                                            </TooltipWrapper>
                                          ) : (
                                            // 不可新增相同的機器人
                                            // <>
                                            //   {tableData.filter(
                                            //     (el) =>
                                            //       el.Status.toLowerCase() ===
                                            //         "running" &&
                                            //       el.RobotId === data.RobotId
                                            //   ).length > 0 ? (
                                            //     <StatusActionIcon
                                            //       type={"pending"}
                                            //       style={{
                                            //         pointerEvents: "none",
                                            //       }}
                                            //       // onClick={() =>
                                            //       //   toggleAlgo(data)
                                            //       // }
                                            //     >
                                            //       <Icons.Play />
                                            //     </StatusActionIcon>
                                            //   ) : (
                                            //     <StatusActionIcon
                                            //       type={data.Status}
                                            //       onClick={() =>
                                            //         toggleAlgo(data)
                                            //       }
                                            //     >
                                            //       <Icons.Play />
                                            //     </StatusActionIcon>
                                            //   )}
                                            // </>

                                            <StatusActionIcon
                                              type={data.Status}
                                              onClick={() => toggleAlgo(data)}
                                            >
                                              <Icons.Play />
                                            </StatusActionIcon>
                                          )}
                                        </div>
                                      );
                                  }
                                case "exchange":
                                  return (
                                    <ExchangeIcon>
                                      {(() => {
                                        switch (data[item.value]) {
                                          case "binance":
                                            return <img src={Binance} />;
                                          case "bybit":
                                            return <img src={Bybit} />;
                                          case "testbybit":
                                            return (
                                              <img
                                                src={Bybit}
                                                style={{
                                                  background:
                                                    "rgba(255,255, 255, 0.2)",
                                                }}
                                              />
                                            );
                                          case "ig":
                                            return <img src={Ig} />;

                                          default:
                                            break;
                                        }
                                      })()}
                                      {/* {data[item.value] === "binance" ? (
                                        // <Icons.Binance />
                                        <img src={Binance} />
                                      ) : (
                                        <img src={Bybit} />
                                        // <Icons.Bybit />
                                      )} */}
                                    </ExchangeIcon>
                                  );
                                case "algotradename":
                                  if (data?.NickName !== "null")
                                    return (
                                      <TextWrapper>
                                        {data.NickName} (
                                        <Lang
                                          datakey={data.AlgoTradeName}
                                          defaultText={""}
                                        />
                                        )
                                      </TextWrapper>
                                    );

                                  return <>{data[item.value]}</>;

                                case "action":
                                  return (
                                    <ActionContainer
                                      status={data.IsOpen}
                                      onClick={() =>
                                        data.Status !== "init"
                                          ? handleDataOpen(data.Id)
                                          : handleDeleteInitAlgo(
                                              data.Id,
                                              data.NickName,
                                              data.AlgoTradeName
                                            )
                                      }
                                    >
                                      {data.Status !== "init" ? (
                                        <Icons.Arrow />
                                      ) : (
                                        <Icons.Close />
                                      )}
                                    </ActionContainer>
                                  );
                                case "status":
                                  return (
                                    <StatusWrapper>
                                      {ButtonType(data[item.value])}
                                    </StatusWrapper>
                                  );
                                case "inheritedloss":
                                  return <div>{data[item.value] ?? "N/A"}</div>;
                                case "creationtime":
                                  return (
                                    <TextWrapper>
                                      {dateTimeFormat(data[item.value])}
                                    </TextWrapper>
                                  );
                                case "target":
                                  return (
                                    <TargetContainer>
                                      <TargetIcon>
                                        {robot
                                          .filter(
                                            (el) => el.Id === data.RobotId
                                          )?.[0]
                                          ?.Targets.filter(
                                            (item) =>
                                              item.Target === data.Target
                                          )?.[0]?.Icon ? (
                                          <img
                                            src={
                                              robot
                                                .filter(
                                                  (el) => el.Id === data.RobotId
                                                )?.[0]
                                                ?.Targets.filter(
                                                  (item) =>
                                                    item.Target === data.Target
                                                )?.[0]?.Icon
                                            }
                                          />
                                        ) : (
                                          (() => {
                                            switch (data.Target) {
                                              case "ETH":
                                                return <Icons.ETH />;
                                              case "BTC":
                                                return <Icons.BTC />;
                                              default:
                                                return;
                                            }
                                          })()
                                        )}
                                      </TargetIcon>
                                      <TargetText>{data.Target}</TargetText>
                                    </TargetContainer>
                                  );

                                case "issubscribe":
                                  return (
                                    <CheckboxWrapper>
                                      <CheckBox
                                        type="checkbox"
                                        onClick={() => handleNotification(data)}
                                        isSubscribe={data[item.value]}
                                      >
                                        <Icons.Success />
                                      </CheckBox>
                                    </CheckboxWrapper>
                                  );

                                case "currentamount":
                                  return (
                                    <CurrentAmountWrapper>
                                      {data[item.value]}{" "}
                                      {(data[item.value] - data.AmountSetting) /
                                        data.AmountSetting !==
                                        0 && (
                                        <Amount
                                          type={
                                            ((data[item.value] -
                                              data.AmountSetting) /
                                              data.AmountSetting) *
                                              100 >
                                            0
                                              ? "true"
                                              : "false"
                                          }
                                        >
                                          (
                                          {(
                                            ((data[item.value] -
                                              data.AmountSetting) /
                                              data.AmountSetting) *
                                            100
                                          ).toFixed(3)}
                                          %)
                                        </Amount>
                                      )}
                                    </CurrentAmountWrapper>
                                  );

                                default:
                                  return (
                                    <TextWrapper>
                                      {data[item.value]}
                                    </TextWrapper>
                                  );
                              }
                            })()}
                          </Td>
                        ))}
                      </Tr>
                      {/* data.IsOpen */}
                      {data.IsOpen && (
                        <DetailContainer key={`detail_${index}`}>
                          <DetailItemContainer>
                            <DetailItem>
                              <DetailItemTitle>
                                <Lang
                                  datakey="algo_inout"
                                  defaultText="in and out"
                                />
                              </DetailItemTitle>
                              <DetailItemContent>
                                <DetailItemContentText>
                                  {getAlgoDetailResponse?.CompleteTradesCount ??
                                    "N/A"}
                                </DetailItemContentText>
                              </DetailItemContent>
                            </DetailItem>
                            <DetailItem>
                              <DetailItemTitle>
                                <Lang
                                  datakey="algo_winning"
                                  defaultText="winning"
                                />
                              </DetailItemTitle>
                              <DetailItemContent>
                                <DetailItemContentText>
                                  {getAlgoDetailResponse?.WinRate !== null ? (
                                    <>
                                      {getAlgoDetailResponse?.WinRate.toFixed(
                                        1
                                      )}
                                      <span>%</span>
                                    </>
                                  ) : (
                                    "N/A"
                                  )}
                                </DetailItemContentText>
                              </DetailItemContent>
                            </DetailItem>

                            <DetailChartWrapper
                              style={{
                                cursor:
                                  getAlgoDetailResponse?.CompleteTrades.length >
                                  0
                                    ? "pointer"
                                    : "default",
                              }}
                              onClick={() =>
                                getAlgoDetailResponse?.CompleteTrades.length >
                                  0 && handleZoomIn()
                              }
                            >
                              <DetailChart>
                                <AlgoDetailChart
                                  chartData={
                                    getAlgoDetailResponse?.CompleteTrades
                                  }
                                />
                              </DetailChart>
                            </DetailChartWrapper>

                            <DetailItem>
                              <DetailItemTitle>OpenAmount</DetailItemTitle>
                              <DetailItemContent>
                                {!data.IsClosed ? (
                                  <>{hanedleOpenAmount()}</>
                                ) : (
                                  "N/A"
                                )}
                              </DetailItemContent>
                            </DetailItem>

                            <DetailItem>
                              <DetailItemTitle>OpenAvg</DetailItemTitle>
                              <DetailItemContent>
                                {!data.IsClosed ? (
                                  <>{handleOpenAvg()}</>
                                ) : (
                                  "N/A"
                                )}
                              </DetailItemContent>
                            </DetailItem>

                            <DetailItem>
                              <DetailItemTitle>
                                <Lang datakey="add_roi" defaultText="ROI" />
                              </DetailItemTitle>
                              <DetailItemContent>
                                <DetailItemContent>
                                  {!data.IsClosed ? (
                                    <>
                                      {handleROI(data.Target)}
                                      <span>%</span>
                                    </>
                                  ) : (
                                    "N/A"
                                  )}
                                </DetailItemContent>
                              </DetailItemContent>
                            </DetailItem>

                            <DetailHistoryWrapper
                              onClick={() => handleOpenHistory(data)}
                            >
                              <Lang datakey="detail" defaultText="detail" />
                            </DetailHistoryWrapper>

                            {!data.IsClosed &&
                              data.Status.toLowerCase() === "running" && (
                                <CloseWrapper onClick={() => closeAlgo(data)}>
                                  <Lang
                                    datakey="algo_closePosition"
                                    defaultText="Close Position"
                                  />
                                </CloseWrapper>
                              )}
                          </DetailItemContainer>

                          {/* <DetailChartWrapper>
                            <DetailChart>
                              <AlgoDetailChart
                                chartData={getAlgoDetailResponse?.CompleteTrades}
                              />
                            </DetailChart>
                          </DetailChartWrapper> */}
                        </DetailContainer>
                      )}
                    </TbodyContainer>
                  ))}
              </Tbody>
            </TableEl>
          ) : (
            <TableEl>
              <NoDataWrapper>
                <Lang datakey="nodata" defaultText="No data available" />
              </NoDataWrapper>
            </TableEl>
          )}
        </TableWrapper>
      )}
      {isOpen && tableData.length > 0 && (
        <ZoomIn
          data={getAlgoDetailResponse?.CompleteTrades}
          setIsOpen={setIsOpen}
        />
      )}
      {errorModalIsOpen && (
        <ErrorModal
          setErrorModalIsOpen={setErrorModalIsOpen}
          type={errorAlgo?.ErrorStatus}
          errorAlgo={errorAlgo}
        />
      )}
      {/* telegramModalIsOpen */}
      {telegramModalIsOpen && (
        <TelegramModal
          setTelegramModalIsOpen={setTelegramModalIsOpen}
          isTelegramVerified={isTelegramVerified}
          type={telegramModalType}
          subscribeFun={_toggleAlgoSubscribe}
          entrustId={entrustId}
          subscribe={subscribe}
        />
      )}
      {historyModalIsOpen && (
        <HistoryModal
          setHistoryModalIsOpen={setHistoryModalIsOpen}
          data={historyData}
        />
      )}

      {/* <AlgoActionModal /> */}

      {amountSettingModalIsOpen && (
        <SettingAmountModal
          robotId={copyRobotId}
          copyAlgo={copyAlgo}
          copyAmount={copyAmount}
          setCopyAmount={setCopyAmount}
          copyTarget={copyTarget}
          setCopyTarget={setCopyTarget}
          copyExchange={copyExchange}
          setCopyExchange={setCopyExchange}
          setAmountSettingModalIsOpen={setAmountSettingModalIsOpen}
        />
      )}
    </Wrapper>
  );
};

export default AlgoTable;
