import { createApi } from "@reduxjs/toolkit/query/react";
import { makeUrlencoded } from "./common";
import defaultBaseQuery from "./query/defaultBaseQueryV2";

export const algoOrderApi = createApi({
  reducerPath: "algoOrderApi",
  baseQuery: defaultBaseQuery,
  endpoints: (builder) => ({
    getAlgoOrderHistory: builder.mutation({
      query: ({ body }) => ({
        url: "AlgoOrder/GetAlgoOrderHistory",
        method: "POST",
        body: makeUrlencoded(body),
      }),
    }),
  }),
});

export const { useGetAlgoOrderHistoryMutation } = algoOrderApi;
