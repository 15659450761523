import Announcement from './Announcement';

const Main = ({ name }) => {
  return (
    <div className='mx-auto md:ml-0 pb-10'>
      <h2 className='text-2xl'>HI, {name.toUpperCase()}</h2>
      <Announcement />
    </div>
  )
}

export default Main
