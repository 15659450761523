import styled, { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";

import Bybit_Ch from "../../images/apikeypermission/bybitApiKeyPermission_ch.jpg";
import Bybit_En from "../../images/apikeypermission/bybitApiKeyPermission_en.png";
import Binance from "../../images/apikeypermission/binanceApiKeyPermission.png";

import { getLanguage } from "../../services/common";

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
`;

const Container = styled.div`
  background-color: #fcfcfc;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100px;
  height: auto;
  padding: 40px;
  width: 41.66vw;
  max-width: 800px;
  border-radius: 8px;

  ${RWD_LG(css`
    width: 70%;
  `)};

  ${RWD_MD(css`
    padding: 20px 40px;
  `)};

  ${RWD_SM(css`
    width: 90%;
    padding: 20px;
  `)};
`;

const ImgWrapper = styled.div`
  width: 80%;
  height: 90%;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`;

const ImgModal = ({ exchange, imgModalIsOpen, setImgModalIsOpen }) => {
  return (
    <Wrapper onClick={() => setImgModalIsOpen(false)}>
      <ImgWrapper>
        <img
          src={
            exchange === 1
              ? Binance
              : getLanguage() === "En"
              ? Bybit_En
              : Bybit_Ch
          }
        />
      </ImgWrapper>
    </Wrapper>
  );
};

export default ImgModal;
