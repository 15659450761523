import styled, { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";

import Lang from "../../../global/Lang";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  /* height: 100%;
  min-height: 45vh; */
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Title = styled.div`
  font-size: min(max(1.25vw, 22px), 24px);
  color: #fcfcfc;
  padding-bottom: 20px;

  ${RWD_MD(css`
    font-size: min(max(1.0416vw, 18px), 20px);
  `)};
`;

const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;
  color: #fcfcfc;

  ${RWD_SM(css`
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0px;
  `)};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${RWD_SM(css`
    padding-bottom: 10px;
  `)};
`;

const AmountContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  ${RWD_SM(css`
    padding-bottom: 10px;
  `)};
`;

const InputEl = styled.input`
  font-size: min(max(1.0416vw, 18px), 20px);
  text-align: center;
  /* padding: 1.0416vw 1.5625vw; */
  padding: 10px 20px;
  border: 1px solid;
  border-radius: 8px;
  width: 100%;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  color: #fcfcfc;

  border-color: ${(props) =>
    props.error ? "#F05D2F" : "rgba(255, 255, 255, 0.15)"};

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 16px), 18px);
  `)};
`;

const Label = styled.label`
  padding: 10px 0;
  font-size: min(max(1.0416vw, 18px), 20px);

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 16px), 18px);
  `)};
`;

const CheckInformation = ({ name, setName, amount }) => {
  return (
    <Wrapper>
      <Title>
        <Lang
          datakey="mining_package_inforisk"
          defaultText="Mining Package Information and Risk"
        />
      </Title>

      <InputWrapper>
        <InputContainer>
          <Label>
            <Lang
              datakey="mining_package_name"
              defaultText="Mining Package Name"
            />
          </Label>
          <InputEl value={name} onChange={(e) => setName(e.target.value)} />
        </InputContainer>

        <AmountContainer>
          <Label>
            <Lang datakey="deposit_amount" defaultText="Deposit Amount" />
          </Label>
          <InputEl value={amount} disabled />
        </AmountContainer>
      </InputWrapper>
    </Wrapper>
  );
};

export default CheckInformation;
