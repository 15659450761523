import { useEffect, useState } from "react";
import styled from "styled-components";
import { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";

import Profile from "./Profile";

// 語言切換
import Lang from "../../global/Lang";

const Wrapper = styled.div`
  height: 100%;
  padding-bottom: 100px;
`;

const Title = styled.div`
  font-size: min(max(1.25vw, 22px), 24px);
  color: #fcfcfc;
  padding-right: 10px;
  text-transform: capitalize;
  padding-bottom: 20px;

  ${RWD_MD(css`
    font-size: min(max(1.145vw, 20px), 22px);
  `)};
`;

const ContentWrapper = styled.div`
  padding: 30px 35px;
  background-color: #22254a;
  border-radius: 8px;

  ${RWD_MD(css`
    padding: 20px 25px;
  `)};

  ${RWD_SM(css`
    padding: 20px 15px;
  `)};
`;

const Setting = () => {
  return (
    <Wrapper>
      <Title>
        <Lang datakey="setting_title" defaultText="Settings" />
      </Title>
      <ContentWrapper>
        <Profile />
      </ContentWrapper>
    </Wrapper>
  );
};

export default Setting;
