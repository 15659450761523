import { Link } from 'react-router-dom';
import { useData } from '../../utils/context';

// --- images ---
import logo_light from '../../img/ava_loginLogo_light.png';
import logo_dark from '../../img/ava_logo_dark.png';

const Logo = ({ className }) => {
  const { isDarkMode } = useData();

  return (
    <>
      {/* --- logo --- */}
      <Link to='/AVA' className={`inline-block ${className}`}>
        <img className='inline-block w-full h-[80px] object-contain' src={isDarkMode ? logo_dark : logo_light} alt='' loading='lazy' />
      </Link>
    </>
  )
}

export default Logo
