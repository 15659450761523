import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { API_GetAccountVerifyInfo } from '../../../utils/api';
import { Button } from '@mui/material';

const InfoItem = styled.div`
  padding: .3rem 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-align: left;

  > *:first-child {
    width: 8rem;
    color: #888;
  }
  > *:last-child {
    flex: 1;
  }

  img {
    width: 100%;
    object-fit: contain;
  }
  input {
    border: 1px solid #ccc;
    padding: .2rem .5rem;
    border-radius: 4px;
  }
`;

const WithdrawalAddress = styled.div`
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const InfoAction = styled.div`
  padding-top: 1rem;
  > *:last-child {
    margin-left: 1rem;
  }
`;

const WithdrawalInfo = ({ AccountId }) => {
  const handleGetInfo = async () => {
    const body = {
      AccountId,
      Type: "Withdrawal",
    };
    const res = await API_GetAccountVerifyInfo(body);
    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return UseSwal(res.Message);
    setData(res?.Info);
  };

  const getWithdrawalStatus = () => {
    if (!data) return;
    if (data.WithdrawalAddress === null) return "未申請";
    if (data.VerifyStatus === 0) return "處理中";
    if (data.VerifyStatus === 1) return "驗證通過";
    return "";
  };

  const handlePass = () => {

  };

  const handleAbort = () => {

  };

  const [data, setData] = useState();

  useEffect(() => {
    handleGetInfo();
  }, [AccountId]);

  return (
    <div>
      <div>
        <InfoItem>
          <div>帳號(信箱)</div>
          <div>{AccountId}</div>
        </InfoItem>
        <InfoItem>
          <div>提領地址</div>
          <WithdrawalAddress>
            {
            data?.WithdrawalAddress
            ?? <>
                <input /><Button>設定</Button>
               </>
            }
          </WithdrawalAddress>
        </InfoItem>
        <InfoItem>
          <div>地址編碼方式</div>
          <div>
            {data?.WithdrawalAddressCrypto}
          </div>
        </InfoItem>
        <InfoItem>
          <div>目前驗證狀態</div>
          <div>{getWithdrawalStatus()}</div>
        </InfoItem>
        <InfoAction>
            <Button
              variant="contained"
              onClick={handlePass}
              disabled={data?.VerifyStatus !== 0 || !data?.WithdrawalAddress}
            >
              通過
            </Button>
            <Button
            variant="outlined"
            color="error"
            onClick={handleAbort}
            disabled={data?.VerifyStatus !== 0 || !data?.WithdrawalAddress}
          >
            未通過
          </Button>
        </InfoAction>
      </div>
    </div>
  )
}

export default WithdrawalInfo
