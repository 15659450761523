import { useEffect, useState } from 'react';
import { TableCell, TableRow } from '@mui/material';

const ListReturnRow = ({ data, AccountID, UserAddress, updateGroup, handleSetUpdateGroup }) => {
    const handleCheckboxOnChange = () => {
        if (data.IsReturn === true) return;

        handleSetUpdateGroup(!isChecked, AccountID, UserAddress, data);
    };

    const getIsChecked = () => {
        return (
            data.IsReturn ||
            updateGroup.filter((item) => (
                item.AccountID === AccountID
                && item.UserAddress === UserAddress
                && item.Periods === data.Periods
                && item.AhftGroupID === data.AhftGroupID
            )).length > 0
        );
    };

    const [isChecked, setIsChecked] = useState(getIsChecked());

    useEffect(() => {
        setIsChecked(getIsChecked());
    }, [updateGroup]);

    return (
        <TableRow
            sx={{
                '&': { cursor: 'pointer' },
                '&:hover': { background: '#eeea' },
            }}
            onClick={handleCheckboxOnChange}
        >
            <TableCell padding="checkbox">
                <input
                    type='checkbox'
                    checked={isChecked}
                    readOnly
                    disabled={data.IsReturn}
                />
            </TableCell>
            <TableCell>{data.AhftGroupID}</TableCell>
            <TableCell>{data.Periods}</TableCell>
            <TableCell>{data.ReturnType}</TableCell>
            <TableCell>{data.ThisPeriodInputAmount}</TableCell>
            <TableCell>{data.EndTime}</TableCell>
            <TableCell>{data.ReturnAmount}</TableCell>
        </TableRow>
    )
}

export default ListReturnRow
