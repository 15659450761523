import styled, { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";
import { useEffect, useState, useRef, Fragment } from "react";

import JoyrideComponent from "../../../global/JoyrideComponent";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectSystem } from "../../../slices/systemSlice";
import Lang from "../../../global/Lang";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  /* height: 100%;
  min-height: 45vh; */
`;

const Title = styled.div`
  font-size: min(max(1.25vw, 22px), 24px);
  color: #fcfcfc;
  padding-bottom: 20px;

  ${RWD_MD(css`
    font-size: min(max(1.0416vw, 18px), 20px);
  `)};
`;

const CheckboxContainer = styled.div`
  font-size: min(max(0.833vw, 14px), 16px);
  padding-left: 10px;
  color: #fcfcfc;
  width: 100%;
`;

const CheckboxWrapper = styled.label`
  color: #151944;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 0;
  ${RWD_MD(css`
    justify-content: space-between;
  `)};
`;

const CheckBox = styled.input.attrs({ type: "checkbox" })`
  accent-color: #3a3d5a;
  width: 20px;
  height: 20px;
  /* transform: scale(1.5); */
`;

const Container = styled.div`
  width: 80%;
  height: 550px;
  overflow: auto;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  border-radius: 8px;
  padding: 1.0416vw 1.5625vw;
  border: 1px solid rgba(255, 255, 255, 0.15);
  color: #fcfcfc;

  &::-webkit-scrollbar-track {
    border-radius: 0.125rem;
    background-color: #3a3d5a;
  }
  &::-webkit-scrollbar {
    width: 0.25rem;
    border-radius: 0.125rem;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0.125rem;
    background-color: rgba(255, 255, 255, 0.3);
  }

  ${RWD_LG(css`
    height: 60vh;
  `)};

  ${RWD_SM(css`
    width: 100%;
    height: 55vh;
    padding: 10px 15px;
  `)};
`;

const TermsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TermsUl = styled.ul`
  list-style-type: decimal;
  list-style-position: inside;
`;

const TermsLi = styled.li`
  font-size: min(max(0.9375vw, 16px), 18px);
  padding: 8px 0;
  line-height: 1.5;
`;

const TermsDetailUl = styled.ul`
  list-style-type: circle;
  list-style-position: inside;
`;

const TermsDetailLi = styled.li`
  font-size: min(max(0.9375vw, 16px), 18px);
  padding: 8px 0 8px 8px;
  line-height: 1.5;
`;

const TermsSubDetailUl = styled.ul`
  list-style-type: square;
  list-style-position: inside;
`;

const TermsSubDetailTitle = styled.span`
  font-weight: bold;
`;

const TermsSubDetailContent = styled.span`
  /* font-weight: bold; */
`;

const TermsSubDetailLi = styled.li`
  font-size: min(max(0.9375vw, 16px), 18px);
  padding: 8px 0 8px 20px;
  line-height: 1.5;
`;

const Confirmation = ({
  setSelectCheck,
  setReturnCheck,
  setTransferCheck,
  setFeeCheck,
  feeCheck,
  setActiveStep,
}) => {
  const { search } = useLocation();
  useEffect(() => {
    const query = new URLSearchParams(search);
    const tutorial = query.get("tutorial");
    if (tutorial === "mining") {
      setJoyrideIsRun(true);
    }
  }, [search]);

  const handleJoyrideCallback = () => {
    setActiveStep(3);
  };

  const [joyrideIsRun, setJoyrideIsRun] = useState(false);

  const joyrideState = [
    {
      target: ".createMining_agreeConfirm",
      content: (
        <Lang
          datakey="mining_tutorial_4-1"
          defaultText="Please read and agree to the Confirmation."
        />
      ),
      placement: "top",
      disableBeacon: true,
      disableOverlayClose: true,
      callbackAction: true,
      function: handleJoyrideCallback,
    },
  ];

  const [isBottom, setIsBottom] = useState(false);
  const dom = useRef(null);

  useEffect(() => {
    document.addEventListener("scroll", handleScroll, true);
    return () => {
      document.removeEventListener("scroll", handleScroll), true;
    };
  }, []);

  useEffect(() => {
    if (isBottom) {
      setFeeCheck(true);
    } else return;
  }, [isBottom]);

  const handleScroll = () => {
    const clientHeight = dom.current?.clientHeight;
    const scrollHeight = dom.current?.scrollHeight;
    const scrollTop = dom.current?.scrollTop;
    setIsBottom(clientHeight + scrollTop + 1 >= scrollHeight);

    // setPrivacyChecked(isBottom);
  };

  const lang = useSelector(selectSystem).language;
  const [language, setLanguage] = useState("");

  useEffect(() => {
    console.log(lang);
    setLanguage(lang);
  }, [lang]);

  const terms = [
    {
      id: 1,
      title: [
        {
          ch: "服務介紹",
          en: "Service Introduction",
        },
      ],
      ch: "本平台為用戶提供加密貨幣投資服務，通過將用戶資金分散投資於多家交易平台(包括但不限 於Binance、Bybit、Huobi、OKX、Bitopro、Bitfinex等)，以期獲得收益。",
      en: "Our platform provides users with cryptocurrency investment services by diversifying user funds across multiple trading platforms (including but not limited to Binance, Bybit, Huobi, OKX, Bitopro, Bitfinex, etc.), aiming to generate returns.",
    },
    {
      id: 2,
      title: [
        {
          ch: "市場風險揭示",
          en: "Market Risk Disclosure",
        },
      ],
      ch: "用戶應理解市場波動可能導致投資虧損。本平台不對市場波動造成的損失負責，用戶應自行承擔相關投資風險。",
      en: "Users should understand that market volatility may lead to investment losses. Our platform is not responsible for losses caused by market fluctuations, and users should bear the associated investment risks themselves.",
    },
    {
      id: 3,
      title: [
        {
          ch: "資金使用與轉換",
          en: "Funds Usageand Conversion",
        },
      ],
      ch: "當用戶款項存入後，將按照Binance當時提供的實時匯率轉換為選擇的貨幣。在服務期滿時，餘額將依照使用者設定的返還方式轉換回原始貨幣並退還(或繼續使用本服務)。",
      en: "Upon deposit, user funds will be converted into the chosen currency at the real-time exchange rate provided by Binance at that time. At the end of the service period, the balance will be converted back to the original currency and returned (or continued with our service) according to the user's chosen method of return.",
    },
    {
      id: 4,
      title: [
        {
          ch: "回報方式變更",
          en: "Changes in Return Methods",
        },
      ],
      ch: "對於回報方式的任何未來更改，當項目周期大於或等於90天時，用戶需提前15天提交更改申請；若項目周期等於15天，則需提前5天提交申請。",
      en: "For any future changes in the method of returns, users must submit a change request 15 days in advance for projects with a duration of 90 days or more; for projects with a duration of 15 days, a 5-day advance request is required.",
    },
    {
      id: 5,
      title: [
        {
          ch: "退款地址填寫",
          en: "Refund Address Submission",
        },
      ],
      ch: "用戶需根據轉移的貨幣種類，準確填寫對應的退款地址。例如，轉入BTC則填寫BTC的退款地址，轉入USDT則填寫USDT的退款地址。地址錯誤導致的任何轉移失敗或損失，用戶需自行承擔責任。",
      en: "Users must accurately fill in the corresponding refund address according to the type of currency transferred. For example, if BTC is transferred, then the BTC refund address should be provided, and for USDT, the USDT refund address. Any transfer failures or losses due to incorrect addresses will be the responsibility of the user.",
    },
    {
      id: 6,
      title: [
        {
          ch: "財務衍生產品及服務費",
          en: "Financial Derivative Products and Service Fees",
        },
      ],
      ch: "創建投資套餐時，將運用多種財務衍生產品。這些產品的服務提供者將收取0.1%至0.4%的服務費。此費用由服務提供者直接收取，非由AVA平台所收取。",
      en: "When creating investment packages, a variety of financial derivative products will be used. The service providers of these products will charge a service fee of 0.1% to 0.4%. This fee is collected directly by the service providers, not by the AVA platform.",
    },
    {
      id: 7,
      title: [
        {
          ch: "收益結算",
          en: "Earnings Settlement",
        },
      ],
      ch: "本平台將在每月的3號或18號進行收益結算(如使用的服務周期較⻑，則需要更⻑的時間)。由於加密貨幣市場的變動性，本平台不提供固定的收益計算公式。收益將根據市場條件和交易結果進行調整。",
      en: "Our platform will settle earnings on the 3rd or 18th of each month (if the service period used is longer, more time may be needed). Due to the volatility of the cryptocurrency market, our platform does not provide a fixed formula for calculating earnings. Profits will be adjusted based on market conditions and trading results.",
    },
    {
      id: 8,
      title: [
        {
          ch: "提前退款政策",
          en: "Early Refund Policy",
        },
      ],
      ch: "用戶可向平台管理員申請提前退款，退款應用在任何情況下，但應注意可能會產生0.5%至4%不等的手續費。",
      en: "Users may apply for an early refund with the platform administrator under any circumstances, but should be aware that a handling fee ranging from 0.5% to 4% may apply.",
    },
    {
      id: 9,
      title: [
        {
          ch: "資金安全保障",
          en: "Funds Security Guarantee",
        },
      ],
      ch: "本平台的資金安全由合作的各大交易平台負責，本平台僅提供資金運用的服務。",
      en: "The security of funds on our platform is the responsibility of our partnered major trading platforms. Our platform only provides the service of funds utilization.",
    },
    {
      id: 10,
      title: [
        {
          ch: "交易策略保密性",
          en: "Confidentiality of Trading Strategies",
        },
      ],
      ch: "本平台的具體交易策略為公司機密，不對外公開。將提供交易策略的大致概述，但詳細內容保持機密。",
      en: "The specific trading strategies of our platform are company confidential and are not disclosed publicly. We will provide a general overview of the trading strategies, but detailed contents are kept confidential.",
    },
    {
      id: 11,
      title: [
        {
          ch: "客戶服務",
          en: "Customer Service",
        },
      ],
      ch: "如遇問題，用戶可通過Telegram聯繫我們的客戶服務。客戶服務的回應時間可能在3至7個工作日之間，具體取決於當時的查詢量。",
      en: "For any issues, users can contact our customer service through Telegram. The response time of customer service may vary between 3 to 7 business days, depending on the query volume at the time.",
    },
    {
      id: 12,
      title: [
        {
          ch: "數據隱私與安全",
          en: "Data Privacy and Security",
        },
      ],
      ch: "本平台承諾保護用戶的個人和財務資料，並遵守下述規範",
      en: "For any issues, users can contact our customer service through Telegram. The response time of customer service may vary between 3 to 7 business days, depending on the query volume at the time.",
      detail: [
        {
          ch: [
            {
              detailTitle: "個人資料的收集與使用：",
              detailContent:
                "我們只收集用於提供服務、處理交易或經用戶明確同意的目的所需的最少個人資料。這包括但不限於姓名、聯繫方式、交易紀錄等。",
            },
            {
              detailTitle: "資料的存儲和安全性：",
              detailContent:
                "所有用戶資料均被安全存儲，並采用業界標準的加密和安全措施來防止未經授權的訪問或泄露。",
            },
            {
              detailTitle: "用戶權利的尊重：",
              detailContent:
                "根據GDPR規定，用戶有權訪問其個人資料、要求更正或刪除其資料、限制或反對其資料的處理，以及數據的可攜性權利。用戶可隨時聯繫我們以行使這些權利。",
            },
            {
              detailTitle: "資料的共享與轉移：",
              detailContent:
                "除非法律要求或為了提供服務所必需，我們不會未經用戶同意向第三方共享或轉移個人資料。",
            },
          ],
          en: [
            {
              detailTitle: "Collection and Use of Personal Data: ",
              detailContent:
                "We only collect the minimum personal data necessary to provide services, process transactions, or as explicitly consented to by users. This includes, but is not limited to, names, contact information, transaction records, etc.",
            },
            {
              detailTitle: "Data Storage and Security: ",
              detailContent:
                "All user data is securely stored and protected with industry-standard encryption and security measures to prevent unauthorized access or disclosure.",
            },
            {
              detailTitle: "Respect for User Rights: ",
              detailContent:
                "In accordance with GDPR regulations, users have the right to access their personal data, request corrections or deletion of their data, restrict or object to the processing of their data, and the right to data portability. Users can contact us at any time to exercise these rights.",
            },
            {
              detailTitle: "Data Sharing and Transfer:",
              detailContent:
                "We do not share or transfer personal data to third parties without user consent unless legally required or necessary to provide the service.",
            },
          ],
        },
      ],
    },
    {
      id: 13,
      title: [
        {
          ch: "其他條款和條件",
          en: "Other Terms and Conditions",
        },
      ],
      ch: "本平台保留隨時修改或更新這些條款和條件的權利。任何此類更改將以電子郵件方式通知用戶，並視為用戶同意和遵守。",
      en: "Our platform reserves the right to modify or update these terms and conditions at any time. Any such changes will be notified to users via email and will be considered as agreed and complied with by the user.",
    },
  ];

  return (
    <Wrapper className="createMining_agreeConfirm">
      <JoyrideComponent
        joyrideState={joyrideState}
        run={joyrideIsRun}
        joyrideType={"mining"}
      />
      <Title>
        <Lang datakey="terms_of_service" defaultText="Terms of Service" />
      </Title>
      <TermsWrapper>
        <Container ref={dom}>
          <TermsUl>
            {terms.map((data, id) => (
              <Fragment key={id}>
                <TermsLi>
                  {data.title[0][language?.toLocaleLowerCase()]}
                </TermsLi>
                <TermsDetailUl>
                  <TermsDetailLi>
                    {data[language?.toLocaleLowerCase()]}
                  </TermsDetailLi>
                  <TermsSubDetailUl>
                    {data.detail?.[0][language?.toLocaleLowerCase()]?.map(
                      (item, index) => (
                        <TermsSubDetailLi key={index}>
                          <TermsSubDetailTitle>
                            {item.detailTitle}
                          </TermsSubDetailTitle>
                          <TermsSubDetailContent>
                            {item.detailContent}
                          </TermsSubDetailContent>
                        </TermsSubDetailLi>
                      )
                    )}
                  </TermsSubDetailUl>
                </TermsDetailUl>
              </Fragment>
            ))}
          </TermsUl>
          {/* <CheckboxWrapper>
          <CheckBox
            type="checkbox"
            onChange={(e) => setSelectCheck(e.target.checked)}
          ></CheckBox>
          <CheckboxContainer>
            I understand when my fund has been credited, it will be instantly
            converted to the chosen currency with the system exchange rate. By
            the end of the expiration period, the balance will be converted to
            the original currency and return. I am fully aware of the potential
            risks involved, and willing to proceed the operation.
          </CheckboxContainer>
        </CheckboxWrapper>
        <CheckboxWrapper>
          <CheckBox
            type="checkbox"
            onChange={(e) => setReturnCheck(e.target.checked)}
          ></CheckBox>
          <CheckboxContainer>
            I am fully aware that for any future changes of return method, when
            the project cycle is greater than or equal to 90 days, the
            application should be submitted 15 days in advance; when the project
            cycle is equal to 15 days, the application should be submitted 5
            days in advance.
          </CheckboxContainer>
        </CheckboxWrapper>
        <CheckboxWrapper>
          <CheckBox
            type="checkbox"
            onChange={(e) => setTransferCheck(e.target.checked)}
          ></CheckBox>
          <CheckboxContainer>
            Please fill in the return address according to the transferred
            currency. Please fill in the return address for BTC returning if you
            would like to transfer BTC into this system. Please fill in the
            return address for USDT returning if you would like to transfer USDT
            into this system. You will be solely responsible for the
            consequences for any incorrectness of the address that leads to a
            transfer failure.
          </CheckboxContainer>
        </CheckboxWrapper>
        <CheckboxWrapper>
          <CheckBox
            type="checkbox"
            onChange={(e) => setFeeCheck(e.target.checked)}
          ></CheckBox>
          <CheckboxContainer>
            I understand that when creating a package, different financial
            derivative products will be used. The service providers of these
            products charge a service fee ranging from 0.1% to 0.4%. This fee is
            collected by the service providers, not by the AVA platform.
          </CheckboxContainer>
        </CheckboxWrapper> */}
        </Container>
      </TermsWrapper>

      <CheckboxWrapper>
        <CheckBox
          type="checkbox"
          checked={feeCheck}
          onChange={() => {}}
        ></CheckBox>
        <CheckboxContainer>
          <Lang
            datakey="addalgo_agree"
            defaultText="I have read and agree to the above terms and conditions"
          />
        </CheckboxContainer>
      </CheckboxWrapper>
    </Wrapper>
  );
};

export default Confirmation;
