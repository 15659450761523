import styled, { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";

import { useState, useEffect } from "react";
import Icons from "../../../global/icons";

import Lang from "../../../global/Lang";

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  color: #151944;
`;

const Container = styled.div`
  background-color: #fcfcfc;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 40px;
  width: 35vw;
  max-width: 850px;
  border-radius: 8px;
  position: relative;

  ${RWD_LG(css`
    width: 80%;
  `)};

  ${RWD_MD(css`
    width: 90%;
    /* padding: 20px 40px; */
  `)};

  ${RWD_SM(css`
    padding: 20px;
  `)};
`;

const Title = styled.div`
  color: #151944;
  font-size: min(max(1.5625vw, 28px), 30px);
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;

  ${RWD_MD(css`
    font-size: min(max(1.0416vw, 18px), 20px);
  `)};
`;

const SubTitle = styled.div`
  font-size: min(max(1.0416vw, 18px), 20px);
  color: #151944;
  padding: 0px 0 20px 0px;
  font-weight: 600;

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 16px), 18px);
  `)};
`;

const RuleWrapper = styled.div`
  padding-bottom: 20px;
`;

const Rule = styled.div`
  text-align: justify;
`;

const RewardWrapper = styled.div``;

const Reward = styled.div`
  text-align: justify;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
`;

const ConfirmButton = styled.button`
  width: 135px;
  border-radius: 60px;
  color: #fcfcfc;
  background-color: #151944;
  border: 1px solid #151944;
`;

const ButtonText = styled.div`
  padding: 9px 0;
  text-align: center;

  font-size: min(max(1.0416vw, 18px), 20px);

  ${RWD_MD(css`
    padding: 6px 15px;
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const RuleModal = ({ data, setIsOpen }) => {
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Wrapper>
      <Container>
        <Title>
          <Lang datakey="quest_quest_rules" defaultText="Quest Rules" />
        </Title>
        <RuleWrapper>
          <SubTitle>
            • <Lang datakey="quest_general_rules" defaultText="General Rules" />
          </SubTitle>
          <Rule>
            <Lang
              datakey={data[0]?.GeneralRuleKey}
              defaultText={data[0]?.GeneralRuleKey}
            />
          </Rule>
        </RuleWrapper>
        <RewardWrapper>
          <SubTitle>
            • <Lang datakey="quest_reward" defaultText="Reward" />
          </SubTitle>
          <Reward>
            <Lang
              datakey={data[0]?.RewardKey}
              defaultText={data[0]?.RewardKey}
            />
          </Reward>
        </RewardWrapper>
        <ButtonWrapper>
          <ConfirmButton onClick={handleClose}>
            <ButtonText>
              <Lang datakey="confirm" defaultText="confirm" />
            </ButtonText>
          </ConfirmButton>
        </ButtonWrapper>
      </Container>
    </Wrapper>
  );
};

export default RuleModal;
