import styled, { css } from "styled-components";

import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectSystem } from "../../slices/systemSlice";
import { useNavigate } from "react-router-dom";

import Icons from "../../global/icons";

const Wrapper = styled.div`
  height: 100%;
  padding-bottom: 100px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 20px;
`;

const Title = styled.div`
  font-size: min(max(1.25vw, 22px), 24px);
  color: #fcfcfc;
  padding-left: 10px;
  text-transform: capitalize;

  ${RWD_MD(css`
    font-size: min(max(1.145vw, 20px), 22px);
  `)};
`;

const TitleIcon = styled.div`
  display: inline-flex;
  width: 30px;
  height: 30px;

  ${RWD_SM(css`
    width: 25px;
    height: 25px;
  `)};

  svg {
    width: 30px;
    height: 30px;

    ${RWD_SM(css`
      width: 25px;
      height: 25px;
    `)};
  }

  & path {
    fill: #fcfcfc;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const InstructionsWrapper = styled.div`
  /* text-align: left; */
  padding-bottom: 20px;
  color: #d3d3d3;
`;

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;
  background-color: #22254a;
  /* border: 1px solid rgba(255, 255, 255, 0.15); */
  border-radius: 8px;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;

  height: 100%;
`;

const LinkTitle = styled.div`
  font-size: min(max(1.145vw, 20px), 22px);
  font-weight: bold;
  padding-bottom: 10px;
  color: #0aecd1;
`;

const LinkContent = styled.div`
  padding-bottom: 20px;
  color: #fcfcfc;
`;

const LinkBoxWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 80%;
  padding: 40px 0;
`;

const LinkBox = styled.div`
  width: 18vw;
  height: 18vw;
  border: 1px solid;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  border-color: rgba(255, 255, 255, 0.15);
  transition: all 0.3s linear;
  cursor: pointer;

  &:hover {
    border: 1px solid #0aecd1;
  }
`;

const LinkImg = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50%;
  width: 15vw;
  height: 15vw;

  svg {
    width: 13vw;
  }
`;

const ApiKeyGuide = () => {
  const lang = useSelector(selectSystem).language;
  const [language, setLanguage] = useState("");

  useEffect(() => {
    console.log(lang);
    setLanguage(lang);
  }, [lang]);

  const navigate = useNavigate();

  const TitleData = [
    {
      en: "How to Link your Exchanges API keys to your AVA Account",
      ch: "如何將您的交易所API密鑰鏈接到您的AVA帳戶",
    },
  ];

  const instructionsData = [
    {
      en: "Here is an article that summarizes the tutorials to link the API keys of your exchanges to your AVA account. At the moment, the exchanges compatible with AVA are : Binance, and Bybit",
      ch: "這是一篇總結將您的交易所API密鑰鏈接到AVA帳戶的教程。目前，與AV連結的交易所有：Binance和Bybit。",
    },
  ];

  return (
    <Wrapper>
      <Container>
        <TitleWrapper>
          <TitleIcon>
            <Icons.Key />
          </TitleIcon>

          <Title>{TitleData[0]?.[language?.toLocaleLowerCase()]}</Title>
        </TitleWrapper>

        <ContentWrapper>
          {instructionsData.map((data) => (
            <InstructionsWrapper>
              <p>{data?.[language?.toLocaleLowerCase()]}</p>
            </InstructionsWrapper>
          ))}

          <LinkWrapper>
            <LinkContainer>
              <LinkTitle>
                {language?.toLocaleLowerCase() === "en"
                  ? "Choose the exchange you want to link to AVA"
                  : "選擇您想要連結到AVA的交易所"}
              </LinkTitle>
              <LinkContent>
                {language?.toLocaleLowerCase() === "en"
                  ? "Select below the exchange platform you wish to link to AVA by clicking on its icon"
                  : "點擊其圖標，選擇下面您希望連結到AVA的交易平台來查看教程"}
              </LinkContent>

              <LinkBoxWrapper>
                <LinkBox onClick={() => navigate("/v2/apikeyguide/binance")}>
                  <LinkImg>
                    <Icons.Binance_white />
                  </LinkImg>
                </LinkBox>

                <LinkBox onClick={() => navigate("/v2/apikeyguide/bybit")}>
                  <LinkImg>
                    <Icons.Bybit_white />
                  </LinkImg>
                </LinkBox>
              </LinkBoxWrapper>
            </LinkContainer>
          </LinkWrapper>
        </ContentWrapper>
      </Container>
    </Wrapper>
  );
};

export default ApiKeyGuide;
