import { useEffect, useState } from 'react';

// styles
import styled, { css } from "styled-components";
import { RWD_MD } from '../../../constants/rwd';

// images
import IconArrowGray from "../../../images/arrow_gray.svg";
import Logo from "../../../images/logo_light.png";

import { getLanguage } from '../../../v2/services/common';

const Footer = styled.div`
  width: 100%;
  background: ${(props) => props.theme.colors.Black};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FooterWrapper = styled.div`
//   width: 82.2vw;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-items: stretch;
  padding: 2.91666vw 0 0 0;

  ${RWD_MD(css`
    padding: 13.88vw 0 0 0;
    flex-direction: column;
    align-items: center;
  `)};
`;

const FooterSloganWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
//   position: relative;
  overflow: hidden;

  ${RWD_MD(css`
    align-items: center;
    padding-top: 2.77vw;
  `)};
`;

const FooterSloganLogo = styled.div`
  width: 5.729vw;
  display: flex;
  padding-bottom: 1.66vw;

  ${RWD_MD(css`
    width: 32.5vw;
  `)};

  img {
    width: 100%;
    height: 100%;
    display: inline-block;
  }
`;

const FooterSlogan = styled.div`
  color: ${(props) => props.theme.colors.DarkGrey};
  text-align: end;
  font-size: 1.041667vw;
  padding-bottom: 2.6vw;

  ${RWD_MD(css`
    font-size: 4.44vw;
    padding: 8.88vw 0 13.88vw 0;
  `)};
`;

const FooterSloganContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  ${RWD_MD(css`
    flex-direction: column;
    text-align: center;
  `)};
`;

const FooterLanguageWrapper = styled.div`
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const FooterLanguage = styled.div`
  padding: 0.5vw 0 0.5vw 1vw;
  font-size: 1.041667vw;
  
  ${RWD_MD(css`
    font-size: 5vw;
    padding: 0 7.22vw;
  `)};
`;

const FooterLanguageIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.666vw;
  transition: all 0.3s;
  transform: ${(props) => (props.isOpen ? "scaleY(-1)" : "scaleY(1)")};

  svg {
    transition: all 0.5s;
    transform: ${(props) => (props.isOpen ? "scaleY(-1)" : "scaleY(1)")};
  }

  ${RWD_MD(css`
    width: 8.88vw;
  `)};
`;

const NewsFooter = () => {
    return (
        <Footer>
            <FooterWrapper>
                <FooterSloganWrapper>
                    <FooterSloganLogo>
                        <img src={Logo} alt="Logo" />
                    </FooterSloganLogo>
                    <FooterSlogan>
                        <FooterSloganContainer>
                            <span>Your financial partner,
                                Working tirelessly around the clock Creating excellent profit opportunities</span>
                        </FooterSloganContainer>
                    </FooterSlogan>
                </FooterSloganWrapper>
            </FooterWrapper>
        </Footer>
    );
}

export default NewsFooter;
