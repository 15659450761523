import styled from "styled-components";
import { css } from "styled-components";

import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../components/Router";

import StepOne from "./AddAlgoStep/StepOne";
import StepTwo from "./AddAlgoStep/StepTwo";
import StepThree from "./AddAlgoStep/StepThree";
import StepFour from "./AddAlgoStep/StepFour";

import AddAlgoModal from "./AddAlgoModal";

import { useSelector } from "react-redux";
import { useAddAlgoMutation } from "../../services/algoApi";

import { selectAccount } from "../../slices/accountSlice";
import { selectSystem } from "../../slices/systemSlice";
import { useGetAccountApikeyBalanceMutation } from "../../services/accountApi";

import Lang from "../../global/Lang";

import { handleColorType } from "../../utils/ButtonStyle";
import Loading from "../../components/Loading/Loading";

// sweetalert
import {
  ErrorMySwal,
  CreateAlgoMySwal,
  WarningMySwal,
} from "../../global/MySwal";
import { getLanguage } from "../../services/common";
import { LangString } from "../../utils";

import { TutorialFunction } from "../../global/Driver";

import { useOutletContext } from "react-router-dom";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 1.5625vw;
  width: 100%;
  overflow: hidden;

  ${RWD_LL(css`
    width: 100%;
    position: absolute;
    top: 0;
    margin-right: 0;
  `)};
`;

const ProgressContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  ${RWD_LL(css`
    width: 100%;
  `)};
`;

const ProgressItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${RWD_LL(css`
    width: 100%;
  `)};
`;

const ProgressItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  ${RWD_LL(css`
    align-items: center;
  `)};

  ::before {
    position: absolute;
    content: "";
    border-bottom: 4px solid;
    border-color: ${(props) => (props.status ? "#0AECD1" : "#3A3D5A")};
    width: 50%;
    top: 15px;
    left: 0;
    z-index: 1;
  }

  ::after {
    position: absolute;
    content: "";
    border-bottom: 4px solid;
    border-color: ${(props) => (props.active ? "#0AECD1" : "#3A3D5A")};
    width: 50%;
    top: 15px;
    right: 0%;
    z-index: 1;

    ${RWD_LL(css`
      width: 50%;
    `)};
  }

  :first-child::before {
    content: none;

    ${RWD_LL(css`
      content: "";
    `)};
  }

  :last-child::after {
    content: none;

    ${RWD_LL(css`
      content: "";
    `)};
  }
`;

const ProgressItemText = styled.div`
  padding-top: 5px;
  text-align: center;
  font-size: min(max(0.833vw, 12px), 16px);
  color: ${(props) => (props.status ? "#0AECD1" : "#FCFCFC")};

  ${RWD_LL(css`
    transform: translateX(0%) translateY(0%);
  `)};
`;

const ProgressItemNumber = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: ${(props) => (props.status ? "#3A3D5A" : "#FCFCFC")};
  background: ${(props) => (props.status ? "#0AECD1" : "#3A3D5A")};
`;

const ButtonWrapper = styled.div`
  display: flex;
  cursor: pointer;
  div:first-child {
    margin-right: 1.5625vw;
  }
`;

const Button = styled.div`
  border: ${({ type }) => handleColorType(type).border};
  border-radius: 60px;
  width: min(max(7.0833vw, 100px), 135px);
  background: ${({ type }) => handleColorType(type).bg};
  text-align: center;
`;

const ButtonText = styled.span`
  font-size: min(max(1.0416vw, 16px), 20px);
  color: #fcfcfc;
  display: inline-flex;
  padding: 0.52vw 1.3vw;
  ${RWD_LG(css`
    padding: 8px 18px;
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const StepActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* flex-wrap: wrap; */

  width: 100%;
  background-color: #151944;
  padding-bottom: 3.854vw;
  padding-top: 3.854vw;
  margin-top: auto;
  position: relative;

  ${RWD_LL(css`
    padding: 100px 0 20px 0;
    align-items: flex-end;
  `)};
`;

const AddAlgo = () => {
  const { addAlgoStatus } = useOutletContext();
  useEffect(() => {
    const steps = [];
    // TutorialFunction({ steps }).drive();
    // TutorialFunction.start();
  }, []);

  const go = useNavigate();
  const telegramVerified =
    useSelector(selectAccount)?.accountProfile?.IsTelegramVerified;
  const algos = useSelector(selectAccount)?.accountAlgo;

  const avapoint = useSelector(selectAccount)?.accountProfile.AvaPoints;
  const airobots = useSelector(selectSystem)?.airobots;

  // const [
  //   _getBalance,
  //   {
  //     data: getAccountBalanceResponse,
  //     isSuccess: isGetAccountBalanceIsSuccess,
  //     error: isGetAccountBalanceError,
  //     isError: isGetAccountBalanceIsError,
  //   },
  // ] = useGetAccountApikeyBalanceMutation();

  const [
    _addAlgo,
    {
      data: addAlgoResponse,
      isSuccess: addAlgoIsSuccess,
      error: addAlgoError,
      isError: addAlgoIsError,
      isLoading: addAlgoIsLoading,
    },
  ] = useAddAlgoMutation();

  // console.log(airobots);

  const [activeStep, setActiveStep] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [algo, setAlgo] = useState(null);
  const [exchangeId, setExchangeId] = useState(null);
  const [amount, setAmount] = useState("");
  const [balance, setBalance] = useState("0");
  const [target, setTarget] = useState(null);
  const [nickname, setNickname] = useState("");

  const [selectApiKeyIsAvailable, setSelectApiKeyIsAvailable] = useState(false);

  const [privacyChecked, setPrivacyChecked] = useState(false);

  const stepDirection = [
    <Lang datakey="addalgo_select_algo" defaultText="Select Algo" />,
    <Lang datakey="addalgo_select_exchange" defaultText="Select Exchange" />,
    <Lang datakey="addalgo_set_amount" defaultText="Enter Amount" />,
  ];

  useEffect(() => {
    const selectTarget = airobots.filter((data) => data.Id === algo);
    setTarget(selectTarget[0]?.Target);
  }, [algo]);

  // useEffect(() => {
  //   if (isGetAccountBalanceIsSuccess) {
  //     setBalance(getAccountBalanceResponse?.Amount);
  //     setActiveStep(activeStep + 1);
  //   }

  //   if (isGetAccountBalanceIsError) {
  //     MySwal.fire({
  //       title: isGetAccountBalanceError.data.Message,
  //       icon: "error",
  //       confirmButtonText: "確定",
  //     });
  //   }
  //   // if (!isGetAccountBalanceSuccess || isGetAccountBalanceLoading) return;
  //   // setBalance(getAccountBalanceResponse?.Amount);
  // }, [getAccountBalanceResponse, isGetAccountBalanceIsError]);

  useEffect(() => {
    if (addAlgoIsSuccess) {
      go(routes.algo_v2);
    }

    if (addAlgoIsError) {
      ErrorMySwal({
        title:
          addAlgoError?.data?.ResultLanguages[getLanguage()] ||
          addAlgoError?.data?.Message,
      }).then((result) => {
        if (result.isConfirmed) {
          go(routes.algo_v2);
        }
      });
    }
  }, [addAlgoResponse, addAlgoIsError, addAlgoIsLoading]);

  useEffect(() => {
    // 選擇交易所時清空balance
    setBalance(0);
  }, [exchangeId]);

  // useEffect(() => {
  //   const apiKey = apikeys.filter(({ ExchangeId }) => ExchangeId == exchangeId);
  //   console.log(apiKey)
  //   if (apiKey[0] === null) {
  //     setApiKeyAvailable(false);
  //   } else {
  //     setApiKeyAvailable(true);
  //   }
  // }, [exchangeId]);

  const handleCancel = () => {
    go("/v2/algo");
  };

  const startAlgoRunning = (subscribe) => {
    _addAlgo({
      body: {
        AiRobotId: algo,
        ExchangeId: exchangeId,
        // Exchange: exchangeId === 1 ? "Binance" : "Bybit",
        Status: "running",
        OrderStatus: "init",
        Amount: amount,
        NickName: nickname,
        // Target: target,
        TargetKey: target,
        IsSubscribe: subscribe,
      },
    });
    setLoading(true);
  };

  const startAlgoInit = (subscribe) => {
    _addAlgo({
      body: {
        AiRobotId: algo,
        ExchangeId: exchangeId,
        Status: "init",
        OrderStatus: "init",
        Amount: amount,
        NickName: nickname,
        // Target: target,
        TargetKey: target,
        IsSubscribe: subscribe ?? false,
      },
    });
    addAlgoStatus[1](true);
    setLoading(true);
  };

  const handleDone = () => {
    const runningAlgo = algos
      ?.filter(({ Status }) => Status.toLowerCase() === "running")
      ?.map(({ AmountSetting }) => AmountSetting)
      ?.reduce((accumulator, currentValue) => {
        if (accumulator === undefined) {
          return accumulator;
        } else {
          return accumulator + currentValue;
        }
      }, 0);

    // avapoint夠 才問要不要啟動algo
    // 原本的邏輯 avapoint >= amount * 0.1 + runningAlgo * 0.1/
    if (
      (runningAlgo < 5000 && avapoint >= 100) ||
      (runningAlgo >= 5000 && avapoint >= runningAlgo * 0.1)
    ) {
      // avapoint 夠
      const title = LangString("alert_algo_active", "Activate Algo now?");
      const checkBoxText = LangString(
        "alert_tg_check",
        "Enable Telegram message notifications"
      );
      const confirmText = LangString("yes", "Yes");
      const cancelText = LangString("no", "No");
      const verifyText = LangString("alert_verify_now", "Verify now");

      CreateAlgoMySwal({
        title: title,
        checkBoxText: checkBoxText,
        verifyText: verifyText,
        confirmButtonText: confirmText,
        cancelButtonText: cancelText,
        showCancelButton: true,
        telegramVerified: telegramVerified,
      }).then((result) => {
        if (result.isConfirmed) {
          startAlgoRunning(result.value?.[0]?.subscribeStatus);
        } else {
          startAlgoInit(result.value?.[0]?.subscribeStatus);
        }
      });
    } else {
      //  Insufficient AVA Points, please deposit and then reactivate Algo.
      WarningMySwal({
        title: "AVA Point 不足",
        text: "請儲值後再啟動Algo",
        showCancelButton: false,
        confirmButtonText: "確認",
      }).then((result) => {
        if (result.isConfirmed) {
          startAlgoInit();
        }
      });

      // setIsOpen(true);
      // setModalData([
      //   {
      //     title: "AVA Point 不足，請儲值後再啟動Algo",
      //     checkbox: false,
      //     icon: "warning",
      //     functionList: [startAlgoInit],
      //     buttonType: "single",
      //   },
      // ]);
    }
  };

  const handleNextStep = () => {
    // 第二步（設定交易所）要轉到第三步取得錢包餘額
    // if (activeStep === 1) {
    //   setBalance(0);
    //   _getBalance({
    //     body: { ExchangeId: exchangeId },
    //   });
    // } else {
    //   setActiveStep(activeStep + 1);
    // }

    setActiveStep(activeStep + 1);
  };

  const handleBackStep = () => {
    if (activeStep === 0) {
      return;
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  // 換頁時回到頂部
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  return (
    <Wrapper>
      {addAlgoIsLoading && <Loading />}
      {activeStep === 0 && (
        <StepOne
          algo={algo}
          setAlgo={setAlgo}
          setActiveStep={setActiveStep}
          target={target}
          setTarget={setTarget}
        />
      )}
      {activeStep === 1 && (
        <StepTwo
          target={target}
          exchangeId={exchangeId}
          setExchangeId={setExchangeId}
          airobots={airobots}
          algo={algo}
          setSelectApiKeyIsAvailable={setSelectApiKeyIsAvailable}
          setActiveStep={setActiveStep}
        />
      )}
      {activeStep === 2 && (
        <StepThree
          exchangeId={exchangeId}
          amount={amount}
          setAmount={setAmount}
          balance={balance}
          setBalance={setBalance}
          target={target}
          setTarget={setTarget}
          nickname={nickname}
          setNickname={setNickname}
          setActiveStep={setActiveStep}
        />
      )}
      {activeStep === 3 && (
        <StepFour
          privacyChecked={privacyChecked}
          setPrivacyChecked={setPrivacyChecked}
        />
      )}

      <StepActionWrapper>
        {activeStep !== 0 && (
          <ButtonWrapper style={{ marginRight: "auto" }}>
            <Button type="stroke" onClick={() => handleBackStep()}>
              <ButtonText>
                <Lang datakey="laststep" defaultText="Back" />
              </ButtonText>
            </Button>
          </ButtonWrapper>
        )}
        <ProgressWrapper className="createAlgo_progress">
          <ProgressContainer>
            {Array.from({ length: 3 }, (v, i) => (
              <ProgressItem
                key={i}
                status={activeStep >= i}
                active={
                  activeStep > i || activeStep == stepDirection.length - 1
                }
              >
                <ProgressItemWrapper>
                  <ProgressItemNumber status={activeStep >= i}>
                    {i + 1}
                  </ProgressItemNumber>
                  <ProgressItemText status={activeStep >= i}>
                    {stepDirection[i]}
                  </ProgressItemText>
                </ProgressItemWrapper>
              </ProgressItem>
            ))}
          </ProgressContainer>
        </ProgressWrapper>

        {(() => {
          switch (activeStep) {
            case 0:
              return (
                <ButtonWrapper>
                  <Button type="storke" onClick={() => handleCancel()}>
                    <ButtonText>
                      <Lang datakey="cancel" defaultText="Cancel" />
                    </ButtonText>
                  </Button>
                  <Button type="fill" onClick={() => handleNextStep()}>
                    <ButtonText>
                      <Lang datakey="nextstep" defaultText="Next" />
                    </ButtonText>
                  </Button>
                </ButtonWrapper>
              );
            case 1:
              return (
                <ButtonWrapper>
                  <Button type="storke" onClick={() => handleCancel()}>
                    <ButtonText>
                      <Lang datakey="cancel" defaultText="Cancel" />
                    </ButtonText>
                  </Button>
                  <Button
                    style={{
                      cursor: selectApiKeyIsAvailable ? "pointer" : "default",
                    }}
                    type={selectApiKeyIsAvailable ? "fill" : "disabled"}
                    onClick={() =>
                      selectApiKeyIsAvailable ? handleNextStep() : ""
                    }
                  >
                    <ButtonText>
                      <Lang datakey="nextstep" defaultText="Next" />
                    </ButtonText>
                  </Button>
                </ButtonWrapper>
              );
            case 2:
              return (
                <ButtonWrapper>
                  <Button type="storke" onClick={() => handleCancel()}>
                    <ButtonText>
                      <Lang datakey="cancel" defaultText="Cancel" />
                    </ButtonText>
                  </Button>
                  <Button
                    style={{
                      cursor:
                        Number(amount) >= 500 &&
                        Number(amount) <=
                          (Math.floor(balance * 10000) / 10000).toFixed(4)
                          ? "pointer"
                          : "default",
                    }}
                    type={
                      Number(amount) >= 500 &&
                      Number(amount) <=
                        (Math.floor(balance * 10000) / 10000).toFixed(4)
                        ? "fill"
                        : "disabled"
                    }
                    onClick={() =>
                      Number(amount) >= 500 &&
                      Number(amount) <=
                        (Math.floor(balance * 10000) / 10000).toFixed(4)
                        ? handleNextStep()
                        : ""
                    }
                  >
                    <ButtonText>
                      <Lang datakey="nextstep" defaultText="Next" />
                    </ButtonText>
                  </Button>
                </ButtonWrapper>
              );

            case 3:
              return (
                <ButtonWrapper>
                  <Button type="storke" onClick={() => handleCancel()}>
                    <ButtonText>
                      <Lang datakey="cancel" defaultText="Cancel" />
                    </ButtonText>
                  </Button>
                  <Button
                    style={{
                      cursor: privacyChecked ? "pointer" : "default",
                    }}
                    type={privacyChecked ? "fill" : "disabled"}
                    onClick={() => (privacyChecked ? handleDone() : "")}
                  >
                    <ButtonText>
                      <Lang datakey="done" defaultText="Done" />
                    </ButtonText>
                  </Button>
                </ButtonWrapper>
              );
          }
        })()}

        {/* {activeStep > 0 ? (
          activeStep === 2 ? (
            <ButtonWrapper>
              <Button type="storke" onClick={() => handleCancel()}>
                <ButtonText>
                  <Lang datakey="cancel" defaultText="Cancel" />
                </ButtonText>
              </Button>
              <Button
                style={{
                  cursor:
                    Number(amount) >= 500 &&
                    Number(amount) <=
                      (Math.floor(balance * 10000) / 10000).toFixed(4)
                      ? "pointer"
                      : "default",
                }}
                type={
                  Number(amount) >= 500 &&
                  Number(amount) <=
                    (Math.floor(balance * 10000) / 10000).toFixed(4)
                    ? "fill"
                    : "disabled"
                }
                onClick={() =>
                  Number(amount) >= 500 &&
                  Number(amount) <=
                    (Math.floor(balance * 10000) / 10000).toFixed(4)
                    ? handleDone()
                    : ""
                }
              >
                <ButtonText>
                  <Lang datakey="done" defaultText="Done" />
                </ButtonText>
              </Button>
            </ButtonWrapper>
          ) : (
            <ButtonWrapper>
              <Button type="storke" onClick={() => handleCancel()}>
                <ButtonText>
                  <Lang datakey="cancel" defaultText="Cancel" />
                </ButtonText>
              </Button>
              <Button
                style={{
                  cursor: selectApiKeyIsAvailable ? "pointer" : "default",
                }}
                type={selectApiKeyIsAvailable ? "fill" : "disabled"}
                onClick={() =>
                  selectApiKeyIsAvailable ? handleNextStep() : ""
                }
              >
                <ButtonText>
                  <Lang datakey="nextstep" defaultText="Next" />
                </ButtonText>
              </Button>
            </ButtonWrapper>
          )
        ) : (
          <ButtonWrapper>
            <Button type="storke" onClick={() => handleCancel()}>
              <ButtonText>
                <Lang datakey="cancel" defaultText="Cancel" />
              </ButtonText>
            </Button>
            <Button type="fill" onClick={() => handleNextStep()}>
              <ButtonText>
                <Lang datakey="nextstep" defaultText="Next" />
              </ButtonText>
            </Button>
          </ButtonWrapper>
        )} */}
      </StepActionWrapper>
      {isOpen && (
        <AddAlgoModal
          modalData={modalData}
          telegramVerified={telegramVerified}
        />
      )}
    </Wrapper>
  );
};

export default AddAlgo;
