import styled from "styled-components";
import { useEffect, useState } from "react";
import Lang from "../../../../../global/Lang";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const TooltipWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  font-size: 12px;
  color: #fcfcfc;
  font-family: Montserrat;
  align-items: center;
`;

const TooltipIcon = styled.div`
  width: 10px;
  height: 10px;
  background: ${({ $color }) => $color};
`;

const TooltipLabelWrapper = styled.div`
  padding-bottom: 5px;
  text-align: center;
`;

const TooltipValueWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TooltipValueTitle = styled.div`
  padding: 0 8px;
`;

const TooltipValue = styled.div`
  padding-left: 10px;
`;

export const RoiChart = ({ chartData }) => {
  const [profitData, setProfitData] = useState([]);

  useEffect(() => {
    if (chartData.length > 0) {
      setProfitData(chartData);
    }
  }, [chartData]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <TooltipWrapper className="custom-tooltip">
          <TooltipLabelWrapper className="label">
            {label?.split(" ")[0]}
          </TooltipLabelWrapper>
          <TooltipValueWrapper className="intro">
            <TooltipIcon $color={payload[0]?.stroke}></TooltipIcon>
            <TooltipValueTitle>{payload[0]?.name}</TooltipValueTitle>
            <TooltipValue>{payload[0]?.value.toFixed(2)}%</TooltipValue>
          </TooltipValueWrapper>
        </TooltipWrapper>
      );
    }

    return null;
  };

  const formatXAxisDate = (date) => {
    return `${date?.split(" ")[0].split("-")[1]}/${
      date?.split(" ")[0].split("-")[2]
    }`;
  };

  const formatYAxisRoi = (value) => `${value}%`;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={1000}
        height={300}
        data={profitData}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid opacity={0.1} />
        <XAxis
          dataKey="CloseDate"
          dy={10}
          stroke="rgba(255, 255, 255, 0.2)"
          tickFormatter={formatXAxisDate}
          style={{
            fontSize: "13px",
            fontFamily: "Montserrat",
          }}
        />
        <YAxis
          stroke="rgba(255, 255, 255, 0.2)"
          tickFormatter={formatYAxisRoi}
          style={{
            fontSize: "13px",
            fontFamily: "Montserrat",
          }}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend
          verticalAlign="top"
          height={25}
          wrapperStyle={{ fontSize: "11px", fontFamily: "Montserrat" }}
        />
        <Line
          type="monotone"
          dataKey="TotalCumulativeProfit"
          stroke="#2F80ED"
          activeDot={{ r: 4 }}
          name={<Lang datakey="roi" defaultText="ROI" />}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
