import { verifications } from "../../../utils/verifications";
import { StyledCard } from "../../Global/Ui";

// --- images ---
import Light_History_icon from "../../../img/light_history.png";
import Dark_History_icon from "../../../img/dark_history.png";

const Sharing = ({ data }) => {
  const {
    user,
    userData,
    isDarkMode,
    getSharingHistory,
    sourceHereAhftHiveBenefit,
    inputWithdrawalValue,
  } = data;
  return (
    <StyledCard
      title={!!user ? "Share with you" : "Total Sharing"}
      titleClass="text-center"
      suffixIcon={
        !user ? (
          <img
            className="cursor-pointer"
            src={isDarkMode ? Dark_History_icon : Light_History_icon}
            onClick={getSharingHistory}
          />
        ) : null
      }
    >
      {/* title */}
      {/* <h3 className='
        relative py-2 text-[0.875rem] font-bold text-center text-blue
        before:absolute before:block before:w-full before:h-[3px] before:bottom-0 before:left-0
        before:bg-sub-blue before:rounded
      '>
        {
          !!user
            ? 'Share with you'
            : 'Total Sharing'
        }
        {
          !user
          &&
          <img
            className='
              absolute block right-0 top-1/2 -translate-y-1/2
              cursor-pointer
            '
            src={isDarkMode ? Dark_History_icon : Light_History_icon}
            alt=''
            onClick={getSharingHistory}
          />
        }
      </h3> */}
      {/* sub title */}
      <p className="pt-5 pb-3 text-[0.875rem] font-bold text-blue">
        AVA POINTS
      </p>
      <div className="w-full flex justify-between items-center">
        <span className="text-[1.5rem] font-bold">
          ${" "}
          {!!user
            ? sourceHereAhftHiveBenefit
                ?.toFixed(2)
                .replace(verifications.currency, ",")
            : parseFloat(Math.floor((userData?.AvaPoint ?? 0) * 100) / 100)
                ?.toFixed(2)
                .replace(verifications.currency, ",")}
        </span>
        {!user && (
          <button
            className="
              inline-block px-3 py-2 bg-sub-blue dark:bg-sub-blue rounded
              text-[0.875rem] text-white dark:text-black font-bold
              cursor-pointer
            "
            onClick={inputWithdrawalValue}
          >
            Withdrawal
          </button>
        )}
      </div>
    </StyledCard>
  );
};

export default Sharing;
