import { useEffect, useState } from "react";
import { verifications } from "../../../utils/verifications";

// --- styled
import * as Styled from "./Styled";

const UpdateInfo = ({ data }) => {
  if (!data) return;
  const [totalAmount, setTotalAmount] = useState(0);
  useEffect(() => {
    const initValue = 0;
    const total = data.reduce((prev, { ReturnAmount }) => {
      return prev + (ReturnAmount ?? 0);
    }, initValue);
    setTotalAmount(
      (Math.floor(total * 100000000) / 100000000)
        .toFixed(8)
        .replaceAll(verifications.point, ",")
    );
  }, [data]);

  return (
    <Styled.UpdateInfoContainer>
      <Styled.UpdateInfoWrapper>
        <Styled.UpdateInfoTitleContainer>
          <h3>返還清單</h3>
          <Styled.UpdateInfoTitleWrapper>
            <span>
              筆數：
              <Styled.UpdateInfoText>{data.length}</Styled.UpdateInfoText>
            </span>
            <span>
              總返還金額：
              <Styled.UpdateInfoText>{totalAmount}</Styled.UpdateInfoText>
            </span>
          </Styled.UpdateInfoTitleWrapper>
        </Styled.UpdateInfoTitleContainer>

        <Styled.UpdateInfoTableContainer>
          <table>
            <thead>
              <tr>
                <th>AccountID</th>
                <th>UserAddress</th>
                <th>AhftGroupID</th>
                <th>Periods</th>
                <th>ReturnAmount</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{item.AccountID}</td>
                  <td>{item.UserAddress}</td>
                  <td>{item.AhftGroupID}</td>
                  <td>{item.Periods}</td>
                  <td>{item.ReturnAmount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Styled.UpdateInfoTableContainer>
      </Styled.UpdateInfoWrapper>
    </Styled.UpdateInfoContainer>
  );
};

export default UpdateInfo;
