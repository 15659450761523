import AccountTable from './AccountTable';
import styled from 'styled-components';
import LogTable from './LogTable';

const Welcome = styled.h3`
  padding-bottom: 1rem;
  color: #666;
`;
const BlockContainer = styled.div`
  padding-bottom: 2rem;
`;

export const Title = styled.h3`
    color: #1976d2;
`;

export const TableContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  border-radius: 4px;
  height: 50vh;
  border: 1px solid #e5e7eb;
`;

const Dashboard = () => {
  return (
    <>
      <Welcome>
        Welcome !
      </Welcome>

      <BlockContainer>
        <LogTable />
      </BlockContainer>

      <BlockContainer>
        <AccountTable />
      </BlockContainer>
    </>
  )
}

export default Dashboard
