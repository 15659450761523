import { useEffect, useState } from "react";

// --- plugins
import Swal from "sweetalert2/dist/sweetalert2";
import { Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

// --- components
import Loading from "../../../components/Global/Loading";
import { API_AvaPointWithdrawalBackend } from "../../../utils/api";

// --- styled
import * as Styled from "./Styled";
import { verifications } from "../../../utils/verifications";
import { Navigate } from "react-router-dom";
import { routes } from "../../../components/Router";

const Withdrawal = () => {
  const columns = [
    {
      field: "Status",
      headerName: "",
      width: 100,
      renderCell: (params) => {
        const id = params.row.ID;
        return (
          <Button
            variant="contained"
            size="small"
            onClick={(e) => doneWithdrawal(e, id)}
          >
            Done
          </Button>
        );
      },
    },
    {
      field: "ID",
      headerName: "ID",
      width: 80,
    },
    {
      field: "AccountID",
      headerName: "AccountID",
      width: 200,
    },
    {
      field: "Point",
      headerName: "Point",
      width: 120,
      valueFormatter: (params) => {
        if (params.value == null) return "";
        const valueFormatted = Math.floor(params.value)
          .toFixed(2)
          .replace(verifications.currency, ",");
        return `${valueFormatted}`;
      },
    },
    {
      field: "UserAddress",
      headerName: "UserAddress",
      width: 550,
    },
    {
      field: "CreateTime",
      headerName: "CreateTime",
      width: 200,
    },
  ];

  const getListWithdrawal = async () => {
    const res = await API_AvaPointWithdrawalBackend();

    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode === 997) {
      Swal.fire(res.Message);
      return <Navigate to={routes.backend_dashboard} />;
    }
    if (res.ResultCode !== 0) return Swal.fire(res.Message);

    setData(
      res.ListWithdrawal.map((item, index) => ({
        ...item,
        id: index,
      }))
    );
    setLoading(false);
  };

  const doneWithdrawal = async (e, id) => {
    e.preventDefault();
    if (!id) console.error("id not found");

    setLoading(true);

    const res = await API_AvaPointWithdrawalBackend(id);

    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return Swal.fire(res.Message);

    Swal.fire({
      title: "success",
      icon: "success",
    });

    getListWithdrawal();
  };

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data === null) return setData("get");
  }, []);

  useEffect(() => {
    if (data === null) return;
    if (data === "get") getListWithdrawal();
  }, [data]);

  if (loading) return <Loading />;
  return (
    <Styled.Container>
      <div>
        <Styled.Title>提領審核</Styled.Title>
      </div>

      <Styled.TableContainer>
        <DataGrid
          sx={[
            { ".MuiDataGrid-cell": { cursor: "pointer" } },
            { ".MuiDataGrid-cell:focus": { outline: "none" } },
            { ".MuiDataGrid-columnHeader:focus": { outline: "none" } },
            { ".MuiDataGrid-cell:focus-within": { outline: "none" } },
            { ".MuiDataGrid-footerContainer": { justifyContent: "center" } },
          ]}
          rows={data}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          experimentalFeatures={{ newEditingApi: true }}
        />
      </Styled.TableContainer>
    </Styled.Container>
  );
};

export default Withdrawal;
