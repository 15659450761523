import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../components/Router";

// --- styled
import styled from "styled-components";
import { RWD_XL, RWD_LG, RWD_MD, RWD_SM, RWD_SS } from "../../utils/rwd";
import { css } from "styled-components";
import Loading from "../../components/Loading/Loading";
import { SuccessMySwal, ErrorMySwal } from "../../global/MySwal";
import {
  useForgotPasswordMutation,
  useVerifyForgotPasswordMutation,
  useSetPasswordMutation,
} from "../../services/authApi";
import LogoImg from "../../../img/ava_logo_dark.png";

import { EmailFormat } from "../../utils";

import { getLanguage } from "../../services/common";
import { useDispatch } from "react-redux";
import { changeLanguage, selectSystem } from "../../slices/systemSlice";
import { useSelector } from "react-redux";
import { handleParseLanguage } from "../../utils";

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: none;
  position: relative;
  background-color: #151944;
  color: #fcfcfc;
  font-family: Poppins;
`;

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40vw;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  padding: 2vw 2.5vw;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );

  ${RWD_LG(css`
    width: 60vw;
    padding: 4vw;
  `)};

  ${RWD_SM(css`
    width: 83.33vw;
    padding: 15px;
  `)};
`;

const Title = styled.h3`
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 20px;
  color: white;
`;

const StepWrapper = styled.div``;

const ContentWrapper = styled.div`
  /* padding: 0 2.5vw 2vw 2.5vw; */
`;

const Content = styled.div``;

const Label = styled.label`
  width: 100%;
  padding: 8px 0;
`;

const Input = styled.input`
  color: #fcfcfc;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  padding: 10px 10px;
  margin: 10px 0;
  width: 100%;

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const ActionContainer = styled.div`
  padding: 30px 0 10px 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Button = styled.button`
  min-width: 120px;
  border-radius: 60px;

  color: ${(props) => (props.type === "confirm" ? "#fcfcfc" : "#151944")};
  background: ${(props) =>
    props.type === "confirm"
      ? props.disabled
        ? "#3A3D5A"
        : "linear-gradient(to bottom, #2f80ed 0%, #5620f0 100%)"
      : ""};
  border-color: ${(props) => (props.disabled ? "#d9d9d9" : "")};
  display: flex;
  justify-content: center;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};

  ${RWD_SS(css`
    min-width: 30vw;
  `)};
`;

const ButtonText = styled.span`
  padding: 8px 16px;
  text-align: center;
  font-size: min(max(1.0416vw, 14px), 20px);
  color: #fcfcfc;
  ${RWD_SM(css`
    min-width: 25vw;
    padding: 8px 10px;
  `)};
`;

const Header = styled.header`
  height: 6.25vw;
  display: flex;
  align-items: center;
  justify-content: center;
  ${RWD_MD(css`
    height: 8vh;
  `)};
  ${RWD_SM(css`
    height: 65px;
  `)};
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 81.88vw;
  ${RWD_MD(css`
    width: 83.33vw;
  `)};
`;

const LogoContainer = styled.div`
  width: 5.729vw;
  display: flex;
  cursor: pointer;

  ${RWD_MD(css`
    width: 11vw;
  `)};

  ${RWD_SM(css`
    width: 15.33vw;
  `)};
`;

const LanguageWrapper = styled.div`
  display: flex;
  align-items: end;
`;

const LanguageText = styled.div`
  cursor: pointer;
`;

const PasswordTemplate = ({ setLoading, account }) => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const valid = password && confirmPassword && password === confirmPassword;

  const [_setPassword, { data, isSuccess, isError, error, isLoading }] =
    useSetPasswordMutation();

  const handleResetPassword = async (e) => {
    await _setPassword({
      body: {
        Account: account,
        Password: password,
      },
    });
  };
  const handleBackToHome = () => {
    navigate(routes.landing);
  };

  useEffect(() => {
    if (isSuccess) {
      // 多國語言
      SuccessMySwal({
        title: handleParseLanguage("update_successful"),
        showCancelButton: false,
        confirmButtonText: handleParseLanguage("confirm"),
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(routes.login_v2);
        }
      });
    }
    if (isError) {
      ErrorMySwal({
        title:
          error?.data?.ResultLanguages[getLanguage()] || error?.data?.Message,
      });
    }

    if (isLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [data, isError, isLoading]);

  return (
    <ContentWrapper>
      <Content>
        <Input
          placeholder={handleParseLanguage("password")}
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          autocomplete="off"
          type="password"
        />
        <Input
          placeholder={handleParseLanguage("confirm_password")}
          onChange={(e) => setConfirmPassword(e.target.value)}
          value={confirmPassword}
          autocomplete="off"
          type="password"
        />
      </Content>
      <ActionContainer>
        <ButtonWrapper>
          <Button
            style={{
              cursor: "pointer",
              border: "1px solid #fcfcfc",
            }}
            onClick={handleBackToHome}
          >
            <ButtonText>{handleParseLanguage("home")}</ButtonText>
          </Button>
          <Button
            type={"confirm"}
            disabled={!valid}
            onClick={handleResetPassword}
          >
            <ButtonText>{handleParseLanguage("confirm")}</ButtonText>
          </Button>
        </ButtonWrapper>
      </ActionContainer>
    </ContentWrapper>
  );
};

const CodeTemplate = ({ handleNextStep, setLoading }) => {
  const navigate = useNavigate();
  const [verifyCode, setVerifyCode] = useState("");
  const valid = verifyCode;
  const handleBackToHome = () => {
    navigate(routes.landing);
  };
  const [_verify, { data, isSuccess, isError, error, isLoading }] =
    useVerifyForgotPasswordMutation();
  const handleVerify = async (e) => {
    _verify({
      body: {
        Source: "ForgotPassword",
        VerifyCode: verifyCode,
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      // 多國語言

      SuccessMySwal({
        title: handleParseLanguage("verification_successful"),
        showCancelButton: false,
        confirmButtonText: handleParseLanguage("confirm"),
      }).then((result) => {
        if (result.isConfirmed) {
          handleNextStep();
        }
      });
    }
    if (isError) {
      ErrorMySwal({
        title:
          error?.data?.ResultLanguages[getLanguage()] || error?.data?.Message,
      });
    }

    if (isLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [data, isError, isLoading]);

  return (
    <ContentWrapper>
      <Content>
        <Input
          placeholder={handleParseLanguage(
            "fortgot_password_verification_directions"
          )}
          onChange={(e) => setVerifyCode(e.target.value)}
          value={verifyCode}
          autocomplete="off"
        />
      </Content>
      <ActionContainer>
        <ButtonWrapper>
          <Button
            style={{
              cursor: "pointer",
              border: "1px solid #fcfcfc",
            }}
            onClick={handleBackToHome}
          >
            <ButtonText>{handleParseLanguage("home")}</ButtonText>
          </Button>
          <Button type={"confirm"} disabled={!valid} onClick={handleVerify}>
            <ButtonText>{handleParseLanguage("verify")}</ButtonText>
          </Button>
        </ButtonWrapper>
      </ActionContainer>
    </ContentWrapper>
  );
};

const AccountTemplate = ({
  handleNextStep,
  setLoading,
  account,
  setAccount,
}) => {
  const navigate = useNavigate();
  const [_forgotPassword, { data, isSuccess, isError, error, isLoading }] =
    useForgotPasswordMutation();
  const valid = account !== "" && EmailFormat(account);

  const handleBackToHome = () => {
    navigate(routes.landing);
  };

  const handleResetPassword = async (e) => {
    await _forgotPassword({
      body: {
        Account: account,
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      SuccessMySwal({
        title: handleParseLanguage("verify_code_sent"),
        text: handleParseLanguage("fortgot_password_verification_notice"),
        showCancelButton: false,
        confirmButtonText: handleParseLanguage("confirm"),
      }).then((result) => {
        if (result.isConfirmed) {
          handleNextStep();
        }
      });
    }
    if (isError) {
      ErrorMySwal({
        title:
          error?.data?.ResultLanguages[getLanguage()] || error?.data?.Message,
      });
    }

    if (isLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [data, isError, isLoading]);

  return (
    <ContentWrapper>
      <Content>
        <Input
          placeholder={`${handleParseLanguage("email")} (${handleParseLanguage(
            "account"
          )})`}
          onChange={(e) => setAccount(e.target.value)}
          id="email"
        />
      </Content>
      <ActionContainer>
        <ButtonWrapper>
          <Button
            style={{
              cursor: "pointer",
              border: "1px solid #fcfcfc",
            }}
            onClick={handleBackToHome}
          >
            <ButtonText>{handleParseLanguage("home")}</ButtonText>
          </Button>
          <Button
            type={"confirm"}
            disabled={!valid}
            onClick={handleResetPassword}
          >
            <ButtonText>{handleParseLanguage("reset_password")}</ButtonText>
          </Button>
        </ButtonWrapper>
      </ActionContainer>
    </ContentWrapper>
  );
};

const ForgotPassword = () => {
  const navigate = useNavigate();
  const lang = useSelector(selectSystem).language;
  const [activeStep, setActiveStep] = useState(0);
  const [account, setAccount] = useState("");
  const [loading, setLoading] = useState(false);
  const handleNextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBackToHome = () => {
    navigate(routes.landing);
  };

  const dispatch = useDispatch();
  const handleChangeLanguage = () => {
    dispatch(changeLanguage());
  };

  return (
    <Wrapper>
      {loading && <Loading />}
      <Header>
        <LogoWrapper>
          <LogoContainer onClick={handleBackToHome}>
            <img src={LogoImg} />
          </LogoContainer>
          <LanguageWrapper onClick={handleChangeLanguage}>
            <LanguageText>
              {lang?.toLowerCase() === "en" ? "EN" : "中"}
            </LanguageText>
          </LanguageWrapper>
        </LogoWrapper>
      </Header>
      <Container>
        <Title>{handleParseLanguage("forgot_password")}</Title>
        <StepWrapper>
          {activeStep === 0 && (
            <AccountTemplate
              handleNextStep={handleNextStep}
              setLoading={setLoading}
              account={account}
              setAccount={setAccount}
            />
          )}
          {activeStep === 1 && (
            <CodeTemplate
              setLoading={setLoading}
              handleNextStep={handleNextStep}
            />
          )}
          {activeStep === 2 && (
            <PasswordTemplate setLoading={setLoading} account={account} />
          )}
        </StepWrapper>
      </Container>
    </Wrapper>
  );
};

export default ForgotPassword;
