import { useState, useEffect } from 'react';
import { Link, useLocation, matchPath, useNavigate } from 'react-router-dom';
import { useData } from '../../utils/context';
import { routes } from '../Router';

// --- plugins ---
import { FiMail } from 'react-icons/fi';

// --- components ---
import { Navbar, DashboardNavbar } from './Navbar';
import ToggleTheme from './ToggleTheme';
import Logo from './Logo';

// --- images ---
import logo_light from '../../img/ava_loginLogo_light.png';
import logo_dark from '../../img/ava_logo_dark.png';
import user from '../../img/user.png';
import { StyledButton } from './Ui';

// --- home page ---
export const Header = () => {
  const navigate = useNavigate();

  const { langData, isDarkMode } = useData();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [mobileTop, setMobileTop] = useState(0);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      (window.scrollY > 100 && window.screen.width < 640)
        ? setMobileTop(-40)
        : setMobileTop(0)
    });
  }, []);
  useEffect(() => {
    document.body.style.overflow = isMenuOpen ? 'hidden' : 'auto';
  }, [isMenuOpen]);

  return (
    <>
      <header
        className='
          fixed z-50 w-full min-w-[320px] pt-[50px] px-[3%] 2xl:px-[13%] sm:pl-[100px]
          flex align-center justify-center sm:justify-between sm:pt-0 transition
          bg-white dark:bg-black-main transition duration-300
        '
        style={{ transform: `translateY(${mobileTop}px)` }}
      >
        {/* logo */}
        <div className='relative z-20 my-5'>
          <img className='block mx-auto  max-w-[100px]' src={isDarkMode ? logo_dark : logo_light} alt='' />
        </div>

        <div className='
          absolute right-5 bottom-8 flex items-center
          sm:relative sm:bottom-0 sm:right-0
        '>
          <div className='hidden 2xl:block'>
            <Navbar />
          </div>

          {/* --- register & login --- */}
          {/* showing upper 1024 */}
          <div className='hidden lg:mr-5 lg:min-w-[300px] lg:flex uppercase'>
            {/* register */}
            <StyledButton
              value='register'
              className='mr-2 flex-1'
              outline={true}
              onClick={() => navigate('/AVA/register')}
            />
            <StyledButton
              value='login'
              className='flex-1'
              onClick={() => navigate('/AVA/login')}
            />
          </div>
          
          {/* --- theme mode --- */}
          <button className='hidden 2xl:inline-block'>
            <ToggleTheme />
          </button>

        </div>

      </header>

      <FullMenu isMenuOpen={isMenuOpen} />

      <Burger
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        mobileTop={mobileTop}
      />
    </>
  )
}

// --- home page burger menu ---
export const Burger = ({ isMenuOpen, setIsMenuOpen, mobileTop }) => {
  // change route close menu
  const location = useLocation();
  useEffect(() => {
    setIsMenuOpen(false);
  }, [location])

  return (
    <div
      className={`
        fixed sm:top-[-60px] right-6 z-[200] burger-animation w-[33.75px] h-auto 2xl:hidden cursor-pointer
        transition duration-300
        ${isMenuOpen && 'active'}
      `}
      
      style={{ transform: `${!isMenuOpen && `translateY(${mobileTop + 100}px)`}` }}
    >
      <div
        className='burger-animation relative z-20'
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <span className='
          w-full block h-[4px] translate-y-[-6.5px]
          transition duration-300
          bg-black-menu dark:bg-white rounded
        ' />
        <span className='
          w-full block h-[4px]
          transition duration-300
          bg-black-menu dark:bg-white rounded
        ' />
        <span className='
          w-full block h-[4px] translate-y-[6.5px]
          transition duration-300
          bg-black-menu dark:bg-white rounded
        ' />
      </div>
    </div>
  )
}

const FullMenu = ({ isMenuOpen }) => {
  {/* home page asider bar menu for mobile */}
  return (
    <div
      className={`
        fixed z-[100] sm:z-[20] top-0 left-full w-full h-full
        bg-sub-button sm:bg-transparent transition duration-300
        ${isMenuOpen && 'translate-x-[-100%]'}
      `}
      onClick={e => e.stopPropagation()}
    >
      <div
        className='
          absolute mx-auto text-center w-full h-[90%] translate-y-[-50%] top-1/2
          sm:w-[240px] sm:h-full sm:right-0 sm:pt-24
          bg-white dark:bg-black-main
        '
        onClick={e => e.stopPropagation()}
      >
        <div className='max-w-[140px] mx-auto'>
          {/* logo */}
          <div className='sm:hidden relative z-20 my-10 max-w-[100px] mx-auto'>
            <Logo />
          </div>

          {/* toggle theme button */}
          <div className='w-full self-center py-4 sm:py-8'>
            <div className='flex justify-center'>
              <ToggleTheme />
            </div>
          </div>

          {/* links */}
          <div className='w-full max-h-[650px]'>
            <Navbar />
          </div>
          
          {/* register & login buttons showing below 1024 */}
          <div className='w-full py-2 sm:py-8 self-center mx-auto lg:hidden'>
            <Link
              to='/AVA/register'
              className='
                block w-full py-2 border border-1 border-primary rounded
                bg-primary dark:text-black-main text-white
              '
            >
              REGISTER
            </Link>
            <Link to='/AVA/login' className='
              block mt-6 w-full py-2 border border-1 border-primary rounded
              text-primary dark:text-primary
            '>
              LOGIN
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

// --- dashboard page ---
export const MainHeader = () => {
  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/AVA', { replace: true })
  }

  const { pathname } = useLocation();
  const location = useLocation();
  const navigate = useNavigate();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [mobileTop, setMobileTop] = useState(0);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      (window.scrollY > 10 && window.screen.width < 640)
        ? setMobileTop(-20)
        : setMobileTop(0)
    });
  }, []);
  // change route close menu
  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);
  useEffect(() => {
    document.body.style.overflow = isMenuOpen ? 'hidden' : 'auto';
  }, [isMenuOpen]);

  return (
    <header
      className='
        fixed z-10 top-4 sm:top-0 left-0 w-full flex justify-between py-5 pl-14 sm:px-14
        bg-white-menu dark:bg-black-menu lg:bg-transparent dark:lg:bg-transparent
        transition duration-150
      '
      style={{ transform: `translateY(${mobileTop}px)` }}
    >
      {/* logo & burger */}
      <div className='lg:hidden flex items-center'>
        {/* burger showing below 768 */}
        <div className={`
          relative burger-animation w-[33.75px] h-auto 2xl:hidden cursor-pointer
          ${isMenuOpen && 'active'}
        `}>
          {/* burger icon */}
          <div
            className='relative z-20 translate-x-[-100%]'
            onClick={e => setIsMenuOpen(!isMenuOpen)}
          >
            <span className='
              w-full block h-[4px] translate-y-[-6.5px]
              transition duration-300
              bg-black-menu dark:bg-white rounded
            ' />
            <span className='
              w-full block h-[4px]
              transition duration-300
              bg-black-menu dark:bg-white rounded
            ' />
            <span className='
              w-full block h-[4px] translate-y-[6.5px]
              transition duration-300
              bg-black-menu dark:bg-white rounded
            ' />
          </div>
          {/* menu */}
          <ul
            className='
              menuShowFromLeft
              fixed z-10 w-full sm:w-[420px] h-screen top-0 right-full
              pt-[180px] sm:pt-[150px] transition-transFormOpacity
              opacity-0 bg-white-menu dark:bg-black-main
            '
          >
            <DashboardNavbar />
            <li className={`
              leading-[2.5rem] px-20 mt-[10px]
              border-l border-l-[12px] border-transparent
              ${Boolean(matchPath('/AVA/setting/*', pathname)) && 'bg-white dark:bg-black border-l-primary'}
            `}>
              <Link to={`/AVA/setting/`}>SETTING</Link>
            </li>
            {/* <li className={`
              leading-[2.5rem] px-20 mt-[10px]
              border-l border-l-[12px] border-transparent
              ${Boolean(matchPath('/AVA/help', pathname)) && 'bg-white dark:bg-black border-l-primary'}
            `}>
              <Link to={`/AVA/help`}>HELP</Link>
            </li> */}
            <li className={`
              leading-[2.5rem] px-20 mt-[10px]
              border-l border-l-[12px] border-transparent
            `}>
              <button className='' onClick={handleLogout}>
                LOGOUT
              </button>
            </li>
            {/* toggle theme button */}
            <li className='h-[2.5rem] px-24 mt-[10px] child:ml-0'>
              <ToggleTheme />
            </li>
          </ul>
        </div>

        {/* logo */}
        <Logo className='relative z-20' />
      </div>

      {/* mail & user */}
      <div className='lg:bg-white lg:dark:bg-black-main lg:h-[60px] lg:text-right lg:w-full lg:fixed lg:right-0 lg:top-0'>
        {/* mail */}
        {/* <div className='inline-block relative z-10 w-[30px] h-[25px] text-alert'>
          <div className='
            absolute top-0 right-0 translate-x-[40%] translate-y-[-35%]
            text-[18px] leading-[18px] pl-[1px]
            bg-white-menu lg:bg-white dark:bg-black-menu dark:lg:bg-black-main
          '>
            2
          </div>
          <FiMail className='w-full h-full' />
        </div> */}
        {/* user */}
        <div className='inline-block mt-5 mr-5 lg:mr-0 relative z-10 w-[30px] h-[30px] ml-[25px]'>
          <img
            className='cursor-pointer w-full h-full'
            src={user}
            alt=''
            onClick={() => navigate(routes.setting)}
          />
        </div>
        {/* theme */}
        <div className='hidden lg:inline-block mt-5 mr-5 relative z-10 w-[30px] h-[30px] ml-[25px] cursor-pointer'>
          <ToggleTheme />
        </div>
      </div>
    </header>
  )
}