import { StyledCard } from '../../Global/Ui';

const Level = ({ data }) => {
  const { userGroup, userData } = data;
  return (
    <StyledCard
      title={`Level ${userData.Level}`}
      titleClass='text-center'
    >
      <p className='pt-5 pb-3 text-[0.875rem] font-bold text-blue'>
        Next Level
      </p>
      {/* decoration level line */}
      <div className={`relative w-full h-[7px] bg-[#eee] rounded`}>
        <div
          className='absolute h-full left-0 top-0 bg-sub-blue rounded'
          style={{ width: `${!!userGroup ? userGroup.filter(({ Level }) => Level > (userData.Level - 1)).length * 20 : 0}%` }}
        />
      </div>
      {/* level up descripton */}
      <span className='text-[0.75rem]'>
        invite {!!userGroup && userGroup.filter(({ Level }) => Level > (userData.Level - 1)).length} of 5 people
      </span>
    </StyledCard>
  )
}

export default Level
