import styled, { css } from "styled-components";
import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import { EffectCards } from "swiper";
import { RWD_MD, RWD_SM } from "../../../utils/rwd";
import Icons from "../../../global/icons";
import Lang from "../../../global/Lang";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px 0;
  margin-top: 10px;
  background-color: #22254a;
  ${RWD_SM(css`
    display: block;
  `)};
`;

const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 30%;
  overflow: hidden;
  ${RWD_SM(css`
    width: 100%;
  `)};
`;

const CardContainer = styled.div`
  width: 90%;
  height: 100%;
  ${RWD_SM(css`
    width: 100%;
    margin: 0 0 2vw 0;
  `)};
`;

const OwnerNoText = styled.div`
  font-size: 20px;
`;

const Card = styled.div`
  width: 17vw;
  max-width: 300px;
  min-width: 100px;
  height: 45vh;
  max-height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  ${RWD_SM(css`
    margin-left: 10vw;
    width: 70vw;
  `)};
`;

const ContentWrapper = styled.div`
  display: flex;
  padding: 0 40px;
  width: 70%;
  ${RWD_SM(css`
    width: 100%;
  `)};
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const ContentTitleWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

const ContentTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  padding-right: 10px;
`;

const ContentNameWrapper = styled.div`
  position: relative;
`;

const ContentName = styled.div`
  /* padding-top: 5px; */
  font-size: 25px;
  font-weight: 600;
  color: #0aecd1;
`;

const ContentNum = styled.div`
  font-size: 12px;
  color: #54587b;
`;

const ContentInfo = styled.div`
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 10px;
  right: -40%;

  svg {
    width: 20px;
    height: 20px;
  }

  & path {
    stroke: #54587b;

    /* fill: wheat; */
    /* stroke: antiquewhite; */
  }
`;

const ContentDetailWrapper = styled.div`
  display: flex;
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
`;

const ContentDetail = styled.div`
  display: flex;

  &:last-child {
    span {
      display: none;
    }
  }
`;

const ContentDetailName = styled.div`
  padding: 0 10px 0 5px;
`;

const ContentDetailCount = styled.div`
  padding: 0 5px 0 10px;
  font-weight: 800;
`;

const ContentButtonWrapper = styled.div`
  padding-top: 20px;
  display: grid;
  flex-direction: column;
  align-items: center;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  ${RWD_MD(css`
    grid-template-columns: repeat(2, 1fr);
  `)};

  ${RWD_SM(css`
    display: flex;
  `)};
`;

const ContentButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid red;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  border: 1px solid;
  border-color: rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  padding: 20px 0;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    border-color: #0aecd1;
  }
  ${RWD_MD(css`
    width: 26vw;
  `)}
  ${RWD_SM(css`
    width: 100%;
  `)};
`;

const ContentButtonTitle = styled.div`
  padding-top: 10px;
  transition: all 0.3s;
  ${ContentButtonContainer}:hover & {
    color: #0aecd1;
  }
`;

const ContentButton = styled.button`
  /* width: 100px;
  height: 100px; */
  border-radius: 50%;
  font-size: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;

  ${ContentButtonContainer}:hover & {
    border-color: #0aecd1;
  }

  svg {
    width: 4vw;
    height: 4vw;
  }
`;

const ContentDataWrapper = styled.div`
  padding-top: 20px;
`;

const ContentData = styled.div`
  display: flex;
  padding: 5px 0;
  align-items: center;
`;

const ContentDataIcon = styled.div`
  width: 25px;
  height: 25px;
  display: inline-flex;

  svg {
    width: 25px;
    height: 25px;
  }
`;

const ContentDataTitle = styled.div`
  width: 100px;
  margin-left: 10px;
`;

const ContentDataValueWrapper = styled.div`
  display: flex;
  align-items: baseline;

  /* color: #0aecd1; */
`;

const ContentDataSymbol = styled.div`
  font-size: min(max(1.145vw, 20px), 22px);
  padding-right: 5px;
`;

const ContentDataValue = styled.div`
  font-weight: bold;
  font-size: min(max(1.5625vw, 28px), 30px);
  font-family: Montserrat;
`;

const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: #3a3d5a;
  border: 1px solid #3a3d5a;
  border-radius: 20px;
  z-index: 1;
`;

const ModalMessage = styled.div`
  font-weight: bold;
  font-size: min(max(1.5625vw, 28px), 30px);
  font-family: Montserrat;
  position: relative;
`;
const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  outline: none;
  position: absolute;
  top: 5px;
  right: 5px;
`;
const Nft = () => {
  const backgroundColor = [
    "rgba(255, 99, 132, 1)",
    "rgba(54, 162, 235, 1)",
    "rgba(255, 206, 86, 1)",
    "rgba(75, 192, 192, 1)",
  ];

  const userOwnTopNftArray = ["Your Top NFT Level is", "Joker", "# 123456"];

  const buttonList = [
    {
      id: 1,
      name: <Lang datakey="member_empowerment" defaultText="Empowerment" />,
      text: "E",
      icon: <Icons.Member_Empowerment />,
    },
    {
      id: 2,
      name: <Lang datakey="member_detail" defaultText="Detail" />,
      text: "D",
      icon: <Icons.Member_Detail />,
    },
    {
      id: 3,
      name: <Lang datakey="member_buy" defaultText="BUY" />,
      text: "B",
      icon: <Icons.Member_Buy />,
    },
    {
      id: 4,
      name: <Lang datakey="member_sell" defaultText="SELL" />,
      text: "S",
      icon: <Icons.Member_Sell />,
    },
  ];

  const ownList = [];

  const [showModal, setShowModal] = useState(false);

  const handleButtonClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Wrapper>
      <CardWrapper>
        <CardContainer>
          <Swiper effect={"cards"} grabCursor={true} modules={[EffectCards]}>
            {Array.from({ length: 1 }, (v, i) => (
              <SwiperSlide>
                <Card style={{ background: backgroundColor[i] }}>
                  <OwnerNoText>n/a</OwnerNoText>
                </Card>
              </SwiperSlide>
            ))}
          </Swiper>
        </CardContainer>
      </CardWrapper>
      <ContentWrapper>
        <ContentContainer>
          {ownList.length === 0 ? (
            <ContentTitleWrapper>
              <ContentTitle>
                <Lang
                  datakey="member_nft_dont_own"
                  defaultText="You don't own NFTs!"
                />
              </ContentTitle>
            </ContentTitleWrapper>
          ) : (
            <ContentTitleWrapper>
              <ContentTitle>{userOwnTopNftArray[0]}</ContentTitle>
              <ContentNameWrapper>
                <ContentName>{userOwnTopNftArray[1]}</ContentName>
                <ContentNum>{userOwnTopNftArray[2]}</ContentNum>
                <ContentInfo>
                  <Icons.Tooltip />
                </ContentInfo>
              </ContentNameWrapper>
            </ContentTitleWrapper>
          )}
          {ownList.length === 0 ? (
            <ContentDetailWrapper></ContentDetailWrapper>
          ) : (
            <ContentDetailWrapper>
              You Own
              {ownList.map((data, index) => (
                <ContentDetail>
                  <ContentDetailCount>{data.count}</ContentDetailCount>
                  <ContentDetailName>{data.name}</ContentDetailName>
                  {index !== ownList.length - 1 && <span> , </span>}
                </ContentDetail>
              ))}
            </ContentDetailWrapper>
          )}
          <ContentButtonWrapper>
            {buttonList.map((data) => (
              <ContentButtonContainer onClick={handleButtonClick}>
                <ContentButton>{data.icon}</ContentButton>
                <ContentButtonTitle>{data.name}</ContentButtonTitle>
              </ContentButtonContainer>
            ))}
          </ContentButtonWrapper>
          {showModal && (
            <ModalWrapper>
              <ModalMessage>
                <Lang
                  datakey="nft_coming_soon"
                  defaultText="NFT features is coming soon"
                />
              </ModalMessage>
              <CloseButton onClick={handleCloseModal}>X</CloseButton>
            </ModalWrapper>
          )}
          <ContentDataWrapper>
            <ContentData>
              <ContentDataIcon>
                <Icons.Member_Value />
              </ContentDataIcon>
              <ContentDataTitle>
                <Lang datakey="member_value" defaultText="Value" />
              </ContentDataTitle>
              <ContentDataValueWrapper>
                <ContentDataSymbol>$</ContentDataSymbol>
                <ContentDataValue>0</ContentDataValue>
              </ContentDataValueWrapper>
            </ContentData>
            <ContentData>
              <ContentDataIcon>
                <Icons.Member_Earn />
              </ContentDataIcon>
              <ContentDataTitle>
                <Lang datakey="member_earn" defaultText="Earn" />
              </ContentDataTitle>
              <ContentDataValueWrapper>
                <ContentDataSymbol>$</ContentDataSymbol>
                <ContentDataValue>0</ContentDataValue>
              </ContentDataValueWrapper>
            </ContentData>
          </ContentDataWrapper>
        </ContentContainer>
      </ContentWrapper>
    </Wrapper>
  );
};

export default Nft;
