import { Link } from 'react-router-dom';

// --- plugins ---
import { IoAlertCircleOutline } from 'react-icons/io5';

const Notice = () => {
  return (
    <div className='mt-10 text-[#666]'>
      <IoAlertCircleOutline className='
        inline-block aling-middle mr-2
        text-[#666] text-[1.25rem]
      ' />
      <span className='align-middle'>Notice:</span>
      <ol className='mt-3 list-decimal'>
        <li className='ml-12 pl-1'>
          <span>Need to know </span>
          <Link to='/AVA' className='text-blue'>
            Computer-Processed Personal Data Protection Law
          </Link>.
        </li>
      </ol>
    </div>
  )
};

export default Notice;