import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_SetAIEntrust } from '../../utils/api';
import { StyledInput, StyledSelect } from '../Global/Ui';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import algo1 from '../../img/algo_1.jpg';
import algo3 from '../../img/algo_3.jpg';
import algo4 from '../../img/algo_4.jpg';
import algo8 from '../../img/algo_8.jpg';

const Desc = ({ str }) => {
  useEffect(() => {
    if (!str) return;
    const doc = new DOMParser().parseFromString(str, 'text/html').body;
    Array.from(doc.querySelectorAll('a')).map(item => {
      item.className ='sub-word hover:text-blue dark:hover:text-blue';
      item.target = '_blank'
    });
    document.getElementById('desc').innerHTML = "";
    document.getElementById('desc').append(doc);
}, [str]);
  return (
    <span id='desc' className='leading-[1.5] text-sub'></span>
  )
}
const DescImg = ({ index }) => {
  if (!index) return;
  const imgGroup = {
    '1': algo1,
    '3': algo3,
    '4': algo4,
    '8': algo8,
  };
  if (!!imgGroup[index])
    return <img src={imgGroup[index]} alt='' />
};

const AddAi = ({ data }) => {
  const handleSetAIEntrust = async (e) => {
    e.preventDefault();

    const res = await API_SetAIEntrust({
      Exchange: exchange,
      ai_robot_id: robotId,
      amount,
    });
    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return Swal.fire(res.Message);

    Swal.fire({
      icon: 'success',
      title: 'Success!',
    }).then(() => navigate('../algotrade'));
  };
  const navigate = useNavigate();
  const [exchange, setExchange] = useState(data?.ListExchange[0]?.Exchange);
  const [robotId, setRobotId] = useState(data?.ai_Robot_List?.filter(({ availabe }) => !!availabe)[0].ai_robot_id);
  const [amount, setAmount] = useState(0);
  const [descStr, setDescStr] = useState('');

  useEffect(() => {
    data?.ai_Robot_List?.map(({ ai_robot_id, robot_desc_ch }) => {
      if (ai_robot_id.toString() === robotId.toString()) {
        setDescStr(robot_desc_ch);
      }
    });
  }, [robotId]);

  if (data === null) return;
  if (data === 'get') return;
  return (
    <form>
      {/* api key */}
      <div className="p-3 flex items-center">
        <div className='mr-4'>
          <label>API Key List</label>
        </div>
        <StyledSelect
          options={data.ListExchange?.map(({ Exchange }) => ({ text: Exchange, value: Exchange }))}
          onChange={e => setExchange(e.target.value)}
          value={exchange}
          defaultSelectClass='text-default'
        />
      </div>

      {/* ai robot */}
      <div className="p-3 flex items-center">
        <div className='mr-4'>
          <label>Algo Select</label>
        </div>
        <StyledSelect
          options={data.ai_Robot_List
                    ?.filter(({ availabe }) => !!availabe)
                    ?.map(({ ai_robot_id, robot_name_en }) => ({ text: robot_name_en, value: ai_robot_id }))}
          onChange={e => setRobotId(e.target.value)}
          value={robotId}
          defaultSelectClass='text-default'
        />
      </div>

      {/* description */}
      <p id="ai-robot-desc" className="mt-4 px-4 pb-3 leading-[1.3] text-[1rem]">
        <Desc str={descStr} />
        <DescImg index={robotId} />
      </p>

      {/* amount */}
      <div className="mt-4 p-3 flex items-center">
        <div className='mr-4'>
          <label>SETTING AMOUNT</label>
        </div>
        <div>
          <StyledInput
            inputClass='text-center'
            value={amount}
            type='number'
            onChange={e => setAmount(e.target.value)}
          />
        </div>
      </div>

      {/* action button */}
      <div className='w-full py-4 flex justify-center'>
        <button
          className='min-w-[100px] mr-10 px-4 py-2 bg-sub-blue dark:bg-sub-blue rounded text-word-dark dark:text-word-light'
          onClick={handleSetAIEntrust}
        >
          Save
        </button>
        <button
          className='min-w-[100px] px-4 py-2 bg-sub-word dark:bg-sub-word rounded text-word-dark dark:text-word-light'
          onClick={() => navigate('../algotrade')}
        >
          Cancel
        </button>
      </div>
    </form>
  )
}

export default AddAi
