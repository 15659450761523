import { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { routes } from '../../../components/Router';

// --- styled
import * as Styled from './StyledLogin';
import UseSwal from '../../hooks/Lang'; // swal confirm button background color

// --- plugins
import { TextField, Button } from '@mui/material';

// --- api
import { API_GetToken } from '../../../utils/api';

const Login = () => {
    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault();
        const res = await API_GetToken({
            email: username,
            password,
        });

        if (!!res.error) return UseSwal(res.error_description);

        localStorage.setItem('systemToken', res.access_token);
        navigate(routes.backend_dashboard);
    };

    if (localStorage.getItem('systemToken'))
        return <Navigate to={routes.backend_dashboard} />

    return (
        <Styled.LoginContainer>
            <Styled.LoginTitle>
                login
            </Styled.LoginTitle>

            <Styled.LoginForm>

                <Styled.LoginField>
                    <TextField
                        label="username"
                        variant="standard"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </Styled.LoginField>

                <Styled.LoginField>
                    <TextField
                        label="password"
                        type="password"
                        variant="standard"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Styled.LoginField>

                <Styled.LoginActionContainer>
                    <Styled.LoginField>
                        <Button
                            variant="contained"
                            onClick={handleLogin}
                            disabled={!username || !password}
                        >
                            登入
                        </Button>
                    </Styled.LoginField>
                </Styled.LoginActionContainer>

            </Styled.LoginForm>
        </Styled.LoginContainer>
    )
}

export default Login
