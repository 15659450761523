import styled, { css } from "styled-components";
import { useState, useEffect } from "react";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";
import Lang from "../../global/Lang";
import { Select } from "../../global/Selects";
import { selectSystem } from "../../slices/systemSlice";
import { useSelector } from "react-redux";
import { QRCodeCanvas } from "qrcode.react";

import { AlertMySwal } from "../../global/MySwal";
import Icons from "../../global/icons";
import { LangString } from "../../utils";

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
`;

const Container = styled.div`
  background-color: #fcfcfc;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100px;
  height: auto;

  padding: 40px;
  width: 41.66vw;
  max-width: 800px;
  border-radius: 8px;

  ${RWD_LG(css`
    width: 70%;
  `)};

  ${RWD_MD(css`
    padding: 20px 40px;
  `)};

  ${RWD_SM(css`
    width: 90%;
    padding: 20px;
  `)};
`;

const Title = styled.div`
  color: #151944;
  font-size: min(max(1.5625vw, 28px), 30px);
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;

  ${RWD_MD(css`
    font-size: min(max(1.0416vw, 18px), 20px);
  `)};
`;

const InputWrapper = styled.div``;

const SubTitle = styled.div`
  color: #151944;
  font-size: min(max(1.145vw, 20px), 22px);
  font-weight: bold;
  padding-bottom: 10px;
  padding-top: 1.3vw;
  display: flex;

  ${RWD_MD(css`
    font-size: min(max(0.9375vw, 16px), 18px);
  `)};
`;

const Input = styled.input`
  background: #d9d9d9;
  padding: 1.0416vw 1.5625vw;
  width: 100%;
  border-radius: 100px;
  color: #151944;

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 14px), 16px);
    padding: 8px 20px;
  `)};
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 50px;

  ${RWD_SM(css`
    padding-top: 25px;
    justify-content: space-between;
  `)};
`;

const Button = styled.button`
  width: min(max(7.0833vw, 100px), 135px);
  border-radius: 60px;
  border: 1px solid;
  color: ${(props) => (props.type === "confirm" ? "#fcfcfc" : "#151944")};
  background-color: ${(props) =>
    props.type === "confirm" ? (props.disabled ? "#d9d9d9" : "#151944") : ""};
  border-color: ${(props) => (props.disabled ? "#d9d9d9" : "#151944")};

  &:first-child {
    margin-right: 1.5625vw;
  }
`;

const ButtonText = styled.div`
  padding: 0.52vw 1.3vw;
  text-align: center;
  font-size: min(max(1.0416vw, 18px), 20px);

  ${RWD_LG(css`
    padding: 6px 15px;
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const AddressWrapper = styled.div`
  padding-top: 40px;

  ${RWD_SM(css`
    padding-top: 20px;
  `)};
`;

const AddressTitle = styled.div`
  color: #151944;
  font-size: min(max(1.145vw, 20px), 22px);
  font-weight: bold;
  padding-bottom: 10px;
  display: flex;

  ${RWD_MD(css`
    font-size: min(max(0.9375vw, 16px), 18px);
  `)};
`;

const SystemTokenContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #151944;
  justify-content: space-between;
`;

const SystemToken = styled.div`
  font-size: 1.145vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: min(max(1.145vw, 20px), 22px);
  color: #151944;

  ${RWD_MD(css`
    font-size: min(max(0.9375vw, 16px), 18px);
  `)};
`;

const CopyIconWrapper = styled.div`
  width: 28px;
  height: 28px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  ${RWD_SM(css`
    width: 25px;
    height: 25px;

    svg {
      width: 25px;
      height: 25px;
    }
  `)};
`;

const QrcodeContainer = styled.div`
  padding: 20px 0 10px 0;
  margin: auto;
`;

const Label = styled.label`
  color: #151944;
  display: flex;
  width: 50%;

  ${RWD_SM(css`
    width: 30%;
  `)};
`;

const PackageInfoContainer = styled.div`
  color: #151944;
  display: flex;
  align-items: center;
  font-size: min(max(1.0416vw, 18px), 20px);

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 14px), 16px);
  `)};

  ${RWD_SM(css`
    border-bottom: 1px solid #151944;
    padding-bottom: 5px;
  `)};
`;

const PackageInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${RWD_SM(css`
    gap: 10px;
  `)};
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0 10px 10px;
  border-bottom: 1px solid #151944;
  justify-content: space-between;
  width: 50%;

  ${RWD_SM(css`
    width: 70%;
    border-bottom: 0px;
    padding: 0;
  `)};
`;

const MiningEditModal = ({
  setIsOpen,
  handleEdit,
  name,
  setName,
  returnType,
  setReturnType,
  modalType,
  txid,
  setTxid,
  selectMiningData,
}) => {
  const address = useSelector(selectSystem)?.despositAddress;
  const [addressData, setAddressData] = useState([]);
  const handleClose = () => {
    setIsOpen(false);
    setName("");
    setReturnType("");
    setTxid("");
  };

  useEffect(() => {
    const data = address.filter((token) => token.Crypto === "BEP-20");
    setAddressData(data);
  }, [address]);

  const CopyResString = LangString("alert_copy", "Copy successful");

  const handleCopy = () => {
    navigator.clipboard.writeText(addressData[0]?.Address).then(() => {
      AlertMySwal({
        title: CopyResString,
        showConfimButton: false,
      });
    });
  };

  return (
    <Wrapper>
      <Container>
        {(() => {
          switch (modalType) {
            case "name":
              return (
                <>
                  <Title>Edit Pacakage Name</Title>
                  <InputWrapper>
                    <SubTitle>Input Pacakage Name</SubTitle>
                    <Input onChange={(e) => setName(e.target.value)} />
                  </InputWrapper>
                </>
              );

            case "returntype":
              return (
                <>
                  <Title>Change Return Type</Title>
                  <InputWrapper>
                    <Select
                      group={"ReturnType"}
                      title={"Return type"}
                      onChangeFun={setReturnType}
                      nowValue={returnType}
                      placeholder={"Select a return type"}
                      colorType={"white"}
                    />
                  </InputWrapper>
                </>
              );

            case "txid":
              return (
                <>
                  <Title>Report TXID</Title>
                  <AddressTitle>Package Information</AddressTitle>

                  <PackageInfoWrapper>
                    <PackageInfoContainer>
                      <Label>Name</Label>
                      <TextContainer>
                        {selectMiningData[0]?.Name ?? "N/A"}
                      </TextContainer>
                    </PackageInfoContainer>

                    <PackageInfoContainer>
                      <Label>Package Name</Label>
                      <TextContainer>
                        {selectMiningData[0]?.PackageName}
                      </TextContainer>
                    </PackageInfoContainer>

                    <PackageInfoContainer>
                      <Label>Deposit Amount</Label>
                      <TextContainer>
                        {selectMiningData[0]?.Deposit}
                      </TextContainer>
                    </PackageInfoContainer>
                  </PackageInfoWrapper>

                  <AddressWrapper>
                    <AddressTitle>
                      System Token ({addressData[0]?.Asset} /{" "}
                      {addressData[0]?.Crypto})
                    </AddressTitle>
                    <SystemTokenContainer>
                      <SystemToken>{addressData[0]?.Address}</SystemToken>
                      <CopyIconWrapper onClick={handleCopy}>
                        <Icons.Copy />
                      </CopyIconWrapper>
                    </SystemTokenContainer>
                  </AddressWrapper>
                  <QrcodeContainer>
                    <QRCodeCanvas
                      id="qrcode"
                      value={addressData[0]?.Address}
                      bgColor={"transparent"}
                    />
                  </QrcodeContainer>

                  <InputWrapper>
                    <SubTitle>Input TXID</SubTitle>
                    <Input onChange={(e) => setTxid(e.target.value)} />
                  </InputWrapper>
                </>
              );
          }
        })()}

        <ButtonWrapper>
          <Button onClick={handleClose}>
            <ButtonText>
              <Lang datakey="cancel" defaultText="Cancel" />
            </ButtonText>
          </Button>
          <Button
            type={"confirm"}
            onClick={() => handleEdit()}
            disabled={(() => {
              switch (modalType) {
                case "name":
                  return name.trim() === "";

                case "returntype":
                  return returnType === "";

                case "txid":
                  return txid.trim() === "";
              }
            })()}
          >
            <ButtonText>
              <Lang datakey="confirm" defaultText="Confirm" />
            </ButtonText>
          </Button>
        </ButtonWrapper>
      </Container>
    </Wrapper>
  );
};

export default MiningEditModal;
