// --- plugins
import styled from 'styled-components';
import { MenuList, MenuItem, ListItemText, Button } from '@mui/material';

// --- images
import Logo from '../../img/ava_logo_dark.png';
import { routes } from '../../components/Router';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: clamp(.5rem, .8rem, 1rem);
`;

const ImgContainer = styled.div`
    width: 70%;
    max-width: 250px;
    min-width: 100px;
    margin: auto;
    padding: 0 2vw;

    img {
        display: block;
        width: 100%;
    }

    span {
        display: block;
        font-size: 70%;
        padding-top: .3rem;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 1px;
    }
`;

const MenuContainer = styled.div`
    flex: 1;
    padding: 1.5rem 0;

    li {
        padding: .8rem 2.5rem;

        span {
            letter-spacing: 1.5px;
        }
    }
`;

const Logout = styled.div`
    padding: 2rem 2rem 0;
    
    button {
        width: 100%;
        color: #ddd;
        outline: 1px solid #ddd;
        font-weight: normal;
        transition: .3s;

        &:hover {
            outline: 1px solid #fff;
            color: #fff;
        }
    }
`;

const SideMenu = () => {
    const navigate = useNavigate();

    const MENUITEMS = [
        {
            text: 'Dashboard',
            link: routes?.backend_dashboard,
        },
        {
            text: '取得返還群組',
            link: routes?.backend_investAhft,
        },
        {
            text: '提領審核',
            link: routes?.backend_withdrawal,
        },
        {
            text: 'Commit Transfer',
            link: routes?.backend_mining_commit_transfer,
        },
    ];

    const logout = () => {
        localStorage.removeItem('systemToken');
        return navigate(routes.backend_login);
    };

    return (
        <Container>
            <ImgContainer>
                <img src={Logo} alt='ava logo' />
                <span>Backend</span>
            </ImgContainer>
            <MenuContainer>
                <MenuList>
                    {MENUITEMS?.map((item, index) => (
                        <MenuItem key={index} onClick={() => navigate(item.link)}>
                            <ListItemText>{item.text}</ListItemText>
                        </MenuItem>
                    ))}
                </MenuList>
            </MenuContainer>
            <Logout>
                <Button variant="outlined" onClick={logout}>
                    登出
                </Button>
            </Logout>
        </Container>
    )
}

export default SideMenu
