import styled, { css, keyframes } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../../utils/rwd";
import { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { selectSystem } from "../../../../slices/systemSlice";
import Lang from "../../../../global/Lang";
import Icons from "../../../../global/icons";

import { RoiChart } from "../PersonalData/components/RoiChart";

import {
  useGetAlgoAvaDataQuery,
  useGetAlgoRoisMutation,
  useGetAlgoPositionHistoriesMutation,
} from "../../../../services/datacenterApi";

import Loading from "../../../../components/Loading/Loading";

import SelectDataCenter from "../../../../global/SelectDataCenter";

import { CandleChart } from "../PersonalData/components/CandleChart";

import { dateTimeFormat } from "../../../../utils";

import BCH from "../../../../images/crypto/bch.png";
import BNB from "../../../../images/crypto/bnb.png";
import BTC from "../../../../images/crypto/btc.png";
import CAKE from "../../../../images/crypto/cake.png";
import ETH from "../../../../images/crypto/eth.png";
import IOTA from "../../../../images/crypto/iota.png";
import NEO from "../../../../images/crypto/neo.png";
import USDT from "../../../../images/crypto/usdt.png";
import EOS from "../../../../images/crypto/eos.png";
import ETC from "../../../../images/crypto/etc.png";

const Wrapper = styled.div`
  color: #fcfcfc;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
`;

const UnderConstructionIcon = styled.div`
  width: 80px;
  height: 80px;

  svg {
    width: 80px;
    height: 80px;
  }

  & path {
    stroke: #0aecd1;
  }
`;

const UnderConstructionText = styled.div`
  color: #0aecd1;
  font-size: 30px;
  padding: 20px 0;
`;

const TotalWrapper = styled.div``;

const TotalContainer = styled.div`
  display: flex;

  ${RWD_SM(css`
    flex-direction: column;
  `)};
`;

const TotalCard = styled.div`
  width: 50%;
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 5px;
  background-color: #22254a;

  :last-child {
    margin-right: 0px;
  }

  ${RWD_SM(css`
    margin-top: 5px;
    margin-right: 0;
    width: 100%;

    :first-child {
      margin-top: 0px;
    }
  `)};
`;

const TotalCardContentWrapper = styled.div`
  display: flex;
  align-items: center;
  width: auto;
`;

const TotalCardContent = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 20px;
`;

const TotalCardTitleWrapper = styled.div`
  font-size: clamp(20px, 1.302083vw, 25px);
  display: flex;
  align-items: center;
`;

const TotalCardInfoWrapper = styled.div`
  font-size: 12px;
  color: #54587b;
  width: 80%;
  text-align: justify;
  padding-top: 5px;
  overflow: hidden;
  transition: all 0.5s linear;
`;

const TotalCardTitle = styled.div`
  color: #0aecd1;
  padding-right: 10px;
`;

const InfoIcon = styled.div`
  display: inline-flex;
  width: 15px;
  cursor: pointer;

  svg {
    width: 15px;
    height: 15px;
  }

  & path {
    stroke: #54587b;
  }

  /* ${RWD_SM(css`
    display: none;
  `)}; */
`;

const TotalCardIcon = styled.div`
  width: clamp(65px, 4.6875vw, 90px);
  height: clamp(65px, 4.6875vw, 90px);

  svg {
    width: clamp(65px, 4.6875vw, 90px);
    height: clamp(65px, 4.6875vw, 90px);
  }

  & path {
    fill: #54587b;
  }
`;

const TotalCardNumWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

const TotalCardNum = styled.div`
  font-size: min(max(1.875vw, 34px), 36px);
  font-family: Montserrat;
  padding: 0 5px;
  font-weight: 900;
  color: #fcfcfc;
`;

const TotalCardSymbol = styled.div`
  color: #fcfcfc;
`;

const RoiChartWrapper = styled.div`
  background-color: #22254a;
  width: 100%;
  height: 400px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;

  ${RWD_SM(css`
    height: 300px;
  `)};
`;

const RoiChartContainer = styled.div`
  width: 95%;
  height: 100%;
`;

const RoiChartDaysWrapper = styled.div`
  width: 95%;
  padding: 15px 0;
`;

const RoiChartDaysContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 95%;
`;

const RoiChartDays = styled.div`
  cursor: pointer;
  transition: all 0.3s;

  color: ${({ $status }) => ($status ? "#0aecd1" : "#fcfcfc")};

  :hover {
    color: #0aecd1;
  }
`;

const MarketPositionChartWrapper = styled.div`
  width: 100%;
  margin-top: 5px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  height: 100%;
`;

const MarketPositionChart = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
  background-color: #22254a;

  ${RWD_SM(css`
    width: 100%;
    margin-top: 5px;
  `)};
`;

const MarketPositionChartTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 95%;
`;

const MarketPositionChartTitle = styled.div`
  font-size: min(max(1.0416vw, 18px), 20px);
  color: #fcfcfc;
  padding: 15px 0;

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 16px), 18px);
  `)};
`;

const MarketPositionIntervalWrapper = styled.div`
  padding-left: 20px;
`;

const MarketPositionIntervalContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const MarketPositionInterval = styled.div`
  cursor: pointer;
  transition: all 0.3s;

  color: ${({ $status }) => ($status ? "#0aecd1" : "#fcfcfc")};

  :hover {
    color: #0aecd1;
  }
`;

const CandleChartWrapper = styled.div`
  width: 95%;
  height: 400px;

  ${RWD_SM(css`
    height: 300px;
  `)};
`;

const MarketPositionChartContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  ${RWD_SM(css`
    flex-direction: column-reverse;
  `)};
`;

const MarketPositionTargetWrapper = styled.div`
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-left: 5px;
  background-color: #22254a;

  ${RWD_SM(css`
    width: 100%;
    margin-left: 0;
  `)};
`;

const MarketPositionTargetContainer = styled.div`
  padding: 40px 10px;
  display: flex;
  height: 100%;
  width: 80%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;

  ${RWD_SM(css`
    flex-direction: row;
    padding: 10px;
    width: 100%;
  `)};
`;

const MarketPositionTarget = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  padding: 5px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  border-radius: 5px;
  transition: all 0.3s;

  border-color: ${({ $active }) =>
    $active ? "#0aecd1" : "rgba(255, 255, 255, 0.15)"};

  &:hover {
    border-color: #0aecd1;
  }

  ${RWD_SM(css`
    padding: 10px 0;
    /* flex-direction: column; */
  `)};
`;

const MarketPositionTargetIcon = styled.div`
  width: clamp(25px, 1.5625vw, 30px);
  height: clamp(25px, 1.5625vw, 30px);
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const MarketPositionTargetValue = styled.div`
  font-weight: 500;
  letter-spacing: 1.1px;
  font-size: clamp(20px, 1.302vw, 25px);

  ${RWD_SM(css`
    font-size: 16px;
  `)};
`;

const PositionHistoryWrapper = styled.div`
  background-color: #22254a;
  width: 100%;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
`;

const PositionHistoryTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;

  ${RWD_SM(css`
    flex-direction: column;
  `)};
`;

const PositionHistoryTitle = styled.div`
  font-size: min(max(1.0416vw, 18px), 20px);
  color: #fcfcfc;
  padding: 15px 0;

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 16px), 18px);
  `)};

  ${RWD_SM(css`
    width: 100%;
  `)};
`;

const PositionHistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  gap: 20px;
`;

const PositionHistoryCard = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
`;

const PositionHistoryCardNameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;

  ${RWD_SM(css`
    flex-direction: column;
  `)};
  /* font-weight: 600; */
`;

const PositionHistoryCardNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: clamp(16px, 0.9375vw, 18px);

  ${RWD_SM(css`
    font-size: 14px;
  `)};
`;

const PositionHistoryTarget = styled.div`
  color: #00c2cb;
  font-weight: 600;
`;

const PositionHistorySide = styled.div`
  color: #f85125;
  padding-left: 10px;
  color: ${({ $side }) => ($side === "Long" ? "#1CAC2A" : "#F05D2F")};
  font-weight: 600;

  ${RWD_SM(css`
    padding-left: 5px;
  `)};
`;

const PositionHistoryCardName = styled.div``;

const PositionHistoryCardStatus = styled.div`
  font-weight: 600;
  font-size: clamp(20px, 1.25vw, 24px);
  text-align: right;
`;

const PositionHistoryCardContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px 20px;
  width: 75%;

  ${RWD_XL(css`
    grid-template-columns: repeat(2, 1fr);
    width: 80%;
  `)};

  ${RWD_SM(css`
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  `)};
`;

const PositionHistoryCardContentWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  ${RWD_SM(css`
    flex-direction: column;
  `)};
`;

const PositionHistoryCardItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  ${RWD_MD(css`
    flex-direction: column;
    align-items: flex-start;
  `)};
`;

const PositionHistoryCardItemItemValueWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

const PositionHistoryCardItemTitle = styled.div`
  color: #54587b;
  font-size: clamp(15px, 0.8854vw, 17px);
`;

const PositionHistoryCardItemValue = styled.div`
  padding-right: 5px;
  font-weight: 500;
  font-size: clamp(16px, 1.145833vw, 22px);

  ${RWD_SM(css`
    padding-left: 0px;
  `)};
`;

const PositionHistoryCardItemCurrency = styled.div`
  font-size: clamp(12px, 0.729167vw, 14px);
  opacity: 0.7;
`;

const PositionHistorySelectWrapper = styled.div`
  display: flex;
  gap: 20px;
  width: 50%;

  ${RWD_SM(css`
    width: 100%;
    padding-bottom: 20px;
  `)};
`;

const ClosingPnlWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
const ClosingPnlTitle = styled.div`
  color: #54587b;
`;

const ClosingPnlValuWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;
const ClosingPnlValue = styled.div`
  font-weight: 500;
  font-size: clamp(18px, 1.302vw, 25px);
  color: ${({ $status }) => ($status ? "#F05D2F" : "#1CAC2A")};
`;

const ClosingPnlNoValue = styled.div`
  font-weight: 500;
  font-size: clamp(18px, 1.302vw, 25px);
  color: #fcfcfc;
`;

const ClosingCurrency = styled.div`
  padding: 0 5px;
  font-size: clamp(12px, 0.729167vw, 14px);
  opacity: 0.7;
`;

const Nodata = styled.div`
  font-size: min(max(1.0416vw, 18px), 20px);
  padding: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
`;

const Algo = () => {
  // robot list
  const airobots = useSelector(selectSystem).airobots;

  const { data, error, isError, isSuccess, isLoading } =
    useGetAlgoAvaDataQuery();
  // get roi
  const [
    _getRoi,
    {
      data: roiData,
      error: roiError,
      isError: roiIsError,
      isSuccess: roiIsSuccess,
      isLoading: roiIsLoading,
    },
  ] = useGetAlgoRoisMutation();

  // get position history

  const [
    _getPositionHistory,
    {
      data: positionHistoryData,
      error: positionHistoryError,
      isError: positionHistoryIsError,
      isSuccess: positionHistoryIsSuccess,
      isLoading: positionHistoryIsLoading,
    },
  ] = useGetAlgoPositionHistoriesMutation();

  const [totalProfit, setTotalProfit] = useState();
  const [totalRoi, setTotalRoi] = useState();
  const [roiChartData, setRoiChartData] = useState([]);
  const [marketData, setMarketData] = useState([]);
  const [historyData, setHistoryData] = useState([]);

  const [infoList, setInfoList] = useState([
    {
      name: "pnl",
      open: false,
      content:
        "PnL represents the total profit or loss generated from your cryptocurrency trades. It is calculated based on the difference between the purchase price and the current market price, helping you track your investment performance.",
    },
    {
      name: "roi",
      open: false,
      content:
        "ROI is a ratio that measures the profitability of the trader over a fixed period. It is calculated using indicative optimized portfolio value to avoid distortion caused by deposits or withdrawals.",
    },
  ]);

  const handleInfo = (type) => {
    setInfoList(
      infoList.map((data) => {
        if (data.name === type) {
          return {
            ...data,
            open: !data.open,
          };
        } else {
          return data;
        }
      })
    );
  };

  const marketPositionIntervalList = [
    {
      Id: 1,
      UnitValue: 15,
      Value: 15,
      Unit: <Lang datakey="m" defaultText="M" />,
    },
    {
      Id: 2,
      UnitValue: 1,
      Value: 60,
      Unit: <Lang datakey="h" defaultText="H" />,
    },
    {
      Id: 3,
      UnitValue: 4,
      Value: 240,
      Unit: <Lang datakey="h" defaultText="H" />,
    },
  ];

  const roiPeriodDaysList = [7, 30, 90];
  const positionHistoryPeriodDaysList = [
    {
      Key: "7D",
      UnitValue: 7,
      Value: 7,
      Unit: <Lang datakey="d" defaultText="D" />,
    },
    {
      Key: "30D",
      UnitValue: 30,
      Value: 30,
      Unit: <Lang datakey="d" defaultText="D" />,
    },
  ];

  const cryptologos = [
    { Src: BCH, Target: "BCH" },
    { Src: BNB, Target: "BNB" },
    { Src: BTC, Target: "BTC" },
    { Src: CAKE, Target: "CAKE" },
    { Src: ETH, Target: "ETH" },
    { Src: IOTA, Target: "IOTA" },
    { Src: NEO, Target: "NEO" },
    { Src: USDT, Target: "USDT" },
    { Src: EOS, Target: "EOS" },
    { Src: ETC, Target: "ETC" },
  ];

  const [selectMarketPositionInterval, setSelectMarketPositionInterval] =
    useState(15);

  const [selectRoiPeriodDays, setSelectRoiPeriodDays] = useState(30);
  const [selectPositionHistoryPeriodDays, setSelectPositionHistoryPeriodDays] =
    useState(7);

  const [
    rememberselectPositionHistoryPeriodDays,
    setRememberselectPositionHistoryPeriodDays,
  ] = useState([]);

  const [selectPositionHistoryRobotId, setSelectPositionHistoryRobotId] =
    useState("all");

  const [selectPositionTarget, setSelectPositionTarget] = useState("");

  const [robotList, setRobotList] = useState([]);

  const [targetList, setTargetList] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      setTotalProfit(data.AlgoSummary.TotalProfit);
      setTotalRoi(data.AlgoSummary.TotalRoi);
      setRoiChartData(data.AlgoRois);
      setMarketData(data.AlgoPositions);
      setHistoryData(data.AlgoPositionHistories);
    }
  }, [data, isError]);

  useEffect(() => {
    // robot list
    if (
      rememberselectPositionHistoryPeriodDays[
        rememberselectPositionHistoryPeriodDays.length - 1
      ] !==
      rememberselectPositionHistoryPeriodDays[
        rememberselectPositionHistoryPeriodDays.length - 2
      ]
    ) {
      const filterRobotList = [
        ...new Set(historyData.map((item) => item.RobotId)),
      ];

      const robot = airobots
        .filter((robot) => filterRobotList.indexOf(robot.Id) > -1)
        .map((data) => {
          return {
            Key: data.NameLanguageKey,
            Value: data.Id,
          };
        });

      setRobotList([
        {
          Key: "All",
          Value: "all",
        },
        ...robot,
      ]);

      setSelectPositionHistoryRobotId("all");
    }
  }, [historyData]);

  useEffect(() => {
    const filterTargetList = [
      ...new Set(marketData.map((item) => item.TargetKey)),
    ];

    setTargetList(filterTargetList);
  }, [marketData]);

  useEffect(() => {
    setSelectPositionTarget(targetList[0]);
  }, [targetList]);

  useEffect(() => {
    _getRoi({
      body: {
        PeriodDays: selectRoiPeriodDays,
      },
    });
  }, [selectRoiPeriodDays]);

  useEffect(() => {
    if (roiIsSuccess) {
      setRoiChartData(roiData.AlgoRois);
    }
  }, [roiData, roiIsError]);

  useEffect(() => {
    if (positionHistoryIsSuccess) {
      setHistoryData(positionHistoryData.AlgoPositionHistories);
    }
  }, [positionHistoryData, positionHistoryIsError]);

  const handleRoiPeriodDays = (day) => {
    setSelectRoiPeriodDays(day);
  };

  const handleMarketPositionInterval = (interval) => {
    setSelectMarketPositionInterval(interval);
  };

  useEffect(() => {
    setRememberselectPositionHistoryPeriodDays([
      ...rememberselectPositionHistoryPeriodDays,
      selectPositionHistoryPeriodDays,
    ]);

    if (selectPositionHistoryRobotId === "all") {
      _getPositionHistory({
        body: {
          PeriodDays: selectPositionHistoryPeriodDays,
        },
      });
    } else {
      if (
        rememberselectPositionHistoryPeriodDays[
          rememberselectPositionHistoryPeriodDays.length - 1
        ] !== selectPositionHistoryPeriodDays
      ) {
        _getPositionHistory({
          body: {
            PeriodDays: selectPositionHistoryPeriodDays,
          },
        });
      } else {
        _getPositionHistory({
          body: {
            PeriodDays: selectPositionHistoryPeriodDays,
            RobotId: selectPositionHistoryRobotId,
          },
        });
      }
    }
  }, [selectPositionHistoryPeriodDays, selectPositionHistoryRobotId]);

  return (
    <Wrapper>
      {(isLoading || positionHistoryIsLoading) && <Loading />}
      <Container>
        {/* <UnderConstructionIcon>
          <Icons.DataCenter_UnderConstruction />
        </UnderConstructionIcon>
        <UnderConstructionText>Coming Soon</UnderConstructionText> */}
        <TotalWrapper>
          <TotalContainer>
            <TotalCard>
              <TotalCardContentWrapper>
                <TotalCardIcon>
                  <Icons.DataCenter_Coin />
                </TotalCardIcon>
                <TotalCardContent>
                  <TotalCardTitleWrapper>
                    <TotalCardTitle>
                      <Lang
                        datakey="datacenter_algodata_totalpnl"
                        defaultText="TOTAL PNL"
                      />
                    </TotalCardTitle>
                    <InfoIcon onClick={() => handleInfo("pnl")}>
                      <Icons.Tooltip />
                    </InfoIcon>
                  </TotalCardTitleWrapper>
                  <TotalCardNumWrapper>
                    <TotalCardSymbol>$</TotalCardSymbol>
                    <TotalCardNum>{totalProfit?.toFixed(2)}</TotalCardNum>
                  </TotalCardNumWrapper>
                </TotalCardContent>
              </TotalCardContentWrapper>
              <TotalCardInfoWrapper
                style={{
                  maxHeight: infoList.filter((data) => data.name === "pnl")[0]
                    .open
                    ? "200px"
                    : "0",
                }}
              >
                <Lang
                  datakey="datacenter_algodata_totalpnl_info"
                  defaultText={
                    infoList.filter((data) => data.name === "pnl")[0].content
                  }
                />
              </TotalCardInfoWrapper>
            </TotalCard>
            <TotalCard>
              <TotalCardContentWrapper>
                <TotalCardIcon>
                  <Icons.DataCenter_Analytics />
                </TotalCardIcon>

                <TotalCardContent>
                  <TotalCardTitleWrapper>
                    <TotalCardTitle>
                      <Lang
                        datakey="datacenter_algodata_totalroi"
                        defaultText="TOTAL ROI"
                      />
                    </TotalCardTitle>
                    <InfoIcon onClick={() => handleInfo("roi")}>
                      <Icons.Tooltip />
                    </InfoIcon>
                  </TotalCardTitleWrapper>
                  <TotalCardNumWrapper>
                    <TotalCardNum>{totalRoi?.toFixed(2)}</TotalCardNum>
                    <TotalCardSymbol>%</TotalCardSymbol>
                  </TotalCardNumWrapper>
                </TotalCardContent>
              </TotalCardContentWrapper>

              <TotalCardInfoWrapper
                style={{
                  maxHeight: infoList.filter((data) => data.name === "roi")[0]
                    .open
                    ? "200px"
                    : "0",
                }}
              >
                <Lang
                  datakey="datacenter_algodata_totalroi_info"
                  defaultText={
                    infoList.filter((data) => data.name === "roi")[0].content
                  }
                />
              </TotalCardInfoWrapper>
            </TotalCard>
          </TotalContainer>
        </TotalWrapper>

        <RoiChartWrapper>
          <RoiChartDaysWrapper>
            <RoiChartDaysContainer>
              {roiPeriodDaysList.map((day) => (
                <RoiChartDays
                  key={day}
                  onClick={() => handleRoiPeriodDays(day)}
                  $status={day === selectRoiPeriodDays}
                >
                  {day}
                  <Lang datakey="d" defaultText="D" />
                </RoiChartDays>
              ))}
            </RoiChartDaysContainer>
          </RoiChartDaysWrapper>
          <RoiChartContainer>
            <RoiChart chartData={roiChartData} />
          </RoiChartContainer>
        </RoiChartWrapper>

        <MarketPositionChartWrapper>
          <MarketPositionChartContainer>
            <MarketPositionChart>
              <MarketPositionChartTitleWrapper>
                <MarketPositionChartTitle>
                  <Lang
                    datakey="datacenter_algodata_marketandposition"
                    defaultText="Market & Position"
                  />
                </MarketPositionChartTitle>
                <MarketPositionIntervalWrapper>
                  <MarketPositionIntervalContainer>
                    {marketPositionIntervalList.map((data) => (
                      <MarketPositionInterval
                        key={data.Id}
                        onClick={() => handleMarketPositionInterval(data.Value)}
                        $status={data.Value === selectMarketPositionInterval}
                      >
                        {data.UnitValue}
                        {data.Unit}
                      </MarketPositionInterval>
                    ))}
                  </MarketPositionIntervalContainer>
                </MarketPositionIntervalWrapper>
              </MarketPositionChartTitleWrapper>

              <CandleChartWrapper>
                <Fragment>
                  {marketData.length > 0 ? (
                    <CandleChart
                      target={selectPositionTarget}
                      marketData={marketData}
                      interval={selectMarketPositionInterval}
                    />
                  ) : (
                    <Nodata>
                      <Lang datakey="nodata" defaultText="No data available" />
                    </Nodata>
                  )}
                </Fragment>
              </CandleChartWrapper>
            </MarketPositionChart>

            <MarketPositionTargetWrapper>
              <MarketPositionTargetContainer>
                {targetList.map((data, index) => (
                  <MarketPositionTarget
                    key={index}
                    $active={selectPositionTarget === data}
                    onClick={() => setSelectPositionTarget(data)}
                  >
                    <MarketPositionTargetIcon>
                      <img
                        src={
                          cryptologos.filter((logo) => logo.Target === data)[0]
                            ?.Src
                        }
                      />
                    </MarketPositionTargetIcon>
                    <MarketPositionTargetValue>
                      {data}
                    </MarketPositionTargetValue>
                  </MarketPositionTarget>
                ))}
              </MarketPositionTargetContainer>
            </MarketPositionTargetWrapper>
          </MarketPositionChartContainer>
        </MarketPositionChartWrapper>

        <PositionHistoryWrapper>
          <PositionHistoryTitleWrapper>
            <PositionHistoryTitle>
              <Lang
                datakey="datacenter_algodata_positionhistory"
                defaultText="Position History"
              />
            </PositionHistoryTitle>

            <PositionHistorySelectWrapper>
              <SelectDataCenter
                title={<Lang datakey="days" defaultText="Days" />}
                dataList={positionHistoryPeriodDaysList}
                onChangeFun={setSelectPositionHistoryPeriodDays}
                nowValue={selectPositionHistoryPeriodDays}
              />

              <SelectDataCenter
                title={"Algo"}
                dataList={robotList}
                onChangeFun={setSelectPositionHistoryRobotId}
                nowValue={selectPositionHistoryRobotId}
                type={"robot"}
              />
            </PositionHistorySelectWrapper>
          </PositionHistoryTitleWrapper>

          <PositionHistoryContainer>
            {historyData.length > 0 ? (
              <Fragment>
                {historyData.map((history) => (
                  <PositionHistoryCard>
                    <PositionHistoryCardNameWrapper>
                      <PositionHistoryCardNameContainer>
                        <PositionHistoryCardName>
                          {history.NickName} (
                          <Lang
                            datakey={history.RobotNameKey}
                            defaultText={""}
                          />
                          ) -
                        </PositionHistoryCardName>
                        <PositionHistoryTarget>
                          {history.TargetKey}
                        </PositionHistoryTarget>
                        <PositionHistorySide $side={history.Side}>
                          {history.Side}
                        </PositionHistorySide>
                      </PositionHistoryCardNameContainer>
                      <PositionHistoryCardStatus>
                        {history.EntrustStatus}
                      </PositionHistoryCardStatus>
                    </PositionHistoryCardNameWrapper>

                    <PositionHistoryCardContentWrapper>
                      <PositionHistoryCardContent>
                        <PositionHistoryCardItemWrapper>
                          <PositionHistoryCardItemTitle>
                            Opened
                          </PositionHistoryCardItemTitle>
                          <PositionHistoryCardItemValue>
                            {dateTimeFormat(history.OpenTime)}
                          </PositionHistoryCardItemValue>
                        </PositionHistoryCardItemWrapper>
                        <PositionHistoryCardItemWrapper>
                          <PositionHistoryCardItemTitle>
                            Entry Price
                          </PositionHistoryCardItemTitle>
                          <PositionHistoryCardItemItemValueWrapper>
                            <PositionHistoryCardItemValue>
                              {history.EntryPrice.toFixed(2)}
                            </PositionHistoryCardItemValue>
                            <PositionHistoryCardItemCurrency>
                              USDT
                            </PositionHistoryCardItemCurrency>
                          </PositionHistoryCardItemItemValueWrapper>
                        </PositionHistoryCardItemWrapper>

                        <PositionHistoryCardItemWrapper>
                          <PositionHistoryCardItemTitle>
                            Closed Vol.
                          </PositionHistoryCardItemTitle>

                          <PositionHistoryCardItemItemValueWrapper>
                            {history.EntrustStatus === "Open" ? (
                              "--"
                            ) : (
                              <Fragment>
                                <PositionHistoryCardItemValue>
                                  {history.ClosedVol.toFixed(2)}
                                </PositionHistoryCardItemValue>
                                <PositionHistoryCardItemCurrency>
                                  USDT
                                </PositionHistoryCardItemCurrency>
                              </Fragment>
                            )}
                          </PositionHistoryCardItemItemValueWrapper>
                        </PositionHistoryCardItemWrapper>

                        <PositionHistoryCardItemWrapper>
                          <PositionHistoryCardItemTitle>
                            Closed
                          </PositionHistoryCardItemTitle>
                          <PositionHistoryCardItemValue>
                            {history.EntrustStatus === "Open"
                              ? "--"
                              : dateTimeFormat(history.ClosedTime)}
                          </PositionHistoryCardItemValue>
                        </PositionHistoryCardItemWrapper>

                        <PositionHistoryCardItemWrapper>
                          <PositionHistoryCardItemTitle>
                            Avg. Close Price
                          </PositionHistoryCardItemTitle>

                          <PositionHistoryCardItemItemValueWrapper>
                            {history.EntrustStatus === "Open" ? (
                              "--"
                            ) : (
                              <Fragment>
                                <PositionHistoryCardItemValue>
                                  {history.AvgClosePrice.toFixed(2)}
                                </PositionHistoryCardItemValue>
                                <PositionHistoryCardItemCurrency>
                                  USDT
                                </PositionHistoryCardItemCurrency>
                              </Fragment>
                            )}
                          </PositionHistoryCardItemItemValueWrapper>

                          {/* <PositionHistoryCardItemItemValueWrapper>
                            <PositionHistoryCardItemValue>
                              {history.AvgClosePrice.toFixed(2)}
                            </PositionHistoryCardItemValue>
                            <PositionHistoryCardItemCurrency>
                              USDT
                            </PositionHistoryCardItemCurrency>
                          </PositionHistoryCardItemItemValueWrapper> */}
                        </PositionHistoryCardItemWrapper>
                      </PositionHistoryCardContent>

                      <ClosingPnlWrapper>
                        <ClosingPnlTitle>Closing PNL</ClosingPnlTitle>
                        <ClosingPnlValuWrapper>
                          {history.ClosingPnl ? (
                            <ClosingPnlValue $status={history.ClosingPnl < 0}>
                              {history.ClosingPnl.toFixed(2)}
                            </ClosingPnlValue>
                          ) : (
                            <ClosingPnlNoValue>--</ClosingPnlNoValue>
                          )}

                          {history.ClosingPnl && (
                            <ClosingCurrency>USDT</ClosingCurrency>
                          )}
                        </ClosingPnlValuWrapper>
                      </ClosingPnlWrapper>
                    </PositionHistoryCardContentWrapper>
                  </PositionHistoryCard>
                ))}
              </Fragment>
            ) : (
              <Nodata>
                <Lang datakey="nodata" defaultText="No data available" />
              </Nodata>
            )}
          </PositionHistoryContainer>
        </PositionHistoryWrapper>
      </Container>
    </Wrapper>
  );
};

export default Algo;
