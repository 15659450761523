import axios from "axios";

export const getKline = (symbol, startDate, endDate) => {
  const url = `https://api.bybit.com/v5/market/kline?category=linear&symbol=${symbol}USDT&interval=D&start=${startDate}&end=${endDate}`;
  return axios.get(url);
};

export const getDataCenterKline = (symbol, interval) => {
  const url = `https://api.bybit.com/v5/market/kline?category=inverse&symbol=${symbol}USDT&interval=${interval}&limit=100`;
  return axios.get(url);
};
