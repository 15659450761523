import { useState } from 'react';
import { useData } from '../../utils/context';
import { StyledInput } from '../Global/Ui';

// plugins
import Swal from 'sweetalert2/dist/sweetalert2.js';

// images
import Light_History_icon from '../../img/light_history.png';
import Dark_History_icon from '../../img/dark_history.png';

// api
import { API_AvaPointWithdrawal } from '../../utils/api';

const WithdrawalHistory = ({ withdrawal, setWithdrawal }) => {
  const { isDarkMode, userData, setUserData } = useData();
  const getWithdrawal = async () => {
    const res = await API_AvaPointWithdrawal({ mode: 'Get' });
    // if (!res) console.error('API error');
    // if (res.ResultCode !== 0) Swal.fire(res.Message);
    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return Swal.fire(res.Message);
    setWithdrawal(res.ListWithdrawal);
  };
  const handleWidthdrawal = async () => {
    const res = await API_AvaPointWithdrawal({ mode: 'Apply', Point: value });
    
    document.getElementById('withdrawalPanel')?.classList.toggle('hidden');
    setValue(100);

    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return Swal.fire(res.Message);
    // if (!res) return console.error('API error');
    // if (res.ResultCode !== 0) return Swal.fire(res.Message);

    const newUserData = {
      ...userData,
      AvaPoint: (userData.AvaPoint - value)
    };
    setUserData(newUserData);
    getWithdrawal();
    Swal.fire({
      icon: 'success',
      title: 'Success!',
    });
  };
  const checkAvaPoint = (e) => {
    e.stopPropagation();
    if (value < 100) return Swal.fire('最低Withdrawal金額為 100');
    if (value > userData.AvaPoint) return Swal.fire('AVA Point 餘額不足');
    setValue(100);
    handleWidthdrawal();
  };
  const closeWithdrawal = (e) => {
    e.stopPropagation();
    const withdrawalPanel = document.getElementById('withdrawalPanel');
    if (e.currentTarget.id === 'withdrawalPanel' || e.currentTarget.id === 'cancelWithdrawal') {
      withdrawalPanel.classList.toggle('hidden');
      setValue(100);
    }
  };
  const toggleWithdrawalHistory = (e) => {
    e.stopPropagation();
    const table = document.getElementById('historyTable');

    if (table.classList.contains('hidden')) {
      table.classList.remove('hidden');
      table.style.animation = 'swal2-show2 .3s 1';
    }
    else{
      table.style.animation = 'swal2-hide2 .15s forwards';
      setTimeout(() => table.classList.add('hidden'), 150);
    }
  };
  const transformDateTime = (utcTime) => {
    const utcTimestamp = new Date(`${utcTime}`).getTime();
    const zoneTimestamp = utcTimestamp + -1 * (new Date().getTimezoneOffset()) * 60 * 1000;
    const zoneTime = new Date(zoneTimestamp);
    return `${zoneTime.getFullYear()}/${zoneTime.getMonth() + 1}/${zoneTime.getDate()} ${zoneTime.getHours()}:${zoneTime.getMinutes()}`;
  };

  const [value, setValue] = useState(100);
  
  return (
    <div
      id='withdrawalPanel'
      className='
        hidden
        fixed z-30 top-0 left-0 w-screen h-screen
        bg-[#3337]
      '
      onClick={closeWithdrawal}
    >
      {/* withdrawal input */}
      <div
        id='withdrawalInputPanel'
        className={`
          absolute abs-center w-full sm:w-2/3 md:w-auto md:px-20 py-10 text-center
          bg-white-menu dark:bg-black-menu rounded
        `}
        onClick={e => e.stopPropagation()}
      >
        <img
          src={isDarkMode ? Dark_History_icon : Light_History_icon}
          alt=''
          className='absolute right-2 top-2 cursor-pointer'
          onClick={toggleWithdrawalHistory}
        />
        <h3 className='py-2 '>
          Withdrawal volume:
        </h3>
        <div className='flex mt-3'>
          <StyledInput
            id='withdrawalVolumn'
            type='number'
            value={value}
            inputClass='text-center'
            onChange={e => setValue(e.target.value)}
          />
          <button
            className='
              inline-block ml-2 px-3 py-1
              rounded text-blue border border-sub-blue
              hover:bg-sub-blue hover:text-white
            '
            onClick={() => setValue(Math.floor(userData.AvaPoint))}
          >
            Max
          </button>
        </div>
        <div className='mt-5'>
          <button
            className='
              inline-block px-4 py-1 rounded w-1/3
              text-word-dark dark:text-word-dark bg-primary dark:bg-primary
            '
            onClick={checkAvaPoint}
          >
            OK
          </button>
          <button
            id='cancelWithdrawal'
            className='
              inline-block ml-3 px-4 py-1 rounded w-1/3
              text-word-dark dark:text-word-dark bg-sub-word dark:bg-sub-word
            '
            onClick={e => closeWithdrawal(e)}
          >
            Cancel
          </button>
        </div>
      </div>

      {/* withdrawal history */}
      <div
        id='historyTable'
        className='absolute abs-center w-full 2xl:w-2/5 h-screen py-5 2xl:py-20 hidden'
        onClick={e => e.stopPropagation()}
      >
        <table className='
          w-full h-full inline-block py-2 text-center overflow-y-scroll
          rounded bg-white-main dark:bg-black-main
          flex flex-col justify-between'
        >
          <thead className='w-full inline-block'>
            <tr className='w-full inline-block flex 2xl:px-20 py-3 text-sub'>
              <td className='flex-[2]'>UserAddress</td>
              <td className='flex-1'>Point</td>
              <td className='flex-1'>Status</td>
              <td className='flex-[2]'>CreatTime</td>
            </tr>
          </thead>
          <tbody className={`inline-block w-full ${!!withdrawal && withdrawal !== 'get' && withdrawal.length > 0 && 'flex-1'}`}>
            {
              withdrawal !== 'get'
              &&
              withdrawal?.map(({ ID, UserAddress, Point, Status, CreateTime }) => (
                <tr key={ID} className='inline-block w-full flex 2xl:px-20 py-3 border-b border-b-sub-word'>
                  <td className='flex-[2] overflow-x-scroll hideScrollbar'>
                    <span className='w-full'>
                      {UserAddress}
                    </span>
                  </td>
                  <td className='flex-1'>{Point}</td>
                  <td className='flex-1'>{Status}</td>
                  <td className='flex-[2]'>{transformDateTime(CreateTime)}</td>
                </tr>
              ))
            }
            {
              (withdrawal !== 'get') && withdrawal?.length === 0
              &&
              <tr className='inline-block w-full flex 2xl:px-20 py-3'>
                <td className='w-full'>
                  <span className='mx-auto text-[1.5rem] text-sub font-bold'>No Withdrawal</span>
                </td>
              </tr>
            }
            
          </tbody>
          <tfoot className='inline-block w-full'>
            <tr className='inline-block w-full flex 2xl:px-20 py-3'>
                <td className='w-full'>
                  <button
                    className='
                      inline-block mx-auto px-3 py-1
                      bg-primary rounded text-word-dark dark:text-word-light
                    '
                    onClick={toggleWithdrawalHistory}
                  >
                    Close
                  </button>
                </td>
              </tr>
          </tfoot>
        </table>
      </div>
    </div>
  )
}

export default WithdrawalHistory