export const myStudy_first = (indicatorName, description) => ({
  name: indicatorName,
  metainfo: {
    id: `${indicatorName}@tv-basicstudies-1`,
    description: indicatorName,
    shortDescription: description,
    isCustomIndicator: true,
    is_hidden_study: false,
    is_price_study: false,
    plots: [
      {
          id: 'plot_0',
          type: 'ohlc_open',
          target: 'candlePlot_0',
      },
      {
          id: 'plot_1',
          type: 'ohlc_high',
          target: 'candlePlot_0',
      },
      {
          id: 'plot_2',
          type: 'ohlc_low',
          target: 'candlePlot_0',
      },
      {
          id: 'plot_3',
          type: 'ohlc_close',
          target: 'candlePlot_0',
      },
    ],
    ohlcPlots: {
      candlePlot_0: {
        title: description,
        isHidden: false
      },
    },
    defaults: {
      ohlcPlots: {
        candlePlot_0: {
          plottype: 'ohlc_candles',
          borderColor: '#000000',
          color: '#2196F3',
          drawBorder: true,
          drawWick: true,
          visible: true,
          wickColor: '#737375',
        }
      }
    },
    inputs: [],
  },
  constructor: function() {
    this.main = function(context, input) {
      this._context = context;
      this._input = input;
      const { open, high, low, close } = this._context.symbol;
      return [open, high, low, close];
    }
  }
});