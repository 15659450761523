import { createApi } from "@reduxjs/toolkit/query/react";
import defaultBaseQuery from "./query/defaultBaseQueryV2";
import { makeUrlencoded } from "./common";

export const datacenterApi = createApi({
  reducerPath: "datacenterApi",
  baseQuery: defaultBaseQuery,
  endpoints: (builder) => ({
    getSentimentAnalysis: builder.query({
      query: () => ({
        url: "/datacenter/GetSentimentAnalysis",
        method: "GET",
      }),
    }),
    getTrendFollowingData: builder.query({
      query: () => ({
        url: "/datacenter/GetTrendFollowingData",
        method: "GET",
      }),
    }),
    getPersonalData: builder.query({
      query: () => ({
        url: "/datacenter/GetPersonalData",
        method: "GET",
      }),
    }),
    getNews: builder.query({
      query: () => ({
        url: "/datacenter/GetNews",
        method: "GET",
      }),
    }),
    getBlockchainNewsData: builder.query({
      query: (page) => ({
        url: `/DataCenter/GetBlockchainNewsData?page=${page}`,
        method: "GET",
      }),
    }),
    getUserSubscribeBlockChainNewsStatus: builder.query({
      query: () => ({
        url: "/DataCenter/UserSubscribeBlockChainNewsStatus",
        method: "GET",
      }),
      providesTags: [{ type: "newsSubscribe", id: "newsSubscribe" }],
    }),
    updateUserSubscribeBlockChainNewsStatus: builder.mutation({
      query: () => ({
        url: "/DataCenter/SubscribeBlockChainNews",
        method: "POST",
      }),
      invalidatesTags: [{ type: "newsSubscribe", id: "newsSubscribe" }],
    }),
    getAlgoAvaData: builder.query({
      query: () => ({
        url: "/datacenter/GetAlgoAvaData",
        method: "GET",
      }),
    }),
    getAlgoRois: builder.mutation({
      query: ({ body }) => ({
        url: "/datacenter/GetAlgoRois",
        method: "POST",
        body: makeUrlencoded(body),
      }),
    }),
    getAlgoPositionHistories: builder.mutation({
      query: ({ body }) => ({
        url: "/datacenter/GetAlgoPositionHistories",
        method: "POST",
        body: makeUrlencoded(body),
      }),
    }),
  }),
});

export const {
  useGetSentimentAnalysisQuery,
  useGetTrendFollowingDataQuery,
  useGetPersonalDataQuery,
  useGetNewsQuery,
  useGetBlockchainNewsDataQuery,
  useGetUserSubscribeBlockChainNewsStatusQuery,
  useUpdateUserSubscribeBlockChainNewsStatusMutation,
  useGetAlgoAvaDataQuery,
  useGetAlgoRoisMutation,
  useGetAlgoPositionHistoriesMutation,
} = datacenterApi;
