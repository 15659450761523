import styled, { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../../utils/rwd";
import { useState, useEffect } from "react";

import Lang from "../../../../global/Lang";
import Icons from "../../../../global/icons";

const Wrapper = styled.div`
  color: #fcfcfc;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 5px;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #22254a;
`;

const UnderConstructionIcon = styled.div`
  width: 80px;
  height: 80px;

  svg {
    width: 80px;
    height: 80px;
  }

  & path {
    stroke: #0aecd1;
  }
`;

const UnderConstructionText = styled.div`
  color: #0aecd1;
  font-size: 30px;
  padding: 20px 0;
`;

const TrendKeyWords = () => {
  return (
    <Wrapper>
      <Container>
        <UnderConstructionIcon>
          <Icons.DataCenter_UnderConstruction />
        </UnderConstructionIcon>
        <UnderConstructionText>
          <Lang datakey="comingsoon" defaultText="Coming Soon" />
        </UnderConstructionText>
      </Container>
    </Wrapper>
  );
};

export default TrendKeyWords;
