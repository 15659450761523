import styled from "styled-components";
import { useEffect, useState } from "react";
import {
  AreaChart,
  Area,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  Line,
  ComposedChart,
} from "recharts";
import { getKline } from "../../../v2/services/klineApi";

const TooltipWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  font-size: 12px;
  color: #fcfcfc;
  font-family: Montserrat;
  align-items: center;
`;

const TooltipIcon = styled.div`
  width: 10px;
  height: 10px;
  background: ${({ $color }) => $color};
`;

const TooltipLabelWrapper = styled.div`
  padding-bottom: 5px;
  text-align: center;
`;

const TooltipValueWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TooltipValueTitle = styled.div`
  padding: 0 8px;
`;

const TooltipValue = styled.div`
  padding-left: 10px;
`;

export const AlgoProfitChart = ({ chartData, target, periodDays }) => {
  const [profitData, setProfitData] = useState([]);
  const [klineData, setKlineData] = useState([]);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);

  useEffect(() => {
    getKline(
      target,
      Date.parse(new Date()) - (periodDays + 1) * 86400000,
      Date.parse(new Date())
    )
      .then((res) => {
        if (res.data.retMsg === "OK") {
          setKlineData(res.data.result?.list.reverse());
        }
      })
      .catch((error) => {
        console.log(error);
        // MySwal.fire({
        //   title: error.message,
        //   icon: "error",
        //   confirmButtonText: "確定",
        // });
      });
  }, [target, periodDays]);

  useEffect(() => {
    if (klineData.length > 0) {
      setProfitData(
        chartData.map((data, index) => {
          return {
            ...data,
            Kline: klineData[index]?.[4],
          };
        })
      );
      setMaxValue(Math.max(...klineData.map((data) => parseFloat(data[4]))));
      setMinValue(Math.min(...klineData.map((data) => parseFloat(data[4]))));
    }
  }, [chartData, klineData]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <TooltipWrapper className="custom-tooltip">
          <TooltipLabelWrapper className="label">
            {label?.split(" ")[0]}
          </TooltipLabelWrapper>
          <TooltipValueWrapper className="intro">
            <TooltipIcon $color={payload[0]?.stroke}></TooltipIcon>
            <TooltipValueTitle>{payload[0]?.name}</TooltipValueTitle>
            <TooltipValue>{payload[0]?.value.toFixed(2)}%</TooltipValue>
          </TooltipValueWrapper>
          <TooltipValueWrapper className="intro">
            <TooltipIcon $color={payload[1]?.stroke}></TooltipIcon>
            <TooltipValueTitle>{payload[1]?.name}</TooltipValueTitle>
            <TooltipValue>{payload[1]?.value}</TooltipValue>
          </TooltipValueWrapper>
        </TooltipWrapper>
      );
    }

    return null;
  };

  const formatXAxisDate = (date) => {
    return `${date?.split(" ")[0].split("-")[1]}/${
      date?.split(" ")[0].split("-")[2]
    }`;
  };

  const formatYAxisRoi = (value) => `${value}%`;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={1000}
        height={250}
        data={profitData}
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient
            id="colorUv"
            x1="0"
            y1="0"
            x2="0"
            y2="1"
            yAxisId="left"
          >
            <stop offset="5%" stopColor="#ffb3ed" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#ffb3ed" stopOpacity={0} />
          </linearGradient>
          <linearGradient
            id="colorPv"
            x1="0"
            y1="0"
            x2="0"
            y2="1"
            yAxisId="right"
          >
            <stop offset="5%" stopColor="#01ffff" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#01ffff" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="CloseTime"
          dy={5}
          stroke="rgba(255, 255, 255, 0.2)"
          tickFormatter={formatXAxisDate}
          style={{
            fontSize: "10px",
            fontFamily: "Montserrat",
          }}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          stroke="rgba(255, 255, 255, 0.2)"
          dx={0}
          style={{
            fontSize: "10px",
            fontFamily: "Montserrat",
          }}
          tickFormatter={formatYAxisRoi}
        />
        <YAxis
          yAxisId="left"
          orientation="left"
          stroke="rgba(255, 255, 255, 0.2)"
          style={{
            fontSize: "10px",
            fontFamily: "Montserrat",
          }}
          domain={[minValue, maxValue]}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend
          verticalAlign="top"
          height={25}
          wrapperStyle={{ fontSize: "11px", fontFamily: "Montserrat" }}
        />
        <Area
          type="monotone"
          dataKey="CumulativeProfitRate"
          stroke="#ffb3ed"
          fillOpacity={1}
          fill="url(#colorUv)"
          yAxisId="right"
          name="ROI"
          activeDot={{ r: 2 }}
        />
        <Line
          type="monotone"
          dataKey="Kline"
          stroke="#01ffff"
          fillOpacity={1}
          fill="url(#colorPv)"
          yAxisId="left"
          name={target}
          activeDot={{ r: 2 }}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
