const Loading = () => {
  return (
    <div className='fixed z-[100] w-screen h-screen top-0 left-0 bg-black/50'>
      <div className='abs-center z-[1]'>
        <span className='loader' />
      </div>
    </div>
  )
}

export default Loading
