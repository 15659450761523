import styled, { css, keyframes } from "styled-components";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../v2/utils/rwd";
import Icons from "../../../v2/global/icons";
import { NewsDateFormat } from "../../../v2/utils";
import { routes } from "../../Router";

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.8);
  color: #151944;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  /* padding: 40px; */
  width: 70vw;
  max-width: 1200px;
  min-height: 70vh;
  border-radius: 8px;
  position: relative;
  animation: mymove 5s alternate infinite;
  overflow: hidden;
  color: #fcfcfc;

  ${RWD_LG(css`
    width: 85%;
  `)};

  ${RWD_MD(css`
    width: 70%;
    height: 85vh;
    /* padding: 20px 40px; */
  `)};

  ${RWD_SM(css`
    width: 90%;
    height: 88vh;
    /* padding: 20px; */
  `)};
`;

const NewsSlideWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const NewsWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex: 1;
`;

const NewsScrollWrapper = styled.div`
  width: 100%;
  height: auto;
  overflow: auto;

  &::-webkit-scrollbar-track {
    border-radius: 0.125rem;
    background-color: #3a3d5a;
  }
  &::-webkit-scrollbar {
    width: 0.25rem;
    border-radius: 0.125rem;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0.125rem;
    background-color: rgba(255, 255, 255, 0.3);
  }

  ${RWD_SM(css`
    height: 70vh;
    padding: 0 15px 0 0;
  `)};
`;

const NewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  padding: 30px;

  ${RWD_SM(css`
    padding: 20px;
  `)};
`;

const NewsTime = styled.div`
  font-size: clamp(14px, 0.83333vw, 16px);
  color: rgba(255, 255, 255, 0.5);
  font-family: Montserrat;
`;

const NewsTitle = styled.div`
  color: #fcfcfc;
  font-size: clamp(25px, 2.34375vw, 45px);
  padding: 10px 0;
  font-weight: 500;

  ${RWD_SM(css`
    font-size: clamp(20px, 1.822917vw, 35px);
  `)};
`;

const NewsSubTitle = styled.div`
  padding-bottom: 20px;
  font-size: clamp(20px, 1.822917vw, 35px);

  ${RWD_SM(css`
    padding-bottom: 10px;
  `)};
`;

const NewsContentContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;

  ${RWD_MD(css`
    flex-direction: column;
  `)};
`;

const NewsContent = styled.div`
  text-align: justify;
  color: ${(props) => props.theme.colors.Grey};
  width: 80%;

  ${RWD_MD(css`
    width: 100%;
  `)};
`;

const NewsImgWrapper = styled.div`
  width: 20%;
  display: inline-flex;
  min-width: 200px;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  ${RWD_MD(css`
    width: 100%;
  `)};
`;

const NewsCommentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 40px;

  ${RWD_MD(css`
    flex-direction: column;
    padding: 20px 0;
  `)};
`;

const NewsComment = styled.div`
  width: 45%;

  ${RWD_MD(css`
    width: 100%;
  `)};
`;

const NewsCommentContentWrapper = styled.div`
  position: relative;
`;

const NewsCommentContentLorem = styled.div`
  color: transparent;
  text-shadow: rgba(255, 255, 255, 0.3) 0 0 10px;
  text-align: justify;
  padding: 20px 0;

  ${RWD_SM(css`
    padding: 10px 0 20px 0;
    font-size: clamp(14px, 0.83333vw, 16px);
  `)};
`;

const NewsCommentContentContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s;
  cursor: pointer;
  width: 100%;

  &:hover {
    color: #0aecd1;
  }
`;

const NewsCommentContent = styled.div`
  padding-bottom: 5px;
  font-size: clamp(14px, 0.9375vw, 18px);
  text-align: center;

  :last-child {
    padding-bottom: 0;
  }
`;

const NewsLevel = styled.div`
  width: 45%;

  ${RWD_MD(css`
    width: 100%;
  `)};
`;

const NewsLevelWrapper = styled.div`
  display: flex;
  padding-top: 20px;
  gap: 40px;

  ${RWD_SM(css`
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
  `)};
`;

const NewsLevelContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NewsLevelIcon = styled.div`
  display: inline-flex;
  width: clamp(30px, 3.385vw, 65px);
  height: clamp(30px, 3.385vw, 65px);

  svg {
    width: clamp(30px, 3.385vw, 65px);
    height: clamp(30px, 3.385vw, 65px);

    & path {
      fill: ${({ $status }) =>
        $status !== "positive"
          ? $status === "negative"
            ? "#f05d2f"
            : "#00c2cb"
          : "#1cac2a"};
    }
  }
`;

const NewsLevelMarket = styled.div`
  padding-bottom: 10px;
  font-size: clamp(13px, 0.833vw, 16px);
`;

const NewsLevelContent = styled.div`
  padding-top: 10px;
  font-size: clamp(14px, 0.833vw, 16px);
`;

const NewsClose = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;

    & path {
      stroke: rgba(255, 255, 255, 0.5);
    }
  }
`;

const NewsArrowWrapper = styled.button`
  width: 8vw;
  padding: 10px 0;
  cursor: ${({ $status }) => ($status ? "pointer" : "default")};
  visibility: ${({ $status }) => ($status ? "visible" : "hidden")};

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 30px;
    height: 30px;

    & path {
      stroke: rgba(255, 255, 255, 0.5);
      transition: all 0.3s linear;
    }
  }

  &:hover svg path {
    stroke: #0aecd1;
  }

  ${RWD_SM(css`
    svg {
      width: 15px;
      height: 15px;
    }
  `)};
`;

const NewsPageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const NewsPageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;

  ${RWD_MD(css`
    padding-top: 15px;
  `)};
`;

const breatheAnimation = keyframes`
    0% {
        box-shadow: 0px 0px 0px 0px rgba(10, 236, 209, 0.8);
    }

    50% {
        box-shadow: 0px 0px 0px 8px rgba(10, 236, 209, 0.2);
    }

    100% {
        box-shadow: 0px 0px 0px 12px transparent;
    }
`;

const NewsPage = styled.div`
  width: 10px;
  height: 10px;

  border-radius: 50%;
  text-align: center;

  overflow: hidden;
  cursor: pointer;
  z-index: 2;
  position: relative;

  background-color: ${({ $active }) => ($active ? "#0aecd1" : "#ccc")};

  &:hover {
    animation: ${breatheAnimation} 1500ms linear infinite;
  }
`;

const ActionButtonWrapper = styled.div`
  padding-top: 20px;
  margin: auto;
  ${RWD_SM(css`
    padding-top: 10px;
  `)};
`;

const ActionButton = styled.button`
  border-radius: 60px;
  min-width: 125px;
  /* width: 140px; */
  color: #fcfcfc;
  text-shadow: 0 0 2px #fcfcfc;
  border-color: #0aecd1;
  box-shadow: 0 0 2px #0aecd1, inset 0 0 2px #0aecd1;
  text-align: center;
  transition: 0.5s;

  &:hover {
    /*Green*/
    color: #0aecd1;
    text-shadow: 0 0 5px #0aecd1;
    border-color: #0aecd1;
    box-shadow: 0 0 5px #0aecd1, inset 0 0 5px #0aecd1;
  }

  ${RWD_LG(css`
    min-width: 110px;
  `)};
`;

const ActionButtonText = styled.span`
  font-size: min(max(1.0416vw, 16px), 20px);
  color: #fcfcfc;
  display: inline-flex;
  padding: 10px 20px;
  ${RWD_LG(css`
    padding: 8px 18px;
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const NewsModal = ({
  newsData,
  activeNews,
  setNewsModalIsOpen,
  setActiveNews,
}) => {
  const navigate = useNavigate();

  const handleCloseModal = () => {
    setNewsModalIsOpen(false);
  };

  const handleActiveNews = (id, type) => {
    switch (type) {
      case "page":
        setActiveNews(id);
        break;
      case "next":
        setActiveNews(activeNews + 1);
        break;
      case "prev":
        setActiveNews(activeNews - 1);
        break;
      default:
        break;
    }
  };

  const handleLogin = () => {
    navigate({
      pathname: `${routes.login_v2}?redirect=newsdata`,
    });
  };
  return (
    <Wrapper>
      <NewsSlideWrapper>
        <NewsArrowWrapper
          $status={activeNews > 1}
          onClick={() => handleActiveNews("", "prev")}
          disabled={activeNews === 1}
        >
          <Icons.Arrow_left />
        </NewsArrowWrapper>
        <Container>
          <NewsClose onClick={handleCloseModal}>
            <Icons.Close />
          </NewsClose>
          <NewsWrapper>
            {newsData
              .filter((news) => news.id === activeNews)
              .map((data) => (
                <NewsContainer>
                  <NewsTime>{NewsDateFormat(data.CreateTime)}</NewsTime>
                  <NewsTitle>{data.Title}</NewsTitle>
                  <NewsScrollWrapper>
                    <NewsContentContainer>
                      <NewsImgWrapper>
                        <img
                          src={
                            data?.ImageUrl
                              ? data?.ImageUrl
                              : "https://picsum.photos/1024/1024?random=1"
                          }
                          alt={data?.Title}
                        />
                      </NewsImgWrapper>
                      <NewsContent>{data.Content}</NewsContent>
                    </NewsContentContainer>

                    <NewsCommentWrapper>
                      <NewsComment>
                        <NewsSubTitle>市場短評</NewsSubTitle>
                        <NewsCommentContentWrapper>
                          <NewsCommentContentLorem>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Deleniti ex eos possimus nesciunt. Maxime
                            neque, cupiditate, omnis dolores enim quidem atque
                            eligendi odit in modi aperiam aliquid, dignissimos
                            dolor nemo atque eligendi odit in modi aperiam
                            aliquid, dignissimos dolor nemo.
                          </NewsCommentContentLorem>

                          <NewsCommentContentContainer onClick={handleLogin}>
                            <NewsCommentContent>
                              想看更多AI市場分析嗎？
                            </NewsCommentContent>
                            <NewsCommentContent>
                              點我註冊AVA查看更多！
                            </NewsCommentContent>
                          </NewsCommentContentContainer>
                        </NewsCommentContentWrapper>
                      </NewsComment>
                      <NewsLevel>
                        <NewsSubTitle>分析評級</NewsSubTitle>
                        <NewsLevelWrapper>
                          {data.Comment.map((item) => (
                            <NewsLevelContentWrapper>
                              <NewsLevelMarket>＊＊＊＊市場</NewsLevelMarket>
                              <NewsLevelIcon $status={item}>
                                {(() => {
                                  switch (item) {
                                    case "positive":
                                      return <Icons.Positive />;
                                    case "neutral":
                                      return <Icons.Neutral />;
                                    case "negative":
                                      return <Icons.Negative />;
                                  }
                                })()}
                              </NewsLevelIcon>
                              <NewsLevelContent>
                                {(() => {
                                  switch (item) {
                                    case "positive":
                                      return "良好";
                                    case "neutral":
                                      return "中性";
                                    case "negative":
                                      return "負面";
                                  }
                                })()}
                              </NewsLevelContent>
                            </NewsLevelContentWrapper>
                          ))}
                        </NewsLevelWrapper>
                      </NewsLevel>
                    </NewsCommentWrapper>
                  </NewsScrollWrapper>
                  <ActionButtonWrapper>
                    <ActionButton onClick={handleLogin}>
                      <ActionButtonText>閱讀更多</ActionButtonText>
                    </ActionButton>
                  </ActionButtonWrapper>
                </NewsContainer>
              ))}
          </NewsWrapper>
        </Container>

        <NewsArrowWrapper
          $status={activeNews < newsData.length}
          onClick={() => handleActiveNews("", "next")}
          disabled={activeNews === newsData.length}
        >
          <Icons.Arrow_right />
        </NewsArrowWrapper>
      </NewsSlideWrapper>
      <NewsPageWrapper>
        <NewsPageContainer>
          {Array.from({ length: newsData.length }, (number, index) => (
            // <AlgoPage $status>
            <NewsPage
              $active={index + 1 == activeNews}
              onClick={() => handleActiveNews(index + 1, "page")}
            ></NewsPage>
          ))}
        </NewsPageContainer>
      </NewsPageWrapper>
    </Wrapper>
  );
};

export default NewsModal;
