import TokenDistribution from './TokenDistribution';

const Main = ({ distribution, totalUSDT }) => {
  return (
    <div className='mt-16 md:mt-0 flex-[4]'>
      <TokenDistribution
        distribution={distribution}
        totalUSDT={totalUSDT}
      />
    </div>
  )
}

export default Main
