import styled, { css } from "styled-components";
import { RWD_XL, RWD_LG, RWD_MD, RWD_SM, RWD_SS, RWD_XS } from "../utils/rwd";
import { useSelector } from "react-redux";
import { selectSystem } from "../slices/systemSlice";
import { useEffect, useState } from "react";
import Icons from "./icons";

const Wrapper = styled.div`
  width: 100%;
  display: ${(props) => (props.styled === "column" ? "flex" : "grid")};
  grid-template-columns: ${(props) =>
    props.colorType === "white" ? "30% auto" : "repeat(2, 1fr)"};
  grid-gap: ${(props) => (props.styled === "column" ? "0" : "40px")};
  align-items: ${(props) => (props.styled === "column" ? "start" : "center")};

  flex-direction: ${(props) => (props.styled === "column" ? "column" : "")};

  ${RWD_SM(css`
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  `)};
`;

const Label = styled.label`
  text-align: left;
  width: auto;
  color: ${(props) => (props.colorType === "white" ? "#22254a" : "#fcfcfc")};
  padding: 10px 0;
  text-align: left;
  font-size: min(max(1.0416vw, 18px), 20px);

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 16px), 18px);
  `)};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  color: ${(props) => (props.colorType === "white" ? "#22254a" : "#fcfcfc")};
`;

const IconContainer = styled.div`
  transform: ${(props) => (props.isOpen ? "scaleY(-1)" : "scaleY(1)")};
  transition: all 0.3s;
`;

const SelectWrapper = styled.div`
  position: relative;
  background: ${(props) =>
    props.colorType === "white"
      ? "#d9d9d9;"
      : "linear-gradient(155.14deg,rgba(255, 255, 255, 0) -2.13%,rgba(255, 255, 255, 0.15) 136.58%)"};

  border: 1px solid rgba(255, 255, 255, 0.15);
  border-bottom: ${(props) =>
    props.isOpen
      ? "1px solid transparent"
      : "1px solid 1px solid rgba(255, 255, 255, 0.15)"};
  border-radius: ${(props) => (props.isOpen ? "8px 8px 0 0" : "8px")};
  cursor: pointer;
`;

const SelectItem = styled.div`
  padding: 10px 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const SelectItemText = styled.div``;

const OptionWrapper = styled.ul`
  position: absolute;
  width: 100%;
  top: 100%;
  background: #22254a;
  border-left: 1px solid;
  border-right: 1px solid;
  border-bottom: 1px solid;
  z-index: 5;
  border-color: ${(props) =>
    props.colorType === "white" ? "#d9d9d9;" : "rgba(255, 255, 255, 0.15)"};
  border-radius: 0px 0px 8px 8px;
  overflow: hidden;
`;

const OptionItem = styled.li`
  padding: 10px 20px;
  background: ${(props) =>
    props.colorType === "white"
      ? "#d9d9d9;"
      : "linear-gradient(155.14deg,rgba(255, 255, 255, 0) -2.13%,rgba(255, 255, 255, 0.15) 136.58%)"};
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
    color: #22254a;
  }
`;

const Select = ({
  title,
  onChangeFun,
  placeholder,
  group,
  nowValue,
  colorType,
  styled,
  defaultValue,
  className,
}) => {
  const data = useSelector(selectSystem).enums;
  const [isOpen, setIsOpen] = useState(false);
  const [nowSelectText, setNowSelectText] = useState("");

  const ReturnTypeText = [
    { id: 1, text: "Compound Package (CP)" }, // no
    { id: 2, text: "Withdraw Package (WP)" }, // all
    { id: 3, text: "Withdraw Mined Token (WMT)" }, // interest
    { id: 4, text: "Mined to Ava Point (MAP)" }, // avapoint
  ];

  useEffect(() => {
    if (defaultValue && data.length > 0) {
      setNowSelectText(
        Object.entries(
          data?.filter((list) => list?.GroupName == group)?.[0]?.Items
        ).filter((el) => el[1] == nowValue)?.[0]?.[0]
      );
    }
  }, [data, defaultValue]);

  useEffect(() => {
    if (nowValue && data.length > 0) {
      if (group === "ReturnType") {
        setNowSelectText(ReturnTypeText[nowValue - 1]?.text);
      } else {
        setNowSelectText(
          Object.entries(
            data?.filter((list) => list?.GroupName == group)?.[0]?.Items
          ).filter((el) => el[1] == nowValue)?.[0]?.[0]
        );
      }
    }
  }, [nowValue]);

  const handleClickItem = (value) => {
    onChangeFun(value);
    setIsOpen(false);
  };

  return (
    <Wrapper colorType={colorType} styled={styled} className={className}>
      <Label colorType={colorType}>{title}</Label>
      <Container colorType={colorType}>
        <SelectWrapper
          colorType={colorType}
          onClick={() => setIsOpen(!isOpen)}
          isOpen={isOpen}
        >
          <SelectItem>
            <SelectItemText>
              {nowSelectText ? nowSelectText : placeholder}
            </SelectItemText>
            <IconContainer isOpen={isOpen}>
              <Icons.Arrow />
            </IconContainer>
          </SelectItem>
        </SelectWrapper>
        {isOpen && (
          <OptionWrapper colorType={colorType}>
            {data && data.length > 0 && group === "ReturnType"
              ? Object.entries(
                  data?.filter((list) => list.GroupName === "ReturnType")?.[0]
                    ?.Items
                )
                  .filter((el) => el[0] !== "transfer")
                  ?.map((option, index) => (
                    <OptionItem
                      colorType={colorType}
                      value={option[1]}
                      key={option[1]}
                      onClick={(e) => handleClickItem(e.target.value)}
                    >
                      {ReturnTypeText[index]?.text}
                    </OptionItem>
                  ))
              : Object.entries(
                  data?.filter((list) => list.GroupName === group)?.[0]?.Items
                )?.map((option) => (
                  <OptionItem
                    colorType={colorType}
                    value={option[1]}
                    key={option[1]}
                    onClick={(e) => handleClickItem(e.target.value)}
                  >
                    {option[0]}
                  </OptionItem>
                ))}
          </OptionWrapper>
        )}
      </Container>
    </Wrapper>
  );
};

export { Select };
