import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectAccount, addAccountApikey } from "../../../slices/accountSlice";
import { selectSystem } from "../../../slices/systemSlice";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";

import Icons from "../../../global/icons";
import Lang from "../../../global/Lang";

import Binance from "../../../images/binance.png";
import Bybit from "../../../images/bybit.png";
import Ig from "../../../icons/ig_white.svg";

import ApiKeyPermissionModal from "../../../components/ApiKeyPermissionModal";

import {
  useSetAccountApikeyMutation,
  useGetAccountApikeyQuery,
} from "../../../services/accountApi";

import { useGetTargetExchangeIdsMutation } from "../../../services/algoApi";

import {
  ErrorMySwal,
  FooterMySwal,
  SuccessMySwal,
} from "../../../global/MySwal";
import { LangString } from "../../../utils";
import { getLanguage } from "../../../services/common";

import { useLocation } from "react-router-dom";
import Loading from "../../../components/Loading/Loading";

import JoyrideComponent from "../../../global/JoyrideComponent";
import { Fragment } from "react";

// #region styled
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  font-size: min(max(1.875vw, 34px), 36px);
  color: #fcfcfc;
  padding-bottom: 20px;
  align-self: start;
  text-transform: capitalize;

  ${RWD_MD(css`
    font-size: min(max(1.0416vw, 18px), 20px);
  `)};
`;

const SubTitle = styled.div`
  font-size: min(max(1.25vw, 22px), 24px);
  font-weight: bold;
  text-align: center;
  color: #fcfcfc;
  padding-bottom: 32px;

  ${RWD_MD(css`
    font-size: min(max(1.145vw, 20px), 22px);
  `)};
`;

const ExchangeCardWrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.exchange === 1 ? "center" : "flex-start "};
  padding-bottom: 80px;
  gap: 20px;
  flex-wrap: wrap;

  ${RWD_LG(css`
    width: 65%;
  `)};

  ${RWD_SM(css`
    width: 100%;
    padding-bottom: 40px;
  `)};
`;

const ExchangeCard = styled.div`
  border: 1px solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: calc((100% - 20px) / 2);
  height: 180px;
  padding: 0 40px;

  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  cursor: pointer;

  border-radius: 8px;

  border-color: ${(props) =>
    props.selected ? "#0AECD1" : "rgba(255, 255, 255, 0.15)"};

  opacity: ${(props) => (props.selected ? 1 : 0.8)};

  cursor: pointer;
  &:hover {
    opacity: 1;
  }

  ${RWD_MD(css`
    height: 130px;
    padding: 0 20px;
  `)};

  ${RWD_SM(css`
    height: 100px;
    padding: 0 20px;
  `)};
`;

const ExchangeIcon = styled.div`
  /* display: inline-flex;
  align-items: center;
  justify-content: center; */
  max-width: 200px;
  height: 45px;

  /* ${RWD_LG(css`
    width: 180px;
    height: 40px;
  `)}; */

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    /* object-fit: contain; */
  }

  ${RWD_MD(css`
    height: 40px;
  `)};

  ${RWD_SM(css`
    height: 35px;
  `)};
`;

const ApiKeyWrapper = styled.div`
  min-width: 50vw;
  width: auto;
  min-height: 45px;
  display: flex;
  padding: 10px 20px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  border-radius: 8px;
  border-color: ${(props) =>
    props.error ? "#F05D2F" : " rgba(255, 255, 255, 0.15)"};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: min(max(1.0416vw, 18px), 20px);
  position: relative;

  ${RWD_LG(css`
    width: 80%;
  `)};

  ${RWD_SM(css`
    width: 100%;
  `)};
`;

const ApiKeyEditWrapper = styled.div`
  display: inline-flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: auto;

  /* margin-left: auto; */
  /* position: absolute;
  right: 20px; */

  svg {
    width: 20px;
    height: 20px;
  }

  :hover {
    & path {
      fill: #0aecd1;
    }
  }

  & path {
    fill: #fcfcfc;
  }
`;

const ApiKeyText = styled.div`
  color: #fcfcfc;
  font-size: min(max(0.9375vw, 16px), 18px);
  width: 100%;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${RWD_SM(css`
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const AddApiKeyModalWrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.4);
`;

const AddApiKeyContainer = styled.div`
  background-color: #fcfcfc;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100px;
  /* min-height: 35.416vw; */
  height: auto;
  width: 41.66vw;
  max-width: 800px;
  padding: 20px 40px;
  border-radius: 8px;

  ${RWD_LG(css`
    width: 70%;
  `)};

  ${RWD_MD(css`
    padding: 20px 40px;
  `)};

  ${RWD_SM(css`
    width: 90%;
    padding: 20px;
  `)};
`;

const UpdateApiKeyWrapper = styled.div`
  background: #151944;
  padding: 10px 0;
  font-size: min(max(1.5625vw, 28px), 30px);
  /* padding: 0.833vw; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin: 10px 0;

  ${RWD_MD(css`
    font-size: min(max(1.0416vw, 18px), 20px);
  `)};
`;

const UpdateApiKeyIcon = styled.div`
  max-width: 200px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    max-width: 200px;
    height: 40px;
    /* object-fit: contain; */
  }

  ${RWD_LG(css`
    height: 30px;

    svg {
      height: 30px;
      /* object-fit: contain; */
    }
  `)};

  ${RWD_MD(css`
    height: 25px;

    svg {
      height: 25px;
      /* object-fit: contain; */
    }
  `)};
`;

const AddApiKeySwitchWrapper = styled.div`
  background: #d9d9d9;
  padding: 10px;
  display: flex;
  border-radius: 100px;
  justify-content: space-between;
`;

const AddApiKeySwithItem = styled.div`
  background-color: ${(props) => (props.selected ? "#151944" : "")};
  border-radius: 100px;
  display: flex;
  padding: 0.833vw;
  cursor: pointer;
  width: 100%;
  align-items: center;
  justify-content: center;

  & path {
    fill: ${(props) => (props.selected ? "" : "#151944")};
  }
`;

const AddApiKeySwichIcon = styled.div`
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 35px;
    width: 100%;
    /* height: 100%; */
    object-fit: contain;
  }

  ${RWD_LG(css`
    height: 30px;

    svg {
      height: 30px;
      /* object-fit: contain; */
    }
  `)};

  ${RWD_SM(css`
    height: 25px;

    svg {
      height: 25px;
      /* object-fit: contain; */
    }
  `)};
`;

const AddApiKeyTitle = styled.div`
  color: #151944;
  font-size: min(max(1.5625vw, 28px), 30px);
  /* font-size: min(max(1.0416vw, 18px), 20px); */
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 10px;

  ${RWD_MD(css`
    font-size: min(max(0.9375vw, 16px), 18px);
  `)};
`;

const AddApiKeySubTitle = styled.div`
  color: #151944;
  font-size: min(max(1.25vw, 22px), 24px);
  font-weight: bold;
  padding-bottom: 10px;
  padding-top: 20px;
  display: flex;

  ${RWD_LG(css`
    padding-top: 10px;
  `)};

  ${RWD_MD(css`
    font-size: min(max(0.9375vw, 16px), 18px);
    padding: 10px;
  `)};
`;

const AddApiKeyInput = styled.input`
  background: #d9d9d9;
  padding: 1.0416vw 1.5625vw;
  width: 100%;
  border-radius: 100px;
  color: #151944;

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 14px), 16px);
    padding: 8px 20px;
  `)};
`;

const AddApiKeyInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddApiKeyButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 50px;

  ${RWD_MD(css`
    padding-top: 25px;
    flex-direction: column;
    justify-content: space-between;
  `)};
`;

const AddApiKeyButtonContainer = styled.div`
  ${RWD_SM(css`
    width: 100%;
    display: flex;
    justify-content: space-between;
  `)};
`;

const AddApiKeyVerifiyButton = styled.button`
  width: min(max(7.0833vw, 100px), 135px);
  border-radius: 60px;
  border: 1px solid;
  background-color: ${(props) => (props.disabled ? "#d9d9d9" : "#151944")};
  color: #fcfcfc;
  border-color: ${(props) => (props.disabled ? "#d9d9d9" : "#151944")};
`;

const AddApiKeyCancelButton = styled.button`
  width: min(max(7.0833vw, 100px), 135px);
  border-radius: 60px;
  color: ${(props) => (props.type === "verify" ? "#fcfcfc" : "#151944")};
  background-color: ${(props) => (props.type === "verify" ? "#151944" : "")};
  cursor: pointer;
  border: 1px solid #151944;
  margin-right: 1.5625vw;
`;

const AddApiKeyButtonText = styled.div`
  padding: 0.52vw 1.3vw;
  text-align: center;
  font-size: min(max(1.0416vw, 18px), 20px);

  ${RWD_LG(css`
    padding: 6px 15px;
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const Error = styled.div`
  padding: 10px 0;
  color: #f05d2f;
`;

const AddApiKeyTooltipWrapper = styled.div`
  position: relative;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 3px;
  cursor: help;

  &:hover span {
    visibility: visible;
  }
`;

const AddApiKeyTooltipContainer = styled.div`
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #c0c0c0;
  padding-top: 1px;
  display: flex;

  svg {
    width: 20px;
    height: 20px;
  }

  & path {
    stroke: #c0c0c0;
  }
`;

const AddApiKeyTooltipText = styled.span`
  visibility: hidden;
  width: auto;
  background-color: #54587b;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 0.833vw;
  white-space: nowrap;
  position: absolute;
  left: calc(1.5vw + 15px);
  z-index: 1;
`;

const AddApiKeyTooltipTextUrl = styled.span`
  visibility: hidden;
  width: auto;
  background-color: #54587b;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 0.833vw;
  white-space: nowrap;
  position: absolute;
  left: calc(1.3vw + 15px);
  z-index: 1;
`;

const AuthWrapper = styled.div`
  color: #151944;
  cursor: pointer;
  font-size: min(max(0.9375vw, 16px), 18px);
  margin-right: auto;

  ${RWD_SM(css`
    padding-bottom: 10px;
  `)};
`;

const ApiKeyTooltipWrapper = styled.div`
  color: #c0c0c0;
  font-weight: lighter;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: min(max(0.9375vw, 16px), 18px);
  cursor: pointer;
  gap: 5px;
`;

const ApiKeyTooltipText = styled.div`
  transition: all 0.3s linear;
  border-bottom: 1px solid transparent;

  ${ApiKeyTooltipWrapper} &:hover {
    border-bottom: 1px solid #c0c0c0;
  }
`;

const ApiKeyTooltipContainer = styled.div`
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;

  padding-top: 1px;
  display: flex;

  svg {
    width: 20px;
    height: 20px;
  }

  & path {
    stroke: #c0c0c0;
  }
`;

// #endregion

const AddApiKeyModal = ({
  setIsOpen,
  exchangeId,
  setExchangeId,
  _addApikey,
  modalType,
  apikeys,
  setImgModalIsOpen,
  selectableExchange,
}) => {
  const [newApikey, setNewApikey] = useState("");
  const [newSecretkey, setNewSecretkey] = useState("");
  const [newAccount, setNewAccount] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const isValid = newApikey.trim() !== "" && newSecretkey.trim() !== "";

  const igIsValid =
    newApikey.trim() !== "" &&
    newAccount.trim() !== "" &&
    newPassword.trim() !== "";

  const [isOnly, setIsOnly] = useState(false);

  // useEffect(() => {
  //   console.log("這邊");
  //   // 判斷有沒有選擇的交易所
  //   if (exchangeId === null) {
  //     // 如果可選的交易所中有bybit 不需要改變(預設為bybit)
  //     // 如果不是的話 設定可選交易所的第一個為預設值
  //     console.log("這這這");
  //     selectableExchange.map((data) => {
  //       if (data === 2) {
  //         return;
  //       } else {
  //         setExchangeId(selectableExchange[0]);
  //       }
  //     });
  //   }
  // }, [selectableExchange, exchangeId]);

  // 確認有沒有設定過apiKey
  useEffect(() => {
    if (apikeys.length > 0) {
      // 判斷選擇的交易所是不是唯一一個沒有設定過apiKey的交易所
      if (
        apikeys.filter(({ ExchangeId }) => ExchangeId === exchangeId).length ===
        0
      ) {
        setIsOnly(true);
      }
    } else {
      setIsOnly(false);
    }
  }, [apikeys]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleAddApikey = () => {
    _addApikey({
      body: {
        // Exchange: exchangeId === 1 ? "Binance" : "Bybit",
        ExchangeId: exchangeId,
        Apikey: newApikey,
        Secretkey: newSecretkey,
        Account: newAccount,
        Password: newPassword,
      },
    });
    setIsOpen(false);
  };

  const handleChangeExchange = (id) => {
    if (id === null) return;
    setExchangeId(id);
  };

  // input api key tooltip
  const handleUrl = () => {
    return exchangeId === 1
      ? window.open(
          "https://www.binance.com/en/support/faq/how-to-create-api-360002502072"
        )
      : window.open("https://www.bybit.com/future-activity/en-US/developer");
  };

  const handleLink = () => {
    window.open(`https://www.bybit.com/invite?ref=YZWO1G`, "_blank");
  };

  return (
    <AddApiKeyModalWrapper>
      <AddApiKeyContainer className="createAlgo_addApiKey">
        <AddApiKeyTitle>
          {modalType === "edit" ? (
            <Lang
              datakey="modal_title_updateApi"
              defaultText="Update API Key"
            />
          ) : (
            <Lang datakey="modal_title_addApi" defaultText="Add API Key" />
          )}

          {/* Bybit 顯示 toolTip */}
          {exchangeId === 2 && (
            <ApiKeyTooltipWrapper onClick={handleLink}>
              <ApiKeyTooltipContainer>
                <Icons.Tooltip />
              </ApiKeyTooltipContainer>
              <ApiKeyTooltipText>
                <Lang
                  datakey="modal_title_addApi"
                  defaultText="Click here to register a Bybit account."
                  toolTip={true}
                />
              </ApiKeyTooltipText>
            </ApiKeyTooltipWrapper>
          )}
        </AddApiKeyTitle>

        {modalType === "edit" ? (
          ""
        ) : (
          <AddApiKeySubTitle>
            <Lang
              datakey="addalgo_select_exchange"
              defaultText="Select Exchange"
            />
          </AddApiKeySubTitle>
        )}

        {modalType === "edit" ? (
          <UpdateApiKeyWrapper>
            <UpdateApiKeyIcon>
              {(() => {
                switch (exchangeId) {
                  case 1:
                    return <Icons.Binance_white />;
                  case 2:
                    return <Icons.Bybit_white />;
                  case 3:
                    return <Icons.Bybit_white />;
                  case 4:
                    return <Icons.Ig_white />;

                  default:
                    break;
                }
              })()}
            </UpdateApiKeyIcon>
          </UpdateApiKeyWrapper>
        ) : (
          <>
            {isOnly ? (
              <UpdateApiKeyWrapper>
                <UpdateApiKeyIcon>
                  {/* {exchangeId === 1 ? (
                    <Icons.Binance_white />
                  ) : (
                    <Icons.Bybit_white />
                  )} */}
                  {(() => {
                    switch (exchangeId) {
                      case 1:
                        return <Icons.Binance_white />;
                      case 2:
                        return <Icons.Bybit_white />;
                      case 3:
                        return <Icons.Bybit_white />;
                      case 4:
                        return <Icons.Ig_white />;

                      default:
                        break;
                    }
                  })()}
                </UpdateApiKeyIcon>
              </UpdateApiKeyWrapper>
            ) : (
              <AddApiKeySwitchWrapper>
                {selectableExchange.map((data) => {
                  switch (data) {
                    case 1:
                      return (
                        <AddApiKeySwithItem
                          selected={exchangeId === 1}
                          onClick={() => handleChangeExchange(1)}
                        >
                          <AddApiKeySwichIcon>
                            <Icons.Binance_white />
                          </AddApiKeySwichIcon>
                        </AddApiKeySwithItem>
                      );
                    case 2:
                      return (
                        <AddApiKeySwithItem
                          selected={exchangeId === 2}
                          onClick={() => handleChangeExchange(2)}
                        >
                          <AddApiKeySwichIcon>
                            <Icons.Bybit_white />
                          </AddApiKeySwichIcon>
                        </AddApiKeySwithItem>
                      );
                    // case 3:
                    //   return <Icons.Bybit_white />;
                    case 4:
                      return (
                        <AddApiKeySwithItem
                          selected={exchangeId === 4}
                          onClick={() => handleChangeExchange(4)}
                        >
                          <AddApiKeySwichIcon>
                            <Icons.Ig_white />
                          </AddApiKeySwichIcon>
                        </AddApiKeySwithItem>
                      );

                    default:
                      break;
                  }
                })}
                {/* <AddApiKeySwithItem
                  selected={exchangeId === 1}
                  onClick={() => handleChangeExchange(1)}
                >
                  <AddApiKeySwichIcon>
                    <Icons.Binance_white />
                  </AddApiKeySwichIcon>
                </AddApiKeySwithItem> */}
                {/* <AddApiKeySwithItem
                  selected={exchangeId === 2}
                  onClick={() => handleChangeExchange(2)}
                >
                  <AddApiKeySwichIcon>
                    <Icons.Bybit_white />
                  </AddApiKeySwichIcon>
                </AddApiKeySwithItem> */}

                {/* <AddApiKeySwithItem
                  selected={exchangeId === 3}
                  onClick={() => handleChangeExchange(3)}
                >
                  <AddApiKeySwichIcon>
                    <Icons.Bybit_white />
                  </AddApiKeySwichIcon>
                </AddApiKeySwithItem> */}

                {/* <AddApiKeySwithItem
                  selected={exchangeId === 4}
                  onClick={() => handleChangeExchange(4)}
                >
                  <AddApiKeySwichIcon>
                    <Icons.Ig_white />
                  </AddApiKeySwichIcon>
                </AddApiKeySwithItem> */}
              </AddApiKeySwitchWrapper>
            )}
          </>
        )}

        <AddApiKeySubTitle>
          <Lang datakey="input_apikey" defaultText="Input API Key" />
          <AddApiKeyTooltipWrapper>
            <AddApiKeyTooltipContainer onClick={handleUrl}>
              <Icons.Tooltip />
            </AddApiKeyTooltipContainer>
            <AddApiKeyTooltipTextUrl>
              點我查看如何新增API Key
            </AddApiKeyTooltipTextUrl>
          </AddApiKeyTooltipWrapper>
        </AddApiKeySubTitle>
        <AddApiKeyInput
          value={newApikey}
          onChange={(e) => setNewApikey(e.target.value)}
        />
        {exchangeId !== 4 && (
          <AddApiKeyInputWrapper>
            <AddApiKeySubTitle>
              <Lang datakey="input_secretkey" defaultText="Input Secret Key" />
            </AddApiKeySubTitle>
            <AddApiKeyInput
              value={newSecretkey}
              onChange={(e) => setNewSecretkey(e.target.value)}
            />
          </AddApiKeyInputWrapper>
        )}

        {/* IG需要Account和Password */}
        {exchangeId === 4 && (
          <AddApiKeyInputWrapper>
            <AddApiKeySubTitle>
              <Lang datakey="input_account" defaultText="Input Account" />
            </AddApiKeySubTitle>
            <AddApiKeyInput
              value={newAccount}
              onChange={(e) => setNewAccount(e.target.value)}
            />

            <AddApiKeySubTitle>
              <Lang datakey="input_password" defaultText="Input Password" />
            </AddApiKeySubTitle>
            <AddApiKeyInput
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </AddApiKeyInputWrapper>
        )}

        <AddApiKeyButtonWrapper>
          {exchangeId !== 4 && (
            <AuthWrapper onClick={() => setImgModalIsOpen(true)}>
              <Lang datakey="apikey_auth" defaultText="ApiKey Auth Setting" />
            </AuthWrapper>
          )}
          <AddApiKeyButtonContainer>
            <AddApiKeyCancelButton onClick={handleClose}>
              <AddApiKeyButtonText>
                <Lang datakey="cancel" defaultText="Cancel" />
              </AddApiKeyButtonText>
            </AddApiKeyCancelButton>
            <AddApiKeyVerifiyButton
              disabled={exchangeId !== 4 ? !isValid : !igIsValid}
              onClick={handleAddApikey}
            >
              <AddApiKeyButtonText>
                <Lang datakey="verify" defaultText="Verify" />
              </AddApiKeyButtonText>
            </AddApiKeyVerifiyButton>
          </AddApiKeyButtonContainer>
        </AddApiKeyButtonWrapper>
      </AddApiKeyContainer>
    </AddApiKeyModalWrapper>
  );
};

const StepTwo = ({
  exchangeId,
  setExchangeId,
  algo,
  airobots,
  setSelectApiKeyIsAvailable,
  setActiveStep,
  target,
}) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const tutorial = query.get("tutorial");
    if (tutorial === "algo") {
      setJoyrideIsRun(true);
    }
  }, [search]);

  const handleJoyrideCallback = () => {
    setActiveStep(2);
  };
  const [joyrideIsRun, setJoyrideIsRun] = useState(false);
  const joyrideState = [
    {
      target: ".createAlgo_addApiKey",
      content: "Please enter your API Key and Secret Key here to bind.",
      placement: "right-start",
      disableBeacon: true,
      disableOverlayClose: true,
    },
    {
      target: ".createAlgo_addApiKey",
      content:
        "For API Key permission settings, please click to refer to ApiKey Auth Setting.",
      placement: "right-start",
      disableBeacon: true,
      disableOverlayClose: true,
    },
    {
      target: ".createAlgo_addApiKey",
      content:
        "If you do not know how to create an API Key, please click the link below for a creation tutorial",
      placement: "right-start",
      disableBeacon: true,
      disableOverlayClose: true,
      callbackAction: true,
      function: handleJoyrideCallback,
      haveLink: true,
      linkName: "API Key Create and Settings",
      link: "apikeyguide/guide",
    },
  ];

  const dispatch = useDispatch();
  const [_addApikey, { data, error, isError, isSuccess }] =
    useSetAccountApikeyMutation();

  const [userApikeys, setUserApiKeys] = useState([]);

  const [imgModalIsOpen, setImgModalIsOpen] = useState(false);

  const [selectableExchange, setSelectableExchange] = useState([]);

  // 取得可使用的幣種和交易所
  const [
    _getTargetExchangeIds,
    {
      data: targetExchangeIds,
      isSuccess: targetExchangeIdsIsSuccess,
      isError: targetExchangeIdsIsError,
      error: targetExchangeIdsyError,
      refetch: targetExchangeIdsRefetch,
    },
  ] = useGetTargetExchangeIdsMutation();

  useEffect(() => {
    _getTargetExchangeIds({
      body: {
        TargetKey: target,
      },
    });
  }, [target]);

  useEffect(() => {
    if (targetExchangeIdsIsSuccess) {
      setSelectableExchange(targetExchangeIds.ExchangeIds);
    }
  }, [targetExchangeIds, targetExchangeIdsIsError]);

  // 取得apikey;
  const {
    data: accountApikey,
    isSuccess: accountApikeyIsSuccess,
    isError: accountApikeyIsError,
    error: accountApikeyError,
    isLoading: accountApikeyIsLoading,
    refetch,
  } = useGetAccountApikeyQuery();

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (accountApikeyIsSuccess) {
      dispatch(addAccountApikey(accountApikey));
      setUserApiKeys(accountApikey?.Apikeys);

      // 確認有沒有設定過apikey
      // if (accountApikey?.Apikeys?.length > 0) {
      //   // 預設為第一個
      //   if (exchangeId === null) {
      //     // 預設交易所
      //     setExchangeId(accountApikey?.Apikeys[0]?.ExchangeId);
      //     // 顯示apiKey
      //     setCurrentApiKey(accountApikey?.Apikeys[0]?.ApiKey);
      //     // 顯示錯誤訊息
      //     setAbnormal(
      //       accountApikey?.Apikeys[0]?.Abnormal?.toLowerCase() === "none"
      //         ? ""
      //         : accountApikey?.Apikeys[0]?.Abnormal
      //     );
      //     // 檢查apiKey是否可用
      //     if (accountApikey?.Apikeys[0]?.Abnormal?.toLowerCase() === "none") {
      //       setSelectApiKeyIsAvailable(true);
      //     }
      //   } else {
      //     // 更新apikey時
      //     const updateApiKey = accountApikey?.Apikeys.filter(
      //       ({ ExchangeId }) => ExchangeId === exchangeId
      //     );
      //     setExchangeId(updateApiKey[0]?.ExchangeId);
      //     setCurrentApiKey(updateApiKey[0]?.ApiKey);
      //     setAbnormal(
      //       updateApiKey[0]?.Abnormal?.toLowerCase() === "none"
      //         ? ""
      //         : updateApiKey[0]?.Abnormal
      //     );
      //     if (updateApiKey[0]?.Abnormal?.toLowerCase() === "none") {
      //       setSelectApiKeyIsAvailable(true);
      //     }
      //   }
      //   // 顯示修改按鈕
      //   setShowEditButton(true);
      // } else {
      //   // 打開新增modal
      //   setModalType("add");
      //   setIsOpen(true);
      //   // 預設交易所選擇幣安 ===> 1
      //   // 預設交易所選擇Bybit ===> 2
      //   setExchangeId(2);
      // }
    }

    if (accountApikeyError) {
      console.log(accountApikeyError.data.Message);
    }
  }, [accountApikey, accountApikeyIsError]);

  // useEffect(() => {
  //   refetch();
  // }, []);

  const exchanges = useSelector(selectSystem).exchanges;

  const [isOpen, setIsOpen] = useState(false);
  const [allExchanges, setAllExchanges] = useState([]);
  const [abnormal, setAbnormal] = useState("");
  const [modalType, setModalType] = useState("add");

  const [currentApiKey, setCurrentApiKey] = useState("");
  const [showEditButton, setShowEditButton] = useState(false);

  const exchangeImg = [
    {
      ExchangeId: 1,
      img: Binance,
    },
    {
      ExchangeId: 2,
      img: Bybit,
    },
    {
      ExchangeId: 3,
      img: Bybit,
    },
    {
      ExchangeId: 4,
      img: Ig,
    },
  ];

  // 取得所有交易所
  useEffect(() => {
    // 過濾該Target可選擇的交易所
    setAllExchanges(
      exchanges
        .filter((data) => selectableExchange?.indexOf(data.ExchangeId) > -1)
        .map((data, index) => {
          return {
            ...data,
            img: exchangeImg.filter(
              (img) => img.ExchangeId === data.ExchangeId
            )[0]?.img,
          };
        })
    );

    // if (accountApikey?.Apikeys?.length > 0) {
    //   // 預設交易所
    //   setExchangeId(
    //     accountApikey?.Apikeys.filter(
    //       (data) => selectableExchange?.indexOf(data.ExchangeId) > -1
    //     )[0]?.ExchangeId
    //   );
    //   // 顯示apiKey
    //   setCurrentApiKey(
    //     accountApikey?.Apikeys.filter(
    //       (data) => selectableExchange?.indexOf(data.ExchangeId) > -1
    //     )[0]?.ApiKey
    //   );
    //   // 顯示錯誤訊息
    //   setAbnormal(
    //     accountApikey?.Apikeys.filter(
    //       (data) => selectableExchange?.indexOf(data.ExchangeId) > -1
    //     )[0]?.Abnormal?.toLowerCase() === "none"
    //       ? ""
    //       : accountApikey?.Apikeys.filter(
    //           (data) => selectableExchange?.indexOf(data.ExchangeId) > -1
    //         )[0]?.Abnormal
    //   );
    //   // 檢查apiKey是否可用
    //   if (
    //     accountApikey?.Apikeys.filter(
    //       (data) => selectableExchange?.indexOf(data.ExchangeId) > -1
    //     )[0]?.Abnormal?.toLowerCase() === "none"
    //   ) {
    //     setSelectApiKeyIsAvailable(true);
    //   }
    // }
  }, [exchanges, selectableExchange]);

  useEffect(() => {
    // 確認有沒有設定過apikey
    // 確定拿到可選擇的交易所資料後再執行
    if (allExchanges.length > 0) {
      if (userApikeys.length > 0) {
        setExchangeId(
          userApikeys.filter(
            (data) => selectableExchange?.indexOf(data.ExchangeId) > -1
          )[0]?.ExchangeId
        );
        // 顯示apiKey
        setCurrentApiKey(
          userApikeys.filter(
            (data) => selectableExchange?.indexOf(data.ExchangeId) > -1
          )[0]?.ApiKey
        );

        // 顯示錯誤訊息
        setAbnormal(
          userApikeys
            .filter(
              (data) => selectableExchange?.indexOf(data.ExchangeId) > -1
            )[0]
            ?.Abnormal?.toLowerCase() === "none"
            ? ""
            : userApikeys.filter(
                (data) => selectableExchange?.indexOf(data.ExchangeId) > -1
              )[0]?.Abnormal
        );

        // 檢查apiKey是否可用
        if (
          userApikeys
            .filter(
              (data) => selectableExchange?.indexOf(data.ExchangeId) > -1
            )[0]
            ?.Abnormal?.toLowerCase() === "none"
        ) {
          setSelectApiKeyIsAvailable(true);
        }
        // 顯示修改按鈕
        setShowEditButton(true);
      } else {
        // 打開新增modal
        setModalType("add");
        setIsOpen(true);
        // 如果可選擇的交易所有Bybit的話 預設為Bybit
        if (allExchanges.filter((data) => data.ExchangeId === 2).length > 0) {
          console.log("有bybit");
          setExchangeId(2);
        } else {
          setExchangeId(allExchanges[0]?.ExchangeId);
        }
      }
    }
  }, [allExchanges, userApikeys]);

  // 確認有沒有設定過apikey
  // useEffect(() => {
  //   console.log(userApikeys);
  //   if (userApikeys?.length > 0) {
  //     // 預設為第一個
  //     if (exchangeId === null) {
  //       // 預設交易所
  //       setExchangeId(userApikeys[0]?.ExchangeId);
  //       // 顯示apiKey
  //       setCurrentApiKey(userApikeys[0]?.ApiKey);
  //       // 顯示錯誤訊息
  //       setAbnormal(
  //         userApikeys[0]?.Abnormal?.toLowerCase() === "none"
  //           ? ""
  //           : userApikeys[0]?.Abnormal
  //       );
  //       // 檢查apiKey是否可用
  //       if (userApikeys[0]?.Abnormal?.toLowerCase() === "none") {
  //         setSelectApiKeyIsAvailable(true);
  //       }
  //     } else {
  //       // 更新apikey時
  //       const updateApiKey = userApikeys.filter(
  //         ({ ExchangeId }) => ExchangeId === exchangeId
  //       );
  //       setExchangeId(updateApiKey[0]?.ExchangeId);
  //       setCurrentApiKey(updateApiKey[0]?.ApiKey);
  //       setAbnormal(
  //         updateApiKey[0]?.Abnormal?.toLowerCase() === "none"
  //           ? ""
  //           : updateApiKey[0]?.Abnormal
  //       );
  //       if (updateApiKey[0]?.Abnormal?.toLowerCase() === "none") {
  //         setSelectApiKeyIsAvailable(true);
  //       }
  //     }
  //     // 顯示修改按鈕
  //     setShowEditButton(true);
  //   } else {
  //     // 打開新增modal
  //     setModalType("add");
  //     setIsOpen(true);
  //     // 預設交易所選擇幣安
  //     setExchangeId(1);
  //   }
  // }, [userApikeys]);

  // // 切換交易所
  // useEffect(() => {
  //   // 先確認existApiKey
  //   const selectIsExist = existApiKey.filter(
  //     ({ ExchangeId }) => ExchangeId === exchangeId
  //   );
  //   if (
  //     selectIsExist.length > 0 &&
  //     selectIsExist[0]?.ExchangeId === exchangeId
  //   ) {
  //     // 顯示apiKey
  //     setCurrentApiKey(selectIsExist[0]?.ApiKey);
  //     // 顯示錯誤訊息
  //     setAbnormal(
  //       selectIsExist[0]?.Abnormal === "None" ? "" : selectIsExist[0]?.Abnormal
  //     );

  //     // if (abnormal === "") {
  //     // }

  //     // console.log(abnormal);
  //   } else {
  //     console.log(123);
  //     // 打開新增modal
  //     setModalType("add");
  //     setCurrentApiKey("");
  //   }
  // }, [exchangeId]);

  // useEffect(() => {
  //   if (!exchanges || exchanges?.length !== exchangeImg.length) return;
  //   setAllExchanges(
  //     exchanges.map((data, index) => ({ ...data, Img: exchangeImg[index] }))
  //   );
  // }, [exchanges]);

  // useEffect(() => {
  //   if (!apikeys || apikeys?.length === 0) {
  //     setExchangeId(1);
  //     return setIsOpen(true);
  //   }
  //   setExchangeId(apikeys[0].ExchangeId);
  // }, [apikeys]);

  // useEffect(() => {
  //   if (!exchangeId || apikeys?.length === 0) return;
  //   const apikey = apikeys.filter(({ ExchangeId }) => ExchangeId == exchangeId);
  //   if (apikey.length === 0) {
  //     setSelectApikey(null);
  //     setIsOpen(true);
  //     setText("");
  //     setAbnormal("");
  //     return;
  //   }

  //   setSelectApikey(apikey[0]);
  //   setText(apikey[0].ApiKey);
  //   setAbnormal(apikey[0].Abnormal);
  // }, [exchangeId]);

  // MySwal.fire({
  //   icon: "success",
  //   title: "新增成功",
  //   showConfirmButton: false,
  // });

  useEffect(() => {
    if (isSuccess) {
      // 多國語言
      const title = LangString("alert_update_success", "Update successful");
      const confirmText = LangString("confirm", "Confirm");

      SuccessMySwal({
        title: title,
        showCancelButton: false,
        confirmButtonText: confirmText,
      });
    }
    if (isError) {
      // ResultCode 2184 為 bybit 是 UTA 帳戶錯誤訊息
      FooterMySwal({
        title:
          error?.data?.ResultLanguages[getLanguage()] || error?.data?.Message,
        confirmButtonText: "確定",
        footerText:
          error?.data?.ResultCode === 2184
            ? "Click me to see the tutorial"
            : "Click me to see Auth setting",
        footerFun:
          error?.data?.ResultCode === 2184
            ? handleBybitUtaTutorial
            : handleImgModal,
      });
    }
  }, [data, isError]);

  const handleImgModal = () => {
    setImgModalIsOpen(true);
  };

  const handleBybitUtaTutorial = () => {
    navigate("/v2/apikeyguide/bybituta");
  };

  const handleChangeExchange = (id) => {
    // 切換交易所
    setExchangeId(id);

    // 先確認選擇的交易所有沒有ApiKey
    const selectIsExist = userApikeys.filter(
      ({ ExchangeId }) => ExchangeId === id
    );
    if (selectIsExist.length > 0) {
      // 顯示apiKey
      setCurrentApiKey(selectIsExist[0]?.ApiKey);
      // 確認錯誤訊息 和 改變apiIsAvailable狀態
      if (selectIsExist[0]?.Abnormal?.toLowerCase() === "none") {
        setAbnormal("");
        setSelectApiKeyIsAvailable(true);
      } else {
        setAbnormal(selectIsExist[0]?.Abnormal);
        setSelectApiKeyIsAvailable(false);
      }
      // 顯示修改按鈕
      setShowEditButton(true);
    } else {
      // 打開新增modal
      setModalType("add");
      setIsOpen(true);
      // 清空apikey顯示欄位
      setCurrentApiKey("");
      // 改變apiIsAvailable狀態
      setSelectApiKeyIsAvailable(false);
      // 清空錯誤訊息
      setAbnormal("");
      // 隱藏修改按鈕
      setShowEditButton(false);
    }
  };

  const handleEditModal = (id) => {
    // 打開編輯modal
    setModalType("edit");
    setIsOpen(true);
  };

  // const handleChangeExchange = (id) => {
  //   console.log(apikeys);
  //   // 尚未設定任何 apikey
  //   if (!apikeys || apikeys?.length === 0) {
  //     setExchangeId(id);
  //     setModalType("add");
  //     return setIsOpen(true);
  //   }

  //   // 選定的 id 尚未設定 apikey
  //   const apikey = apikeys?.filter(({ ExchangeId }) => ExchangeId === id);
  //   if (apikey?.length === 0) {
  //     setExchangeId(id);
  //     setModalType("add");
  //     return setIsOpen(true);
  //   }
  //   console.log(apikeys);

  //   setExchangeId(id);
  // };

  return (
    <Wrapper>
      {allExchanges.length == 0 && <Loading />}
      <JoyrideComponent
        joyrideState={joyrideState}
        run={joyrideIsRun}
        joyrideType={"algo"}
      />
      <Title>
        <Lang
          datakey="addalgo_select_exchange_title"
          defaultText="Set Your AlgoTrade"
        />{" "}
        -{" "}
        <Lang
          defaultText=""
          datakey={airobots.filter(({ Id }) => Id === algo)[0]?.NameLanguageKey}
        />
      </Title>
      <SubTitle>
        <Lang datakey="addalgo_select_exchange" defaultText="Select Exchange" />
      </SubTitle>
      <ExchangeCardWrapper exchange={allExchanges.length}>
        {allExchanges?.map((data) => (
          <ExchangeCard
            key={data.ExchangeId}
            selected={exchangeId === data.ExchangeId}
            onClick={() => handleChangeExchange(data.ExchangeId)}
          >
            <ExchangeIcon>
              <img src={data.img} />
            </ExchangeIcon>
          </ExchangeCard>
        ))}
      </ExchangeCardWrapper>

      <SubTitle>API Key</SubTitle>
      {/* <ApiKeyWrapper error={selectApikey !== null}> */}
      <ApiKeyWrapper>
        <ApiKeyText>{currentApiKey}</ApiKeyText>
        {showEditButton && (
          <ApiKeyEditWrapper onClick={() => handleEditModal(exchangeId)}>
            <Icons.Edit />
          </ApiKeyEditWrapper>
        )}
      </ApiKeyWrapper>
      {abnormal !== "" && <Error>{abnormal}</Error>}

      {isOpen && (
        <AddApiKeyModal
          setIsOpen={setIsOpen}
          setExchangeId={setExchangeId}
          exchangeId={exchangeId}
          _addApikey={_addApikey}
          setCurrentApiKey={setCurrentApiKey}
          modalType={modalType}
          apikeys={userApikeys}
          setImgModalIsOpen={setImgModalIsOpen}
          selectableExchange={selectableExchange}
        />
      )}

      {imgModalIsOpen && (
        <ApiKeyPermissionModal
          exchange={exchangeId}
          // imgModalIsOpen={imgModalIsOpen}
          setImgModalIsOpen={setImgModalIsOpen}
        />
      )}
    </Wrapper>
  );
};

export default StepTwo;
