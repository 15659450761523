import styled from 'styled-components';

export const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const Title = styled.h3`
    color: #1976d2;
    padding: 1rem 0;
`;

export const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const TitleWrapper = styled.div`
    display: flex;
    align-items: center;

    *:last-child {
        padding-left: 1rem;
    }
`;

export const ShowAllContainer = styled.div`
    display: flex;
    align-items: center;

    * {
        cursor: pointer;

        &:last-child {
            padding-left: .5rem;
        }
    }
`;

export const TableContainer = styled.div`
    flex: 1;
    overflow-y: auto;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
`;

export const TheadSearchContainer = styled.div`
    display: flex;
    align-items: center;

    svg {
        font-size: 1.2rem;
        color: #ccca;
    }
`;

export const CollapseContentContainer = styled.div`
    padding: 0 2.5rem;
`;

export const CollaspeContentTitle = styled.div`
    font-weight: bold;
    font-size: 1.2rem;
    color: #1976d2;
    padding-top: .5rem;
`;

export const CollapseContentWrapper = styled.div`
    max-height: 350px;
    overflow: auto;
    padding-bottom: 2rem;
`;

export const EmptyContainer = styled.div`
    text-align: center;
    padding: 1rem 0;
    color: #ccc;
    font-weight: bold;
    text-transform: uppercase;
`;

export const UpdateInfoContainer = styled.div`
    height: 100%;
    max-height: 80vh;
    padding: 2rem 3rem 0;
`;

export const UpdateInfoWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const UpdateInfoTitleContainer = styled.div`
    h3 {
        color: #1976d2;
        padding-top: 1rem;
    }
`;

export const UpdateInfoTitleWrapper = styled.div`
    padding: 1rem 0;

    > *:last-child {
        padding-left: 1rem;
    }
`;

export const UpdateInfoText = styled.span`
    font-size: 1.2rem;
    color: #1976d2;
    font-weight: bold;
`;

export const UpdateInfoTableContainer = styled.div`
    position: relative;
    overflow-y: auto;
    flex: 1;
    padding: 0 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;

    thead {
        position: sticky;
        top: 0;
        backdrop-filter: blur(5px);
        background: #fffc;
    }

    tr {
        border-bottom: 1px solid #ccc;
    }

    th, td {
        padding: .8rem .5rem;
        font-size: .8rem;
    }
`;