import { useEffect, useState } from "react";

// --- styled
import * as Styled from "./Styled";
import UseSwal from "../../hooks/Lang"; // swal confirm button background color

// --- components
import Loading from "../../../components/Global/Loading";
import UpdateInfo from "./UpdateInfo";

// --- plugins
import Swal from "sweetalert2/dist/sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

// --- api
import { API_InvestAHFTBackend } from "../../../utils/api";
import ListReturnGroupRow from "./ListReturnGroupRow";

const columns = [
  {
    field: "Asset",
    label: "Asset",
    minWidth: 100,
    align: "center",
  },
  {
    field: "UserAddress",
    label: "UserAddress",
    minWidth: 400,
    align: "left",
  },
  {
    field: "TotalNeedReturnAmount",
    label: "TotalNeedReturnAmount",
    minWidth: 200,
    align: "left",
  },
];

const InvestAhft = () => {
  // --- api
  const getInvestAhft = async () => {
    setLoading(true);
    const res = await API_InvestAHFTBackend();

    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return UseSwal(res.Message);

    setListReturnGroup(res.ListReturnGroup);
    setData(
      res.ListReturnGroup?.filter(
        ({ ListReturn }) =>
          ListReturn?.filter(({ IsReturn }) => IsReturn === false).length > 0
      )?.map((item, index) => {
        return {
          ...item,
          id: index,
        };
      })
    );
    setUpdateGroup([]);
    setLoading(false);
  };
  const updateInvestAhft = async () => {
    setLoading(true);

    const res = await API_InvestAHFTBackend(updateGroup);
    setLoading(false);

    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return UseSwal(res.Message);

    UseSwal({
      title: "success",
      icon: "success",
    });

    // refetch
    getInvestAhft();
  };

  // --- check
  const isConfirmUpdate = () => {
    if (updateGroup?.length === 0) return UseSwal("請先選取欲返還項目");
    // console.log(updateGroup);

    const swal = withReactContent(Swal);

    swal
      .mixin({
        customClass: {
          popup: "w-auto",
          htmlContainer: "m-0",
          confirmButton: "system",
        },
      })
      .fire({
        showCancelButton: true,
        html: <UpdateInfo data={updateGroup} />,
      })
      .then((result) => {
        if (result.isConfirmed) {
          updateInvestAhft();
        }
      });
  };

  // --- checkbox controll
  const handleAddAll = (e) => {
    e.stopPropagation();
    handleSetUpdateGroup(!isChecked, "", "", "all");
    setIsChecked(!isChecked);
  };

  // --- update data
  const handleSetUpdateGroup = (isNew, AccountID, UserAddress, newItem) => {
    if (isNew) {
      // - add
      // all
      if (newItem === "all") {
        const newUpdateGroup = [];
        data.map((item) => {
          item.ListReturn.filter(({ IsReturn }) => IsReturn === false).map(
            (data) =>
              newUpdateGroup.push({
                AccountID: item.AccountID,
                UserAddress: item.UserAddress,
                ...data,
              })
          );
        });

        return setUpdateGroup(newUpdateGroup);
      }

      // group
      if (newItem === "group") {
        // remove
        let newUpdateGroup = updateGroup.filter(
          (item) =>
            !(item.AccountID === AccountID && item.UserAddress === UserAddress)
        );

        data
          .filter(
            (item) =>
              item.AccountID === AccountID && item.UserAddress === UserAddress
          )[0]
          .ListReturn.filter(({ IsReturn }) => IsReturn === false)
          .map((item) => {
            newUpdateGroup = [
              ...newUpdateGroup,
              {
                AccountID,
                UserAddress,
                ...item,
              },
            ];
          });

        return setUpdateGroup(newUpdateGroup);
      }

      // single
      return setUpdateGroup([
        ...updateGroup,
        {
          AccountID,
          UserAddress,
          ...newItem,
        },
      ]);
    }

    // - remove
    // all
    if (newItem === "all") {
      return setUpdateGroup([]);
    }

    // group
    if (newItem === "group") {
      return setUpdateGroup(
        updateGroup.filter(
          (item) =>
            !(item.AccountID === AccountID && item.UserAddress === UserAddress)
        )
      );
    }

    // single
    return setUpdateGroup(
      updateGroup.filter(
        (item) =>
          !(
            item.AccountID === AccountID &&
            item.UserAddress === UserAddress &&
            item.Periods === newItem.Periods &&
            item.AhftGroupID === newItem.AhftGroupID
          )
      )
    );
  };

  const [loading, setLoading] = useState(true);
  const [listReturnGroup, setListReturnGroup] = useState(null);
  const [data, setData] = useState(null); // IsReturn == false
  const [updateGroup, setUpdateGroup] = useState([]);
  const [onlyShowNotReturn, setOnlyShowNotReturn] = useState(true);
  const [isChecked, setIsChecked] = useState(false); // check all
  const [searchAccountId, setSearchAccountId] = useState("");

  useEffect(() => {
    if (data === null) return setData("get");
  }, []);

  useEffect(() => {
    if (data === null) return;
    if (data === "get") getInvestAhft();
  }, [data]);

  useEffect(() => {
    if (updateGroup?.length === 0) return setIsChecked(false);
    let totalLength = 0;
    data?.map(({ ListReturn }) =>
      ListReturn.map(({ IsReturn }) => !IsReturn && totalLength++)
    );
    setIsChecked(totalLength === updateGroup.length);
  }, [updateGroup]);

  if (loading) return <Loading />;
  return (
    <Styled.Container>
      {/* header: title */}
      <Styled.TitleContainer>
        <Styled.TitleWrapper>
          <Styled.Title>取得返還群組</Styled.Title>
          <Styled.ShowAllContainer>
            <input
              type="checkbox"
              id="showAll"
              checked={onlyShowNotReturn}
              onChange={() => setOnlyShowNotReturn(!onlyShowNotReturn)}
            />
            <label htmlFor="showAll">僅顯示未返還</label>
          </Styled.ShowAllContainer>
        </Styled.TitleWrapper>
        <div>
          <Button variant="contained" onClick={isConfirmUpdate}>
            Return
          </Button>
        </div>
      </Styled.TitleContainer>

      {/* content: table */}
      <Styled.TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead
            sx={{ "&": { cursor: "default", zIndex: 2, position: "relative" } }}
          >
            <TableRow>
              {/* checkbox */}
              <TableCell onClick={handleAddAll}>
                <input type="checkbox" checked={isChecked} readOnly />
              </TableCell>

              {/* account id */}
              <TableCell
                sx={{
                  "&:hover .MuiFormLabel-root": {
                    borderBottom: "1px solid #1976d2",
                  },
                  ".MuiFormLabel-root": { transition: "100ms" },
                }}
                align="left"
              >
                <Styled.TheadSearchContainer>
                  <TextField
                    sx={{
                      ".MuiInputBase-root *": { border: "none" },
                    }}
                    label="AccountID"
                    value={searchAccountId}
                    onChange={(e) => setSearchAccountId(e.target.value)}
                  />
                </Styled.TheadSearchContainer>
              </TableCell>

              {/* others */}
              {columns?.map((column) => (
                <TableCell
                  key={column.field}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}

              {/* actions field */}
              <TableCell key="toggle"></TableCell>
            </TableRow>
          </TableHead>

          <TableBody sx={{ "&": { zIndex: 1, position: "relative" } }}>
            {(onlyShowNotReturn ? data : listReturnGroup)
              ?.filter(
                ({ AccountID }) =>
                  !searchAccountId ||
                  AccountID.toLowerCase().includes(
                    searchAccountId.toLowerCase()
                  )
              )
              ?.map((row, index) => {
                return (
                  <ListReturnGroupRow
                    key={`${row.AccountID}_${row.UserAddress}`}
                    row={row}
                    updateGroup={updateGroup}
                    handleSetUpdateGroup={handleSetUpdateGroup}
                    onlyShowNotReturn={onlyShowNotReturn}
                  />
                );
              })}
          </TableBody>
        </Table>
      </Styled.TableContainer>
    </Styled.Container>
  );
};

export default InvestAhft;
