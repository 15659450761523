import { useEffect, useState } from 'react';
import AddAi from './AddAi'
import Loading from '../Global/Loading';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { API_Exchange } from '../../utils/api';

const AddAlgo = () => {
  const handleGetExchange = async () => {
    const res = await API_Exchange({ mode: 'Query' });
    setLoading(false);
    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return Swal.fire(res.Message);
    setData(res);
  };
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (data === null) setData('get');
  }, []);
  useEffect(() => {
    if (data === 'get') handleGetExchange();
  }, [data]);

  if (loading) return <Loading />
  return (
    <div className='w-limit'>
      <h3 className='w-full mb-8 text-center text-[2rem]'>Add Algo</h3>
      <AddAi data={data} />
    </div>
  )
}

export default AddAlgo
