import { useEffect } from 'react';
import { UDFCompatibleDatafeed } from './UDF/udf-compatible-datafeed';
import { widget } from '../../charting_library';
import { myStudy_first } from './Studies/Study_Test';

const createWidget = () => {
  const datafeedUrl = "https://demo-feed-data.tradingview.com";
  const datafeed = new UDFCompatibleDatafeed(datafeedUrl);
  new widget({
    symbol: 'AAPL',
    datafeed,
    library_path: '/charting_library/',
    container: 'tvContainer',
    autosize: true,
    studies_access: {
      type: 'white',
      tools: [
        {
          name: 'myStudy_first',
          grayed: false
        },
      ]
    },
    custom_indicators_getter: function(PineJS) {
      return Promise.resolve([
        myStudy_first('myStudy_first', 'Candle Study'),
      ]);
    },
  });
};

const PackageChart = () => {
  useEffect(() => {
    createWidget();
  }, []);	

  return (
    <div
      id='tvContainer'
      className='w-full h-full'
    />
  )
}

export default PackageChart
