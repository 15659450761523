import styled, { css } from "styled-components";

import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";
import { useState, useEffect } from "react";

import Completed from "./components/Completed";
import InProgress from "./components/InProgress";

import Icons from "../../global/icons";
import { handleColorType } from "../../utils/ButtonStyle";

import { useGetAccountQuestsQuery } from "../../services/questApi";
import Lang from "../../global/Lang";
import Loading from "../../components/Loading/Loading";

const Wrapper = styled.div`
  height: 100%;
  padding-bottom: 100px;
  color: #fcfcfc;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 20px;
`;

const Title = styled.div`
  font-size: min(max(1.25vw, 22px), 24px);
  color: #fcfcfc;
  padding-right: 10px;
  text-transform: capitalize;

  ${RWD_MD(css`
    font-size: min(max(1.145vw, 20px), 22px);
  `)};
`;

const TabWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const TabContainer = styled.div`
  display: flex;
  width: auto;
  ${RWD_SM(css`
    width: 100%;
  `)};
`;

const Tab = styled.div`
  padding: 0.78125vw 1.3vw;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 20px;
  /* margin-right: 20px; */
  color: #fcfcfc;
  cursor: pointer;
  border-radius: 8px;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  border: ${(props) =>
    props.activeTab
      ? "1px solid #0AECD1"
      : "1px solid rgba(255, 255, 255, 0.15)"};
  font-size: min(max(0.833vw, 14px), 16px);

  &:last-child {
    margin-right: 0px;
  }

  ${RWD_SM(css`
    justify-content: center;
    padding: 10px;
  `)};
`;

const TabContent = styled.div`
  font-size: min(max(0.833vw, 14px), 16px);
  padding-left: 10px;
`;

const TabIcon = styled.div`
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 30px;
    height: 30px;
  }

  ${RWD_SM(css`
    width: 25px;
    height: 25px;

    svg {
      width: 25px;
      height: 25px;
    }
  `)};
`;

const QuestWrapper = styled.div`
  display: flex;
  flex: 1;
`;

const QuestContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;
`;

const QusetCard = styled.div`
  padding: 10px 20px;
  border: 1px solid;
  border-radius: 8px;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  border-color: rgba(255, 255, 255, 0.15);
`;

const QuestCardTitleWrapper = styled.div``;

const QuestCardTitle = styled.div`
  font-size: min(max(1.302vw, 20px), 25px);
  font-weight: bold;
`;

const QusetCardInfoIcon = styled.div``;

const QuesetCardContent = styled.div`
  color: #54587b;
`;
const QuestCardReward = styled.div`
  display: flex;
  align-items: baseline;
`;

const QuestCardRewardPoint = styled.div`
  font-size: min(max(1.875vw, 34px), 36px);
  font-family: Montserrat;
  font-weight: bold;
  color: #0aecd1;
`;

const QuestButton = styled.button`
  border-radius: 60px;
  width: min(max(7.0833vw, 100px), 135px);
  background: ${({ type }) => handleColorType(type).bg};
  text-align: center;
`;

const QuestButtonText = styled.span`
  font-size: min(max(1.0416vw, 16px), 20px);
  color: #fcfcfc;
  display: inline-flex;
  padding: 0.52vw 1.3vw;
  ${RWD_LG(css`
    padding: 8px 18px;
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const QuestHub = () => {
  // Status
  // Waiting 待完成，顯示 GO
  // Finished 已完成，顯示 Claim
  // Claimed 已收取獎勵，移至 Complete 頁籤
  // Expired 已過期（預留）
  // Disabled 不適用（預留)
  const { data, isSuccess, isError, error, isLoading, refetch } =
    useGetAccountQuestsQuery();

  const [activeTab, setActiveTab] = useState(1);
  const [questData, setQuestData] = useState();
  const [completedData, setCompletedData] = useState([]);
  const [waitingData, setWaitingData] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      setQuestData(data?.Quests);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    setCompletedData(questData?.filter((data) => data.Status === "Claimed"));
    setWaitingData(
      questData?.filter(
        (data) => data.Status === "Waiting" || data.Status === "Finished"
      )
    );
  }, [questData]);

  const tabList = [
    {
      id: 1,
      name: <Lang datakey="quest_inprogress" defaultText="In Progress" />,
      icon: <Icons.Questhub_InProgress />,
    },
    {
      id: 2,
      name: <Lang datakey="quest_completed" defaultText="Completed" />,
      icon: <Icons.Questhub_Completed />,
    },
  ];

  return (
    <Wrapper>
      {isLoading && <Loading />}
      <Container>
        <TitleWrapper>
          <Title>QuestHub</Title>
        </TitleWrapper>
        <TabWrapper>
          <TabContainer>
            {tabList.map((data) => (
              <Tab
                onClick={() => setActiveTab(data.id)}
                key={data.id}
                activeTab={data.id === activeTab}
              >
                <TabIcon>{data.icon}</TabIcon>
                <TabContent>{data.name}</TabContent>
              </Tab>
            ))}
          </TabContainer>
        </TabWrapper>

        <QuestWrapper>
          {activeTab === 1 && waitingData && (
            <InProgress questData={waitingData} />
          )}
          {activeTab === 2 && completedData && (
            <Completed completedData={completedData} />
          )}
        </QuestWrapper>
      </Container>
    </Wrapper>
  );
};

export default QuestHub;
