import { useEffect, useState } from 'react';

// --- components ---
import PolicyCheck from './PolicyCheck';
import Alert from '../Global/Alert';
import Loading from '../Global/Loading';

// --- plugins ---
// icons
import { IoAlertCircleOutline } from 'react-icons/io5';
import { MdOutlineArrowForwardIos, MdOutlineArrowBackIos } from 'react-icons/md';
// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

const Package = ({
  currentStep,
  totalStep,
  transferPkg,
  investPackage,
  setValid,
  projectInformation,
  setProjectInfomation,
  className
}) => {
  // --- const ---
  const ASSET_COLORS = [
    '#55CCFF',
    '#E77373',
    '#7AB981',
    '#FFB951',
  ];
  const POLICYS = [
    'I understand when my fund has been credited, it will be instantly converted to the chosen currency with the system exchange rate. By the end of the expiration period, the balance will be converted to the original currency and return. I am fully aware of the potential risks involved, and willing to proceed the operation.'
  ];

  // --- handler ---
  const getConicGradient = (content) => {
    // content: BTC:50%;USDT:50%
    const bg = [];
    let totalDeg = 0;
    content.split(';').map((asset, index) => {
      const percent = parseInt(asset.split(':')[1].replace('%', ''));
      const deg = percent * (360 / 100);
      bg.push(`${ASSET_COLORS[index]} ${totalDeg}deg ${totalDeg + deg}deg`);
      totalDeg += deg;
    });

    return bg.join(', ');
  };

  // --- states ---
  // status
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [packageGroups, setPackgroups] = useState(() => {
    if (!!transferPkg) return investPackage.filter(({ Type, PackageID }) => Type === projectInformation.type && PackageID !== transferPkg)
    return investPackage.filter(({ Type }) => Type === projectInformation.type)
  });
  const [selectPackage, setSelectPackage] = useState(-1);

  // --- effect ---
  useEffect(() => {
    if (!!investPackage) {
      setSelectPackage(0);
      setLoading(false);
    };
  }, [investPackage]);

  useEffect(() => {
    if (selectPackage === -1 && projectInformation.packageID !== null) {
      const index = packageGroups.map(({ PackageID }) => PackageID).indexOf(projectInformation.packageID);
      setSelectPackage(index === -1 ? 0 : index);
    }

    if (selectPackage !== -1) setProjectInfomation({
      ...projectInformation,
      packageID: packageGroups[selectPackage].PackageID
    });
  }, [selectPackage]);

  // --- components ---
  if (loading) return <Loading />

  return (
    <>
      {/* title */}
      <h3 className='tracking-[-0.015em] text-center'>
        <span className='align-middle'>Package Selection ({`${currentStep}/${totalStep}`})</span>
        <IoAlertCircleOutline className='inline-block text-sub-word ml-4 align-middle' />
      </h3>

      { error
        &&
        <Alert className={`transition duration-300 opacity-${error ? 1 : 0}`} message={error} />
      }
      
      {/* package */}
      <div className={`relative mt-10 mx-auto p-2 ${className}`}>
        <Swiper
          className='relative'
          navigation={{
            nextEl: ".next",
            prevEl: ".prev"
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Navigation, Pagination]}
          slidesPerView={1}
          breakpoints={{
            768: {
              slidesPerView: 2,
              centerInsufficientSlides: true
            },
            1536: {
              slidesPerView: 4,
              centerInsufficientSlides: true
            },
          }}
        >
          {packageGroups.map(({ Name, Content, PeriodsDays, AnnualizedInterestRate, PackageID }, index) => (
            <SwiperSlide
              key={index}
              className='pb-10 sm:px-10'
            >
              {
                <div
                  className={`
                    relative min-h-[450px] shrink-0 w-[280px] mx-auto rounded cursor-pointer
                    ${
                      selectPackage === index
                      ? 'bg-sub-white dark:bg-sub-black border-transparent shadow-activePackage'
                      : 'bg-white dark:bg-black-main border-1 border-[#666] shadow-package'
                    }
                  `}
                  onClick={() => setSelectPackage(index)}
                >
                  {/* pkg name */}
                  <div className='w-[80%] mx-auto h-[5rem] break-all text-center py-3 text-[1.5rem] font-bold font-[Montserrat] tracking-[-0.015rem] '>
                    {Name}
                  </div>

                  {/* donut chart */}
                  <div
                    className='relative w-[120px] h-[120px] mx-auto my-5 rounded-[50%]'
                    style={{ background: `conic-gradient(${getConicGradient(Content)})` }}
                  >
                    <div
                      className={`
                        absolute w-[80%] h-[80%] abs-center rounded-[50%]
                        text-[1.5rem] font-medium font-[Montserrat] tracking-[-0.015rem]
                        ${
                          selectPackage === index
                          ? 'bg-sub-white dark:bg-sub-black'
                          : 'bg-white dark:bg-black-main'
                        }
                      `}
                    >
                      <span className='absolute abs-center bottom-[-10%]'>
                        <p className='font-[Montserrat font-medium text-[0.75rem] text-center'>APY</p>
                        +{(AnnualizedInterestRate * 100).toFixed(0)}%
                      </span>
                    </div>
                  </div>

                  {/* asset percent */}
                  <div>
                    {/* Content: "BNB:50%;CAKE:50%" */}
                    {Content.split(';').map((label, index) => (
                      <span key={index} className={`block text-center py-1`}>
                        <span className='inline-block w-[45%] text-right' style={{ color: ASSET_COLORS[index] }}>{label.split(':')[0]} </span>
                        <span className='inline-block w-[50%] pl-2 text-left' style={{ color: ASSET_COLORS[index] }}>{label.split(':')[1]}</span>
                      </span>
                    ))}
                  </div>

                  {/* period */}
                  <div className='absolute bottom-0 w-full text-center my-5 '>
                    <span className='text-[1.5rem]'>{PeriodsDays}</span> days / period
                  </div>
                </div>
              }
            </SwiperSlide>
          ))}
        </Swiper>

        {/* swiper prev & next buttons */}
        <button
          className="
            prev absolute z-10 left-0 top-1/2 translate-y-[-50%]
            text-blue text-[2rem] cursor-pointer
          "
        >
          <MdOutlineArrowBackIos />
        </button>
        <button
          className="
            next absolute z-10 right-0 top-1/2 translate-y-[-50%]
            text-blue text-[2rem] cursor-pointer
            "
        >
          <MdOutlineArrowForwardIos />
        </button>
      </div>

      {/* policy */}
      <PolicyCheck data={POLICYS} setValid={setValid} />

      <p className='max-w-[650px] mx-auto mt-10 font-[Inter] text-[0.75rem] text-[#FF0303] dark:text-[#FF0303]'>
      The deposit amount will convert to the selected token at the distribution. It will be used as deposit of the specific token mining equipment.  
      </p>
    </>
  )
};

export default Package;