import { useEffect, useState } from 'react';

// --- components ---
import PolicyCheck from './PolicyCheck';
import Alert from '../Global/Alert';
import Loading from '../Global/Loading';

// --- plugins ---
// icons
import { IoAlertCircleOutline } from 'react-icons/io5';
import { GoArrowRight } from 'react-icons/go';
import { Swal } from 'sweetalert2/dist/sweetalert2';

// --- api ---
import { API_InvestAHFT } from '../../utils/api';

const TransferPackage = ({
  currentStep,
  totalStep,
  transferPkg,
  investPackage,
  setValid,
  projectInformation,
  setProjectInfomation,
  className
}) => {
  // --- const ---
  const ASSET_COLORS = [
    '#55CCFF',
    '#E77373',
    '#7AB981',
    '#FFB951',
  ];
  const POLICYS = [
    `我知道預覽轉換是在時間：${new Date()}時，依照幣安交易所(https://www.binance.com/)，所獲得的市場報價換算的結果。點擊確認後，則會依照點擊時的報價再換算一次。`
  ];

  // --- handler ---
  const getPackageTransferPreview = async () => {
    const res = await API_InvestAHFT({ mode: 'PackageTransferPreview', groupId: projectInformation.Gid, PackageID: projectInformation.packageID });
    // if (res.error) return setError(res.error);
    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return Swal.fire(res.Message);
    setPreviewData(res.PreviewDatas);
    setLoading(false);
  };

  // --- states ---
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [previewData, setPreviewData] = useState(null);

  // --- effect ---
  useEffect(() => {
    if (!!projectInformation) {
      setPreviewData('get');
    };
  }, [projectInformation]);
  useEffect(() => {
    if (previewData === 'get') {
      getPackageTransferPreview();
    }
  }, [previewData]);

  // --- components ---
  if (loading) return <Loading />

  return (
    <>
      {/* title */}
      <h3 className='tracking-[-0.015em] text-center'>
        <span className='align-middle'>Transfer Result Review ({`${currentStep}/${totalStep}`})</span>
        <IoAlertCircleOutline className='inline-block text-sub-word ml-4 align-middle' />
      </h3>

      {error && <Alert className='transition duration-300' message={error} />}
      

      {/* project name */}
      <div className='w-full text-center py-8'>
        <label>Mining Package Name</label>
        <input
          type='text'
          value={projectInformation.name}
          className='block mx-auto lg:inline-block lg:ml-10 w-[300px] px-3 rounded pointer-events-none'
          readOnly
        />
      </div>


      {/* package */}
      <div className='lg:flex justify-center items-center'>

        {/* old pkg */}
        <div
          className={`
            relative h-[360px] py-5 mx-auto my-5 lg:mx-3 w-[280px] rounded pointer-events-none
            bg-white dark:bg-black-main border border-sub-word
            flex flex-col justify-between
          `}
        >
          {/* <span className='absolute bottom-full text-sub'>Before Transfer</span> */}
          <div>
            {/* pkg name */}
            <div className='w-[80%] mx-auto break-all h-[4rem] text-center text-[1.2rem] font-bold font-[Montserrat] tracking-[-0.015rem] '>
              {projectInformation.oriPkg.PackageName}
            </div>
            <div className='w-full mb-3 text-center text-[1rem] text-sub'>
              {projectInformation.oriPkg.PeriodDays} days / period
            </div>

            {/* asset & value */}
            {projectInformation.oriPkg.ListNowPeriodTargetAsset.map(({ TargetAsset, PresentAmount }, index) => (
              <span key={index} className={`block px-10 py-2 flex justify-center`}>
                <span className='flex-1' style={{ color: ASSET_COLORS[index] }}>
                  {TargetAsset}
                </span>
                <span className='flex-1' style={{ color: ASSET_COLORS[index] }}>
                  {PresentAmount.toFixed(2)}
                </span>
              </span>
            ))}
          </div>

          {/* total */}
          <div>
            <hr className='w-[80%] mx-auto' />
            <div className={`px-10 py-2 flex justify-center`}>
              <span className='flex-1 text-sub'>
                Total
              </span>
              <span className='flex-1'>
                {projectInformation.amount.toFixed(0)}
              </span>
            </div>
          </div>
        </div>

        <div>
          <GoArrowRight className='mx-auto rotate-[90deg] lg:rotate-0 text-[2rem] text-sub' />
        </div>

        {/* new pkg */}
        <div
          className={`
            relative h-[360px] py-5 mx-auto my-5 lg:mx-3 w-[280px] rounded pointer-events-none
            bg-white dark:bg-black-main border border-[5px] border-sub-blue
            flex flex-col justify-between
          `}
        >
          {/* <span className='absolute bottom-full text-sub'>After Transfer</span> */}
          <div>
            {/* pkg name */}
            <div className='w-[80%] mx-auto break-all h-[4rem] text-center text-[1.2rem] font-bold font-[Montserrat] tracking-[-0.015rem] '>
              {investPackage.filter(({ PackageID }) => projectInformation.packageID === PackageID)[0].Name}
            </div>
            <div className='w-full mb-3 text-center text-[1rem] text-sub'>
              {investPackage.filter(({ PackageID }) => projectInformation.packageID === PackageID)[0].PeriodsDays} days / period
            </div>

            {/* asset & value */}
            {previewData.map(({ Asset, TransferAmount }, index) => (
              <span key={index} className={`block px-10 py-2 flex justify-center`}>
                <span className='flex-1' style={{ color: ASSET_COLORS[index] }}>
                  {Asset}
                </span>
                <span className='flex-1' style={{ color: ASSET_COLORS[index] }}>
                  {TransferAmount.toFixed(2)}
                </span>
              </span>
            ))}
          </div>

          {/* total */}
          <div>
            <hr className='w-[80%] mx-auto' />
            <div className={`px-10 py-2 flex justify-center`}>
              <span className='flex-1 text-sub'>
                Total
              </span>
              <span className='flex-1'>
                {previewData.reduce((prev, { TransferAmount, Binance_Price }) => prev + TransferAmount * Binance_Price, 0).toFixed(0)}
              </span>
            </div>
          </div>
        </div>

      </div>

      {/* policy */}
      <PolicyCheck data={POLICYS} setValid={setValid} />
    </>
  )
};

export default TransferPackage;