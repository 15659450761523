import { useEffect, useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useGetAlgoQuery } from "../../services/algoApi";
import { setAccountAlgo } from "../../slices/accountSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectAccount, addAccountApikey } from "../../slices/accountSlice";
import { selectSystem } from "../../slices/systemSlice";

import styled, { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";

// component
import Introduce from "./Introduce";
import AlgoTable from "./AlgoTable";
import Icons from "../../global/icons";
import Lang from "../../global/Lang";
import Loading from "../../components/Loading/Loading";

import Binance from "../../images/binance.png";
import Bybit from "../../images/bybit.png";

import {
  getBinanceBalance,
  getBybitBalance,
  getIgBalance,
} from "../../services/balanceApi";
import {
  useGetAccountApikeyQuery,
  useUpdateWatchingTutorialMutation,
} from "../../services/accountApi";

// sweetAlert
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
  ErrorMySwal,
  TutorialMySwal,
  SuccessMySwal,
} from "../../global/MySwal";
import { getLanguage } from "../../services/common";
import { LangString } from "../../utils";

// Joyride

import JoyrideComponent from "../../global/JoyrideComponent";

// #region styled
const Wrapper = styled.div`
  height: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
`;

const Title = styled.div`
  font-size: min(max(1.25vw, 22px), 24px);
  color: #fcfcfc;
  padding-right: 10px;
  text-transform: capitalize;

  ${RWD_MD(css`
    font-size: min(max(1.145vw, 20px), 22px);
  `)};
`;

const SlideWrapper = styled.div`
  padding-bottom: 56px;
`;

const AlgoTableWrapper = styled.div`
  position: relative;
`;

const AlgoCreateButtonWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 3.125vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
`;

const AlgoCreateButton = styled.div`
  border-radius: 60px;
  width: min(max(7.0833vw, 100px), 135px);
  background: linear-gradient(to bottom, #2f80ed 0%, #5620f0 100%);
  cursor: pointer;
  text-align: center;
`;

const AlgoCreateButtonText = styled.div`
  font-size: min(max(1.0416vw, 16px), 20px);
  color: #fcfcfc;
  padding: 0.52vw 1.3vw;
  ${RWD_LG(css`
    padding: 8px 18px;
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const IntroDuceWrapper = styled.div`
  max-height: ${(props) => (props.openIntroduce ? "100%" : "0")};
  overflow: hidden;
`;

const IntroDuceTitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 20px;
`;

const IntroDuceIcon = styled.div`
  cursor: pointer;
  transform: ${(props) => (props.openIntroduce ? "scaleY(-1)" : "scaleY(1)")};
  transition: all 0.3s;
`;

const BalanceWrapper = styled.div`
  color: #fcfcfc;
`;

const BalanceListWrapper = styled.div`
  max-height: ${(props) => (props.openBalance ? "80vh" : "0")};
  overflow: hidden;
`;

const BalanceOpenIcon = styled.div`
  cursor: pointer;
  transform: ${(props) => (props.openBalance ? "scaleY(-1)" : "scaleY(1)")};
  transition: all 0.3s;
`;

const BalanceBox = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.15);
  padding: 1.5vw 2vw 1.5vw 2vw;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 10px;

  ${RWD_SM(css`
    padding: 20px 15px;
    margin-right: 0;
    margin-bottom: 10px;

    width: 100%;
  `)};
`;

const BalanceContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  padding-bottom: 24px;

  ${RWD_SM(css`
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  `)};
`;

const BalanceLogoWrapper = styled.div`
  height: 35px;

  ${RWD_LG(css`
    height: 25px;
  `)};

  img {
    height: 100%;
    object-fit: cover;
  }
`;

const BalanceText = styled.div`
  font-size: min(max(0.9375vw, 16px), 18px);
  align-self: flex-end;
  padding-top: 5px;

  ${RWD_SM(css`
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const BalanceIcon = styled.span`
  font-size: min(max(1.145vw, 20px), 22px);
  /* font-weight: bold; */
  padding-right: 5px;

  ${RWD_SM(css`
    font-size: min(max(0.9375vw, 16px), 18px);
  `)};
`;

const BalanceAmount = styled.span`
  font-weight: bold;
  font-size: min(max(1.5625vw, 28px), 30px);

  ${RWD_SM(css`
    font-size: min(max(1.25vw, 22px), 24px);
  `)};
`;

const BalanceError = styled.span`
  color: #f05d2f;
  opacity: 0.5;
  font-size: min(max(0.9375vw, 16px), 18px);
  align-self: flex-end;
  padding-top: 5px;

  ${RWD_SM(css`
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const WalletBalanceWrapper = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

const WalletBalanceTitle = styled.div`
  font-size: min(max(1.041667vw, 18px), 20px);
  /* font-weight: bold; */
  padding-right: 5px;

  ${RWD_SM(css`
    font-size: min(max(0.9375vw, 16px), 18px);
  `)};
`;

const WalletBalance = styled.div`
  display: flex;
  align-items: baseline;
`;

const Balance = styled.div`
  font-size: min(max(1.875vw, 34px), 36px);
  padding-top: 1.5vw;
  width: 100%;

  ${RWD_SM(css`
    padding-top: 5px;
  `)};
`;

const BalanceTitle = styled.div`
  font-size: min(max(1.145vw, 20px), 22px);
  color: #fcfcfc;
  padding-right: 10px;

  ${RWD_MD(css`
    font-size: min(max(1.0416vw, 18px), 20px);
  `)};
`;

const BalanceTitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 24px;
`;

const FreeBalanceWrapper = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

const FreeBalanceTitle = styled.div`
  font-size: min(max(0.9375vw, 16px), 18px);
  color: #fcfcfc;

  ${RWD_MD(css`
    font-size: min(max(0.372093vw, 14px), 16px);
  `)};
`;

const FreeBalance = styled.div`
  display: flex;
  align-items: baseline;
  color: #1cac2a;
`;

const FreeBalanceIcon = styled.span`
  font-size: min(max(0.9375vw, 16px), 18px);
  /* font-weight: bold; */
  padding-right: 5px;

  ${RWD_SM(css`
    font-size: min(max(0.372093vw, 14px), 16px);
  `)};
`;

const FreeBalanceAmount = styled.div`
  font-weight: bold;
  font-size: min(max(1.354167vw, 24px), 26px);

  ${RWD_SM(css`
    font-size: min(max(0.46vw, 18px), 20px);
  `)};
`;

const TutorialHelpIconWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #fcfcfc;
  /* position: absolute; */
  bottom: 0px;
  cursor: pointer;
  width: 100%;
`;

const TutorialHelpIcon = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  right: 0;

  & path {
    transition: all 0.3s linear;
  }

  svg {
    width: 25px;
    height: 25px;

    ${TutorialHelpIconWrapper}:hover & {
      & path {
        stroke: #0aecd1;
      }
    }
  }
`;

const TutorialHelpText = styled.div`
  font-size: 14px;
  padding-left: 5px;
  transition: all 0.3s linear;

  ${TutorialHelpIconWrapper} &:hover {
    color: #0aecd1;
  }
`;

// #endregion

const Algo = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tutorial = searchParams.get("tutorial");
  const questhubtutorial = searchParams.get("questhubtutorial");
  const handleJoyrideCallback = () => {
    go({
      pathname: "/v2/addalgo/",
      search: "?tutorial=algo",
    });
  };
  const [userCloseTutorial, setUserCloseTutorial] = useState(false);
  const [joyrideIsRun, setJoyrideIsRun] = useState(false);
  const joyrideState = [
    {
      target: ".createAlgo_btn",
      content: (
        <Lang
          datakey="algo_tutorial_1-1"
          defaultText="Click the button to create a NEW Algo."
        />
      ),
      placement: "left",
      disableBeacon: true,
      disableOverlayClose: true,
      callbackAction: true,
      function: handleJoyrideCallback,
    },
  ];

  const handleTutorial = () => {
    const confirmText = LangString("yes", "Yes");
    const cancelText = LangString("nothanks", "No, Thanks");
    const titleText = LangString("welcome", "WELCOME !");
    const contentText = LangString(
      "algo_tutorial_title",
      "Would you like to start the Mining Trade beginner's tutorial?"
    );
    TutorialMySwal({
      title: titleText,
      text: contentText,
      showCancelButton: true,
      cancelButtonText: cancelText,
      confirmButtonText: confirmText,
    }).then((result) => {
      if (result.isConfirmed) {
        setJoyrideIsRun(true);
      } else {
        // 清除param
        searchParams.delete("questhubtutorial");
        searchParams.delete("tutorial");
        setSearchParams(searchParams);

        // 使用者不看教學
        setUserCloseTutorial(true);
        _updateWatchingTutorial({
          body: {
            TutorialKey: "Algo",
          },
        });
      }
    });
  };

  const go = useNavigate();
  const dispatch = useDispatch();
  const profileData = useSelector(selectAccount).accountProfile;
  const apiKeyData = useSelector(selectAccount).accountApikey;

  // 確認是否已經讀過新手教學
  useEffect(() => {
    if (
      (profileData.HasWatchedAlgoTutorial === false && tutorial == null) ||
      questhubtutorial === "algo"
    ) {
      // 開始新手教學
      handleTutorial();
    }
  }, [profileData, searchParams]);

  const [openIntroduce, setOpenIntroduce] = useState(false);
  const [algoList, setAlgoList] = useState([]);
  const [openBalance, setOpenBalance] = useState(true);

  const [binance, setBinance] = useState(null);
  const [bybit, setBybit] = useState(null);
  const [ig, setIg] = useState(null);

  const [initAlgo, setInitAlgo] = useState(false);

  const btcusdt = useSelector(selectSystem).btcusdt;
  const ethusdt = useSelector(selectSystem).ethusdt;

  const getTargetUsdt = (target) => {
    switch (target) {
      case "BTC":
        return btcusdt;
      case "ETH":
        return ethusdt;
    }
  };

  // 取得algo
  const {
    data: algoResponse,
    isSuccess: algoResponseIsSuccess,
    isError: algoResponseIsError,
    error: algoResponseError,
    refetch,
    isLoading,
    isFetching,
  } = useGetAlgoQuery();

  // 取得apikey;
  const {
    data: accountApikey,
    isSuccess: accountApikeyIsSuccess,
    isError: accountApikeyIsError,
    error: accountApikeyError,
  } = useGetAccountApikeyQuery();

  // 新手教學
  const [
    _updateWatchingTutorial,
    {
      data: tutorialData,
      isSuccess: tutorialIsSuccess,
      isError: tutorialIsError,
      error: tutorialError,
    },
  ] = useUpdateWatchingTutorialMutation();

  useEffect(() => {
    if (tutorialIsSuccess) {
      // 使用者不看教學 直接return
      if (userCloseTutorial) return;
      const confirmText = LangString("confirm", "Confirm");
      const titleText = LangString("tutorial_complete", "Tutorial complete!");
      SuccessMySwal({
        title: titleText,
        showCancelButton: false,
        confirmButtonText: confirmText,
      });
    }

    if (tutorialIsError) {
      ErrorMySwal({
        title:
          tutorialError?.data?.ResultLanguages[getLanguage()] ||
          tutorialError?.data?.Message,
      });
    }
  }, [tutorialData, tutorialIsError]);

  useEffect(() => {
    // 取得錢包餘額
    if (accountApikeyIsSuccess) {
      dispatch(addAccountApikey(accountApikey));
      if (accountApikey?.Apikeys?.length > 0) {
        accountApikey?.Apikeys?.forEach((element) => {
          if (
            element?.ExchangeId === 1 &&
            element?.Abnormal?.toLowerCase() === "none"
          ) {
            const data = {
              apikey: element.ApiKey,
              secretkey: element.SecretKey,
            };

            getBinanceBalance(data)
              .then((res) => {
                if (res.status === 200) {
                  const USDT = res.data.filter(({ asset }) => asset === "USDT");
                  setBinance(USDT[0]?.balance);
                } else {
                  ErrorMySwal({
                    title: res.data.msg,
                  });
                }
              })
              .catch((error) => {
                console.log(error.message);
                // MySwal.fire({
                //   title: error.message,
                //   icon: "error",
                //   confirmButtonText: "確定",
                // });
              });
          } else if (
            element?.ExchangeId === 4 &&
            element?.Abnormal?.toLowerCase() === "none"
          ) {
            const data = {
              apikey: element.ApiKey,
              secretkey: element.SecretKey,
            };

            getIgBalance(data)
              .then((res) => {
                console.log(res);
                if (res.status === 200) {
                  console.log(res.data.accounts[0]?.balance?.available);
                  setIg(res.data.accounts[0]?.balance?.available);
                } else {
                  ErrorMySwal({
                    title: res.data.retMsg,
                  });
                }
              })
              .catch((error) => {
                console.log(error);
                // MySwal.fire({
                //   title: error.message,
                //   icon: "error",
                //   confirmButtonText: "確定",
                // });
              });
          } else {
            if (
              element?.ExchangeId === 2 &&
              element?.Abnormal?.toLowerCase() === "none"
            ) {
              const data = {
                apikey: element.ApiKey,
                secretkey: element.SecretKey,
              };

              getBybitBalance(data)
                .then((res) => {
                  if (res.data.retMsg === "OK") {
                    setBybit(res.data.result?.list[0]?.coin[0]?.walletBalance);
                  } else {
                    ErrorMySwal({
                      title: res.data.retMsg,
                    });
                  }
                })
                .catch((error) => {
                  console.log(error);
                  // MySwal.fire({
                  //   title: error.message,
                  //   icon: "error",
                  //   confirmButtonText: "確定",
                  // });
                });
            }
          }
        });
      }
    }
  }, [accountApikey, accountApikeyIsError, openBalance]);

  useEffect(() => {
    // 每次進入頁面都刷新algo
    refetch();
  }, []);

  useEffect(() => {
    if (algoResponseIsSuccess) {
      setAlgoList(algoResponse?.Algos);
      dispatch(setAccountAlgo(algoResponse));

      if (algoResponse?.Algos?.length > 0) {
        setOpenIntroduce(false);
      } else {
        // 有algo 關閉algo介紹
        setOpenIntroduce(true);
      }
    }

    if (algoResponseIsError) {
      ErrorMySwal({
        title:
          algoResponseError?.data?.ResultLanguages[getLanguage()] ||
          algoResponseError?.data?.Message,
      });
    }
  }, [algoResponse, algoResponseIsError]);

  const handleOpenSlide = () => {
    setOpenIntroduce(!openIntroduce);
  };

  const handleAddAlgo = () => {
    go("/v2/addalgo");
  };

  const fakeAlgoData = [
    {
      AlgoTradeName: "ai_robot_11",
      AmountSetting: 500,
      CreationTime: "2023-10-02 08:12",
      CurrentAmount: 500,
      ErrorStatus: null,
      Exchange: "testbybit",
      Id: "66",
      InheritedLoss: null,
      IsClosed: true,
      IsSubscribe: false,
      NickName: "Test",
      RobotId: 18,
      Status: "init",
      Target: "ETH",
    },
  ];

  const handleFreeBalance = (exchange, walletBalance) => {
    const sum = algoResponse?.Algos.filter(
      (data) => data.Status === "running" && data.Exchange === exchange
    )
      .map((item) => item.CurrentAmount)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    return (walletBalance - sum).toFixed(4) > 0
      ? (walletBalance - sum).toFixed(4)
      : Number(0).toFixed(4);
  };

  return (
    <Wrapper>
      <JoyrideComponent
        joyrideState={joyrideState}
        setJoyrideIsRun={setJoyrideIsRun}
        run={joyrideIsRun}
        joyrideType={"algo"}
      />
      {(isLoading || isFetching) && <Loading />}
      <Container>
        <IntroDuceTitleWrapper>
          <Title>
            <Lang datakey="algo_about" defaultText="More about AlgoTrade" />
          </Title>
          {algoResponse?.Algos?.length > 0 && (
            <IntroDuceIcon
              onClick={handleOpenSlide}
              openIntroduce={openIntroduce}
            >
              <Icons.Arrow />
            </IntroDuceIcon>
          )}
        </IntroDuceTitleWrapper>

        <IntroDuceWrapper openIntroduce={openIntroduce}>
          <SlideWrapper>
            <Introduce />
          </SlideWrapper>
        </IntroDuceWrapper>

        {apiKeyData.length > 0 &&
          (apiKeyData.filter((data) => data.ExchangeId === 1).length > 0 ||
            apiKeyData.filter((data) => data.ExchangeId === 2).length > 0) && (
            <BalanceWrapper>
              <BalanceTitleWrapper>
                <BalanceTitle>
                  <Lang datakey="wallet_balance" defaultText="Wallet balance" />
                </BalanceTitle>
                <BalanceOpenIcon
                  onClick={() => setOpenBalance(!openBalance)}
                  openBalance={openBalance}
                >
                  <Icons.Arrow />
                </BalanceOpenIcon>
              </BalanceTitleWrapper>

              <BalanceListWrapper openBalance={openBalance}>
                <BalanceContainer>
                  {apiKeyData.filter((data) => data.ExchangeId === 1).length >
                    0 && (
                    <BalanceBox>
                      <BalanceLogoWrapper>
                        <img src={Binance} />
                      </BalanceLogoWrapper>
                      {/* <Balance>
                        <BalanceIcon>$</BalanceIcon>
                        <BalanceAmount>
                          {Number(binance).toFixed(4)}{" "}
                        </BalanceAmount>
                      </Balance> */}
                      <Balance>
                        <WalletBalanceWrapper>
                          <WalletBalanceTitle>
                            Wallet Balance
                          </WalletBalanceTitle>
                          <WalletBalance>
                            <BalanceIcon>$</BalanceIcon>
                            <BalanceAmount>
                              {Number(binance).toFixed(4)}
                            </BalanceAmount>
                          </WalletBalance>
                        </WalletBalanceWrapper>

                        {/* WalletBalance - Sum(CurrentAmount) */}
                        <FreeBalanceWrapper>
                          <FreeBalanceTitle>Free Balance</FreeBalanceTitle>
                          <FreeBalance>
                            <FreeBalanceIcon>$</FreeBalanceIcon>
                            <FreeBalanceAmount>
                              {handleFreeBalance("binance", Number(bybit))}
                            </FreeBalanceAmount>
                          </FreeBalance>
                        </FreeBalanceWrapper>
                      </Balance>
                      {binance === null ? (
                        <BalanceError>
                          <Lang
                            datakey="algo_walletBalance_apikey"
                            defaultText="APIKey verification failed"
                          />
                        </BalanceError>
                      ) : (
                        <BalanceText>USDT</BalanceText>
                      )}
                    </BalanceBox>
                  )}

                  {apiKeyData.filter((data) => data.ExchangeId === 2).length >
                    0 && (
                    <BalanceBox>
                      <BalanceLogoWrapper>
                        <img src={Bybit} />
                      </BalanceLogoWrapper>
                      <Balance>
                        <WalletBalanceWrapper>
                          <WalletBalanceTitle>
                            Wallet Balance
                          </WalletBalanceTitle>
                          <WalletBalance>
                            <BalanceIcon>$</BalanceIcon>
                            <BalanceAmount>
                              {Number(bybit).toFixed(4)}{" "}
                            </BalanceAmount>
                          </WalletBalance>
                        </WalletBalanceWrapper>

                        {/* WalletBalance - Sum(CurrentAmount) */}
                        <FreeBalanceWrapper>
                          <FreeBalanceTitle>Free Balance</FreeBalanceTitle>
                          <FreeBalance>
                            <FreeBalanceIcon>$</FreeBalanceIcon>
                            <FreeBalanceAmount>
                              {handleFreeBalance("bybit", Number(bybit))}
                            </FreeBalanceAmount>
                          </FreeBalance>
                        </FreeBalanceWrapper>
                      </Balance>

                      {bybit === null ? (
                        <BalanceError>
                          <Lang
                            datakey="algo_walletBalance_apikey"
                            defaultText="APIKey verification failed"
                          />
                        </BalanceError>
                      ) : (
                        <BalanceText>USDT</BalanceText>
                      )}
                    </BalanceBox>
                  )}
                </BalanceContainer>
              </BalanceListWrapper>
            </BalanceWrapper>
          )}

        <AlgoTableWrapper>
          {(!isLoading || !isFetching) &&
            algoList?.length > 0 &&
            tutorial == null && (
              <AlgoTable
                algoData={algoList}
                getTargetUsdt={getTargetUsdt}
                isTelegramVerified={profileData?.IsTelegramVerified}
              />
            )}

          {tutorial === "algo" && (
            <AlgoTable
              algoData={fakeAlgoData}
              getTargetUsdt={getTargetUsdt}
              initAlgo={initAlgo}
              // isTelegramVerified={profileData?.IsTelegramVerified}
            />
          )}
        </AlgoTableWrapper>

        <AlgoCreateButtonWrapper>
          <AlgoCreateButton
            onClick={() => handleAddAlgo()}
            className="createAlgo_btn"
          >
            <AlgoCreateButtonText>
              <Lang datakey="add_algo" defaultText="Create" />
            </AlgoCreateButtonText>
          </AlgoCreateButton>

          <TutorialHelpIconWrapper onClick={() => handleTutorial()}>
            <TutorialHelpIcon>
              <Icons.Tutorial_Help />
            </TutorialHelpIcon>
            <TutorialHelpText>
              <Lang datakey="restart_tutorial" defaultText="Restart tutorial" />
            </TutorialHelpText>
          </TutorialHelpIconWrapper>
        </AlgoCreateButtonWrapper>
      </Container>
    </Wrapper>
  );
};

export default Algo;
