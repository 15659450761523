import styled from "styled-components";
import { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";
import { useState, useEffect } from "react";
import Icons from "../../global/icons";
import { dateTimeFormat } from "../../utils";

import Lang from "../../global/Lang";

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  color: #151944;
`;

const Container = styled.div`
  background-color: #fcfcfc;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 40px;
  width: 80vw;
  max-width: 850px;
  border-radius: 8px;
  position: relative;

  ${RWD_LG(css`
    width: 80%;
  `)};

  ${RWD_MD(css`
    width: 90%;
    /* padding: 20px 40px; */
  `)};

  ${RWD_SM(css`
    padding: 20px;
  `)};
`;

const Title = styled.div`
  color: #151944;
  font-size: min(max(1.5625vw, 28px), 30px);
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;

  ${RWD_MD(css`
    font-size: min(max(1.0416vw, 18px), 20px);
  `)};
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: 1px solid #151944;

  ${RWD_MD(css`
    overflow-x: auto;
  `)};
`;

const TableContainer = styled.div`
  overflow: auto;
  max-height: 60vh;
  height: 100%;
  overscroll-behavior: contain;

  ${RWD_MD(css`
    max-height: 50vh;
    min-width: 400px;
  `)};
`;

const TableHeaderWrapper = styled.div`
  padding: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #151944;
  color: #fcfcfc;
  font-size: min(max(0.833vw, 14px), 16px);
  position: sticky;

  ${RWD_MD(css`
    padding: 5px;
    min-width: 400px;
  `)};
`;

const Th = styled.div`
  font-weight: 400;
  text-align: center;
  width: 100%;
`;

const TableItemWrapper = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => (props.index % 2 ? "" : "#D9D9D9")};
`;

const TableItem = styled.div`
  /* width: 100%; */
`;

const TableItemText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  font-size: min(max(0.833vw, 14px), 16px);
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${(props) => (props.status ? "scaleY(-1)" : "scaleY(1)")};
  cursor: pointer;
  transition: all 0.3s;
`;

const TableDataWrapper = styled.div`
  /* border: 1px solid blue; */
`;

const DetailWrapper = styled.div`
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  padding: 1.875vw;
  border-top: 1px solid #3a3d5a;
  border-bottom: 1px solid #3a3d5a;
  overflow-x: auto;

  ${RWD_MD(css`
    padding: 10px;
  `)};
`;

const DetailTableHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  background: #54587b;
  font-size: min(max(0.833vw, 14px), 16px);

  ${RWD_MD(css`
    min-width: 500px;
  `)};
  /* color: #fcfcfc; */
`;

const DetailTableHeader = styled.div`
  width: 100%;
  text-align: center;
  color: #fcfcfc;
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: min(max(0.833vw, 14px), 16px);

  ${RWD_MD(css`
    min-width: 500px;
  `)};
`;

const DetailItemWrapper = styled.div`
  display: flex;
`;

const DeatilItem = styled.div`
  border-top: 1px solid #d9d9d9;
  width: 100%;
  padding: 5px;
  text-align: center;
`;

const CloseIcon = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
`;

const Nodata = styled.div`
  text-align: center;
  /* font-weight: bold; */
  font-size: 20px;
  padding: 20px;
`;

const HistoryModal = ({ setHistoryModalIsOpen, data }) => {
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    if (!data) return;
    setTableData(() =>
      data.map((item) => ({
        ...item,
        isOpen: false,
        ExecFee: item?.GroupInfos?.map((data) => data.ExecFee ?? 0)
          .reduce((a, b) => Number(a) + Number(b), 0)
          ?.toFixed(2),

        // item?.GroupInfos?.map((data) =>
        //   data.ExecFee?.toFixed(2)
        // ).reduce((a, b) => Number(a) + Number(b), 0),

        Time:
          item?.GroupInfos?.filter((data) => data.Action === "Close").length > 0
            ? dateTimeFormat(
                item?.GroupInfos?.filter((data) => data.Action === "Close")[0]
                  .CreateOrderTime
              )
            : dateTimeFormat(
                item?.GroupInfos?.filter((data) => data.Action === "Open")[0]
                  .CreateOrderTime
              ),
      }))
    );
  }, [data]);
  const tableHeader = [
    {
      id: 1,
      value: "GroupId",
      width: "15%",
    },
    {
      id: 2,
      value: "Side",
      width: "15%",
    },
    {
      id: 3,
      value: "Profit",
      width: "20%",
    },
    {
      id: 4,
      value: "ExecFee",
      width: "15%",
    },
    {
      id: 5,
      value: "Time",
      width: "25%",
    },
    {
      id: 6,
      value: "Action",
      width: "10%",
    },
  ];

  const detailHeader = [
    {
      id: 1,
      value: "Action",
      width: "15%",
    },
    {
      id: 2,
      value: "Amount",
      width: "20%",
    },
    {
      id: 3,
      value: "AvgPrice",
      width: "20%",
    },
    {
      id: 4,
      value: "ExecFee",
      width: "20%",
    },
    {
      id: 5,
      value: "CreateOrderTime",
      width: "25%",
    },
  ];

  const handleDataOpen = (id) => {
    setTableData(
      tableData.filter((data) => {
        data.isOpen = data.GroupId === id ? !data.isOpen : false;
        return data;
      })
    );
  };
  const handleCancel = () => {
    setHistoryModalIsOpen(false);
  };

  return (
    <Wrapper>
      <Container>
        <Title>Order History</Title>
        <CloseIcon onClick={handleCancel}>
          <Icons.Close />
        </CloseIcon>
        {tableData.length === 0 && (
          <Nodata>
            <Lang datakey="nodata" defaultText="No data available" />
          </Nodata>
        )}
        {tableData.length > 0 && (
          <TableWrapper>
            <TableHeaderWrapper>
              {tableHeader.map((item) => (
                <Th
                  key={item.id}
                  style={{
                    width: `${item.width}`,
                  }}
                >
                  {item.value}
                </Th>
              ))}
            </TableHeaderWrapper>
            <TableContainer>
              {tableData.map((item, index) => (
                <TableDataWrapper key={index}>
                  <TableItemWrapper key={item.id} index={index + 1}>
                    {tableHeader.map((el) => (
                      <TableItem
                        key={el.id}
                        style={{
                          width: `${el.width}`,
                        }}
                      >
                        {(() => {
                          switch (el.value) {
                            case "Profit":
                              return (
                                <TableItemText>
                                  {item[el.value] ?? "N/A"}
                                </TableItemText>
                              );

                            case "Action":
                              return (
                                <IconWrapper
                                  onClick={() => handleDataOpen(item.GroupId)}
                                >
                                  <Icons.Arrow />
                                </IconWrapper>
                              );

                            default:
                              return (
                                <TableItemText>{item[el.value]}</TableItemText>
                              );
                          }
                        })()}
                      </TableItem>
                    ))}
                  </TableItemWrapper>
                  {item.isOpen && (
                    <DetailWrapper>
                      <DetailTableHeaderWrapper>
                        {detailHeader.map((item, index) => (
                          <DetailTableHeader
                            style={{
                              width: `${item.width}`,
                            }}
                            key={index}
                          >
                            {item.value}
                          </DetailTableHeader>
                        ))}
                      </DetailTableHeaderWrapper>
                      <DetailContainer>
                        {item?.GroupInfos.map((data, index) => (
                          <DetailItemWrapper key={index}>
                            {detailHeader.map((el, index) => (
                              <DeatilItem
                                style={{
                                  width: `${el.width}`,
                                }}
                                key={index}
                              >
                                {(() => {
                                  switch (el.value) {
                                    case "CreateOrderTime":
                                      return dateTimeFormat(data[el.value]);
                                    case "ExecFee":
                                      return data[el.value]
                                        ? data[el.value].toFixed(2)
                                        : 0;
                                    default:
                                      return data[el.value];
                                  }
                                })()}
                              </DeatilItem>
                            ))}
                          </DetailItemWrapper>
                        ))}
                      </DetailContainer>
                    </DetailWrapper>
                  )}
                </TableDataWrapper>
              ))}
            </TableContainer>
          </TableWrapper>
        )}
      </Container>
    </Wrapper>
  );
};

export default HistoryModal;
