import { useEffect, useState } from 'react';
import { useData } from '../../utils/context';
// import AlgoPerformance from '../Landing/Strategy/AlgoPerformance';
import AlgoPerformance from '../Landing/Strategy/AlgoPerformance_v221209';
import InformationBot from '../Landing/Strategy/InformationBot';
import Performance from '../Landing/Strategy/Performance';
import { API_AIRobotList, API_AlgoTradeStaticData, API_AvgPrice_binance, API_InfoBotList, API_InfoBotListById, API_MiningSystemStaticData } from '../../utils/api';
import { Swal } from 'sweetalert2/dist/sweetalert2';

const Main = () => {
  const handleGetAlgoStaticData = async () => {
    const res = await API_AlgoTradeStaticData();
    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return Swal.fire(res.Message);
    setIsWaitApi(false);

    // chart data
    setAlgoStaticData({
      ...algoStaticData,
      labels: res?.XAxisData,
      datasets: res?.YAxisSeriesDatas?.filter(({ Name }) => Name === 'MainAlgo_LineChart')?.map(({ Name, YAxisDatas }, index) => ({
        label: Name,
        data: YAxisDatas,
        borderColor: 'rgb(' + (136 + (index * 10)) + ' ' + (220 - (index * 10)) + ' ' + (255 - (index * 50)) + ')',
        backgroundColor: 'rgb(' + (136 + (index * 10)) + ' ' + (220 - (index * 10)) + ' ' + (255 - (index * 50)) + ')',
      }))
    });

    // table data
    setAlgoTableData({
      ...algoTableData,
      labels: res?.XAxisData,
      datasets: res?.YAxisSeriesDatas?.filter(({ Name }) => Name === 'MainAlgo_Table')?.map(({ Name, YAxisDatas }, index) => ({
        label: Name,
        data: YAxisDatas,
        borderColor: 'rgb(' + (136 + (index * 10)) + ' ' + (220 - (index * 10)) + ' ' + (255 - (index * 50)) + ')',
        backgroundColor: 'rgb(' + (136 + (index * 10)) + ' ' + (220 - (index * 10)) + ' ' + (255 - (index * 50)) + ')',
      }))
    });
  };
  const handleGetInfoBotList = async (hasLogin) => {
    const res = hasLogin
      ? await API_InfoBotListById()
      : await API_InfoBotList();
    setIsWaitApi(false);

    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return Swal.fire(res.Message);
    
    setInfobot({
      hasLogin,
      infoBotDatas: res.infoBotDatas ?? [],
    });
  };
  const handleGetExchange = async () => {
    const res = await API_AIRobotList();
    setIsWaitApi(false);
    
    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return Swal.fire(res.Message);
    
    setRobotGroup(res.Ai_Robot_List?.filter(({ availabe }) => availabe));
  };
  const handleGetAvgPrice = async (targets) => {
    const res = await API_AvgPrice_binance(targets);
    setCurrentPrice(res);
  }
  const handleGetMiningSystemStaticData = async () => {
    const res = await API_MiningSystemStaticData();
    setIsWaitApi(false);

    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return Swal.fire(res.Message);

    setPerformance({
      ...performance,
      labels: res?.XAxisData,
      datasets: res?.YAxisSeriesDatas?.map(({ Name, YAxisDatas }, index) => ({
        label: Name,
        data: YAxisDatas,
        borderColor: 'rgb(' + (136 + (index * 10)) + ' ' + (220 - (index * 10)) + ' ' + (255 - (index * 50)) + ')',
        backgroundColor: 'rgb(' + (136 + (index * 10)) + ' ' + (220 - (index * 10)) + ' ' + (255 - (index * 50)) + ')',
      }))
    });
  };


  const { langData, setIsWaitApi, waitingApi, setWaitingApi } = useData();

  const [robotGroup, setRobotGroup] = useState([]);
  const [infobot, setInfobot] = useState([]);
  const [currentPrice, setCurrentPrice] = useState([]);
  const [algoTableData, setAlgoTableData] = useState({
    labels: [],
    datasets: []
  });
  const [algoStaticData, setAlgoStaticData] = useState({
    labels: [],
    datasets: []
  });
  const [performance, setPerformance] = useState({
    labels: [],
    datasets: []
  });

  useEffect(() => {
    const callApis = [
      handleGetExchange,
      handleGetMiningSystemStaticData,
      handleGetAlgoStaticData,
      () => handleGetInfoBotList(true),
    ];

    if (waitingApi.length === 0) {
      setWaitingApi(callApis);
    }
  }, []);

  useEffect(() => {
    if (robotGroup.length === 0) return;
    if (currentPrice.length !== 0) return;
    const targets = robotGroup.map(({ target }) => target);
    handleGetAvgPrice(targets)
  }, [robotGroup]);

  return (
    <div className='px-4 xl:max-w-[90%] mx-auto'>
      <div className='text-[2.5rem] font-bold'>Feature</div>
      <InformationBot data={infobot} setData={setInfobot} prices={currentPrice} isLanding={false} />
      <AlgoPerformance chart={algoStaticData} table={algoTableData} />
      <Performance data={performance} />
    </div>
  )
}

export default Main
