import styled, { css } from "styled-components";
import { QRCodeCanvas } from "qrcode.react";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";

import Loading from "../../../components/Loading/Loading";
import Button from "../../../global/Buttons";
import Lang from "../../../global/Lang";

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
`;

const Container = styled.div`
  background-color: #fcfcfc;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100px;
  height: auto;
  padding: 40px;
  width: 50vw;
  max-width: 650px;
  /* min-width: 500px; */
  border-radius: 8px;

  ${RWD_LG(css`
    width: 70%;

    /* min-width: auto; */
  `)};

  ${RWD_MD(css`
    width: 60%;
    padding: 20px;
  `)};

  ${RWD_SM(css`
    width: 90%;
    padding: 20px;
  `)};
`;

const Title = styled.div`
  color: #151944;
  font-size: min(max(1.5625vw, 28px), 30px);
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 20px;

  ${RWD_MD(css`
    font-size: min(max(1.0416vw, 18px), 20px);
  `)};
`;

const PasswordWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #d9d9d9;
  border-radius: 100px;
`;

const PasswordIcon = styled.button`
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  margin-right: 20px;

  svg {
    width: 30px;
    height: 30px;
  }

  & path {
    stroke: #fcfcfc;
  }

  ${RWD_SM(css`
    width: 25px;
    height: 25px;
    margin-right: 15px;

    svg {
      width: 25px;
      height: 25px;
    }
  `)};
`;

const InputWrapper = styled.div``;

const SubTitle = styled.div`
  color: #151944;
  font-size: min(max(1.145vw, 20px), 22px);
  font-weight: bold;
  padding-bottom: 10px;
  padding-top: 1.3vw;
  display: flex;

  ${RWD_MD(css`
    font-size: min(max(0.9375vw, 16px), 18px);
  `)};
`;

const Input = styled.input`
  background: #d9d9d9;
  padding: 1.0416vw 1.5625vw;
  width: 100%;
  border-radius: 100px;
  color: #151944;

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 14px), 16px);
    padding: 8px 20px;
  `)};
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 50px;
  position: relative;

  button:first-child {
    margin-right: 1.5625vw;
  }

  ${RWD_SM(css`
    padding-top: 25px;
    justify-content: space-between;

    flex-direction: ${(props) => (props.type === "apikey" ? "column" : "row")};
  `)};
`;

// const Button = styled.button`
//   width: min(max(7.0833vw, 100px), 135px);
//   border-radius: 60px;
//   border: 1px solid;
//   color: ${(props) => (props.type === "confirm" ? "#fcfcfc" : "#151944")};
//   background-color: ${(props) =>
//     props.type === "confirm" ? (props.disabled ? "#d9d9d9" : "#151944") : ""};
//   border-color: ${(props) => (props.disabled ? "#d9d9d9" : "#151944")};
// `;

const ButtonText = styled.div`
  padding: 0.52vw 1.3vw;
  text-align: center;
  font-size: min(max(1.0416vw, 18px), 20px);

  ${RWD_LG(css`
    padding: 6px 15px;
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const ErrorMsgWrapper = styled.div`
  padding: 10px 0;
  color: #f05d2f;
`;

const InputContainer = styled.div`
  display: flex;
  background: #d9d9d9;
  border-radius: 100px;
`;

const QrcodeContainer = styled.div`
  padding: 20px 0;
  margin: auto;

  /* ${RWD_SM(css`
    padding: 4vw 0;
  `)}; */
`;

const QrcodeBtn = styled.div`
  color: #151944;
  white-space: nowrap;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: min(max(1.0416vw, 14px), 20px);
  padding-right: 10px;
`;

const UpdateApiKeyWrapper = styled.div`
  background: #151944;
  padding: 10px 0;
  /* padding: 0.833vw; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  ${RWD_MD(css`
    padding: 5px;
    margin-bottom: 10px;
  `)};
`;

const UpdateApiKeyIcon = styled.div`
  max-width: 200px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    max-width: 200px;
    height: 40px;
    /* object-fit: contain; */
  }

  ${RWD_MD(css`
    height: 25px;

    svg {
      height: 25px;
      /* object-fit: contain; */
    }
  `)};
`;

const UpdateApiKeyInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const NoticeWrapper = styled.div`
  /* border: 1px solid red; */
  color: #151944;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  font-size: min(max(0.9375vw, 16px), 18px);

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 14px), 16px);
  `)};

  ${RWD_SM(css`
    align-items: flex-start;
  `)};
`;

const NoticeIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;

  padding-top: 1px;
  display: flex;
  margin-right: 5px;

  svg {
    width: 20px;
    height: 20px;
  }

  & path {
    stroke: #151944;
  }
`;

const AuthWrapper = styled.div`
  color: #151944;
  cursor: pointer;
  font-size: min(max(0.9375vw, 16px), 18px);
  margin-right: auto;

  ${RWD_SM(css`
    padding-bottom: 10px;
  `)};
`;

const ButtonContainer = styled.div`
  ${RWD_SM(css`
    width: 100%;
    display: flex;
    justify-content: space-between;
  `)};
  /* border: 1px solid red; */
`;

const TgNoticeWrapper = styled.div`
  color: #151944;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  font-size: min(max(0.9375vw, 16px), 18px);

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 14px), 16px);
  `)};

  ${RWD_SM(css`
    align-items: flex-start;
  `)};
`;

const AddApiKeyTooltipWrapper = styled.div`
  color: #c0c0c0;
  font-weight: lighter;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: min(max(0.9375vw, 16px), 18px);
  cursor: pointer;
  gap: 5px;
`;

const AddApiKeyTooltipContainer = styled.div`
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;

  padding-top: 1px;
  display: flex;

  svg {
    width: 20px;
    height: 20px;
  }

  & path {
    stroke: #c0c0c0;
  }
`;

const AddApiKeyTooltipText = styled.span`
  transition: all 0.3s linear;
  border-bottom: 1px solid transparent;

  ${AddApiKeyTooltipWrapper} &:hover {
    border-bottom: 1px solid #c0c0c0;
  }
`;

const AddApiKeyTooltipTextUrl = styled.span`
  visibility: hidden;
  width: auto;
  background-color: #54587b;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 0.833vw;
  white-space: nowrap;
  position: absolute;
  left: calc(1.3vw + 15px);
  z-index: 1;
`;

const TelegramVerifyModal = ({
  setVerifyCode,
  verifyCode,
  setTgVerifyModalIsOpen,
  handleVerifyTelegram,
}) => {
  const tgUrl = `${process.env.REACT_APP_apiv2Host}/telegram/callback`;
  const verifiedUrl = `https://t.me/${
    process.env.REACT_APP_telegramBotName
  }?start=login&redirect_url=${encodeURIComponent(tgUrl)}`;

  const handleSendVerifyCode = () => {
    window.location.href = verifiedUrl;
  };

  const handleClose = () => {
    setTgVerifyModalIsOpen(false);
  };

  return (
    <Wrapper>
      <Container>
        <Title>Verify Telegram ID</Title>
        <QrcodeContainer>
          <QRCodeCanvas value={verifiedUrl} bgColor={"transparent"} />
        </QrcodeContainer>
        <InputWrapper>
          <SubTitle>Input Verification Code</SubTitle>
          <InputContainer>
            <Input onChange={(e) => setVerifyCode(e.target.value)}></Input>
            <QrcodeBtn onClick={handleSendVerifyCode}>
              <ButtonText>
                <Lang datakey="get_code" defaultText="Get Code" />
              </ButtonText>
            </QrcodeBtn>
          </InputContainer>
        </InputWrapper>
        <ButtonWrapper>
          <ButtonContainer>
            <Button onClickFun={handleClose} type={"cancel"} />

            <Button
              onClickFun={handleVerifyTelegram}
              type={"confirm"}
              disabled={verifyCode == ""}
            />
          </ButtonContainer>
        </ButtonWrapper>
      </Container>
    </Wrapper>
  );
};

export default TelegramVerifyModal;
