import styled from "styled-components";
import { useState, useEffect } from "react";
import { API_Exchange, API_DepositAVAPoint } from "../../../utils/api";
import Lang from "../../global/Lang";
import Icons from "../../global/icons";
import { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";

// sweetAlert
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { SuccessMySwal, ErrorMySwal } from "../../global/MySwal";
import { LangString } from "../../utils";
import { getLanguage } from "../../services/common";

import {
  useGetAvaPointDepoistHistoryQuery,
  useSetAvaPointTxidMutation,
} from "../../services/avaPointApi";

import { useGetAccountProfileQuery } from "../../services/accountApi";
import { useDispatch } from "react-redux";
import { setAccountProfile } from "../../slices/accountSlice";

import { dateTimeFormat } from "../../utils";
import { IoMic } from "react-icons/io5";

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  color: #151944;
`;

const Container = styled.div`
  background-color: #fcfcfc;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 40px;
  width: 80vw;
  max-width: 850px;
  border-radius: 8px;
  position: relative;

  ${RWD_LG(css`
    width: 80%;
  `)};

  ${RWD_MD(css`
    width: 90%;
    /* padding: 20px 40px; */
  `)};

  ${RWD_SM(css`
    padding: 20px;
  `)};
`;

const Title = styled.div`
  color: #151944;
  font-size: min(max(1.5625vw, 28px), 30px);
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;

  ${RWD_MD(css`
    font-size: min(max(1.0416vw, 18px), 20px);
  `)};
`;

const TableWrapper = styled.div`
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #151944;
  color: #fcfcfc;
  position: sticky;
  top: 0;

  ${RWD_MD(css`
    padding: 5px 0;
    min-width: 600px;
  `)};
`;

const Th = styled.div`
  font-weight: 400;
  text-align: center;
  width: 100%;
`;

const ListWrapper = styled.div`
  position: relative;
  font-size: min(max(0.833vw, 14px), 16px);

  ${RWD_MD(css`
    min-width: 930px;
  `)};
`;

const ListBody = styled.div`
  /* border: 1px solid red; */
`;

const ListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => (props.index % 2 ? "#fcfcfc" : "#D9D9D9")};
`;

const ListItem = styled.div`
  padding: 5px 5px;
  text-align: center;
`;

const ListItemText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
`;

const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Input = styled.input`
  /* background: #d9d9d9; */
  padding: 5px 10px;
  width: 73%;
  color: #151944;
  border: 1px solid #ccc;
  font-size: min(max(0.833vw, 12px), 15px);
`;

const Nodata = styled.div`
  font-size: min(max(1.0416vw, 18px), 20px);
  padding: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ConfirmWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
`;

const ConfirmButton = styled.button`
  padding: 5px 5px;
  background-color: ${(props) => (props.disabled ? "#ccc" : "#151944")};
  border-color: ${(props) => (props.disabled ? "#ccc" : "#151944")};
  color: #fcfcfc;
  width: 27%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: min(max(0.833vw, 13px), 15px);
  white-space: nowrap;
  border-width: 1px;
`;

const CloseIcon = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
`;

const TabWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TabItem = styled.div`
  width: 120px;
  border: 1px solid #151944;
  text-align: center;
  padding: 0.5vw;
  border-radius: 8px 8px 0 0;
  border-bottom: ${(props) => (props.activeTab ? "#151944" : "#d9d9d9")};
  background: ${(props) => (props.activeTab ? "#151944" : "")};
  color: ${(props) => (props.activeTab ? "#fcfcfc" : "#151944")};
  cursor: pointer;
  margin-right: 5px;
  font-size: min(max(0.833vw, 14px), 16px);

  ${RWD_MD(css`
    width: 120px;
  `)};
`;

const ListBox = styled.div`
  border: 1px solid #151944;
  overflow: auto;
  height: 60vh;

  ${RWD_MD(css`
    max-height: 60vh;
  `)};
`;

const History = ({ setOpen }) => {
  const dispatch = useDispatch();
  const [dataList, setDataList] = useState([]);
  const [TXID, setTXID] = useState("");
  const [activeTab, setActiveStep] = useState("All");

  const isValid = TXID.trim() !== "";

  const { data, isError, error, isSuccess, refetch } =
    useGetAvaPointDepoistHistoryQuery();

  useEffect(() => {
    // 每次進入頁面都重新刷新History
    refetch();
  }, []);

  const {
    data: accountData,
    error: errorAccountData,
    isError: isErrorAccountData,
    isSuccess: isSuccessAccountData,
    refetch: accountDataRefetch,
    isFetching,
  } = useGetAccountProfileQuery();

  useEffect(() => {
    if (isSuccessAccountData) {
      dispatch(setAccountProfile(accountData));
    }

    if (isErrorAccountData) {
      console.log(errorAccountData.data.Message);
    }
  }, [accountData, isErrorAccountData, isFetching]);

  useEffect(() => {
    if (isSuccess) {
      setDataList(data.History);
    }
    if (isError) {
      console.log(error.data.Message);
    }
  }, [data, isSuccess]);

  const [
    _setAvaPointTxid,
    {
      data: setAvaPointTxidData,
      error: setAvaPointTxidError,
      isError: setAvaPointTxidIsError,
      isSuccess: setAvaPointTxidIsSuccess,
    },
  ] = useSetAvaPointTxidMutation();

  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (setAvaPointTxidIsSuccess) {
      // 多國語言
      const title = LangString("alert_update_success", "Update successful");
      const confirmText = LangString("confirm", "Confirm");

      SuccessMySwal({
        title: title,
        showCancelButton: false,
        confirmButtonText: confirmText,
      }).then((result) => {
        if (result.isConfirmed) {
          setOpen(false);
          accountDataRefetch();
        }
      });
    }
    if (setAvaPointTxidIsError) {
      ErrorMySwal({
        title:
          setAvaPointTxidError?.data?.ResultLanguages[getLanguage()] ||
          setAvaPointTxidError?.data?.Message,
      });
    }
  }, [setAvaPointTxidData, setAvaPointTxidIsSuccess]);

  const handleSetTxid = async (id) => {
    await _setAvaPointTxid({
      body: {
        DepositId: id,
        Txid: TXID,
      },
    });
  };

  const tableHeader = [
    {
      id: 1,
      name: "Date",
      value: "CreateTime",
      width: "15%",
    },
    {
      id: 2,
      name: "Amount",
      value: "Amount",
      width: "15%",
    },
    {
      id: 3,
      name: "TXID",
      value: "Txid",
      width: "20%",
    },
    {
      id: 4,
      name: "Status",
      value: "Status",
      width: "10%",
    },
    {
      id: 5,
      name: "Description",
      value: "Description",
      width: "35%",
    },
  ];

  const tabStatus = ["All", "Deposit", "Withdrawal"];

  return (
    <Wrapper>
      <Container>
        <Title>
          <Lang
            datakey="transaction_history"
            defaultText="Transaction History"
          />
        </Title>
        <CloseIcon onClick={handleCancel}>
          <Icons.Close />
        </CloseIcon>
        {dataList.length > 0 ? (
          <DataWrapper>
            <TabWrapper>
              {tabStatus.map((data) => (
                <TabItem
                  key={data}
                  activeTab={activeTab === data}
                  onClick={() => setActiveStep(data)}
                >
                  {data}
                </TabItem>
              ))}
            </TabWrapper>
            {(() => {
              switch (activeTab) {
                case "Deposit":
                case "Withdrawal":
                  return (
                    <ListBox>
                      <ListWrapper>
                        <TableWrapper>
                          {tableHeader.map((item) => (
                            <Th
                              key={item.id}
                              style={{ width: `${item.width}` }}
                            >
                              {item.name}
                            </Th>
                          ))}
                        </TableWrapper>

                        {dataList.length > 0 && (
                          <ListBody>
                            {dataList
                              .filter((type) => type.Type === activeTab)
                              .map((item, index) => (
                                <ListContainer key={item.Id} index={index + 1}>
                                  {tableHeader.map((el) => (
                                    <ListItem
                                      style={{ width: `${el.width}` }}
                                      key={el.id}
                                    >
                                      {(() => {
                                        switch (el.value) {
                                          case "Txid":
                                            switch (item.Status.toLowerCase()) {
                                              case "review":
                                                if (item.Type === "Deposit") {
                                                  return (
                                                    <ConfirmWrapper>
                                                      <Input
                                                        placeholder="輸入TXID"
                                                        onChange={(e) =>
                                                          setTXID(
                                                            e.target.value
                                                          )
                                                        }
                                                      ></Input>
                                                      <ConfirmButton
                                                        disabled={!isValid}
                                                        onClick={() =>
                                                          handleSetTxid(item.Id)
                                                        }
                                                      >
                                                        <Lang
                                                          datakey="confirm"
                                                          defaultText="Confirm"
                                                        />
                                                      </ConfirmButton>
                                                    </ConfirmWrapper>
                                                  );
                                                }

                                              default:
                                                return (
                                                  <ListItemText>
                                                    {item[el.value]}
                                                  </ListItemText>
                                                );
                                            }

                                          case "CreateTime":
                                            return item[el.value];

                                          default:
                                            return (
                                              <ListItemText>
                                                {item[el.value]}
                                              </ListItemText>
                                            );
                                        }
                                      })()}
                                    </ListItem>
                                  ))}
                                </ListContainer>
                              ))}
                          </ListBody>
                        )}
                      </ListWrapper>
                    </ListBox>
                  );

                // case "Membership":
                // case "Mining":
                //   return (
                //     <ListBox>
                //       <Nodata>
                //         <Lang
                //           datakey="nodata"
                //           defaultText="No data available"
                //         />
                //       </Nodata>
                //     </ListBox>
                //   );

                default:
                  return (
                    <ListBox>
                      <ListWrapper>
                        <TableWrapper>
                          {tableHeader.map((item) => (
                            <Th
                              key={item.id}
                              style={{ width: `${item.width}` }}
                            >
                              {item.name}
                            </Th>
                          ))}
                        </TableWrapper>

                        {dataList.length > 0 && (
                          <ListBody>
                            {dataList.map((item, index) => (
                              <ListContainer key={item.Id} index={index + 1}>
                                {tableHeader.map((el) => (
                                  <ListItem
                                    style={{ width: `${el.width}` }}
                                    key={el.id}
                                  >
                                    {(() => {
                                      switch (el.value) {
                                        case "Txid":
                                          switch (item.Status.toLowerCase()) {
                                            case "review":
                                              if (item.Type === "Deposit") {
                                                return (
                                                  <ConfirmWrapper>
                                                    <Input
                                                      placeholder="輸入TXID"
                                                      onChange={(e) =>
                                                        setTXID(e.target.value)
                                                      }
                                                    ></Input>
                                                    <ConfirmButton
                                                      disabled={!isValid}
                                                      onClick={() =>
                                                        handleSetTxid(item.Id)
                                                      }
                                                    >
                                                      <Lang
                                                        datakey="confirm"
                                                        defaultText="Confirm"
                                                      />
                                                    </ConfirmButton>
                                                  </ConfirmWrapper>
                                                );
                                              }

                                            default:
                                              return (
                                                <ListItemText>
                                                  {item[el.value]}
                                                </ListItemText>
                                              );
                                          }

                                        case "CreateTime":
                                          return item[el.value];

                                        default:
                                          return (
                                            <ListItemText>
                                              {item[el.value]}
                                            </ListItemText>
                                          );
                                      }
                                    })()}
                                  </ListItem>
                                ))}
                              </ListContainer>
                            ))}
                          </ListBody>
                        )}
                      </ListWrapper>
                    </ListBox>
                  );
              }
            })()}
          </DataWrapper>
        ) : (
          <Nodata>
            <Lang datakey="nodata" defaultText="No data available" />
          </Nodata>
        )}
      </Container>
    </Wrapper>
  );
};

export default History;
