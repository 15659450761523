import styled, { css } from "styled-components";
import Icons from "../../global/icons";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";

import React, { useEffect, useState, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Lang from "../../global/Lang";

import * as d3 from "d3";

import StackedBarChart from "./StackedBarChart";

import ChartDataLabels from "chartjs-plugin-datalabels";

import HorizontalStackedBarChart from "./HorizontalStackedBarChart";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 20px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  /* border: 1px solid red; */
`;

const PeriodMinedWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 10px;
  justify-content: flex-end;
  cursor: pointer;
  width: 100%;
  position: absolute;
  top: 0;
`;

const PeriodMinedIcon = styled.div`
  width: 15px;
  height: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  & path {
    fill: ${(props) => (props.isShow ? "#151944" : "#ccc")};
  }

  svg {
    width: 15px;
    height: 15px;
  }
`;

const PeriodMinedText = styled.div`
  padding-left: 5px;
  font-size: min(max(0.9375vw, 12px), 18px);
  color: ${(props) => (props.isShow ? "#151944" : "#ccc")};
`;

const DepositTokenWrapper = styled.div`
  width: 100%;
  font-size: min(max(0.9375vw, 12px), 18px);

  border: 1px solid #151944;
  border-bottom: 0px;
  border-right: 0px;
`;

const DepositTokenTitle = styled.div`
  width: 100%;
  background: #151944;
  color: #fcfcfc;
  display: flex;
  padding: 2px 0 2px 5px;
`;

const DepositTokenContainer = styled.div`
  width: 100%;
  display: grid;

  justify-content: flex-start;
  align-items: center;

  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

  ${RWD_LG(css`
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  `)};
`;

const DepositToken = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #151944;
  border-right: 1px solid #151944;
`;

const DepositTokenValue = styled.div`
  color: #151944;
  width: 60%;

  text-align: end;
  padding-right: 10px;
`;

const DepositTokenTarget = styled.div`
  color: #3a3d5a;
  width: 40%;
  text-align: center;
  border-right: 1px solid #151944;
`;

const ChartWrapper = styled.div`
  display: flex;
  padding-top: 10px;
  width: 100%;
  position: relative;
`;

const MiningBarChartTest = ({
  chartData,
  date,
  selectDate,
  initData,
  transferUsdt,
}) => {
  const [indexDay, setIndexDay] = useState("");

  const [isShow, setIsShow] = useState(true);

  useEffect(() => {
    if (selectDate == "") {
      setIndexDay(date.length - 1);
    } else {
      setIndexDay(date.findIndex((day) => day === selectDate));
    }
  }, [selectDate]);

  const bg = ["#151944", "transparent", "#0aecd1", "transparent"];
  const align = ["bottom", "bottom", "bottom", "bottom"];
  const anchor = ["center", "center", "center", "end"];
  const offset = ["15", "15", "15", "15"];

  const options = {
    responsive: true,

    animation: {
      duration: 0,
    },

    indexAxis: "y",
    scales: {
      x: {
        // stacked: true,
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return `${value} % `;
          },
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          crossAlign: "far",
          padding: 10,
        },
      },
    },

    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        align: (value) => {
          return align[value.datasetIndex];
        },
        anchor: (value) => {
          return anchor[value.datasetIndex];
        },
        offset: (value) => {
          return offset[value.datasetIndex];
        },
        color: (value) => {
          return bg[value.datasetIndex];
        },
        font: {
          size: 12,
        },
        formatter: (value, i) => {
          let period;

          if (typeof value === "object") {
            if (i.dataset?.count[i.dataIndex][0] === 0) {
              return i.dataset?.count[i.dataIndex][1].toFixed(10) == 0
                ? ""
                : isShow
                ? i.dataset?.count[i.dataIndex][1].toFixed(10)
                : profit.map((data) => data.Total)[i.dataIndex];
            } else {
              period = (
                i.dataset?.count[i.dataIndex][1] -
                i.dataset?.count[i.dataIndex][0]
              ).toFixed(10);

              return isShow
                ? `${(
                    i.dataset?.count[i.dataIndex][1] -
                    i.dataset?.count[i.dataIndex][0]
                  ).toFixed(10)}
                  (+${(
                    ((
                      i.dataset?.count[i.dataIndex][1] -
                      i.dataset?.count[i.dataIndex][0]
                    ).toFixed(10) /
                      initData[i.dataIndex]?.Amount) *
                    100
                  ).toFixed(2)}%)`
                : "";
            }
          } else {
            return profit.map((data) => data.Total)[i.dataIndex];
          }
          // 在這裡進行自訂，例如可以返回數值 value 或其他資料
          // return `${value?.toFixed(2)}`;
        },
        // backgroundColor: (value) => {
        //   return bgColor[value.datasetIndex];
        // },
      },
    },
  };

  // 加上總收益 profit 加起來

  const profit = chartData.map((data) => {
    return {
      Date: date,
      Target: data.Target,
      Total: data.Profits.map((item) => item)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
        .toFixed(6),
      TotalProfitPercent: data.ProfitPercent.map((item) => item * 100).reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      ),
      Profit: data.Profits.map((profit) => profit),
      ProfitPercent: data.ProfitPercent.map((profit) => profit * 100),
    };
  });

  const nowSum = profit
    .map((data) => data.ProfitPercent)
    .map((el) =>
      el
        .slice(0, indexDay)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    );

  const period = profit.map((data) => data.ProfitPercent[indexDay]);
  const total = profit.map((data) => data.TotalProfitPercent);

  const nowSumCount = profit
    .map((data) => data.Profit)
    .map((el) =>
      el
        .slice(0, indexDay)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    );

  const periodCount = profit.map((data) => data.Profit[indexDay]);

  // 全部 - 當前日期加總值 - 當前數值
  const calculator = profit.map((data, index) =>
    Number(total[index] - nowSum[index] - period[index]) < 0
      ? 0
      : Number(total[index] - nowSum[index] - period[index])
  );

  const data = {
    labels: chartData.map((data) => data.Target),

    datasets: [
      {
        backgroundColor: "rgba(21, 25, 68, 0.05)",
        data: total.map((data) => data.toFixed(2)),
        grouped: false,
        order: 1,
        // categoryPercentage: 0.9,
        barPercentage: 1,
      },
      {
        backgroundColor: "#151944",
        data: nowSum.map((data, index) =>
          isShow ? [0, data] : [0, data + period[index]]
        ),
        count: nowSumCount.map((data, index) =>
          isShow ? [0, data] : [0, data + period[index]]
        ),
        categoryPercentage: 0.3,
        barThickness: 30,
      },
      {
        backgroundColor: isShow ? "#0aecd1" : "#151944",
        data: period.map((data, index) => [
          nowSum[index],
          nowSum[index] + data,
        ]),
        count: periodCount.map((data, index) => [
          nowSumCount[index],
          nowSumCount[index] + data,
        ]),
        categoryPercentage: 0.3,
        barThickness: 30,
      },
      {
        backgroundColor: "#151944",
        data: period.map((data, index) => [nowSum[index] + data, total[index]]),
        count: profit.map((data) => data.Total),
        categoryPercentage: 0.3,
        barThickness: 30,
      },
    ],
  };

  return (
    <Wrapper>
      <Container>
        <DepositTokenWrapper>
          <DepositTokenTitle>
            <Lang datakey="deposit_token" defaultText="Deposit Token" />
          </DepositTokenTitle>
          <DepositTokenContainer>
            {initData.map((data) => (
              <DepositToken>
                <DepositTokenTarget>{data.Target}</DepositTokenTarget>
                <DepositTokenValue>{data.Amount}</DepositTokenValue>
              </DepositToken>
            ))}
          </DepositTokenContainer>
        </DepositTokenWrapper>

        <ChartWrapper>
          <PeriodMinedWrapper onClick={() => setIsShow(!isShow)}>
            <PeriodMinedIcon isShow={isShow}>
              <Icons.Check />
            </PeriodMinedIcon>
            <PeriodMinedText isShow={isShow}>
              <Lang datakey="period_mined" defaultText="Period Mined" />
            </PeriodMinedText>
          </PeriodMinedWrapper>
          <Bar options={options} data={data} plugins={[ChartDataLabels]} />
        </ChartWrapper>
      </Container>
    </Wrapper>
  );
};

export default MiningBarChartTest;
