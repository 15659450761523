import styled, { css } from "styled-components";
import Intro_1 from "../../../images/intro_1.svg";
import Intro_2 from "../../../images/intro_2.svg";
import Intro_3 from "../../../images/intro_3.svg";
import { RWD_XL, RWD_LG, RWD_MD, RWD_SM } from "../../../constants/rwd";
import parse from "html-react-parser";
import { getLanguage } from "../../../v2/services/common";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 7.8125vw 0;
`;

const Container = styled.div`
  display: flex;
  width: 81.88vw;
`;

const TitleWrapper = styled.div`
  color: ${(props) => props.theme.colors.White};
  padding: clamp(80px, 8.333vw, 160px) 0 clamp(30px, 2.604167vw, 50px) 0;
`;

const Title = styled.h2`
  margin: 0;
  font-size: clamp(30px, 2.0833vw, 40px);
  font-weight: 500;

  ${RWD_MD(css`
    font-size: clamp(20px, 4.557vw, 35px);
  `)};
`;

const ReasonBoxWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;

  ${RWD_MD(css`
    grid-template-columns: repeat(1, 1fr);
    gap: 80px;
  `)};

  ${RWD_SM(css`
    gap: 40px;
  `)};
`;

const ReasonBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => props.theme.colors.White};
`;

const ReasonTitle = styled.div`
  font-size: clamp(24px, 1.45833vw, 28px);
  font-weight: 500;
  padding: 30px 0 14px 0;
  text-align: center;

  ${RWD_MD(css`
    font-size: clamp(16px, 3.125vw, 24px);
    padding: 20px 0 5px 0;
  `)};
`;

const ReasonDescription = styled.p`
  color: ${(props) => props.theme.colors.Grey};
  text-align: center;
  font-size: clamp(16px, 1.041667vw, 20px);

  ${RWD_MD(css`
    font-size: clamp(14px, 2.864vw, 22px);
  `)};
`;

const ReasonIcon = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: clamp(100px, 6.25vw, 120px);

  ${RWD_MD(css`
    width: clamp(60px, 13.02vw, 100px);
  `)};
`;

const Reason = () => {
  const titleData = [
    {
      ch: "為什麼選擇我們的產品",
      en: "Why choose our product",
    },
  ];
  const reasonList = [
    {
      id: 1,
      src: Intro_1,
      title: [
        {
          ch: "低風險與分散資金管理",
          en: "Low Risk and Diversified Fund Management",
        },
      ],
      content: [
        {
          ch: "嚴守紀律，精準執行。<br>降低風險，避免交易所流動性不足風險。",
          en: "Strict discipline, precise execution.<br>Reduce risk, avoid the risk of exchange liquidity shortage.",
        },
      ],
    },
    {
      id: 2,
      src: Intro_2,
      title: [
        {
          ch: "優秀的團隊與策略",
          en: "Excellent Team and Strategy",
        },
      ],
      content: [
        {
          ch: "歷經牛熊轉換的千錘百鍊。<br>由資深投資專家規劃，確保獲利最大機會。",
          en: "Tempered through bull and bear cycles.<br>Planned by senior investment experts to ensure maximum profit opportunities.",
        },
      ],
    },
    {
      id: 3,
      src: Intro_3,
      title: [
        {
          ch: "佈局與未來展望",
          en: "Deployment and Future Outlook",
        },
      ],
      content: [
        {
          ch: "腳踏實地卻不拘泥現況。<br>技術與專業並進，佈局未來，搶得先機。",
          en: "Practical and forward-thinking.<br>Advancing both technology and expertise, positioning for the future to seize opportunities.",
        },
      ],
    },
  ];

  const templateHandler = (data) => {
    if (getLanguage()?.toLocaleLowerCase() === "ch") {
      return parse(data.ch);
    } else {
      return parse(data.en);
    }
  };

  return (
    <Wrapper>
      <TitleWrapper>
        <Title data-aos="fade-up" data-aos-duration="1000">
          {titleData[0][getLanguage()?.toLocaleLowerCase()]}
        </Title>
      </TitleWrapper>
      <Container>
        <ReasonBoxWrapper>
          {reasonList.map((data) => (
            <ReasonBox
              key={data.id}
              data-aos="flip-left"
              data-aos-duration="1000"
            >
              <ReasonIcon>
                <img
                  src={data.src}
                  alt={data.title[0][getLanguage()?.toLocaleLowerCase()]}
                />
              </ReasonIcon>
              <ReasonTitle>
                {data.title[0][getLanguage()?.toLocaleLowerCase()]}
              </ReasonTitle>
              <ReasonDescription>
                {templateHandler(data.content[0])}
              </ReasonDescription>
            </ReasonBox>
          ))}
        </ReasonBoxWrapper>
      </Container>
    </Wrapper>
  );
};

export default Reason;
