import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_apiv2Host}`,
});

export const landingApi = createApi({
  reducerPath: "landingApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getLandingAlgos: builder.query({
      query: () => ({
        url: "/landing/GetLandingAlgos",
        method: "GET",
      }),
    }),
    getBlockChainNewsPreview: builder.query({
      query: () => ({
        url: "/landing/GetBlockChainNewsPreview",
        method: "GET",
      }),
    }),
  }),
});

export const { useGetLandingAlgosQuery, useGetBlockChainNewsPreviewQuery } =
  landingApi;
