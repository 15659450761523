import styled from "styled-components";
import { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";
import { useEffect, useState, useRef, Fragment } from "react";

import Lang from "../../../global/Lang";
import { getLanguage } from "../../../services/common";
import { LangString } from "../../../utils";

import JoyrideComponent from "../../../global/JoyrideComponent";
import { useNavigate, useLocation } from "react-router-dom";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fcfcfc;
  height: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Title = styled.div`
  font-size: min(max(1.25vw, 22px), 24px);
  color: #fcfcfc;
  padding-bottom: 20px;
  align-self: flex-start;
  text-transform: capitalize;

  ${RWD_MD(css`
    font-size: min(max(1.0416vw, 18px), 20px);
  `)};
`;

const PrivacyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PrivacyContainer = styled.div`
  width: 80%;
  height: 550px;
  overflow: auto;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  border-radius: 8px;
  padding: 1.0416vw 1.5625vw;
  border: 1px solid rgba(255, 255, 255, 0.15);
  color: #fcfcfc;

  &::-webkit-scrollbar-track {
    border-radius: 0.125rem;
    background-color: #3a3d5a;
  }
  &::-webkit-scrollbar {
    width: 0.25rem;
    border-radius: 0.125rem;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0.125rem;
    background-color: rgba(255, 255, 255, 0.3);
  }

  ${RWD_LG(css`
    height: 70vh;
  `)};

  ${RWD_SM(css`
    width: 100%;
    height: 55vh;
    padding: 10px 15px;
  `)};
`;

const CheckboxWrapper = styled.label`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px 0;
`;

const CheckboxContainer = styled.div`
  font-size: min(max(0.833vw, 14px), 16px);
  padding-left: 10px;
`;

const CheckBox = styled.input.attrs({ type: "checkbox" })`
  accent-color: #3a3d5a;
  width: 20px;
  height: 20px;
  margin: 0;
`;

const PrivacyTitle = styled.div`
  font-size: min(max(1.0416vw, 18px), 20px);
  padding: 1.5vw 0;
  text-align: center;
`;

const PrivacyUlWrapper = styled.ul`
  list-style-type: decimal;
  list-style-position: inside;
`;

const PricacyUlContainer = styled.li``;

const PrivacyUl = styled.ul`
  list-style-type: circle;
  list-style-position: inside;
`;

const PricacyUlTitle = styled.ul`
  /* list-style-type: decimal;
  list-style-position: inside; */
`;

const PricacyTitleLi = styled.li``;

const PrivacyLi = styled.li`
  font-size: min(max(0.9375vw, 16px), 18px);
  /* padding: 8px 0; */
  padding: 8px 0 8px 8px;
  line-height: 1.5;
`;

const MySwalWrapper = styled.div``;

const MySwalContentWrapper = styled.div`
  padding: 0 0 30px 0;
  color: #22254a;
`;
const MySwalButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MySwalTitleWrapper = styled.div``;

const MySwalTitle = styled.div`
  font-weight: bold;
  font-size: min(max(1.5625vw, 28px), 30px);
`;

const MySwalTelegramWrapper = styled.div`
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 15px;
`;

const MySwalConfirmButton = styled.button`
  border: 1px solid #22254a;
  background-color: #22254a;
  padding: 10px 20px;
  border-radius: 0.25rem;
  color: #fcfcfc;
`;

const MySwalCancelButton = styled.button`
  border: 1px solid #22254a;
  padding: 10px 20px;
  border-radius: 0.25rem;
  color: #22254a;
  margin-right: 10px;
`;

const MySwalCheckboxContainer = styled.div`
  font-size: min(max(0.9375vw, 16px), 18px);
  padding-left: 10px;
`;

const MySwalCheckboxWrapper = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.verified ? "#151944" : "#D9D9D9")};
`;

const MySwalCheckBox = styled.input.attrs({ type: "checkbox" })`
  accent-color: #151944;
  width: 20px;
  height: 20px;
  margin: 0;
`;

const StepFour = ({ privacyChecked, setPrivacyChecked, setActiveStep }) => {
  const { search } = useLocation();

  const navigate = useNavigate();
  const handleSecondJoyrideCallback = () => {
    MySwal.close();
    navigate({
      pathname: "/v2/algo/",
      search: "?tutorial=algo",
    });
  };

  useEffect(() => {
    const query = new URLSearchParams(search);
    const tutorial = query.get("tutorial");
    if (tutorial === "algo") {
      setJoyrideIsRun(true);
    }
  }, [search]);

  const handleJoyrideCallback = () => {
    const title = LangString("alert_algo_active", "Activate Algo now?");
    const checkBoxText = LangString(
      "alert_tg_check",
      "Enable Telegram message notifications"
    );
    const confirmText = LangString("yes", "Yes");
    const cancelText = LangString("no", "No");

    MySwal.fire({
      showCloseButton: false,
      showConfirmButton: false,
      showCancelButton: false,
      confirmButtonColor: "#22254a",
      background: "#fcfcfc",
      backdrop: `rgba(11,11,11,0.8)`,
      icon: "question",
      allowOutsideClick: false,
      customClass: "createAlgo_Alert",

      html: (
        <MySwalWrapper>
          <MySwalContentWrapper>
            <MySwalTitleWrapper>
              <MySwalTitle>{title}</MySwalTitle>
            </MySwalTitleWrapper>

            <MySwalTelegramWrapper>
              <MySwalCheckboxWrapper>
                <MySwalCheckBox
                  type="checkbox"
                  id="tg"
                  disabled
                ></MySwalCheckBox>

                <MySwalCheckboxContainer>
                  {checkBoxText}
                </MySwalCheckboxContainer>
              </MySwalCheckboxWrapper>
            </MySwalTelegramWrapper>
          </MySwalContentWrapper>

          <Fragment>
            <MySwalButtonWrapper>
              <MySwalCancelButton>{cancelText}</MySwalCancelButton>
              <MySwalConfirmButton>{confirmText}</MySwalConfirmButton>
            </MySwalButtonWrapper>
          </Fragment>
        </MySwalWrapper>
      ),
    });
  };

  const [joyrideIsRun, setJoyrideIsRun] = useState(false);
  const joyrideState = [
    {
      target: ".createAlgo_CheckTerms",
      content: (
        <Lang
          datakey="algo_tutorial_4-1"
          defaultText="Please read and agree to the terms, then click the 'DONE' button to complete the creation."
        />
      ),
      placement: "top",
      disableBeacon: true,
      disableOverlayClose: true,
      callbackAction: true,
      function: handleJoyrideCallback,
    },
    {
      target: ".createAlgo_Alert",
      content: (
        <Lang
          datakey="algo_tutorial_4-2"
          defaultText="You can activate the Algo immediately, or activate it later in the 'Initial' tab under AlgoTrade Details."
        />
      ),
      placement: "right",
      disableBeacon: true,
      disableOverlayClose: true,
      callbackAction: true,
      function: handleSecondJoyrideCallback,
    },
  ];

  const [isBottom, setIsBottom] = useState(false);
  const dom = useRef(null);

  useEffect(() => {
    document.addEventListener("scroll", handleScroll, true);
    return () => {
      document.removeEventListener("scroll", handleScroll), true;
    };
  }, []);

  useEffect(() => {
    if (isBottom) {
      setPrivacyChecked(true);
    } else return;
  }, [isBottom]);

  const handleScroll = () => {
    const clientHeight = dom.current?.clientHeight;
    const scrollHeight = dom.current?.scrollHeight;
    const scrollTop = dom.current?.scrollTop;
    setIsBottom(clientHeight + scrollTop + 1 >= scrollHeight);

    // setPrivacyChecked(isBottom);
  };

  const terms = [
    {
      id: 1,
      title: [
        {
          Ch: "財務往來與轉帳授權",
          En: "Financial Transactions and Transfer Authorization",
        },
      ],
      Ch: "本服務運行絕不涉及與客戶之間的財務往來，亦未獲得任何形式的轉帳授權。服務費用僅透過 AVA Point收取，此為獲利手續費的唯一方式。任何關於本金顯示的問題應被視為單純的顯示問 題。本服務不持有任何資金。客戶應以其帳戶內顯示的金額為準確金額依據。",
      En: "This service does not involve any financial transactions with customers and has not been granted any form of transfer authorization. Service fees are solely collected through AVA Points, which is the only method for profit charges. Any issues related to the display of principal should be considered purely display issues. This service does not hold any funds. Customers should base their accurate amounts on the balance displayed in their accounts.",
    },
    {
      id: 2,
      title: [
        {
          Ch: "Algo啟動與交易",
          En: "Algo Activation and Trading",
        },
      ],
      Ch: "啟動Algo代表用戶同意允許程式透過運算自動交易用戶所設定之額度。Algo為自動交易之服 務，所有盈虧由使用者自行承擔。",
      En: "Activating Algo signifies the user's agreement to allow the program to automatically trade the amount set by the user through calculations. Algo is a service for automatic trading, and all profits and losses are borne by the user.",
    },
    {
      id: 3,
      title: [
        {
          Ch: "Algo產生獲利時收費",
          En: "Fees on Profits Generated by Algo",
        },
      ],
      Ch: "若Algo產生獲利，將收取20%的AVA POINT作為系統手續費(詳見收費規則)。",
      En: "If Algo generates profit, a 20% AVA POINT will be charged as a system handling fee (refer to fee rules for details).",
    },
    {
      id: 4,
      title: [
        {
          Ch: "Algo執行異常情況處理",
          En: "Handling of Algo Execution Anomalies",
        },
      ],
      Ch: "若Algo執行中發生餘額異常、API KEY失效或用戶手動操作等異常情況，將強制暫停所有在執 行中的Algo，並即時平倉所有交易，依規定結算所有收費。",
      En: "If anomalies such as balance irregularities, invalid API KEYS, or manual user operations occur during Algo execution, all Algos in operation will be forcibly paused, all trades will be immediately closed, and all fees will be settled according to regulations.",
    },
    {
      id: 5,
      title: [
        {
          Ch: "Algo手動暫停與費用結算",
          En: "Manual Pause of Algo and Fee Settlement",
        },
      ],
      Ch: "若Algo經使用者手動暫停，將依實時行情及訂單記錄結算所有費用。所有Algo的交易皆可通過 交易歷史紀錄查閱。",
      En: "If Algo is manually paused by the user, all fees will be settled based on real-time market conditions and order records. All Algo trades can be viewed through the transaction history record.",
    },
    {
      id: 6,
      title: [
        {
          Ch: "收費規則",
          En: "Fee Rules",
        },
      ],
      Ch: "收費方式一率為收取AVA POINT，1 AVA POINT = 1 USDT。啟動任何Algo時，帳戶需至少有 100 AVA POINT。若啟動的Algo總額超過5000 USDT，則需至少有運行總額5%的AVA POINT。 若累計獲利超過初始金額，則會扣除該筆交易收益的20% AVA POINT作為手續費。每個Algo的 收費計算獨立，彼此不影響。",
      En: "The fee is uniformly charged in AVA POINTS, where 1 AVA POINT = 1 USDT. To activate any Algo, the account must have at least 100 AVA POINTS. If the total amount activated in Algo exceeds 5000 USDT, at least 5% of the total operation amount in AVA POINTS is required. If cumulative profits exceed the initial amount, 20% of the profit from that transaction will be deducted as a handling fee in AVA POINTS. Each Algo's fee calculation is independent and does not affect each other.",
    },
    {
      id: 7,
      title: [
        {
          Ch: "Algo停止與費用結算",
          En: "Algo Stoppage and Fee Settlement",
        },
      ],
      Ch: "Algo停止後將立即結清所有費用，爾後不能累計或保留或移轉獲利或虧損紀錄至下一個或別的 Algo計算。此停止可能因使用者手動操作或錯誤(例如餘額異常/API KEY異常/AVA POINT不 足)觸發。",
      En: "After Algo is stopped, all fees will be immediately settled, and profit or loss records cannot be accumulated, retained, or transferred to calculate for the next or another Algo. This stoppage may be triggered by user manual operation or errors (e.g., balance irregularities/API KEY anomalies/insufficient AVA POINTS).",
    },
    {
      id: 8,
      title: [
        {
          Ch: "AVAPoint最低需求與不足時的處理",
          En: "Minimum AVAPoint Requirements and Handling of Insufficiencies:",
        },
      ],
      Ch: "Algo運行中將即時運算AVA POINT是否滿足最低需求。若不足，則會強制暫停所有Algo，並依 規定結算所有收費。若AVA POINT結算後出現負值，儲值後會優先扣除。",
      En: "During the operation of Algo, the sufficiency of AVA POINTS will be calculated in real-time. If insufficient, all Algos will be forcibly paused, and all fees will be settled according to regulations. If a negative value occurs after AVA POINT settlement, it will be deducted first after top-up.",
    },
    {
      id: 9,
      title: [
        {
          Ch: "通知方式",
          En: "Notification Methods",
        },
      ],
      Ch: "關於API KEY逾期或其他重要通知，將透過Telegram及Email方式通知客戶。",
      En: "Customers will be notified via Telegram and Email about API KEY expiration or other important notifications.",
    },
    {
      id: 10,
      title: [
        {
          Ch: "退款政策",
          En: "Refund Policy",
        },
      ],
      Ch: "客戶可在AVA Point累計達到100點時提出退款申請。",
      En: "Customers can apply for a refund when AVA Points accumulate to 100 points.",
    },
    {
      id: 11,
      title: [
        {
          Ch: "記錄查閱",
          En: "Record Inquiry",
        },
      ],
      Ch: "所有收費皆可通過繳費記錄確認。",
      En: "All charges can be confirmed through the payment record.",
    },
  ];
  const rules = [
    {
      id: 1,
      Ch: "收費方式一率為收取AVA POINT做為支付方式，1 AVA POINT = 1 USDT。",
      En: "Fees are uniformly charged in AVA POINT as the payment method, where 1 AVA POINT = 1 USDT.",
    },
    {
      id: 2,
      Ch: "任何一個Algo 啟動執行時，該帳戶需至少有100 AVA POINT。若啟動的Algo總額超過5000USDT，則需至少有運行總額5%的AVA POINT。",
      En: "For any activated Algo, the account must have a minimum of 100 AVA POINT. If the total amount for the activated Algo exceeds 5000 USDT, at least 5% of the total running amount in AVA POINT is required.",
    },
    {
      id: 3,
      Ch: "任何一筆交易執行完畢（亦即經過一次的開倉和平倉流程）時，若累計的獲利超過初始金額，則會立即扣除該筆交易收益的20%之AVA POINT作為系統手續費。EX：初始資金為1000開始，結算一筆交易完成以後，資金餘額為1100,此時會收取(1100-1000)＊20% 共計20點的AVA POINT，若爾後又結算一筆交易，資金餘額為1050，此時不會在收取系統手續費，但若在下一筆交易使資金餘額變更為1200,此時會收取(1200-1100)＊20% 共計20點的AVA POINT。",
      En: "After the execution of any trade (i.e., after both opening and closing processes), if the accumulated profit exceeds the initial amount, 20% of the profit in AVA POINT will be immediately deducted as a system fee. For example, with an initial capital of 1000, if the balance becomes 1100 after a trade, a total of (1100-1000) * 20% = 20 AVA POINT will be charged. Subsequent trades will not incur this fee until the balance changes again. If the balance becomes 1200, (1200-1100) * 20% = 20 AVA POINT will be charged.",
    },
    {
      id: 4,
      Ch: "收取費用的計算每個Algo獨立，彼此不影響。EX：假設此為第一次結算，Algo A為 初始資金+200 / Algo B 初始資金-200， 此次結算應收取200*20% = 40 AVA POINT。",
      En: "The fee calculation is independent for each Algo and does not affect others. For example, assuming this is the first settlement, Algo A with initial capital +200 / Algo B with initial capital -200, the settlement fee would be 200 * 20% = 40 AVA POINT.",
    },
    {
      id: 5,
      Ch: "收Algo停止後當下即結清所有費用，爾後不能累計或保留或移轉獲利或虧損紀錄至下一個或別的Algo計算。此停止可能是使用者手動操作，也可能是因為錯誤例如餘額異常/API KEY異常/AVA POINT 不足讓系統介入停止。",
      En: "All fees will be settled immediately upon Algo's termination, and subsequent accumulation, retention, or transfer of profit or loss records to another Algo calculation is not allowed. Termination can occur due to user manual actions or errors such as balance anomalies, API KEY issues, or insufficient AVA POINT that necessitate system intervention.",
    },
    {
      id: 6,
      Ch: 'Algo運行中會即時運算AVA POINT是否滿足最低需求的條件（規則2），若當下計算點數不足，則用戶"所有在執行中的Algo都會強制暫停"，且即時平倉所有交易，並依規定結算所有收費，若AVA POINT結算後出現負值，儲值後會優先扣除。',
      En: `During Algo's operation, the availability of AVA POINT to meet the minimum requirements (Rule 2) will be assessed in real-time. If the points are insufficient at the moment of calculation, all "ongoing Algos will be forcibly paused", leading to an immediate closure of all trades, and all fees will be settled as specified. If a negative balance occurs after AVA POINT settlement, future deposits will be prioritized to cover it.`,
    },
    {
      id: 7,
      Ch: '若收取AVA POINT時發生點數不足之情形，用戶"所有在執行中的Algo都會強制暫停"，且即時平倉所有交易，並依規定結算所有收費，若AVA POINT結算後出現負值，儲值後會優先扣除。',
      En: 'If there is an insufficient point situation when collecting AVA POINTs, all "ongoing Algos will be forcibly paused", leading to an immediate closure of all trades, and all fees will be settled as specified. If a negative balance occurs after AVA POINT settlement, future deposits will be prioritized to cover it.',
    },
    {
      id: 8,
      Ch: "所有收費皆可通過繳費記錄確認。",
      En: "All fees can be confirmed through the payment records.",
    },
  ];

  return (
    <Wrapper>
      <JoyrideComponent
        joyrideState={joyrideState}
        run={joyrideIsRun}
        joyrideType={"algo"}
      />
      <Container className="createAlgo_CheckTerms">
        <Title>
          <Lang
            datakey="addalgo_terms_title"
            defaultText="Terms and Conditions of Use and Fee Structure"
          />
        </Title>
        <PrivacyWrapper>
          <PrivacyContainer ref={dom}>
            <PrivacyTitle>
              <Lang datakey="terms_title" defaultText="Terms Of Service" />
            </PrivacyTitle>
            <PrivacyUlWrapper>
              {terms.map((data, id) => (
                <PrivacyUl>
                  <PricacyUlTitle>
                    <PricacyTitleLi>
                      {id + 1}. {data.title[0][getLanguage()]}
                    </PricacyTitleLi>
                  </PricacyUlTitle>
                  <PrivacyLi key={id}>{data[getLanguage()]}</PrivacyLi>
                </PrivacyUl>
              ))}
            </PrivacyUlWrapper>

            {/* <PrivacyTitle>
              <Lang datakey="addalgo_fee" defaultText="Fee Structure" />
            </PrivacyTitle>

            <PrivacyUlWrapper>
              {rules.map((data, id) => (
                <PrivacyLi key={id}>{data[getLanguage()]}</PrivacyLi>
              ))}
            </PrivacyUlWrapper> */}
          </PrivacyContainer>
        </PrivacyWrapper>
        <CheckboxWrapper>
          <CheckBox
            type="checkbox"
            checked={privacyChecked}
            onChange={() => {}}
          ></CheckBox>
          <CheckboxContainer>
            <Lang
              datakey="addalgo_agree"
              defaultText="I have read and agree to the above terms and conditions"
            />
          </CheckboxContainer>
        </CheckboxWrapper>
      </Container>
    </Wrapper>
  );
};

export default StepFour;
