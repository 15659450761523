import { configureStore } from "@reduxjs/toolkit";
import { accountApi } from "../services/accountApi";
import { avaPointApi } from "../services/avaPointApi";
import { authApi } from "../services/authApi";
import { systemApi } from "../services/systemApi";
import { algoApi } from "../services/algoApi";
import { algoOrderApi } from "../services/algoOrderApi";
import { miningApi } from "../services/miningApi";
import { datacenterApi } from "../services/datacenterApi";
import { questApi } from "../services/questApi";
import { landingApi } from "../services/landingApi";
import { getNewsApi } from "../services/getNewsApi";

import loginReducer from "../slices/loginSlice";
import accountReducer from "../slices/accountSlice";
import systemReducer from "../slices/systemSlice";
import miningReducer from "../slices/miningSlice";

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [accountApi.reducerPath]: accountApi.reducer,
    [systemApi.reducerPath]: systemApi.reducer,
    [avaPointApi.reducerPath]: avaPointApi.reducer,
    [algoApi.reducerPath]: algoApi.reducer,
    [algoOrderApi.reducerPath]: algoOrderApi.reducer,
    [miningApi.reducerPath]: miningApi.reducer,
    [datacenterApi.reducerPath]: datacenterApi.reducer,
    [questApi.reducerPath]: questApi.reducer,
    [landingApi.reducerPath]: landingApi.reducer,
    [getNewsApi.reducerPath]: getNewsApi.reducer,

    login: loginReducer,
    account: accountReducer,
    system: systemReducer,
    mining: miningReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      authApi.middleware,
      accountApi.middleware,
      systemApi.middleware,
      avaPointApi.middleware,
      algoApi.middleware,
      algoOrderApi.middleware,
      miningApi.middleware,
      datacenterApi.middleware,
      questApi.middleware,
      landingApi.middleware,
      getNewsApi.middleware,
    ]),
});

export default store;
