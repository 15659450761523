import PackageChart from './PackageChart';

const TradingView = () => {
  return (
    <div className='w-full px-10 h-[92vh]'>
      <PackageChart />
      {/* <CloudChart market={"NASDAQ"} code={'AAPL'} /> */}
    </div>
  )
}

export default TradingView
