import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";

const baseQuery = fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_apiv2Host}`
});

export const getNewsApi = createApi({
    reducerPath: "getNewsApi",
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getNews: builder.query({
            query: (id) => ({
                url: `/DataCenter/GetBlockchainNewsDataById?id=${id}`,
                method: "GET"
            })
        })
    })
})

export const { useGetNewsQuery } = getNewsApi;
