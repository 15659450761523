import Cookies from "js-cookie";
import CryptoJS from "crypto-js";

const TOKEN_NAME = "avaToken_v2";
const LANGUAGE = "avaLanguage";
const LANGUAGE_DATA = "avaLanguageData";
const NOTIFICATION = "avaNotification";
const ONBOARDING = "avaOnboarding";
const TERMSOFUSER = "avaTermsOfUser";
const THEMEMODE = "theme";

export const setAuthToken = (token = "", expires_in = 604800) => {
  const expires = expires_in / 24 / 60 / 60; // default 7 days
  Cookies.set(TOKEN_NAME, token, { expires });
};

export const getAuthToken = () => {
  if (!localStorage.getItem("token")) Cookies.remove(TOKEN_NAME);
  return {
    token: Cookies.get(TOKEN_NAME),
  };
};

export const removeAuthToken = () => {
  localStorage.removeItem("token");
  return Cookies.remove(TOKEN_NAME);
};

export const setLanguage = (lang) => {
  localStorage.setItem(LANGUAGE, lang);
};

export const getLanguage = () => {
  return localStorage.getItem(LANGUAGE);
};

export const removeLanguage = () => {
  localStorage.removeItem(LANGUAGE);
};

export const setLanguageData = (langData) => {
  localStorage.setItem(LANGUAGE_DATA, langData);
};

export const getLanguageData = () => {
  return JSON.parse(localStorage.getItem(LANGUAGE_DATA));
};

export const removeLanguageData = () => {
  localStorage.removeItem(LANGUAGE_DATA);
};

export const setNotification = (status) => {
  localStorage.setItem(NOTIFICATION, status);
};

export const getNotification = () => {
  return localStorage.getItem(NOTIFICATION);
};

export const removeNotification = () => {
  localStorage.removeItem(NOTIFICATION);
};

export const setOnboarding = (status) => {
  localStorage.setItem(ONBOARDING, status);
};

export const getOnboarding = () => {
  return localStorage.getItem(ONBOARDING);
};

export const removeOnboarding = () => {
  localStorage.removeItem(ONBOARDING);
};

export const setTermsOfUser = (status) => {
  localStorage.setItem(TERMSOFUSER, status);
};

export const getTermsOfUser = () => {
  return localStorage.getItem(TERMSOFUSER);
};

export const removeTermsOfUser = () => {
  localStorage.removeItem(TERMSOFUSER);
};

export const makeUrlencoded = (data) => {
  var urlencoded = new URLSearchParams();
  Array.from(Object.keys(data)).map((key) => {
    urlencoded.append(key, data[key]);
  });
  return urlencoded;
};

export const makeBybitWebsocketAuthMessage = ({
  ApiKey = "",
  SecretKey = "",
}) => {
  if (!ApiKey || !SecretKey) return console.error("Params error");

  const expires = Date.now() + 10000;
  const message = `GET/realtime${expires}`;
  const signature = CryptoJS.HmacSHA256(
    message,
    CryptoJS.enc.Utf8.parse(SecretKey)
  ).toString();
  const authMessage = {
    op: "auth",
    args: [ApiKey, expires, signature],
  };
  return JSON.stringify(authMessage);
};

export const makeBinanceWebsocketAuthRequest = ({
  ApiKey = "",
  SecretKey = "",
  params = null,
}) => {
  if (!!params) {
    const _params = Object.keys(params)
      .sort()
      .map((key) => ({ [key]: params[key] }));
    console.log(_params);
  }

  // const expires = Date.now() + 10000;
  // const message = `GET/realtime${expires}`;
  // const signature = CryptoJS.HmacSHA256(message, CryptoJS.enc.Utf8.parse(SecretKey)).toString();
  // const authMessage = {
  //   op: 'auth',
  //   args: [ApiKey, expires, signature],
  // };
  // return JSON.stringify(authMessage);
};

export const removeThemeMode = () => {
  localStorage.removeItem(THEMEMODE);
};
