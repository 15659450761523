import { createApi } from "@reduxjs/toolkit/query/react";
import defaultBaseQuery from "./query/defaultBaseQueryV2";

export const systemApi = createApi({
  reducerPath: "systemApi",
  baseQuery: defaultBaseQuery,
  endpoints: (builder) => ({
    getDropdown: builder.query({
      query: () => ({
        url: "/system/getDropdown",
        method: "GET",
      }),
    }),
  }),
});

export const { useGetDropdownQuery } = systemApi;
