import Logo from "../../images/logo_light.png";

import pvImg from "../../images/pv.png";
import systemImg from "../../images/system.png";
import faqImg from "../../images/faq.png";

import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

// --- plugins ---
import styled from "styled-components";

import Slide from "./Component/Slide";
import Tab from "./Component/Tab";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

// icons
import { SlArrowUp } from "react-icons/sl";

// AOS
import AOS from "aos";
import "aos/dist/aos.css";

import SmoothScroll from "smooth-scroll";
import { Link as ScrollLink } from "react-scroll";

import { interpolate, animated, useSpring } from "react-spring";

// --- others
import useListAnimation from "./Hook/useListAnimation";
import { RWD_XL, RWD_LG, RWD_MD, RWD_SM } from "../../constants/rwd";

import { css } from "styled-components";

// --- styled components ---
const Wrapper = styled.div`
  font-family: "Poppins", "Noto Sans TC", sans-serif;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  background-image: linear-gradient(153.06deg, #5c1594 -0.31%, #210a5c 99.69%);
`;

const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 5;
  transition: all 0.3s;
  height: 65px;
  position: absolute;
  /* position: fixed; */
  /* background-image: linear-gradient(
    to bottom,
    #1e3c72 0%,
    #1e3c72 1%,
    #2a5298 100%
  ); */

  background-image: linear-gradient(to top, #5c1594 -0.31%, #210a5c 99.69%);

  transform: ${(props) =>
    props.showHeader === true ? "translateY(0%)" : "translateY(-100%)"};

  position: fixed;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  position: relative;

  ${RWD_LG(css`
    width: 90%;
  `)};

  ${RWD_SM(css`
    position: static;
    /* width: 100%; */
  `)};
`;

const MenuList = styled(animated.ul)`
  list-style: none;
  display: flex;
  padding: 0;
  transform-origin: left top;
  ${RWD_SM(css`
    position: absolute;
    flex-direction: column;
    margin: 0;
    left: 0;
    width: 100%;
    padding: 10vh 0;

    background-image: linear-gradient(
      to bottom,
      #1e3c72 0%,
      #1e3c72 1%,
      #2a5298 100%
    );

    background-image: linear-gradient(to top, #5c1594 -0.31%, #210a5c 99.69%);
    /* display: none; */
  `)};
`;

const MenuItem = styled.li`
  padding: 0 1vw;

  ${RWD_SM(css`
    padding: 6vw 5vw;
  `)};

  a {
    cursor: pointer;
    color: white;
    text-decoration: none;
    transition: all 0.3s;
  }

  &:hover {
    a {
      color: #48d6d2;
    }
  }
`;

const HamburgerMenuList = styled.div`
  flex-direction: column;
  align-items: center;
  height: 22px;
  width: 40px;
  padding: 5px 0 5px 15px;
  display: none;
  justify-content: space-between;
  cursor: pointer;
  z-index: 99;
  ${RWD_SM(css`
    display: flex;
  `)};
`;

const HamburgerMenuItem = styled.span`
  display: inline-block;
  width: 30px;
  background-color: #48d6d2;
  height: 2px;
  opacity: 1;
  transform-origin: center;
  transition: transform 0.4s ease-in-out, opacity 0.2s ease-in-out;

  &:nth-of-type(1) {
    transform-origin: top right;
    transform: ${(props) =>
      props.hamIsOpen
        ? "rotate(-45deg) translateY(0) translateX(0)"
        : "rotate(0) translateY(0) translateX(0)"};
  }

  :nth-of-type(2) {
    opacity: ${(props) => (props.hamIsOpen ? 0 : 1)};
  }

  &:nth-of-type(3) {
    transform-origin: bottom right;
    transform: ${(props) =>
      props.hamIsOpen
        ? "rotate(45deg) translateY(0) translateX(0)"
        : "rotate(0) translateY(0) translateX(0)"};
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  ${RWD_SM(css`
    position: static;
  `)};
  /* margin-right: auto; */
`;

const LogoContainer = styled.div`
  width: 65px;
`;

const color = "linear-gradient(-225deg, #2cd8d5 0%, #c5c1ff 56%, #ffbac3 100%)";

const backgroundColor = "linear-gradient(to bottom right, #ff4581, #4388dd)";

const Button = styled.div`
  /* border: 1px solid rebeccapurple; */
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #74ebd5 0%, #9face6 100%);
  background: linear-gradient(294.72deg, #ff4581 9.05%, #4388dd 79.28%);
  transition: all 0.5s;
  cursor: pointer;

  span {
    color: white;
    font-size: clamp(12px, 0.8vw, 18px);
    padding: 0.8vw 2vw;

    ${RWD_SM(css`
      padding: 2vw 4vw;
    `)};
  }

  &:hover {
    background: linear-gradient(to right, #9face6 0%, #74ebd5 100%);
  }
`;

const LoginButtonWrapper = styled.div`
  display: flex;
  position: absolute;
  right: 0;

  ${RWD_SM(css`
    margin-left: auto;
    position: static;
  `)};
`;

const LoginButton = styled.div`
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #74ebd5 0%, #9face6 100%);

  background: linear-gradient(294.72deg, #ff4581 9.05%, #4388dd 79.28%);
  transition: all 0.5s;
  cursor: pointer;
  max-height: 45px;
  margin-left: 1.5vw;

  ${RWD_SM(css`
    margin-left: 3vw;
  `)};

  span {
    color: white;
    font-size: clamp(12px, 0.8vw, 18px);
    padding: 0.5vw 1.5vw;

    ${RWD_SM(css`
      padding: 2vw 4vw;
    `)};
  }

  /* &:hover {
    background: linear-gradient(to right, #9face6 0%, #74ebd5 100%);
  } */
`;

const LanguageButton = styled.div`
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
  cursor: pointer;
  max-height: 45px;
  border: 2px solid #48d6d2;
  box-sizing: border-box;
  border: 2px solid transparent;
  border-radius: 30px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right, #5c1594, #5c1594),
    linear-gradient(294.72deg, #ff4581 9.05%, #4388dd 79.28%);

  a {
    color: white;
    font-size: clamp(12px, 0.8vw, 18px);
    padding: 0.5vw 1.5vw;
    text-decoration: none;

    ${RWD_SM(css`
      padding: 2vw 4vw;
    `)};
  }

  &:hover {
    color: #48d6d2;
  }
`;

const ButtonUnFill = styled.a`
  /* border: 1px solid rebeccapurple; */
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-image: linear-gradient(180deg, #2af598 0%, #009efd 100%);
  background-image: linear-gradient(to top, #37ecba 0%, #72afd3 100%); */

  cursor: pointer;
  box-sizing: border-box;
  text-decoration: none;

  border: 2px solid transparent;
  border-radius: 30px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right, #5c1594, #5c1594),
    linear-gradient(294.72deg, #ff4581 9.05%, #4388dd 79.28%);
  /* border: 4px solid transparent; */
  /* border-image: ${(props) => (props.fill ? "transparent" : `${color}`)}; */
  /* background-image: ${(props) =>
    props.fill ? `${color}` : "transparent"}; */

  span,
  a {
    color: white;
    font-size: clamp(12px, 0.8vw, 18px);
    transition: all 0.3s;
    padding: 0.8vw 2vw;

    ${RWD_SM(css`
      padding: 2vw 4vw;
    `)};
  }

  :hover {
    span,
    a {
      color: #48d6d2;
    }
  }

  /* &:hover {
    background: linear-gradient(to right, #9face6 0%, #74ebd5 100%);
  } */
`;

const Contaienr = styled.div`
  border: 1px solid yellow;
  width: 80vw;
`;

const PvSection = styled.section`
  display: flex;
  width: 80vw;
  min-height: 100vh;
  height: 100%;
  align-items: center;
  ${RWD_LG(css`
    width: 90%;
    flex-direction: column;
    justify-content: center;
  `)};

  ${RWD_SM(css`
    justify-content: space-evenly;
  `)};
`;

const PvContent = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  color: white;
  padding-right: 8vw;

  ${RWD_LG(css`
    width: 100%;
    padding-right: 0;
    margin-top: 65px;
  `)};
`;

const PvTitleWrapper = styled.div`
  padding-bottom: 5vh;

  ${RWD_LG(css`
    padding-bottom: 2vh;
    padding-top: 2vh;
  `)};
`;

const PvTitle = styled.h1`
  margin: 0;
  text-align: left;

  align-self: flex-start;
  display: flex;
  font-size: clamp(32px, 7.161vw, 55px);
`;

const PvSubTitle = styled.h4`
  margin: 0;
  text-align: left;
  color: #48d6d2;
  padding: 1vh 0;
  font-size: clamp(13.5px, 2vw, 25px);
`;

const PvDetail = styled.div`
  text-align: left;
  ${RWD_LG(css`
    font-size: clamp(13px, 2vw, 20px);
  `)};
`;

const PvImg = styled.div`
  width: 50%;
  animation: float 3s ease-out infinite;

  ${RWD_LG(css`
    width: 80%;
  `)};

  ${RWD_SM(css`
    width: 85%;
  `)};

  img {
    /* ${RWD_LG(css`
      padding-top: 5vh;
    `)}; */
  }
`;

const PvButtons = styled.div`
  display: flex;
  padding-top: 5vh;
  justify-content: flex-start;
  width: 100%;

  div:first-child {
    margin-right: 2vw;

    ${RWD_LG(css`
      margin-right: 4vw;
    `)};
  }

  ${RWD_LG(css`
    padding-bottom: 2vh;
  `)};
`;

const IntroductionSection = styled.section`
  width: 80%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${RWD_LG(css`
    justify-content: center;
    width: 90%;
  `)};
`;

const IntroductionTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IntroductionTitle = styled.h2`
  margin: 0;
  color: white;
`;

const IntroductionSubTitle = styled.h4`
  margin: 0;
  text-align: left;
  color: white;
  padding: 1vh 0;

  ${RWD_LG(css`
    text-align: center;
  `)};
`;

const IntroductionContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 5vh;

  ${RWD_LG(css`
    flex-direction: column-reverse;
  `)};
`;

const IntroductionImg = styled.div`
  width: 50%;
  animation: float 3s ease-out infinite;
  margin: auto;

  ${RWD_LG(css`
    width: 80%;
  `)};

  ${RWD_SM(css`
    width: 85%;
  `)};

  img {
    /* width: 80%; */
  }
`;

const IntroductionList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 35%;
  padding: 1vw;
  border: 1px solid royalblue;

  /* background-image: linear-gradient(
    to top,
    #1e3c72 0%,
    #1e3c72 1%,
    #2a5298 100%
  ); */

  background: gba(255, 255, 255, 0.2);
  height: auto;
  border-radius: 10px;

  ${RWD_LG(css`
    width: auto;
    margin-bottom: 5vh;
    padding: 4vw 3.5vw;
  `)};
`;
const IntroductionItem = styled.li`
  text-align: left;
  padding: 1vw;
  /* background-color: rgba(12, 52, 131, 0.2); */
  border-radius: 10px;
  transition: border 0.5s;
  cursor: pointer;
  display: flex;
  flex-direction: column;

  background: gba(255, 255, 255, 0.1);

  /* border: 1px solid rgba(72, 214, 210, 0.5); */
  box-sizing: border-box;
  margin-bottom: 1vw;
  border: ${(props) =>
    props.onclick ? "1px solid #FF4581" : "1px solid royalblue"};

  &:hover {
    border: 1px solid rgba(72, 214, 210, 0.5);
  }

  :last-child {
    margin-bottom: 0;
  }

  ${RWD_LG(css`
    padding: 2vw;
    margin-bottom: 2.5vw;
  `)};

  /* span {
    color: white;
    background: transparent;
  } */

  /* &:hover {
    span {
      background: linear-gradient(to right, #74ebd5 0%, #9face6 100%);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
    }
  } */

  /* background-image: linear-gradient(to top, #0c3483 0%, #a2b6df 100%, #6b8cce 100%, #a2b6df 100%); */
`;

const IntroductionItemTitle = styled.span`
  font-size: 1vw;
  background: ${(props) => (props.onclick ? "#48d6d2" : "white")};
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  padding-bottom: 1vh;
  font-size: clamp(16px, 1vw, 20px);
`;

const IntroductionItemContent = styled.span`
  padding-left: 10px;
  font-size: clamp(14px, 0.7vw, 18px);
  transition: all 0.5s;
  color: white;
  opacity: ${(props) => (props.onclick ? "1" : "0")};
  /* height: 0; */
`;

const RobotSection = styled.section`
  width: 80%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${RWD_LG(css`
    width: 90%;
  `)};
`;

const RobotTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5vh;
`;

const RobotTitle = styled.h2`
  margin: 0;
  color: white;
`;

const RobotSubTitle = styled.h4`
  margin: 0;
  text-align: left;
  color: white;
  padding: 1vh 0;

  ${RWD_LG(css`
    text-align: center;
  `)};
`;

const RobotCardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 10vh;
`;

const RobotCardContainer = styled.div`
  width: 20%;
  padding: 3vw 1vw 1vw 1vw;
  position: relative;
  border-radius: 20px;
  color: white;
`;

const RobotCardTitle = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 2vh;
  font-size: 1vw;
`;

const RobotCardIcon = styled.div`
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 40px;
  background-image: ${color};
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const RobotCardContent = styled.div`
  color: #ccc;
`;

const FaqSection = styled.section`
  display: flex;
  width: 80%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${RWD_LG(css`
    flex-direction: column-reverse;
    width: 90%;
  `)};
`;

const FaqImg = styled.div`
  width: 40%;
  padding-right: 5vw;
  animation: float 3s ease-out infinite;
  margin: 0 auto;

  ${RWD_LG(css`
    width: 80%;
    padding: 0;
  `)};

  ${RWD_SM(css`
    width: 85%;
  `)};
`;

const FaqWrapper = styled.div`
  width: 60%;
  min-height: 75%;

  ${RWD_LG(css`
    width: auto;
  `)};
`;

const FaqContent = styled.div`
  width: 55%;
`;

const FaqTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const FaqTitle = styled.h2`
  margin: 0;
  color: white;
`;

const FaqSubTitle = styled.h4`
  margin: 0;
  text-align: left;
  color: white;
  padding: 1vh 0;
`;

const FaqTab = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  border-bottom: 1px solid gray;
`;

const FaqTabItem = styled.li`
  /* padding-right: 3vw; */
  margin-right: 3vw;
  color: #ccc;
  padding-bottom: 5px;

  :first-child {
    color: #48d6d2;
    border-bottom: 4px solid #48d6d2;
  }
`;

const FaqSlideContainer = styled.div`
  border: 1px solid royalblue;
  text-align: left;
  padding: 1.5vw 1vw;
  color: white;
  border-radius: 15px;
  margin-bottom: 2vh;
`;

const FaqSlideTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FaqSlideIcon = styled.div``;

const FaqSlideContent = styled.div`
  padding-top: 2vh;
`;

const RoadMapSection = styled.section`
  padding-top: 10vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;

  ${RWD_LG(css`
    height: 100%;
  `)};
`;

const RoadMapTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5vh;
  width: 50%;

  ${RWD_LG(css`
    width: 90%;
  `)};
`;

const RoadMapTitle = styled.h2`
  margin: 0;
  color: white;
`;

const RoadMapSubTitle = styled.h4`
  margin: 0;
  text-align: center;
  color: white;
  padding: 1vh 0;
`;

const RoadMapContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  ${RWD_LG(css`
    flex-direction: row;
    align-items: stretch;
    width: 90%;
  `)};
`;

const ProgressBarWrapper = styled.div`
  /* position: absolute; */
  width: 100%;
  height: 100%;

  ${RWD_LG(css`
    width: 30%;
  `)};
`;

const ProgressBarContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProgressBarList = styled.ul`
  /* overflow: hidden; */
  /* margin-left: 10vw; */
  padding: 0;
  width: 80%;
  display: flex;
  justify-content: space-between;

  ${RWD_LG(css`
    width: 100%;
    flex-direction: column;
    height: 70vh;
    margin: 0;
    align-items: center;
  `)};
`;

const ProgressBarLineWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  left: 0;

  ${RWD_LG(css`
    height: 100%;
  `)};
`;

const ProgressBarLine = styled.hr`
  width: 100%;
  height: 2px;

  background-image: linear-gradient(
    90deg,
    gray,
    gray 75%,
    transparent 75%,
    transparent 100%
  );
  background-size: 25px 1px;
  border: none;

  ${RWD_LG(css`
    height: 100%;
    width: 2px;
    background-size: 1px 25px;
    background-image: linear-gradient(
      180deg,
      gray,
      gray 75%,
      transparent 75%,
      transparent 100%
    );
  `)};
`;

const ProgressCircle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #48d6d2;
  opacity: 0.2;

  ${RWD_MD(css`
    width: 25px;
    height: 25px;
  `)};
`;

const ProgressBarItem = styled.li`
  list-style-type: none;
  color: #b6b6b6;
  text-transform: uppercase;
  width: 25%;
  display: flex;
  position: relative;
  color: blue;
  padding: 1vw 0;

  ${RWD_LG(css`
    height: 25%;
  `)};

  &::before {
    content: "";
    width: 20px;
    height: 20px;
    display: block;
    background: #48d6d2;
    /* border: 5px solid transparent; */
    border-radius: 50%;
    margin: 0 auto 5px auto;
    z-index: 5;
    top: 10%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    ${RWD_MD(css`
      width: 15px;
      height: 15px;
    `)};
  }

  &::after {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    background-color: ${(props) =>
      props.status !== "pending" ? "#00784D" : "#B6B6B6"};
    background-color: #48d6d2;

    ${RWD_LG(css`
      height: 100%;
      width: 2px;
      /* transform: rotate(90deg) scale(2.8, 1); */
      left: 25;
    `)};
  }

  &:last-child::after {
    content: none;
  }

  &:last-child::before {
    background: #ccc;
  }
`;

const RoadMapCards = styled.div`
  display: flex;
  width: 80%;
  justify-content: space-around;

  ${RWD_LG(css`
    flex-direction: column;
  `)};
`;

const RoadMapCardWrapper = styled.div`
  width: 20%;
  ${RWD_LG(css`
    width: 100%;
    align-self: flex-start;
    display: flex;
  `)};
  /* position: absolute; */
`;

const RoadMapCardLineWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RoadMapCardLine = styled.hr`
  transform: rotate(90deg);
  width: 50px;
  display: inline-block;
  margin: 15px 0;

  ${RWD_LG(css`
    transform: rotate(0deg);
    margin: 0 -7vw;
    width: 15vw;
  `)};
`;

const RoadMapCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 1vw;
  border: 1px solid royalblue;
  border-radius: 10px;
  color: white;
  width: auto;
  box-sizing: border-box;
  border: 1px solid transparent;
  border-radius: 10px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right, #210a5c, #210a5c),
    linear-gradient(294.72deg, #ff4581 9.05%, #4388dd 79.28%);

  ${RWD_LG(css`
    width: 100%;
    padding: 2.5vw 2.5vw 2.5vw 10vw;
  `)};
`;

const RoadMapCardTime = styled.div`
  font-size: clamp(13px, 0.5vw, 18px);
  opacity: 0.6;
  padding-bottom: 1.5vh;

  ${RWD_LG(css`
    padding-bottom: 1vh;
  `)};

  ${RWD_SM(css`
    padding-bottom: 0.5vh;
  `)};
`;

const RoadMapCardTitle = styled.div`
  padding-bottom: 1.5vh;
  font-size: clamp(14px, 0.8vw, 20px);

  ${RWD_LG(css`
    padding-bottom: 1vh;
  `)};

  ${RWD_SM(css`
    padding-bottom: 0.5vh;
  `)};
`;

const RoadMapCardList = styled.ul`
  margin: 0;
  padding: 0;
  padding-left: 1.5vw;
  font-size: 0.5vw;

  ${RWD_LG(css`
    padding-left: 5vw;
    font-size: clamp(12px, 0.8vw, 18px);
  `)};
`;

const RoadMapCardItem = styled.li`
  padding: 0.2vw;
`;

const Footer = styled.footer`
  display: flex;
  width: 80%;
  justify-content: space-between;
  padding-top: 15vh;

  ${RWD_LG(css`
    width: 90%;
    flex-direction: column;
  `)};
`;

const FooterLogoWrapper = styled.div`
  width: 65px;
`;

const FooterLogoMediaList = styled.ul``;

const FooterLogoMediaItem = styled.li``;

const FooterListWrapper = styled.div`
  /* display: flex; */
  display: none;

  ${RWD_MD(css`
    justify-content: space-around;
  `)};
`;

const FooterList = styled.ul`
  list-style: none;

  ${RWD_LG(css`
    padding: 0;
    width: 30%;
  `)};
`;

const FooterItem = styled.li`
  text-align: left;
  color: white;
  padding: 0.3vw 0.5vw;
  font-size: clamp(13px, 0.8vw, 20px);

  :first-child {
    color: #48d6d2;
    font-size: clamp(16px, 1vw, 22px);
    padding-bottom: 1vw;
  }

  ${RWD_LG(css`
    padding: 0.7vw;
  `)};
`;

const CopyRight = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
`;

const SlideWrapper = styled.div`
  /* border: 1px solid red; */
  height: auto;

  ${RWD_LG(css`
    width: 100%;
  `)};
`;

const GoTopWrapper = styled.div`
  position: fixed;
  bottom: 2.5vw;
  right: 2.5vw;
  transition: all 0.5s;
  opacity: ${(props) => (props.showGoTop ? "1" : "0")};

  ${RWD_MD(css`
    bottom: 4vw;
    right: 4vw;
  `)};
`;

const GoTopIcon = styled.div`
  width: clamp(40px, 2.5vw, 50px);
  height: clamp(40px, 2.5vw, 50px);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: linear-gradient(to right, #74ebd5 0%, #9face6 100%);

  pointer-events: none;

  background: linear-gradient(294.72deg, #ff4581 9.05%, #4388dd 79.28%);
  font-size: clamp(16px, 0.8vw, 20px);
`;

const ModalWrapper = styled.div`
  position: fixed;
  z-index: 99;
  height: 100%;
  width: 30vw;
  height: 25vh;
  border: 1px solid red;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
    rgba(0, 0, 0, 0.05) 0px 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(153.06deg, #5c1594 -0.31%, #210a5c 99.69%);
  border: 2px solid transparent;
  border-radius: 15px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right, #5c1594, #5c1594),
    linear-gradient(294.72deg, #ff4581 9.05%, #4388dd 79.28%);

  ${RWD_LG(css`
    width: 50vw;
  `)};

  ${RWD_MD(css`
    width: 50vw;
  `)};

  ${RWD_SM(css`
    width: 80vw;
    /* width: 100%; */
  `)};
`;

const ModalContent = styled.div`
  color: white;

  letter-spacing: 2px;
  display: flex;
  flex-direction: column;
  /* border: 1px solid green; */
  width: 100%;
  height: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
`;

const ModalLogo = styled.div`
  display: flex;
  width: 60px;
  position: absolute;
  padding: 5%;
  top: 0;
  left: 0;
  box-sizing: content-box;
`;

const ModalText = styled.div`
  font-size: clamp(20px, 2.5vw, 36px);

  ${RWD_LG(css`
    font-size: 30px;
  `)};

  ${RWD_SM(css`
    font-size: 25px;
  `)};

  /* border: 1px solid yellow; */
`;

const ModalClose = styled.div`
  position: absolute;
  right: 3%;
  font-size: 20px;
  top: 4%;
  color: white;
  cursor: pointer;
  z-index: 99;
`;

const Chinese = () => {
  const [hamIsOpen, setHamIsOpen] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const HamburgerMenuProps = useSpring({
    top: hamIsOpen ? "0" : "-1000%",
    boxShadow: hamIsOpen
      ? "0px 25px 20px -20px rgba(0, 0, 0, 0.45)"
      : "0px 0px 0px 0px rgba(0, 0, 0, 0)",
  });

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const Trail = ({ isOpen, children }) => {
    // console.log(children);
    const items = ["A", "l", "g", "o", "T", "r", "a", "d", "e"];

    // AlgoTrade;

    const list = useListAnimation(items, isOpen);

    return list.map(({ x, ...props }, index) => {
      const style = {
        ...props,
        transform: interpolate([x], (tX) => `translate3d(${tX}%, 0, 0)`),
        dispaly: "flex",
      };
      return (
        <animated.div className="list" style={style} key={index}>
          {items[index]}
        </animated.div>
      );
    });
  };

  // const smoothScrollInit = () => {
  //   const scroll = new SmoothScroll('a[href*="#"]', {
  //     speed: 1000,
  //   });
  //   document.addEventListener("scrollStart", function () {
  //     setHamIsOpen(false);
  //   });
  // };

  useEffect(() => {
    AOS.init();
    // smoothScrollInit();
  }, []);

  const [systemList, setSystemList] = useState([
    {
      id: 1,
      title: "限量發行",
      content: "保證未來不再增發",
      onclick: true,
    },
    {
      id: 2,
      title: "資格永久",
      content: "持有此NFT權益永不改變",
      onclick: false,
    },
    {
      id: 3,
      title: "全面福利",
      content: "所有的產品和好處都能享有",
      onclick: false,
    },
    {
      id: 4,
      title: "價值穩定",
      content: "實際的服務與過往成績所成就的價值是肯定的",
      onclick: false,
    },
    {
      id: 5,
      title: "永續發展",
      content: "持續進步，精益求精，創造更棒的未來",
      onclick: false,
    },
  ]);

  const toggleShowSystem = (id) => {
    setSystemList(
      systemList.map((data) => {
        if (data.id === id) {
          data.onclick = !data.onclick;
        } else {
          data.onclick = false;
        }

        return data;
      })
    );
  };

  const ToggleHamMenu = () => {
    setHamIsOpen(!hamIsOpen);
  };

  const [showHeader, setShowHeader] = useState(true);
  const [showGoTop, setShowGoTop] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    let lastScroll = 0;
    const handleScroll = (event) => {
      const windowHeight = window.innerHeight;
      let currentScroll = window.scrollY;

      if (currentScroll > windowHeight) {
        setShowGoTop(true);
      } else {
        setShowGoTop(false);
      }

      if (currentScroll > lastScroll && currentScroll !== 0) {
        setShowHeader(false);
        setHamIsOpen(false);
        setModalIsOpen(false);
      } else {
        setShowHeader(true);
      }

      lastScroll = currentScroll;
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const menuItems = [
    {
      text: "About",
      description: "關於Achiever",
    },
    {
      text: "Features",
      description: "三大福利",
    },
    {
      text: "FAQs",
      description: "安全保證",
    },
    {
      text: "RoadMap",
      description: "發展規劃",
    },
  ];

  return (
    <Wrapper id="landing">
      <Header showHeader={showHeader} id="header">
        <HeaderContainer>
          <LogoWrapper>
            <LogoContainer>
              <img src={Logo} />
            </LogoContainer>
          </LogoWrapper>
          <MenuList style={HamburgerMenuProps}>
            {menuItems?.map(({ text, description }, index) => (
              <MenuItem key={index}>
                <ScrollLink to={text} smooth={true}>
                  {description}
                </ScrollLink>
              </MenuItem>
            ))}
          </MenuList>
          <LoginButtonWrapper>
            {/* <LanguageButton>
              <NavLink to={"/AVA"}>CH</NavLink>
            </LanguageButton> */}
            <LoginButton>
              <span>購買方案</span>
            </LoginButton>
          </LoginButtonWrapper>

          <HamburgerMenuList
            hamIsOpen={hamIsOpen}
            onClick={() => ToggleHamMenu()}
          >
            <HamburgerMenuItem hamIsOpen={hamIsOpen} />
            <HamburgerMenuItem hamIsOpen={hamIsOpen} />
            <HamburgerMenuItem hamIsOpen={hamIsOpen} />
          </HamburgerMenuList>
        </HeaderContainer>
      </Header>

      <PvSection id="home">
        <PvContent>
          <PvSubTitle>日日夜夜 時時交易</PvSubTitle>
          <PvTitleWrapper>
            <PvTitle>Algo Trade</PvTitle>
            <PvTitle>ACHIEVER</PvTitle>
          </PvTitleWrapper>
          <PvDetail>AVA交易演算法NFT限量收藏方案</PvDetail>
          <PvButtons>
            <Button onClick={toggleModal}>
              <span>立即購買</span>
            </Button>
            <ButtonUnFill>
              <ScrollLink to="FAQs" smooth={true}>
                查看更多
              </ScrollLink>
            </ButtonUnFill>
          </PvButtons>
        </PvContent>
        <PvImg>
          <img src={pvImg} />
        </PvImg>
      </PvSection>

      <RobotSection id="Features">
        <RobotTitleWrapper>
          <RobotTitle
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="2000"
          >
            Earn 24/7
          </RobotTitle>
          <RobotSubTitle
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="2000"
            data-aos-delay="300"
          >
            成就您在Web 3.0的成功與收入
          </RobotSubTitle>
        </RobotTitleWrapper>
        <SlideWrapper>
          <Slide />
        </SlideWrapper>
      </RobotSection>

      <IntroductionSection id="About">
        <IntroductionTitleWrapper>
          <IntroductionTitle
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="2000"
          >
            AVA宇宙首款NFT
          </IntroductionTitle>
          <IntroductionSubTitle
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="2000"
            data-aos-delay="300"
          >
            真實購買程序使用與分潤資格，真正的服務，踏實的價值
          </IntroductionSubTitle>
        </IntroductionTitleWrapper>
        <IntroductionContent>
          <IntroductionImg>
            <img src={systemImg} />
          </IntroductionImg>
          <IntroductionList
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="2000"
            data-aos-delay="300"
          >
            {systemList?.map((data, index) => (
              <IntroductionItem
                key={index}
                onClick={() => toggleShowSystem(data.id)}
                onclick={data.onclick}
              >
                <IntroductionItemTitle onclick={data.onclick}>
                  {data.title}
                </IntroductionItemTitle>
                <IntroductionItemContent onclick={data.onclick}>
                  {data.content}
                </IntroductionItemContent>
              </IntroductionItem>
            ))}
            {/* <IntroductionItem>
              <IntroductionItemTitle>Low Risk.</IntroductionItemTitle>
            </IntroductionItem>
            <IntroductionItem>
              <IntroductionItemTitle>
                Divergent Exchanges.
              </IntroductionItemTitle>
            </IntroductionItem>
            <IntroductionItem style={{ color: "#48D6D2" }}>
              <IntroductionItemTitle>
                Well Performed Strategies.
              </IntroductionItemTitle>
            </IntroductionItem>
            <IntroductionItem>
              <IntroductionItemTitle>Bull Market.</IntroductionItemTitle>
            </IntroductionItem>
            <IntroductionItem>
              <IntroductionItemTitle>Bear Market.</IntroductionItemTitle>
            </IntroductionItem> */}
          </IntroductionList>
        </IntroductionContent>
      </IntroductionSection>

      <FaqSection id="FAQs">
        <FaqImg>
          <img src={faqImg} />
        </FaqImg>
        <FaqWrapper>
          <Tab />
        </FaqWrapper>

        {/* <FaqContent> */}
        {/* <FaqTitleWrapper>
            <FaqTitle>Frequently asked questions</FaqTitle>
            <FaqSubTitle>
              Below we've provided a bit of ICO, ICO Token, cryptocurrencies,
              and few others.
            </FaqSubTitle>
          </FaqTitleWrapper> */}

        {/* <FaqTab>
            <FaqTabItem>General</FaqTabItem>
            <FaqTabItem>Token Sales</FaqTabItem>
            <FaqTabItem>Legal</FaqTabItem>
            <FaqTabItem>Road Map</FaqTabItem>
          </FaqTab> */}
        {/* <FaqSlideContainer style={{ border: "1px solid #48d6d2" }}>
            <FaqSlideTitle>
              <span>What Is ICO crypto?</span>
              <FaqSlideIcon>
                <SlArrowDown
                  style={{ color: "#48d6d2", transform: "rotate(180deg)" }}
                />
              </FaqSlideIcon>
            </FaqSlideTitle>
            <FaqSlideContent>
              A protocol that facilitates cost-efficient and secure atomic
              transactions by utilizing a wide range of protocols and performing
              argument validation and execution verification.
            </FaqSlideContent>
          </FaqSlideContainer> */}

        {/* <FaqSlideContainer>
            <FaqSlideTitle>
              <span>What Cryptocurrencies Can I Use To Purchase?</span>
              <FaqSlideIcon>
                <SlArrowDown />
              </FaqSlideIcon>
            </FaqSlideTitle>
            <FaqSlideContent>
              A protocol that facilitates cost-efficient and secure atomic
              transactions by utilizing a wide range of protocols and performing
              argument validation and execution verification.
            </FaqSlideContent> 
          </FaqSlideContainer> */}

        {/* <FaqSlideContainer>
            <FaqSlideTitle>
              <span>What Can I Participate In The ICO Token Sale?</span>
              <FaqSlideIcon>
                <SlArrowDown />
              </FaqSlideIcon>
            </FaqSlideTitle>
            <FaqSlideContent>
              A protocol that facilitates cost-efficient and secure atomic
              transactions by utilizing a wide range of protocols and performing
              argument validation and execution verification.
            </FaqSlideContent>
          </FaqSlideContainer> */}

        {/* <FaqSlideContainer>
            <FaqSlideTitle>
              <span>What Do I Benefit From The ICO Token?</span>
              <FaqSlideIcon>
                <SlArrowDown />
              </FaqSlideIcon>
            </FaqSlideTitle>
            <FaqSlideContent>
              A protocol that facilitates cost-efficient and secure atomic
              transactions by utilizing a wide range of protocols and performing
              argument validation and execution verification.
            </FaqSlideContent>
          </FaqSlideContainer> */}
        {/* </FaqContent> */}
      </FaqSection>
      <RoadMapSection id="RoadMap">
        <RoadMapTitleWrapper>
          <RoadMapTitle
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="2000"
          >
            未來發展項目
          </RoadMapTitle>
          <RoadMapSubTitle
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="2000"
            data-aos-delay="300"
          >
            發行NFT，建立完善使用分級機制且成為未來發展的後盾，績效創造價值，價值創造更多可能
          </RoadMapSubTitle>
        </RoadMapTitleWrapper>
        <RoadMapContent>
          <ProgressBarWrapper>
            <ProgressBarContainer>
              <ProgressBarLineWrapper>
                <ProgressBarLine />
              </ProgressBarLineWrapper>

              <ProgressBarList>
                <ProgressBarItem>
                  <ProgressCircle className="blink" />
                </ProgressBarItem>
                <ProgressBarItem>
                  <ProgressCircle className="blink" />
                </ProgressBarItem>
                <ProgressBarItem>
                  <ProgressCircle className="blink" />
                </ProgressBarItem>
                <ProgressBarItem>
                  <ProgressCircle style={{ background: "transparent" }} />
                </ProgressBarItem>
              </ProgressBarList>
            </ProgressBarContainer>
          </ProgressBarWrapper>
          <RoadMapCards>
            <RoadMapCardWrapper data-aos="fade-up" data-aos-duration="1500">
              <RoadMapCardLineWrapper>
                <RoadMapCardLine />
              </RoadMapCardLineWrapper>
              <RoadMapCardContainer>
                <RoadMapCardTime>2022/06/15</RoadMapCardTime>
                <RoadMapCardTitle>NFT應用概念建立</RoadMapCardTitle>
                <RoadMapCardList>
                  <RoadMapCardItem>機器人使用權</RoadMapCardItem>
                  <RoadMapCardItem>原始碼股權</RoadMapCardItem>
                </RoadMapCardList>
              </RoadMapCardContainer>
            </RoadMapCardWrapper>

            <RoadMapCardWrapper data-aos="fade-up" data-aos-duration="2000">
              <RoadMapCardLineWrapper>
                <RoadMapCardLine />
              </RoadMapCardLineWrapper>
              <RoadMapCardContainer>
                <RoadMapCardTime>2022/12/10</RoadMapCardTime>
                <RoadMapCardTitle>研究與規劃</RoadMapCardTitle>
                <RoadMapCardList>
                  <RoadMapCardItem>白皮書撰寫</RoadMapCardItem>
                  <RoadMapCardItem>技術研究與測試</RoadMapCardItem>
                </RoadMapCardList>
              </RoadMapCardContainer>
            </RoadMapCardWrapper>

            <RoadMapCardWrapper data-aos="fade-up" data-aos-duration="2500">
              <RoadMapCardLineWrapper>
                <RoadMapCardLine />
              </RoadMapCardLineWrapper>
              <RoadMapCardContainer>
                <RoadMapCardTime>2023/04/10</RoadMapCardTime>
                <RoadMapCardTitle>設計與開發</RoadMapCardTitle>
                <RoadMapCardList>
                  <RoadMapCardItem>平台及系統設計</RoadMapCardItem>
                  <RoadMapCardItem>開發系統</RoadMapCardItem>
                </RoadMapCardList>
              </RoadMapCardContainer>
            </RoadMapCardWrapper>

            <RoadMapCardWrapper data-aos="fade-up" data-aos-duration="3000">
              <RoadMapCardLineWrapper>
                <RoadMapCardLine />
              </RoadMapCardLineWrapper>
              <RoadMapCardContainer style={{ border: "1px solid royalblue" }}>
                <RoadMapCardTime>2023/10/10</RoadMapCardTime>
                <RoadMapCardTitle>完成</RoadMapCardTitle>
                <RoadMapCardList>
                  <RoadMapCardItem>進行發行與營運</RoadMapCardItem>
                  {/* <RoadMapCardItem>Team Assemble</RoadMapCardItem> */}
                </RoadMapCardList>
              </RoadMapCardContainer>
            </RoadMapCardWrapper>
          </RoadMapCards>
        </RoadMapContent>
      </RoadMapSection>
      <Footer>
        <FooterLogoWrapper>
          <img src={Logo} />
        </FooterLogoWrapper>
        <FooterListWrapper>
          <FooterList>
            <FooterItem>Company</FooterItem>
            <FooterItem>About US</FooterItem>
            <FooterItem>Meet Our Team</FooterItem>
            <FooterItem>Contact</FooterItem>
            <FooterItem>Blog</FooterItem>
            <FooterItem>Partners</FooterItem>
          </FooterList>

          <FooterList>
            <FooterItem>Links</FooterItem>
            <FooterItem>Our Tokens</FooterItem>
            <FooterItem>Roadmap</FooterItem>
            <FooterItem>FAQs</FooterItem>
            <FooterItem>Login</FooterItem>
            <FooterItem>Features</FooterItem>
          </FooterList>

          <FooterList>
            <FooterItem>Privacy</FooterItem>
            <FooterItem>Advertiser Agreement</FooterItem>
            <FooterItem>Acceptable Use Policy</FooterItem>
            <FooterItem>Pricacy Policy</FooterItem>
            <FooterItem>Technology Policy</FooterItem>
            <FooterItem>Development Agreement</FooterItem>
          </FooterList>
        </FooterListWrapper>
      </Footer>
      <GoTopWrapper showGoTop={showGoTop}>
        <ScrollLink to="landing" smooth={true}>
          <GoTopIcon>
            <SlArrowUp />
          </GoTopIcon>
        </ScrollLink>
      </GoTopWrapper>
      <CopyRight>
        <span>Copyright © 2022 AVA All right reserved</span>
      </CopyRight>
      {modalIsOpen && (
        <ModalWrapper>
          <ModalClose onClick={toggleModal}>&times;</ModalClose>
          <ModalContent>
            <ModalLogo>
              <img src={Logo} />
            </ModalLogo>
            <ModalText>敬請期待</ModalText>
          </ModalContent>
        </ModalWrapper>
      )}
    </Wrapper>
  );
};

export default Chinese;
