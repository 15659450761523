import { createApi } from "@reduxjs/toolkit/query/react";
import { makeUrlencoded } from "./common";
import defaultBaseQuery from "./query/defaultBaseQueryV2";

export const algoApi = createApi({
  reducerPath: "algoApi",
  baseQuery: defaultBaseQuery,
  endpoints: (builder) => ({
    getAlgo: builder.query({
      query: () => ({
        url: "/algo/GetAccountAlgos",
        method: "GET",
      }),
      providesTags: [{ type: "algo", id: "algo" }],
    }),

    getAlgoRefetch: builder.mutation({
      query: () => ({
        url: "/algo/RefreshAlgos",
        method: "GET",
      }),
      invalidatesTags: [{ type: "algo", id: "algo" }],
    }),

    addAlgo: builder.mutation({
      query: ({ body }) => ({
        url: "/algo/AddAlgo",
        method: "POST",
        body: makeUrlencoded(body),
      }),
      invalidatesTags: [{ type: "algo", id: "algo" }],
    }),

    startAccountAlgo: builder.mutation({
      query: ({ body }) => ({
        url: "/algo/StartAccountAlgo",
        method: "POST",
        body: makeUrlencoded(body),
      }),
      invalidatesTags: [{ type: "algo", id: "algo" }],
    }),

    stopAccountAlgo: builder.mutation({
      query: ({ body }) => ({
        url: "/algo/StopAccountAlgo",
        method: "POST",
        body: makeUrlencoded(body),
      }),
      invalidatesTags: [{ type: "algo", id: "algo" }],
    }),

    closeAccountAlgo: builder.mutation({
      query: ({ body }) => ({
        url: "/algo/CloseAccountAlgo",
        method: "POST",
        body: makeUrlencoded(body),
      }),
      invalidatesTags: [{ type: "algo", id: "algo" }],
    }),

    getAlgoDetail: builder.mutation({
      query: ({ body }) => ({
        url: "/algo/GetAlgoDetail",
        method: "POST",
        body: makeUrlencoded(body),
      }),
      invalidatesTags: [{ type: "algo", id: "algo" }],
    }),

    toggleAlgoSubscribe: builder.mutation({
      query: ({ body }) => ({
        url: "/algo/ToggleAlgoSubscribe",
        method: "POST",
        body: makeUrlencoded(body),
      }),
      invalidatesTags: [{ type: "algo", id: "algo" }],
    }),

    getTargetExchangeIds: builder.mutation({
      query: ({ body }) => ({
        url: "/algo/GetTargetExchangeIds",
        method: "POST",
        body: makeUrlencoded(body),
      }),
      invalidatesTags: [{ type: "algo", id: "algo" }],
    }),

    deleteInitAlgo: builder.mutation({
      query: ({ body }) => ({
        url: "/algo/DeleteInitAlgo",
        method: "POST",
        body: makeUrlencoded(body),
      }),
      invalidatesTags: [{ type: "algo", id: "algo" }],
    }),
  }),
});

export const {
  useGetAlgoQuery,
  useAddAlgoMutation,
  useGetAlgoRefetchMutation,
  useStartAccountAlgoMutation,
  useStopAccountAlgoMutation,
  useCloseAccountAlgoMutation,
  useGetAlgoDetailMutation,
  useToggleAlgoSubscribeMutation,
  useGetTargetExchangeIdsMutation,
  useDeleteInitAlgoMutation,
} = algoApi;
