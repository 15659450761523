import styled, { keyframes, css } from "styled-components";
import { FaSpinner } from "react-icons/fa";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: transparent;
  backdrop-filter: blur(2px);
  z-index: 98;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  align-items: center;

  ${RWD_LG(css`
    min-height: calc(100vh - 80px - 45px);
  `)};

  ${RWD_SM(css`
    min-height: calc(100vh - 60px - 45px);
  `)};
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
    transform-origin: center;
  }

  to {
    transform: rotate(360deg);
    transform-origin: center;
  }
`;

const Animation = styled.div`
  animation: ${rotate} 2s linear infinite;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50vh;
`;

const Loading = () => {
  return (
    <Wrapper>
      <Animation>
        <FaSpinner style={{ color: "#0AECD1" }} />
      </Animation>
    </Wrapper>
  );
};

export default Loading;
