import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// --- components ---
import { StyledAlert, StyledButton, StyledInput, StyledSelect } from '../Global/Ui';
import Loading from '../Global/Loading';
import ToggleTheme from '../Global/ToggleTheme';
import Logo from '../Global/Logo';

// --- plugins ---
import { IoAlertCircleOutline } from 'react-icons/io5';
import Swal from 'sweetalert2/dist/sweetalert2';

// --- api ---
import { API_MailVerify, API_Register } from '../../utils/api';
import { verifications } from '../../utils/verifications';

const Register = () => {
  // --- handler ---
  const getVerifyMail = async (e) => {
    const res = await API_MailVerify({ email });
    if (!res.includes('完成')) return Swal.fire(res);
    Swal.fire({
      title: 'Register success!',
      icon: 'success'
    }).then(() => {
      handleGoToLogin(e);
    });
  };
  const handleSignUp = async (e) => {
    const res = await API_Register({ step: '3', phone: getCodeNumber, verifyCode, email, password });
    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return Swal.fire(res.Message);
    getVerifyMail(e);
  };
  const checkIsVerifyCodeCorrect = async (e) => {
    e.preventDefault();
    const res = await API_Register({
      step: '2',
      phone: getCodeNumber,
      verifyCode,
    });

    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return Swal.fire(res.Message);

    handleSignUp(e);
  };
  const handleSendCode = async () => {
    const res = await API_Register({ step: '1', phone: getCodeNumber, verifyCode });
    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return Swal.fire(res.Message);
    Swal.fire("已發送驗證碼").then(() => {
      localStorage.setItem('resendSMS', 60);
      setSendSetSMSCodeLabel('(60s)');
      setCanGetSMSCode(false);
    });
  };
  const handleGoToLogin = (e) => {
    e.preventDefault();
    navigate('/AVA/login');
  };

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [countryCode, setCountryCode] = useState('852');
  const [phone, setPhone] = useState('');
  const [phonePlaceholder, setPhonePlaceholder] = useState('98765432');
  const [getCodeNumber ,setGetCodeNumber] = useState('');
  const [verifyCode, setVerifyCode] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [canSignUp, setCanSignUp] = useState(false);
  const [sendSMSCodeLabel, setSendSetSMSCodeLabel] = useState(localStorage.getItem('resendSMS') ? `(${localStorage.getItem('resendSMS')}s)` : 'GET Code');
  const [canGetSMSCode, setCanGetSMSCode] = useState(false);

  useEffect(() => {
    document.body.removeAttribute('style');
  }, []);

  // update phone placeholder
  useEffect(() => {
    // hk
    if (countryCode === '852') return setPhonePlaceholder('98765432')
    // tw
    if (countryCode === '886') return setPhonePlaceholder('9876543210')
  }, [countryCode]);

  // setGetCodeNumber (for api)
  useEffect(() => {
    setGetCodeNumber(`+${countryCode}${phone}`);
  }, [countryCode, phone]);

  // check can sign up or not
  useEffect(() => {
    setError({});

    if (!phone) return setCanSignUp(false);
    if (countryCode === '852' && !verifications.phoneHK.test(phone)) {
      setError({ ...error, phone: `Please check phone format` })
      setCanGetSMSCode(false);
      return setCanSignUp(false);
    }
    if (countryCode === '886' && !verifications.phoneTW.test(phone)) {
      setError({ ...error, phone: `Please check phone format` })
      setCanGetSMSCode(false);
      return setCanSignUp(false);
    }
    
    if (!localStorage.getItem('resendSMS')) setCanGetSMSCode(true);

    if (!verifyCode) return setCanSignUp(false);

    if (!email) return setCanSignUp(false);
    if (!verifications.email.test(email)) {
      setError({ ...error, email: 'Please check email format' })
      return setCanSignUp(false);
    }

    if (!password) return setCanSignUp(false);
    if (!confirmPassword) return setCanSignUp(false);
    if (password !== confirmPassword) {
      setError({ ...error, password: 'different with password' })
      return setCanSignUp(false);
    }
    setCanSignUp(true);
  }, [countryCode, phone, email, verifyCode, password, confirmPassword]);

  useEffect(() => {
    const resendSec = localStorage.getItem('resendSMS');
    if (!resendSec) return;
    if (resendSec < 0) {
      localStorage.removeItem('resendSMS');
      setSendSetSMSCodeLabel('GET Code');
      return setCanGetSMSCode(true);
    }
    setCanGetSMSCode(false);
    setTimeout(() => {
      localStorage.setItem('resendSMS', resendSec - 1);
      setSendSetSMSCodeLabel(`(${localStorage.getItem('resendSMS')}s)`);
    }, 1000);
  }, [sendSMSCodeLabel]);

  if (loading) return <Loading />
  return (
    <div className='w-limit'>
      <div className='w-full font-[Montserrat] py-5'>
        {/* --- logo --- */}
        <div className='py-10 mx-auto text-center'>
          <Logo />
        </div>
        
        {/* --- Register form --- */}
        <form>
          <div className='
            w-full px-5 sm:px-20 py-10 bg-main
            border border-sub-button rounded
          '>
            {/* title */}
            <h2 className='mx-auto text-[1.5rem]'>
              Sign up for a free account
            </h2>

            {/* phone */}
            <div className='pt-5 flex flex-wrap'>
              <label className='block w-full px-2'>
                Phone Number & Verification
              </label>
              <div className='w-full flex flex-wrap'>
                {/* phone number */}
                <StyledSelect
                  selectedValue={countryCode}
                  defaultSelectClass='text-default'
                  onChange={e => setCountryCode(e.target.value)}
                  options={[{ value: '852', text: '852' }, { value: '886', text: '886' }]}
                />
                <div className='pl-2 lg:px-2 flex-1'>
                  <StyledInput
                    type='tel'
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                    placeholder={phonePlaceholder}
                    className='flex-1'
                  />
                </div>
                {/* sms code */}
                <div className='w-full lg:w-auto pt-2 lg:pt-0'>
                  <div className='relative'>
                    <div
                      className={`
                        absolute right-2 top-1/2 -translate-y-1/2
                        flex items-center
                        ${canGetSMSCode ? 'child:text-sub-blue cursor-pointer' : 'child:text-sub-word pointer-events-none'}
                      `}
                      onClick={handleSendCode}
                    >
                      <span>{sendSMSCodeLabel}</span>
                      <IoAlertCircleOutline className='text-[1.3rem] ml-2' />
                    </div>
                    <StyledInput
                      value={verifyCode}
                      onChange={e => setVerifyCode(e.target.value)}
                      placeholder='SMS Code'
                    />
                  </div>
                </div>
              </div>
              {error.phone && <div className='text-fail'>{error.phone}</div>}
            </div>

            {/* email */}
            <div className='pt-5'>
              <label className='px-2' htmlFor='email'>
                Email Address
              </label>
              {/* email */}
              <div className='relative w-full'>
                <StyledInput
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  placeholder='welcome@example.com'
                />
              </div>
              {error.email && <div className='text-fail'>{error.email}</div>}
            </div>

            {/* password */}
            <div className='pt-5'>
              <label htmlFor='password' className='px-2'>
                Password
              </label>
              <StyledInput
                id='password'
                type='password'
                value={password}
                onChange={e => setPassword(e.target.value)}
                placeholder='Create Password'
              />
            </div>

            {/* confirm password */}
            <div className='pt-5'>
              <label htmlFor='confirmPassword' className='px-2'>
                Confirm Password
              </label>
              <StyledInput
                type='password'
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
                placeholder='Confirm Password'
              />
              {error.password && <div className='text-fail'>{error.password}</div>}
            </div>

            {/* Next Step & Login buttons */}
            <div className='pt-8 flex'>
              {/* Next Step */}
              <StyledButton
                value='Sign Up'
                onClick={checkIsVerifyCodeCorrect}
                enable={canSignUp}
                className='mr-4'
              />
              <StyledButton
                outline={true}
                value='login'
                onClick={handleGoToLogin}
              />
              {/* <span
                className='
                  absolute sm:whitespace-nowrap bottom-full left-0 tracking-normal
                  font-[Roboto] text-[0.875rem] text-blue
                '
              >
                Do you already have an account?
              </span> */}
            </div>

            {/* home & forget password */}
            <div className='mx-auto pt-5'>
              <div className='w-full text-center py-1'>
                <Link
                  to='/AVA/'
                  className='text-blue'
                >
                  Home
                </Link>
              </div>
            </div>
          </div>
        </form>

        {/* --- notice --- */}
        <div className='
          min-w-[320px] max-w-[622px] mx-auto font-[Roboto] text-[1rem]
          text-sub-word
        '>
          <div className='flex items-center pt-5 mb-3'>
            <IoAlertCircleOutline className='mr-4 text-[1.5rem]' />
            <p className='text-[1rem]'>Notice:</p>
          </div>

          <div className='w-full'>
            <ol className='pl-10'>
              <li>
                1. Need to know <span className='text-blue cursor-pointer'>Computer-Processed Personal Data Protection Law</span>.
              </li>
            </ol>
          </div>
        </div>
      </div>

      {/* toggle theme */}
      <div className='
        fixed w-full bg-white/75 dark:bg-black-main/75 left-0 bottom-0 py-3 px-5
         sm:bg-transparent cursor-pointer child:ml-0
      '>
        <ToggleTheme />
      </div>
    </div>
  )
}

export default Register
