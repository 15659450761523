import styled, { css, keyframes } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";

import Lottie from "lottie-react";

import Lang from "../../../global/Lang";

import subscribeAnimation from "../../../lotties/subscribe.json";
import unsubscribeAnimation from "../../../lotties/unsubscribe.json";

const fadeIn = keyframes`
   0% { opacity: 0; }
  100% { opacity: 1; }

`;

const fadeOut = keyframes`
   0% { opacity: 1; }
  100% { opacity: 0; }

`;

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  color: #151944;
  animation: ${fadeIn} 0.3s;
`;

const Container = styled.div`
  background-color: #fcfcfc;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 40px;
  width: 35vw;
  max-width: 850px;
  border-radius: 8px;
  position: relative;

  ${RWD_LG(css`
    width: 80%;
  `)};

  ${RWD_MD(css`
    width: 90%;
    /* padding: 20px 40px; */
  `)};

  ${RWD_SM(css`
    padding: 20px;
  `)};
`;

const Title = styled.div`
  color: #151944;
  font-size: min(max(1.5625vw, 28px), 30px);
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;

  ${RWD_MD(css`
    font-size: min(max(1.0416vw, 18px), 20px);
  `)};
`;

const LottieWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
`;

const ConfirmButton = styled.button`
  width: 135px;
  border-radius: 60px;
  color: #fcfcfc;
  background-color: #151944;
  border: 1px solid #151944;
`;

const ButtonText = styled.div`
  padding: 9px 0;
  text-align: center;

  font-size: min(max(1.0416vw, 18px), 20px);

  ${RWD_MD(css`
    padding: 6px 15px;
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const Text = styled.div`
  text-align: center;
  font-weight: 500;
`;

const SubscribeModal = ({ setSubscribeIsOpen, subscribeStatus }) => {
  const handleClose = () => {
    setSubscribeIsOpen(false);
  };

  const modalContent = {
    subscribe: [
      <Lang
        datakey="subscribed_success"
        defaultText="You have successfully subscribed!"
      />,
      <Lang
        datakey="subscribed_success_description"
        defaultText="You will receive our instant news updates on Telegram!"
      />,
    ],
    unsubscribe: [
      <Lang
        datakey="unsubscribed_success"
        defaultText="You have successfully unsubscribed."
      />,
      <Lang
        datakey="unsubscribed_success_description"
        defaultText="To receive the latest AI news, please click Subscribe again!"
      />,
    ],
  };

  return (
    <Wrapper>
      <Container>
        <LottieWrapper>
          <Lottie
            animationData={
              subscribeStatus ? subscribeAnimation : unsubscribeAnimation
            }
            loop={true}
            style={{ height: "300px", width: "300px" }}
          />
        </LottieWrapper>
        <Title>
          {subscribeStatus
            ? modalContent?.subscribe?.[0]
            : modalContent?.unsubscribe?.[0]}
        </Title>
        <Text>
          {subscribeStatus
            ? modalContent?.subscribe?.[1]
            : modalContent?.unsubscribe?.[1]}
        </Text>
        <ButtonWrapper>
          <ConfirmButton onClick={handleClose}>
            <ButtonText>
              <Lang datakey="confirm" defaultText="confirm" />
            </ButtonText>
          </ConfirmButton>
        </ButtonWrapper>
      </Container>
    </Wrapper>
  );
};

export default SubscribeModal;
