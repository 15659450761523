import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import styled from 'styled-components';
import { TableContainer, Title } from './Dashboard';
import { useEffect, useState } from 'react';
import { API_GetErrorApiLogs } from '../../../utils/api';

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ErrorTypeSelectionWrapper = styled.div`
  display: flex;
`;
const ErrorTypeSelection = styled.div`
  padding: .2rem .4rem;
  
  * {
    cursor: pointer;
  }
  label {
    padding: 0 .2rem;
  }
`;
const ErrorTypeStatus = styled.div`
  padding: .2rem 1rem;
  border-radius: 500px;
  color: #fff;
  background: ${(props) => {
    switch(props.type) {
      case "User": return "green";
      case "System": return "red";
      case "ThirdParty": return "orange";
      case "None": return "grey";
    }
  }};
`;
const NoWrap = styled.div`
  white-space: nowrap;
`;

const LogTable = () => {
  const handleGetErrorLogs = async () => {
    const res = await API_GetErrorApiLogs();
    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return UseSwal(res.Message);
    setData(res.ErrorLogs);
  };

  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([
    {
      fieldName: "CreateTime",
      displayName: "時間",
      align: "left",
    },
    {
      fieldName: "Type",
      displayName: "錯誤類別",
      align: "center",
    },
    {
      fieldName: "Category",
      displayName: "錯誤功能",
      align: "center",
    },
    {
      fieldName: "AccountId",
      displayName: "觸發帳號",
      align: "left",
    },
    {
      fieldName: "Description",
      displayName: "錯誤訊息",
      align: "left",
    },
  ]);
  const [filterType, setFilterType] = useState({
    User: false,
    System: true,
    ThirdParty: true,
  });

  useEffect(() => {
    handleGetErrorLogs();
  }, []);
  
  return (
    <>
      <TitleWrapper>
        <Title>
          錯誤紀錄
        </Title>

        <ErrorTypeSelectionWrapper>
          <ErrorTypeSelection>
            <ErrorTypeStatus
              type={filterType.System ? "System" : "None"}
              onClick={() => setFilterType(prev => ({ ...prev, System: !filterType.System }))}
            >
              System
            </ErrorTypeStatus>
          </ErrorTypeSelection>
          <ErrorTypeSelection>
            <ErrorTypeStatus
              type={filterType.ThirdParty ? "ThirdParty" : "None"}
              onClick={() => setFilterType(prev => ({ ...prev, ThirdParty: !filterType.ThirdParty }))}
            >
              ThirdParty
            </ErrorTypeStatus>
          </ErrorTypeSelection>
          <ErrorTypeSelection>
            <ErrorTypeStatus
              type={filterType.User ? "User" : "None"}
              onClick={() => setFilterType(prev => ({ ...prev, User: !filterType.User }))}
            >
              User
            </ErrorTypeStatus>
          </ErrorTypeSelection>
        </ErrorTypeSelectionWrapper>
      </TitleWrapper>

      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
              <TableRow>
                {columns?.map((column, index) => (
                  <TableCell key={index} align={column.align}>
                    <NoWrap>{column.displayName}</NoWrap>
                  </TableCell>
                ))}
              </TableRow>
          </TableHead>
          <TableBody>
            {data
              ?.filter((log) => (filterType[log.Type]))
              ?.map((log) => (
                <TableRow key={log.Id} sx={{ '&': { cursor: 'pointer', whiteSpace: 'nowrap' }, '&:hover': { background: '#eeea' } }}>
                  <TableCell>
                    <NoWrap>{log.CreateTime}</NoWrap>
                  </TableCell>
                  <TableCell align="center">
                    <ErrorTypeStatus type={log.Type}>{log.Type}</ErrorTypeStatus>
                  </TableCell>
                  <TableCell align="center">
                    {log.Category}
                  </TableCell>
                  <TableCell>
                    <NoWrap>{log.AccountId}</NoWrap>
                  </TableCell>
                  <TableCell>
                    {log.Description}
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table> 
      </TableContainer>
    </>
  )
}

export default LogTable
