import { useState } from 'react';

// max data length: 12
const RecentlyChart = ({ data }) => {

  const [isShow, setIsShow] = useState(false);
  const [tagLeft, setTagLeft] = useState(0);
  const [tagTop, setTagTop] = useState(0);
  const [volume, setVolume] = useState('');

  if (data.length === 0) return;

  const maxValue = Math.max(...data.map(({ totalUSDT }) => isNaN(totalUSDT) ? 0 : totalUSDT));
  const minValue = Math.min(...data.map(({ totalUSDT }) => isNaN(totalUSDT) ? 0 : totalUSDT)) * 0.9;
  const blankBar = data.length > 12 ? 0 : 12 - data.length;

  const handleShowTag = (e, index) => {
    const tag = document.querySelectorAll('.tag')[index];
    const databar = document.querySelectorAll('.databar')[index];
    const rect = databar.getBoundingClientRect();
    const y = rect.height - (e.clientY - rect.top);
    tag.style.bottom = y + 'px';
  }

  return (
    <div className='w-full h-full px-5 py-10'>
      <div className='
        w-full h-[440px] p-5
        flex justify-around
      '>
        <div className='relative h-full flex flex-col-reverse justify-between'>
          {/* y scale label */}
          <span className='
            absolute bottom-full left-1/2 translate-x-[-50%] pb-2
            
          '>
            Volume 
          </span>

          {/* y scales (USDT) */}
          {Array.from(Array(10).keys()).map(index => (
            <span key={index} className='sub-word text-center'>
              {((maxValue - minValue) / 10 * (index + 1) + minValue).toFixed(2)}
            </span>
          ))}

          {/* x scale label */}
          <span className='
            absolute top-full left-1/2 translate-x-[-50%] pt-2
            text-center
          '>
            End
            <br className='2xl:hidden' />
            Date
          </span>
        </div>

        {/* bar chart */}
        <div className={`relative w-full h-full flex-grow flex ${data.length >= 6 && 'justify-end'}`}>
          { // show data when bar on hover
            isShow
            &&
            <div
              className='
                absolute z-10 p-2 text-[0.5rem] 
                bg-[#ccc]/50 dark:bg-[#666]/75 rounded pointer-events-none whitespace-nowrap
              '
              style={{ left: tagLeft, top: tagTop }}
            >
              volumn:
              <br />
              {volume.toFixed(2)}
            </div>
          }
          {data.map(({ Period, totalUSDT, EndDate }, index) => {
            return (
              <div key={index} className={`relative h-full shrink-0 w-[16.666667%] lg:w-[8.333333%] ${(data.length >= 6 && index < data.length - 6) && 'hidden'} lg:block`}>
                <div className='relative mx-auto w-[10px] h-full bg-sub-word rounded overflow-y-hidden'>
                  <span
                    className='databar absolute block top-full w-full bg-sub-blue rounded hover:saturate-200 hover:brightness-70 duration-300'
                    style={{ height: `${(totalUSDT - minValue) / (maxValue - minValue) * 100}%` }}
                    onMouseMove={(e) => handleShowTag(e, index)}
                    onMouseEnter={() => document.querySelectorAll('.tag')[index].classList.toggle('hidden')}
                    onMouseLeave={() => document.querySelectorAll('.tag')[index].classList.toggle('hidden')}
                  />
                </div>

                <div
                  className='
                    tag hidden absolute left-0 z-10 p-2 text-[0.5rem] 
                    bg-[#ccc]/50 dark:bg-[#666]/75 rounded pointer-events-none whitespace-nowrap
                  '
                >
                  volumn:
                  <br />
                  {totalUSDT.toFixed(2)}
                </div>

                {/* x scales */}
                <span className='
                  absolute top-full left-1/2 translate-x-[-50%] pt-2
                  text-sub text-center
                '>
                  {/* {EndDate} */}
                  {new Date(EndDate).getMonth() + 1}/
                  {new Date(EndDate).getDate()}
                  <br className='2xl:hidden' />
                  /{new Date(EndDate).getFullYear()}
                </span>
              </div>
            )
          })}

          {/* blank bar */}
          {Array.from(Array(blankBar).keys()).map(index => (
            <div key={index} className={`relative h-full shrink-0 w-[16.666667%] lg:w-[8.333333%] ${(data.length >= 6 || (data.length < 6 && index >= 6 - data.length)) && 'hidden'} lg:block`}>
              <div className='relative mx-auto w-[10px] h-full bg-sub-word rounded'>
                {/* x scales */}
                <span className='
                  absolute top-full left-1/2 translate-x-[-50%] pt-2
                  text-sub
                '>
                  {/* {data.length + 1 + index} */}
                  {/* {new Date(data[data.length - 1].EndDate + (1 + index) * periodLong).getMonth() + 1}/
                  {new Date(data[data.length - 1].EndDate + (1 + index) * periodLong).getDate()}/
                  {new Date(data[data.length - 1].EndDate + (1 + index) * periodLong).getFullYear()} */}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default RecentlyChart
