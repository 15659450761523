import RecentlyChart from './RecentlyChart';
import { StyledAlert, StyledNoContent } from '../../Global/Ui';
import { AiFillCaretDown } from 'react-icons/ai';
import { HiDotsVertical } from 'react-icons/hi';

const Main = ({ data }) => {
  const {
    error,
    recentlyChartTabs,
    setRecentlyChartTabCurrentSelected,
    recentlyChartSelections,
    recentlyChartCurrentSelected,
    recentlyBarCharData,
    setRecentlyChartCurrentSelected,
    recentlyChartTabCurrentSelected,
  } = data;
  return (
    <>
      {/* error message */}
      <div className='mx-auto mt-20 rounded py-4'>
        {!!error.recently && <StyledAlert value={error.recently} />}
      </div>
      {/* chart */}
      <div className='mx-auto bg-sub-white dark:bg-black rounded'>
        {/* title & chart tabs */}
        <div className='w-full border-b border-b-1 border-sub-word'>
          <div className='px-10 flex justify-between items-center'>
            {/* title */}
            <h3 className='uppercase text-[1.125rem] font-bold'>
              Minning Report
            </h3>
            
            {/* chart tabs & three dots icon */}
            <div className='flex items-center'>
              {/* chart tabs */}
              <div className='max-w-[100px] min-h-[3rem] md:max-w-full overflow-scroll hideScrollBar'>
                <ul
                  className='relative flex font-bold text-sub-word'
                >
                  {recentlyChartTabs?.map((item, index) => (
                    <li
                      key={index}
                      className={`
                        tabOnSelect py-5 shrink-0 text-center py-3 w-[100px] cursor-pointer
                        ${index === recentlyChartTabCurrentSelected && 'active'}
                      `}
                      onClick={() => {
                        setRecentlyChartTabCurrentSelected(index);
                      }}
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              </div>

              {/* three points */}
              <HiDotsVertical className='ml-2 text-[1rem]' />
            </div>
          </div>
        </div>

        {/* chart */}
        <div className={`
          relative p-5
          ${(!recentlyChartSelections || recentlyChartSelections?.length === 0) && 'min-h-[500px]'}
        `}>
          {/* chart selections */}
          {// recentlyChartSelections
            recentlyChartSelections?.length > 0
            &&
            <div className='relative'>
              <button
                className='
                  min-w-[100px] ml-5 px-2 py-0.5 text-[0.875rem] font-bold
                  flex justify-between items-center
                  bg-[#FAFAFA] dark:bg-black-menu 
                  border border-[1px] border-sub-button rounded
                '
                onClick={e => e.currentTarget.nextSibling.classList.toggle('active')}
              >
                {recentlyChartCurrentSelected + 1}. {recentlyChartSelections[recentlyChartCurrentSelected]}
                <AiFillCaretDown className='text-[0.75rem]' />
              </button>
              <ul className='
                absolute z-10 top-[105%] left-5 hidden active:inline-block
                bg-[#FAFAFA] dark:bg-black-menu 
                border border-[1px] border-sub-button rounded overflow-hidden
              '>
                {recentlyChartSelections?.map((item, index) => (
                  <li
                    key={index}
                    className={`
                      block min-w-[100px] px-5 py-2 cursor-pointer
                      hover:bg-sub-button/50
                      ${index === recentlyChartCurrentSelected && 'font-bold bg-sub-button/50'}
                    `}
                    onClick={e => {
                      setRecentlyChartCurrentSelected(index);
                      e.target.parentElement.classList.toggle('active');
                    }}
                  >
                    {index + 1}. {item}
                  </li>
                ))}
              </ul>
            </div>
          }
          {
            (!recentlyChartSelections || recentlyChartSelections?.length === 0)
            &&
            <div className='absolute top-0 left-0 w-full h-full'>
              <StyledNoContent />
            </div>
          }
        </div>

        {/* bar chart */}
        <RecentlyChart data={recentlyBarCharData} />
      </div>
    </>
  )
}

export default Main
