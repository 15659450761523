import styled from 'styled-components';

export const Container = styled.div`
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`;

export const Title = styled.h3`
    padding: 1rem 0;
    color: #1976d2;
`;

export const TableContainer = styled.div`
    flex: 1;

    table tr:first-child {
        text-align: center;
    }
`;

export const ActionContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 1.5rem;
    
`;