import styled from "styled-components";
import { css } from "styled-components";
import { RWD_XL, RWD_LG, RWD_MD, RWD_SM, RWD_SS, RWD_XS } from "../utils/rwd";
import Lang from "./Lang";

const ButtonContainer = styled.button`
  width: 135px;
  border-radius: 60px;
  border: 1px solid;
  color: ${(props) => (props.type === "confirm" ? "#fcfcfc" : "#151944")};
  background-color: ${(props) =>
    props.type === "confirm" ? (props.disabled ? "#d9d9d9" : "#151944") : ""};
  border-color: ${(props) => (props.disabled ? "#d9d9d9" : "#151944")};

  &:first-child {
    margin-right: 1.5625vw;
  }

  ${RWD_XL(css`
    width: 110px;
  `)};

  ${RWD_MD(css`
    width: 90px;
  `)};
`;

const ButtonText = styled.div`
  padding: 9px 0;
  text-align: center;

  font-size: min(max(1.0416vw, 18px), 20px);

  ${RWD_MD(css`
    padding: 6px 15px;
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const Button = ({ onClickFun, disabled, type }) => {
  return (
    <ButtonContainer disabled={disabled} onClick={onClickFun} type={type}>
      <ButtonText disabled={disabled}>
        {(() => {
          switch (type) {
            case "cancel":
              return <Lang datakey="cancel" defaultText="Cancel" />;
            case "confirm":
              return <Lang datakey="confirm" defaultText="Confirm" />;
            default:
              return null;
          }
        })()}
      </ButtonText>
    </ButtonContainer>
  );
};

export default Button;
