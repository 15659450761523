export const ProcessBar = ({ ProcessBarTags, pointNumber, currentStep }) => {
  const length = (ProcessBarTags && ProcessBarTags.length) || pointNumber || 0;
  return (
    <div
      className={`relative w-[90%] mx-auto h-[3px] bg-[#666] my-10`}
      style={{background: `linear-gradient(to right, #ccc ${currentStep * (100 / (length - 1)).toFixed(6)}%, #666 ${currentStep * (100 / (length - 1)).toFixed(6)}%)`}}  
    >
      {/* process bar with tags */}
      {ProcessBarTags &&
        ProcessBarTags.map((tag, index) => (
          <span
            key={index}
            className={`
              w-[15px] h-[15px] block absolute top-1/2 translate-x-[-50%] translate-y-[-50%] rounded-[50%]
              ${index === currentStep
                ? 'bg-primary text-primary'
                : index < currentStep
                  ? 'bg-sub-word text-sub-word'
                  : 'bg-[#666] before:hidden' }
              sm:before:content-[attr(data-tag)]
              sm:before:absolute sm:before:top-full sm:before:left-1/2
              sm:before:translate-x-[-50%] sm:before:translate-y-[50%]
            `}
            style={{left: `${index * (100/3)}%`}}
            data-tag={tag}
          />
        )
      )}
      
      {/* process bar without tags */}
      {pointNumber &&
        Array.from(Array(pointNumber).keys()).map(index => (
          <span
            key={index}
            className={`
              w-[15px] h-[15px] block absolute top-1/2 translate-x-[-50%] translate-y-[-50%] rounded-[50%]
              ${index === currentStep
                ? 'bg-primary text-primary'
                : index < currentStep
                  ? 'bg-sub-word text-sub-word'
                  : 'bg-[#666] before:hidden' }
            `}
            style={{left: `${index * (100/(length - 1))}%`}}
          />
        ))
      }
    </div>
  )
}

export const PreviousButton = ({ currentStep, setCurrentStep, handleCancel }) => {
  const handlePrev = (e) => {
    e.preventDefault();
    (currentStep > 0)
      ? setCurrentStep(currentStep - 1)
      : handleCancel();
  };

  return (
    <button
      className={`
        px-4 py-2 w-[140px] sm:w-[200px] mx-[15px]
        text-primary border border-2 border-primary rounded
      `}
      onClick={handlePrev}
    >
      {!Boolean(currentStep) && 'CANCEL' || 'BACK'}
    </button>
  )
}

export const NextButton = ({ canClick, currentStep, stepLength, setCurrentStep, handleSubmit }) => {
  const handleNext = (e) => {
    e.preventDefault();
    (currentStep < stepLength - 1) 
      ? setCurrentStep(currentStep + 1)
      : handleSubmit();
  };

  return (
    <button
      className={`
        px-4 py-2 w-[140px] sm:w-[200px] mx-[15px]
        text-white border border-2 rounded
        ${canClick && 'bg-primary border-primary' || 'border-sub-button bg-sub-button cursor-default pointer-events-none'}
      `}
      onClick={handleNext}
    >
      {(currentStep === stepLength - 1) && 'DONE' || 'NEXT STEP'}
    </button>
  )
}