import { useEffect } from "react";
import styled, { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";

import Icons from "../../../global/icons";

import Reflective from "../../../images/id/id_card_reflective.png";
import Blurry from "../../../images/id/id_card_blurry.png";
import Cut from "../../../images/id/id_card_cut.png";
import Perfect from "../../../images/id/id_card_perfect.png";
import { useState } from "react";

import Lang from "../../../global/Lang";

import { LangString } from "../../../utils";

// sweetAlert
import { ErrorMySwal } from "../../../global/MySwal";

import Loading from "../../../components/Loading/Loading";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Title = styled.div`
  font-size: min(max(1.25vw, 22px), 24px);
  color: #fcfcfc;
  padding-bottom: 20px;

  ${RWD_MD(css`
    font-size: min(max(1.0416vw, 18px), 20px);
  `)};
`;

const SampleImgWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${RWD_MD(css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  `)};
`;

const SampleImgContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 22%;

  border-radius: 8px;
  padding: 20px;
  background-color: #22254a;

  ${RWD_MD(css`
    width: 100%;
  `)};
`;

const SampleImg = styled.div`
  padding: 20px;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: auto;
  }

  ${RWD_MD(css`
    width: 70%;
  `)};

  ${RWD_SM(css`
    padding: 10px;
    width: 100%;
  `)};
`;

const SampleImgText = styled.div`
  color: #fcfcfc;
`;

const SampleIcon = styled.div`
  width: 30px;
  height: 30px;

  svg {
    width: 30px;
    height: 30px;
  }

  & path {
    fill: ${(props) => (props.isPass ? "#1CAC2A" : "#f05d2f")};
  }
`;

const SampleListWrapper = styled.div`
  padding: 20px 20px 20px 0;
`;

const SampleListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SampleListItem = styled.div`
  display: flex;
  padding: 10px 0;
  align-items: center;
`;

const SampleListItemText = styled.div`
  padding-left: 10px;
  color: #fcfcfc;

  ${RWD_SM(css`
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const UploadImgWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;

  ${RWD_SM(css`
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  `)};
`;

const UploadImageContainer = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.15);
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  border-radius: 8px;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  background-image: ${(props) => `url(${props.src})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`;

const ImgInput = styled.input.attrs({ type: "file" })`
  visibility: hidden;
  width: 100%;
`;

const UploadImageLabel = styled.label`
  width: 100%;
  height: 250px;
  cursor: pointer;
`;

const UploadContentWrapper = styled.div`
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: #fcfcfc;
  position: absolute;
  width: 100%;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: ${(props) => (props.show ? "flex" : "none")};
`;

const UploadImgText = styled.div`
  color: #fcfcfc;
  padding: 10px 0;
`;

const UploadImgIcon = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;

  svg {
    width: 50px;
    height: 50px;
  }

  & path {
    stroke: #fcfcfc;
  }
`;

const DescriptionWrapper = styled.div`
  color: #fcfcfc;
  padding: 20px 0;
  font-size: 14px;
`;

const DescriptionContent = styled.div``;

const UploadImg = ({
  kycType,
  setIdFront,
  setIdBack,
  setPassport,
  idFront,
  idBack,
  passport,
}) => {
  const [viewIdFront, setViewIdFront] = useState("");
  const [viewIdBack, setViewIdBack] = useState("");
  const [viewPassport, setViewPassport] = useState("");

  const [isUpload, setIsUpload] = useState(false);

  const allowedFileTypes = ["image/jpeg", "image/png", "image/jpg"];

  const imgSampleData = [
    {
      iconType: <Icons.Success />,
      iconColor: "success",
      src: Perfect,
      text: <Lang datakey="identity_idcard_standard" defaultText="Standard" />,
    },
    {
      iconType: <Icons.Error />,
      iconColor: "error",
      src: Reflective,
      text: (
        <Lang datakey="identity_idcard_reflective" defaultText="Reflective" />
      ),
    },
    {
      iconType: <Icons.Error />,
      iconColor: "error",
      src: Blurry,
      text: <Lang datakey="identity_idcard_blurry" defaultText="Blurry" />,
    },
    {
      iconType: <Icons.Error />,
      iconColor: "error",
      src: Cut,
      text: (
        <Lang
          datakey="identity_idcard_notfully"
          defaultText="Not Fully Captured"
        />
      ),
    },
  ];

  useEffect(() => {
    const reader = new FileReader();
    if (idFront && idFront.type.match("image.*")) {
      setIsUpload(true);
      if (idFront.size > 5 * 1024 * 1024) {
        // 多國語言
        const title = LangString(
          "alert_filesize",
          "Unable to upload files larger than 5 mb."
        );
        ErrorMySwal({
          title: title,
          showConfimButton: false,
        }).then((result) => {
          if (result.isConfirmed) {
            setIsUpload(false);
            setIdFront("");
            setViewIdFront("");
          }
        });

        return;
      }

      if (!allowedFileTypes.some((type) => idFront.type.match(type))) {
        const title = LangString(
          "alert_filetype",
          "Only JPG, PNG and JPEG files are allowed."
        );
        ErrorMySwal({
          title: title,
          showConfimButton: false,
        }).then((result) => {
          if (result.isConfirmed) {
            setIsUpload(false);
            setIdFront("");
            setViewIdFront("");
          }
        });
        return;
      }

      reader.readAsDataURL(idFront);
      reader.onload = function (e) {
        setIsUpload(false);
        setViewIdFront(e.target.result);
      };
    }
  }, [idFront]);

  useEffect(() => {
    const reader = new FileReader();
    if (idBack && idBack.type.match("image.*")) {
      setIsUpload(true);
      if (idBack.size > 5 * 1024 * 1024) {
        // 多國語言
        const title = LangString(
          "alert_filesize",
          "Unable to upload files larger than 5 mb."
        );
        ErrorMySwal({
          title: title,
          showConfimButton: false,
        }).then((result) => {
          if (result.isConfirmed) {
            setIdBack("");
            setViewIdBack("");
            setIsUpload(false);
          }
        });

        return;
      }

      if (!allowedFileTypes.some((type) => idBack.type.match(type))) {
        const title = LangString(
          "alert_filetype",
          "Only JPG, PNG and JPEG files are allowed."
        );
        ErrorMySwal({
          title: title,
          showConfimButton: false,
        }).then((result) => {
          if (result.isConfirmed) {
            setIdBack("");
            setViewIdBack("");
            setIsUpload(false);
          }
        });
        return;
      }

      reader.readAsDataURL(idBack);
      reader.onload = function (e) {
        setIsUpload(false);
        setViewIdBack(e.target.result);
      };
    }
  }, [idBack]);

  useEffect(() => {
    const reader = new FileReader();

    if (passport && passport.type.match("image.*")) {
      setIsUpload(true);
      if (passport.size > 5 * 1024 * 1024) {
        // 多國語言
        const title = LangString(
          "alert_filesize",
          "Unable to upload files larger than 5 mb."
        );

        ErrorMySwal({
          title: title,
          showConfimButton: false,
        }).then((result) => {
          if (result.isConfirmed) {
            setPassport("");
            setViewPassport("");
            setIsUpload(false);
          }
        });
        return;
      }

      if (!allowedFileTypes.some((type) => passport.type.match(type))) {
        const title = LangString(
          "alert_filetype",
          "Only JPG, PNG and JPEG files are allowed."
        );
        ErrorMySwal({
          title: title,
          showConfimButton: false,
        }).then((result) => {
          if (result.isConfirmed) {
            setPassport("");
            setViewPassport("");
            setIsUpload(false);
          }
        });
        return;
      }

      reader.readAsDataURL(passport);
      reader.onload = function (e) {
        setIsUpload(false);
        setViewPassport(e.target.result);
      };
    }
  }, [passport]);

  return (
    <Wrapper>
      {isUpload && <Loading />}
      <Title>
        <Lang
          datakey="kyc_upload_document"
          defaultText="Upload Image Of ID Card"
        />
      </Title>
      <SampleImgWrapper>
        {imgSampleData.map((data) => (
          <SampleImgContainer>
            <SampleIcon isPass={data.iconColor === "success"}>
              {data.iconType}
            </SampleIcon>
            <SampleImg>
              <img src={data.src} />
            </SampleImg>
            <SampleImgText>{data.text}</SampleImgText>
          </SampleImgContainer>
        ))}
      </SampleImgWrapper>

      <SampleListWrapper>
        <SampleListContainer>
          <SampleListItem>
            <SampleIcon isPass={true}>
              <Icons.Success />
            </SampleIcon>
            <SampleListItemText>
              <Lang
                datakey="kyc_upload_document_government_issued"
                defaultText="Government-issued"
              />
            </SampleListItemText>
          </SampleListItem>
          <SampleListItem>
            <SampleIcon isPass={true}>
              <Icons.Success />
            </SampleIcon>
            <SampleListItemText>
              <Lang
                datakey="kyc_upload_document_readable"
                defaultText="Readable, well-lit, coloured images"
              />
            </SampleListItemText>
          </SampleListItem>
          <SampleListItem>
            <SampleIcon isPass={true}>
              <Icons.Success />
            </SampleIcon>
            <SampleListItemText>
              <Lang
                datakey="kyc_upload_document_size"
                defaultText="Original full-size, unedited documents"
              />
            </SampleListItemText>
          </SampleListItem>
          <SampleListItem>
            <SampleIcon isPass={false}>
              <Icons.Error />
            </SampleIcon>
            <SampleListItemText>
              <Lang
                datakey="kyc_upload_document_color"
                defaultText="No black and white images"
              />
            </SampleListItemText>
          </SampleListItem>
          <SampleListItem>
            <SampleIcon isPass={false}>
              <Icons.Error />
            </SampleIcon>
            <SampleListItemText>
              <Lang
                datakey="kyc_upload_document_expiration"
                defaultText="No edited or expired documents"
              />
            </SampleListItemText>
          </SampleListItem>
        </SampleListContainer>
      </SampleListWrapper>

      {kycType === 1 ? (
        <UploadImgWrapper>
          <UploadImageContainer src={viewIdFront}>
            <UploadImageLabel htmlFor="IdFront">
              <ImgInput
                accept="image/*"
                id="IdFront"
                onChange={(e) => setIdFront(e.target.files[0])}
              />
              <UploadContentWrapper show={!idFront}>
                <UploadImgIcon>
                  <Icons.Upload_img />
                </UploadImgIcon>
                <UploadImgText>
                  <Lang
                    datakey="kyc_upload_document_front"
                    defaultText="Upload ID Card Front Page"
                  />
                </UploadImgText>
              </UploadContentWrapper>
            </UploadImageLabel>
          </UploadImageContainer>

          <UploadImageContainer src={viewIdBack}>
            <UploadImageLabel htmlFor="IdBack">
              <ImgInput
                accept="image/*"
                id="IdBack"
                onChange={(e) => setIdBack(e.target.files[0])}
              />

              <UploadContentWrapper show={!idBack}>
                <UploadImgIcon>
                  <Icons.Upload_img />
                </UploadImgIcon>
                <UploadImgText>
                  <Lang
                    datakey="kyc_upload_document_back"
                    defaultText="Upload ID Card Back Page"
                  />
                </UploadImgText>
              </UploadContentWrapper>
            </UploadImageLabel>
          </UploadImageContainer>
        </UploadImgWrapper>
      ) : (
        <UploadImgWrapper style={{ gridTemplateColumns: "repeat(1, 1fr)" }}>
          <UploadImageContainer src={viewPassport}>
            <UploadImageLabel htmlFor="Passport">
              <ImgInput
                accept="image/*"
                id="Passport"
                onChange={(e) => setPassport(e.target.files[0])}
              />

              <UploadContentWrapper show={!passport}>
                <UploadImgIcon>
                  <Icons.Upload_img />
                </UploadImgIcon>
                <UploadImgText>
                  <Lang
                    datakey="kyc_upload_document_passport"
                    defaultText="Upload Passport Page"
                  />
                </UploadImgText>
              </UploadContentWrapper>
            </UploadImageLabel>
          </UploadImageContainer>
        </UploadImgWrapper>
      )}

      <DescriptionWrapper>
        <DescriptionContent>
          <Lang
            datakey="kyc_upload_document_notice"
            defaultText="File size must less than 5MB in jpg/jpeg/png format."
          />
        </DescriptionContent>
      </DescriptionWrapper>
    </Wrapper>
  );
};

export default UploadImg;
