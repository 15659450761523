import styled, { css } from "styled-components";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Wrapper = styled.div`
  width: 100%;
  background-color: #3a3d5a;
  border-radius: 8px;
  padding: 10px;
`;

const MiningLineChart = ({ chartData, chartLabel, chartName }) => {
  const options = {
    responsive: true,
    legend: {
      labels: {
        fontColor: "orange",
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#fcfcfc",
        },
      },
      y: {
        ticks: {
          color: "#fcfcfc",
          callback: function (value) {
            return `${Math.round(value)} % `;
          },
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: chartName,
        color: "#fcfcfc",
      },
    },
  };

  const borderColor = [
    "rgba(255, 99, 132, 1)",
    "rgba(54, 162, 235, 1)",
    "rgba(255, 206, 86, 1)",
    "rgba(75, 192, 192, 1)",
  ];

  const backgroundColor = [
    "rgba(255, 99, 132, 1)",
    "rgba(54, 162, 235, 1)",
    "rgba(255, 206, 86, 1)",
    "rgba(75, 192, 192, 1)",
  ];

  const calculator = (arr) => {
    return arr.map((data, index, array) => {
      let subArray = array.slice(0, index + 1);
      let sum = subArray.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );

      return sum;
    });
  };

  const data = {
    labels: chartLabel,
    datasets: chartData.map((item, index) => {
      return {
        label: item.Target,
        data: calculator(item.ProfitPercent.map((profit) => profit * 100)),
        borderColor: borderColor[index],
        backgroundColor: backgroundColor[index],
        borderWidth: 1,
        pointRadius: 2,
      };
    }),
  };

  return (
    <Wrapper>
      <Line options={options} data={data} />
    </Wrapper>
  );
};

export default MiningLineChart;
