import { useState, useEffect } from "react";
import { Provider } from "react-redux";
import { Router } from "./components/Router";

import store from "./v2/store";
import { DataContext } from "./utils/context";
import { data } from "./utils/lang";

// css
import "sweetalert2/dist/sweetalert2.min.css";
import "./style/style.css";
import { BrowserRouter } from "react-router-dom";

import { setLanguage, getLanguage } from "./v2/services/common";

const App = () => {
  const [userData, setUserData] = useState("");
  const [lang, setLang] = useState("en");
  const langData = data[lang];
  const [yearRates, setYearRates] = useState([]);

  const [isWaitApi, setIsWaitApi] = useState(false);
  const [waitingApi, setWaitingApi] = useState([]);

  const value = {
    lang,
    setLang,
    langData,
    userData,
    setUserData,
    yearRates,
    setYearRates,

    isWaitApi,
    setIsWaitApi,
    waitingApi,
    setWaitingApi,
  };

  // 避免連打 api 連線中斷錯誤
  useEffect(() => {
    if (isWaitApi) return;
    if (waitingApi.length === 0) return;

    setIsWaitApi(true);
    const callback = waitingApi[0];
    callback();
  }, [waitingApi]);

  useEffect(() => {
    if (isWaitApi) return;
    if (waitingApi.length === 0) return;

    setWaitingApi(waitingApi.slice(1));
  }, [isWaitApi]);

  // 預設語系為英文
  useEffect(() => {
    if (getLanguage()) return;
    setLanguage("En");
  }, []);

  return (
    <Provider store={store}>
      <DataContext.Provider value={value}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </DataContext.Provider>
    </Provider>
  );
};

export default App;
