import { useState } from 'react';
import ChartLogin from './ChartLogin';

import styled from 'styled-components';
import { Button } from '@mui/material';
import { FiMaximize, FiMinimize } from 'react-icons/fi';

import TrendFollowingBt from './TrendFollowingBt';

// #region styled
const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    background: #111;
`;

const ActionContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    transition: 250ms;
    overflow: hidden;
    height: 0;

    &.active {
        height: 8vh;
        padding: 1rem 2rem;
    }
`;

const Title = styled.div`
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.2rem;
    color: #555;
    letter-spacing: 1px;
`;

const ChartContainer = styled.div`
    flex: 1;
    overflow: hidden;
    padding: 0 2rem;
`;

const ToggleLayout = styled.div`
    position: fixed;
    z-index: 1;
    bottom: 1rem;
    right: 1rem;
    padding: 0.8rem;
    font-size: 1.8rem;
    display: inline-block;
    width: auto;
    cursor: pointer;
    border-radius: 50%;
    transition:
        left                250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        background-color    250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow          250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border-color        250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        color               250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    color: #fdfdfd;
    background: #1976d222;
    
    &:hover {
        background: #1976d2;
        box-shadow:
            0px 2px 4px -1px rgb(0 0 0 / 20%),
            0px 4px 5px 0px rgb(0 0 0 / 14%),
            0px 1px 10px 0px rgb(0 0 0 / 12%);
    }
    
    &.active {
        position: relative;
        left: 0;
        top: 0;
        color: #1976d2;
        background: transparent;
        box-shadow: none;
        padding: 0.65rem;
        font-size: 1.5rem;

        &:hover {
            background: #1976d2;
            color: #fdfdfd;
            box-shadow:
                0px 2px 4px -1px rgb(0 0 0 / 20%),
                0px 4px 5px 0px rgb(0 0 0 / 14%),
                0px 1px 10px 0px rgb(0 0 0 / 12%);
        }
    }
`;
// #endregion

const ChartDashboard = () => {
    const LOGIN_ACCOUNT = 'avachart';
    const logger = localStorage.getItem('chartLogger');

    const [isLogin, setIsLogin] = useState(!!logger && logger === LOGIN_ACCOUNT);
    const [isMenuOpen, setIsMenuOpen] = useState(true);

    const handleLogout = (e) => {
        e.preventDefault();
        localStorage.removeItem('chartLogger')
        setIsLogin(false);
    };

    if (!isLogin) return <ChartLogin allowAccount={LOGIN_ACCOUNT} setIsLogin={setIsLogin} />
    return (
        <Container>
            <ActionContainer className={isMenuOpen && 'active'}>
                <ToggleLayout className={isMenuOpen && 'active'} onClick={() => setIsMenuOpen(!isMenuOpen)}>
                    {isMenuOpen
                        ? <FiMaximize />
                        : <FiMinimize />
                    }
                </ToggleLayout>

                {/* 防止關閉選單時 Logout 按鈕跳到左邊 */}
                {!isMenuOpen && <div />}
                
                {/* title */}
                <Title>
                    Trend_Following_Bt
                </Title>
                
                <Button
                    variant="contained"
                    onClick={handleLogout}
                >
                    Logout
                </Button>
            </ActionContainer>
            
            <ChartContainer>
                <TrendFollowingBt isMenuOpen={isMenuOpen} />
            </ChartContainer>
        </Container>
    )
}

export default ChartDashboard
