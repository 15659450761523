import { IoIosArrowBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

const UserInfo = ({ data }) => {
  const { user, invite } = data;
  const navigate = useNavigate();

  if (!user) return (
    <div className='w-full h-[150px] px-6 flex items-center'>
      <span className='pb-2 pr-5 text-[4rem] font-bold'>
        {invite}
      </span>
      <div className='w-full py-4 child:text-blue overflow-x-auto'>
        <span className='relative block w-full text-[2rem] leading-[1em] font-medium'>
          PEOPLE
        </span>
        <span className='block text-[0.75rem] tracking-[0.015rem]'>
          in your memberships
        </span>
      </div>
    </div>
  );

  return (
    <div className='w-full h-[150px] px-6'>
      <div
        className='
          block text-[1rem] cursor-pointer
          flex items-center
        '
        onClick={() => navigate('/AVA/dashboard/memberships')}
      >
        <IoIosArrowBack className='text-blue' />
        <span className='text-blue'>BACK</span>
      </div>
      <div className='w-full py-4 child:text-blue overflow-x-auto'>
        <span className='relative block w-full text-[2rem] leading-[1em] font-medium'>
          {user}
        </span>
        <span className='block text-[0.75rem] tracking-[0.015rem]'>
          in your memberships
        </span>
      </div>
    </div>
  )
}

export default UserInfo
