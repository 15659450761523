import { MdPlaylistAdd } from 'react-icons/md';

const ShortCut = ({ id, customClass, shortcutName, handleClick, icon=null }) => {
  return (
    <div className={`
      text-center ${customClass}
    `}>
      <div
      id={id}
        className='
          p-2 h-[140px] w-[140px]
          2xl:h-[160px] 2xl:w-[160px]
          rounded bg-sub-blue cursor-pointer
          flex flex-col
        '
        onClick={handleClick}
      >
        <div className='flex-1 child:text-white child:text-[4rem] child:2xl:text-[5.5rem] child:mx-auto flex items-center'>
          { // icon
            icon === null
              ? <MdPlaylistAdd />
              : icon
          }
        </div>
        {/* shortcut name */}
        <span className='
          font-bold uppercase text-[.9rem] leading-[1.1rem] 2xl:text-[1rem] 2xl:leading-[1.2rem] text-white
        '>
          {shortcutName}
        </span>
      </div>
    </div>
  )
}

export default ShortCut
