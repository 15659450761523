import { createApi } from "@reduxjs/toolkit/query/react";
import defaultBaseQuery from "./query/defaultBaseQueryV2";
import { makeUrlencoded } from "./common";

export const avaPointApi = createApi({
  reducerPath: "avaPointApi",
  baseQuery: defaultBaseQuery,
  endpoints: (builder) => ({
    getAvaPointWithdrawalHistory: builder.query({
      query: () => ({
        url: "/api/AvaAPI/AvaPointWithdrawal",
        method: "POST",
        body: {
          mode: "Get",
        },
      }),
    }),
    getAvaPointDepoistHistory: builder.query({
      query: () => ({
        url: "/avapoint/History",
        method: "GET",
      }),
      providesTags: [{ type: "avapoint", id: "avapoint" }],
    }),
    setAvaPointTxid: builder.mutation({
      query: ({ body }) => ({
        url: "/avapoint/SetTxid",
        method: "POST",
        body: makeUrlencoded(body),
      }),
      invalidatesTags: [{ type: "avapoint", id: "avapoint" }],
    }),
    setAvaPointDeposit: builder.mutation({
      query: ({ body }) => ({
        url: "/avapoint/desposit",
        method: "POST",
        body: makeUrlencoded(body),
      }),
      invalidatesTags: [{ type: "avapoint", id: "avapoint" }],
    }),
    setWithdrawal: builder.mutation({
      query: ({ body }) => ({
        url: "/avapoint/withdrawal",
        method: "POST",
        body: makeUrlencoded(body),
      }),
      invalidatesTags: [{ type: "avapoint", id: "avapoint" }],
    }),
  }),
});

export const {
  useGetAvaPointWithdrawalHistoryQuery,
  useGetAvaPointDepoistHistoryQuery,
  useSetAvaPointTxidMutation,
  useSetAvaPointDepositMutation,
  useSetWithdrawalMutation,
} = avaPointApi;
