import { useEffect, useState } from "react";

// --- plugins
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// --- styled
import * as Styled from "./Styled";

// --- components
import ListReturnRow from "./ListReturnRow";
import { verifications } from "../../../utils/verifications";

const ListReturnGroupRow = ({
  row,
  updateGroup,
  handleSetUpdateGroup,
  onlyShowNotReturn,
}) => {
  const handleOpenSubTable = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };
  const handleAddGroupItems = (e) => {
    e.stopPropagation();
    if (
      row.ListReturn.filter(({ IsReturn }) => IsReturn === false).length === 0
    )
      return;
    handleSetUpdateGroup(!isChecked, row.AccountID, row.UserAddress, "group");
    setIsChecked(!isChecked);
  };

  const getIsChecked = () => {
    const updateGroupLength = updateGroup.filter(
      (item) =>
        item.AccountID === row.AccountID && item.UserAddress === row.UserAddress
    ).length;
    const needReturnGroupLength = row.ListReturn.filter(
      ({ IsReturn }) => IsReturn === false
    ).length;
    return updateGroupLength === needReturnGroupLength;
  };

  const [open, setOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(getIsChecked());

  useEffect(() => {
    setIsChecked(getIsChecked());
  }, [updateGroup, onlyShowNotReturn]);

  return (
    <>
      <TableRow
        sx={{
          "& > *": { borderBottom: "unset" },
          "&": { cursor: "pointer" },
          "&:hover": { background: "#eeea" },
        }}
        onClick={handleOpenSubTable}
      >
        {/* checkbox */}
        <TableCell onClick={handleAddGroupItems}>
          <input
            type="checkbox"
            checked={isChecked}
            disabled={
              row.ListReturn.filter(({ IsReturn }) => IsReturn === false)
                .length === 0
            }
            readOnly
          />
        </TableCell>

        <TableCell>{row.AccountID}</TableCell>
        <TableCell align="center">{row.Asset}</TableCell>
        <TableCell>{row.UserAddress}</TableCell>
        <TableCell>
          {(Math.floor(row.TotalNeedReturnAmount * 100000000) / 100000000)
            .toFixed(8)
            .replaceAll(verifications.point, ",")}
        </TableCell>
        <TableCell>
          {row.ListReturn?.length > 0 && (
            <IconButton aria-label="expand row" size="small">
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
      </TableRow>

      {/* collapse */}
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Styled.CollapseContentContainer>
              <Styled.CollaspeContentTitle>
                List Return
              </Styled.CollaspeContentTitle>
              <Styled.CollapseContentWrapper>
                <Table stickyHeader size="small" aria-label="sticky table">
                  <TableHead sx={{ "&": { cursor: "default" } }}>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>AhftGroupID</TableCell>
                      <TableCell>Periods</TableCell>
                      <TableCell>ReturnType</TableCell>
                      <TableCell>ThisPeriodInputAmount</TableCell>
                      <TableCell>EndTime</TableCell>
                      <TableCell>ReturnAmount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.ListReturn?.filter(
                      ({ IsReturn }) => !onlyShowNotReturn || IsReturn === false
                    )?.map((historyRow, index) => (
                      <ListReturnRow
                        key={index}
                        AccountID={row.AccountID}
                        UserAddress={row.UserAddress}
                        data={historyRow}
                        updateGroup={updateGroup}
                        handleSetUpdateGroup={handleSetUpdateGroup}
                      />
                    ))}
                    {row.ListReturn?.filter(
                      ({ IsReturn }) => !onlyShowNotReturn || IsReturn === false
                    ).length === 0 && (
                      <TableRow>
                        <TableCell colSpan={7}>
                          <Styled.EmptyContainer>Empty</Styled.EmptyContainer>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Styled.CollapseContentWrapper>
            </Styled.CollapseContentContainer>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ListReturnGroupRow;
