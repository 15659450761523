import styled, { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";

import Icons from "../../../global/icons";
import SelfIe from "../../../images/id/example_selfie.jpg";
import { useState } from "react";
import { useEffect } from "react";

// sweetAlert
import { ErrorMySwal } from "../../../global/MySwal";
import { LangString } from "../../../utils";
import Loading from "../../../components/Loading/Loading";
import Lang from "../../../global/Lang";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 45vh;
`;

const Title = styled.div`
  font-size: min(max(1.25vw, 22px), 24px);
  color: #fcfcfc;
  padding-bottom: 20px;

  ${RWD_MD(css`
    font-size: min(max(1.0416vw, 18px), 20px);
  `)};
`;

const Container = styled.div`
  display: flex;

  ${RWD_SM(css`
    flex-direction: column;
    align-items: center;
  `)};
`;

const SampleImgContainer = styled.div`
  width: 25%;
  border-radius: 8px;
  overflow: hidden;

  ${RWD_LG(css`
    width: 50%;
  `)};

  ${RWD_SM(css`
    width: 50%;
  `)};
`;

const SampleImg = styled.div`
  width: 100%;
  height: auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const SampleListWrapper = styled.div`
  padding-left: 20px;

  ${RWD_SM(css`
    padding-left: 0;
  `)};
`;

const SampleListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${RWD_SM(css`
    padding-top: 20px;
  `)};
`;

const SampleListItem = styled.div`
  display: flex;
  padding: 10px 0;
  align-items: center;

  ${RWD_MD(css`
    padding: 0 0 10px 0;
  `)};
`;

const SampleListItemText = styled.div`
  padding-left: 10px;
  color: #fcfcfc;

  ${RWD_SM(css`
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const SampleIcon = styled.div`
  width: 30px;
  height: 30px;

  svg {
    width: 30px;
    height: 30px;
  }

  & path {
    fill: ${(props) => (props.isPass ? "#1CAC2A" : "#f05d2f")};
  }
`;

const UploadImgWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
`;

const UploadImageContainer = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.15);
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  border-radius: 8px;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  background-image: ${(props) => `url(${props.src})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`;

const ImgInput = styled.input.attrs({ type: "file" })`
  visibility: hidden;
  width: 100%;
`;

const UploadImageLabel = styled.label`
  width: 100%;
  height: 250px;
  cursor: pointer;
`;

const UploadImgText = styled.div`
  padding: 10px 0;
`;

const UploadImgIcon = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;

  svg {
    width: 50px;
    height: 50px;
  }

  & path {
    stroke: #fcfcfc;
  }
`;

const UploadContentWrapper = styled.div`
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: #fcfcfc;
  position: absolute;
  width: 100%;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: ${(props) => (props.show ? "flex" : "none")};
`;

const DescriptionWrapper = styled.div`
  color: #fcfcfc;
  padding: 20px 0;
  font-size: 14px;
`;

const DescriptionContent = styled.div``;

const UploadSelfiePhoto = ({ selfie, setSelfie }) => {
  const [viewSelfie, setViewSelfie] = useState("");
  const [isUpload, setIsUpload] = useState(false);
  const allowedFileTypes = ["image/jpeg", "image/png", "image/jpg"];
  useEffect(() => {
    const reader = new FileReader();
    if (selfie && selfie.type.match("image.*")) {
      setIsUpload(true);
      if (selfie.size > 5 * 1024 * 1024) {
        // 多國語言
        const title = LangString(
          "alert_filesize",
          "Unable to upload files larger than 5 mb."
        );

        ErrorMySwal({
          title: title,
          showConfimButton: false,
        }).then((result) => {
          if (result.isConfirmed) {
            setSelfie("");
            setViewSelfie("");
            setIsUpload(false);
          }
        });

        return;
      }

      if (!allowedFileTypes.some((type) => selfie.type.match(type))) {
        const title = LangString(
          "alert_filetype",
          "Only JPG, PNG and JPEG files are allowed."
        );
        ErrorMySwal({
          title: title,
          showConfimButton: false,
        }).then((result) => {
          if (result.isConfirmed) {
            setSelfie("");
            setViewSelfie("");
            setIsUpload(false);
          }
        });
        return;
      }

      reader.readAsDataURL(selfie);
      reader.onload = function (e) {
        setIsUpload(false);
        setViewSelfie(e.target.result);
      };
    }
  }, [selfie]);
  return (
    <Wrapper>
      {isUpload && <Loading />}
      <Title>
        <Lang datakey="kyc_upload_document" defaultText="Take Selfie Photo" />
      </Title>
      <Container>
        <SampleImgContainer>
          <SampleImg>
            <img src={SelfIe} />
          </SampleImg>
        </SampleImgContainer>
        <SampleListWrapper>
          <SampleListContainer>
            <SampleListItem>
              <SampleIcon isPass={true}>
                <Icons.Success />
              </SampleIcon>
              <SampleListItemText>
                <Lang
                  datakey="kyc_selfie_photo_expression"
                  defaultText="Take a selfie of yourself with neutral expression"
                />
              </SampleListItemText>
            </SampleListItem>
            <SampleListItem>
              <SampleIcon isPass={true}>
                <Icons.Success />
              </SampleIcon>
              <SampleListItemText>
                <Lang
                  datakey="kyc_selfie_photo_visible"
                  defaultText="Make sure your whole face is visible, centred, and youreyes are
                  open"
                />
              </SampleListItemText>
            </SampleListItem>
            <SampleListItem>
              <SampleIcon>
                <Icons.Error />
              </SampleIcon>
              <SampleListItemText>
                <Lang
                  datakey="kyc_selfie_photo_crop"
                  defaultText="Do not crop your ID or use screenshots of your ID"
                />
              </SampleListItemText>
            </SampleListItem>
            <SampleListItem>
              <SampleIcon>
                <Icons.Error />
              </SampleIcon>
              <SampleListItemText>
                <Lang
                  datakey="kyc_selfie_photo_hide"
                  defaultText="Do not hide or alter parts of your face（No hats / beauty images
                  / headgear）"
                />
              </SampleListItemText>
            </SampleListItem>
          </SampleListContainer>
        </SampleListWrapper>
      </Container>

      <UploadImgWrapper>
        <UploadImageContainer src={viewSelfie}>
          <UploadImageLabel htmlFor="Selfie">
            <ImgInput
              accept="image/*"
              id="Selfie"
              onChange={(e) => setSelfie(e.target.files[0])}
            />
            <UploadContentWrapper show={!selfie}>
              <UploadImgIcon>
                <Icons.Upload_img />
              </UploadImgIcon>

              <UploadImgText show={!selfie}>
                <Lang
                  datakey="kyc_upload_selfie_photo"
                  defaultText="Upload Selfie Photo"
                />
              </UploadImgText>
            </UploadContentWrapper>
          </UploadImageLabel>
        </UploadImageContainer>
      </UploadImgWrapper>

      <DescriptionWrapper>
        <DescriptionContent>
          <Lang
            datakey="kyc_selfie_photo_notice"
            defaultText="File size must less than 5MB in jpg/jpeg/png format."
          />
        </DescriptionContent>
      </DescriptionWrapper>
    </Wrapper>
  );
};

export default UploadSelfiePhoto;
