import styled, { css } from "styled-components";
import { useState, useEffect } from "react";
import { QRCodeCanvas } from "qrcode.react";

import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const QrcodeContainer = styled.div`
  padding: 20px;
`;

const Description = styled.div`
  padding: 20px 0;
  color: #d3d3d3;
  text-align: center;
  font-size: clamp(14px, 0.8333vw, 16px);
`;

const InputWrapper = styled.div`
  display: flex;
  background: #fcfcfc;
  border-radius: 100px;
  width: 100%;
  max-width: 40vw;

  ${RWD_MD(css`
    max-width: 80%;
  `)};

  ${RWD_SM(css`
    max-width: 100%;
  `)};
`;

const InputEl = styled.input`
  background: #fcfcfc;
  padding: 1.0416vw 1.5625vw;
  width: 100%;
  border-radius: 100px;
  color: #151944;

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 14px), 16px);
    padding: 10px 20px;
  `)};
`;

const GetCodeButton = styled.button`
  color: #151944;
  white-space: nowrap;
  padding-right: 15px;
  font-size: min(max(1.0416vw, 14px), 20px);
`;

const TelegramStep = ({ setVerifyCode }) => {
  const tgUrl = `${process.env.REACT_APP_apiv2Host}/telegram/callback`;
  const verifiedUrl = `https://t.me/${
    process.env.REACT_APP_telegramBotName
  }?start=login&redirect_url=${encodeURIComponent(tgUrl)}`;

  const handleSendVerifyCode = () => {
    window.location.href = verifiedUrl;
  };

  return (
    <Wrapper>
      <Container>
        <QrcodeContainer>
          <QRCodeCanvas value={verifiedUrl} bgColor={"#fcfcfc"} size={160} />
        </QrcodeContainer>
        <Description>
          Scan the QR Code or click 'Get Code' on your mobile device to obtain
          the verification code.
        </Description>
        <InputWrapper>
          <InputEl onChange={(e) => setVerifyCode(e.target.value)} />
          <GetCodeButton onClick={handleSendVerifyCode}>Get Code</GetCodeButton>
        </InputWrapper>
      </Container>
    </Wrapper>
  );
};

export default TelegramStep;
