import styled, { css } from "styled-components";
import { RWD_MD, RWD_SM } from "../../utils/rwd";
import { useEffect, useState } from "react";
import Nft from "./components/Nft";
import PopupRawData from "./components/PopupHistoryData";
import Icons from "../../global/icons";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { AlertMySwal } from "../../global/MySwal";
import { LangString } from "../../utils";
import MarkdownView from "./components/MarkdownView";
import { useGetAccountProfileQuery } from "../../services/accountApi";
import {
  API_GetDownLineMarkDownAccount,
  API_GetHiveParallelMarkDown,
  API_GetTotalHiveBenefit,
} from "../../../utils/api";
import icons from "../../global/icons";
import { schemeDark2 } from "d3";
import Lang from "../../global/Lang";
const Wrapper = styled.div`
  height: 100%;
  padding-bottom: 100px;
  color: #fcfcfc;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 20px;
`;

const Title = styled.div`
  font-size: min(max(1.25vw, 22px), 24px);
  color: #fcfcfc;
  padding-right: 10px;
  text-transform: capitalize;

  ${RWD_MD(css`
    font-size: min(max(1.145vw, 20px), 22px);
  `)};
`;

const SubTitle = styled.div`
  font-size: min(max(1.0416vw, 18px), 20px);
  color: #fcfcfc;
  // width: 95%;

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 16px), 18px);
  `)};
`;

const ProfitWrapper = styled.div`
  padding-bottom: 50px;
`;

const ProfitContainer = styled.div`
  background-color: #22254a;
  padding: 40px 40px 0 40px;
  position: relative;
`;

const ProfitTitleConttainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding-bottom: 10px;
`;

const ProfitTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: baseline;
  ${RWD_SM(css`
    display: block;
  `)};
`;

const ProfitTitleText = styled.div`
  text-wrap: nowrap;
`;
const ProfitTitleUnity = styled.div`
  text-wrap: nowrap;
  ${RWD_SM(css`
    text-align: right;
  `)};
`;
const ProfileSubTitle = styled.div`
  padding-bottom: 40px;
  display: flex;
  align-items: baseline;

  ${RWD_MD(css`
    flex-wrap: wrap;
  `)};

  ${RWD_SM(css`
    display: block;
    margin-top: 5vw;
  `)}
`;

const ProfilesSubUnity = styled.div`
  ${RWD_MD(css`
    text-align: right;
  `)};
`;

const InviteWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  /* padding: 10px; */
  justify-content: space-between;
  width: 50%;
  ${RWD_MD(css`
    width: 100%;
    display: block;
  `)};
`;

const InviteTitle = styled.div`
  width: 30%;
  ${RWD_MD(css`
    width: 100%;
  `)};
`;

const InviteContentWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  font-size: min(max(1.0416vw, 18px), 20px);
  text-align: left;
  /* padding: 1.0416vw 1.5625vw; */
  padding: 10px 20px;
  border: 1px solid;
  border-radius: 8px;
  flex: 1;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  border-color: rgba(255, 255, 255, 0.15);
  color: #fcfcfc;

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 16px), 18px);
  `)};
`;

const InviteContent = styled.div`
  color: #fcfcfc;
`;

const CopyIconWrapper = styled.div`
  position: absolute;
  right: 2%;
  width: 28px;
  height: 28px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: 28px;
    height: 28px;

    & path {
      fill: #fcfcfc;
    }
  }

  ${RWD_SM(css`
    width: 25px;
    height: 25px;

    svg {
      width: 25px;
      height: 25px;
    }
  `)};
`;
const NumContainer = styled.div`
  font-size: min(max(1.5625vw, 28px), 30px);
  padding: 0 10px;
  font-family: Montserrat;
  font-weight: 600;
  ${RWD_MD(css`
    text-wrap: nowrap;
  `)};
  ${RWD_SM(css`
    display: block;
    text-align: center;
  `)};
`;

const MarkdownWrapper = styled.div`
  position: relative;
`;

const IconContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 40px;
  display: inline-flex;
  background: #22254a;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
  svg {
    width: 60px;
    height: 60px;
  }
`;

const PopupRawDataBtn = styled.button`
  font-size: min(max(0.833vw, 14px), 16px);
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  padding: 0.78125vw 1.3vw;
  border: 1px solid;
  border-color: rgba(255, 255, 255, 0.15);
  border-radius: 8px;
  &:hover {
    border-color: #0aecd1;
    color: #0aecd1;
  }
`;

const GetTotalHiveBenefit = async () => {
  const res = await API_GetTotalHiveBenefit();
  if (res.ResultCode === 999) return console.error(res.Message);
  return res;
};

const GetMarkdownInfo = async () => {
  const res = await API_GetDownLineMarkDownAccount();
  if (res.ResultCode === 999) return console.error(res.Message);
  return res;
};

const GetHiveParallelMarkDown = async (level) => {
  const res = await API_GetHiveParallelMarkDown(level);
  if (res.ResultCode === 999) return console.error(res.Message);
  return res;
};

const Member = () => {
  const [invitedNumber, setInvitedNumber] = useState(0);
  const [hiveBenefitData, setHiveBenefitData] = useState({
    userTotalHiveBenefit: "0",
    userDownlineHiveBenefit: "0",
    userL1HHiveBenefit: "0",
    userL2HHiveBenefit: "0",
    userL3HHiveBenefit: "0",
  });
  const [markdownData, setMarkdownData] = useState("");
  const [showPopupData, setShowPopupData] = useState(false);
  const [fakeInviteUrl, setFakeInviteUrl] = useState("");

  useEffect(() => {
    const hiveBenefit = async () => {
      const data = await GetTotalHiveBenefit();
      if (data) {
        setHiveBenefitData((prevState) => ({
          ...prevState,
          userTotalHiveBenefit: data.TotalHiveBenefit,
          userDownlineHiveBenefit: data.DownlineTotalHiveBenefit,
          userL1HHiveBenefit: data.L1HTotalHiveBenefit,
          userL2HHiveBenefit: data.L2HTotalHiveBenefit,
          userL3HHiveBenefit: data.L3HTotalHiveBenefit,
        }));
      }
    };
    hiveBenefit();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const data = await GetMarkdownInfo();
      if (data) {
        setMarkdownData(data.MarkDownStr);
        setInvitedNumber(data.InvitedAvaNumber);
      }
    };
    fetchData();
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const totalHive = await GetTotalHiveBenefit();
  //     if(totalHive){
  //       setuserTotalHiveBenefit(totalHive.TotalHiveBenefit);
  //     }
  //   };
  //   fetchData();
  // }, []);

  //取得 Ref Code API
  const {
    data: profileData,
    error,
    isError,
    isSuccess,
    refetch,
    isFetching,
  } = useGetAccountProfileQuery();
  const referenceCode = profileData?.Profile?.ReferenceCode;

  // Ref Link
  const host = window.location.host;
  const protocol = host.includes("localhost") ? "http" : "https";
  const inviteUrl = `${protocol}://${host}/v2/register/?invites=${referenceCode}`;
  useEffect(() => {
    if (host.includes("develop")) {
      setFakeInviteUrl(`https://develop.d1ak8ax8gn2hh3...`);
    } else if (host.includes("avairobot")) {
      setFakeInviteUrl(`https://www.avairobot.com/v2/...`);
    } else {
      setFakeInviteUrl(`${protocol}://${host}...`);
    }
  }, [host, protocol, referenceCode]);

  //copy
  const handleCopy = (type) => {
    // 多國語言
    const CopyResString = LangString("alert_copy", "Copy successful");
    let text;
    if (type === "reference") {
      text = referenceCode;
    } else {
      text = inviteUrl;
    }
    navigator.clipboard.writeText(text).then(() => {
      AlertMySwal({
        title: CopyResString,
        showConfimButton: false,
      });
    });
  };

  const OpenPopupTable = () => {
    Swal.fire({
      title: "Loading...",
      showConfirmButton: false,
      allowOutsideClick: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });
    setShowPopupData(true);
  };

  const closePopupTable = () => {
    setShowPopupData(false);
  };

  const handleMarkdownBtn = async (level) => {
    Swal.fire({
      title: "Loading...",
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    let data;
    if (level === "DownLine") {
      data = await GetMarkdownInfo();
    } else {
      data = await GetHiveParallelMarkDown(level);
    }
    if (data) {
      setMarkdownData(data.MarkDownStr);
    }
    Swal.close();
  };

  return (
    <Wrapper>
      <Container>
        <TitleWrapper>
          <Title>Member</Title>
        </TitleWrapper>
        <ProfitWrapper>
          <ProfitTitleConttainer>
            <SubTitle>
              <Lang
                datakey="hive_profit_sharing"
                defaultText="Hive Profit Sharing"
              />
            </SubTitle>
            <PopupRawDataBtn onClick={OpenPopupTable}>
              <Lang datakey="history_data" defaultText="History Data" />
            </PopupRawDataBtn>
          </ProfitTitleConttainer>
          <ProfitContainer>
            <ProfitTitle>
              <ProfitTitleText>
                <Lang
                  datakey="member_you_invited"
                  defaultText="You Have Invited"
                />
              </ProfitTitleText>
              <NumContainer>{invitedNumber}</NumContainer>
              <ProfitTitleUnity>
                <Lang datakey="member_join_ava" defaultText="Person to AVA" />
              </ProfitTitleUnity>
            </ProfitTitle>
            <ProfileSubTitle>
              <ProfitTitleText>
                <Lang
                  datakey="member_you_earn"
                  defaultText="You Already Earn"
                />
              </ProfitTitleText>
              <NumContainer>
                $ {hiveBenefitData.userTotalHiveBenefit}
              </NumContainer>
              <ProfilesSubUnity>AVA Point</ProfilesSubUnity>
            </ProfileSubTitle>
            <InviteWrapper>
              <InviteTitle>
                <Lang datakey="referral_code" defaultText="Referral Code" />
              </InviteTitle>
              <InviteContentWrapper>
                <InviteContent>{referenceCode}</InviteContent>
                <CopyIconWrapper onClick={() => handleCopy("reference")}>
                  <Icons.Copy />
                </CopyIconWrapper>
              </InviteContentWrapper>
            </InviteWrapper>
            <InviteWrapper>
              <InviteTitle>
                <Lang datakey="referral_link" defaultText="Referral Link" />
              </InviteTitle>
              <InviteContentWrapper>
                <InviteContent>{fakeInviteUrl}</InviteContent>
                <CopyIconWrapper onClick={() => handleCopy("invite")}>
                  <Icons.Copy />
                </CopyIconWrapper>
              </InviteContentWrapper>
            </InviteWrapper>
          </ProfitContainer>
          {showPopupData && <PopupRawData onClose={closePopupTable} />}
          <MarkdownWrapper>
            <IconContainer>
              <Icons.Member_Mindmap />
            </IconContainer>
            <MarkdownView
              markdownData={markdownData}
              userHiveBenefit={hiveBenefitData}
              onButtonClick={handleMarkdownBtn}
            />
          </MarkdownWrapper>
        </ProfitWrapper>
        <SubTitle>
          <Lang datakey="member_nft_sharing" defaultText="NFT Sharing" />
        </SubTitle>
        <Nft />
      </Container>
    </Wrapper>
  );
};

export default Member;
