import { createApi } from "@reduxjs/toolkit/query/react";
import { makeUrlencoded } from "./common";
import defaultBaseQuery from "./query/defaultBaseQueryV2";

export const accountApi = createApi({
  reducerPath: "accountApi",
  baseQuery: defaultBaseQuery,
  endpoints: (builder) => ({
    getAccountProfile: builder.query({
      query: () => ({
        url: "/account/GetAccountProfile",
        method: "GET",
      }),
      providesTags: [{ type: "account", id: "account" }],
    }),

    setAccountProfile: builder.mutation({
      query: ({ body }) => ({
        url: "/account/UpdateAccountProfile",
        method: "POST",
        body: makeUrlencoded(body),
      }),
      invalidatesTags: [{ type: "account", id: "account" }],
    }),

    getAccountApikey: builder.query({
      query: () => ({
        url: "/account/GetAccountApikeys",
        method: "GET",
      }),
      keepUnusedDataFor: 3,
      providesTags: [{ type: "apikey", id: "apikey" }],
    }),

    setAccountApikey: builder.mutation({
      query: ({ body }) => ({
        url: "/account/SetAccountApikey",
        method: "POST",
        body: makeUrlencoded(body),
      }),
      invalidatesTags: [{ type: "apikey", id: "apikey" }],
    }),

    getAccountApikeyBalance: builder.mutation({
      query: ({ body }) => ({
        url: "/account/GetAccountApikeyBalance",
        method: "POST",
        body: makeUrlencoded(body),
      }),
      providesTags: [{ type: "apikey", id: "apikey" }],
    }),

    setAccountWithdrawal: builder.mutation({
      query: ({ body }) => ({
        url: "/account/SetAccountWithdrawal",
        method: "POST",
        body: makeUrlencoded(body),
      }),
      invalidatesTags: [{ type: "account", id: "account" }],
    }),

    setRecommender: builder.mutation({
      query: ({ body }) => ({
        url: "/account/SetRecommender",
        method: "POST",
        body: makeUrlencoded(body),
      }),
      invalidatesTags: [{ type: "account", id: "account" }],
    }),
    updateWatchingTutorial: builder.mutation({
      query: ({ body }) => ({
        url: "/account/FinishedWatchingTutorial",
        method: "POST",
        body: makeUrlencoded(body),
      }),
      invalidatesTags: [{ type: "account", id: "account" }],
    }),
    updateReadingTerms: builder.mutation({
      query: () => ({
        url: "/account/FinishedReadingTerms",
        method: "POST",
      }),
      invalidatesTags: [{ type: "account", id: "account" }],
    }),
  }),
});

export const {
  useGetAccountProfileQuery,
  useSetAccountProfileMutation,
  useGetAccountApikeyQuery,
  useSetAccountApikeyMutation,
  useGetAccountApikeyBalanceMutation,
  useSetAccountWithdrawalMutation,
  useSetRecommenderMutation,
  useUpdateWatchingTutorialMutation,
  useUpdateReadingTermsMutation,
} = accountApi;
