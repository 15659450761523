import { useEffect, useState } from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import { useData } from "../utils/context";

// --- components ---
import { Header, MainHeader } from "./Global/Header";
import Asidebar from "./Global/Asidebar";
import Footer from "./Global/Footer";
import Loading from "./Global/Loading";

// --- api ---
import { API_GetAccountData, API_GetYearRate_binance } from "../utils/api";
import Swal from "sweetalert2/dist/sweetalert2";

const ProtectivePage = () => {
  // --- call api ---
  const getAccountData = async () => {
    const res = await API_GetAccountData();
    // if (!!res.error) return console.error(res.error);
    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return Swal.fire(res.Message);
    setUserData(res);
    getYearRate_binance();
  };
  const getYearRate_binance = async () => {
    const res = await API_GetYearRate_binance();
    if (!!res.error) return console.error(res.error);
    setYearRates(res);
    setLoading(false);
  };

  // --- states ---
  const { userData, setUserData, setYearRates } = useData("");
  const [loading, setLoading] = useState(true);

  // --- effect ---
  useEffect(() => {
    setUserData("get");
  }, []);

  useEffect(() => {
    if (userData === "get") {
      getAccountData();
    }
  }, [userData]);

  // --- components ---
  if (loading) return <Loading />;
  return (
    <main className="relative w-full lg:pl-[240px] pt-36 sm:pt-[150px] lg:pt-[80px]">
      <Asidebar />
      <MainHeader />
      <div className="w-full">
        <Outlet />
      </div>
    </main>
  );
};

export const GlobalLayout = ({ element }) => {
  if (localStorage.getItem("token"))
    return <Navigate to="/v2/datacenter?tab=personaldata" />;
  return <main>{element}</main>;
};

export const LandingLayout = () => {
  return (
    <main>
      {/* <Header /> */}
      <Outlet />
      {/* <Footer /> */}
    </main>
  );
};

export const ProtectiveLayout = () => {
  const location = useLocation();
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    token && setLoading(false);
    localStorage.getItem("token")
      ? setToken(localStorage.getItem("token"))
      : setLoading(false);
  }, [token]);

  return (
    <>
      {!loading &&
        (token ? (
          <ProtectivePage />
        ) : (
          <Navigate to="/AVA/login" state={{ from: location }} />
        ))}
    </>
  );
};
