import { QRCodeCanvas } from "qrcode.react";
import { MdOutlineFileCopy } from "react-icons/md";
// --- images ---
// import Light_History_icon from '../../img/light_history.png';
// import Dark_History_icon from '../../img/dark_history.png';

const Deposit = () => {
  return (
    <>
      {/* <div className="absolute top-4 right-2">
        <img
          id="history"
          className="cursor-pointer"
          src={localStorage.getItem('theme') === 'dark' ? Dark_History_icon : Light_History_icon}
        />
      </div> */}
      <form className="text-[0.8rem] md:text-[1rem]">
        <div className="p-3">
          <label>System Address</label>
          <div className="flex py-2 justify-between">
            <input
              type="text"
              className="
                block w-[48%] rounded w-full text-center pointer-events-none
                bg-transparent dark:bg-transparent border-sub-word dark:border-sub-word
              "
              value="USDT"
              readOnly
            />
            <input
              type="text"
              className="block w-[48%] rounded w-full text-center pointer-events-none bg-transparent dark:bg-transparent border-sub-word dark:border-sub-word"
              value="TRC20"
              readOnly
            />
          </div>
          <div className="relative">
            <input
              type="text"
              className="block rounded w-full px-3 py-1 pointer-events-none bg-transparent dark:bg-transparent border-sub-word dark:border-sub-word"
              value="TVLkHDJmgDJ4Wn7DjFpLMMrmDnQS42owzL"
              readOnly
            />
            <MdOutlineFileCopy className="block text-[1.2rem] md:text-[1.5rem] p-1 absolute z-1 dark:bg-black-main right-2 top-1/2 -translate-y-1/2 cursor-pointer text-word-dark dark:word-text-light" />
          </div>
        </div>
        <div className="p-3 flex justify-center">
          <div className="mx-auto bg-white p-4 rounded">
            <QRCodeCanvas
              id="qrcode"
              className="md:hidden"
              size="60"
              value="TVLkHDJmgDJ4Wn7DjFpLMMrmDnQS42owzL"
              bgColor={"transparent"}
            />
            <QRCodeCanvas
              id="qrcode"
              className="hidden md:block"
              size="120"
              value="TVLkHDJmgDJ4Wn7DjFpLMMrmDnQS42owzL"
              bgColor={"transparent"}
            />
          </div>
        </div>
        <div className="p-3 mt-4">
          <label>Deposit Token</label>
          <input
            id="depositAmount"
            type="text"
            className="block rounded w-full px-3 py-1 border mt-1"
          />
        </div>
        <div className="p-3">
          <label>TXID</label>
          <input
            id="txid"
            type="text"
            className="block rounded w-full px-3 py-1 border mt-1"
          />
        </div>
        <div className="p-3 flex items-start">
          <input id="policy" type="checkbox" className="translate-y-1.5 mr-2" />
          <label htmlFor="policy">
            我知道填入的TXID是正確的，並清楚了解如果填入錯誤資訊可能會導致帳務延宕。
          </label>
        </div>
      </form>
    </>
  );
};

export default Deposit;
