import styled, { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";
import Lang from "../../../global/Lang";
import { handleColorType } from "../../../utils/ButtonStyle";
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  useGetMiningQuery,
  useGetMiningPackageQuery,
  useApplyTransferPackageMutation,
} from "../../../services/miningApi";
import { useDispatch, useSelector } from "react-redux";
import { setMiningPackage, selectMining } from "../../../slices/miningSlice";
import { routes } from "../../../../components/Router";
// component
import SelectPackage from "../components/SelectPackage";
import CheckInformation from "../components/CheckInformation";
import Review from "../components/Review";

import { SuccessMySwal, ErrorMySwal } from "../../../global/MySwal";
import { LangString } from "../../../utils";
import { getLanguage } from "../../../services/common";
import { selectSystem } from "../../../slices/systemSlice";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Title = styled.div`
  font-size: min(max(1.25vw, 22px), 24px);
  color: #fcfcfc;
  padding-right: 10px;
  text-transform: capitalize;
  padding-bottom: 20px;
  font-weight: bold;

  ${RWD_MD(css`
    font-size: min(max(1.145vw, 20px), 22px);
  `)};
`;

const ContentWrapper = styled.div`
  /* padding: 30px 35px;
  border-radius: 8px; */
  height: 100%;
  flex-direction: column;

  /* ${RWD_MD(css`
    padding: 20px 25px;
  `)};

  ${RWD_SM(css`
    padding: 20px 15px;
    margin-bottom: 50px;
  `)}; */
`;

const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 1.5625vw;

  width: 100%;
  overflow: hidden;

  ${RWD_LL(css`
    width: 100%;
    position: absolute;
    top: 0;
    margin-right: 0;
  `)};
`;

const ProgressContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  ${RWD_LL(css`
    width: 100%;
  `)};
`;

const ProgressItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${RWD_LL(css`
    width: 100%;
  `)};
`;

const ProgressItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  /* transform: translateX(-25%); */

  ${RWD_LL(css`
    align-items: center;
  `)};

  ::before {
    position: absolute;
    content: "";
    border-bottom: 4px solid;
    border-color: ${(props) => (props.status ? "#0AECD1" : "#3A3D5A")};
    width: 50%;
    top: 15px;
    left: 0;
    z-index: 1;

    /* ${RWD_LL(css`
      width: 50%;
    `)}; */
  }

  ::after {
    position: absolute;
    content: "";
    border-bottom: 4px solid;
    border-color: ${(props) => (props.active ? "#0AECD1" : "#3A3D5A")};
    width: 50%;
    top: 15px;
    right: 0%;
    z-index: 1;

    ${RWD_LL(css`
      width: 50%;
    `)};
  }

  :first-child::before {
    content: none;

    ${RWD_LL(css`
      content: "";
    `)};
  }

  :last-child::after {
    content: none;

    ${RWD_LL(css`
      content: "";
    `)};
  }
`;

const ProgressItemText = styled.div`
  padding-top: 5px;
  /* position: absolute; */
  /* top: 100%; */
  text-align: center;
  /* position: absolute; */
  /* white-space: nowrap; */
  /* transform: translateX(calc(50% - 15px)); */

  font-size: min(max(0.833vw, 12px), 16px);
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  color: ${(props) => (props.status ? "#0AECD1" : "#FCFCFC")};

  ${RWD_LL(css`
    transform: translateX(0%) translateY(0%);
  `)};
`;

const ProgressItemNumber = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: ${(props) => (props.status ? "#3A3D5A" : "#FCFCFC")};
  background: ${(props) => (props.status ? "#0AECD1" : "#3A3D5A")};
`;

const StepActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* flex-wrap: wrap; */

  width: 100%;
  background-color: #151944;
  padding-bottom: 3.854vw;
  padding-top: 3.854vw;
  margin-top: auto;
  position: relative;

  ${RWD_LL(css`
    padding: 100px 0 20px 0;
    /* flex-direction: column; */
    /* height: 200px; */
    align-items: flex-end;
  `)};
`;

const ButtonWrapper = styled.div`
  display: flex;
  cursor: pointer;

  button:first-child {
    margin-right: 1.5625vw;
  }
`;

const Button = styled.button`
  border: ${({ type }) => handleColorType(type).border};
  border-radius: 60px;
  width: min(max(7.0833vw, 100px), 135px);
  background: ${({ type }) => handleColorType(type).bg};
  text-align: center;
`;

const ButtonText = styled.span`
  font-size: min(max(1.0416vw, 16px), 20px);
  color: #fcfcfc;
  display: inline-flex;
  padding: 0.52vw 1.3vw;
  ${RWD_LG(css`
    padding: 8px 18px;
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const TransferPackage = () => {
  const navigate = useNavigate();
  // user mining list
  const [selectMiningData, setSelectMiningData] = useState([]);
  const { GroupId } = useParams();

  const dispatch = useDispatch();
  const {
    data: miningResponse,
    isSuccess: miningResponseIsSuccess,
    isError: miningResponseIsError,
    error: miningResponseError,
  } = useGetMiningQuery();

  useEffect(() => {
    if (miningResponseIsSuccess) {
      setSelectMiningData(
        miningResponse?.Minings.filter((data) => data.GroupId == GroupId)
      );
      setName(
        miningResponse?.Minings.filter((data) => data.GroupId == GroupId)[0]
          ?.Name
      );
      setAsset(
        miningResponse?.Minings.filter((data) => data.GroupId == GroupId)[0]
          .Target
      );
    }

    if (miningResponseIsError) {
      ErrorMySwal({
        title:
          miningResponseError?.data?.ResultLanguages[getLanguage()] ||
          miningResponseError?.data?.Message,
      });
    }
  }, [miningResponse, miningResponseIsError]);

  const {
    data: miningPackageResponse,
    isSuccess: miningPackageResponseIsSuccess,
    isError: miningPackageResponseIsError,
    error: miningPackageResponseError,
    isLoading: miningPackageResponseIsLoading,
  } = useGetMiningPackageQuery();

  const [
    _transferPackage,
    {
      data: transferPackageResponse,
      isSuccess: transferPackageResponseIsSuccess,
      isError: transferPackageResponseIsError,
      error: transferPackageResponseError,
    },
  ] = useApplyTransferPackageMutation();

  const stepDirection = [
    // "選擇Package", "確認Package"
    <Lang
      datakey="addpackage_step_selectpackage"
      defaultText="Select A Package"
    />,
    <Lang
      datakey="addpackage_step_confirmpackage"
      defaultText="Confirm The Package"
    />,
  ];
  const [activeStep, setActiveStep] = useState(0);
  const [packageData, setPackageData] = useState([]);

  // select package
  const [packageId, setPackageId] = useState("");
  const [riskType, setRiskType] = useState("");
  const [asset, setAsset] = useState("");

  // review
  const [name, setName] = useState("");
  const [transferCheck, setTransferCheck] = useState("");

  // riskType
  const [riskTypeData, setRiskTypeData] = useState([]);

  const selectPackageIsValid = packageId !== "" && riskType !== "";
  const reviewIsValid = name != "" && transferCheck;

  // const selectData = useSelector(selectSystem).enums;

  // useEffect(() => {
  //   if (selectData.length > 0) {
  //     const selectDataList = Object.entries(
  //       selectData?.filter((list) => list?.GroupName === "PackageRiskType")?.[0]
  //         ?.Items
  //     ).map((item) => item[1]);

  //     const whiteList = selectDataList.map((type) => {
  //       if (
  //         miningPackageResponse?.Packages.filter(
  //           (data) => data.Type === type && data.IsAvailable
  //         ).length > 0
  //       ) {
  //         return type;
  //       } else {
  //         return;
  //       }
  //     });

  //     setRiskTypeData(
  //       Object.entries(
  //         selectData?.filter(
  //           (list) => list?.GroupName === "PackageRiskType"
  //         )?.[0]?.Items
  //       ).filter(function (event) {
  //         return whiteList.indexOf(event[1]) > -1;
  //       })
  //     );
  //   }
  // }, [selectData]);

  // Information step
  useEffect(() => {
    if (miningPackageResponseIsSuccess) {
      dispatch(setMiningPackage(miningPackageResponse));

      // 過濾 IsAvailable = true 的 package
      setPackageData(
        miningPackageResponse?.Packages.filter((data) => data.IsAvailable)
      );

      if (miningPackageResponseIsError) {
        console.log(miningPackageResponseError?.data.Message);
      }
    }
  }, [miningPackageResponse, miningPackageResponseIsError]);

  useEffect(() => {
    if (transferPackageResponseIsSuccess) {
      // 多國語言
      const title = LangString(
        "alert_transfer_success",
        "Reservation conversion successful"
      );
      const confirmText = LangString("confirm", "Confirm");

      SuccessMySwal({
        title: title,
        showCancelButton: false,
        confirmButtonText: confirmText,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(routes.mining_v2);
        }
      });
    }

    if (transferPackageResponseIsError) {
      ErrorMySwal({
        title:
          transferPackageResponseError?.data?.ResultLanguages[getLanguage()] ||
          transferPackageResponseError?.data?.Message,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(routes.mining_v2);
        }
      });
    }
  }, [transferPackageResponse, transferPackageResponseIsError]);

  const handleBackStep = () => {
    if (activeStep === 0) {
      return;
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  const handleNextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const handleCancel = () => {
    navigate(routes.mining_v2);
  };

  const handleTransfer = () => {
    _transferPackage({
      body: {
        GroupId: GroupId,
        PackageId: packageId,
        AhftName: name,
      },
    });
  };

  //  換頁置頂
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  return (
    <Wrapper>
      <Title>
        <Lang datakey="transfer_package" defaultText="Transfer Package" />
      </Title>
      {miningPackageResponse && (
        <ContentWrapper>
          {activeStep === 0 && (
            <SelectPackage
              riskType={riskType}
              riskTypeData={riskTypeData}
              setRiskType={setRiskType}
              setRiskTypeData={setRiskTypeData}
              packageData={packageData}
              packageId={packageId}
              setPackageId={setPackageId}
              asset={asset}
              setAsset={setAsset}
              isLoading={miningPackageResponseIsLoading}
            />
          )}

          {activeStep === 1 && (
            <Review
              name={name}
              setName={setName}
              packageId={packageId}
              selectMiningData={selectMiningData}
              setTransferCheck={setTransferCheck}
              allMiningPackages={miningPackageResponse}
            />
          )}
        </ContentWrapper>
      )}
      <StepActionWrapper>
        {activeStep !== 0 && (
          <ButtonWrapper style={{ marginRight: "auto" }}>
            <Button type="stroke" onClick={() => handleBackStep()}>
              <ButtonText>
                <Lang datakey="laststep" defaultText="Back" />
              </ButtonText>
            </Button>
          </ButtonWrapper>
        )}
        <ProgressWrapper>
          <ProgressContainer>
            {Array.from({ length: 2 }, (v, i) => (
              <ProgressItem
                key={i}
                status={activeStep >= i}
                active={
                  activeStep > i || activeStep == stepDirection.length - 1
                }
              >
                <ProgressItemWrapper>
                  <ProgressItemNumber status={activeStep >= i}>
                    {i + 1}
                  </ProgressItemNumber>
                  <ProgressItemText status={activeStep >= i}>
                    {stepDirection[i]}
                  </ProgressItemText>
                </ProgressItemWrapper>
              </ProgressItem>
            ))}
          </ProgressContainer>
        </ProgressWrapper>
        {(() => {
          switch (activeStep) {
            case 0:
              return (
                <ButtonWrapper>
                  <Button type="storke" onClick={() => handleCancel()}>
                    <ButtonText>
                      <Lang datakey="cancel" defaultText="Cancel" />
                    </ButtonText>
                  </Button>
                  <Button
                    disabled={!selectPackageIsValid}
                    type={selectPackageIsValid ? "fill" : "disabled"}
                    onClick={() => handleNextStep()}
                  >
                    <ButtonText>
                      <Lang datakey="nextstep" defaultText="Next" />
                    </ButtonText>
                  </Button>
                </ButtonWrapper>
              );
            // case 1:
            //   return (
            //     <ButtonWrapper>
            //       <Button type="storke" onClick={() => handleCancel()}>
            //         <ButtonText>
            //           <Lang datakey="cancel" defaultText="Cancel" />
            //         </ButtonText>
            //       </Button>
            //       <Button
            //         // disabled={!selectPackageIsValid}
            //         // type={selectPackageIsValid ? "fill" : "disabled"}
            //         onClick={() => handleNextStep()}
            //       >
            //         <ButtonText>
            //           <Lang datakey="nextstep" defaultText="Next" />
            //         </ButtonText>
            //       </Button>
            //     </ButtonWrapper>
            //   );
            // case 2:
            //   return (
            //     <ButtonWrapper>
            //       <Button type="storke" onClick={() => handleCancel()}>
            //         <ButtonText>
            //           <Lang datakey="cancel" defaultText="Cancel" />
            //         </ButtonText>
            //       </Button>
            //       <Button
            //         disabled={!confirmIsValid}
            //         type={confirmIsValid ? "fill" : "disabled"}
            //         onClick={() => handleNextStep()}
            //       >
            //         <ButtonText>
            //           <Lang datakey="nextstep" defaultText="Next" />
            //         </ButtonText>
            //       </Button>
            //     </ButtonWrapper>
            //   );

            case 1:
              return (
                <ButtonWrapper>
                  <Button type="storke" onClick={() => handleCancel()}>
                    <ButtonText>
                      <Lang datakey="cancel" defaultText="Cancel" />
                    </ButtonText>
                  </Button>
                  <Button
                    onClick={() => handleTransfer()}
                    disabled={!reviewIsValid}
                    type={reviewIsValid ? "fill" : "disabled"}
                  >
                    <ButtonText>
                      <Lang datakey="done" defaultText="Done" />
                    </ButtonText>
                  </Button>
                </ButtonWrapper>
              );
          }
        })()}
      </StepActionWrapper>
    </Wrapper>
  );
};

export default TransferPackage;
