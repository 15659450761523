import { useState } from 'react';
import { useData } from '../../../utils/context';

// --- components ---
import DonutChart from './DonutChart';

// --- plugins ---
import { FaDollarSign } from 'react-icons/fa';

// --- images ---
import switch_icon from '../../../img/switch.png';
import switch_icon_dark from '../../../img/switch_dark.png';

const colors = [
  'rgba(139, 220, 255, 1)',
  'rgba(234, 208, 208, 1)',
  'rgba(200, 233, 184, 1)',
  'rgba(233, 213, 184, 1)',
]

const AssetDistribution = ({ totalUSDT, distribution }) => {
  // --- handler ---
  const toggleAssetDistribution = () => {
    const donutChart = document.getElementById('donutChart');
    const chart = donutChart.querySelector('.chart');
    const assetCard = donutChart.querySelector('.assetCard');
    chart.classList.toggle('active');
    assetCard.classList.toggle('active');
  };

  // --- context ---
  const { isDarkMode } = useData();

  // --- states ---
  const [currencyDetail, setCurrencyDetail] = useState(() => {
    if (distribution.length === 0 ) return [];
    return distribution.map(({ type, USDT }, index) => ({
      currency: type,
      color: colors[index],
      percent: (USDT / totalUSDT) * 100,
      value: USDT
    }))
  });

  return (
    <div className='w-full'>
      {/* title */}
      <h3 className='py-2 whitespace-nowrap flex justify-between items-center'>
        Token Distribution
        {
          currencyDetail.length > 0
          &&
          <span
            className='2xl:hidden cursor-pointer'
            onClick={toggleAssetDistribution}
          >
            <img
              className='block'
              src={isDarkMode ? switch_icon_dark : switch_icon}
              alt='' 
            />
          </span>
        }
      </h3>
      {/* chart & cards */}
      <div
        id='donutChart'
        className='
          relative w-min-[260px] h-[420px] mx-auto 
          2xl:flex
          overflow-hidden
          bg-sub-white dark:bg-black rounded
        '
      >
        {/* chart */}
        <div className='
          chart absolute right-full active
          active:left-0 active:relative h-full w-full
          2xl:relative 2xl:flex-grow 2xl:right-0
        '>
          <DonutChart size={260} innerSize={40} data={[...currencyDetail]} />
          <span className='absolute z-[-1] abs-center w-[260px] h-[260px] rounded-[50%] origin-center animate-spinWhite dark:animate-spinDark' />

          {/* center information */}
          <div className='
            absolute z-1 abs-center block text-center
          '>
            <span className='block font-bold text-[2.25rem]'>{(totalUSDT / 1000).toFixed(1)}K</span>
            <span className='block text-[0.875rem]'>USDT</span>
          </div>
        </div>

        {/* asset cards */}
        {
          currencyDetail.length > 0
          &&
          <div
            className='
              assetCard absolute left-full top-1/2 px-[45px] translate-y-[-50%]
              active:left-1/2 active:translate-x-[-50%]
              active:2xl:left-0 active:2xl:relative active:2xl:translate-x-0
              2xl:relative 2xl:left-0 
              flex flex-col justify-center
              transition duration-300
            '
          >
            {currencyDetail.map(({ currency, color, percent }, index) => (
              <div
                key={index}
                className='
                  w-[260px] sm:w-[300px] h-[50px] my-[10px] px-3
                  flex justify-between items-center
                  bg-white dark:bg-black-main rounded
                '
              >
                <FaDollarSign className='text-[1.8rem]' style={{ color: color }} />
                <div className='px-5 flex-grow'>
                  <span className='block text-[1rem] font-bold'>{currency}</span>
                </div>
                <span className='font-bold text-[1.5rem]'>{percent.toFixed(1)}%</span>
              </div>
            ))}
          </div>
        }
      </div>
    </div>
  )
}

export default AssetDistribution
