import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useData } from '../../utils/context';

// --- components ---
import Cards from './Cards/Main';
import Users from './Users/Main';
import SharingHistory from './SharingHistory';
import WithdrawalHistory from './WithdrawalHistory';

// --- plugins ---
import Swal from 'sweetalert2/dist/sweetalert2.js';
import withReactContent from 'sweetalert2-react-content';

// --- api ---
import {
  API_AvaPointWithdrawal,
  API_GetAccountRelationship,
  API_UiTransactionRecord
} from '../../utils/api';

const Memberships = () => {
  // --- router ---
  const { user } = useParams();

  // --- context ---
  const { userData, isDarkMode } = useData();

  const getWithdrawalAVAPoints = async () => {
    const res = await API_AvaPointWithdrawal({ mode: 'Get' });
    // if (!!res.error) return setError(res.error);
    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return Swal.fire(res.Message);
    setWithdrawal(() => res.ListWithdrawal);

    getAccountRelationship();
  };
  const getAccountRelationship = async () => {
    const res = await API_GetAccountRelationship();
    // if (!!res.error) return setError(res.error);
    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return Swal.fire(res.Message);
    setRelationShip(res);
    setUserGroup(res.ListDownline);
    setCustomUserGroup(res.ListDownline);
  };
  const getSharingHistory = async () => {
    Swal.fire({
      title: 'Loading...',
      showConfirmButton: false,
      allowOutsideClick: false,
    });

    const res = await API_UiTransactionRecord({ mode: 'InvestReward' });
    Swal.close();
    // if (res.ResultCode != 0) return setError(res.Message);
    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return Swal.fire(res.Message);
    setSharingLog(res.ListInvestReward);
  };
  const getTotalWithdrawalPoints = () => {
    const initValue = 0;
    const total = withdrawal
      .filter(data => data.Status === 'Done')
      .reduce((prevValue, currentValue) => prevValue + currentValue.Point, initValue);

    setTotalWithdrawalPoints(total);
  };
  const handleSortUserGroup = (value) => {
    // setCurrentUserGroupPage(0);
    setSort(value);

    // sort by name
    if (value === 'name') {
      const sortCol = 'Name';
      return setCustomUserGroup([...userGroup].sort((a, b) =>
        a[sortCol].toLowerCase() > b[sortCol].toLowerCase() ? 1 : -1
      ));
    }

    // sort by invite
    if (value === 'invite') {
      const sortCol = 'ListDownline';
      return setCustomUserGroup([...userGroup].sort((a, b) => {
        if (a[sortCol] === null) a[sortCol] = [];
        if (b[sortCol] === null) b[sortCol] = [];
        return a[sortCol].length < b[sortCol].length ? 1 : -1
      }
      ));
    }

    // sort by sharing
    if (value === 'sharing') {
      const sortCol = 'SourceHereAhftHiveBenefit';
      return setCustomUserGroup([...userGroup].sort((a, b) =>
        a[sortCol] < b[sortCol] ? 1 : -1
      ));
    }
  };
  const inputWithdrawalValue = () => {
    document.getElementById('withdrawalPanel')?.classList.toggle('hidden');
    document.getElementById('withdrawalInputPanel').style.animation = 'swal2-show2 .3s 1';
    document.getElementById('historyTable')?.classList.remove('hidden');
    document.getElementById('historyTable')?.classList.add('hidden');
  };

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [relationShip, setRelationShip] = useState(null);
  const [userGroup, setUserGroup] = useState(null);
  const [customUserGroup, setCustomUserGroup] = useState(null);
  const [sort, setSort] = useState('sharing');
  const [withdrawal, setWithdrawal] = useState(null);
  const [totalWithdralPoints, setTotalWithdrawalPoints] = useState(0);
  const [invite, setInvite] = useState(0);
  const [sourceHereAhftHiveBenefit, setSourceHereAhftHiveBenefit] = useState(0);
  // const [cardPerPage, setCardPerPage] = useState(0);
  // const [currentUserGroupPage, setCurrentUserGroupPage] = useState(0);
  const [sharingLog, setSharingLog] = useState(null);

  useEffect(() => {
    if (!userData) return;
    if (withdrawal === null) setWithdrawal('get');
  }, [userData]);

  useEffect(() => {
    if (withdrawal === 'get') getWithdrawalAVAPoints();
    if (!!withdrawal && withdrawal !== 'get') {
      getTotalWithdrawalPoints();
    }
  }, [withdrawal]);

  useEffect(() => {
    if (userGroup !== null) {
      // setCurrentUserGroupPage(0);
      setInvite(userGroup.length);
      // handleGetUserCardPerPage();
      setLoading(false);
    };
  }, [userGroup]);

  useEffect(() => {
    if (!relationShip) return
    if (!user) {
      setUserGroup(relationShip.ListDownline);
      handleSortUserGroup('sharing');
      return;
    }
    const index = relationShip.ListDownline.map(({ AccountID }) => AccountID).indexOf(user);
    setUserGroup(relationShip.ListDownline[index].ListDownline);
    setSourceHereAhftHiveBenefit(relationShip.ListDownline[index].SourceHereAhftHiveBenefit);
    handleSortUserGroup('sharing');
  }, [user, userGroup]);

  useEffect(() => {
    if (sharingLog === null) return;

    const mySwal = withReactContent(Swal);
    mySwal.fire({
      html: <SharingHistory data={sharingLog} />,
      showConfirmButton: false,
      customClass: {
        popup: 'w-1/2 h-[85vh]',
        htmlContainer: 'mx-0',
      }
    });

  }, [sharingLog]);

  return (
    <div>
      {/* --- withdrawal panel --- */}
      <WithdrawalHistory
        withdrawal={withdrawal}
        setWithdrawal={setWithdrawal}
      />

      <Cards data={{
        user,
        userData,
        userGroup,
        isDarkMode,
        invite,
        sourceHereAhftHiveBenefit,
        getSharingHistory,
        inputWithdrawalValue,
        totalWithdralPoints,
      }}/>

      <Users data={{
        loading,
        userGroup,
        sort,
        // currentUserGroupPage,
        // setCurrentUserGroupPage,
        // cardPerPage,
        handleSortUserGroup,
        customUserGroup,
        user,
      }}/>      
      
    </div>
  )
}

export default Memberships