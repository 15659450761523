import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useData } from "../../utils/context";

// --- components ---
// steps
import Amount from "./Amount";
import Package from "./Package";
import Payback from "./Payback";
import Transfer from "./Transfer";
import TransferPackage from "./TransferPackage";
import Notice from "./Notice";
// global
import {
  NextButton,
  PreviousButton,
  ProcessBar,
} from "../Global/ProcessBar.js";
import Loading from "../Global/Loading";

// --- plugins ---
import Swal from "sweetalert2/dist/sweetalert2.js";

// --- api ---
import { API_InvestAHFT } from "../../utils/api.js";

// --- steps component ---
const stepOrder = (props) => {
  // transfer pkg from mining
  if (!!props.transferPkg)
    return [
      <Amount className="max-w-[650px]" {...props} />,
      <Package className="max-w-[1420px]" {...props} />,
      <TransferPackage className="max-w-[1420px]" {...props} />,
    ];

  // add pkg
  return [
    <Amount className="max-w-[650px]" {...props} />,
    <Package className="max-w-[1420px]" {...props} />,
    <Payback className="max-w-[650px]" {...props} />,
    <Transfer className="max-w-[650px]" {...props} />,
  ];
};

// --- AddPackage component ---
const AddPackage = () => {
  // --- call api ---
  async function getInvestPackages() {
    const res = await API_InvestAHFT({ mode: "Query" });
    // if (res.error) return setError(res.error);
    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return Swal.fire(res.Message);

    setInvestData(res);
    setInvestRestrict(res.InvestRestrictUSDT);
    setInvestPackage(res.ListPackage);
    if (!!Gid) handleGetTransferData(res);

    setLoading(false);
  }

  // --- handler ---
  const handleSubmit = () => {
    !!transferPkg ? handleTransferInvest() : handleInsertInvest();
  };
  const handleInsertInvest = async () => {
    const res = await API_InvestAHFT({
      mode: "Insert",
      Amount: projectInformation.amount,
      Asset: "USDT", // wait api
      // Address: 'abc', // block by backend
      ReturnType: projectInformation.returnType,
      PackageID: projectInformation.packageID,
      Name: projectInformation.name,
    });
    // if (!!res.error) return setError(res.error);
    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return Swal.fire(res.Message);

    Swal.fire({
      icon: "success",
      title: "Add Package Successful!",
    }).then(() => {
      navigate("/AVA/dashboard/mining");
    });
  };
  const handleTransferInvest = async () => {
    const res = await API_InvestAHFT({
      mode: "PackageTransferCommit",
      groupId: Gid,
      PackageID: projectInformation.packageID,
      Name: projectInformation.name,
    });
    // if (!!res.error) return setError(res.error);
    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return Swal.fire(res.Message);

    Swal.fire({
      icon: "success",
      title: "Transfer Package Successful!",
    }).then(() => {
      navigate("/v2/mining");
    });
  };
  const handleCancel = () => {
    navigate("/v2/mining");
  };
  const handleGetTransferData = (res) => {
    const data = res.ListInvestData;
    const gids = data.map(({ GroupID }) => GroupID.toString());
    const index = gids.indexOf(Gid);
    const {
      PeriodDays,
      ListNowPeriodTargetAsset,
      Name,
      PackageID,
      PackageName,
      Type,
      ReturnType,
    } = { ...data[index] };

    // validate
    if (index === -1) {
      console.warn("Can not find pair GroupID in ListInvestData.");
      return Swal.fire(`Package not found!`).then(() => {
        navigate("/v2/mining");
      });
    }
    if (data[index].Type === "TREND") {
      return Swal.fire("Package risk TREND can not be transfered!").then(() => {
        navigate("/v2/mining");
      });
    }
    if (data[index].Status !== "running") {
      return Swal.fire("This Package is not running!").then(() => {
        navigate("/v2/mining");
      });
    }
    // if (res.ListPackage.filter(pkg => pkg.PackageID === PackageID).length === 0) {
    //   console.log(PackageID)
    //   console.log(res.ListPackage.filter(pkg => pkg.PackageID === PackageID))
    //   console.warn('PackageID which GroupID on selected does not exist in ListPackage.');
    //   return Swal.fire('Old package does not exist!').then(() => {
    //     navigate('/AVA/dashboard/mining');
    //   });
    // }

    // get oriPkg data
    const initValue = 0;
    const totalPresentAmount = ListNowPeriodTargetAsset.reduce(
      (prev, { TargetAsset, PresentAmount }) => {
        const assetRate =
          TargetAsset === "USDT"
            ? 1
            : yearRates.filter(({ asset }) => asset === TargetAsset)[0]
                .info[364].closeValue;
        return prev + assetRate * PresentAmount;
      },
      initValue
    );

    setProjectInfomation({
      ...projectInformation,
      Gid,
      name: Name,
      type: Type,
      oriType: Type,
      oriPkg: {
        Type,
        PackageName,
        ListNowPeriodTargetAsset,
        PeriodDays,
      },
      packageID: PackageID,
      amount: totalPresentAmount,
      returnType: ReturnType,
    });

    setTransferPkg(PackageID);
  };

  // --- router ---
  const { Gid } = useParams();
  const navigate = useNavigate();

  // --- states ---
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [transferPkg, setTransferPkg] = useState(false);
  const [investData, setInvestData] = useState(null);
  const [investRestrict, setInvestRestrict] = useState(null);
  const [investPackage, setInvestPackage] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [valid, setValid] = useState(false);
  const [projectInformation, setProjectInfomation] = useState({
    name: "",
    amount: 0,
    type: "",
    packageID: null,
    returnType: null,
  });

  // --- effect ---
  useEffect(() => {
    if (!investData) setInvestData("get");
  }, []);
  useEffect(() => {
    if (investData === "get") getInvestPackages();
  }, [investData]);
  useEffect(() => {
    if (!error) return;
    Swal.fire({
      icon: "error",
      title: error,
    }).then(() => {
      navigate("/v2/mining");
    });
  }, [error]);

  // --- context ---
  const { yearRates } = useData();

  // --- props ---
  const propData = {
    currentStep: currentStep + 1,
    totalStep: stepOrder({ transferPkg }).length,
    transferPkg,
    investRestrict,
    investPackage,
    projectInformation,
    setProjectInfomation,
    valid,
    setValid,
    setCurrentStep,
  };

  if (loading) return <Loading />;

  return (
    <div className="min-w-[340px] w-[90%] mx-auto">
      {/* --- process bar --- */}
      {/* <div className='max-w-[650px] mx-auto'>
        <ProcessBar
          pointNumber={stepOrder({transferPkg}).length}
          currentStep={currentStep}
        />
      </div> */}

      {/* --- form --- */}
      <form className="py-4 mx-auto">
        {/* step */}
        <div className='py-3 font-["Montserrat"] tracking-[-0.015em] text-[0.9375rem]'>
          {stepOrder(propData)[currentStep]}
        </div>

        {/* back(cancel) & next(done) buttons */}
        <div className="py-5 flex justify-center sm:justify-between max-w-[650px] mx-auto">
          <PreviousButton {...{ currentStep, setCurrentStep, handleCancel }} />
          <NextButton
            canClick={valid}
            {...{
              currentStep,
              setCurrentStep,
              handleSubmit,
              stepLength: stepOrder({ transferPkg }).length,
            }}
          />
        </div>
      </form>

      {/* --- Notice only show at Amount Step --- */}
      <div className="max-w-[650px] mx-auto">
        {!Boolean(currentStep) && <Notice />}
      </div>
    </div>
  );
};

export default AddPackage;
