import styled, { css } from "styled-components";
import Icons from "../../global/icons";
import Logo from "../../../images/logo_light.png";
import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";
import Lottie from "lottie-react";

import inviteAnimation from "../../lotties/invite2.json";
import { AlertMySwal } from "../../global/MySwal";
import { LangString } from "../../utils";
import { QRCodeCanvas } from "qrcode.react";

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  animation: mymove 5s alternate infinite;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
`;

const Container = styled.div`
  height: 90%;
  width: 90vw;
  display: flex;
  flex-direction: column;

  ${RWD_LG(css`
    height: 95%;
  `)};

  ${RWD_SM(css`
    width: 90%;
  `)};
`;

const LogoWrapper = styled.div`
  padding-bottom: 30px;

  ${RWD_SM(css`
    padding-bottom: 20px;
    img {
      width: 60px;
      height: auto;
    }
  `)};
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 40px;

  ${RWD_LG(css`
    position: absolute;
    align-items: center;
    top: 0;
    padding-top: 20px;
    padding-bottom: 0;
  `)};
`;

const Title = styled.div`
  font-size: min(max(2.5vw, 40px), 48px);
  color: #fcfcfc;
  text-transform: capitalize;
  padding-bottom: 5px;
  display: flex;
  align-items: baseline;

  ${RWD_MD(css`
    font-size: min(max(1.145vw, 20px), 30px);
  `)};
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;

  ${RWD_LG(css`
    flex-direction: column;
    position: relative;
  `)};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 25px;
  width: 65%;

  ${RWD_LG(css`
    width: 100%;
  `)};

  ${RWD_SM(css`
    padding: 0;
    flex: 1;
    justify-content: center;
    /* flex: 1; */
  `)};
`;

const KvWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  /* flex: 1; */
  /* width: 40vw; */
  width: clamp(500px, 36.45vw, 700px);
  overflow: hidden;

  ${RWD_LG(css`
    padding-top: 10vh;
    width: 100%;
  `)};
`;

const InviteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;

  ${RWD_LG(css`
    flex: 1;
  `)};

  ${RWD_MD(css`
    width: 100%;
  `)};
`;

const InviteContainer = styled.div`
  display: flex;

  flex-direction: column;
  padding-bottom: 20px;
  /* padding: 10px; */
  width: 100%;
`;

const InviteTitle = styled.div`
  width: 100%;
  color: #d3d3d3;
  padding-bottom: 10px;
  font-size: min(max(1.145vw, 18px), 22px);
`;

const InviteContentWrapper = styled.div`
  font-size: min(max(1.0416vw, 18px), 20px);
  text-align: center;
  /* padding: 1.0416vw 1.5625vw; */
  padding: 10px 20px;
  border: 1px solid;
  border-radius: 8px;
  /* flex: 1; */
  display: flex;
  justify-content: space-between;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  border-color: rgba(255, 255, 255, 0.15);
  color: #fcfcfc;

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 16px), 18px);
  `)};
`;

const InviteContent = styled.div`
  color: #fcfcfc;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 90%;
  text-align: start;
`;

const CopyIconWrapper = styled.div`
  width: 28px;
  height: 28px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: 28px;
    height: 28px;

    & path {
      fill: #fcfcfc;
    }
  }

  ${RWD_SM(css`
    width: 25px;
    height: 25px;

    svg {
      width: 25px;
      height: 25px;
    }
  `)};
`;

const Point = styled.div`
  font-size: min(max(4.1667vw, 40px), 80px);
  font-family: Montserrat;
  font-weight: bold;
  color: #0aecd1;
  padding: 0 10px;

  ${RWD_SM(css`
    padding: 0 5px;
  `)};
  /* padding: 0 10px; */
`;

const CodeAndQrcode = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  justify-content: space-between;

  ${RWD_LG(css`
    flex-direction: column;
  `)};
  /* padding: 0 10px; */
`;

const Invite = () => {
  const { search } = useLocation();
  const [referenceCode, setReferenceCode] = useState("");
  const [referenceLink, setReferenceLink] = useState("");

  const { inviteCode } = useParams();

  console.log(inviteCode);

  useEffect(() => {
    const query = new URLSearchParams(search);
    setReferenceCode(query.get("invites"));
    setReferenceLink(
      `${window.location.origin}/v2/register/?invites=${query.get("invites")}`
    );
  }, [search]);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const [lottieWidth, setLottieWidth] = useState("");
  const [lottieHeight, setLottieHeight] = useState("");

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (windowSize[0] <= "1024") {
      setLottieWidth(windowSize[0] * 0.9);
      setLottieHeight(windowSize[1] * 0.5);

      if (windowSize[0] <= "430") {
        setLottieHeight(windowSize[1] * 0.4);
      }
    } else {
      setLottieWidth(windowSize[0] * 0.7);
      setLottieHeight(windowSize[1] * 0.7);
    }
  }, [windowSize]);

  const handleCopy = (type) => {
    // 多國語言
    const CopyResString = LangString("alert_copy", "Copy successful");
    let text;
    if (type === "code") {
      text = referenceCode;
    } else {
      text = referenceLink;
    }
    navigator.clipboard.writeText(text).then(() => {
      AlertMySwal({
        title: CopyResString,
        showConfimButton: false,
      });
    });
  };

  return (
    <Wrapper>
      <Container>
        <LogoWrapper>
          <img src={Logo} />
        </LogoWrapper>
        <ContentWrapper>
          <KvWrapper>
            <Lottie
              animationData={inviteAnimation}
              loop={true}
              style={{
                height: lottieHeight,
                width: lottieWidth,
              }}
            />
          </KvWrapper>

          <ContentContainer>
            <TitleWrapper>
              <Title>註冊AVA用戶</Title>
              <Title>
                獲得
                <Point>50</Point>
                AVA Coin 高額獎勵
              </Title>
            </TitleWrapper>

            <CodeAndQrcode>
              {/* <QrcodeContainer style={{ height: `${windowSize[0] * 0.08}` }}>
                <QRCodeCanvas
                  id="qrcode"
                  value={referenceLink}
                  bgColor={"transparent"}
                  fgColor={"#151944"}
                  size={windowSize[0] * 0.08 < 100 ? 100 : windowSize[0] * 0.08}
                />
              </QrcodeContainer> */}
              <InviteWrapper>
                <InviteContainer>
                  <InviteTitle>推薦碼</InviteTitle>
                  {/* Referral Code  */}
                  <InviteContentWrapper>
                    <InviteContent>{referenceCode}</InviteContent>
                    <CopyIconWrapper onClick={() => handleCopy("code")}>
                      <Icons.Copy />
                    </CopyIconWrapper>
                  </InviteContentWrapper>
                </InviteContainer>
                <InviteContainer>
                  <InviteTitle>推薦連結</InviteTitle>
                  {/* Referral Link  */}
                  <InviteContentWrapper>
                    <InviteContent>{referenceLink}</InviteContent>
                    <CopyIconWrapper onClick={() => handleCopy("link")}>
                      <Icons.Copy />
                    </CopyIconWrapper>
                  </InviteContentWrapper>
                </InviteContainer>
              </InviteWrapper>
            </CodeAndQrcode>
          </ContentContainer>
        </ContentWrapper>
      </Container>
    </Wrapper>
  );
};

export default Invite;
