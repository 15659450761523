import styled, { css } from "styled-components";
import { useState, useEffect } from "react";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";

const Wrapper = styled.div``;

const SvgEl = styled.svg`
  width: clamp(180px, 10vw, 250px);
  height: clamp(180px, 10vw, 250px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;

  ${RWD_SM(css`
    width: 100px;
    height: 100px;
    font-size: 6px;
  `)};
`;

const Stamp = ({ time }) => {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const svgToPath = (cx, cy, r) => {
    var path =
      "M" +
      (cx - r) +
      " " +
      cy +
      "a" +
      r +
      " " +
      r +
      " 0 1 0 " +
      2 * r +
      " 0" +
      "a" +
      r +
      " " +
      r +
      " 0 1 0 " +
      -2 * r +
      " 0" +
      "z";
    return path;
  };

  const [textPath, setPathText] = useState("");
  const [dPath, setDpath] = useState("");

  console.log(time);

  const [circleSize, setCircleSize] = useState("");

  const [radiusSize, setRadiusSize] = useState("");
  const [innerRadiusSize, setInnerRadiusSize] = useState("");

  const [innerFontSize, setInnerFontSize] = useState(18);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    setPathText(
      ` <textPath xlink:href="#text-path"     font-weight="600" fill="#fcfcfc" letter-spacing="2.14"> • Congratulations ! • Completion Date ${time}</textPath>`
    );

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    setDpath(
      svgToPath(
        (window.innerWidth * 0.1) / 2,
        (window.innerWidth * 0.1) / 2,
        (window.innerWidth * 0.085) / 2
      )
    );

    if (windowSize[0] <= 430) {
      setCircleSize(50);
      setRadiusSize(48);
      setInnerRadiusSize(48 / 1.5);
      setDpath(svgToPath(50, 50, 42));
      setInnerFontSize(10);
    } else {
      if ((windowSize[0] * 0.1) / 2 <= 180 && windowSize[0] > 430) {
        setCircleSize(90);
        setRadiusSize(88);
        setInnerRadiusSize(88 / 1.5);
        setDpath(svgToPath(90, 90, 78));
        setInnerFontSize(16);
      } else {
        setCircleSize((windowSize[0] * 0.1) / 2);
        setRadiusSize(((window.innerWidth - 2) * 0.1) / 2);
        setInnerRadiusSize(((window.innerWidth - 2) * 0.1) / 3);
        setInnerFontSize(18);
      }
    }
  }, [windowSize]);

  return (
    <Wrapper>
      <SvgEl>
        <circle
          cx={circleSize}
          cy={circleSize}
          r={radiusSize}
          fill="none"
          stroke="#fcfcfc"
          stroke-width="2"
        />
        <circle
          cx={circleSize}
          cy={circleSize}
          r={radiusSize}
          fill="none"
          stroke="#fcfcfc"
          stroke-width="2"
        />
        <circle
          cx={circleSize}
          cy={circleSize}
          r={innerRadiusSize + 2}
          fill="none"
          stroke="#fcfcfc"
          stroke-width="1"
        />
        <circle
          cx={circleSize}
          cy={circleSize}
          r={innerRadiusSize}
          fill="none"
          stroke="#fcfcfc"
          stroke-width="1"
        />
        <defs>
          <path id="text-path" d={dPath} fill="none" />
        </defs>
        <text
          dominant-baseline="middle"
          text-anchor="middle"
          x={circleSize}
          y="50%"
          font-size={innerFontSize}
          font-weight="600"
          fill="#fcfcfc"
          //   stroke="#fcfcfc"
        >
          COMPLETE
        </text>
        <text dangerouslySetInnerHTML={{ __html: textPath }}></text>
      </SvgEl>
    </Wrapper>
  );
};

export default Stamp;
