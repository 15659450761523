import styled from "styled-components";
import { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";
import { useState, useEffect, useRef, useCallback, Fragment } from "react";
import { useSelector } from "react-redux";

import { Autoplay, Navigation, Pagination } from "swiper";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/navigation";
import "swiper/css/pagination";

import Icons from "../../../global/icons";
import Lang from "../../../global/Lang";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { selectSystem } from "../../../slices/systemSlice";
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

import { driver } from "driver.js";
import "driver.js/dist/driver.css";

import JoyrideComponent from "../../../global/JoyrideComponent";
import { useLocation } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  /* border: 1px solid blue; */
  /* min-height: 100vh; */

  .swiper {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    overflow: hidden;
  }

  .swiper-pagination {
    bottom: 0px;
  }
`;

const Container = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;

  ${RWD_LG(css`
    flex-direction: column;
  `)};
`;

const SwiperWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;

  ${RWD_LG(css`
    /* flex-direction: row; */
    width: 100%;
    padding-bottom: 20px;
  `)};

  ${RWD_SM(css`
    padding: 25px 0;
  `)};
`;

const SwiperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${RWD_LG(css`
    flex-direction: row;
    width: 100%;
    /* position: relative; */
    /* padding: 50px 0; */
  `)};
`;

const PrevButton = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scaleY(-1);
  cursor: pointer;
  &:hover {
    & path {
      stroke: ${(props) => (props.isEnd ? "" : "#0AECD1")};
    }
  }

  ${RWD_LG(css`
    transform: rotate(90deg);
    width: 20px;
    height: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  `)};
`;

const NextButton = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    & path {
      stroke: ${(props) => (props.isEnd ? "" : "#0AECD1")};
    }
  }

  ${RWD_LG(css`
    transform: rotate(-90deg);
    width: 20px;
    height: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  `)};
`;

const SlideWrapper = styled.div`
  height: calc(650px - 45px);
  width: 80%;

  ${RWD_LG(css`
    width: calc(100% - 40px);
    height: auto;
    /* width: 100%; */
  `)};
`;

const AlgoWrapper = styled.div`
  width: 100%;
  height: 100%;
  color: #fcfcfc;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${RWD_LG(css`
    width: 100%;
  `)};
`;

const AlgoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  ${RWD_LG(css`
    width: 100%;
  `)};
`;

const AlgoName = styled.div`
  font-size: 1.5625vw;
  padding-bottom: 10px;
`;

const AlgoDirection = styled.div`
  font-size: min(max(1.0416vw, 18px), 20px);
  padding-bottom: 20px;

  ${RWD_SM(css`
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const ChartTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
`;

const TooltipWrapper = styled.div`
  position: relative;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 3px;
  cursor: help;

  &:hover span {
    visibility: visible;
  }
`;

const TooltipContainer = styled.div`
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #c0c0c0;
  padding-top: 1px;
  display: flex;

  svg {
    width: 20px;
    height: 20px;
  }

  & path {
    stroke: #c0c0c0;
  }
`;

const TooltipText = styled.span`
  visibility: hidden;
  width: auto;
  background-color: #54587b;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: min(max(0.833vw, 14px), 16px);
  white-space: nowrap;
  position: absolute;
  left: calc(1.3vw + 15px);
  z-index: 1;
`;

const ChartTitle = styled.div`
  font-size: min(max(0.833vw, 14px), 16px);
`;

const ChartComponentContainer = styled.div`
  width: 100%;
  height: 300px;
  align-self: center;

  ${RWD_LG(css`
    height: 30vw;
  `)};

  ${RWD_MD(css`
    height: 250px;
  `)};

  ${RWD_SM(css`
    height: 200px;
  `)};
`;

const AlgoFeatureWrapper = styled.div`
  display: flex;
  padding: 30px 0;
  width: 100%;
  justify-content: space-between;

  ${RWD_LG(css`
    width: 100%;
  `)};

  ${RWD_SM(css`
    flex-direction: column;
  `)};
`;

const AlgoReviewsWrapper = styled.div`
  width: 47%;

  padding: 1.0416vw;
  height: 180px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.15);

  ${RWD_LG(css`
    height: 190px;
  `)};

  ${RWD_SM(css`
    width: 100%;
    padding: 15px;
  `)};
`;

const AlgoReviewsContainer = styled.div`
  overflow-y: auto;
  font-size: min(max(1.0416vw, 18px), 20px);
`;

const AlgoReviewNameWrapper = styled.div`
  display: flex;
  font-size: min(max(1.0416vw, 18px), 20px);
`;

const AlogoRevierNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 10px;
`;

const AlgoReviewName = styled.div`
  &:last-child {
    color: #d9d9d9;
    font-size: min(max(0.833vw, 14px), 16px);
  }
`;

const AlgoReviewStar = styled.div`
  /* border: 1px solid red; */
  width: 1.2vw;
  height: 1.2vw;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 1.2vw;
    height: 1.2vw;
  }

  ${RWD_LG(css`
    width: 20px;
    height: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  `)};

  & path {
    fill: #0aecd1;
  }
`;

const AlgoReviewStarWrapper = styled.div`
  display: flex;
  padding-top: 6px;
`;

const AlgoReviewImg = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid rgba(255, 255, 255, 0.15);

  img {
    height: 100%;
    object-fit: cover;
  }
`;

const AlgoReviewContent = styled.div`
  padding-top: 6px;
  font-size: min(max(0.9375vw, 16px), 18px);

  ${RWD_SM(css`
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const AlgoFeatureContainer = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.15);
  width: 47%;
  padding: 1.0416vw;
  height: 180px;
  overflow-x: auto;
  border-radius: 10px;
  flex-wrap: nowrap;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  display: flex;

  ${RWD_LG(css`
    height: 190px;
  `)};

  ${RWD_SM(css`
    width: 100%;
    padding: 15px;
    margin-bottom: 20px;
  `)};

  /* 整個捲軸 */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  /* 捲軸的軌道 */
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  /*捲軸尚未滑到的軌道*/
  ::-webkit-scrollbar-track-piece {
    background: transparent;
  }
  /* 滑動的區塊 */
  ::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    border: 5px solid rgba(255, 255, 255, 0.5);
  }
  /* 滑鼠移到滑動的區塊上 */
  ::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
`;

const AlgoFeature = styled.ul`
  list-style-type: circle;
  list-style-position: inside;
`;

const AlgoFeatureItem = styled.li`
  font-size: min(max(1.0416vw, 18px), 20px);
  padding: 5px;

  ${RWD_SM(css`
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const Title = styled.div`
  font-size: min(max(1.25vw, 22px), 24px);
  color: #fcfcfc;
  padding-bottom: 20px;

  ${RWD_MD(css`
    font-size: min(max(1.0416vw, 18px), 20px);
  `)};
`;

const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TargetWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  width: 80%;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
`;

const IconContainer = styled.div`
  width: 1.5vw;
  height: 1.5vw;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  & path {
    fill: ${(props) => (props.selected ? "#0AECD1" : "#fcfcfc")};
  }

  svg {
    width: 1.5vw;
    height: 1.5vw;
  }

  ${RWD_LG(css`
    width: 3.5vw;
    height: 3.5vw;

    svg {
      width: 3.5vw;
      height: 3.5vw;
    }
  `)};
`;

const IconText = styled.div`
  color: ${(props) => (props.selected ? "#0AECD1" : "#fcfcfc")};
  padding-left: 10px;
`;

const SubTitle = styled.div`
  font-size: min(max(1.875vw, 34px), 36px);
  font-weight: bold;
  color: #fcfcfc;
  padding-bottom: 10px;
  text-align: left;
  width: 100%;

  ${RWD_MD(css`
    font-size: min(max(1.145vw, 20px), 22px);
    /* padding-bottom: 0; */
  `)};
`;

const Tab = styled.div`
  border: 1px solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* padding: 20px 10px; */
  /* flex: 1; */
  height: 100%;
  /* &:last-child {
    margin-bottom: 0;
    width: 100%;
  } */

  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );

  border-radius: 8px;

  border-color: ${(props) =>
    props.selected ? "#0AECD1" : "rgba(255, 255, 255, 0.15)"};

  opacity: ${(props) => (props.selected ? 1 : 0.8)};

  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  ${RWD_LG(css`
    width: 100%;
    padding: 15px 0;
  `)};
`;

const TabIcon = styled.div`
  /* padding-top: 10px; */
  width: 60px;
  height: 60px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  & path {
    fill: ${(props) => (props.selected ? "#0AECD1" : "#fcfcfc")};
  }

  svg {
    width: 60px;
    height: 60px;
  }

  ${RWD_LG(css`
    width: 8vw;
    height: 8vw;

    svg {
      width: 8vw;
      height: 8vw;
    }
  `)};
`;

const TabText = styled.div`
  font-size: min(max(1.145vw, 20px), 22px);
  padding: 10px 0;
  color: ${(props) => (props.selected ? "#0AECD1" : "#fcfcfc")};

  ${RWD_MD(css`
    font-size: min(max(0.9375vw, 16px), 18px);
  `)};
`;

const SlideZoomInWrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.8);
`;

const SlideZoomInClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
`;

const SlideZoomInContainer = styled.div`
  background-color: #fcfcfc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  min-height: 80vh;
  height: auto;
  padding: 40px 10px;
  width: 85vw;
  position: relative;
`;

const SlideZoomInItemWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SlideZoomInItemContainer = styled.div`
  background-color: #151944;
  height: 70vh;
  padding: 30px;
  width: 100%;
`;

const SlideZoomInLeftButton = styled.div`
  width: auto;
  padding: 20px;
  cursor: pointer;
`;

const SlideZoomInRightButton = styled.div`
  width: auto;
  padding: 20px;
  cursor: pointer;
`;

const TargetTabWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
`;

const TargetTabContainer = styled.div`
  display: flex;
  width: 100%;
`;

const TargetTab = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* flex: 1; */
  /* padding: 0.78125vw 1.3vw; */
  padding: 0.5vw 1vw;
  border: ${(props) =>
    props.activeTab
      ? "1px solid #0AECD1"
      : "1px solid rgba(255, 255, 255, 0.15)"};
  color: #fcfcfc;
  margin-right: 10px;
  border-radius: 8px;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  cursor: pointer;

  ${RWD_MD(css`
    /* width: 150px; */
    padding: 8px 10px;
    text-align: center;
  `)};
`;

const TargetTabIcon = styled.div`
  width: 25px;
  height: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const TargetTabText = styled.div`
  padding-left: 10px;
`;

const AlgoInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;

  ${RWD_LG(css`
    width: 100%;
  `)};
`;

const StepOne = ({ setAlgo, algo, setActiveStep, target, setTarget }) => {
  const { search } = useLocation();

  const [targetList, setTargetList] = useState();
  const [selectTarget, setSelectTarget] = useState("");

  useEffect(() => {
    const query = new URLSearchParams(search);
    const tutorial = query.get("tutorial");
    if (tutorial === "algo") {
      setJoyrideIsRun(true);
    }
  }, [search]);

  //
  const handleJoyrideCallback = () => {
    setActiveStep(1);
  };
  const [joyrideIsRun, setJoyrideIsRun] = useState(false);
  const joyrideState = [
    {
      target: ".createAlgo_selectAlgo",
      content: (
        <Lang
          datakey="algo_tutorial_2-1"
          defaultText="Here you can select your Algo strategy. Click the up/down arrows or drag to see more options."
        />
      ),
      placement: "top",
      disableBeacon: true,
      disableOverlayClose: true,
      callbackAction: false,
    },
    {
      target: ".createAlgo_progress",
      content: (
        <Lang
          datakey="algo_tutorial_2-2"
          defaultText="Here shows the current creation stage of Algo. When you are ready, click 'Next' to proceed to the next step."
        />
      ),
      placement: "top",
      disableBeacon: true,
      disableOverlayClose: true,
      callbackAction: true,
      function: handleJoyrideCallback,
    },
  ];

  // const tutorial = driver({
  //   steps: [
  //     {
  //       element: ".createAlgo_selectAlgo",
  //       popover: {
  //         title: "Create Algo",
  //         description:
  //           "Here you can select your Algo strategy. Click the up/down arrows or drag to see more options.",
  //         side: "left",
  //         align: "center",
  //       },
  //     },
  //     {
  //       element: ".createAlgo_progress",
  //       popover: {
  //         title: "Create Algo",
  //         description:
  //           "Here shows the current creation stage of Algo. When you are ready, click 'Next' to proceed to the next step.",
  //         side: "right",
  //         align: "center",
  //       },
  //     },
  //   ],
  //   onNextClick: () => {
  //     if (tutorial.isLastStep()) {
  //       setActiveStep(1);
  //     }
  //     // console.log();
  //     tutorial.moveNext();
  //   },
  // });

  // useEffect(() => {
  //   tutorial.drive();
  // }, []);

  // RWD
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const [swiperDirection, setSwiperDirection] = useState("vertical");
  const [swiperView, setSwiperView] = useState(3);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (windowSize[0] <= "1024") {
      setSwiperDirection("horizontal");
      if (windowSize[0] <= "430") {
        setSwiperView(2);
      }
    } else {
      setSwiperDirection("vertical");
      setSwiperView(3);
    }
  }, [windowSize]);

  const gunIconList = [
    {
      gun: "Rifle",
      gunCh: "步槍",
      icon: <Icons.Rifle />,
    },
    {
      gun: "Howitzer",
      gunCh: "榴彈砲",
      icon: <Icons.GrenadeLauncher />,
    },
    {
      gun: "Flintlock",
      gunCh: "燧發槍",
      icon: <Icons.Flintlock />,
    },
    {
      gun: "Pistol",
      gunCh: "戰術手槍",
      icon: <Icons.Pistol />,
    },
    {
      gun: "Machine",
      gunCh: "衝鋒槍",
      icon: <Icons.Submachine />,
    },
    {
      gun: "Drone",
      gunCh: "無人機",
      icon: <Icons.Drone />,
    },
    {
      gun: "Gatling",
      gunCh: "重型機槍",
      icon: <Icons.Gatling />,
    },
  ];

  const reviewList = [
    {
      id: 9,
      review: [
        {
          name: "Sheldon",
          content:
            "勝率：50%, 盈利因子：2.241, 最大單筆交易獲利：10,801, 最大單筆交易虧損：7,672",
          img: "https://picsum.photos/500/400?random=1",
        },
        {
          name: "Leonard",
          content:
            "勝率：50%, 盈利因子：2.241, 最大單筆交易獲利：10,801, 最大單筆交易虧損：7,672",
          img: "https://picsum.photos/500/400?random=2",
        },
      ],
    },
    {
      id: 10,
      review: [
        {
          name: "Howard",
          content:
            "勝率：50%, 盈利因子：2.241, 最大單筆交易獲利：10,801, 最大單筆交易虧損：7,672",
          img: "https://picsum.photos/500/400?random=3",
        },
        {
          name: "Rajesh",
          content:
            "勝率：50%, 盈利因子：2.241, 最大單筆交易獲利：10,801, 最大單筆交易虧損：7,672",
          img: "https://picsum.photos/500/400?random=4",
        },
      ],
    },
    {
      id: 11,
      review: [
        {
          name: "Penny",
          content:
            "勝率：50%, 盈利因子：2.241, 最大單筆交易獲利：10,801, 最大單筆交易虧損：7,672",
          img: "https://picsum.photos/500/400?random=5",
        },
        {
          name: "Amy",
          content:
            "勝率：50%, 盈利因子：2.241, 最大單筆交易獲利：10,801, 最大單筆交易虧損：7,672",
          img: "https://picsum.photos/500/400?random=6",
        },
        {
          name: "Bernadette",
          content:
            "勝率：50%, 盈利因子：2.241, 最大單筆交易獲利：10,801, 最大單筆交易虧損：7,672",
          img: "https://picsum.photos/500/400?random=7",
        },
      ],
    },
    {
      id: 12,
      review: [
        {
          name: "Sheldon",
          content:
            "勝率：50%, 盈利因子：2.241, 最大單筆交易獲利：10,801, 最大單筆交易虧損：7,672",
          img: "https://picsum.photos/500/400?random=1",
        },
        {
          name: "Leonard",
          content:
            "勝率：50%, 盈利因子：2.241, 最大單筆交易獲利：10,801, 最大單筆交易虧損：7,672",
          img: "https://picsum.photos/500/400?random=2",
        },
        {
          name: "Howard",
          content:
            "勝率：50%, 盈利因子：2.241, 最大單筆交易獲利：10,801, 最大單筆交易虧損：7,672",
          img: "https://picsum.photos/500/400?random=3",
        },
        {
          name: "Rajesh",
          content:
            "勝率：50%, 盈利因子：2.241, 最大單筆交易獲利：10,801, 最大單筆交易虧損：7,672",
          img: "https://picsum.photos/500/400?random=4",
        },
        {
          name: "Penny",
          content:
            "勝率：50%, 盈利因子：2.241, 最大單筆交易獲利：10,801, 最大單筆交易虧損：7,672",
          img: "https://picsum.photos/500/400?random=5",
        },
        {
          name: "Amy",
          content:
            "勝率：50%, 盈利因子：2.241, 最大單筆交易獲利：10,801, 最大單筆交易虧損：7,672",
          img: "https://picsum.photos/500/400?random=6",
        },
        {
          name: "Bernadette",
          content:
            "勝率：50%, 盈利因子：2.241, 最大單筆交易獲利：10,801, 最大單筆交易虧損：7,672",
          img: "https://picsum.photos/500/400?random=7",
        },
      ],
    },
    {
      id: 13,
      review: [
        {
          name: "Sheldon",
          content:
            "勝率：50%, 盈利因子：2.241, 最大單筆交易獲利：10,801, 最大單筆交易虧損：7,672",
          img: "https://picsum.photos/500/400?random=1",
        },
        {
          name: "Leonard",
          content:
            "勝率：50%, 盈利因子：2.241, 最大單筆交易獲利：10,801, 最大單筆交易虧損：7,672",
          img: "https://picsum.photos/500/400?random=2",
        },
      ],
    },
    {
      id: 3,
      review: [
        {
          name: "Howard",
          content:
            "勝率：50%, 盈利因子：2.241, 最大單筆交易獲利：10,801, 最大單筆交易虧損：7,672",
          img: "https://picsum.photos/500/400?random=3",
        },
        {
          name: "Rajesh",
          content:
            "勝率：50%, 盈利因子：2.241, 最大單筆交易獲利：10,801, 最大單筆交易虧損：7,672",
          img: "https://picsum.photos/500/400?random=4",
        },
      ],
    },
    {
      id: 4,
      review: [
        {
          name: "Penny",
          content:
            "勝率：50%, 盈利因子：2.241, 最大單筆交易獲利：10,801, 最大單筆交易虧損：7,672",
          img: "https://picsum.photos/500/400?random=5",
        },
        {
          name: "Amy",
          content:
            "勝率：50%, 盈利因子：2.241, 最大單筆交易獲利：10,801, 最大單筆交易虧損：7,672",
          img: "https://picsum.photos/500/400?random=6",
        },
        {
          name: "Bernadette",
          content:
            "勝率：50%, 盈利因子：2.241, 最大單筆交易獲利：10,801, 最大單筆交易虧損：7,672",
          img: "https://picsum.photos/500/400?random=7",
        },
      ],
    },
  ];

  const airobots = useSelector(selectSystem).airobots;
  const lang = useSelector(selectSystem).language;
  const langData = useSelector(selectSystem).langData;

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!airobots || airobots?.length === 0) return;
    if (!algo) setAlgo(airobots.filter((el) => el.RobotData !== null)[0]?.Id);

    setAlgoCard(
      airobots
        .filter((el) => el.RobotData !== null)
        .map((data) => {
          return {
            ...data,
            icon: gunIconList.filter(
              (gunIcon) =>
                gunIcon.gun ===
                langData
                  .filter(({ Key }) => Key === data.NameLanguageKey)[0]
                  ?.En?.split(" ")[0]
            )[0]?.icon,
          };
        })
    );
  }, [airobots, langData, algo]);

  const Chart = ({ chartData }) => {
    const options = {
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
      },
      animation: false,

      scales: {
        x: {
          ticks: {
            color: "#fcfcfc",
          },
          grid: {
            color: function () {
              return "transparent";
            },
          },
        },
        y: {
          border: {
            display: false,
          },
          ticks: {
            color: "#fcfcfc",
            callback: function (value) {
              return `${value} % `;
            },
          },
          grid: {
            color: function () {
              return "rgba(255, 255, 255, 0.3)";
            },
          },
        },
      },
    };
    const labels = Array.from({ length: chartData.length }, (v, i) => i);
    const data = {
      labels,
      datasets: [
        {
          data: chartData.map((data) => (data / 10000) * 100),
          borderColor: "#2F80ED",
          backgroundColor: "#2F80ED",
          pointRadius: 2,
        },
      ],
    };
    return <Line options={options} data={data} />;
  };

  const ZoomIn = ({ data, clickSlide, setIsOpen }) => {
    const [current, setCurrent] = useState();

    useEffect(() => {
      setCurrent(clickSlide.type);
    }, [clickSlide]);

    const handleSlide = () => {
      setCurrent(() => {
        if (current === "img") {
          return "chart";
        } else {
          return "img";
        }
      });
    };

    return (
      <SlideZoomInWrapper>
        <SlideZoomInContainer>
          <SlideZoomInClose onClick={() => setIsOpen(false)}>
            <Icons.Close />
          </SlideZoomInClose>
          <SlideZoomInLeftButton onClick={() => handleSlide()}>
            <Icons.Arrow_left />
          </SlideZoomInLeftButton>

          <SlideZoomInItemWrapper>
            {current === "img" ? (
              <img src={data[0].imgSrc} />
            ) : (
              <SlideZoomInItemContainer>
                <Chart chartData={data[0].chartData} />
              </SlideZoomInItemContainer>
            )}
          </SlideZoomInItemWrapper>
          <SlideZoomInRightButton onClick={() => handleSlide()}>
            <Icons.Arrow_right />
          </SlideZoomInRightButton>
        </SlideZoomInContainer>
      </SlideZoomInWrapper>
    );
  };

  const [slideData, setSlideData] = useState([]);
  const [algoCard, setAlgoCard] = useState([]);

  useEffect(() => {
    setTargetList(airobots.filter((data) => data.Id === algo)[0]?.Targets);

    // 預設選擇第一個Target
    setTarget(
      airobots.filter((data) => data.Id === algo)[0]?.Targets?.[0]?.Target
    );
  }, [algo, algoCard]);

  const handleTarget = (target) => {
    setTarget(target);
  };

  //   useEffect(() => {
  //     if (algo === 1) {
  //       setSlideData(StableAI_Future_BTC);
  //       // 回到第一張Slide
  //       if (swiper !== undefined) {
  //         swiper.slideTo(0);
  //       }
  //     }
  //     if (algo === 3) {
  //       setSlideData(RushAI_Future_BTC);
  //       if (swiper !== undefined) {
  //         swiper.slideTo(0);
  //       }
  //     }

  //     if (algo === 4) {
  //       setSlideData(RushAI_Future_ETH);
  //       if (swiper !== undefined) {
  //         swiper.slideTo(0);
  //       }
  //     }
  //   }, [algo]);

  const [onClickSlide, setOnClickSlide] = useState([]);

  const handleZoomIn = (type, id) => {
    setIsOpen(true);
    setOnClickSlide({
      type: type,
      id: id,
    });
  };

  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const [isBeginning, setIsBeginning] = useState();
  const [isEnd, setIsEnd] = useState();

  return (
    <Wrapper>
      <JoyrideComponent
        joyrideState={joyrideState}
        run={joyrideIsRun}
        joyrideType={"algo"}
      />
      <Title>
        <Lang
          datakey="addalgo_select_algo_title"
          defaultText="Create Your AlgoTrade"
        />
      </Title>

      <Container>
        <SwiperWrapper className="createAlgo_selectAlgo">
          <SubTitle>
            <Lang datakey="addalgo_select_algo" defaultText="Select Algo" />
          </SubTitle>
          <SwiperContainer>
            <PrevButton
              onClick={handlePrev}
              isEnd={sliderRef.current?.swiper.isBeginning}
            >
              <Icons.Arrow />
            </PrevButton>
            <SlideWrapper>
              <Swiper
                direction={swiperDirection}
                modules={[Navigation]}
                slidesPerView={swiperView}
                className="mySwiper"
                ref={sliderRef}
                spaceBetween={10}
              >
                {algoCard.map((data, index) => (
                  <SwiperSlide key={data.Id}>
                    <Tab
                      key={data.Id}
                      onClick={() => setAlgo(data.Id)}
                      selected={algo === data.Id}
                    >
                      <TabIcon selected={algo === data.Id}>{data.icon}</TabIcon>
                      <TabText selected={algo === data.Id}>
                        <Lang
                          defaultText={data.NameLanguageKey}
                          datakey={data.NameLanguageKey}
                        />
                      </TabText>

                      {/* 不要Target */}
                      {/* <TargetWrapper>
                      <IconContainer selected={algo === data.Id}>
                        {data.Target === "BTC" ? (
                          <Icons.BTC_STROKE />
                        ) : (
                          <Icons.ETH_STROKE />
                        )}
                      </IconContainer>
                      <IconText selected={algo === data.Id}>
                        {data.Target === "BTC" ? "Bitcoin" : "Ethereum"}
                      </IconText>
                    </TargetWrapper> */}
                    </Tab>
                  </SwiperSlide>
                ))}
              </Swiper>
            </SlideWrapper>
            <NextButton
              onClick={handleNext}
              isEnd={sliderRef.current?.swiper.isEnd}
            >
              <Icons.Arrow />
            </NextButton>
          </SwiperContainer>
        </SwiperWrapper>

        <AlgoInfoWrapper>
          <SubTitle>選擇 Target</SubTitle>
          <TargetTabWrapper>
            <TargetTabContainer>
              {targetList?.map((data) => (
                <TargetTab
                  key={data.Target}
                  onClick={() => handleTarget(data.Target)}
                  activeTab={data.Target === target}
                >
                  <TargetTabIcon>
                    <img src={data.Icon} />
                  </TargetTabIcon>
                  <TargetTabText>{data.Target}</TargetTabText>
                </TargetTab>
              ))}
            </TargetTabContainer>
          </TargetTabWrapper>
          {algoCard
            .filter((data) => data.Id === algo)[0]
            ?.Targets?.filter((el) => el.Target === target)
            .map((item, index) => (
              <AlgoWrapper key={index}>
                <AlgoContainer>
                  <ChartTitleWrapper>
                    <ChartTitle>
                      <Lang
                        datakey="finance_linechart"
                        defaultText="Fund change line chart"
                      />
                    </ChartTitle>
                    <TooltipWrapper>
                      <TooltipContainer>
                        <Icons.Tooltip />
                      </TooltipContainer>
                      <TooltipText>
                        <Lang
                          datakey="finance_linechart"
                          defaultText="Fund change line chart"
                          toolTip={true}
                        />
                      </TooltipText>
                    </TooltipWrapper>
                  </ChartTitleWrapper>

                  <ChartComponentContainer>
                    <Chart chartData={item?.RobotData?.split(",") ?? []} />
                  </ChartComponentContainer>
                  <AlgoFeatureWrapper>
                    <AlgoFeatureContainer>
                      <AlgoFeature>
                        {langData
                          .filter(
                            ({ Key }) => Key === item?.DescLanguageKey
                          )?.[0]
                          ?.[lang].split(",")
                          .map((item, index) => (
                            <AlgoFeatureItem key={index}>
                              {item}
                            </AlgoFeatureItem>
                          ))}
                      </AlgoFeature>
                    </AlgoFeatureContainer>

                    <AlgoReviewsWrapper>
                      <Swiper
                        slidesPerView={1}
                        // loop={true}
                        autoplay={{
                          delay: 5000,
                          disableOnInteraction: false,
                        }}
                        pagination={true}
                        modules={[Pagination, Autoplay]}
                        className="mySwiper"
                      >
                        {reviewList
                          .filter((data) => data.id === algo)[0]
                          ?.review.map((item, index) => (
                            <SwiperSlide key={index}>
                              <AlgoReviewsContainer>
                                <AlgoReviewNameWrapper>
                                  <AlgoReviewImg>
                                    <img src={item.img} />
                                  </AlgoReviewImg>
                                  <AlogoRevierNameContainer>
                                    <AlgoReviewName>{item.name}</AlgoReviewName>
                                    <AlgoReviewName>
                                      <Lang
                                        datakey="financial_advisor"
                                        defaultText="Financial Advisor"
                                      />
                                    </AlgoReviewName>
                                  </AlogoRevierNameContainer>
                                </AlgoReviewNameWrapper>
                                <AlgoReviewStarWrapper>
                                  {Array.from({ length: 5 }, (v, i) => (
                                    <AlgoReviewStar key={i}>
                                      <Icons.Star />
                                    </AlgoReviewStar>
                                  ))}
                                </AlgoReviewStarWrapper>
                                <AlgoReviewContent>
                                  {item.content}
                                </AlgoReviewContent>
                              </AlgoReviewsContainer>
                            </SwiperSlide>
                          ))}
                      </Swiper>
                    </AlgoReviewsWrapper>
                  </AlgoFeatureWrapper>
                  <AlgoDirection>
                    <Lang
                      datakey={item.SloganLanguageKey}
                      defaultText={item.SloganLanguageKey}
                    />
                  </AlgoDirection>
                </AlgoContainer>
              </AlgoWrapper>
            ))}
        </AlgoInfoWrapper>
      </Container>

      {isOpen && (
        <ZoomIn
          data={slideData}
          clickSlide={onClickSlide}
          setIsOpen={setIsOpen}
        />
      )}
    </Wrapper>
  );
};

export default StepOne;
