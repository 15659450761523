import styled, { css } from "styled-components";
import { useEffect, useState } from "react";
import { API_GetDownlineTransactionRecord } from "../../../../utils/api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Icons from "../../../global/icons";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";
import Lang from "../../../global/Lang";

const CloseButton = styled.button`
  position: absolute;
  top: 5%;
  right: 5%;
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: Block;
`;

const Wrapper = styled.div`
  width: 70%;
  max-height: 80vh;
  padding: 20px;
  border-color: #fcfcfc;
  border-radius: 20px;
  border: 1px solid;
  background: #fcfcfc;
  color: black;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  width: 80vw;
  max-width: 1000px;
  overflow: hidden;
  ${RWD_MD(css`
    width: 90%;
  `)}
`;

const TitleContainer = styled.div`
  color: #151944;
  font-size: min(max(1.5625vw, 28px), 30px);
  font-weight: bold;
  width: 100%;
  padding: 20px;
  text-align: center;
  ${RWD_SM(css`
    font-size: 20px;
  `)}
`;

const DataTitleContainer = styled.div`
  display: flex;
`;

const DataTitle = styled.div`
  width: 25%;
  background-color: #151944;
  color: #fcfcfc;
  font-size: min(max(0.833vw, 14px), 16px);
  padding: 10px;
  ${RWD_SM(css`
    font-size: 8px;
  `)}
`;

const DataBody = styled.div`
  display: block;
  overflow-y: auto;
  overflow: auto;
  max-height: 60vh;
  height: 100%;
`;

const DataItem = styled.div`
  display: flex;
  background-color: ${(props) => (props.index % 2 === 0 ? "white" : "#D9D9D9")};
`;

const DataRow = styled.div`
  border-color: black;
  border: 1px solid;
  font-size: min(max(0.833vw, 14px), 16px);
  padding: 10px;
  width: 25%;
  word-wrap: break-word;
  ${RWD_SM(css`
    font-size: 8px;
  `)}
`;
const PopupRawData = ({ onClose }) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  useEffect(() => {
    const fetchPopupHistoryData = async () => {
      try {
        Swal.fire({
          title: "Loading...",
          showConfirmButton: false,
          allowOutsideClick: false,
        });

        const res = await API_GetDownlineTransactionRecord();
        if (res.ResultCode != 0) {
          setIsLoading(false);
          setError(true);
          Swal.close();
          Swal.fire(res.Message);
        }
        setData(res.ListInvestReward);
        setIsLoading(false);
        Swal.close();
      } catch (error) {
        setIsLoading(false);
        setError(true);
        Swal.close();
        Swal.fire(error.Message);
      }
    };
    fetchPopupHistoryData();
  }, []);

  const formatDate = (rawDate) => {
    const dateObj = new Date(rawDate);
    return `${dateObj.getFullYear()}/${
      dateObj.getMonth() + 1
    }/${dateObj.getDate()} ${String(dateObj.getHours()).padStart(
      2,
      "0"
    )}:${String(dateObj.getMinutes()).padStart(2, "0")}:${String(
      dateObj.getSeconds()
    ).padStart(2, "0")}`;
  };
  if (isLoading) return null;
  return (
    <Wrapper>
      <CloseButton onClick={onClose}>
        <Icons.Close />
      </CloseButton>
      <TitleContainer>
        <Lang
          datakey="member_hive_sharing_last_day"
          defaultText="Hive Sharing Last 90 Days"
        />
      </TitleContainer>
      <DataTitleContainer>
        <DataTitle>
          <Lang datakey="member_benefit_level" defaultText="BenefitLevel" />
        </DataTitle>
        <DataTitle>
          <Lang datakey="member_from_account" defaultText="FromAccount" />
        </DataTitle>
        <DataTitle>
          <Lang datakey="member_share_points" defaultText="SharePoints" />
        </DataTitle>
        <DataTitle>
          <Lang datakey="member_create_time" defaultText="CreateTime" />
        </DataTitle>
      </DataTitleContainer>
      <DataBody>
        {data &&
          data.map((item, index) => (
            <DataItem key={index} index={index}>
              <DataRow>{item.BenefitLevel}</DataRow>
              <DataRow>{item.TriggerName}</DataRow>
              <DataRow>{item.RewardPoints}</DataRow>
              <DataRow>{formatDate(item.CreateTime)}</DataRow>
            </DataItem>
          ))}
      </DataBody>
    </Wrapper>
  );
};

export default PopupRawData;
