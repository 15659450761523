import { ThemeProvider } from "styled-components";

const Theme = {
  colors: {
    Green: "#0AECD1",
    LightGreen: "#C8FFF8",
    Black: "#0B0E28",
    White: "#FCFCFC",
    DarkGrey: "#54587B",
    Grey: "#D9D9D9",
    DarkBlue: "#151944",
    Blue: "#2F80ED",
  },
};

// const Theme = ({ children }) => {
//   <ThemeProvider theme={theme}>{children}</ThemeProvider>;
// };

export default Theme;
