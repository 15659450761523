import styled from 'styled-components';

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    overflow: none;
    position: relative;
`;

export const LoginContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    padding: 2rem 5rem;
    border: 1px solid #ccc;
    border-radius: 10px;
`;

export const LoginTitle = styled.h3`
    text-align: center;
    text-transform: uppercase;
    color: #666;
`;

export const LoginForm = styled.form`

`;

export const LoginField = styled.div`
    min-width: 20rem;
    padding: .5rem 0;

    > * {
        display: block;
        width: 100%;
    }
`;

export const LoginActionContainer = styled.div`
    padding: 1rem 0;
`;
