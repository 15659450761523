import styled, { css } from "styled-components";
import { QRCodeCanvas } from "qrcode.react";
import Icons from "../../global/icons";

import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";
import { useState, useEffect, useRef } from "react";
import { LangString } from "../../utils";
import { AlertMySwal } from "../../global/MySwal";
import Lang from "../../global/Lang";
import html2canvas from "html2canvas";
import pcImage from "../../images/referral/general_view.png";
import joinImage from "../../images/referral/join.png";
import logo from "../../../images/logo_light.png";

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
`;

const Container = styled.div`
  background-color: #fcfcfc;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 40px;
  /* padding: 2.088vw 4.166vw; */
  width: 41.66vw;
  max-width: 650px;
  border-radius: 8px;
  border: 1px solid blue;

  ${RWD_LG(css`
    width: 70%;
  `)};

  ${RWD_MD(css`
    padding: 20px 40px;
  `)};

  ${RWD_SM(css`
    width: 90%;
    padding: 20px;
  `)};
`;

const QrocdeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
`;

const QrcodeContainer = styled.div`
  padding: 20px;
  border: 1px solid #151944;
  /* background: #fcfcfc; */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  background: #fcfcfc;
`;

const QrcodeDownload = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  padding: 5px 0;
  background: red;
  background: #151944;
  margin-bottom: 30px;

  svg {
    fill: #fcfcfc;
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }
`;

const QrcodeDownloadImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #0f0c29, #302b63, #24243e);
  position: relative;
  cursor: pointer;
  overflow: hidden;
`;

const QrcodeLogo = styled.div`
  position: absolute;
  top: 0;
  z-index: 1;
  left: 0;
  width: 50px;
  margin: 10px 0 0 15px;

  img {
    width: 100%;
    height: auto;
  }
`;

const QrcodeDownloadImage = styled.div`
  height: 350px;
  width: 100%;

  img {
    position: absolute;
    bottom: -20px;
    left: -20px;
    height: 100%;
  }

  ${RWD_SM(css`
    height: 250px;
  `)};
`;

const QrcodeJoinImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 55%;
  top: 10%;
  right: 2%;

  img {
    width: 100%;
    height: auto;
    margin: 10px 0;
    object-fit: contain;
  }
`;

const QrcodeInner = styled.div`
  display: flex;
  align-items: center;
  background-color: aliceblue;
  position: absolute;
  padding: 6px;
  border-radius: 4px;
  top: 28%;
  left: 47%;
  transform: translate(-50%, 0%);
`;

const QrcodeReferralWrapper = styled.div`
  margin-top: auto;
  display: flex;
  position: absolute;
  bottom: 20px;
  right: 5%;
  gap: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${RWD_SM(css`
    gap: 0;
    bottom: 10px;
  `)};
`;

const QrcodeReferralText = styled.div`
  font-size: 15px;
`;

const QrcodeReferralContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: baseline;
`;

const QrcodeReferral = styled.div``;

const QrcodeReferralCode = styled.div`
  color: #0aecd1;
  font-size: 20px;
`;

const Title = styled.div`
  color: #151944;
  font-size: min(max(1.5625vw, 28px), 30px);
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;

  ${RWD_MD(css`
    font-size: min(max(1.0416vw, 18px), 20px);
  `)};
`;

const ContentWrapper = styled.div`
  color: #151944;
  display: flex;
  justify-content: space-between;
`;

const ContentTitle = styled.div`
  color: #151944;
  font-size: min(max(1.145vw, 20px), 22px);
  font-weight: bold;
  padding: 10px 0;
  display: flex;
  white-space: nowrap;
  width: 35%;

  ${RWD_MD(css`
    font-size: min(max(0.9375vw, 16px), 18px);
  `)};

  ${RWD_SM(css`
    width: 40%;
  `)};
`;
const Content = styled.div`
  font-size: 1.145vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: min(max(0.9375vw, 16px), 18px);
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #151944;
  width: 65%;

  ${RWD_SM(css`
    width: 60%;
  `)};
`;

const ContentBox = styled.div`
  /* max-height: 150px; */
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ConfirmButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 30px;
`;

const ConfirmButton = styled.button`
  border-radius: 60px;
  width: min(max(7.0833vw, 100px), 135px);
  text-align: center;
  background: #151944;
`;

const ConfirmButtonText = styled.span`
  font-size: min(max(1.0416vw, 16px), 20px);

  display: inline-flex;
  padding: 0.52vw 1.3vw;
  color: #fcfcfc;
  ${RWD_LG(css`
    padding: 8px 18px;
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const CopyIconWrapper = styled.div`
  width: 28px;
  height: 28px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: 28px;
    height: 28px;
  }

  /* & path {
    fill: #151944;
  } */

  ${RWD_SM(css`
    width: 25px;
    height: 25px;

    svg {
      width: 25px;
      height: 25px;
    }
  `)};
`;

const ReferenceModal = ({ code, setReferanceModalIsOpen }) => {
  const [referenceLink, setReferenceLink] = useState("");
  const [referencePage, setReferencePage] = useState("");

  const useCapture = ({ fileName, ...options } = {}) => {
    const ref = useRef(null);

    const handleCapture = async () => {
      if (!ref.current) return;
      const canvas = await html2canvas(ref.current, options);
      const link = document.createElement("a");
      link.download = code;
      link.href = canvas.toDataURL();
      link.click();
    };
    return [ref, handleCapture];
  };

  const [ref, handleCapture] = useCapture();

  useEffect(() => {
    setReferenceLink(`${window.location.origin}/v2/register/?invites=${code}`);
    setReferencePage(`${window.location.origin}/v2/invite/?invites=${code}`);
  }, [code]);

  const handleCopy = (type) => {
    // 多國語言
    const CopyResString = LangString("alert_copy", "Copy successful");
    let text;
    if (type === "code") {
      text = code;
    } else {
      text = referenceLink;
    }
    navigator.clipboard.writeText(text).then(() => {
      AlertMySwal({
        title: CopyResString,
        showConfimButton: false,
      });
    });
  };

  const handleNavigate = () => {
    window.open(referencePage, "_blank");

    // const handleSelectTransferPackage = (id) => {
    //   navigate(`transferpackage/${id}`);
    // };
  };

  //   const handleNavigate = () => {
  //     // 創建一個包含查詢參數的URL
  //     const queryParams = new URLSearchParams();
  //     queryParams.append("invites", code);
  //     queryParams.append("invites", code);
  //     const url = `${window.location.origin}/v2/invite?${queryParams.toString()}`;

  //     // 使用 window.open 在新分頁中打開URL
  //     window.open(url, "_blank");
  //   };

  const handleClose = () => {
    setReferanceModalIsOpen(false);
  };

  const { width } = useWindowSize();

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: typeof window !== "undefined" ? window.innerWidth : 0,
      height: typeof window !== "undefined" ? window.innerHeight : 0,
    });

    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
  }

  return (
    <Wrapper>
      <Container>
        <Title>
          <Lang datakey="referral_code" defaultText="Referral Code" />
        </Title>
        {/* <QrocdeWrapper>
          <QrcodeContainer>
            <QRCodeCanvas
              id="qrcode"
              value={referenceLink}
              bgColor={"transparent"}
              fgColor={"#151944"}
            />
          </QrcodeContainer>
        </QrocdeWrapper> */}
        <QrcodeDownloadImageWrapper ref={ref} onClick={handleCapture}>
          <QrcodeLogo>
            <img src={logo} alt="ava_logo" />
          </QrcodeLogo>

          <QrcodeDownloadImage>
            <img src={pcImage} alt="ava_referral" />
          </QrcodeDownloadImage>
          <QrcodeJoinImage>
            <img src={joinImage} alt="ava_referral_join" />
            <QrcodeInner>
              <QRCodeCanvas
                id="qrcode"
                value={referenceLink}
                bgColor={"transparent"}
                fgColor={"#151944"}
                size={width > 430 ? 60 : 40}
              />
            </QrcodeInner>
          </QrcodeJoinImage>
          <QrcodeReferralWrapper>
            <QrcodeReferralText>
              <Lang
                datakey="referral_text"
                defaultText="Member Only, Speed Your Way to FIRE!"
              />
            </QrcodeReferralText>

            <QrcodeReferralContainer>
              <QrcodeReferral>
                <Lang datakey="referral_code" defaultText="Referral Code" />
              </QrcodeReferral>
              <QrcodeReferralCode>{code}</QrcodeReferralCode>
            </QrcodeReferralContainer>
          </QrcodeReferralWrapper>
        </QrcodeDownloadImageWrapper>

        <QrcodeDownload onClick={handleCapture}>
          Click To Download
          <Icons.Download />
        </QrcodeDownload>
        <ContentBox>
          <ContentWrapper>
            <ContentTitle>
              <Lang datakey="referral_code" defaultText="Referral Code" />
            </ContentTitle>
            <ContentContainer>
              <Content>{code}</Content>
              <CopyIconWrapper onClick={() => handleCopy("code")}>
                <Icons.Copy />
              </CopyIconWrapper>
            </ContentContainer>
          </ContentWrapper>
          <ContentWrapper>
            <ContentTitle>
              <Lang datakey="referral_link" defaultText="Referral Link" />
            </ContentTitle>
            <ContentContainer>
              <Content>{referenceLink}</Content>
              <CopyIconWrapper onClick={() => handleCopy("link")}>
                <Icons.Copy />
              </CopyIconWrapper>
            </ContentContainer>
          </ContentWrapper>
          <ContentWrapper>
            <ContentTitle>
              <Lang datakey="referral_page" defaultText="Referral Page" />
            </ContentTitle>
            <ContentContainer>
              <Content>{referencePage}</Content>
              <CopyIconWrapper onClick={handleNavigate}>
                <Icons.Redirect />
              </CopyIconWrapper>
            </ContentContainer>
          </ContentWrapper>
        </ContentBox>

        <ConfirmButtonWrapper>
          <ConfirmButton onClick={handleClose}>
            <ConfirmButtonText>確認</ConfirmButtonText>
          </ConfirmButton>
        </ConfirmButtonWrapper>
      </Container>
    </Wrapper>
  );
};

export default ReferenceModal;
