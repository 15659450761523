import Invite from './Invite'
import Level from './Level'
import Sharing from './Sharing'
import UserInfo from './UserInfo'
import Withdrawal from './Withdrawal'

const Main = ({ data }) => {
  const {
    user,
    userData,
    userGroup,
    isDarkMode,
    invite,
    sourceHereAhftHiveBenefit,
    getSharingHistory,
    inputWithdrawalValue,
    totalWithdralPoints,
  } = data;
  return (
    <div className='mx-auto w-[320px] sm:w-[640px] 2xl:w-[960px]'>
      <UserInfo data={{ user, invite }} />
      <div className='
        child:h-[150px] child:w-[300px] child:sm:m-2.5
        flex flex-wrap items-stretch
      '>
        <Sharing data={{ user, userData, isDarkMode, getSharingHistory, sourceHereAhftHiveBenefit, inputWithdrawalValue }} />
        <Withdrawal data={{ user, userGroup, userData, totalWithdralPoints }} />
        <Level data={{ userGroup, userData }} />
        <Invite data={{ user, invite }} />
      </div>
    </div>
  )
}

export default Main
