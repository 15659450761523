import styled, { css } from "styled-components";
import PV from "../../../images/pv_robot.png";
import { RWD_XL, RWD_LG, RWD_MD, RWD_SM } from "../../../constants/rwd";
import { GiMeshNetwork } from "react-icons/gi";
import { GiKeyLock } from "react-icons/gi";
import { GiBullseye } from "react-icons/gi";
import parse from "html-react-parser";
import { getLanguage } from "../../../v2/services/common";
import { useNavigate } from "react-router-dom";
import { routes } from "../../Router";

const Wrapper = styled.div`
  margin-top: 6.25vw;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 6.25vw);
  margin-top: 6.25vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  ${RWD_MD(css`
    margin-top: 8vh;
    min-height: calc(100vh - 8vh);
  `)};

  ${RWD_SM(css`
    margin-top: 65px;
    min-height: calc(100vh - 65px);
  `)};
`;

const Container = styled.div`
  display: flex;
  width: 81.88vw;

  ${RWD_MD(css`
    flex-direction: column;
  `)};
`;

const Title = styled.h1`
  color: ${(props) => props.theme.colors.White};
  font-weight: 500;
  font-size: clamp(50px, 3.125vw, 60px);

  ${RWD_SM(css`
    font-size: clamp(24px, 6.976vw, 30px);
  `)};
`;

const SubTitle = styled.h2`
  color: ${(props) => props.theme.colors.Grey};
  font-weight: 400;
  margin-bottom: 30px;
  font-size: clamp(13px, 1.145833vw, 22px);

  ${RWD_MD(css`
    font-size: clamp(13px, 2.34375vw, 18px);
  `)};

  ${RWD_SM(css`
    margin-bottom: 0;
  `)};
`;

const Highlight = styled.span`
  color: transparent;
  background-image: linear-gradient(
    -225deg,
    #77ffd2 0%,
    #6297db 48%,
    #1eecff 100%
  );
  font-weight: 600;
  background-clip: text;
`;

const FeaturesWrapper = styled.div`
  display: flex;
  gap: 20px;

  ${RWD_SM(css`
    flex-direction: column-reverse;
    margin-top: auto;
  `)};
`;

const Features = styled.div`
  color: ${(props) => props.theme.colors.White};
  display: flex;
  align-items: flex-start;
  gap: 5px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);

  &:last-child {
    border-right: none;
  }

  ${RWD_LG(css`
    max-width: 33%;
  `)};

  ${RWD_SM(css`
    max-width: 100%;
    border-right: none;
  `)};
`;

const FeaturesContainer = styled.div`
  padding-right: 20px;
`;

const FeaturesIcon = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.Green};
  font-size: clamp(18px, 1.45833vw, 28px);
  padding: 5px;
`;

const FeaturesTitle = styled.div`
  font-size: clamp(18px, 1.45833vw, 28px);
  display: flex;
  gap: 5px;
  margin-bottom: 5px;

  ${RWD_SM(css`
    font-size: 16px;
  `)};
`;

const FeaturesContent = styled.div`
  font-size: clamp(16px, 1.041667vw, 20px);
  color: ${(props) => props.theme.colors.Grey};

  ${RWD_SM(css`
    font-size: 14px;
  `)};
`;

const PvImage = styled.div`
  position: absolute;
  display: inline-flex;
  height: 100vh;
  width: 100%;
  z-index: 1;
  top: -6.25vw;
  background-image: url(${PV});
  background-size: cover;
  background-position: top 3vw center;
  background-repeat: no-repeat;

  img {
    /* position: absolute; */

    object-fit: cover;
    object-position: center;
  }

  ${RWD_LG(css`
    opacity: 0.8;
    background-position: right -30vw bottom 0;
    /* img {
      object-position: right -30vw bottom 0;
    } */
  `)};

  ${RWD_MD(css`
    top: -8vh;
    background-position: right -60vw bottom 0;
    /* img {
      object-position: right -50vw bottom 0;
    } */
  `)};

  ${RWD_SM(css`
    top: -65px;

    object-position: right -75vw bottom 0;
  `)};
`;

const SloganWrapper = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  width: 100%;
  flex-direction: column;

  ${RWD_LG(css`
    height: 75vh;
  `)};
`;

const ButtonWrapper = styled.div`
  margin-bottom: 50px;

  ${RWD_LG(css`
    order: 3;
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  `)};

  ${RWD_LG(css`
    margin-bottom: 0;
  `)};
`;

const Button = styled.button`
  border-radius: 60px;
  padding: 0.78125vw 2.9166vw;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  font-size: clamp(18px, 1.145vw, 22px);

  border: ${(props) =>
    props.type === "hollow" ? `1px solid ${props.theme.colors.White}` : ""};
  background: ${(props) =>
    props.type === "hollow"
      ? "transparent"
      : "linear-gradient(to bottom, #2F80ED 0%, #5620F0 100%)"};
  color: ${(props) => props.theme.colors.White};
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 2;
  text-decoration: none;

  ${RWD_MD(css`
    /* font-size: 4.166vw; */
    padding: 13px 40px;
  `)};

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 30px;
    z-index: -2;
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: ${(props) => (props.type === "hollow" ? "100%" : "0%")};
    height: ${(props) => (props.type === "hollow" ? "0" : "100%")};
    background: ${(props) =>
      props.type === "hollow"
        ? "white"
        : "linear-gradient(to right, #6a11cb 0%, #2575fc 100%)"};
    transition: all 0.3s;
    border-radius: 30px;
    z-index: -1;
  }

  &:hover {
    color: ${(props) =>
      props.type === "hollow"
        ? props.theme.colors.DarkBlue
        : props.theme.colors.White};
    border: ${(props) =>
      props.type === "hollow" ? "1px solid transparent" : ""};

    &:before {
      width: 100%;
      height: 100%;
    }
  }
`;

const Hero = () => {
  const navigate = useNavigate();
  const titleData = [
    {
      ch: "你的AI交易助手",
      en: "Your Artificial Intelligence<br />Trading Assistant",
    },
  ];

  const ctaData = [
    {
      ch: "立即體驗",
      en: "Experience Now",
    },
  ];

  const featuresData = [
    {
      id: 1,
      icon: <GiBullseye />,
      title: [
        {
          ch: "緊盯局勢",
          en: "Stay on Top",
        },
      ],
      content: [
        {
          ch: "無縫下達專業判斷",
          en: "Seamlessly Deliver Professional Judgments",
        },
      ],
    },
    {
      id: 2,
      icon: <GiMeshNetwork />,
      title: [
        {
          ch: "自動交易AI",
          en: "Automated Trading AI",
        },
      ],
      content: [
        {
          ch: "掌握加密貨幣市場",
          en: "Master the Cryptocurrency Market",
        },
      ],
    },
    {
      id: 3,
      icon: <GiKeyLock />,
      title: [
        {
          ch: "安全保障",
          en: "Security Assurance",
        },
      ],
      content: [
        {
          ch: "嚴格保護數據安全",
          en: "Strictly Protect Data Security",
        },
      ],
    },
  ];

  const templateHandler = (lang) => {
    if (lang?.toLocaleLowerCase() === "en") {
      const keyword = "Artificial Intelligence";
      return titleData[0].en
        .split(new RegExp(`(${keyword})`, "gi"))
        .map((part, i) =>
          part === keyword ? <Highlight key={i}>{part}</Highlight> : parse(part)
        );
    } else {
      const keyword = "AI";
      return titleData[0].ch
        .split(new RegExp(`(${keyword})`, "gi"))
        .map((part, i) =>
          part === keyword ? <Highlight key={i}>{part}</Highlight> : part
        );
    }
  };

  return (
    <Wrapper id="Home">
      <Container>
        <SloganWrapper>
          <Title>{templateHandler(getLanguage())}</Title>

          <SubTitle>You Have the Vision, We Drive the Growth</SubTitle>
          <ButtonWrapper>
            <Button onClick={() => navigate(routes.login_v2)}>
              {ctaData[0][getLanguage()?.toLocaleLowerCase()]}
            </Button>
          </ButtonWrapper>
          <FeaturesWrapper>
            {featuresData.map((data) => (
              <Features key={data.id}>
                <FeaturesIcon>{data.icon}</FeaturesIcon>
                <FeaturesContainer>
                  <FeaturesTitle>
                    {data.title[0][getLanguage()?.toLocaleLowerCase()]}
                  </FeaturesTitle>
                  <FeaturesContent>
                    {data.content[0][getLanguage()?.toLocaleLowerCase()]}
                  </FeaturesContent>
                </FeaturesContainer>
              </Features>
            ))}
          </FeaturesWrapper>
        </SloganWrapper>
      </Container>
      <PvImage>{/* <img src={PV} alt="ava" /> */}</PvImage>
    </Wrapper>
  );
};

export default Hero;
