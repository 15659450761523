import { useEffect, useState } from "react";
import { API_GetAlgoTargets, API_UpdateAlgoTargets } from "../../../utils/api";
import styled from "styled-components";
import UseSwal from "../../hooks/Lang"; // swal confirm button background color

const Container = styled.div`
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 4rem 2rem;
  background: #fffa;
  box-shadow: -5px 5px 10px 0px #0003;
  backdrop-filter: blur(10px);
  transform: translateX(-100%);
  transition: 500ms;
  width: 100%;

  display: flex;
  flex-direction: column;

  &.show {
    transform: translate(0);
  }

  @media screen and (min-width: 1024px) {
    width: auto;
  }
`;

const TargetContainer = styled.div`
  overflow-y: auto;
  input {
    padding: 0.2rem 0.5rem;
  }
`;
const TargetWrapper = styled.div``;
const TargetItem = styled.div`
  padding-bottom: 1rem;

  > * {
    display: flex;
    align-items: center;

    &:not(:first-child) {
      padding-top: 0.5rem;
    }
  }
`;
const TargetTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  color: #484891;
`;
const TargetName = styled.div`
  width: 8rem;
  padding-right: 1rem;
`;
const UploadImage = styled.label`
  padding: 0.2rem;
  border: 1px solid #48489180;
  border-radius: 4px;
  color: #48489180;
  font-size: 0.9rem;
  transition: 200ms;
  background: #fff;
  cursor: pointer;

  &:hover {
    border: 1px solid #484891;
    color: #484891;
  }

  img {
    width: 2rem;
    height: 2rem;
    object-fit: contain;
  }
`;

const AddTargetContainer = styled.div`
  padding: 0.2rem;
  border: 1px solid #48489180;
  border-radius: 4px;
  color: #48489180;
  font-size: 0.9rem;
  text-align: center;
  transition: 200ms;
  background: #fff;
  cursor: pointer;

  &:hover {
    border: 1px solid #484891;
    color: #484891;
  }
`;

const ActionContainer = styled.div`
  padding-top: 1rem;
  text-align: center;

  button.outline {
    margin-right: 1rem;
  }
`;

const AlgoTargets = ({ isOpen, closeBox }) => {
  const getAlgoData = async () => {
    const res = await API_GetAlgoTargets();
    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return UseSwal(res.Message);
    setData(res.Targets);
  };

  const handleCloseBox = () => {
    setNewTargets([]);
    setNewIcons({});
    setNewNames({});
    closeBox();
  };

  const handleSave = async (e) => {
    e.preventDefault();

    if (newTargets.some(({ TargetKey }) => !newNames[TargetKey]))
      return UseSwal("顯示名稱不可為空");

    const formdata = new FormData();
    Array.from(Object.keys(newIcons)).map((key) => {
      const file = newIcons[key];
      const filename = `${key}.${file.name.split(".")[1]}`;
      formdata.append(key, newIcons[key], filename);
    });

    Array.from(Object.keys(newNames)).map((key) => {
      formdata.append(key, newNames[key]);
    });

    const res = await API_UpdateAlgoTargets(formdata);
    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return UseSwal(res.Message);
    UseSwal("更新成功!");
    await getAlgoData();
    handleCloseBox();
  };

  const addNewTarget = () => {
    UseSwal({
      title: "請輸入 Target Key",
      input: "text",
      inputPlaceholder: "僅可輸入英文或數字（最多十字元）",
      inputAttributes: {
        maxlength: "10",
        style: "text-align: center; text-transform: uppercase;",
      },
    }).then((result) => {
      if (!/^[a-zA-Z0-9]+$/.test(result.value))
        return UseSwal("TargetKey 格式錯誤");

      if (
        newTargets.filter(
          ({ TargetKey }) => TargetKey === result.value.toUpperCase()
        ).length > 0
      )
        return UseSwal("TargetKey 已存在");

      setNewTargets([
        ...newTargets,
        {
          TargetKey: result.value.toUpperCase(),
          Name: "",
          IconPath: "",
        },
      ]);
    });
  };

  const [data, setData] = useState();
  const [newTargets, setNewTargets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newIcons, setNewIcons] = useState({});
  const [newNames, setNewNames] = useState({});

  useEffect(() => {
    if (!data) setData("get");
  }, []);

  useEffect(() => {
    if (data === "get") getAlgoData();
  }, [data]);

  return (
    <Container className={isOpen ? "show" : ""}>
      <TargetContainer>
        <TargetWrapper>
          {data !== "get" &&
            data?.length > 0 &&
            [...data, ...newTargets]?.map(
              ({ TargetKey, Name, IconPath }, index) => (
                <TargetItem key={index}>
                  {/* 幣種名稱 */}
                  <TargetTitle>{TargetKey}</TargetTitle>

                  {/* 圖示 */}
                  <div>
                    <TargetName>圖示</TargetName>
                    {!IconPath && !newIcons[TargetKey] ? (
                      <UploadImage htmlFor={`${TargetKey}_icon`}>
                        上傳圖片
                      </UploadImage>
                    ) : (
                      <UploadImage htmlFor={`${TargetKey}_icon`}>
                        <img
                          src={
                            newIcons[TargetKey]
                              ? URL.createObjectURL(newIcons[TargetKey])
                              : IconPath
                          }
                        />
                      </UploadImage>
                    )}
                    <input
                      id={`${TargetKey}_icon`}
                      type="file"
                      accept="image/*"
                      onChange={(e) =>
                        setNewIcons({
                          ...newIcons,
                          [TargetKey]: e.target.files[0],
                        })
                      }
                      hidden
                    />
                  </div>

                  {/* 顯示名稱 */}
                  <div>
                    <TargetName>顯示名稱</TargetName>
                    <input
                      value={newNames[TargetKey] ?? Name}
                      onChange={(e) =>
                        setNewNames({
                          ...newNames,
                          [TargetKey]: e.target.value,
                        })
                      }
                    />
                  </div>
                </TargetItem>
              )
            )}

          <AddTargetContainer onClick={addNewTarget}>
            新增幣種
          </AddTargetContainer>

          <ActionContainer>
            <button className="outline" onClick={handleCloseBox}>
              取消
            </button>
            <button onClick={handleSave}>儲存</button>
          </ActionContainer>
        </TargetWrapper>
      </TargetContainer>
    </Container>
  );
};

export default AlgoTargets;
