export const handleColorType = (type) => {
  switch (type) {
    case "fill":
      return {
        border:
          "1px solid linear-gradient(to bottom, #2f80ed 0%, #5620f0 100%)",
        bg: "linear-gradient(to bottom, #2f80ed 0%, #5620f0 100%)",
      };
    case "stroke":
      return {
        border: "1px solid #fcfcfc",
        bg: "transparent",
      };
    case "disabled":
      return {
        border: "1px solid #3A3D5A",
        bg: "#3A3D5A",
      };
    case "Waiting":
      return {
        border: "1px solid #3A3D5A",
        bg: "linear-gradient(to right, #0aecd1 0%,#007adf 100%);",
      };
    case "Finished":
      return {
        border: "1px solid #3A3D5A",
        bg: "linear-gradient(to right, #ed6ea0 0%, #ec8c69 100%);",
      };

    default:
      return {
        border: "1px solid #fcfcfc",
        bg: "transparent",
      };
  }
};

export const handleMiningStatusColorType = (type) => {
  switch (type) {
    case "running":
      return {
        bg: "#4643CA",
      };
    case "waiting":
      return {
        bg: "#54587B",
      };
    case "over":
      return {
        bg: "#E89924",
      };

    case "help":
      return {
        bg: "#F05D2F",
      };

    default:
      return {
        bg: "#54587B",
      };
  }
};
