import styled, { css } from "styled-components";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectAccount } from "../../slices/accountSlice";
import { selectSystem } from "../../slices/systemSlice";
import { useGetTargetExchangeIdsMutation } from "../../services/algoApi";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";

import Lang from "../../global/Lang";

import SelectCopyAlgo from "../../global/SelectCopyAlgo";

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
`;

const Container = styled.div`
  background-color: #fcfcfc;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100px;
  padding: 40px;
  height: auto;
  /* width: 41.66vw; */
  width: 41.66vw;
  max-width: 800px;
  border-radius: 8px;

  ${RWD_LG(css`
    width: 70%;
  `)};

  ${RWD_MD(css`
    padding: 20px 40px;
  `)};

  ${RWD_SM(css`
    width: 90%;
    padding: 20px;
  `)};
`;

const Title = styled.div`
  color: #151944;
  font-size: min(max(1.5625vw, 28px), 30px);
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;

  ${RWD_MD(css`
    font-size: min(max(1.0416vw, 18px), 20px);
  `)};
`;

const SubTitle = styled.div`
  color: #151944;
  font-size: min(max(1.145vw, 20px), 22px);
  font-weight: bold;
  padding-bottom: 10px;
  padding-top: 1.3vw;
  display: flex;

  ${RWD_MD(css`
    font-size: min(max(0.9375vw, 16px), 18px);
  `)};
`;

const InputWrapper = styled.div``;

const Input = styled.input`
  background: #d9d9d9;
  padding: 1.0416vw 1.5625vw;
  width: 100%;
  border: 1px solid;
  border-radius: 100px;
  color: #151944;
  border-color: ${(props) =>
    props.error ? "#F05D2F" : "rgba(255, 255, 255, 0.15)"};

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 14px), 16px);
    padding: 8px 20px;
  `)};
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 50px;

  ${RWD_SM(css`
    padding-top: 25px;
    justify-content: space-between;
  `)};
`;

const Button = styled.button`
  width: min(max(7.0833vw, 100px), 135px);
  border-radius: 60px;
  border: 1px solid;
  color: ${(props) => (props.type === "confirm" ? "#fcfcfc" : "#151944")};
  background-color: ${(props) =>
    props.type === "confirm" ? (props.disabled ? "#d9d9d9" : "#151944") : ""};
  border-color: ${(props) => (props.disabled ? "#d9d9d9" : "#151944")};

  &:first-child {
    margin-right: 1.5625vw;
  }
`;

const ButtonText = styled.div`
  padding: 0.52vw 1.3vw;
  text-align: center;
  font-size: min(max(1.0416vw, 18px), 20px);

  ${RWD_LG(css`
    padding: 6px 15px;
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const SettingAmountModal = ({
  robotId,
  copyAmount,
  setCopyAmount,
  setAmountSettingModalIsOpen,
  copyAlgo,
  copyTarget,
  setCopyTarget,
  copyExchange,
  setCopyExchange,
}) => {
  const robot = useSelector(selectSystem).airobots;
  const exchange = useSelector(selectSystem).exchanges;
  const apikey = useSelector(selectAccount).accountApikey;
  const [targetData, setTargetData] = useState([]);
  const [exchangeData, setExchangeData] = useState([]);

  // 取得可使用的幣種和交易所
  const [
    _getTargetExchangeIds,
    {
      data: targetExchangeIds,
      isSuccess: targetExchangeIdsIsSuccess,
      isError: targetExchangeIdsIsError,
      error: targetExchangeIdsyError,
      refetch: targetExchangeIdsRefetch,
    },
  ] = useGetTargetExchangeIdsMutation();

  useEffect(() => {
    // 該機器人可選擇的Target
    setTargetData(
      robot
        .filter((item) => item.Id === robotId)[0]
        ?.Targets.map((el) => {
          return {
            Key: el.TargetKey,
            Value: el.Target,
          };
        })
    );
  }, [robotId, robot]);

  useEffect(() => {
    // 拿到target之後 確認該Target可選擇的Exchange
    _getTargetExchangeIds({
      body: {
        TargetKey: copyTarget,
      },
    });
  }, [copyTarget]);

  useEffect(() => {
    if (targetExchangeIdsIsSuccess) {
      const availableApiKey = apikey
        .filter((data) => data.Abnormal === "None")
        .map((key) => key.ExchangeId);

      setExchangeData(
        exchange
          .filter(
            (data) =>
              targetExchangeIds?.ExchangeIds?.indexOf(data.ExchangeId) > -1
          )
          .filter((item) => availableApiKey.indexOf(item.ExchangeId) > -1)
          .map((el) => {
            return {
              Key: el.ExchangeId,
              Value: el.ExchangeName,
            };
          })
      );
    }
  }, [targetExchangeIds, targetExchangeIdsIsError]);

  const handleCancel = () => {
    setAmountSettingModalIsOpen(false);
  };

  return (
    <Wrapper>
      <Container>
        <Title>Setting Algo</Title>
        <InputWrapper>
          <SubTitle>Amount</SubTitle>
          <InputContainer>
            <Input
              //   error={errorMsg}
              //   placeholder={`100 - ${data?.Profile?.AvaPoints}`}
              onChange={(e) =>
                setCopyAmount(
                  e.target.value
                    .replace(/[^0-9.]/g, "")
                    .replace(/(\..*?)\..*/g, "$1")
                    .replace(/^0[^.]/, "0")
                )
              }
              value={copyAmount}
            />
            {/* <MaxBtn onClick={handleMax}>Max</MaxBtn> */}
          </InputContainer>
        </InputWrapper>
        <SelectCopyAlgo
          dataList={targetData}
          title={"Target"}
          robotId={robotId}
          type={"target"}
          nowValue={copyTarget}
          onChangeFun={setCopyTarget}
        />
        <SelectCopyAlgo
          dataList={exchangeData}
          title={"ExchangeId"}
          robotId={robotId}
          type={"exchange"}
          nowValue={copyExchange}
          onChangeFun={setCopyExchange}
        />

        <ButtonWrapper>
          <Button onClick={handleCancel}>
            <ButtonText>
              <Lang datakey="cancel" defaultText="Cancel" />
            </ButtonText>
          </Button>
          <Button
            type={"confirm"}
            // disabled={
            //   copyAmount.trim() === "" ||
            //   Number(withdraw) > Number(data?.Profile?.AvaPoints)
            // }
            onClick={copyAlgo}
          >
            <ButtonText>
              <Lang datakey="confirm" defaultText="Confirm" />
            </ButtonText>
          </Button>
        </ButtonWrapper>
      </Container>
    </Wrapper>
  );
};

export default SettingAmountModal;
