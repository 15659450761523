// --- components ---
import ShortCut from '../../Global/ShortCut';

import { API_SendAccountReport } from '../../../utils/api';

// --- plugins ---
import { MdOutlineFileDownload } from 'react-icons/md';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { useState } from 'react';
import Loading from '../../Global/Loading';

const ServicesShortcut = ({ handleCheckStatus }) => {
  const handleSendAccountReport = async () => {
    
    setLoading(true);
    const res = await API_SendAccountReport();
    setLoading(false);

    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return Swal.fire(res.Message);

    Swal.fire({
      html: '<div class="px-[2rem]">Please check your registered E-mail address.<br />System has sent you the Account Report.</div>',
      icon: 'success',
    });
  };

  const [loading, setLoading] = useState(false);

  if (loading) return <Loading />;

  return (
    <div className='w-full'>
      <h3 className='py-2'>
        Services Shortcut
      </h3>
      <div className='grid grid-cols-2 4xl:grid-cols-3'>

        <div>
          <ShortCut
            shortcutName='ADD MINING PKG'
            handleClick={handleCheckStatus}
          />
        </div>

        <div>
          <ShortCut
            shortcutName='Get Account Report'
            icon={<MdOutlineFileDownload />}
            handleClick={handleSendAccountReport}
          />
        </div>

      </div>
    </div>
  )
}

export default ServicesShortcut
