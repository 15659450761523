import styled from "styled-components";
import { css } from "styled-components";

import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";

import { useState, useEffect } from "react";
import Lang from "../../global/Lang";
import { useSetWithdrawalMutation } from "../../services/avaPointApi";
import { useGetAccountProfileQuery } from "../../services/accountApi";
import { setAccountProfile } from "../../slices/accountSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectSystem } from "../../slices/systemSlice";
import { useGetAlgoRefetchMutation } from "../../services/algoApi";

import { API_AvaPointWithdrawal } from "../../../utils/api";
import Button from "../../global/Buttons";

// sweetAlert
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
`;

const Container = styled.div`
  background-color: #fcfcfc;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100px;
  padding: 40px;
  height: auto;
  /* width: 41.66vw; */
  width: 41.66vw;
  max-width: 800px;
  border-radius: 8px;

  ${RWD_LG(css`
    width: 70%;
  `)};

  ${RWD_MD(css`
    padding: 20px 40px;
  `)};

  ${RWD_SM(css`
    width: 90%;
    padding: 20px;
  `)};
`;

const Title = styled.div`
  color: #151944;
  font-size: min(max(1.5625vw, 28px), 30px);
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;

  ${RWD_MD(css`
    font-size: min(max(1.0416vw, 18px), 20px);
  `)};
`;

const SubTitle = styled.div`
  color: #151944;
  font-size: min(max(1.145vw, 20px), 22px);
  font-weight: bold;
  padding-bottom: 10px;
  padding-top: 1.3vw;
  display: flex;

  ${RWD_MD(css`
    font-size: min(max(0.9375vw, 16px), 18px);
  `)};
`;

const InputWrapper = styled.div``;

const Input = styled.input`
  background: #d9d9d9;
  padding: 1.0416vw 1.5625vw;
  width: 80%;
  border: 1px solid;
  border-radius: 100px;
  color: #151944;
  border-color: ${(props) =>
    props.error ? "#F05D2F" : "rgba(255, 255, 255, 0.15)"};

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 14px), 16px);
    padding: 8px 20px;
  `)};
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MaxBtn = styled.div`
  width: 15%;
  padding: 10px 15px;
  height: auto;
  background: #151944;
  color: #fcfcfc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: min(max(0.833vw, 14px), 16px);
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 50px;

  ${RWD_SM(css`
    padding-top: 25px;
    justify-content: space-between;
  `)};
`;

// const Button = styled.button`
//   /* width: min(max(7.0833vw, 100px), 135px); */
//   width: 135px;
//   border-radius: 60px;
//   border: 1px solid;
//   color: ${(props) => (props.type === "confirm" ? "#fcfcfc" : "#151944")};
//   background-color: ${(props) =>
//     props.type === "confirm" ? (props.disabled ? "#d9d9d9" : "#151944") : ""};
//   border-color: ${(props) => (props.disabled ? "#d9d9d9" : "#151944")};

//   &:first-child {
//     margin-right: 1.5625vw;
//   }

//   ${RWD_MD(css`
//     width: 90px;
//   `)};
// `;

// const ButtonText = styled.div`
//   padding: 9px 0;
//   text-align: center;

//   font-size: min(max(1.0416vw, 18px), 20px);

//   ${RWD_MD(css`
//     padding: 6px 15px;
//     font-size: min(max(0.833vw, 14px), 16px);
//   `)};
// `;

const ErrorMsgWrapper = styled.div`
  padding-top: 5px;
  font-size: min(max(0.833vw, 14px), 16px);
  color: #f05d2f;
`;

const Withdraw = ({ setOpen }) => {
  const dispatch = useDispatch();
  const lang = useSelector(selectSystem).language;
  const [language, setLanguage] = useState("");
  useEffect(() => {
    setLanguage(lang);
  }, [lang]);
  const [errorMsg, setErrorMsg] = useState("");
  const [withdraw, setWithdraw] = useState("");

  const MySwal = withReactContent(Swal).mixin({
    confirmButtonColor: "#22254a",
  });

  const { data, error, isError, isSuccess, refetch, isFetching } =
    useGetAccountProfileQuery();

  const [_refetchAlgo, {}] = useGetAlgoRefetchMutation();

  const handleWidthdrawal = async () => {
    const res = await API_AvaPointWithdrawal({
      mode: "Apply",
      Point: withdraw,
    });

    // setValue(100);

    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0)
      return MySwal.fire({
        icon: "error",
        title: res.Message,
      }).then((result) => {
        if (result.isConfirmed) {
          // _refetchAlgo();
          handleCancel();
          // refetch();
        }
      });

    if (res.Message === "Success")
      return MySwal.fire({
        icon: "success",
        title: "提領成功",
      }).then((result) => {
        if (result.isConfirmed) {
          _refetchAlgo();
          handleCancel();
          refetch();
        }
      });

    // if (!res) return console.error('API error');
    // if (res.ResultCode !== 0) return Swal.fire(res.Message);

    // const newUserData = {
    //   ...userData,
    //   AvaPoint: userData.AvaPoint - value,
    // };
    // setUserData(newUserData);
    // getWithdrawal();
    // Swal.fire({
    //   icon: "success",
    //   title: "Success!",
    // });
  };

  // const [
  //   _setWithdrawal,
  //   {
  //     data: setWithdrawlData,
  //     error: withdrawalDataError,
  //     isError: withdrawalDataIsError,
  //     isSuccess: withdrawalDataIsSuccess,
  //   },
  // ] = useSetWithdrawalMutation();

  useEffect(() => {
    if (Number(withdraw) < 100 && withdraw !== "") {
      setErrorMsg("minimum");
    } else {
      if (Number(withdraw) > Number(data?.Profile?.AvaPoints)) {
        setErrorMsg("insufficient");
      } else {
        setErrorMsg("");
      }
    }
  }, [withdraw]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(setAccountProfile(data));
    }
  }, [data, isSuccess, isFetching]);

  // useEffect(() => {
  //   if (withdrawalDataIsSuccess) {
  //     console.log(data);

  //     MySwal.fire({
  //       icon: "success",
  //       title: "提領成功",
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         _refetchAlgo();
  //         handleCancel();
  //         refetch();
  //       }
  //     });
  //   }
  // }, [setWithdrawlData, withdrawalDataIsError]);

  const handleCancel = () => {
    setOpen(false);
  };

  const handleMax = () => {
    setWithdraw(data?.Profile?.AvaPoints.toString());
    console.log(withdraw);
  };

  const handlePropagation = () => {
    event.stopPropagation();
  };

  const handleWithdrawal = async () => {
    await handleWidthdrawal();
    // await _setWithdrawal({
    //   body: {
    //     Amount: withdraw,
    //   },
    // });
  };

  return (
    <Wrapper onClick={handlePropagation}>
      <Container>
        <Title>
          <Lang datakey="withdrawal_volume" defaultText="Withdrawal Volume" />
        </Title>
        <InputWrapper>
          <SubTitle>
            <Lang datakey="withdrawal" defaultText="Withdrawal" />
          </SubTitle>
          <InputContainer>
            <Input
              error={errorMsg}
              placeholder={
                language === "Ch"
                  ? "請輸入100或更大的數字。"
                  : "Please enter a number of 100 or higher."
                // data?.Profile?.AvaPoints < 100
                //   ? ""
                //   : `100 - ${data?.Profile?.AvaPoints}`
              }
              onChange={(e) =>
                setWithdraw(
                  e.target.value
                    .replace(/[^0-9.]/g, "")
                    .replace(/(\..*?)\..*/g, "$1")
                    .replace(/^0[^.]/, "0")
                )
              }
              value={withdraw}
            />
            <MaxBtn onClick={handleMax}>
              <Lang datakey="max" defaultText="Max" />
            </MaxBtn>
          </InputContainer>
          <ErrorMsgWrapper error={errorMsg}>
            {(() => {
              switch (errorMsg) {
                case "minimum":
                  return (
                    <Lang
                      datakey="withdrawal_error_minimum"
                      defaultText="Minimum transaction amount is 100. Please adjust."
                    />
                  );

                case "insufficient":
                  return (
                    <Lang
                      datakey="withdrawal_error_insufficient"
                      defaultText="Insufficient AVA Points balance."
                    />
                  );

                default:
                  return;
              }
            })()}
          </ErrorMsgWrapper>
        </InputWrapper>
        <ButtonWrapper>
          <Button onClickFun={handleCancel} type={"cancel"} />
          {/* <Button onClick={handleCancel}>
            <ButtonText>
              <Lang datakey="cancel" defaultText="Cancel" />
            </ButtonText>
          </Button> */}

          <Button
            onClickFun={handleWithdrawal}
            type={"confirm"}
            disabled={
              withdraw.trim() === "" ||
              Number(withdraw) > Number(data?.Profile?.AvaPoints)
            }
          />
          {/* <Button
            type={"confirm"}
            disabled={
              withdraw.trim() === "" ||
              Number(withdraw) > Number(data?.Profile?.AvaPoints)
            }
            onClick={handleWithdrawal}
          >
            <ButtonText>
              <Lang datakey="confirm" defaultText="Confirm" />
            </ButtonText>
          </Button> */}
        </ButtonWrapper>
      </Container>
    </Wrapper>
  );
};

export default Withdraw;
