import { useState, useEffect, useRef } from 'react';
import { StyledFlex, StyledNoContent } from '../../Global/Ui';
import UserCard from './UserCard';
import Loading from '../../Global/Loading';

const Main = ({ data }) => {
  const {
    loading,
    userGroup,
    sort,
    // currentUserGroupPage,
    // setCurrentUserGroupPage,
    // cardPerPage,
    handleSortUserGroup,
    customUserGroup,
    user,
  } = data;

  // --- handler
  const handleGetUserCardPerPage = () => {
    if (usersContainer.current.offsetWidth >= 900)
      return setCardPerPage(10);

    if (usersContainer.current.offsetWidth >= 720)
      return setCardPerPage(8);

    if (usersContainer.current.offsetWidth >= 540)
      return setCardPerPage(6);

    return setCardPerPage(4);
    
  };
  const handlePrevPage = () => {
    if (currentUserGroupPage === 0) return;
    setCurrentUserGroupPage(currentUserGroupPage - 1);
  };
  const handleNextPage = () => {
    if (currentUserGroupPage === (Math.ceil(userGroup.length / cardPerPage) - 1)) return;
    setCurrentUserGroupPage(currentUserGroupPage + 1);
  };
  const handleGetPages = () => {
    if (!userGroup) return 1;
    if (!cardPerPage) return 1;
    const pages = Math.ceil(userGroup.length / cardPerPage);
    return pages === 0 ? 1 : pages;
  };

  const usersContainer = useRef();
  const [cardPerPage, setCardPerPage] = useState(0);
  const [currentUserGroupPage, setCurrentUserGroupPage] = useState(0);

  useEffect(() => {
    handleGetUserCardPerPage();
    window.addEventListener('resize', handleGetUserCardPerPage);
  }, []);

  return (
    <div className='w-full py-12 mx-auto w-[320px] 2sm:w-[360px] sm:w-[540px] md:w-[720px] xl:w-[900px] 2xl:w-[960px]'>
      {/* sort */}
      <div className='mr-[10px] 1sm:mr-[25px] text-right'>
        <span className='mr-3'>sort by</span>
        <select
          className='border border-sub-button bg-[#FAFAFA] dark:bg-black-menu rounded uppercase px-2 py-1'
          value={sort}
          onChange={e => handleSortUserGroup(e.target.value)}
        >
          {/* <option value='profits'>PROFITS</option> */}
          <option value='name'>Name</option>
          <option value='invite'>Invite</option>
          <option value='sharing'>Sharing</option>
        </select>
      </div>

      {/* user group */}
      <div
        className={`
          relative h-[360px] overflow-hidden
          flex flex-wrap
        `}
        ref={usersContainer}
      >
        {loading && <Loading />}
        {
          customUserGroup
          ?.slice(
            currentUserGroupPage * cardPerPage,
            currentUserGroupPage * cardPerPage + cardPerPage
          )
          .map((data, index) => (
            <div key={index} className='w-[160px] h-[160px] 2sm:m-2.5 2xl:mx-4'>
              <UserCard key={index} index={index} data={data} canClick={!user} />
            </div>
          ))
        }
        {
          customUserGroup?.length === 0
          &&
          <div className='absolute top-0 left-0 w-full h-full'>
            <StyledNoContent />
          </div>
        }
      </div>
      
      {/* user group pages */}
      <div className='mx-auto mt-5 px-8 max-w-[320px] text-center flex justify-between'>
        {/* previous page */}
        <div
          className='text-sub-word cursor-pointer'
          onClick={handlePrevPage}
        >
          ＜
        </div>
        
        {/* page numbers */}
        <div className='overflow-x-auto'>
          {
            Array.from(Array(handleGetPages()).keys())
            .map(index => (
              <span
                key={index}
                className={`
                  px-3 cursor-pointer
                  ${index === currentUserGroupPage ? 'text-blue' : 'text-sub-word'}
                `}
                onClick={() => setCurrentUserGroupPage(index)}
              >
                {index + 1}
              </span>
            ))
          }
        </div>
        
        {/* next page */}
        <div
          className='text-sub-word cursor-pointer'
          onClick={handleNextPage}
        >
          ＞
        </div>
      </div>
    </div>
  )
}

export default Main