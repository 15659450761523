import Logo from "../../../images/logo_light.png";
import User from "../../../images/user.svg";
import styled, { css } from "styled-components";
import { useState } from 'react';
import { useSpring, animated } from "react-spring";
import { getLanguage } from "../../../v2/services/common";
import { RWD_MD, RWD_SM } from "../../../constants/rwd";
import { useNavigate } from "react-router-dom";
import { routes } from "../../Router";

const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  transition: all 0.3s;
  height: 6.25vw;
  width: 100%;
  font-weight: 500;

  ${RWD_MD(css`
    height: 8vh;
  `)};

  ${RWD_SM(css`
    height: 65px;
  `)};
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 81.88vw;

  ${RWD_MD(css`
    width: 83.33vw;
  `)};
`;

const LogoWrapper = styled.div``;

const LogoContainer = styled.div`
  width: 5.729vw;
  display: flex;

  ${RWD_MD(css`
    width: 11vw;
  `)};

  ${RWD_SM(css`
    width: 15.33vw;
  `)};
`;

const LoginButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;

  ${RWD_MD(css`
    display: none;
  `)};
`;

const Button = styled.button`
  border-radius: 60px;
  padding: 0.78125vw 2.9166vw;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  font-size: clamp(18px, 1.145vw, 22px);

  border: ${(props) =>
        props.type === "hollow" ? `1px solid ${props.theme.colors.White}` : ""};
  background: ${(props) =>
        props.type === "hollow"
            ? "transparent"
            : "linear-gradient(to bottom, #2F80ED 0%, #5620F0 100%)"};
  color: ${(props) => props.theme.colors.White};
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 2;
  text-decoration: none;

  ${RWD_MD(css`
    padding: 13px 40px;
  `)};

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 30px;
    z-index: -2;
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: ${(props) => (props.type === "hollow" ? "100%" : "0%")};
    height: ${(props) => (props.type === "hollow" ? "0" : "100%")};
    background: ${(props) =>
        props.type === "hollow"
            ? "white"
            : "linear-gradient(to right, #6a11cb 0%, #2575fc 100%)"};
    transition: all 0.3s;
    border-radius: 30px;
    z-index: -1;
  }

  &:hover {
    color: ${(props) =>
        props.type === "hollow"
            ? props.theme.colors.DarkBlue
            : props.theme.colors.White};
    border: ${(props) =>
        props.type === "hollow" ? "1px solid transparent" : ""};

    &:before {
      width: 100%;
      height: 100%;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  ${RWD_MD(css`
    padding: 13px 40px;
  `)};
`;

const NewsHeader = () => {
    const navigate = useNavigate();
    const [hamIsOpen, setHamIsOpen] = useState(false);

    const ToggleHamMenu = () => {
        setHamIsOpen(!hamIsOpen);
    };

    const HamburgerMenuProps = useSpring({
        left: hamIsOpen ? "0vw" : "-50vw",
        boxShadow: hamIsOpen
            ? "0px 25px 20px -20px rgba(0, 0, 0, 0.45)"
            : "0px 0px 0px 0px rgba(0, 0, 0, 0)",
    });

    return (
        <Header>
            <HeaderContainer>
                <LogoWrapper>
                    <LogoContainer>
                        <img src={Logo} alt="Logo" />
                    </LogoContainer>
                </LogoWrapper>
                <LoginButtonWrapper>
                    <ButtonWrapper>
                        <Button onClick={() => navigate(routes.login_v2)}>
                            {getLanguage()?.toLocaleLowerCase() === "en" ? "Login/Register" : "登入/註冊"}
                        </Button>
                    </ButtonWrapper>
                </LoginButtonWrapper>
            </HeaderContainer>
        </Header>
    );
};

export default NewsHeader;
