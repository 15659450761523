import styled, { css, ThemeProvider } from "styled-components";
import { useState, useEffect } from 'react';
import Theme from "../../constants/theme";

// components
import Loading from '../../v2/components/Loading/Loading';
import NewsHeader from "./components/NewsHeader";
import NewsFooter from "./components/NewsFooter";
import NewsContent from "./components/NewsContent";


// ==================== styled components ====================
const Wrapper = styled.div`
    box-sizing: border-box;
    background: linear-gradient(to right, #24243e, #302b63, #0f0c29);
    font-family: "Poppins", "Noto Sans TC", "sans-serif";
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    animation: mymove 5s alternate infinite;

    // iOS < 11.2
    padding-left: constant(safe-area-inset-left);
    padding-top: constant(safe-area-inset-top);
    padding-right: constant(safe-area-inset-right);

    // iOS >= 11.2
    padding-left: env(safe-area-inset-left);
    padding-top: env(safe-area-inset-top);
    padding-right: env(safe-area-inset-right);
`;


const NewsContentWrapper = styled.div`
    flex: 1;
`;


const NewsDetail = () => {
    return (
        <ThemeProvider theme={Theme}>
            <Wrapper>
                <NewsHeader />
                <NewsContentWrapper>
                    <NewsContent />
                </NewsContentWrapper>
                <NewsFooter />
            </Wrapper>
        </ThemeProvider>
    )
}

export default NewsDetail