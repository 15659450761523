import { createApi } from "@reduxjs/toolkit/query/react";
import defaultBaseQuery from "./query/defaultBaseQueryV2";
import { makeUrlencoded } from "./common";

export const questApi = createApi({
  reducerPath: "questApi",
  baseQuery: defaultBaseQuery,
  endpoints: (builder) => ({
    getAccountQuests: builder.query({
      query: () => ({
        url: "/quest/GetAccountQuests",
        method: "GET",
      }),
      providesTags: [{ type: "quset", id: "quset" }],
    }),
    claimQuestPoints: builder.mutation({
      query: ({ body }) => ({
        url: "/quest/ClaimQuestPoints",
        method: "POST",
        body: makeUrlencoded(body),
      }),
      invalidatesTags: [{ type: "quset", id: "quset" }],
    }),
  }),
});

export const { useGetAccountQuestsQuery, useClaimQuestPointsMutation } =
  questApi;
