// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";
import { FreeMode, Pagination, Autoplay } from "swiper";

import { BsFillShieldLockFill } from "react-icons/bs";
import { HiKey } from "react-icons/hi";
import { HiOutlineInboxStack } from "react-icons/hi2";

import { useEffect, useState } from "react";

import { css } from "styled-components";
import { RWD_LG, RWD_MD } from "../../../constants/rwd";

// AOS
import AOS from "aos";
import "aos/dist/aos.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import parse from "html-react-parser";

const Slide = styled.div`
  height: auto;
`;
const SlideImg = styled.img`
  width: 100%;
  max-height: 300px;
  object-fit: cover;
`;

const RobotCardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
  align-items: center;
  padding: 5vw 0;
  height: auto;
  width: 25vw;

  /* height: 90%; */

  /* ${RWD_LG(css`
    width: 42vw;
  `)}; */

  ${RWD_LG(css`
    width: 42vw;
  `)};

  ${RWD_MD(css`
    width: 90vw;
    padding: 10vw 0;
  `)};
`;

const RobotCardContainer = styled.div`
  width: 100%;
  border: 1px solid royalblue;
  padding: 1.5vw;
  padding-bottom: 5vh;
  position: relative;
  border-radius: 20px;
  color: white;
  box-sizing: border-box;
  cursor: pointer;
  max-height: 80%;
  min-height: 70%;
  height: 100%;

  ${RWD_LG(css`
    padding: 0 1.5vw;
  `)};
  ${RWD_MD(css`
    padding: 0 6vw;
  `)};
`;

const RobotCardTitle = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 2vh;
  font-size: clamp(15px, 1.1vw, 19px);
  padding-top: 3vw;

  ${RWD_LG(css`
    padding-top: clamp(60px, 8vw, 70px);
  `)};
`;

const RobotCardIcon = styled.div`
  border-radius: 50%;
  width: 4vw;
  height: 4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  font-size: clamp(30px, 2.5vw, 40px);
  background-image: linear-gradient(
    -225deg,
    #2cd8d5 0%,
    #c5c1ff 56%,
    #ffbac3 100%
  );

  background: linear-gradient(294.72deg, #ff4581 9.05%, #4388dd 79.28%);
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${RWD_LG(css`
    width: clamp(60px, 8vw, 70px);
    height: clamp(60px, 8vw, 70px);
    font-size: clamp(30px, 4vw, 40px);
  `)};
`;

const RobotCardContent = styled.div`
  color: #ccc;
  font-size: clamp(14px, 0.9vw, 20px);
  padding-bottom: 3.5vw;

  ${RWD_MD(css`
    padding-bottom: clamp(60px, 8vw, 70px);
  `)};
`;

export default ({ language='CH' }) => {
  useEffect(() => {
    AOS.init();
  }, []);

  const [langData, setLangData] = useState({
    EN: [
      {
        title: "Reliable, Tamper-Proo",
        subTitle: "Network",
        content:
          "Use decentralization, trusted nodes, premium data, and cryptographic proofs to connect highly accurate and available data/ APIs to any smart contract.",
        icon: <BsFillShieldLockFill data-aos="flip-left" />,
      },
      {
        title: "Seamless Connection",
        subTitle: "To Any API",
        content:
          "Use decentralization, trusted nodes, premium data, and cryptographic proofs to connect highly accurate and available data/ APIs to any smart contract.",
        icon: <HiKey data-aos="flip-left" />,
      },
      {
        title: "Proven, Ready-Made",
        subTitle: "Solution",
        content:
          "Use decentralization, trusted nodes, premium data, and cryptographic proofs to connect highly accurate and available data/ APIs to any smart contract.",
        icon: <HiOutlineInboxStack data-aos="flip-left" />,
      },
      {
        title: "Secure, Off-Chain",
        subTitle: "Computation",
        content:
          "Use decentralization, trusted nodes, premium data, and cryptographic proofs to connect highly accurate and available data/ APIs to any smart contract.",
        icon: <BsFillShieldLockFill data-aos="flip-left" />,
      },
      {
        title: "Reliable, Tamper-Proo",
        subTitle: "Network",
        content:
          "Use decentralization, trusted nodes, premium data, and cryptographic proofs to connect highly accurate and available data/ APIs to any smart contract.",
        icon: <HiKey data-aos="flip-left" />,
      },
      {
        title: "Seamless Connection",
        subTitle: "To Any API",
        content:
          "Use decentralization, trusted nodes, premium data, and cryptographic proofs to connect highly accurate and available data/ APIs to any smart contract.",
        icon: <HiOutlineInboxStack data-aos="flip-left" />,
      },
    ],
    CH: [
      {
        title: "豐富的交易演算法使用權",
        // subTitle: "戰場的標準配備",
        content:
          "由交易專家持續研究並透過大數據，AI不斷計算且推陳出輕的交易機器人，是投入市場不可獲缺的 幫手!",
        icon: <BsFillShieldLockFill data-aos="flip-left" />,
      },
      {
        title: "多樣的市場指標及消息通知",
        // subTitle: "越戰越勇，無所畏懼",
        content:
          "由交易專家經長時間研究開發的專屬指標，以及全時在各種媒介觀察並分析的第一手消息，讓您資 訊領先，運籌帷幄更有所本!",
        icon: <HiKey data-aos="flip-left" />,
      },
      {
        title: "智慧財產的分潤權",
        // subTitle: "瞄準目標，即刻執行",
        content: "經過考驗且得到認可的程序若售出，還可以得到豐厚的分潤!",
        icon: <HiOutlineInboxStack data-aos="flip-left" />,
      },
    ],
  });

  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={10}
      pagination={{
        clickable: true,
      }}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      breakpoints={{
        // when window width is >= 768px
        768: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        1280: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
      }}
      modules={[Pagination]}
    >
      {langData[language]?.map((data, index) => (
        <SwiperSlide key={index}>
          <RobotCardWrapper>
            <RobotCardContainer>
              <RobotCardIcon>{data.icon}</RobotCardIcon>
              <RobotCardTitle>
                <span>{data.title}</span>
                <span>{data.subTitle}</span>
              </RobotCardTitle>
              <RobotCardContent>
                <span>{data.content}</span>
              </RobotCardContent>
            </RobotCardContainer>
          </RobotCardWrapper>
        </SwiperSlide>
      ))}
      {/* <SwiperSlide>
        <RobotCardContainer>
          <RobotCardIcon>
            <HiKey />
          </RobotCardIcon>
          <RobotCardTitle>
            <span>Reliable, Tamper-Proof </span>
            <span>Network</span>
          </RobotCardTitle>
          <RobotCardContent>
            <span>
              Use decentralization, trusted nodes, premium data, and
              cryptographic proofs to connect highly accurate and available
              data/ APIs to any smart contract.
            </span>
          </RobotCardContent>
        </RobotCardContainer>
      </SwiperSlide>
      <SwiperSlide>
        <RobotCardContainer>
          <RobotCardIcon>
            <HiOutlineInboxStack />
          </RobotCardIcon>
          <RobotCardTitle>
            <span>Reliable, Tamper-Proof </span>
            <span>Network</span>
          </RobotCardTitle>
          <RobotCardContent>
            <span>
              Use decentralization, trusted nodes, premium data, and
              cryptographic proofs to connect highly accurate and available
              data/ APIs to any smart contract.
            </span>
          </RobotCardContent>
        </RobotCardContainer>
      </SwiperSlide>
      <SwiperSlide>
        <RobotCardContainer>
          <RobotCardIcon>
            <BsFillShieldLockFill />
          </RobotCardIcon>
          <RobotCardTitle>
            <span>Reliable, Tamper-Proof </span>
            <span>Network</span>
          </RobotCardTitle>
          <RobotCardContent>
            <span>
              Use decentralization, trusted nodes, premium data, and
              cryptographic proofs to connect highly accurate and available
              data/ APIs to any smart contract.
            </span>
          </RobotCardContent>
        </RobotCardContainer>
      </SwiperSlide>
      <SwiperSlide>
        <RobotCardContainer>
          <RobotCardIcon>
            <HiKey />
          </RobotCardIcon>
          <RobotCardTitle>
            <span>Reliable, Tamper-Proof </span>
            <span>Network</span>
          </RobotCardTitle>
          <RobotCardContent>
            <span>
              Use decentralization, trusted nodes, premium data, and
              cryptographic proofs to connect highly accurate and available
              data/ APIs to any smart contract.
            </span>
          </RobotCardContent>
        </RobotCardContainer>
      </SwiperSlide>
      <SwiperSlide>
        <RobotCardContainer>
          <RobotCardIcon>
            <HiOutlineInboxStack />
          </RobotCardIcon>
          <RobotCardTitle>
            <span>Reliable, Tamper-Proof </span>
            <span>Network</span>
          </RobotCardTitle>
          <RobotCardContent>
            <span>
              Use decentralization, trusted nodes, premium data, and
              cryptographic proofs to connect highly accurate and available
              data/ APIs to any smart contract.
            </span>
          </RobotCardContent>
        </RobotCardContainer>
      </SwiperSlide> */}
    </Swiper>
  );
};
