import styled, { css } from "styled-components";
import { useState, useEffect, useRef, Fragment } from "react";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";

import { useUpdateReadingTermsMutation } from "../../services/accountApi";
import { setTermsOfUser } from "../../services/common";

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  /* color: #151944; */
`;

const Container = styled.div`
  width: clamp(650px, 60vw, 1000px);
  height: 90vh;
  color: #fcfcfc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  ${RWD_MD(css`
    width: 75vw;
  `)};

  ${RWD_SM(css`
    width: 90vw;
    height: 95vh;
  `)};
`;

const Title = styled.div`
  text-align: center;
  font-size: min(max(1.25vw, 22px), 24px);
  color: #fcfcfc;
  padding-bottom: 20px;

  ${RWD_MD(css`
    font-size: min(max(1.0416vw, 18px), 20px);
  `)};
`;

const TermsWrapper = styled.div`
  width: 100%;
  height: 60vh;
  overflow: auto;

  border-radius: 8px;
  padding: 1.0416vw 1.5625vw;

  color: #fcfcfc;

  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );

  &::-webkit-scrollbar-track {
    border-radius: 0.125rem;
    background-color: #3a3d5a;
  }
  &::-webkit-scrollbar {
    width: 0.25rem;
    border-radius: 0.125rem;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0.125rem;
    background-color: rgba(255, 255, 255, 0.3);
  }

  ${RWD_LG(css`
    height: 60vh;
  `)};

  ${RWD_SM(css`
    width: 100%;
    height: 55vh;
    padding: 10px 15px;
  `)};
`;

const TermsUl = styled.div`
  list-style-type: decimal;
  list-style-position: inside;
`;

const TermsLi = styled.li`
  font-size: min(max(0.9375vw, 16px), 18px);
  padding: 8px 0;
  line-height: 1.5;
`;

const TermsDetailUl = styled.ul`
  list-style-type: circle;
  list-style-position: inside;
`;
const TermsDetailLi = styled.li`
  font-size: min(max(0.9375vw, 16px), 18px);
  padding: 8px 0 8px 8px;
  line-height: 1.5;
`;

const TermsDetailWrapper = styled.ul`
  width: 90%;
  margin: 0 auto;
  text-align: justify;
  padding-top: 15px;
  list-style-type: lower-alpha;
  list-style-position: inside;
  color: #d3d3d3;
`;

const TermsDetailItem = styled.div`
  padding-bottom: 10px;
`;

const TermsDetailTitle = styled.li`
  padding-bottom: 10px;
`;

const CheckboxContainer = styled.div`
  font-size: min(max(0.833vw, 14px), 16px);
  padding-left: 10px;
  color: #fcfcfc;
  width: auto;
`;

const CheckboxWrapper = styled.label`
  color: #151944;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;
  padding-left: 1.5625vw;
  /* ${RWD_MD(css`
    justify-content: space-between;
  `)}; */
`;

const CheckBox = styled.input.attrs({ type: "checkbox" })`
  accent-color: #ccc;
  width: 20px;
  height: 20px;
`;

const ActionWrapper = styled.div`
  width: 100%;
  align-items: center;
`;

const ActionContainer = styled.div`
  padding: 30px 0 10px 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  button:last-child {
    margin-left: auto;
  }
`;

const Button = styled.button`
  min-width: 120px;
  border-radius: 60px;

  color: ${(props) => (props.type === "confirm" ? "#fcfcfc" : "#151944")};
  background: ${(props) =>
    props.type === "confirm"
      ? props.disabled
        ? "#3A3D5A"
        : "linear-gradient(to bottom, #2f80ed 0%, #5620f0 100%)"
      : ""};
  border-color: ${(props) => (props.disabled ? "#d9d9d9" : "")};
  display: flex;
  justify-content: center;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};

  button:last-child {
    align-self: flex-end;
  }
`;

const ButtonText = styled.span`
  padding: 8px 16px;
  text-align: center;
  font-size: min(max(1.0416vw, 14px), 20px);
  color: #fcfcfc;
  ${RWD_SM(css`
    min-width: 25vw;
    padding: 8px 10px;
  `)};
`;

const TermsOfUse = () => {
  const [isBottom, setIsBottom] = useState(false);
  const dom = useRef(null);
  const [termsIsCheck, setTermsIsCheck] = useState(false);

  const handleScroll = () => {
    const clientHeight = dom.current?.clientHeight;
    const scrollHeight = dom.current?.scrollHeight;
    const scrollTop = dom.current?.scrollTop;
    setIsBottom(clientHeight + scrollTop + 1 >= scrollHeight);
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScroll, true);
    return () => {
      document.removeEventListener("scroll", handleScroll), true;
    };
  }, []);

  useEffect(() => {
    if (isBottom) {
      setTermsIsCheck(true);
    } else return;
  }, [isBottom]);

  const userTerms = [
    {
      id: 1,
      title: [
        {
          ch: "釋義",
          en: "Definitions",
        },
      ],
      content: [
        {
          ch: [
            "除文義另有指定外，在本⽤⼾條款及細則內，下列詞語具有下列涵義：",
            "「AVA」及「我們」是指ACHIEVER ROBOTRADE TECHNOLOGY LIMITED；",
            "「內容」是指我們網站中展示或使用的所有文字、圖形、標誌、圖示、照片、圖像、動態視覺圖像、或此類圖像和聲音的組合、音訊、電腦程式以及其他與我們網站有關的資料；",
            "「不可抗力事件」指不受本協議任何一方合理控制的任何事件或情況（包括但不限於任何罷工、勞資糾紛或其他工業問題、處所或設備遭受毀壞、政府規例、暴亂、戰爭、火災、地震或其他天然災害。）；",
            "「知識產權」包括但不限於任何發明、技術創新、發現、設計、配方、專有技術、流程、商業方法、專利、商標、服務標誌、版權、電腦軟體、創意、創作、著作、講座、插圖、照片、影片、科學和數學模型、對所有此類財產的改進，以及定義、描述或說明所有此類財產的所有記錄材料；",
            "「香港」指中華人民共和國香港特別行政區；",
            "「平台」及「網站」是指我們的網站 (https://www.avairobot.com/) 及其連接網頁。",
          ],
          en: [
            "In these Terms and Conditions, unless the context requires otherwise, the following words and expressions have the following meanings:",
            "“AVA”, “us” and “our” are used to refer to ACHIEVER ROBOTRADE TECHNOLOGY LIMITED;",
            "“Content” means all text, graphics, logos, icons, photographs, images, moving visual representational images or a combination of sounds and such images, audio, computer programs, and other material featured, displayed or used in or in relation to our site;",
            "“Force Majeure Event” means any circumstances not foreseeable at the date of this Agreement and not within the reasonable control of the party in question including, without limitation, industrial action, destruction of premises or equipment, governmental action, civil disorder or war, fire, earthquake or other natural disaster;",
            "“Intellectual Property Rights” shall include, without limitation, any inventions, technological innovations, discoveries, designs, formulas, know-how, processes, business methods, patents, trademarks, service marks, copyrights, computer software, ideas, creations, writings, lectures, illustrations, photographs, motion pictures, scientific and mathematical models, improvements to all such property, and all recorded material defining, describing, or illustrating all such property.",
            "“Hong Kong” means Hong Kong Special Administrative Region of the People’s Republic of China;",
            "“Platform”, “Website” and “Site” means our website at https://www.avairobot.com/, and any associated sites linked to it.",
          ],
        },
      ],
    },
    {
      id: 2,
      title: [
        {
          ch: "協議與接受",
          en: "Agreement and Acceptance",
        },
      ],
      content: [
        {
          ch: [
            "本⽤⼾條款及細則構成您與我們之間具有法律約束力的協議，您使⽤本平台提供之任何產品、服務、內容、工具及資料將受本條款約束。",
            "凡您註冊AVA的賬⼾、取覽本平台或使⽤我們的服務時，即表示您已閱讀下列資料，並接受下述的條款及細則，亦同意接受此等條款及細則約束。倘若您不同意下列條款及細則，請勿瀏覽本網站或使用我們的服務。",
          ],
          en: [
            "These Terms and Conditions constitute a legally binding agreement (the “Agreement”)  between you and us which govern your use of our products, services, Content, tools and information.",
            "By registering an account with AVA, accessing our platform or using our services, you acknowledge that you have read the following information and accept the terms and conditions set out below, and agree to be bound by such terms and conditions. If you do not agree to such terms and conditions below, do not access this website, or use our services.",
          ],
        },
      ],
    },
    {
      id: 3,
      title: [
        {
          ch: "⾵險提⽰",
          en: "Risk Warning",
        },
      ],
      content: [
        {
          ch: [
            "數位資產交易存在重⼤⾵險，數位資產價值可能會因應市況而劇烈波動，您可能須承受重⼤損失。您應根據您的知識、財務狀況和投資⽬標，仔細考慮是否適合進⾏此類交易。",
            "進行交易或投資決定均屬於您本人，您確認您已瞭解投資數位資產的⾵險，並將對您的投資決定及因此產⽣的任何損失或責任承擔全部責任。",
            "您必須細閱本平台之使用守則或手册，包括有關平台內容、顯示、指示及運作，本平台會盡力解答您的問題，但您亦有責任細閱本平台之使用守則或手册。",
          ],
          en: [
            "Digital asset trading involves significant risks. The value of digital assets can fluctuate greatly, potentially leading to substantial losses. You should carefully consider whether such trading is suitable for you based on your knowledge, financial situation and investment objectives.",
            "Trading or investment decision is yours. You acknowledge that you understand risks in relation to digital asset trading and will assume full responsibility for your decisions and any resulting losses or liabilities.",
            "You must read the user guideline or manual of this Platform carefully, including the content, display, instructions and operation of the Platform. The Platform will endeavour to answer any questions you may have, but it is also your responsibility to read the Platform's user guideline or manual carefully.",
          ],
        },
      ],
    },
    {
      id: 4,
      title: [
        {
          ch: "服務",
          en: "Services",
        },
      ],
      content: [
        {
          ch: [
            "我們提供⼀個管理數位資產的平台，包括根據您的指示及以AI 應用程式將您的資⾦分散投資於各個交易所以優化利潤。",
            "我們服務的具體細節，包括任何限制和要求，均詳細列於產品條款中。",
          ],
          en: [
            "We provide a platform for managing digital assets, including diversifying your funds across various exchanges to optimize profits in accordance with your instructions and applications of artificial intelligence.",
            "The specific details of our services, including any limitations and requirements, are detailed in the product terms.",
          ],
        },
      ],
    },
    {
      id: 5,
      title: [
        {
          ch: "費⽤",
          en: "Fees",
        },
      ],
      content: [
        {
          ch: [
            "您同意⽀付使⽤我們服務所產⽣的所有相關費⽤，詳情請⾒我們的相關產品條款中的說明。",
          ],
          en: [
            "You agree to pay all related fees incurred in using our services, as detailed in our relevant product terms.",
          ],
        },
      ],
    },
    {
      id: 6,
      title: [
        {
          ch: "賬⼾管理",
          en: "Account Management",
        },
      ],
      content: [
        {
          ch: [
            "您必須開立並維持您的AVA賬⼾才能使用我們的服務。就閣下賬⼾的開立、運作、關閉及您擁有賬⼾數量，我們保留絕對的權利。",
            "您需對您賬⼾下的所有活動負責，並確保您的賬⼾信息的安全性與機密性。",
          ],
          en: [
            "You must create and maintain your account to access our services. We have the absolute discretion as to the opening, operation, closure of the account and the number of accounts you may hold.",
            "You are responsible for all activities under your account and must ensure the security and confidentiality of your account information.",
          ],
        },
      ],
    },
    {
      id: 7,
      title: [
        {
          ch: "交易",
          en: "Transactions",
        },
      ],
      content: [
        {
          ch: [
            "您可以使用我們提供的服務進⾏交易。我們不保證任何交易能在特定時間內完成。",
            "您應監控您的賬⼾，以識別任何未授權的活動。我們對因未授權交易造成的損失不承擔任何責任。",
          ],
          en: [
            "You may conduct transactions as per the services we provide. We do not guarantee the completion of any transaction within a specific time frame.",
            "You should monitor your account to identify any unauthorized activities. We are not responsible for losses due to unauthorized transactions.",
          ],
        },
      ],
    },
    {
      id: 8,
      title: [
        {
          ch: "安全",
          en: "Security",
        },
      ],
      content: [
        {
          ch: [
            "您有責任保護您的賬⼾和個⼈信息不會未經授權地被查閲、處理、刪除、喪失或使用。",
            "您應經常維護您的賬⼾和登錄憑證的安全措施。",
            "倘若您認為有人可能未經您准許而試圖使用或已經使用您的賬⼾和登錄憑證，或已出現任何其他未經授權的使用情況或保安入侵、損失或盜用的情況，您應立即通知我們。",
          ],
          en: [
            "You are responsible for protecting your account and personal information from unauthorized access, processing, erasure, loss or use.",
            "You should always maintain appropriate security measures for your account and login credentials.",
            "If you believe someone may attempt to use or has used your account and login credentials without your permission, or that any other unauthorised use or security breach, loss or theft has occurred, you should immediately notify us.",
          ],
        },
      ],
    },
    {
      id: 9,
      title: [
        {
          ch: "禁止的行為",
          en: "Prohibited Activity",
        },
      ],
      content: [
        {
          ch: [
            "閣下禁止從事任何我們在絶對酌情決定權下被視為不恰當及╱或將被視為非法的行為或被任何適用於本網站的法律所禁止的行為，包括但不限於：-",
          ],
          en: [
            "You are prohibited from doing any act that we in its absolute discretion may deem to be inappropriate and/or would be deemed to be an unlawful act or is prohibited by any laws applicable to this website including but not limited to:",
          ],
        },
      ],
      detail: [
        {
          ch: [
            {
              detailTitle:
                "利用我們的網站及/或服務進行任何詐欺或其他違法行為；",
            },
            {
              detailTitle:
                "擅自入侵、訪問、使用或試圖入侵、訪問或使用我們的伺服器的任何其他部份以及／或任何數據區；",
            },
            {
              detailTitle:
                "未得我們書面批准﹙除非我們在本網站中另有訂明，或我們另外給予特許﹚而張貼、發佈、傳送、複製、分發或以任何方式利用從本網站獲得的任何資料作商業用途；",
            },
            {
              detailTitle:
                "發布任何被視為違法的、具有恐嚇成份的、誹謗的、欺詐的、淫褻的或其他令人反感的內容，或其他可能侵犯第三方權益 (如私隱權和知識財產權) 的內容；",
            },
            {
              detailTitle:
                "使用任何機器人軟件、蜘蛛軟件、網站搜尋或檢索應用程式或任何其他方法或工具來複製、檢索、存檔我們網站的任何部分。",
            },
          ],
          en: [
            {
              detailTitle:
                "commit fraud or other illegal acts through our website or services;",
            },
            {
              detailTitle:
                "trespass, break into, access, use or attempt to trespass, break into, access or use any other parts of our servers, and/or any data areas for which you have not been authorized by us;",
            },
            {
              detailTitle:
                "post, publish, transmit, reproduce, distribute or in any way exploit any Information obtained through this website for commercial purposes without our prior written permission (unless otherwise specified by us in this website or pursuant to separate licence granted by us);",
            },
            {
              detailTitle:
                "post any content that is being considered as unlawful, threatening, defamatory, fraudulent, obscene or otherwise objectionable, or because it may infringe or violate any other third party’s rights such as privacy and Intellectual Property Rights;",
            },
            {
              detailTitle:
                "use any robot, spider, site search or retrieval application, or any other method or device to copy, retrieve, archive or index any portion of our site.",
            },
          ],
        },
      ],
    },
    {
      id: 10,
      title: [
        {
          ch: "私隱政策",
          en: "Privacy Policy",
        },
      ],
      content: [
        {
          ch: [
            "我們承諾按照我們的私隱政策保護您的個⼈資料。我們亦可能透過本平台收集閣下的個人資料或資訊，包括閣下個人電腦的訊息處理機位址、閣下所在國家及若干個人資料。",
            "請瀏覽我們的私隱政策，以了解我們如何處理及使用該等資料的詳情。",
          ],
          en: [
            "We commit to protecting your personal data in accordance with our Privacy Policy. We may also through this website collect your personal data or information, including the IP address of your personal computer, the country you are from and certain personal details.",
            "Please refer to our Privacy Policy for further information on our handling and use of such information.",
          ],
        },
      ],
    },
    {
      id: 11,
      title: [
        {
          ch: "條款之修改",
          en: "Modification of Terms",
        },
      ],
      content: [
        {
          ch: [
            "我們保留隨時修改本條款的權利。所有修改將通過電⼦郵件或我們的網站通知閣下。凡繼續使⽤我們的服務即表⽰您接受這些新條款。",
          ],
          en: [
            "We reserve the right to modify these terms at any time. Changes will be notified via email or on our website. Continued use of our services indicates your acceptance of the new terms.",
          ],
        },
      ],
    },
    {
      id: 12,
      title: [
        {
          ch: "聲明與保證",
          en: "Representations and Warranties",
        },
      ],
      content: [
        {
          ch: ["凡您使⽤我們的服務，即表示閣下保證及聲明閣下：-"],
          en: [
            "By using our accessing or using services, you represent and warrant to us, at all times, the following: -",
          ],
        },
      ],
      detail: [
        {
          ch: [
            {
              detailTitle:
                "是具備充分權力、權限和能力的個人、公司、法律實體或其他組織，可以使用我們的服務及簽訂本條款並遵守您在本條款中的義務；",
            },
            {
              detailTitle:
                "如果您是法律實體，則您在所屬司法管轄區正式註冊、正式成立並有效存續，並且具有處理事務的全部權利。如果您是個人，則您不少於18歲；",
            },
            {
              detailTitle:
                "如果您是法律實體的員工或代理人並代表其簽署本條款，則您必須獲得作為其代表的正式授權並使實體因簽訂本條款而受其約束；",
            },
            {
              detailTitle:
                "您清楚了解本協議和數位資產交易的性質和風險，並且有能力承擔與本協議和任何數位資產交易相關的所有風險；",
            },
            {
              detailTitle: "您提供的所有資料和陳述均為屬實、完整且不具誤導性；",
            },
            {
              detailTitle:
                "就您使用我們的服務，您有責任確定您須支付的相關稅項（如有），並且您有責任向相關稅務機關繳納、報告並支付正確稅款。",
            },
          ],
          en: [
            {
              detailTitle:
                "you have full power, authority, and capacity to use our services and enter into and deliver, and perform your obligations under these Terms and Conditions;",
            },
            {
              detailTitle:
                "if you are a legal entity, you are duly incorporated, duly organised, and validly existing under the laws of your jurisdiction and have full power to conduct your business. If you are an individual, you are not less than 18 years old;",
            },
            {
              detailTitle:
                "if you act as an employee or agent of a legal entity, and enter into these Terms and Conditions on their behalf, you must be duly authorised to act on behalf of and bind such legal entity for the purposes of entering into these Terms and Conditions;",
            },
            {
              detailTitle:
                "you understand the nature and risks of the subject matter of the Agreement and the digital asset transactions, and are capable of assuming, and do assume, all risks associated with the Agreement and any digital asset transactions;",
            },
            {
              detailTitle:
                "all the information given, and representations made, by you are correct, complete and not misleading;",
            },
            {
              detailTitle:
                "it is your responsibility to determine what, if any, taxes apply to the payments you make or receive, and it is your responsibility to collect, report, and remit the correct tax to the appropriate tax authority.",
            },
          ],
        },
      ],
    },
    {
      id: 13,
      title: [
        {
          ch: "賬⼾終⽌",
          en: "Account Termination",
        },
      ],
      content: [
        {
          ch: [
            "您可以按照規定的程序關閉您的賬⼾。在某些情況下，我們可能會限制賬⼾的關閉。",
          ],
          en: [
            "You may close your account following the specified procedure. Account closure may be restricted under certain circumstances.",
          ],
        },
      ],
    },
    {
      id: 14,
      title: [
        {
          ch: "免責聲明及責任限制",
          en: "Disclaimer and Limitation of Liability",
        },
      ],
      content: [
        {
          ch: [
            "本網站所含內容僅供一般參考。儘管我們致力保持信息更新的及時性和準確性，但我們並不就本網站中任何內容的準確性、完整性、及時性、適用性或有效性作出任何聲明，並且不會對相關內容中的任何錯誤、遺漏、延誤或因顯示或使用內容而產生的任何損失、傷害或損害承擔任何責任。",
            "本網站所包含的內容不是，亦不應理解為買賣任何特定資產或使用任何特定投資策略的要約或建議，或買賣任何特定資產或使用任何特定投資策略的要約或建議邀請。 除非另有說明，AVA均不具有在任何司法管轄區進行受監管活動的許可。",
            "本網站所載內容可能包含AVA以外的其他方控制的鏈接網頁。 我們向您提供超鏈接僅為方便起見，包含任何鏈接並不表示我們對該網站的認可、批准或推薦，或與其運營商的任何關聯。",
            "我們將盡一切努力保持網站正常運行。 但是我們概不對因我們無法控制的技術問題導致網站暫時無法使用負責，亦不會承擔任何責任。",
            "我們對閣下的責任總額上限為閣下在過去12個⽉內⽀付給我們的費⽤。就間接損失包括利潤或機會的損失，我們槪不承擔責任。",
            "AVA向外的所有轉帳服務，僅在每月的5日以及20日執行非轉帳日期間受理的所有轉帳服務。",
          ],
          en: [
            "The Content contained herein is for general information purposes. While we endeavor to keep the information up to date and correct, we make no representations as to the accuracy, completeness, timeliness, suitability, or validity of any information herein and will not be liable for any errors, omissions, or delays in this information or any losses, injuries, or damages arising from its display or use.",
            "The Content contained herein is not, and should not be read as, an offer or recommendation to buy or sell or a solicitation of an offer or recommendation to buy or sell any particular asset or to use any particular investment strategy. Unless otherwise specified, we herein do not hold ourselves as being licensed to carry on regulated activities in any jurisdiction.",
            "The Content herein may contain hyperlinks to websites controlled by parties other than AVA. We are providing these hyperlinks to you only as a convenience, and the inclusion of any link does not imply endorsement, approval or recommendation by us of the site or any association with its operators.",
            "We will make every effort to keep the website up and running smoothly. However, we take no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.",
            "Our liability is limited to the fees you have paid us in the past 12 months. We are not liable for any indirect losses, including but not limited to lost profits or opportunities.",
            "All outward transfer services from AVA are only executed on the 5th and the 20th of each month, accepting all transfer services during non-transfer days.",
          ],
        },
      ],
    },
    {
      id: 15,
      title: [
        {
          ch: "價值單位",
          en: "Unit of Value",
        },
      ],
      content: [
        {
          ch: [
            "除⾮在平台上另有明確標⽰，所有提及或計算的價值單位均以「AVAPoint」作為衡量基準。",
            "「AVAPoint」是我們專⾨為平台交易和服務所設計的計價單位，旨在為⽤⼾提供⼀致和標準化的價值衡量⽅式。",
          ],
          en: [
            "Unless otherwise explicitly indicated on the platform, all mentioned or calculated value units are measured in AVAPoint.",
            "AVAPoint is a unit of valuation specifically designed for platform transactions and services, intended to provide users with a consistent and standardized method of value measurement.",
          ],
        },
      ],
    },
    {
      id: 16,
      title: [
        {
          ch: "知識產權",
          en: "Intellectual Property Rights",
        },
      ],
      content: [
        {
          ch: [
            "載於本網站的資料的所有版權、商標、標誌、專利、知識及其他產權均屬我們所有。任何種類之權利不可為他人特許專有或轉讓予他人，亦不得以其他方式傳送給取覽該等資料的人士。未得我們發出的書面同意，在任何情況下一律不得將本網站或其任何部分所載的資料抄錄、複製或另作派發。",
          ],
          en: [
            "All copyright, trademark, logo, patent, intellectual and other property rights in the information contained herein is owned by us. No rights of any kind are licensed or assigned or shall otherwise pass to persons accessing such information. Under no circumstances should information contained herein or any part of it be copied, reproduced or redistributed without our express written consent.",
          ],
        },
      ],
    },
    {
      id: 17,
      title: [
        {
          ch: "彌償",
          en: "Indemnity",
        },
      ],
      content: [
        {
          ch: [
            "您同意就您違反本使用條款導致的任何及所有申索、損失、法律責任和開支 （包括但不限於律師費），向我們作出一切賠償。",
          ],
          en: [
            "You agree to fully indemnify and keep us fully indemnified from and against all claims, losses, liabilities and expenses (including but not limited to attorney's fees) arising from your violation of these Terms and Conditions.",
          ],
        },
      ],
    },
    {
      id: 18,
      title: [
        {
          ch: "不可抗力",
          en: "Force Majeure",
        },
      ],
      content: [
        {
          ch: [
            "因不可抗力事件導致履行延遲或不能履行時，我們對本條款規定的任何履行延遲或未能履行概不承擔任何責任。",
          ],
          en: [
            "We will not be liable for any delay or failure to perform as required by these Terms and Conditions to the extent the delay or failure is caused by a Force Majeure Event.",
          ],
        },
      ],
    },
    {
      id: 19,
      title: [
        {
          ch: "可分割性",
          en: "Severability",
        },
      ],
      content: [
        {
          ch: [
            "如果本協議中任何一項或幾項約定在某管轄的現行法律下爲不合法的、無效的或不可強制執行的，則該約定在其他管轄的現行法律下的合法性、有效性和可執行性、以及其他約定的合法性、有效性和可執行性，不受其任何影響或損害。",
          ],
          en: [
            "If, at any time, any one or more of the provisions in this Agreement is or are deemed to be invalid, illegal, unenforceable or incapable of performance in any respect, the validity, legality, enforceability or performance of the remaining provisions of this Agreement shall not be affected.",
          ],
        },
      ],
    },
    {
      id: 20,
      title: [
        {
          ch: "法律管限",
          en: "Governing Law",
        },
      ],
      content: [
        {
          ch: [
            "本協議適用於香港，並按該法律解釋。就執行本協議或因本協議引起或與之相關的任何法律程序，雙方不可撤銷且無條件地同意接受香港特別行政區法院的非專屬管轄權。",
          ],
          en: [
            "This Agreement shall be governed and construed in accordance with the laws of Hong Kong and the Parties irrevocably submit to the non-exclusive jurisdiction of the Hong Kong courts.",
          ],
        },
      ],
    },
    {
      id: 21,
      title: [
        {
          ch: "語言",
          en: "Language",
        },
      ],
      content: [
        {
          ch: ["如本網站的中英文內容有任何歧異，概以英文版本為準。"],
          en: [
            "In case of discrepancies between the English and Chinese language content of this Site, the English version shall prevail.",
          ],
        },
      ],
    },
  ];

  const [_updateReadingTerms, { data, isSuccess, isError, error, isLoading }] =
    useUpdateReadingTermsMutation();

  const handleReadTerms = () => {
    _updateReadingTerms();
  };

  useEffect(() => {
    if (isSuccess) {
      console.log(data);
      setTermsOfUser("confirmed");
    }

    if (isError) {
      console.log(error.data?.Message);
    }
  }, [data, isError]);

  return (
    <Wrapper>
      <Container>
        <Title>Terms and Conditions</Title>
        <TermsWrapper ref={dom}>
          <TermsUl>
            {userTerms.map((data) => (
              <Fragment key={data.id}>
                <TermsLi>{data?.title[0]?.en}</TermsLi>
                <TermsDetailUl>
                  {data?.content[0]?.en.map((item, index) => (
                    <TermsDetailLi key={index}>{item}</TermsDetailLi>
                  ))}
                </TermsDetailUl>
                {data?.detail && (
                  <TermsDetailWrapper>
                    {data?.detail[0]?.en?.map((item, index) => (
                      <Fragment key={index}>
                        <TermsDetailTitle>{item.detailTitle}</TermsDetailTitle>
                        <TermsDetailItem>{item.detailContent}</TermsDetailItem>
                      </Fragment>
                    ))}
                  </TermsDetailWrapper>
                )}
              </Fragment>
            ))}
          </TermsUl>
        </TermsWrapper>

        <ActionWrapper>
          <CheckboxWrapper>
            <CheckBox type="checkbox" checked={termsIsCheck} />
            <CheckboxContainer>
              I have read and agree to the above terms and conditions
            </CheckboxContainer>
          </CheckboxWrapper>
          <ActionContainer>
            <ButtonWrapper>
              <Button
                type={"confirm"}
                disabled={!termsIsCheck}
                variant="contained"
                onClick={handleReadTerms}
              >
                <ButtonText>
                  {termsIsCheck ? "Confirm" : "Scroll down to agree"}
                </ButtonText>
              </Button>
            </ButtonWrapper>
          </ActionContainer>
        </ActionWrapper>
      </Container>
    </Wrapper>
  );
};

export default TermsOfUse;
