import styled, { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";

import { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { selectMining } from "../../../slices/miningSlice";
import { selectSystem } from "../../../slices/systemSlice";

import { TaiwanTimeFormat } from "../../../utils";
import { getLanguage } from "../../../services/common";

import Icons from "../../../global/icons";
import Lang from "../../../global/Lang";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  /* height: 100%;
  min-height: 45vh; */
`;

const Title = styled.div`
  font-size: min(max(1.25vw, 22px), 24px);
  color: #fcfcfc;
  padding-bottom: 20px;

  ${RWD_MD(css`
    font-size: min(max(1.0416vw, 18px), 20px);
  `)};
`;

const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;
  padding-bottom: 20px;
  color: #fcfcfc;

  ${RWD_SM(css`
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0px;
  `)};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${RWD_SM(css`
    padding-bottom: 10px;
  `)};
`;

const InputEl = styled.input`
  font-size: min(max(1.0416vw, 18px), 20px);
  text-align: center;
  /* padding: 1.0416vw 1.5625vw; */
  padding: 10px 20px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 8px;
  width: 100%;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  color: #fcfcfc;

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 16px), 18px);
  `)};
`;

const Label = styled.label`
  padding: 10px 0;
  font-size: min(max(1.0416vw, 18px), 20px);

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 16px), 18px);
  `)};
`;

const PackageWrapper = styled.div`
  color: #fcfcfc;
  padding: 20px 0;
  align-self: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PackageContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
  width: 75%;

  ${RWD_LG(css`
    width: 90%;
  `)};

  ${RWD_MD(css`
    width: 100%;
  `)};

  ${RWD_SM(css`
    flex-direction: column;
  `)};
`;

const Package = styled.div`
  /* width: 45%; */
  /* min-height: 350px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* justify-content: space-between; */
  padding: 20px 10px;
  max-width: 400px;
  width: 45%;

  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.15);

  :last-child {
    border: 1px solid #0aecd1;
  }

  ${RWD_SM(css`
    width: 100%;
  `)};
`;

const PackageNameWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
`;
const PackageName = styled.div`
  padding: 10px 0;
  text-align: center;
  font-size: min(max(1.0416vw, 18px), 20px);
  word-wrap: break-word;
`;

const PackageDayWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
`;
const PackageDay = styled.div`
  font-size: min(max(1.25vw, 22px), 24px);
  font-weight: bold;
  padding-right: 5px;
`;

const PackageDataWrapper = styled.div`
  width: 50%;
  padding: 20px 0;
`;

const PacakageDataContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const PacakageImgDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const PackageImgWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px 0;
  height: 100%;
`;

const PacakageImg = styled.div`
  width: min(max(10vw, 150px), 200px);
  height: min(max(10vw, 150px), 200px);
  border-radius: 50% 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PacakageImgCover = styled.div`
  width: min(max(8vw, 100px), 150px);
  height: min(max(8vw, 100px), 150px);
  background-color: #151944;
  border-radius: 50% 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const PackageImgRateText = styled.div``;

const PacakageImgRate = styled.div`
  font-size: min(max(1.25vw, 22px), 24px);
  font-weight: bold;

  span {
    font-size: min(max(0.833vw, 16px), 18px);
    font-weight: lighter;
  }
`;

const PackageTarget = styled.div``;
const PackagePercentage = styled.div``;

const PacakageTotalWrapper = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  display: flex;
  width: auto;
  min-width: 80%;
  align-items: center;
  justify-content: center;
  margin-top: auto;
`;

const PackageTotalContainer = styled.div`
  display: flex;
  display: flex;
  align-items: baseline;
  justify-content: center;
  padding-top: 20px;
`;

const PackageUnit = styled.div`
  font-size: min(max(0.833vw, 16px), 18px);
`;

const PacakageTotalName = styled.div`
  font-size: min(max(0.833vw, 16px), 18px);
`;

const PacakageTotal = styled.div`
  font-size: min(max(1.25vw, 22px), 24px);
  font-weight: bold;
  padding-left: 10px;
`;

const ChangeIconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  width: 30px;
  height: 30px;
  align-self: center;
  justify-content: center;

  ${RWD_SM(css`
    width: 60px;
    height: 60px;
  `)};

  svg {
    width: 30px;
    height: 30px;

    ${RWD_SM(css`
      transform: rotate(90deg);
    `)};
  }

  & path {
    fill: rgba(255, 255, 255, 0.15);
  }
`;

const CheckboxContainer = styled.div`
  font-size: min(max(0.833vw, 14px), 16px);
  padding-left: 10px;
  color: #fcfcfc;
  width: 100%;
`;

const CheckboxWrapper = styled.label`
  color: #151944;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 25px 0;
  ${RWD_MD(css`
    justify-content: space-between;
  `)};
`;

const CheckBox = styled.input.attrs({ type: "checkbox" })`
  accent-color: #ccc;
  width: 20px;
  height: 20px;
  /* transform: scale(1.5); */
  margin-top: 3px;
`;

const Review = ({
  packageId,
  selectMiningData,
  name,
  setName,
  setTransferCheck,
  allMiningPackages,
}) => {
  // websocket
  const bchusdt = useSelector(selectSystem).bchusdt;
  const bnbusdt = useSelector(selectSystem).bnbusdt;
  const btcusdt = useSelector(selectSystem).btcusdt;
  const cakeusdt = useSelector(selectSystem).cakeusdt;
  const ethusdt = useSelector(selectSystem).ethusdt;
  const iotausdt = useSelector(selectSystem).iotausdt;
  const neousdt = useSelector(selectSystem).neousdt;
  const eosusdt = useSelector(selectSystem).eosusdt;
  const etcusdt = useSelector(selectSystem).etcusdt;

  const packageData = useSelector(selectMining)?.miningPackage;

  const [originalPackageData, setOriginalPackageData] = useState([]);
  const [transferPackageData, setTransferPackageData] = useState([]);
  const [total, setTotal] = useState("");

  useEffect(() => {
    setTotal(
      selectMiningData[0]?.PeriodItems.map((item) =>
        transferUsdt(item.Target, item.CurrentVolume)
      ).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    );
    setOriginalPackageData(
      allMiningPackages.Packages.filter(
        (data) => data.PackageId === selectMiningData[0].PackageId
      )
    );
  }, [selectMiningData, allMiningPackages]);

  useEffect(() => {
    setTransferPackageData(
      packageData.filter((data) => data.PackageId == packageId)
    );
  }, [packageId]);

  const transferUsdt = (target, value) => {
    switch (target) {
      case "BCH":
        return value * bchusdt;
      case "BNB":
        return value * bnbusdt;
      case "BTC":
        return value * btcusdt;
      case "CAKE":
        return value * cakeusdt;
      case "ETH":
        return value * ethusdt;
      case "IOTA":
        return value * iotausdt;
      case "NEO":
        return value * neousdt;
      case "EOS":
        return value * eosusdt;
      case "ETC":
        return value * etcusdt;
      case "USDT":
        return value * 1;
      default:
        if (target?.includes("USDT")) return value * 1;
        else return value * 1;
    }
  };

  const backgroundColor = [
    "rgba(255, 99, 132, 1)",
    "rgba(54, 162, 235, 1)",
    "rgba(255, 206, 86, 1)",
    "rgba(75, 192, 192, 1)",
  ];

  const policyText =
    getLanguage() === "Ch"
      ? `我知道預覽轉換是在時間：${TaiwanTimeFormat()} (台北標準時間) 時，依照幣安交易所(https://www.binance.com/)，所獲得的市場報價換算的結果。當Package結算時，會依據結算當天報價執行轉換。`
      : `I know that the preview conversion is based on the market quotes obtained from Binance (https://www.binance.com/ ) at ${TaiwanTimeFormat()} (Taipei Standard Time). When the Package is settled, the conversion will be executed according to the closing day's quotes.`;

  const BackgroundColor = (data) => {
    const list = data?.map((el) => el.Proportion * 100);
    const percentage = list?.map((item, index, array) => {
      let sub = array.slice(0, index + 1);
      let sum = sub.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      return sum;
    });

    return data?.map((item, index) => {
      if (data.length > 1) {
        return `${backgroundColor[index]} ${percentage[index - 1] ?? 0}% ${
          percentage[index]
        }%`;
      } else {
        return `${backgroundColor[index]} 0 ${percentage[index]}%`;
      }
    });
  };

  return (
    <Wrapper>
      <Title>
        <Lang
          datakey="mining_package_info"
          defaultText="Mining Package Information"
        />
      </Title>
      <InputWrapper>
        <InputContainer>
          <Label>
            <Lang
              datakey="mining_package_name"
              defaultText="Mining Package Name"
            />
          </Label>
          <InputEl value={name} onChange={(e) => setName(e.target.value)} />
        </InputContainer>

        <InputContainer>
          <Label>
            <Lang datakey="deposit_amount" defaultText="Deposit Amount" />
          </Label>
          <InputEl value={selectMiningData[0]?.Deposit} disabled />
        </InputContainer>
      </InputWrapper>
      <Title>
        <Lang
          datakey="transfer_result_review"
          defaultText="Transfer Result Review"
        />
      </Title>
      <PackageWrapper>
        <PackageContainer>
          <Package>
            <PackageNameWrapper>
              <PackageName>{selectMiningData[0]?.PackageName}</PackageName>
            </PackageNameWrapper>
            <PackageDayWrapper>
              <PackageDay>{originalPackageData[0]?.PeriodDays}</PackageDay>
              <PackageUnit>
                <Lang datakey="mining_days" defaultText="days" /> /{" "}
                <Lang datakey="mining_period" defaultText="period" />
              </PackageUnit>
            </PackageDayWrapper>
            <PacakageImgDataWrapper>
              <PackageImgWrapper>
                <PacakageImg
                  style={{
                    background: `conic-gradient(${BackgroundColor(
                      originalPackageData[0]?.Components
                    )}) `,
                  }}
                >
                  <PacakageImgCover>
                    <PackageImgRateText>APY</PackageImgRateText>
                    <PacakageImgRate>
                      {`${(originalPackageData[0]?.Rate * 100).toFixed(2)}`}
                      <span> %</span>
                    </PacakageImgRate>
                  </PacakageImgCover>
                </PacakageImg>
              </PackageImgWrapper>
              {selectMiningData[0]?.PeriodItems.length == 0 ? (
                <PackageDataWrapper>
                  {originalPackageData[0]?.Components.map((data, index) => (
                    <PacakageDataContainer
                      style={{ color: backgroundColor[index] }}
                    >
                      <PackageTarget>{data.Asset}</PackageTarget>
                      <PackagePercentage>
                        {Number(0).toFixed(2)}
                      </PackagePercentage>
                    </PacakageDataContainer>
                  ))}
                </PackageDataWrapper>
              ) : (
                <PackageDataWrapper>
                  {selectMiningData[0]?.PeriodItems.map((data, index) => (
                    <PacakageDataContainer
                      style={{ color: backgroundColor[index] }}
                    >
                      <PackageTarget>{data.Target}</PackageTarget>
                      <PackagePercentage>
                        {data.CurrentVolume?.toFixed(2)}
                      </PackagePercentage>
                    </PacakageDataContainer>
                  ))}
                </PackageDataWrapper>
              )}
            </PacakageImgDataWrapper>

            <PacakageTotalWrapper>
              <PackageTotalContainer>
                <PacakageTotalName>
                  <Lang datakey="total" defaultText="Total" />
                </PacakageTotalName>
                <PacakageTotal>{Number(total)?.toFixed(2)}</PacakageTotal>
              </PackageTotalContainer>
            </PacakageTotalWrapper>
          </Package>
          <ChangeIconWrapper>
            <Icons.Change />
          </ChangeIconWrapper>
          <Package>
            <PackageNameWrapper>
              <PackageName>{transferPackageData[0]?.Name}</PackageName>
            </PackageNameWrapper>
            <PackageDayWrapper>
              <PackageDay>{transferPackageData[0]?.PeriodDays}</PackageDay>
              <PackageUnit>
                <Lang datakey="mining_days" defaultText="days" /> /{" "}
                <Lang datakey="mining_period" defaultText="period" />
              </PackageUnit>
            </PackageDayWrapper>
            <PacakageImgDataWrapper>
              <PackageImgWrapper>
                <PacakageImg
                  style={{
                    background: `conic-gradient(${BackgroundColor(
                      transferPackageData[0]?.Components
                    )}) `,
                  }}
                >
                  <PacakageImgCover>
                    <PackageImgRateText>APY</PackageImgRateText>
                    <PacakageImgRate>
                      {`${(transferPackageData[0]?.Rate * 100).toFixed(2)}`}
                      <span> %</span>
                    </PacakageImgRate>
                  </PacakageImgCover>
                </PacakageImg>
              </PackageImgWrapper>
              <PackageDataWrapper>
                {transferPackageData[0]?.Components.map((data, index) => (
                  <PacakageDataContainer
                    style={{ color: backgroundColor[index] }}
                  >
                    <PackageTarget>{data.Asset}</PackageTarget>

                    <PackagePercentage>
                      {(
                        (total * data.Proportion) /
                        transferUsdt(data.Asset, 1)
                      )?.toFixed(2)}
                    </PackagePercentage>
                  </PacakageDataContainer>
                ))}
              </PackageDataWrapper>
            </PacakageImgDataWrapper>

            <PacakageTotalWrapper>
              <PackageTotalContainer>
                <PacakageTotalName>
                  <Lang datakey="total" defaultText="Total" />
                </PacakageTotalName>
                <PacakageTotal>{Number(total)?.toFixed(2)}</PacakageTotal>
              </PackageTotalContainer>
            </PacakageTotalWrapper>
          </Package>
        </PackageContainer>
      </PackageWrapper>
      <CheckboxWrapper>
        <CheckBox
          type="checkbox"
          onChange={(e) => setTransferCheck(e.target.checked)}
        ></CheckBox>
        <CheckboxContainer>{policyText}</CheckboxContainer>
      </CheckboxWrapper>
    </Wrapper>
  );
};

export default Review;
