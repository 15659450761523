// components
import AlgoStaticTable from './AlgoStaticTable';

// images
import algo_performance from '../../../img/algo_performance.png';

// plugins
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

const AlgoPerformance = ({ chart, table }) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  const options = {
    // responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Minning Performance',
      },
    },
    scales: {
      y: {
        // min: 0,
        ticks: {
          callback: function(value){return value+ "%"}
        }
      }
    }
  };

  return (
    <div className='pt-10'>
      <h3 className='py-2'>Algo Performance Top3</h3>

      {/* line chart */}
      <div className='w-full py-8 overflow-x-auto'>
        <div className='min-w-[600px]'>
          <Line options={options} data={chart ?? { labels: [], datasets: []}} />
        </div>
      </div>
      
      {/* table */}
      <div className='py-8'>
        <AlgoStaticTable data={table} />
      </div>

      {/* img */}
      <div className='py-8'>
        <img src={algo_performance} alt='' />
      </div>

    </div>
  )
}
export default AlgoPerformance