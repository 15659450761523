import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { routes } from "../../../components/Router";
import { getAuthToken } from "../../services/common";

const Navigate = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!getAuthToken().token) navigate(routes.login_v2);
  }, []);

  return (
    <>
      <Outlet />
    </>
  );
};

export default Navigate;
