import { useState, useEffect } from "react";

import {
  API_CommitMiningApplyTransferGroup,
  API_GetMiningApplyTransferGroup,
} from "../../../utils/api";
import UseSwal from "../../hooks/Lang"; // swal confirm button background color
import Loading from "../../../components/Global/Loading";

import styled from "styled-components";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

const HeaderContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;

  *:last-child {
    padding-left: 1rem;
  }
`;
const Title = styled.h3`
  color: #1976d2;
  padding: 1rem 0;
`;

const TableContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  border: 1px solid #e5e7eb;
  border-radius: 4px;

  tbody tr {
    cursor: pointer;

    &:hover {
      background: #eeea;
    }
  }
`;

const MiningCommitTransfer = () => {
  // api: 取得申請轉換組別
  const getApplyTransferGroup = async () => {
    setLoading(true);
    const res = await API_GetMiningApplyTransferGroup();
    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return UseSwal(res.Message);
    setData(res.ApplyAhftGroup);
    setLoading(false);
  };

  // api: 確認轉換組別
  const commitTransfer = async () => {
    setLoading(true);
    const res = await API_CommitMiningApplyTransferGroup({
      AhftGroupIds: commitAhftIds,
    });
    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return UseSwal(res.Message);
    setCommitAhftIds([]);
    setSearchAccountId("");
    setLoading(false);
    UseSwal("確認轉換組別成功！");
    await getApplyTransferGroup();
  };

  // 更新確認轉換組別
  const toggleCommitAhftIds = (ahftId) => {
    !commitAhftIds.includes(ahftId)
      ? setCommitAhftIds([...commitAhftIds, ahftId])
      : setCommitAhftIds(commitAhftIds.filter((id) => id !== ahftId));
  };

  // 全選確認轉換組別
  const toggleCommitAll = (e) => {
    e.target.checked
      ? setCommitAhftIds(data.map((ahft) => ahft.AhftGroupId))
      : setCommitAhftIds([]);
  };

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchAccountId, setSearchAccountId] = useState("");
  const [commitAhftIds, setCommitAhftIds] = useState([]);

  useEffect(() => {
    if (!data) setData("get");
  }, []);

  useEffect(() => {
    if (!data) return;
    if (data === "get") getApplyTransferGroup();
  }, [data]);

  if (loading) return <Loading />;
  return (
    <HeaderContainer>
      {/* header: title */}
      <TitleContainer>
        <TitleWrapper>
          <Title>Apply Mining Transfer Group</Title>
        </TitleWrapper>
        <div>
          <Button
            variant="contained"
            onClick={commitTransfer}
            disabled={commitAhftIds.length === 0}
          >
            Commit
          </Button>
        </div>
      </TitleContainer>

      {/* content: table */}
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead
            sx={{ "&": { cursor: "default", zIndex: 2, position: "relative" } }}
          >
            <TableRow>
              {/* checkbox */}
              <TableCell>
                <input
                  type="checkbox"
                  checked={data.length === commitAhftIds.length}
                  onChange={toggleCommitAll}
                />
              </TableCell>

              {/* account id */}
              <TableCell>
                <div>
                  <TextField
                    sx={{
                      ".MuiInputBase-root *": { border: "none" },
                    }}
                    label="AccountID"
                    value={searchAccountId}
                    onChange={(e) => setSearchAccountId(e.target.value)}
                  />
                </div>
              </TableCell>

              {/* others */}
              <TableCell>AhftGroupId</TableCell>
              <TableCell>FromPeriod</TableCell>
              <TableCell>FromPackageId</TableCell>
              <TableCell>ToPackageId</TableCell>
              <TableCell>PeriodEndTime</TableCell>

              {/* actions field */}
              <TableCell key="toggle"></TableCell>
            </TableRow>
          </TableHead>

          <TableBody sx={{ "&": { zIndex: 1, position: "relative" } }}>
            {data
              ?.filter(
                ({ AccountId }) =>
                  !searchAccountId ||
                  AccountId.toLowerCase().includes(
                    searchAccountId.toLowerCase()
                  )
              )
              ?.map((ahft, index) => (
                <TableRow
                  key={index}
                  onClick={() => toggleCommitAhftIds(ahft.AhftGroupId)}
                >
                  <TableCell>
                    <input
                      type="checkbox"
                      checked={commitAhftIds.includes(ahft.AhftGroupId)}
                      readOnly
                    />
                  </TableCell>
                  <TableCell>{ahft.AccountId}</TableCell>
                  <TableCell>{ahft.AhftGroupId}</TableCell>
                  <TableCell>{ahft.CurrentPeriod}</TableCell>
                  <TableCell>{ahft.FromPackageId}</TableCell>
                  <TableCell>{ahft.ToPackageId}</TableCell>
                  <TableCell>{ahft.PeriodEndTime}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </HeaderContainer>
  );
};

export default MiningCommitTransfer;
