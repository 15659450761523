import { StyledButton } from '../Global/Ui';
import Swal from 'sweetalert2/dist/sweetalert2.js';

const SharingHistory = ({ data }) => {
  return (
    <div className='w-full h-full relative flex flex-col'>
      <h3 className='text-center py-5 text-gray'>
        Sharing History (Last 3 month)
      </h3>
      <div className='flex-1'>
        <div className='sticky top-0 px-8 flex py-4 text-sub border-b border-b-sub-word bg-white-main dark:bg-black-main'>
          <div className='flex-1'>BenefitLevel</div>
          <div className='flex-[2]'>TriggerName</div>
          <div className='flex-1'>RewardPoints</div>
          <div className='flex-1'>CreateTime</div>
        </div>
        {
          data.map(({ BenefitLevel, CreateTime, TriggerName, RewardPoints }, index) => (
            <div key={index} className='px-8 flex py-4 text-[1rem]  border-b border-b-sub-word'>
              <div className='flex-1'>{BenefitLevel}</div>
              <div className='flex-[2]'>{TriggerName}</div>
              <div className='flex-1'>{RewardPoints}</div>
              <div className='flex-1'>{CreateTime.replaceAll('T', ' ')}</div>
            </div>
          ))
        }
      </div>
      <div className='max-w-[8vw] py-[1vh] mx-auto'>
        <StyledButton
          value='Close'
          onClick={() => Swal.close()}
        />
      </div>
    </div>
  )
}

export default SharingHistory
