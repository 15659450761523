import styled, { css } from "styled-components";

import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectSystem } from "../../slices/systemSlice";

import Step_1 from "../../images/apikeyGuide/BybitUta/Step_1.jpg";
import Step_2 from "../../images/apikeyGuide/BybitUta/Step_2.jpg";
import Step_3 from "../../images/apikeyGuide/BybitUta/Step_3.jpg";
import Step_4 from "../../images/apikeyGuide/BybitUta/Step_4.jpg";
import Step_5_1 from "../../images/apikeyGuide/BybitUta/Step_5-1.jpg";
import Step_5_2 from "../../images/apikeyGuide/BybitUta/Step_5-2.jpg";
import Step_6 from "../../images/apikeyGuide/BybitUta/Step_6.jpg";
import Step_7 from "../../images/apikeyGuide/BybitUta/Step_7.jpg";
import Step_8 from "../../images/apikeyGuide/BybitUta/Step_8.jpg";
import Step_9_1 from "../../images/apikeyGuide/BybitUta/Step_9-1.jpg";
import Step_9_2 from "../../images/apikeyGuide/BybitUta/Step_9-2.jpg";
import Step_9_3 from "../../images/apikeyGuide/BybitUta/Step_9-3.jpg";

import { useOutletContext } from "react-router-dom";

const Wrapper = styled.div`
  height: 100%;
  padding-bottom: 100px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 20px;
`;

const Title = styled.div`
  font-size: min(max(1.25vw, 22px), 24px);
  color: #fcfcfc;
  padding-right: 10px;
  text-transform: capitalize;

  ${RWD_MD(css`
    font-size: min(max(1.145vw, 20px), 22px);
  `)};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const InstructionsWrapper = styled.div`
  /* text-align: left; */

  color: #d3d3d3;
  padding-bottom: 20px;
`;

const StepWrapper = styled.div`
  width: 100%;
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #22254a;
  margin-bottom: 20px;
`;

const StepBox = styled.div`
  padding: 30px 40px;
  width: 100%;
`;

const StepNumber = styled.div`
  font-size: min(max(2.5vw, 40px), 48px);
  color: #0aecd1;
  padding-right: 10px;
`;

const StepNumberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const StepNumberContainer = styled.div`
  font-size: 13px;
  position: absolute;
  left: 0;
  top: -10px;
  color: rgba(10, 236, 209, 0.5);
`;

const StepImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
`;

const StepImg = styled.div`
  display: flex;
  background-color: #fcfcfc;
  padding: 10px;

  img {
    width: 100%;
  }
`;

const StepImgDescription = styled.div`
  font-size: 15px;
  padding-top: 20px;
  color: #54587b;
`;

const StepTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: baseline;
  border-bottom: 2px solid #3a3d5a;
  font-family: Montserrat;
  color: #fcfcfc;
`;

const StepNote = styled.div`
  padding: 10px 0;
  color: #d3d3d3;
`;

const StepRemark = styled.div``;

const LinkWrapper = styled.div`
  padding-bottom: 20px;
  color: #d3d3d3;
`;

const LinkText = styled.a`
  border-bottom: 1px solid #d3d3d3;
  width: auto;
  cursor: pointer;
  transition: all 0.3;

  &:hover {
    color: #0aecd1;
    border-color: #0aecd1;
  }
`;

const ApiKeyBybitUta = () => {
  // 關閉Onboarding
  const { onboardingStatus } = useOutletContext();
  onboardingStatus[0]();

  const lang = useSelector(selectSystem).language;
  const [language, setLanguage] = useState("");

  useEffect(() => {
    setLanguage(lang);
  }, [lang]);

  const TitleData = [
    {
      id: 1,
      en: "How to create your Bybit.com Subaccount for UTA",
      ch: "如何在 Bybit.com 創建 UTA 子帳戶",
    },
  ];

  const instructionsData = [
    {
      id: 1,
      en: "If your Bybit account is a UTA account, please follow the steps below to create a Standard account to use the AVA platform.",
      ch: "如果你的 Bybit 帳戶是 UTA 帳戶，請按照以下步驟創建一個標準帳戶以使用 AVA 平台。",
    },
  ];

  const stepData = [
    {
      id: 1,
      title: [
        {
          en: 'Go to bybit.com (Exchange) (1) under profile options, find "Subaccount" (2)',
          ch: '進入 bybit.com（交易所），在個人資料選項（1）中找到 "子帳號"（2）',
        },
      ],

      note: "",
      imgData: [
        {
          img: Step_1,
          imgDescription: "",
        },
      ],

      remark: "",
    },
    {
      id: 2,
      title: [
        {
          en: 'Then click on "Create Subaccount" (3) and choose "Standard Subaccount" (4)',
          ch: '點擊 "新建子帳戶"（3），選擇 "普通子帳戶"（4）',
        },
      ],
      note: "",
      imgData: [
        {
          img: Step_2,
          imgDescription: "",
        },
      ],
      remark: "",
    },
    {
      id: 3,
      title: [
        {
          en: 'Choose "Standard Account" (5) and input the Username(6)',
          ch: ' 選擇 "經典帳戶"（5），輸入用戶名（6）',
        },
      ],
      note: "",
      imgData: [
        {
          img: Step_3,
          imgDescription: "",
        },
      ],
      remark: "",
    },
    {
      id: 4,
      title: [
        {
          en: 'Click on  "API Management" (7)',
          ch: '點擊 "API 管理"（7）',
        },
      ],
      note: "",
      imgData: [
        {
          img: Step_4,
          imgDescription: "",
        },
      ],
      remark: "",
    },
    {
      id: 5,
      title: [
        {
          en: 'Click on "Create New Key" (8) and choose "System -generated API Keys" (9)',
          ch: '點擊 "新建密鑰"（8），選擇 "系統生成的API密鑰"（9）',
        },
      ],
      note: "",
      imgData: [
        {
          img: Step_5_1,
          imgDescription: [
            {
              en: "",
              ch: "",
            },
          ],
        },
        {
          img: Step_5_2,
          imgDescription: [
            {
              en: 'Click on "System -generated API Keys"',
              ch: '點擊 "System-generated API Keys"',
            },
          ],
        },
      ],
      remark: "",
    },
    {
      id: 6,
      title: [
        {
          en: 'Select the "API Transaction" option and specify AVA (10)',
          ch: "選擇“API交易”選項並命名為AVA（10）",
        },
      ],
      note: "",
      imgData: [
        {
          img: Step_6,
          imgDescription: "",
        },
      ],
      remark: "",
    },
    {
      id: 7,
      title: [
        {
          en: 'Fill in the following rights (11),(12),(13), then validate with "Submit"',
          ch: '填寫以下權限（11），（12），（13），然後點擊 "提交" 確認',
        },
      ],
      note: "",
      imgData: [
        {
          img: Step_7,
          imgDescription: [
            {
              en: "Check the above rights carefully",
              ch: "仔細檢查上述權限",
            },
          ],
        },
      ],
      remark: "",
    },
    {
      id: 8,
      title: [
        {
          en: "Validate the security checks (Code by 2FA), in the summary, copy your API Key and API Secret to a text file (14)",
          ch: '通過 "2FA" 進行安全驗證，在摘要中，將您的API密鑰和API密鑰密碼複製到一個文本文件中（14）',
        },
      ],
      note: "",
      imgData: [
        {
          img: Step_8,
          imgDescription: [
            {
              en: 'Once your keys are copied to a safe place, you can close by clicking on "Understood"',
              ch: '一旦你的金鑰被複製到安全的地方，你可以點擊 "了解" 來關閉',
            },
          ],
        },
      ],
      remark: "",
    },
    {
      id: 9,
      title: [
        {
          en: 'Now go to your AVA account in the "Setting" section',
          ch: '現在轉到 AVA 帳戶的 "設置" 部分',
        },
      ],
      note: [
        {
          en: 'In the section "API Management" select "Bybit" and indicate your "API Key" and "API Secret" that you just copied in a text file. And click on "Ok".',
          ch: "在“API管理”部分選擇“Bybit”並將您剛剛在文本文件中複製的“API密鑰”和“API密鑰密碼”貼至此處。然後點擊“確定”",
        },
      ],
      imgData: [
        {
          img: Step_9_1,
          imgDescription: "",
        },
        {
          img: Step_9_2,
          imgDescription: "",
        },
      ],
      remark: "",
    },
    {
      id: 10,
      title: [
        {
          en: "If all went well, you will see your Bybit key added",
          ch: "如果一切順利，您將看到您的Bybit密鑰已添加",
        },
      ],
      note: "",
      imgData: [
        {
          img: Step_9_3,
          imgDescription: [
            {
              en: "The Bybit API key has been added. Congratulations!",
              ch: "Bybit API密鑰已添加成功。恭喜！",
            },
          ],
        },
      ],
      remark: "",
    },
  ];

  const handleLink = () => {
    window.open(`https://www.bybit.com/invite?ref=YZWO1G`, "_blank");
  };

  return (
    <Wrapper>
      <Container>
        <TitleWrapper>
          <Title>{TitleData[0]?.[language?.toLocaleLowerCase()]}</Title>
        </TitleWrapper>

        <ContentWrapper>
          {instructionsData.map((data) => (
            <InstructionsWrapper
              onClick={() => handleUtaLink(data.id)}
              key={data.id}
            >
              <p>{data?.[language?.toLocaleLowerCase()]}</p>
            </InstructionsWrapper>
          ))}

          <StepWrapper>
            {stepData.map((data) => (
              <StepContainer>
                <StepBox>
                  <StepTitle>
                    <StepNumberWrapper>
                      <StepNumberContainer>STEP</StepNumberContainer>
                      <StepNumber>
                        {String(data.id).padStart(2, "0")}
                      </StepNumber>
                    </StepNumberWrapper>
                    <p>{data.title[0]?.[language?.toLocaleLowerCase()]}</p>
                  </StepTitle>
                  <StepNote>
                    {data.note?.[0]?.[language?.toLocaleLowerCase()]}
                  </StepNote>
                  {data.imgData.map((img) => (
                    <StepImgContainer>
                      <StepImg>
                        <img src={img.img} />
                      </StepImg>
                      <StepImgDescription>
                        {
                          img.imgDescription?.[0]?.[
                            language?.toLocaleLowerCase()
                          ]
                        }
                      </StepImgDescription>
                    </StepImgContainer>
                  ))}
                  <StepRemark>
                    {data.remark?.[0]?.[language?.toLocaleLowerCase()]}
                  </StepRemark>
                </StepBox>
              </StepContainer>
            ))}
          </StepWrapper>
        </ContentWrapper>
      </Container>
    </Wrapper>
  );
};

export default ApiKeyBybitUta;
