import styled, { css } from "styled-components";
import { useState, useEffect, Fragment } from "react";
import { useSendEmailAndTelegramMutation } from "../../../services/authApi";
import { SuccessMySwal, ErrorMySwal } from "../../../global/MySwal";
import { LangString } from "../../../utils";
import { getLanguage } from "../../../services/common";
import Loading from "../../Loading/Loading";

import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";
import { routes } from "../../../../components/Router";
import Lang from "../../../global/Lang";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Title = styled.div`
  font-size: 30px;
  text-align: center;
  font-size: clamp(20px, 1.5625vw, 30px);
`;

const Description = styled.div`
  padding: 20px 0;
  color: #d3d3d3;
  text-align: center;
  font-size: clamp(14px, 0.8333vw, 16px);
`;

const Help = styled.div`
  padding-top: 5px;
  color: #54587b;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.3s linear;
  border-bottom: 1px solid transparent;
  margin-bottom: 10px;

  &:hover {
    border-bottom: 1px solid #54587b;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  background: #fcfcfc;
  border-radius: 100px;
  width: 100%;
  max-width: 40vw;

  ${RWD_MD(css`
    max-width: 80%;
  `)};

  ${RWD_SM(css`
    max-width: 100%;
  `)};
`;

const InputEl = styled.input`
  background: #fcfcfc;
  padding: 1.0416vw 1.5625vw;
  width: 100%;
  border-radius: 100px;
  color: #151944;

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 14px), 16px);
    padding: 10px 20px;
  `)};
`;

const GetCodeButton = styled.button`
  color: #151944;
  white-space: nowrap;
  padding-right: 15px;
  font-size: min(max(1.0416vw, 14px), 20px);
`;

const WithdrawalStep = ({
  setVerifyCode,
  withdrawalIsVerified,
  withdrawlVerifyCode,
  withdrawalAddress,
  setWithdrawalAddress,
}) => {
  const [
    _getWithdrawlVerifyCode,
    { data, isSuccess, isError, error, isLoading },
  ] = useSendEmailAndTelegramMutation();

  useEffect(() => {
    if (isSuccess) {
      // 多國語言
      const title = LangString(
        "alert_verificationCode_success",
        "Verification code sent"
      );
      const text = LangString(
        "alert_telegramVerificationCode_info",
        "Please check your email or Telegram to retrieve the verification code."
      );
      const confirmText = LangString("confirm", "Confirm");
      SuccessMySwal({
        title: title,
        text: text,
        showCancelButton: false,
        confirmButtonText: confirmText,
      });
    }

    if (isError) {
      ErrorMySwal({
        title:
          error?.data?.ResultLanguages[getLanguage()] || error?.data?.Message,
      });
    }
  }, [data, isError]);

  const handleGetWithdrawVerifyCode = async () => {
    await _getWithdrawlVerifyCode();
  };

  const handleGuide = () => {
    window.open(
      `${window.location.origin}${routes.WithdrawalGuide_v2}?guide=onboarding`,
      "_blank"
    );
  };

  return (
    <Wrapper>
      {isLoading && <Loading />}

      <Container>
        {withdrawalIsVerified ? (
          <Fragment key={"verified"}>
            <Title>
              <Lang
                datakey="deposit_input_address"
                defaultText="Input Your Deposit Address"
              />
            </Title>
            <Help onClick={handleGuide}>
              If you don't know HOW, click here.
            </Help>
            <InputWrapper>
              <InputEl onChange={(e) => setWithdrawalAddress(e.target.value)} />
            </InputWrapper>
            <Description>！Address format only accept BEP-20 only</Description>
          </Fragment>
        ) : (
          <Fragment key={"address"}>
            <Title>Update Withdrawal Address After Verification</Title>
            <Help onClick={handleGuide}>
              If you don't know HOW, click here.
            </Help>
            <InputWrapper>
              <InputEl onChange={(e) => setVerifyCode(e.target.value)} />
              <GetCodeButton onClick={handleGetWithdrawVerifyCode}>
                Get Code
              </GetCodeButton>
            </InputWrapper>
            <Description>
              Please click "Get Code" to get the verification code (sent via
              verified Telegram).
            </Description>
          </Fragment>
        )}
      </Container>
    </Wrapper>
  );
};

export default WithdrawalStep;
