import styled, { css } from "styled-components";
import { Transformer } from "markmap-lib";
import * as markmap from "markmap-view";
import { useEffect, useRef, useState } from "react";
import { RWD_MD } from "../../../utils/rwd";
import Icons from "../../../global/icons";
import Lang from "../../../global/Lang";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #22254a;
  padding: 40px;
`;

const Container = styled.div`
  border: 1px solid;
  display: flex;
  width: 100%;
  padding: 20px;
  border-color: rgba(255, 255, 255, 0.15);
  border-radius: 20px;
`;

const MarkdownBtnStyle = css`
  width: 170px;
  border: 1px solid;
  border-color: rgba(255, 255, 255, 0.15);
  border-radius: 20px;
  padding: 5px;
  &:hover {
    border-color: #0aecd1;
    color: #0aecd1;
  }
`;

const FullscreenBtn = styled.button`
  width: 100px;
  border: 1px solid;
  border-color: rgba(255, 255, 255, 0.15);
  border-radius: 20px;
  padding: 5px;
  position: absolute;
  &:hover {
    border-color: #0aecd1;
    color: #0aecd1;
  }
  top: 45px;
  right: 45px;
`;

const SwitchBtnDiv = styled.div`
  height: 60px;
  position: absolute;
  display: flex;
  bottom: 40px;
  left: 40px;
  ${RWD_MD(css`
    width: 170;
    display: block;
    top: 80px;
  `)};
`;

const ShowBtnDiv = styled.div`
  cursor: pointer;
  display: flex;
  height: 30px;
  margin: 15px 10px;
  border: 1px solid;
  border-color: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  svg path {
    stroke: white;
  }
  &:hover {
    border-color: #0aecd1;
    color: #0aecd1;
    svg path {
      stroke: #0aecd1;
    }
  }
  ${RWD_MD(css`
    width: 42px;
  `)};
`;

const IconLine = styled.div``;

const MarkBtnDiv = styled.div`
  ${RWD_MD(css`
    display: block;
    width: 170px;
  `)};
`;

const StyledButton = styled.button`
  ${MarkdownBtnStyle}
  transform: ${({ showButtons, translateValue }) =>
    showButtons ? "translateX(0)" : `translateX(${translateValue})`};
  opacity: ${({ showButtons }) => (showButtons ? 1 : 0)};
  transition: transform 1s, opacity 1s;
  pointer-events: ${({ showButtons }) => (showButtons ? "auto" : "none")};
  ${({ isSelected }) => isSelected && activeStyle}
`;

const activeStyle = {
  borderColor: "#0aecd1",
  color: "#0aecd1",
};

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: typeof window !== "undefined" ? window.innerWidth : 0,
    height: typeof window !== "undefined" ? window.innerHeight : 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

const useMarkdown = (md, option, isFullscreen) => {
  const { Markmap, loadCSS, loadJS } = markmap;
  const transformer = new Transformer();

  const ref = useRef(null);
  useEffect(() => {
    ref.current.innerHTML = "";
    const { root, features } = transformer.transform(md);
    const { styles, scripts } = transformer.getUsedAssets(features);
    if (styles) loadCSS(styles);
    if (scripts) loadJS(scripts, { getMarkmap: () => markmap });
    Markmap.create(ref.current, option, root);
  }, [md, option]);
  return (
    <svg
      ref={ref}
      style={{ width: "100%", height: isFullscreen ? "100%" : "400px" }}
    ></svg>
  );
};

const MarkdownView = (props) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [FullscreenStr, setFullscreenStr] = useState(
    <Lang datakey="fullscreen" defaultText="Fullscreen" />
  );
  const [selectedButton, setSelectedButton] = useState("DownLine");
  const [showButtons, setShowButtons] = useState(true);
  const MarkdownComponent = useMarkdown(props.markdownData, null, isFullscreen);
  const userHiveBenefit = props.userHiveBenefit;

  const { width } = useWindowSize();
  const isSMResolution = window.innerWidth <= 768;

  const handleToggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
    if (isFullscreen)
      setFullscreenStr(<Lang datakey="fullscreen" defaultText="Fullscreen" />);
    else
      setFullscreenStr(
        <Lang datakey="exitfullscreen" defaultText="Exit Fullscreen" />
      );
  };

  const handleToggleShowBtn = () => {
    setShowButtons(!showButtons);
  };

  const handleButtonClick = (level) => {
    props.onButtonClick(level);
    setSelectedButton(level);
  };

  return (
    <Wrapper
      style={
        isFullscreen
          ? {
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 1000,
            }
          : {}
      }
    >
      <Container style={isFullscreen ? { height: "100%" } : {}}>
        {MarkdownComponent}
      </Container>
      <FullscreenBtn onClick={handleToggleFullscreen}>
        {FullscreenStr}
      </FullscreenBtn>
      <SwitchBtnDiv>
        <ShowBtnDiv
          onClick={handleToggleShowBtn}
          style={{
            transform: isSMResolution
              ? showButtons
                ? "rotate(270deg)"
                : "rotate(450deg)"
              : showButtons
              ? "rotate(0deg)"
              : "rotate(180deg)",
            transition: "transform 1s",
          }}
        >
          <Icons.Arrow
            style={{
              transform: showButtons ? "rotate(90deg)" : "rotate(90deg)",
              transition: "transform 1s",
            }}
          />
          <IconLine>｜</IconLine>
        </ShowBtnDiv>
        <MarkBtnDiv>
          <StyledButton
            onClick={() => handleButtonClick("DownLine")}
            isSelected={selectedButton === "DownLine"}
            showButtons={showButtons}
            translateValue="0%"
          >
            <Lang datakey="sponsor_map" defaultText="Sponsor Map" />
            <br /> ${userHiveBenefit.userDownlineHiveBenefit}
          </StyledButton>
          <StyledButton
            onClick={() => handleButtonClick("L1H")}
            isSelected={selectedButton === "L1H"}
            showButtons={showButtons}
            translateValue="-100%"
          >
            <Lang datakey="member_chain" defaultText=" Member Chain" /> L1H{" "}
            <br /> ${userHiveBenefit.userL1HHiveBenefit}
          </StyledButton>
          <StyledButton
            onClick={() => handleButtonClick("L2H")}
            isSelected={selectedButton === "L2H"}
            showButtons={showButtons}
            translateValue="-200%"
          >
            <Lang datakey="member_chain" defaultText=" Member Chain" /> L2H{" "}
            <br /> ${userHiveBenefit.userL2HHiveBenefit}
          </StyledButton>
          <StyledButton
            onClick={() => handleButtonClick("L3H")}
            isSelected={selectedButton === "L3H"}
            showButtons={showButtons}
            translateValue="-300%"
          >
            <Lang datakey="member_chain" defaultText=" Member Chain" /> L3H{" "}
            <br /> ${userHiveBenefit.userL3HHiveBenefit}
          </StyledButton>
        </MarkBtnDiv>
      </SwitchBtnDiv>
    </Wrapper>
  );
};

export default MarkdownView;
