import { useEffect, useState } from 'react';

// --- components ---
import PolicyCheck from './PolicyCheck';
import Loading from '../Global/Loading';

const Payback = ({
  currentStep,
  totalStep,
  setValid,
  projectInformation,
  setProjectInfomation
}) => {
  const POLICYS = [
    `I am fully aware that for any future changes of return method, when the project cycle is greater than or equal to 90 days, the application should be submitted 15 days in advance; when the project cycle is equal to 15 days, the application should be submitted 5 days in advance.`,
    `Please fill in the return address according to the transferred currency. Please fill in the return address for BTC returning if you would like to transfer BTC into this system. Please fill in the return address for USDT returning if you would like to transfer USDT into this system.
    You will be solely responsible for the consequences for any incorrectness of the address that leads to a transfer failure.`
  ];

  // --- states ---
  const [loading, setLoading] = useState(true);
  const [paybackMode, setPaybacktMode] = useState(() => {
    return projectInformation.returnType !== null
      ? projectInformation.returnType
      : 'interest'
  });
  const [otherMode, setOtherMode] = useState('USDT');

  // --- effect ---
  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    setProjectInfomation({
      ...projectInformation,
      returnType: paybackMode
    });
  }, [paybackMode]);

  // --- components ---
  if (loading) return <Loading />
  
  return (
    <>
      {/* title */}
      <h3 className='text-center'>
        Mining Package Setting ({`${currentStep}/${totalStep}`})
      </h3>

      {/* payback mode */}
      <div className='w-[80%] mx-auto mt-10 sm:w-full sm:max-w-[660px] flex flex-wrap text-center'>
        <span
          className={`
            w-full sm:mr-2 sm:flex-1 border border-1 rounded cursor-pointer
            ${paybackMode === 'interest' && 'bg-primary border-primary' || 'border-black-menu'}
          `}
          onClick={() => setPaybacktMode('interest')}
        >
          Withdraw Mined Token
        </span>
        <span
          className={`
            w-full mt-2 sm:mt-0 sm:mr-2 sm:flex-1 border border-1 rounded cursor-pointer
            ${paybackMode === 'all' && 'bg-primary border-primary' || 'border-black-menu'}
          `}
          onClick={() => setPaybacktMode('all')}
        >
          Withdraw Package
        </span>
        <span
          className={`
            w-full mt-2 sm:mt-0 sm:flex-1 border border-1 rounded cursor-pointer
            ${paybackMode === 'no' && 'bg-primary border-primary' || 'border-black-menu'}
          `}
          onClick={() => setPaybacktMode('no')}
        >
          Compound Package
        </span>
      </div>

      {/* usdt or package */}
      <div className='mt-10 mb-10 sm:flex sm:w-full sm:max-w-[660px] mx-auto'>
        {/* usdt */}
        <div
          className={`
            w-[80%] mx-auto sm:flex-1 border border-1 rounded overflow-hidden cursor-pointer
            ${otherMode === 'USDT' && 'border-primary' || 'border-[#666]'}
          `}
          onClick={() => setOtherMode('USDT')}
        >
          <span className={`
            block w-full text-center border-b border-b-[2px]
            ${otherMode === 'USDT' && 'border-b-primary bg-primary' || 'border-b-[#666]'}
          `}>
            USDT
          </span>
          <p className='px-8 pt-8 pb-20 text-[0.875rem]'>
            System will transfer all tokens into USDT when the package is over.
          </p>
        </div>

        {/* whole package */}
        {/* <div
          className={`
            relative pointer-events-none
            w-[80%] mx-auto mt-4 sm:mt-0 sm:flex-1 border border-1 rounded overflow-hidden cursor-pointer
            ${otherMode === 'WHOLE' && 'border-primary' || 'border-[#666]'}
          `}
          onClick={() => setOtherMode('WHOLE')}
        >
          <span className={`
            block w-full text-center border-b border-b-[2px] bg-sub-word text-[#666]
            ${otherMode === 'WHOLE' && 'border-b-primary bg-primary' || 'border-[#666]'}
          `}>
            WHOLE PACKAGE
          </span>
          <p className='px-2 pt-4 pb-8 text-[0.875rem] text-[#666]'>
            
          </p>
          
          <span className='absolute px-2 bottom-1 right-2 bg-sub-button text-white rounded'>coming soon</span>
        </div> */}
      </div>

      {/* policy */}
      <PolicyCheck data={POLICYS} setValid={setValid} />
    </>
  )
};

export default Payback;