import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { forwardRef, useRef, useState } from "react";
import Icons from "./icons";
import styled from "styled-components";

const Button = styled.div`
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  border-color: ${(props) =>
    props.error ? "#0aecd1" : "rgba(255, 255, 255, 0.15)"};
  border-radius: 8px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  width: 25px;
  height: 25px;
  cursor: pointer;
  path {
    /* stroke: #878787; */
    stroke: ${(props) => (props.isOnClick ? "#0AECD1" : " #878787")};
  }
`;

const Input = styled.input`
  border: 0;
  width: 100%;
  outline: none;

  /* font-size: 0.729vw; */
`;

const Calendar = ({ placeholder, onChangeFun, selectDate }) => {
  const dateInputRef = useRef(null);

  const handleCalendarClose = () => {
    setIsOpen(false);
  };
  const handleCalendarOpen = () => {
    setIsOpen(true);
  };
  const [isOpen, setIsOpen] = useState(false);

  const DateInput = forwardRef(({ value, onClick, isOpen }, ref) => {
    return (
      <Button onClick={onClick} ref={ref}>
        <Input placeholder={placeholder} defaultValue={value}></Input>
        <IconContainer isOnClick={isOpen}>
          {selectDate === null ? (
            <Icons.Calendar />
          ) : (
            <Icons.Close onClick={() => onChangeFun(null)} />
          )}
        </IconContainer>
      </Button>
    );
  });

  return (
    <DatePicker
      selected={selectDate}
      onChange={(date) => onChangeFun(date)}
      dateFormat="yyyy/MM/dd"
      onCalendarClose={handleCalendarClose}
      onCalendarOpen={handleCalendarOpen}
      customInput={<DateInput ref={dateInputRef} isOpen={isOpen} />}
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      maxDate={new Date()}
    />
  );
};

export default Calendar;
