import styled, { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../../utils/rwd";
import { useState, Fragment, useEffect } from "react";
import { useGetNewsQuery } from "../../../../services/datacenterApi";

import Icons from "../../../../global/icons";
import Lang from "../../../../global/Lang";
import parse from "html-react-parser";

const Wrapper = styled.div`
  color: #fcfcfc;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 5px;
  background-color: #22254a;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
`;

const Title = styled.div`
  font-size: min(max(1.0416vw, 18px), 20px);
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px 0;
  padding-left: 10px;

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 16px), 18px);
  `)};
`;

const TitleIcon = styled.div`
  width: 25px;
  height: 25px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 25px;
    height: 25px;
  }
`;

const NoteWrapper = styled.div`
  width: 95%;
  /* background-color: #3a3d5a; */
`;

const NoteContainer = styled.div`
  display: flex;
  padding: 15px 0;
  align-items: flex-start;
  align-items: stretch;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  cursor: pointer;
`;

const Note = styled.div`
  width: 90%;
`;

const NoteTitle = styled.div`
  font-size: min(max(1.041667vw, 16px), 20px);
  transition: all 0.2s;
  color: ${(props) => (props.status ? "#fcfcfc" : "rgba(255, 255, 255, 0.7)")};
`;

const NoteContent = styled.ul`
  padding: 15px 20px 15px 20px;

  list-style-type: circle;
  list-style-position: inside;
  font-size: min(max(0.9375vw, 15px), 18px);
`;

const NoteContentItem = styled.li`
  padding: 5px 0;
  font-size: min(max(0.833vw, 14px), 16px);
  padding-left: 15px;
  color: #fcfcfc;
`;

const SwitchWrapper = styled.div`
  color: #fcfcfc;
  width: 30px;
  height: 30px;
  border: 0;
  font-size: 10px;
  position: relative;
`;

const SwitchBar = styled.span`
  position: absolute;
  transition: 300ms;
  background: white;
  border-radius: 1px;
  transform: ${(props) => (props.status ? "rotate(90deg)" : "rotate(0deg)")};

  :first-child {
    top: 25%;
    bottom: 25%;
    width: 10%;
    left: 45%;
  }

  :last-child {
    left: ${(props) => (props.status ? "50%" : "25%")};
    right: ${(props) => (props.status ? "50%" : "25%")};
    height: 10%;
    top: 45%;
  }
`;

const Announcement = () => {
  const [newsList, setNewsList] = useState([]);
  const { data, error, isError, isSuccess, isLoading } = useGetNewsQuery();

  useEffect(() => {
    if (isSuccess) {
      setNewsList(
        data.News.map((news) => {
          return {
            ...news,
            NewsContent: news.NewsContent.replace("</br>", "<br>"),
            isOpen: false,
          };
        })
      );
    }
  }, [data, isError]);

  const handleSlide = (id) => {
    setNewsList(
      newsList.map((data) => {
        if (data.NewsId === id) {
          data.isOpen = !data.isOpen;
          return data;
        } else {
          return { ...data, isOpen: false };
        }
      })
    );
  };

  return (
    <Wrapper>
      <Container>
        <TitleWrapper>
          <TitleIcon>
            <Icons.DataCenter_Announcement />
          </TitleIcon>
          <Title>
            <Lang
              datakey="datacenter_avadata_systemnote"
              defaultText="AVA System Note"
            />
          </Title>
        </TitleWrapper>

        <NoteWrapper>
          {newsList.map((data) => (
            <NoteContainer
              key={data.NewsId}
              onClick={() => handleSlide(data.NewsId)}
            >
              <Note>
                <NoteTitle status={data.isOpen}>{data.NewsTitle}</NoteTitle>
                {data.isOpen && (
                  <Fragment>
                    <NoteContent>{parse(data.NewsContent)}</NoteContent>

                    {/* {data.NewsContent.map((item, index) => (
                      <NoteContent key={index}>
                        {item.itemtitle}
                        {item.itemcontent.map((el, index) => (
                          <NoteContentItem key={index}>{el}</NoteContentItem>
                        ))}
                      </NoteContent>
                    ))} */}
                  </Fragment>
                )}
              </Note>
              <SwitchWrapper>
                <SwitchBar status={data.isOpen}></SwitchBar>
                <SwitchBar status={data.isOpen}></SwitchBar>
              </SwitchWrapper>
            </NoteContainer>
          ))}
        </NoteWrapper>
      </Container>
    </Wrapper>
  );
};

export default Announcement;
