import { useEffect, useState } from "react";
import {
  API_GetCustomRate_binance,
  API_TrendFollowingBTResult,
} from "../../utils/api";

import Loading from "../Global/Loading";
import UseSwal from "../../backend/hooks/Lang";

import styled from "styled-components";
import { Chart as ChartJS, registerables } from "chart.js";
import { Line, Chart } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";

ChartJS.register(...registerables, zoomPlugin);

// #region styled
const Container = styled.div`
  display: flex;
  width: 98vw;
  height: 100%;
`;

const MenuContainer = styled.div`
  position: relative;
  height: 100%;
  padding: 1rem 0;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 0;
  transition: width 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &.active {
    width: 10vw;
  }
`;

const MenuField = styled.div`
  padding: 0.5rem;
  cursor: pointer;
  color: #666;
  background: transparent;

  &.active {
    background: #1976d2;
    color: #fdfdfd;
  }

  &:not(.active):hover {
    background: #1976d2cc;
    color: #fdfdfd;
  }
`;

const ChartContainer = styled.div`
  position: relative;
  height: 100%;
  flex: 1;
`;

const ChartWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  > *:nth-child(odd) {
    height: 25%;
  }
  > *:nth-child(even) {
    height: 50%;
  }
`;

const ChartFieldContainer = styled.div`
  position: relative;
`;

const ChartTitle = styled.div`
  position: absolute;
  z-index: 1;
  top: 0.5rem;
  right: 2rem;
  color: #aaa5;
  font-size: 1.5rem;
  font-weight: bold;
`;

const ChartField = styled.div`
  height: 100%;
  width: 98%;
  overflow-x: auto;
  border-bottom: 1px solid #cccc;
`;

const NoSymbolSelected = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 1px;
  color: #ccc;
`;

const SideSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  color: #666;
  font-size: 0.8rem;
  border-left: 1px solid #ccc;
`;

const SideSummaryFieldWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  &:first-child {
    border-bottom: 1px solid #ccc;
  }
`;

const SideSummaryTitle = styled.div`
  position: sticky;
  top: 0;
  padding: 0.5rem 0;
  font-size: 0.9rem;
  text-transform: uppercase;
  text-align: center;
  color: #888;
  font-weight: bold;
  background: #333;
`;

const SideSummaryFieldTitle = styled.div`
  font-weight: bold;
  letter-spacing: 1px;
`;

const SideSummaryField = styled.div`
  padding: 0.2rem 0.5rem;
`;

const SummaryContainer = styled.div`
  padding: 2rem 8rem;
  color: #666;
`;

const SummaryTitle = styled.label`
  display: block;
  padding: 2rem 0 1rem;
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
`;
const SummaryTable = styled.table`
  td {
    padding: 0.5rem 2rem 0.5rem 0;
  }
`;
// #endregion

// #region private components
const LineChart = ({ data, title }) => {
  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          callback: function (value, index, ticks) {
            const date = new Date(labels[value]);
            return `${date.getMonth() + 1}/${date.getDate()}`;
          },
        },
      },
      y: {
        beginAtZero: false,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            const time = +tooltipItems[0]?.label;
            const date = new Date(time);
            return `${date.getFullYear()}/${
              date.getMonth() + 1
            }/${date.getDate()}`;
          },
        },
      },
      zoom: {
        limits: {
          y: { min: 0 },
        },
        pan: {
          enabled: true,
          mode: "xy",
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          mode: "xy",
        },
      },
    },
  };
  const labels = data?.filter(({ sma }) => sma !== 0)?.map(({ time }) => time);
  const lineChartData = {
    labels,
    datasets: [
      {
        // close
        label: "close",
        data: data?.filter(({ sma }) => sma !== 0)?.map(({ close }) => close),
        borderColor: "rgba(50, 150, 200, 0.5)",
        backgroundColor: "rgba(50, 150, 200, 0.5)",
        tension: 0.3,
      },
      {
        // sma
        label: "SMA",
        data: data?.filter(({ sma }) => sma !== 0)?.map(({ sma }) => sma),
        backgroundColor: "rgba(200, 200, 200, 0.5)",
        borderColor: "rgba(200, 200, 200, 0.5)",
        tension: 0.3,
      },
    ],
  };
  return (
    <ChartFieldContainer>
      <ChartTitle>{title}</ChartTitle>
      <ChartField>
        <Line options={options} data={lineChartData} />
      </ChartField>
    </ChartFieldContainer>
  );
};
const TargetChart = ({ data, markData, title }) => {
  const labels = data?.map(({ time }) => time);
  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: false,
        ticks: {
          autoSkip: true,
          callback: function (value, index, ticks) {
            const date = new Date(labels[value]);
            return `${
              date.getMonth() + 1
            }/${date.getDate()} ${date.getHours()}`;
          },
        },
      },
      y: {
        beginAtZero: false,
      },
    },
    plugins: {
      legend: {
        labels: {
          filter: function (legendItem, chartData) {
            return legendItem.text !== "hidden";
          },
        },
      },
      zoom: {
        limits: {
          y: { min: 0 },
        },
        pan: {
          enabled: true,
          mode: "xy",
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          mode: "xy",
        },
      },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            const time = +tooltipItems[0]?.label;
            const date = new Date(time);
            return `${date.getFullYear()}/${
              date.getMonth() + 1
            }/${date.getDate()} ${date.getHours()}:00`;
          },
          label: (tooltipItem) => {
            const _time = +tooltipItem?.label;
            const _data = data?.filter(({ time }) => time === _time)[0];

            if (tooltipItem?.dataset?.label === "SMA")
              return `SMA: ${
                tooltipItem?.dataset?.data[tooltipItem?.dataIndex]
              }`;

            if (tooltipItem?.dataset?.label === "ATR")
              return `ATR: ${
                tooltipItem?.dataset?.data[tooltipItem?.dataIndex]
              }`;

            if (tooltipItem?.dataset?.label?.match(/open|close|raise|lessen/))
              return `close: ${_data?.close}`;

            return [
              `open: ${_data?.open}`,
              `close: ${_data?.close}`,
              `high: ${_data?.high}`,
              `close: ${_data?.close}`,
            ];
          },
        },
      },
    },
  };

  const lineChartData = {
    labels,
    datasets: [
      {
        // sma
        label: "SMA",
        data: data?.map(({ sma }) => (sma === 0 ? null : sma)),
        backgroundColor: "rgba(200, 200, 200, 0.5)",
        borderColor: "rgba(200, 200, 200, 0.5)",
        order: 2,
        tension: 0.3,
        spanGaps: true,
        type: "line",
      },
      // { // atr
      //     label: 'ATR',
      //     data: data?.map(({ atr }) => atr === 0 ? null : atr),
      //     backgroundColor: 'rgba(200, 150, 50, 0.5)',
      //     borderColor: 'rgba(200, 150, 50, 0.5)',
      //     order: 3,
      //     tension: 0.3,
      //     spanGaps: true,
      //     type: 'line',
      // },
      {
        // open, close
        label: "OHLC",
        data: data?.map(({ open, close }) => [open, close]),
        backgroundColor: data?.map(({ open, close }) =>
          open > close ? "rgba(150, 50, 50, 1)" : "rgba(50, 150, 50, 1)"
        ),
        order: 3,
        tension: 0.3,
        type: "bar",
        grouped: false,
      },
      {
        // high, low
        label: "hidden",
        data: data?.map(({ high, low }) => [high, low]),
        backgroundColor: data?.map(({ open, close }) =>
          open > close ? "rgba(250, 100, 100, 0.5)" : "rgba(100, 250, 100, 0.5)"
        ),
        order: 3,
        tension: 0.3,
        type: "bar",
        grouped: false,
        barThickness: 2,
        hidden: false,
      },
      {
        // mark open
        label: "open",
        data: markData
          ?.filter(({ action }) => action === 1)
          ?.map(({ history_create_time }) => {
            return {
              x: new Date(history_create_time).getTime(),
              y: data?.filter(
                ({ time }) => time === new Date(history_create_time).getTime()
              )[0]?.close,
            };
          }),
        pointBackgroundColor: "rgba(50, 200, 50)",
        pointBorderColor: "rgba(200, 250, 200)",
        pointBorderWidth: 2.5,
        pointRadius: 9,
        pointStyle: "triangle",
        pointHoverRadius: 7,
        order: 1,
        type: "scatter",
      },
      {
        // mark close
        label: "close",
        data: markData
          ?.filter(({ action }) => action === 2)
          ?.map(({ history_create_time }) => {
            return {
              x: new Date(history_create_time).getTime(),
              y: data?.filter(
                ({ time }) => time === new Date(history_create_time).getTime()
              )[0]?.close,
            };
          }),
        pointBackgroundColor: "rgba(255, 80, 80)",
        pointBorderColor: "rgba(255, 200, 200)",
        pointBorderWidth: 2.5,
        pointRadius: 9,
        rotation: 180,
        pointStyle: "triangle",
        pointHoverRadius: 7,
        order: 1,
        type: "scatter",
      },
      {
        // mark raise
        label: "raise",
        data: markData
          ?.filter(({ action }) => action === 3)
          ?.map(({ history_create_time }) => {
            return {
              x: new Date(history_create_time).getTime(),
              y: data?.filter(
                ({ time }) => time === new Date(history_create_time).getTime()
              )[0]?.close,
            };
          }),
        pointBackgroundColor: "rgba(50, 150, 250)",
        pointBorderColor: "rgba(200, 230, 250)",
        pointBorderWidth: 2.5,
        pointRadius: 9,
        pointStyle: "triangle",
        pointHoverRadius: 7,
        order: 1,
        type: "scatter",
      },
      {
        // mark lessen
        label: "lessen",
        data: markData
          ?.filter(({ action }) => action === 4)
          ?.map(({ history_create_time }) => {
            return {
              x: new Date(history_create_time).getTime(),
              y: data?.filter(
                ({ time }) => time === new Date(history_create_time).getTime()
              )[0]?.close,
            };
          }),
        pointBackgroundColor: "rgba(250, 250, 0)",
        pointBorderColor: "rgba(250, 250, 200)",
        pointBorderWidth: 2,
        pointRadius: 9,
        rotation: 180,
        pointStyle: "triangle",
        pointHoverRadius: 7,
        order: 1,
        type: "scatter",
      },
    ],
  };

  return (
    <ChartFieldContainer>
      <ChartTitle>{title}</ChartTitle>
      <ChartField>
        <Chart options={options} data={lineChartData} />
      </ChartField>
    </ChartFieldContainer>
  );
};
// #endregion

const TrendFollowingBt = ({ isMenuOpen }) => {
  const [loading, setLoading] = useState(true);
  // const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [data, setData] = useState([]);
  const [symbols, setSymbols] = useState([]);

  const [selectedSymbol, setSelectedSymbol] = useState("");
  const [chartData, setChartData] = useState(null);
  const [profitData, setProfitData] = useState(null);
  const [selectedProfitData, setSelectedProfitData] = useState(null);

  useEffect(() => {
    handleGetTrendFollowingBT();
  }, []);

  const handleGetTrendFollowingBT = async () => {
    const res = await API_TrendFollowingBTResult();
    if (res.ResultCode !== 0) return UseSwal(res.Message);

    setLoading(false);
    setSymbols(res.OrderHistorys.map(({ Symbol }) => Symbol));
    setData(res.OrderHistorys ?? []);
    setProfitData(res.profitFactorData ?? null);
  };

  const handleChangeSymbol = async (e, symbol) => {
    e.preventDefault();
    const selectedData = data?.filter(({ Symbol }) => Symbol === symbol)[0];
    const _start = selectedData.Historys[0].history_create_time;
    const _end =
      selectedData.Historys[selectedData.Historys.length - 1]
        .history_create_time;
    const startTimeStamp = new Date(_start).getTime();
    const endTimeStamp = new Date(_end).getTime();

    const res = await API_GetCustomRate_binance({
      symbol,
      startTime: startTimeStamp,
      endTime: endTimeStamp,
    });
    setChartData(res);
    setSelectedSymbol(symbol);
    setSelectedProfitData(
      data?.filter(({ Symbol }) => Symbol === symbol)[0]?.profitFactorData
    );
  };

  if (loading) return <Loading />;
  return (
    <Container className={isMenuOpen && "active"}>
      <MenuContainer className={isMenuOpen && "active"}>
        <div>
          {symbols?.map((symbol, index) => (
            <MenuField
              key={index}
              className={selectedSymbol === symbol && "active"}
              onClick={(e) => handleChangeSymbol(e, symbol)}
            >
              {symbol}
            </MenuField>
          ))}
        </div>
      </MenuContainer>

      <ChartContainer>
        <ChartWrapper>
          {!selectedSymbol && (
            // <NoSymbolSelected>No Symbol Selected</NoSymbolSelected>
            <SummaryContainer>
              <SummaryTitle>Summary</SummaryTitle>
              <SummaryTable>
                <tbody>
                  {Array.from(Object.keys(profitData)).map((key, index) => {
                    if (typeof profitData[key] === "object") return;
                    return (
                      <tr key={index}>
                        <td>{key}</td>
                        <td>{profitData[key]}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </SummaryTable>
            </SummaryContainer>
          )}
          {!!selectedSymbol && (
            <>
              <LineChart data={chartData?.BTCUSDT} title="BTCUSDT" />
              <TargetChart
                data={chartData[selectedSymbol]}
                markData={
                  data?.filter(({ Symbol }) => Symbol === selectedSymbol)[0]
                    .Historys
                }
                title={selectedSymbol}
              />
              <LineChart data={chartData?.ETHUSDT} title="ETHUSDT" />
            </>
          )}
        </ChartWrapper>
      </ChartContainer>

      <SideSummaryContainer>
        {!!selectedProfitData && (
          <>
            <SideSummaryFieldWrapper>
              <SideSummaryTitle>Symbol Profit</SideSummaryTitle>
              {Array.from(Object.keys(selectedProfitData))
                ?.filter((key) => key != "MDD")
                ?.map((key, index) => {
                  return (
                    <SideSummaryField key={index}>
                      <SideSummaryFieldTitle>
                        {key === "AverageTradeCompleteTime" ? "Time" : key}
                      </SideSummaryFieldTitle>
                      <div>
                        {key === "WinRate"
                          ? Math.floor(+selectedProfitData[key] / 100) + "%"
                          : selectedProfitData[key]}
                      </div>
                    </SideSummaryField>
                  );
                })}
            </SideSummaryFieldWrapper>

            <SideSummaryFieldWrapper>
              <SideSummaryTitle>Summary Profit</SideSummaryTitle>
              {Array.from(Object.keys(profitData))
                ?.filter((key) => key != "MDD")
                ?.map((key, index) => {
                  return (
                    <SideSummaryField key={index}>
                      <SideSummaryFieldTitle>
                        {key === "AverageTradeCompleteTime" ? "Time" : key}
                      </SideSummaryFieldTitle>
                      <div>
                        {key === "WinRate"
                          ? Math.floor(+selectedProfitData[key] / 100) + "%"
                          : selectedProfitData[key]}
                      </div>
                    </SideSummaryField>
                  );
                })}
            </SideSummaryFieldWrapper>
          </>
        )}
      </SideSummaryContainer>
    </Container>
  );
};

export default TrendFollowingBt;
