import styled from "styled-components";
import { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Icons from "../../global/icons";
import { routes } from "../../../components/Router";

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  color: #151944;
`;

const Container = styled.div`
  background-color: #fcfcfc;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  height: 100px;
  padding: 20px;
  /* min-height: 35.416vw; */
  height: auto;
  padding: 2.088vw 4.166vw;
  width: 41.66vw;
  border-radius: 8px;

  ${RWD_LG(css`
    width: 50%;
  `)};

  ${RWD_MD(css`
    width: 60%;
    padding: 20px 40px;
  `)};

  ${RWD_SM(css`
    width: 90%;
    padding: 20px;
  `)};
`;

const Title = styled.div`
  padding-top: 20px;
  font-size: min(max(1.25vw, 22px), 24px);
  font-weight: bold;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 50px;
  width: 80%;

  ${RWD_SM(css`
    width: 100%;
    /* width: auto; */
    padding-top: 25px;
    /* justify-content: center; */
  `)};
`;

const Button = styled.div`
  width: min(max(7.0833vw, 100px), 135px);
  border-radius: 60px;
  border: 1px solid;

  color: ${(props) => (props.type === "confirm" ? "#fcfcfc" : "#151944")};
  background-color: ${(props) =>
    props.type === "confirm" ? (props.disabled ? "#d9d9d9" : "#151944") : ""};
  cursor: pointer;
  border-color: ${(props) => (props.disabled ? "#d9d9d9" : "#151944")};

  /* &:last-child {
    margin-left: 1.5625vw;
  } */
`;

const ButtonText = styled.div`
  padding: 0.52vw 1.3vw;
  text-align: center;
  font-size: min(max(1.0416vw, 18px), 20px);

  ${RWD_LG(css`
    padding: 8px 18px;
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const IconWrapper = styled.div`
  display: inline-flex;
  width: 100px;
  height: 100px;
  align-items: center;
  justify-content: center;

  svg {
    width: 100px;
    height: 100px;
  }

  & path {
    fill: #f05d2f;
  }

  ${RWD_SM(css`
    width: 80px;
    height: 80px;
    svg {
      width: 80px;
      height: 80px;
    }
  `)};
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid blue; */
`;

const ErrorModal = ({ type, setErrorModalIsOpen, errorAlgo }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const errorText = [
    {
      statusType: "ApiKeyInvalid",
      text: "ApiKey 不合法",
    },
    {
      statusType: "WalletInsufficient",
      text: "錢包餘額不足",
    },
    {
      statusType: "AvaPointInsufficient",
      text: "Ava Ponit 不足",
    },
    {
      statusType: "AvaPointLessThenMinimum",
      text: "Ava Point 不足",
    },
    {
      statusType: "AvaPointLessThenRunningFee",
      text: "Ava Point 餘額不足，低於投資總額的5%，您的帳戶需要儲值更多的AvaPoint",
    },
  ];

  const handleClose = () => {
    // 請除url query
    setErrorModalIsOpen(false);
    searchParams.delete("deposit");
    searchParams.delete("invalid");
    setSearchParams(searchParams);
  };
  const redirect = (type) => {
    setErrorModalIsOpen(false);

    switch (type) {
      case "AvaPointInsufficient":
      case "AvaPointLessThenMinimum":
      case "AvaPointLessThenRunningFee":
        return navigate({
          pathname: routes.algo_v2,
          search: "?deposit=avapoint",
        });

      case "ApiKeyInvalid":
        return navigate({
          pathname: routes.setting_v2,
          search: `?invalid=apikey_${errorAlgo.Exchange}`,
        });

      default:
        break;
    }
  };
  return (
    <Wrapper>
      <Container>
        <IconWrapper>
          <Icons.Error />
        </IconWrapper>
        {(() => {
          switch (type) {
            case "ApiKeyInvalid":
              return errorText
                .filter(({ statusType }) => statusType === type)
                .map((data) => (
                  <ContentWrapper key={data.statusType}>
                    <Title>{data.text}</Title>
                    <ButtonWrapper>
                      <Button onClick={handleClose}>
                        <ButtonText>我了解了</ButtonText>
                      </Button>
                      <Button
                        type={"confirm"}
                        onClick={() => redirect(data.statusType)}
                      >
                        <ButtonText>前往更新</ButtonText>
                      </Button>
                    </ButtonWrapper>
                  </ContentWrapper>
                ));
            case "WalletInsufficient":
              return errorText
                .filter(({ statusType }) => statusType === type)
                .map((data) => (
                  <ContentWrapper key={data.statusType}>
                    <Title>{data.text}</Title>
                    <ButtonWrapper>
                      <Button onClick={handleClose}>
                        <ButtonText>我了解了</ButtonText>
                      </Button>
                      {/* <Button
                        type={"confirm"}
                        onClick={() => redirect(data.statusType)}
                      >
                        <ButtonText>前往儲值</ButtonText>
                      </Button> */}
                    </ButtonWrapper>
                  </ContentWrapper>
                ));
            case "AvaPointInsufficient":
            case "AvaPointLessThenMinimum":
            case "AvaPointLessThenRunningFee":
              return errorText
                .filter(({ statusType }) => statusType === type)
                .map((data) => (
                  <ContentWrapper key={data.statusType}>
                    <Title>{data.text}</Title>
                    <ButtonWrapper>
                      <Button onClick={handleClose}>
                        <ButtonText>我了解了</ButtonText>
                      </Button>
                      <Button
                        type={"confirm"}
                        onClick={() => redirect(data.statusType)}
                      >
                        <ButtonText>前往儲值</ButtonText>
                      </Button>
                    </ButtonWrapper>
                  </ContentWrapper>
                ));
            default:
              return null;
          }
        })()}
      </Container>
    </Wrapper>
  );
};

export default ErrorModal;
