import styled, { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../../utils/rwd";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectSystem } from "../../../../slices/systemSlice";
import { useGetSentimentAnalysisQuery } from "../../../../services/datacenterApi";

import Icons from "../../../../global/icons";
import Lang from "../../../../global/Lang";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Wrapper = styled.div`
  color: #fcfcfc;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 5px;
  background-color: #22254a;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 95%;
  padding: 15px 0;
`;

const TitleIcon = styled.div`
  width: 25px;
  height: 25px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 25px;
    height: 25px;
  }
`;

const SubTitle = styled.div`
  font-size: min(max(1.0416vw, 18px), 20px);
  color: #fcfcfc;
  padding-left: 10px;

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 16px), 18px);
  `)};
`;

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ChartDate = styled.div`
  padding-bottom: 10px;
  width: 100%;
`;

const Chart = styled.div`
  width: 100%;

  padding-bottom: 20px;
`;

const ChartContainer = styled.div`
  display: flex;
  width: 95%;
  /* padding-bottom: 40px; */
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.15);

  &:first-child {
    border-top: 0;
  }
`;

const SentimentAnalysis = () => {
  const [chartData, setChartData] = useState([]);
  const [barData, setBarData] = useState([]);
  const { data, error, isError, isSuccess } = useGetSentimentAnalysisQuery();
  const lang = useSelector(selectSystem).language;
  const [language, setLanguage] = useState("");

  useEffect(() => {
    setLanguage(lang);
  }, [lang]);

  //#1cac2a 綠色
  // #F05D2F 紅色

  useEffect(() => {
    if (isSuccess) {
      setChartData(data?.SentimentAnalysisGroup);

      setBarData(
        data?.SentimentAnalysisGroup.map((item) => {
          return {
            labels: item?.Data?.map((bar) => bar.Ticker),
            datasets: [
              {
                label: language === "En" ? "Positive" : "積極",
                backgroundColor: "rgba(28,172,42, 0.8)",
                data: item?.Data?.map((bar) => bar.TotalPositive),
              },
              {
                label: language === "En" ? "Neutral" : "中立",
                backgroundColor: "rgba(190, 190, 190, 0.8)",
                data: item?.Data?.map((bar) => bar.TotalNeutral),
              },
              {
                label: language === "En" ? "Negivate" : "消極",
                backgroundColor: "rgba(240,93,47,0.8)",
                data: item?.Data?.map((bar) => bar.TotalNegivate),
              },
            ],
          };
        })
      );
      // setChartLabel(data?.Data.map((item) => item?.Ticker));
    }
  }, [data, isError]);

  const options = {
    responsive: true,

    scales: {
      x: {
        stacked: true,
        ticks: {
          color: "#fcfcfc",
        },
      },
      y: {
        stacked: true,
        ticks: {
          color: "#fcfcfc",
        },
      },
    },
    plugins: {
      title: {
        display: false,
        text: "Sentiment Analysis",
        color: "#fcfcfc",
      },
      legend: {
        labels: {
          color: "#ccc",
        },
      },
    },
  };

  return (
    <Wrapper>
      <Container>
        <TitleWrapper>
          <TitleIcon>
            <Icons.DataCenter_BarChart />
          </TitleIcon>
          <SubTitle>
            <Lang
              datakey="datacenter_marketdata_sentimentanalysis"
              defaultText="Sentiment Analysis"
            />
          </SubTitle>
        </TitleWrapper>
        <ChartWrapper>
          {chartData.map((data, index) => (
            <ChartContainer key={index}>
              <ChartDate>
                <Lang datakey="time" defaultText="Time" />
                {`： ${data.Date?.split(" ")[0].split("/")[2]}/${
                  data.Date?.split(" ")[0].split("/")[0]
                }/${data.Date?.split(" ")[0].split("/")[1]} ${
                  data.Date?.split(" ")[1]
                }`}
              </ChartDate>
              <Chart>
                <Bar options={options} data={barData[index]} />
              </Chart>
            </ChartContainer>
          ))}
        </ChartWrapper>
      </Container>
    </Wrapper>
  );
};

export default SentimentAnalysis;
