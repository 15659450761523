import { css } from "styled-components";

export const RWD_XL = (inner) => css`
  @media screen and (max-width: 1280px) {
    ${inner};
  }
`;

export const RWD_LG = (inner) => css`
  @media screen and (max-width: 1024px) {
    ${inner};
  }
`;

// ipad pro 11寸
export const RWD_LL = (inner) => css`
  @media screen and (max-width: 834px) {
    ${inner};
  }
`;

export const RWD_MD = (inner) => css`
  @media screen and (max-width: 768px) {
    ${inner};
  }
`;
// iPhone 14 Pro Max
export const RWD_SM = (inner) => css`
  @media screen and (max-width: 430px) {
    ${inner};
  }
`;

export const RWD_SS = (inner) => css`
  @media screen and (max-width: 320px) {
    ${inner};
  }
`;

export const RWD_XS = (inner) => css`
  @media screen and (max-width: 319px) {
    ${inner};
  }
`;
