import ServicesShortcut from './ServicesShortcut'

const Main = ({ handleCheckStatus }) => {
  return (
    <div className='flex-[3] xl:pr-10'>
      <ServicesShortcut handleCheckStatus={handleCheckStatus} />
    </div>
  )
}

export default Main
