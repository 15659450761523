import { useEffect, useState, Fragment } from "react";
import Icons from "./v2/global/icons";
import styled, { css } from "styled-components";
import { LangString } from "./v2/utils/index";
import { PwaMySwal, InfoMySwal } from "./v2/global/MySwal";
import Lang from "./v2/global/Lang";

import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "./constants/rwd";

import { ChekcUserAgent } from "./v2/utils/index";

const ShortcutWrapper = styled.div`
  color: #fcfcfc;
  width: 100%;
  margin-top: auto;
  padding-bottom: 20px;

  ${RWD_LG(css`
    padding-bottom: 120px;
  `)};
`;

const Shortcut = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: min(max(0.9375vw, 14px), 18px);

  ${RWD_LG(css`
    flex-direction: row;
    width: 100%;
  `)};

  ${RWD_SM(css`
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const ShortcutText = styled.div`
  text-align: center;
  white-space: nowrap;
`;

const ShortcutIcon = styled.div`
  width: 20px;
  height: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  svg {
    width: 20px;
    height: 20px;
  }

  ${RWD_LG(css`
    width: 30px;
    height: 30px;

    svg {
      width: 30px;
      height: 30px;
    }
  `)};

  ${RWD_SM(css`
    width: 25px;
    height: 25px;

    svg {
      width: 25px;
      height: 25px;
    }
  `)};
`;

const PWAInstallPrompt = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleBeforeInstallPrompt = (event) => {
    event.preventDefault();
    setDeferredPrompt(event);
    console.log(`'beforeinstallprompt' event was fired.`);
  };

  const handleInstallClick = () => {
    // 支援並尚未安裝
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User added to home screen");
        } else {
          console.log("User cancelled home screen install");
        }

        setDeferredPrompt(null);
      });
    } else {
      // 抓不到事件 => 不支援（ios系統）
      if (ChekcUserAgent() === "iOS") {
        // 多國語言
        const title = LangString("shortcut_install_pwa", "Install AVA Web App");
        const confirmText = LangString("confirm", "Confirm");
        PwaMySwal({
          title: title,
          confirmButtonText: confirmText,
        });
      } else {
        // 多國語言
        const title = LangString("shortcut_install_pwa", "Install AVA Web App");
        const text = LangString(
          "shortcut_install_completed",
          "You have already completed the creation process. Please open the AVA shortcut on your desktop"
        );
        const confirmText = LangString("confirm", "Confirm");
        // 抓不到事件 => 已經安裝過
        InfoMySwal({
          title: title,
          text: text,
          showCancelButton: false,
          confirmButtonText: confirmText,
        });
      }
    }
  };

  return (
    <ShortcutWrapper>
      <Shortcut onClick={handleInstallClick}>
        <ShortcutIcon>
          <Icons.Shortcut />
        </ShortcutIcon>
        <ShortcutText>
          <Lang datakey="menu_createshortcut" defaultText="Create Shortcut" />
        </ShortcutText>
      </Shortcut>

      {/* {deferredPrompt && <button>Click</button>} */}
      {/* <button onClick={handleInstallClick}>Click</button> */}
    </ShortcutWrapper>
  );
};

export default PWAInstallPrompt;
