import { verifications } from '../../../utils/verifications';
import { StyledCard } from '../../Global/Ui';

const Withdrawal = ({ data }) => {
  const { user, totalWithdralPoints } = data;
  if (!!user) return;

  return (
    <StyledCard
      title='Withdrawal'
      titleClass='text-center'
    >
      {/* title */}
      {/* <h3 className='
        relative py-2 text-[0.875rem] font-bold text-center text-blue
        before:absolute before:block before:w-full before:h-[3px] before:bottom-0 before:left-0
        before:bg-sub-blue before:rounded
      '>
        Withdrawal
      </h3> */}
      {/* sub title */}
      <p className='pt-5 pb-3 text-[0.875rem] font-bold text-blue'>
        AVA POINTS
      </p>
      <span className='text-[1.5rem] font-bold'>
        $ {totalWithdralPoints?.toFixed(2).replace(verifications.currency, ',') || '0.00'}
      </span>
    </StyledCard>
  )
}

export default Withdrawal
