import styled from "styled-components";
import { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";
import { useNavigate } from "react-router-dom";
import Icons from "../../global/icons";
import { routes } from "../../../components/Router";

import { SuccessMySwal } from "../../global/MySwal";

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  color: #151944;
`;

const Container = styled.div`
  background-color: #fcfcfc;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  height: 100px;
  padding: 20px;
  /* min-height: 35.416vw; */
  height: auto;
  padding: 2.088vw 4.166vw;
  width: 41.66vw;
  border-radius: 8px;

  ${RWD_LG(css`
    width: 50%;
  `)};

  ${RWD_MD(css`
    width: 60%;
    padding: 20px 40px;
  `)};

  ${RWD_SM(css`
    width: 90%;
    padding: 20px;
  `)};
`;

const IconWrapper = styled.div`
  display: inline-flex;
  width: 100px;
  height: 100px;
  align-items: center;
  justify-content: center;

  svg {
    width: 100px;
    height: 100px;
  }

  & path {
    fill: ${(props) => (props.type === "default" ? "#e89924 " : "  #1CAC2A")};
  }

  ${RWD_SM(css`
    width: 80px;
    height: 80px;
    svg {
      width: 80px;
      height: 80px;
    }
  `)};
`;

const Title = styled.div`
  padding-top: 20px;
  font-size: min(max(1.25vw, 22px), 24px);
  font-weight: bold;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 50px;
  width: 80%;

  ${RWD_SM(css`
    width: 100%;
    /* width: auto; */
    padding-top: 25px;
    /* justify-content: center; */
  `)};
`;

const Button = styled.button`
  width: min(max(7.0833vw, 100px), 135px);
  border-radius: 60px;
  border: 1px solid;

  color: ${(props) => (props.type === "confirm" ? "#fcfcfc" : "#151944")};
  background-color: ${(props) =>
    props.type === "confirm" ? (props.disabled ? "#d9d9d9" : "#151944") : ""};
  cursor: pointer;
  border-color: ${(props) => (props.disabled ? "#d9d9d9" : "#151944")};
`;

const ButtonText = styled.div`
  padding: 0.52vw 1.3vw;
  text-align: center;
  font-size: min(max(1.0416vw, 18px), 20px);

  ${RWD_LG(css`
    padding: 8px 18px;
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const MessageWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
`;

const MessageContainer = styled.div``;

const SubTitle = styled.div`
  padding-top: 20px;
  font-size: min(max(1.0416vw, 18px), 20px);
  font-weight: bold;
  text-align: center;
`;

const TelegramModal = ({
  setTelegramModalIsOpen,
  isTelegramVerified,
  type,
  subscribeFun,
  entrustId,
  subscribe,
}) => {
  console.log(type);
  const navigate = useNavigate();
  const redirect = () => {
    navigate({
      pathname: routes.setting_v2,
      search: "?verified=telegram",
    });
  };

  const toggleSubscribe = () => {
    subscribeFun({
      body: {
        EntrustId: entrustId,
      },
    });
  };

  return (
    <Wrapper>
      <Container>
        <IconWrapper type={type}>
          {type === "success" || type === "copy" ? (
            <Icons.Success />
          ) : (
            <Icons.Warning />
          )}
        </IconWrapper>

        {(() => {
          switch (type) {
            case "success":
              return (
                <MessageWrapper>
                  <MessageContainer>
                    <Title>Algo 啟動成功</Title>

                    {isTelegramVerified ? (
                      subscribe ? (
                        ""
                      ) : (
                        <SubTitle>是否開啟 Telegram 通知</SubTitle>
                      )
                    ) : (
                      <SubTitle>尚未驗證 Telegram，是否前往驗證" </SubTitle>
                    )}
                  </MessageContainer>

                  {isTelegramVerified ? (
                    subscribe ? (
                      <ButtonWrapper>
                        <Button
                          type={"confirm"}
                          onClick={() => setTelegramModalIsOpen(false)}
                        >
                          <ButtonText>確認</ButtonText>
                        </Button>
                      </ButtonWrapper>
                    ) : (
                      <ButtonWrapper>
                        <Button onClick={() => setTelegramModalIsOpen(false)}>
                          <ButtonText>否</ButtonText>
                        </Button>
                        <Button type={"confirm"} onClick={toggleSubscribe}>
                          <ButtonText>是</ButtonText>
                        </Button>
                      </ButtonWrapper>
                    )
                  ) : (
                    <ButtonWrapper>
                      <Button onClick={() => setTelegramModalIsOpen(false)}>
                        <ButtonText>否</ButtonText>
                      </Button>
                      <Button type={"confirm"} onClick={redirect}>
                        <ButtonText>是</ButtonText>
                      </Button>
                    </ButtonWrapper>
                  )}
                </MessageWrapper>
              );
            case "copy":
              return (
                <MessageWrapper>
                  <MessageContainer>
                    <Title>Algo 複製成功</Title>
                  </MessageContainer>
                  <ButtonWrapper>
                    <Button
                      type={"confirm"}
                      onClick={() => setTelegramModalIsOpen(false)}
                    >
                      <ButtonText>確認</ButtonText>
                    </Button>
                  </ButtonWrapper>
                </MessageWrapper>
              );
            case "default":
              return (
                <MessageWrapper>
                  <MessageContainer>
                    <Title>尚未驗證 Telegram</Title>
                  </MessageContainer>
                  <ButtonWrapper>
                    <Button onClick={() => setTelegramModalIsOpen(false)}>
                      <ButtonText>我了解了</ButtonText>
                    </Button>
                    <Button type={"confirm"} onClick={redirect}>
                      <ButtonText>前往驗證</ButtonText>
                    </Button>
                  </ButtonWrapper>
                </MessageWrapper>
              );
          }
        })()}
      </Container>
    </Wrapper>
  );
};

export default TelegramModal;
