import styled, { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";
import { useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { selectSystem } from "../../slices/systemSlice";
import { useEffect } from "react";
import Banner from "../../images/terms.svg";
import Lang from "../../global/Lang";

const Wrapper = styled.div`
  height: 100%;
  padding-bottom: 100px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: #22254a;
  padding-top: 20px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 20px;
`;

const Title = styled.div`
  font-size: min(max(1.25vw, 22px), 24px);
  color: #fcfcfc;
  padding-right: 10px;
  text-transform: capitalize;

  ${RWD_MD(css`
    font-size: min(max(1.145vw, 20px), 22px);
  `)};
`;

const ImgWrapper = styled.div`
  width: 100px;
  height: auto;
  display: inline-flex;
  position: absolute;
  bottom: -10px;
  right: 0px;

  svg {
    width: 100px;
  }
`;

const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #3a3d5a;
  position: relative;
  width: calc(100% - 80px);

  ${RWD_SM(css`
    width: calc(100% - 40px);
  `)};
`;

const TabContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const Tab = styled.div`
  padding: 15px 20px;
  cursor: pointer;

  border-bottom: ${(props) =>
    props.activeTab ? "2px solid #0aecd1" : "1px solid trasparent"};
`;

const TabText = styled.div`
  text-transform: capitalize;
  color: ${(props) => (props.activeTab ? "#fcfcfc" : "#3a3d5a")};
`;

const DefinitionsWrapper = styled.div`
  /* font-size: min(max(1.145vw, 20px), 22px); */
  font-family: Montserrat;
  font-weight: bold;
  padding-bottom: 10px;
  color: #fcfcfc;
`;

const ContentWrapper = styled.div`
  padding-top: 50px;

  padding: 30px 40px;
  width: 100%;

  ${RWD_SM(css`
    padding: 30px 20px;
  `)};
`;

const ContentContainer = styled.div`
  color: #fcfcfc;
  padding: 10px 0 20px 0;
`;

const TermsTitleWrapper = styled.div`
  font-size: min(max(1.145vw, 20px), 22px);
  border-bottom: 1px solid #3a3d5a;
  font-family: Montserrat;
  font-weight: bold;
  padding-bottom: 10px;
`;

const TermsWrapper = styled.ul`
  color: #d3d3d3;
  line-height: 1.5;
  text-align: justify;
  padding-top: 15px;
`;

const TermsItem = styled.li``;

const TermsDetailWrapper = styled.ul`
  width: 95%;
  margin: 0 auto;
  text-align: justify;
  padding-top: 15px;
  list-style-type: lower-alpha;
  list-style-position: inside;
  color: #d3d3d3;
`;

const TermsDetailItem = styled.div`
  padding-bottom: 10px;
`;

const TermsDetailTitle = styled.li`
  padding-bottom: 10px;
`;

const TermsOfService = () => {
  const tabList = ["user", "algo", "mining"];
  const [activeTab, setActiveTab] = useState("user");

  const lang = useSelector(selectSystem).language;
  const [language, setLanguage] = useState("");

  useEffect(() => {
    setLanguage(lang);
  }, [lang]);

  // algo
  const algoTerms = [
    {
      id: 1,
      title: [
        {
          ch: "⾵險提⽰",
          en: "Risk Warning",
        },
      ],
      content: [
        {
          ch: [
            "數位資產交易存在重⼤⾵險，數位資產價值可能會劇烈波動，您可能須承受重⼤損失。您應根據您的財務狀況和投資⽬標，仔細考慮是否適合進⾏此類交易。",
            "進行交易或投資決定均屬於您本人，您確認您已瞭解投資數位資產的⾵險，並將對您的投資決定及因此產⽣的任何損失或責任承擔全部責任。",
            "您必須細閱本平台之使用守則或手册，包括有關平台內容、顯示、指示及運作，本平台會盡力解答您的問題，但您亦有責任細閱本平台之使用守則或手册。",
          ],
          en: [
            "Digital asset trading involves significant risks. The value of digital assets can fluctuate greatly, potentially leading to substantial losses. You should carefully consider whether such trading is suitable for you based on your financial situation and investment objectives.",
            "Trading or investment decision is yours. You acknowledge that you understand these risks in relation to digital asset trading and will assume full responsibility for your decisions and any resulting losses or liabilities.",
            "You must read the user guideline or manual of this Platform carefully, including the content, display, instructions and operation of the Platform. The Platform will endeavour to answer any questions you may have, but it is also your responsibility to read the Platform's user guideline or manual carefully.",
          ],
        },
      ],
    },
    {
      id: 2,
      title: [
        {
          ch: "Algo啟動與交易",
          en: "Algo Activation and Trading",
        },
      ],
      content: [
        {
          ch: [
            "Algo為透過電腦程式來⾃動執行數位資產交易之服務 (以下稱「本服務」)。",
            "啟動Algo代表⽤⼾同意允許程式透過運算⾃動交易⽤⼾所設定之額度。所有盈虧由使⽤者⾃⾏承擔。",
          ],
          en: [
            "Algo refers to the service of using computer programs to automate the process of trading digital assets  (the “Service”)",
            "Activating Algo signifies the user’s agreement to allow the program to automatically trade the amount set by the user through calculations. All profits and losses are borne by the user.",
          ],
        },
      ],
    },
    {
      id: 3,
      title: [
        {
          ch: "財務往來與轉帳授權",
          en: "Financial Transactions and Transfer Authorization",
        },
      ],
      content: [
        {
          ch: [
            "本服務運⾏絕不涉及我們與客⼾之間的財務往來，我們亦沒有獲得任何形式的轉帳授權。服務費⽤僅透過 AVA Point收取，此為我們收取⼿續費的唯⼀⽅式。任何關於本⾦顯⽰的問題應被視為純粹的顯⽰問題。本服務不涉及任何資⾦。客⼾應以其帳⼾內顯⽰的餘額為依據。",
          ],
          en: [
            "This service does not involve any financial transactions with customers and has not been granted any form of transfer authorization. Service fees are solely collected through AVA Points, which is the only method for profit charges. Any issues related to the display of principal should be considered purely display issues. This service does not hold any funds. Customers should base their accurate amounts on the balance displayed in their accounts.",
          ],
        },
      ],
    },
    {
      id: 4,
      title: [
        {
          ch: "Algo產⽣獲利時之收費",
          en: "Fees on Profits Generated by Algo",
        },
      ],
      content: [
        {
          ch: [
            "若Algo產⽣獲利，我們將收取20%的AVA POINT作為系統⼿續費（詳⾒下列收費規則）。",
          ],
          en: [
            "If Algo generates profit, a 20% AVA POINT will be charged as a system handling fee (refer to fee rules for details).",
          ],
        },
      ],
    },
    {
      id: 5,
      title: [
        {
          ch: "Algo異常情況處理",
          en: "Handling of Algo Execution Anomalies",
        },
      ],
      content: [
        {
          ch: [
            "若Algo執⾏時發⽣問題， 如餘額異常、API KEY失效等異常情況或⽤⼾⼿動操作，系統將強制暫停所有正在執⾏中的Algo，並即時平倉所有交易，所有收續費按我們收費規則結算。",
          ],
          en: [
            "If anomalies such as balance irregularities, invalid API KEYS, or manual user operations occur during Algo execution, all Algos in operation will be forcibly paused, all trades will be immediately closed, and all fees will be settled according to fee rules set out below.",
          ],
        },
      ],
    },
    {
      id: 6,
      title: [
        {
          ch: "Algo⼿動暫停與費⽤結算",
          en: "Manual Pause of Algo and Fee Settlement",
        },
      ],
      content: [
        {
          ch: [
            "若Algo經使⽤者⼿動暫停，系統將依實時⾏情及訂單記錄結算所有收續費。所有Algo的交易皆可通過交易紀錄查閱。",
          ],
          en: [
            "If Algo is manually paused by the user, all fees will be settled based on real-time market conditions and order records. All Algo trades can be viewed through the transaction history record.",
          ],
        },
      ],
    },
    {
      id: 7,
      title: [
        {
          ch: "收費規則",
          en: "Fee Rules",
        },
      ],
      content: [
        {
          ch: [
            "收費⽅式⼀律為收取AVA POINT，1 AVA POINT 相等於 1 USDT。啟動任何Algo時，帳⼾需持有⾄少 100 AVA POINT。若啟動的Algo總額超過5000 USDT，則需⾄少有運⾏總額5%的AVA POINT。若累計獲利超過初始數額，則會扣除該筆交易收益的20% AVA POINT作為⼿續費。每個Algo的收費會獨⽴計算，彼此不受影響。",
          ],
          en: [
            "The fee is uniformly charged in AVA POINTS, where 1 AVA POINT = 1 USDT. To activate any Algo, the account must have at least 100 AVA POINTS. If the total amount activated in Algo exceeds 5000 USDT, at least 5% of the total operation amount in AVA POINTS is required. If cumulative profits exceed the initial amount, 20% of the profit from that transaction will be deducted as a handling fee in AVA POINTS. Each Algo’s fee calculation is independent and does not affect each other.",
          ],
        },
      ],
    },
    {
      id: 8,
      title: [
        {
          ch: "Algo停⽌與費⽤結算",
          en: "Algo Stoppage and Fee Settlement",
        },
      ],
      content: [
        {
          ch: [
            "Algo被暫停後，系統將⽴即結清所有費⽤，其獲利或虧損紀錄將不會累計、保留或轉移⾄下⼀個或別的Algo計算。Algo之暫停可能是因使⽤者⼿動操作或錯誤（例如餘額異常/API KEY異常/AVA POINT不⾜）而引起。",
          ],
          en: [
            "After Algo is stopped, all fees will be immediately settled, and profit or loss records cannot be accumulated, retained, or transferred to calculate for the next or another Algo. This stoppage may be triggered by user manual operation or errors (e.g., balance irregularities/API KEY anomalies/insufficient AVA POINTS).",
          ],
        },
      ],
    },
    {
      id: 9,
      title: [
        {
          ch: "AVA Point最低需求與不⾜時的處理",
          en: "Minimum AVA Point Requirements and Handling of Insufficiencies",
        },
      ],
      content: [
        {
          ch: [
            "當Algo運⾏時， 系統將即實時運算閣下持有的AVA POINT是否滿⾜最低需求。若AVA POINT不⾜，系統會強制暫停所有Algo，並依收費規則結算所有收費。若AVA POINT結算後出現負值，閣下增值時, 該負值會被優先扣除。",
          ],
          en: [
            "During the operation of Algo, the sufficiency of AVA POINTS will be calculated in real-time. If insufficient, all Algos will be forcibly paused, and all fees will be settled according to the fee rules . If a negative value occurs after AVA POINT settlement, it will be deducted first after top-up.",
          ],
        },
      ],
    },
    {
      id: 10,
      title: [
        {
          ch: "通知⽅式",
          en: "Notification Methods",
        },
      ],
      content: [
        {
          ch: [
            "關於API KEY逾期或其他重要通知，我們將透過Telegram及Email⽅式通知客⼾。",
          ],
          en: [
            "Customers will be notified via Telegram and Email about API KEY expiration or other important notifications.",
          ],
        },
      ],
    },
    {
      id: 11,
      title: [
        {
          ch: "退款政策",
          en: "Refund Policy",
        },
      ],
      content: [
        {
          ch: ["客⼾可在AVA Point累計達到100點時提出退款申請。"],
          en: [
            "Customers can apply for a refund when AVA Points accumulate to 100 points.",
          ],
        },
      ],
    },
    {
      id: 12,
      title: [
        {
          ch: "退款政策",
          en: "Record Inquiry",
        },
      ],
      content: [
        {
          ch: ["客⼾可在AVA Point累計達到100點時提出退款申請。"],
          en: [
            "All charges can be viewed and confirmed through the payment record.",
          ],
        },
      ],
    },
    {
      id: 13,
      title: [
        {
          ch: "條款之修改",
          en: "Modification of Terms",
        },
      ],
      content: [
        {
          ch: [
            "我們保留隨時修改本條款的權利。所有修改將通過電⼦郵件或我們的網站通知閣下。凡繼續使⽤我們的服務即表⽰您接受這些新條款。",
          ],
          en: [
            "We reserve the right to modify these terms at any time. Changes will be notified via email or on our website. Continued use of our services indicates your acceptance of the new terms.",
          ],
        },
      ],
    },
    {
      id: 14,
      title: [
        {
          ch: "禁止的行為",
          en: "Prohibited Activity",
        },
      ],
      content: [
        {
          ch: [
            "閣下禁止從事任何我們在絶對酌情決定權下被視為不恰當及╱或將被視為非法的行為或被任何適用於本網站的法律所禁止的行為，包括但不限於：-",
          ],
          en: [
            "You are prohibited from doing any act that we in its absolute discretion may deem to be inappropriate and/or would be deemed to be an unlawful act or is prohibited by any laws applicable to this website including but not limited to: -",
          ],
        },
      ],
      detail: [
        {
          ch: [
            {
              detailTitle:
                "利用我們的網站及/或服務進行任何詐欺或其他違法行為；",
            },
            {
              detailTitle:
                "擅自入侵、訪問、使用或試圖入侵、訪問或使用我們的伺服器的任何其他部份以及／或任何數據區；",
            },
            {
              detailTitle:
                "未得我們書面批准﹙除非我們在本網站中另有訂明，或我們另外給予特許﹚而張貼、發佈、傳送、複製、分發或以任何方式利用從本網站獲得的任何資料作商業用途；",
            },
            {
              detailTitle:
                "發布任何被視為違法的、具有恐嚇成份的、誹謗的、欺詐的、淫褻的或其他令人反感的內容，或其他可能侵犯第三方權益 (如私隱權和知識財產權) 的內容；",
            },
            {
              detailTitle:
                "使用任何機器人軟件、蜘蛛軟件、網站搜尋或檢索應用程式或任何其他方法或工具來複製、檢索、存檔我們網站的任何部分。",
            },
          ],
          en: [
            {
              detailTitle:
                "commit fraud or other illegal acts through our website or services;",
            },
            {
              detailTitle:
                "trespass, break into, access, use or attempt to trespass, break into, access or use any other parts of our servers, and/or any data areas for which you have not been authorized by us;",
            },
            {
              detailTitle:
                "post, publish, transmit, reproduce, distribute or in any way exploit any Information obtained through this website for commercial purposes without our prior written permission (unless otherwise specified by us in this website or pursuant to separate licence granted by us);",
            },
            {
              detailTitle:
                "post any content that is being considered as unlawful, threatening, defamatory, fraudulent, obscene or otherwise objectionable, or because it may infringe or violate any other third party’s rights such as privacy and Intellectual Property Rights;",
            },
            {
              detailTitle:
                "use any robot, spider, site search or retrieval application, or any other method or device to copy, retrieve, archive or index any portion of our site.",
            },
          ],
        },
      ],
    },
    {
      id: 15,
      title: [
        {
          ch: "聲明與保證",
          en: "Representations and Warranties",
        },
      ],
      content: [
        {
          ch: ["凡您使⽤我們的服務，即表示閣下保證及聲明閣下：-"],
          en: [
            "By using our accessing or using services, you represent and warrant to us, at all times, the following: -",
          ],
        },
      ],
      detail: [
        {
          ch: [
            {
              detailTitle:
                "是具備充分權力、權限和能力的個人、公司、法律實體或其他組織，可以使用我們的服務及簽訂本條款並遵守您在本條款中的義務；",
            },
            {
              detailTitle:
                "如果您是法律實體，則您在所屬司法管轄區正式註冊、正式成立並有效存續，並且具有處理事務的全部權利。如果您是個人，則您不少於18歲；",
            },
            {
              detailTitle:
                "如果您是法律實體的員工或代理人並代表其簽署本條款，則您必須獲得作為其代表的正式授權並使實體因簽訂本條款而受其約束；",
            },
            {
              detailTitle:
                "您清楚了解本協議和數位資產交易的性質和風險，並且有能力承擔與本協議和任何數位資產交易相關的所有風險；",
            },
            {
              detailTitle: "您提供的所有資料和陳述均為屬實、完整且不具誤導性；",
            },
            {
              detailTitle:
                "就您使用我們的服務，您有責任確定您須支付的相關稅項（如有），並且您有責任向相關稅務機關繳納、報告並支付正確稅款。",
            },
          ],
          en: [
            {
              detailTitle:
                "you have full power, authority, and capacity to use our services and enter into and deliver, and perform your obligations under these Terms and Conditions;",
            },
            {
              detailTitle:
                "if you are a legal entity, you are duly incorporated, duly organised, and validly existing under the laws of your jurisdiction and have full power to conduct your business. If you are an individual, you are not less than 18 years old;",
            },
            {
              detailTitle:
                "if you act as an employee or agent of a legal entity, and enter into these Terms and Conditions on their behalf, you must be duly authorised to act on behalf of and bind such legal entity for the purposes of entering into these Terms and Conditions;",
            },
            {
              detailTitle:
                "you understand the nature and risks of the subject matter of the Agreement and the digital asset transactions, and are capable of assuming, and do assume, all risks associated with the Agreement and any digital asset transactions;",
            },
            {
              detailTitle:
                "all the information given, and representations made, by you are correct, complete and not misleading;",
            },
            {
              detailTitle:
                "it is your responsibility to determine what, if any, taxes apply to the payments you make or receive, and it is your responsibility to collect, report, and remit the correct tax to the appropriate tax authority.",
            },
          ],
        },
      ],
    },
    {
      id: 16,
      title: [
        {
          ch: "私隱政策",
          en: "Record Inquiry",
        },
      ],
      content: [
        {
          ch: [
            "我們承諾按照我們的私隱政策保護您的個⼈資料。我們亦可能透過本平台收集閣下的個人資料或資訊，包括閣下個人電腦的訊息處理機位址、閣下所在國家及若干個人資料。",
            "請瀏覽我們的私隱政策，以了解我們如何處理及使用該等資料的詳情。",
          ],
          en: [
            "We commit to protecting your personal data in accordance with our Privacy Policy. We may also through this website collect your personal data or information, including the IP address of your personal computer, the country you are from and certain personal details.",
            "Please refer to our Privacy Policy for further information on our handling and use of such information.",
          ],
        },
      ],
    },
    {
      id: 17,
      title: [
        {
          ch: "免責聲明及責任限制",
          en: "Disclaimer and Limitation of Liability",
        },
      ],
      content: [
        {
          ch: [
            "本網站所含內容僅供一般參考。儘管我們致力保持信息更新的及時性和準確性，但我們並不就本網站中任何內容的準確性、完整性、及時性、適用性或有效性作出任何聲明，並且不會對相關內容中的任何錯誤、遺漏、延誤或因顯示或使用內容而產生的任何損失、傷害或損害承擔任何責任。",
            "本網站所包含的內容不是，亦不應理解為買賣任何特定資產或使用任何特定投資策略的要約或建議，或買賣任何特定資產或使用任何特定投資策略的要約或建議邀請。 除非另有說明，AVA均不具有在任何司法管轄區進行受監管活動的許可。",
            "本網站所載內容可能包含AVA以外的其他方控制的鏈接網頁。 我們向您提供超鏈接僅為方便起見，包含任何鏈接並不表示我們對該網站的認可、批准或推薦，或與其運營商的任何關聯。",
            "我們將盡一切努力保持網站正常運行。 但是我們概不對因我們無法控制的技術問題導致網站暫時無法使用負責，亦不會承擔任何責任。",
            "我們對閣下的責任總額上限為閣下在過去12個⽉內⽀付給我們的費⽤。就間接損失包括利潤或機會的損失，我們槪不承擔責任。",
          ],
          en: [
            "The Content contained herein is for general information purposes. While we endeavor to keep the information up to date and correct, we make no representations as to the accuracy, completeness, timeliness, suitability, or validity of any information herein and will not be liable for any errors, omissions, or delays in this information or any losses, injuries, or damages arising from its display or use.",
            "The Content contained herein is not, and should not be read as, an offer or recommendation to buy or sell or a solicitation of an offer or recommendation to buy or sell any particular asset or to use any particular investment strategy. Unless otherwise specified, we herein do not hold ourselves as being licensed to carry on regulated activities in any jurisdiction.",
            "The Content herein may contain hyperlinks to websites controlled by parties other than AVA. We are providing these hyperlinks to you only as a convenience, and the inclusion of any link does not imply endorsement, approval or recommendation by us of the site or any association with its operators.",
            "We will make every effort to keep the website up and running smoothly. However, we take no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.",
            "Our liability is limited to the fees you have paid us in the past 12 months. We are not liable for any indirect losses, including but not limited to lost profits or opportunities.",
          ],
        },
      ],
    },
    {
      id: 18,
      title: [
        {
          ch: "知識產權",
          en: "Intellectual Property Rights",
        },
      ],
      content: [
        {
          ch: [
            "載於本網站的資料的所有版權、商標、標誌、專利、知識及其他產權均屬我們所有。任何種類之權利不可為他人特許專有或轉讓予他人，亦不得以其他方式傳送給取覽該等資料的人士。未得我們發出的書面同意，在任何情況下一律不得將本網站或其任何部分所載的資料抄錄、複製或另作派發。",
          ],
          en: [
            "All copyright, trademark, logo, patent, intellectual and other property rights in the information contained herein is owned by us. No rights of any kind are licensed or assigned or shall otherwise pass to persons accessing such information. Under no circumstances should information contained herein or any part of it be copied, reproduced or redistributed without our express written consent.",
          ],
        },
      ],
    },
    {
      id: 19,
      title: [
        {
          ch: "彌償",
          en: "Indemnity",
        },
      ],
      content: [
        {
          ch: [
            "您同意就您違反本使用條款導致的任何及所有申索、損失、法律責任和開支 （包括但不限於律師費），向我們作出一切賠償。",
          ],
          en: [
            "You agree to fully indemnify and keep us fully indemnified from and against all claims, losses, liabilities and expenses (including but not limited to attorney's fees) arising from your violation of these Terms and Conditions.",
          ],
        },
      ],
    },
    {
      id: 20,
      title: [
        {
          ch: "不可抗力",
          en: "Force Majeure",
        },
      ],
      content: [
        {
          ch: [
            "因不可抗力事件導致履行延遲或不能履行時，我們對本條款規定的任何履行延遲或未能履行概不承擔任何責任。",
          ],
          en: [
            "We will not be liable for any delay or failure to perform as required by these Terms and Conditions to the extent the delay or failure is caused by a Force Majeure Event.",
          ],
        },
      ],
    },
    {
      id: 21,
      title: [
        {
          ch: "可分割性",
          en: "Severability",
        },
      ],
      content: [
        {
          ch: [
            "如果本協議中任何一項或幾項約定在某管轄的現行法律下爲不合法的、無效的或不可強制執行的，則該約定在其他管轄的現行法律下的合法性、有效性和可執行性、以及其他約定的合法性、有效性和可執行性，不受其任何影響或損害。",
          ],
          en: [
            "If, at any time, any one or more of the provisions in this Agreement is or are deemed to be invalid, illegal, unenforceable or incapable of performance in any respect, the validity, legality, enforceability or performance of the remaining provisions of this Agreement shall not be affected.",
          ],
        },
      ],
    },
    {
      id: 22,
      title: [
        {
          ch: "法律管限",
          en: "Governing Law",
        },
      ],
      content: [
        {
          ch: [
            "本協議適用於香港，並按該法律解釋。就執行本協議或因本協議引起或與之相關的任何法律程序，雙方不可撤銷且無條件地同意接受香港特別行政區法院的非專屬管轄權。",
          ],
          en: [
            "This Agreement shall be governed and construed in accordance with the laws of Hong Kong and the Parties irrevocably submit to the non-exclusive jurisdiction of the Hong Kong courts.",
          ],
        },
      ],
    },
    {
      id: 23,
      title: [
        {
          ch: "語言",
          en: "Language",
        },
      ],
      content: [
        {
          ch: ["如本網站的中英文內容有任何歧異，概以英文版本為準。"],
          en: [
            "In case of discrepancies between the English and Chinese language content of this Site, the English version shall prevail.",
          ],
        },
      ],
    },
  ];

  // mining
  const miningTerms = [
    {
      id: 1,
      title: [
        {
          ch: "服務介紹",
          en: "Service Introduction",
        },
      ],
      content: [
        {
          ch: [
            "本平台為用戶提供加密貨幣投資服務，通過將用戶資金分散投資於多家交易平台(包括但不限於Binance、Bybit、Huobi、OKX、Bitopro、Bitfinex等)，以期獲得收益。",
          ],
          en: [
            "Our platform provides users with cryptocurrency investment services by diversifying user funds across multiple trading platforms (including but not limited to Binance, Bybit, Huobi, OKX, Bitopro, Bitfinex, etc.), aiming to generate returns.",
          ],
        },
      ],
    },
    {
      id: 2,
      title: [
        {
          ch: "市場風險揭示",
          en: "Market Risk Disclosure",
        },
      ],
      content: [
        {
          ch: [
            "⽤⼾應理解市場波動可能導致投資虧損。本平台不對市場波動造成的損失負責，⽤⼾應⾃⾏承擔相關投資⾵險。",
            "進行交易或投資決定均屬於您本人，您確認您已瞭解投資數位資產的⾵險，並將對您的投資決定及因此產⽣的任何損失或責任承擔全部責任。",
            "您必須細閱本平台之使用守則或手册，包括有關平台內容、顯示、指示及運作，本平台會盡力解答您的問題，但您亦有責任細閱本平台之使用守則或手册。",
          ],
          en: [
            "Users should understand that market volatility may lead to investment losses. Our platform is not responsible for losses caused by market fluctuations, and users should bear the associated investment risks themselves.",
            "Trading or investment decision is yours. You acknowledge that you understand these risks in relation to digital asset trading and will assume full responsibility for your decisions and any resulting losses or liabilities.",
            "You must read the user guideline or manual of this Platform carefully, including the content, display, instructions and operation of the Platform. The Platform will endeavour to answer any questions you may have, but it is also your responsibility to read the Platform's user guideline or manual carefully.",
          ],
        },
      ],
    },
    {
      id: 3,
      title: [
        {
          ch: "資金使用與轉換",
          en: "Funds Usage and Conversion",
        },
      ],
      content: [
        {
          ch: [
            "當⽤⼾款項存⼊後，將按照Binance當時提供的實時匯率轉換為選擇的貨幣。在服務期滿時，餘額將依照使⽤者設定的返還⽅式轉換回原始貨幣並退還(或繼續使⽤本服務)。",
          ],
          en: [
            "Upon deposit, user funds will be converted into the chosen currency at the real-time exchange rate provided by Binance at that time. At the end of the service period, the balance will be converted back to the original currency and returned (or continued with our service) according to the user s chosen method of return.",
          ],
        },
      ],
    },
    {
      id: 4,
      title: [
        {
          ch: "回報方式變更",
          en: "Changes in Return Methods",
        },
      ],
      content: [
        {
          ch: [
            "對於回報⽅式的任何未來更改，當項⽬周期為90天或以上時，⽤⼾需提前15天提交更改申請；若項⽬周期等於15天，則需提前5天提交申請。",
          ],
          en: [
            "For any future changes in the method of returns, users must submit a change request 15 days in advance for projects with a duration of 90 days or more; for projects with a duration of 15 days, a 5-day advance request is required.",
          ],
        },
      ],
    },
    {
      id: 5,
      title: [
        {
          ch: "填寫退款地址",
          en: "Refund Address Submission",
        },
      ],
      content: [
        {
          ch: [
            "⽤⼾需根據轉移的貨幣種類，準確填寫對應的退款地址。例如，轉⼊BTC則填寫BTC的退款地址，轉⼊USDT則填寫USDT的退款地址。地址錯誤導致的任何轉移失敗或損失，⽤⼾需⾃⾏承擔責任。",
          ],
          en: [
            "Users must accurately fill in the corresponding refund address according to the type of currency transferred. For example, if BTC is transferred, then the BTC refund address should be provided, and for USDT, the USDT refund address. Any transfer failures or losses due to incorrect addresses will be the responsibility of the user.",
          ],
        },
      ],
    },
    {
      id: 6,
      title: [
        {
          ch: "財務衍⽣產品及服務費",
          en: "Financial Derivative Products and Service Fees",
        },
      ],
      content: [
        {
          ch: [
            "創建投資套餐時，將運⽤多種財務衍⽣產品。這些產品的服務提供者將收取0.1%⾄0.4%的服務費。此費⽤由服務提供者直接收取，⾮由AVA平台所收取。",
          ],
          en: [
            "When creating investment packages, a variety of financial derivative products will be used. The service providers of these products will charge a service fee of 0.1% to 0.4%. This fee is collected directly by the service providers, not by the AVA platform.",
          ],
        },
      ],
    },
    {
      id: 7,
      title: [
        {
          ch: "收益結算",
          en: "Earnings Settlement",
        },
      ],
      content: [
        {
          ch: [
            "本平台將在每⽉的3號或18號進⾏收益結算(如使⽤的服務周期較⻑，則需要更⻑的時間)。由於加密貨幣市場的變動性，本平台不提供固定的收益計算公式。收益將根據市場條件和交易結果進⾏調整。",
            "本服務每次轉帳返還最少須達100USDT(或0.01BTC)，如未達到此額度的款項，將會由AVA平台暫管。直至用戶累積轉帳額度超100USDT(或0.01BTC)將會自動進行轉帳",
          ],
          en: [
            "Our platform will settle earnings on the 3rd or 18th of each month (if the service period used is longer, more time may be needed). Due to the volatility of the cryptocurrency market, our platform does not provide a fixed formula for calculating earnings. Profits will be adjusted based on market conditions and trading results.",
            "For each transfer, this service requires a minimum return of 100 USDT (or 0.01 BTC). If the amount does not reach this threshold, it will be temporarily managed by the AVA platform. Once the user's cumulative transfer amount exceeds 100 USDT (or 0.01 BTC), the transfer will be automatically processed.",
          ],
        },
      ],
    },
    {
      id: 8,
      title: [
        {
          ch: "提前退款政策",
          en: "Early Refund Policy",
        },
      ],
      content: [
        {
          ch: [
            "在任何情況下，⽤⼾均可向本平台管理員申請提前退款，但應注意可能會產⽣0.5%⾄4% 不等的⼿續費。",
          ],
          en: [
            "Users may apply for an early refund with the platform administrator under any circumstances, but should be aware that a handling fee ranging from 0.5% to 4% may apply.",
          ],
        },
      ],
    },
    {
      id: 9,
      title: [
        {
          ch: "資⾦安全保障",
          en: "Funds Security Guarantee",
        },
      ],
      content: [
        {
          ch: [
            "本平台的資⾦安全由合作的各⼤交易平台負責，本平台僅提供資⾦運⽤的服務。",
          ],
          en: [
            "The security of funds on our platform is the responsibility of our partnered major trading platforms. Our platform only provides the service of funds utilization.",
          ],
        },
      ],
    },
    {
      id: 10,
      title: [
        {
          ch: "交易策略保密性",
          en: "Confidentiality of Trading Strategies",
        },
      ],
      content: [
        {
          ch: [
            "本平台的具體交易策略為公司機密，不對外公開。我們將提供交易策略的⼤致概述，但詳細內容將保持機密。",
          ],
          en: [
            "The specific trading strategies of our platform are company’s confidential information and are not disclosed publicly. We will provide a general overview of the trading strategies, but detailed contents are kept confidential.",
          ],
        },
      ],
    },
    {
      id: 11,
      title: [
        {
          ch: "客戶服務",
          en: "Customer Service",
        },
      ],
      content: [
        {
          ch: [
            "如遇上任何問題，⽤⼾可通過Telegram聯繫我們的客⼾服務。客⼾服務的回應時間可能在3⾄7個⼯作⽇之間，實際回應時間取決於我們當時的查詢量。",
          ],
          en: [
            "For any issues, users can contact our customer service through Telegram. The response time of customer service may vary between 3 to 7 business days, depending on the query volume at the time.",
          ],
        },
      ],
    },
    {
      id: 12,
      title: [
        {
          ch: "數據隱私與安全",
          en: "Data Privacy and Security",
        },
      ],
      content: [
        {
          ch: ["本平台承諾保護⽤⼾的個⼈和財務資料，並遵守下述規範：-"],
          en: [
            "Our platform commits to protecting users personal and financial data, and adheres to the following regulations: -",
          ],
        },
      ],
      subContent: [
        {
          ch: [
            "請瀏覽我們的私隱政策，以了解我們如何處理及使用該等資料的詳情。",
          ],
          en: [
            "Please refer to our Privacy Policy for further information on our handling and use of such information.",
          ],
        },
      ],
      detail: [
        {
          ch: [
            {
              detailTitle: "個人資料的收集與使用：",
              detailContent:
                "我們只收集最少及必要的個⼈資料，⽤於我們提供服務、處理交易或經⽤⼾明確同意的⽬的。這包括但不限於姓名、聯繫⽅式、交易紀錄等。",
            },
            {
              detailTitle: "資料的儲存和安全性：",
              detailContent:
                "所有⽤⼾資料均被安全存儲，並採⽤業界標準的加密和安全措施來防⽌未經授權的訪問或泄露。",
            },
            {
              detailTitle: "⽤⼾權利的尊重：",
              detailContent:
                "根據GDPR規定，⽤⼾有權訪問其個⼈資料、要求更正或刪除其資料、限制或反對其資料的處理，以及數據的可攜性權利。⽤⼾可隨時聯繫我們以⾏使這些權利。",
            },
            {
              detailTitle: "資料的共享與轉移：",
              detailContent:
                "除⾮法律要求或為了提供服務所必需，我們不會未經⽤⼾同意向第三⽅共享或轉移個⼈資料。",
            },
          ],
          en: [
            {
              detailTitle: "Collection and Use of Personal Data:",
              detailContent:
                "We only collect the minimum personal data necessary to provide services, process transactions, or as explicitly consented to by users. This includes, but is not limited to, names, contact information, transaction records, etc.",
            },
            {
              detailTitle: "Data Storage and Security:",
              detailContent:
                "All user data is securely stored and protected with industry-standard encryption and security measures to prevent unauthorized access or disclosure.",
            },
            {
              detailTitle: "Respect for User Rights:",
              detailContent:
                "n accordance with GDPR regulations, users have the right to access their personal data, request corrections or deletion of their data, restrict or object to the processing of their data, and the right to data portability. Users can contact us at any time to exercise these rights.",
            },
            {
              detailTitle: "Data Sharing and Transfer:",
              detailContent:
                "We do not share or transfer personal data to third parties without user consent unless legally required or necessary to provide the service.",
            },
          ],
        },
      ],
    },
    {
      id: 13,
      title: [
        {
          ch: "條款之修改",
          en: "Modification of Terms",
        },
      ],
      content: [
        {
          ch: [
            "本平台保留隨時修改或更新本條款和細則的權利。任何此類更改將以電⼦郵件⽅式通知⽤⼾，凡繼續使⽤我們的服務即表⽰您接受這些新條款。",
          ],
          en: [
            "Our platform reserves the right to modify or update these terms and conditions at any time. Any such changes will be notified to users via email. Continued use of our services indicates your acceptance of the new terms.",
          ],
        },
      ],
    },
    {
      id: 14,
      title: [
        {
          ch: "禁止的行為",
          en: "Prohibited Activity",
        },
      ],
      content: [
        {
          ch: [
            "閣下禁止從事任何我們在絶對酌情決定權下被視為不恰當及╱或將被視為非法的行為或被任何適用於本網站的法律所禁止的行為，包括但不限於：-",
          ],
          en: [
            "You are prohibited from doing any act that we in its absolute discretion may deem to be inappropriate and/or would be deemed to be an unlawful act or is prohibited by any laws applicable to this website including but not limited to: -",
          ],
        },
      ],
      detail: [
        {
          ch: [
            {
              detailTitle:
                "利用我們的網站及/或服務進行任何詐欺或其他違法行為；",
            },
            {
              detailTitle:
                "擅自入侵、訪問、使用或試圖入侵、訪問或使用我們的伺服器的任何其他部份以及／或任何數據區；",
            },
            {
              detailTitle:
                "未得我們書面批准﹙除非我們在本網站中另有訂明，或我們另外給予特許﹚而張貼、發佈、傳送、複製、分發或以任何方式利用從本網站獲得的任何資料作商業用途；",
            },
            {
              detailTitle:
                "發布任何被視為違法的、具有恐嚇成份的、誹謗的、欺詐的、淫褻的或其他令人反感的內容，或其他可能侵犯第三方權益 (如私隱權和知識財產權) 的內容；",
            },
            {
              detailTitle:
                "使用任何機器人軟件、蜘蛛軟件、網站搜尋或檢索應用程式或任何其他方法或工具來複製、檢索、存檔我們網站的任何部分。",
            },
          ],
          en: [
            {
              detailTitle:
                "commit fraud or other illegal acts through our website or services;",
            },
            {
              detailTitle:
                "trespass, break into, access, use or attempt to trespass, break into, access or use any other parts of our servers, and/or any data areas for which you have not been authorized by us;",
            },
            {
              detailTitle:
                "post, publish, transmit, reproduce, distribute or in any way exploit any Information obtained through this website for commercial purposes without our prior written permission (unless otherwise specified by us in this website or pursuant to separate licence granted by us);",
            },
            {
              detailTitle:
                "post any content that is being considered as unlawful, threatening, defamatory, fraudulent, obscene or otherwise objectionable, or because it may infringe or violate any other third party’s rights such as privacy and Intellectual Property Rights;",
            },
            {
              detailTitle:
                "use any robot, spider, site search or retrieval application, or any other method or device to copy, retrieve, archive or index any portion of our site.",
            },
          ],
        },
      ],
    },
    {
      id: 15,
      title: [
        {
          ch: "聲明與保證",
          en: "Representations and Warranties",
        },
      ],
      content: [
        {
          ch: ["凡您使⽤我們的服務，即表示閣下保證及聲明閣下：-"],
          en: [
            "By using our accessing or using services, you represent and warrant to us, at all times, the following: -",
          ],
        },
      ],
      detail: [
        {
          ch: [
            {
              detailTitle:
                "是具備充分權力、權限和能力的個人、公司、法律實體或其他組織，可以使用我們的服務及簽訂本條款並遵守您在本條款中的義務；",
            },
            {
              detailTitle:
                "如果您是法律實體，則您在所屬司法管轄區正式註冊、正式成立並有效存續，並且具有處理事務的全部權利。如果您是個人，則您不少於18歲；",
            },
            {
              detailTitle:
                "如果您是法律實體的員工或代理人並代表其簽署本條款，則您必須獲得作為其代表的正式授權並使實體因簽訂本條款而受其約束；",
            },
            {
              detailTitle:
                "您清楚了解本協議和數位資產交易的性質和風險，並且有能力承擔與本協議和任何數位資產交易相關的所有風險；",
            },
            {
              detailTitle: "您提供的所有資料和陳述均為屬實、完整且不具誤導性；",
            },
            {
              detailTitle:
                "就您使用我們的服務，您有責任確定您須支付的相關稅項（如有），並且您有責任向相關稅務機關繳納、報告並支付正確稅款。",
            },
          ],
          en: [
            {
              detailTitle:
                "you have full power, authority, and capacity to use our services and enter into and deliver, and perform your obligations under these Terms and Conditions;",
            },
            {
              detailTitle:
                "if you are a legal entity, you are duly incorporated, duly organised, and validly existing under the laws of your jurisdiction and have full power to conduct your business. If you are an individual, you are not less than 18 years old;",
            },
            {
              detailTitle:
                "if you act as an employee or agent of a legal entity, and enter into these Terms and Conditions on their behalf, you must be duly authorised to act on behalf of and bind such legal entity for the purposes of entering into these Terms and Conditions;",
            },
            {
              detailTitle:
                "you understand the nature and risks of the subject matter of the Agreement and the digital asset transactions, and are capable of assuming, and do assume, all risks associated with the Agreement and any digital asset transactions;",
            },
            {
              detailTitle:
                "all the information given, and representations made, by you are correct, complete and not misleading;",
            },
            {
              detailTitle:
                "it is your responsibility to determine what, if any, taxes apply to the payments you make or receive, and it is your responsibility to collect, report, and remit the correct tax to the appropriate tax authority.",
            },
          ],
        },
      ],
    },
    {
      id: 16,
      title: [
        {
          ch: "免責聲明及責任限制",
          en: "Disclaimer and Limitation of Liability",
        },
      ],
      content: [
        {
          ch: [
            "本網站所含內容僅供一般參考。儘管我們致力保持信息更新的及時性和準確性，但我們並不就本網站中任何內容的準確性、完整性、及時性、適用性或有效性作出任何聲明，並且不會對相關內容中的任何錯誤、遺漏、延誤或因顯示或使用內容而產生的任何損失、傷害或損害承擔任何責任。",
            "本網站所包含的內容不是，亦不應理解為買賣任何特定資產或使用任何特定投資策略的要約或建議，或買賣任何特定資產或使用任何特定投資策略的要約或建議邀請。 除非另有說明，AVA均不具有在任何司法管轄區進行受監管活動的許可。",
            "本網站所載內容可能包含AVA以外的其他方控制的鏈接網頁。 我們向您提供超鏈接僅為方便起見，包含任何鏈接並不表示我們對該網站的認可、批准或推薦，或與其運營商的任何關聯。",
            "我們將盡一切努力保持網站正常運行。 但是我們概不對因我們無法控制的技術問題導致網站暫時無法使用負責，亦不會承擔任何責任。",
            "我們對閣下的責任總額上限為閣下在過去12個⽉內⽀付給我們的費⽤。就間接損失包括利潤或機會的損失，我們槪不承擔責任。",
          ],
          en: [
            "The Content contained herein is for general information purposes. While we endeavor to keep the information up to date and correct, we make no representations as to the accuracy, completeness, timeliness, suitability, or validity of any information herein and will not be liable for any errors, omissions, or delays in this information or any losses, injuries, or damages arising from its display or use.",
            "The Content contained herein is not, and should not be read as, an offer or recommendation to buy or sell or a solicitation of an offer or recommendation to buy or sell any particular asset or to use any particular investment strategy. Unless otherwise specified, we herein do not hold ourselves as being licensed to carry on regulated activities in any jurisdiction.",
            "The Content herein may contain hyperlinks to websites controlled by parties other than AVA. We are providing these hyperlinks to you only as a convenience, and the inclusion of any link does not imply endorsement, approval or recommendation by us of the site or any association with its operators.",
            "We will make every effort to keep the website up and running smoothly. However, we take no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.",
            "Our liability is limited to the fees you have paid us in the past 12 months. We are not liable for any indirect losses, including but not limited to lost profits or opportunities.",
          ],
        },
      ],
    },
    {
      id: 17,
      title: [
        {
          ch: "知識產權",
          en: "Intellectual Property Rights",
        },
      ],
      content: [
        {
          ch: [
            "載於本網站的資料的所有版權、商標、標誌、專利、知識及其他產權均屬我們所有。任何種類之權利不可為他人特許專有或轉讓予他人，亦不得以其他方式傳送給取覽該等資料的人士。未得我們發出的書面同意，在任何情況下一律不得將本網站或其任何部分所載的資料抄錄、複製或另作派發。",
          ],
          en: [
            "All copyright, trademark, logo, patent, intellectual and other property rights in the information contained herein is owned by us. No rights of any kind are licensed or assigned or shall otherwise pass to persons accessing such information. Under no circumstances should information contained herein or any part of it be copied, reproduced or redistributed without our express written consent.",
          ],
        },
      ],
    },
    {
      id: 18,
      title: [
        {
          ch: "彌償",
          en: "Indemnity",
        },
      ],
      content: [
        {
          ch: [
            "您同意就您違反本使用條款導致的任何及所有申索、損失、法律責任和開支 （包括但不限於律師費），向我們作出一切賠償。",
          ],
          en: [
            "You agree to fully indemnify and keep us fully indemnified from and against all claims, losses, liabilities and expenses (including but not limited to attorney's fees) arising from your violation of these Terms and Conditions.",
          ],
        },
      ],
    },

    {
      id: 19,
      title: [
        {
          ch: "不可抗力",
          en: "Force Majeure",
        },
      ],
      content: [
        {
          ch: [
            "因不可抗力事件導致履行延遲或不能履行時，我們對本條款規定的任何履行延遲或未能履行概不承擔任何責任。",
          ],
          en: [
            "We will not be liable for any delay or failure to perform as required by these Terms and Conditions to the extent the delay or failure is caused by a Force Majeure Event.",
          ],
        },
      ],
    },
    {
      id: 20,
      title: [
        {
          ch: "可分割性",
          en: "Severability",
        },
      ],
      content: [
        {
          ch: [
            "如果本協議中任何一項或幾項約定在某管轄的現行法律下爲不合法的、無效的或不可強制執行的，則該約定在其他管轄的現行法律下的合法性、有效性和可執行性、以及其他約定的合法性、有效性和可執行性，不受其任何影響或損害。",
          ],
          en: [
            "If, at any time, any one or more of the provisions in this Agreement is or are deemed to be invalid, illegal, unenforceable or incapable of performance in any respect, the validity, legality, enforceability or performance of the remaining provisions of this Agreement shall not be affected.",
          ],
        },
      ],
    },
    {
      id: 21,
      title: [
        {
          ch: "法律管限",
          en: "Governing Law",
        },
      ],
      content: [
        {
          ch: [
            "本協議適用於香港，並按該法律解釋。就執行本協議或因本協議引起或與之相關的任何法律程序，雙方不可撤銷且無條件地同意接受香港特別行政區法院的非專屬管轄權。",
          ],
          en: [
            "This Agreement shall be governed and construed in accordance with the laws of Hong Kong and the Parties irrevocably submit to the non-exclusive jurisdiction of the Hong Kong courts.",
          ],
        },
      ],
    },
    {
      id: 22,
      title: [
        {
          ch: "語言",
          en: "Language",
        },
      ],
      content: [
        {
          ch: ["如本網站的中英文內容有任何歧異，概以英文版本為準。"],
          en: [
            "In case of discrepancies between the English and Chinese language content of this Site, the English version shall prevail.",
          ],
        },
      ],
    },
  ];

  // register
  const userTerms = [
    {
      id: 1,
      title: [
        {
          ch: "釋義",
          en: "Definitions",
        },
      ],
      content: [
        {
          ch: [
            "除文義另有指定外，在本⽤⼾條款及細則內，下列詞語具有下列涵義：",
            "「AVA」及「我們」是指ACHIEVER ROBOTRADE TECHNOLOGY LIMITED；",
            "「內容」是指我們網站中展示或使用的所有文字、圖形、標誌、圖示、照片、圖像、動態視覺圖像、或此類圖像和聲音的組合、音訊、電腦程式以及其他與我們網站有關的資料；",
            "「不可抗力事件」指不受本協議任何一方合理控制的任何事件或情況（包括但不限於任何罷工、勞資糾紛或其他工業問題、處所或設備遭受毀壞、政府規例、暴亂、戰爭、火災、地震或其他天然災害。）；",
            "「知識產權」包括但不限於任何發明、技術創新、發現、設計、配方、專有技術、流程、商業方法、專利、商標、服務標誌、版權、電腦軟體、創意、創作、著作、講座、插圖、照片、影片、科學和數學模型、對所有此類財產的改進，以及定義、描述或說明所有此類財產的所有記錄材料；",
            "「香港」指中華人民共和國香港特別行政區；",
            "「平台」及「網站」是指我們的網站 (https://www.avairobot.com/) 及其連接網頁。",
          ],
          en: [
            "In these Terms and Conditions, unless the context requires otherwise, the following words and expressions have the following meanings:",
            "“AVA”, “us” and “our” are used to refer to ACHIEVER ROBOTRADE TECHNOLOGY LIMITED;",
            "“Content” means all text, graphics, logos, icons, photographs, images, moving visual representational images or a combination of sounds and such images, audio, computer programs, and other material featured, displayed or used in or in relation to our site;",
            "“Force Majeure Event” means any circumstances not foreseeable at the date of this Agreement and not within the reasonable control of the party in question including, without limitation, industrial action, destruction of premises or equipment, governmental action, civil disorder or war, fire, earthquake or other natural disaster;",
            "“Intellectual Property Rights” shall include, without limitation, any inventions, technological innovations, discoveries, designs, formulas, know-how, processes, business methods, patents, trademarks, service marks, copyrights, computer software, ideas, creations, writings, lectures, illustrations, photographs, motion pictures, scientific and mathematical models, improvements to all such property, and all recorded material defining, describing, or illustrating all such property.",
            "“Hong Kong” means Hong Kong Special Administrative Region of the People’s Republic of China;",
            "“Platform”, “Website” and “Site” means our website at https://www.avairobot.com/, and any associated sites linked to it.",
          ],
        },
      ],
    },
    {
      id: 2,
      title: [
        {
          ch: "協議與接受",
          en: "Agreement and Acceptance",
        },
      ],
      content: [
        {
          ch: [
            "本⽤⼾條款及細則構成您與我們之間具有法律約束力的協議，您使⽤本平台提供之任何產品、服務、內容、工具及資料將受本條款約束。",
            "凡您註冊AVA的賬⼾、取覽本平台或使⽤我們的服務時，即表示您已閱讀下列資料，並接受下述的條款及細則，亦同意接受此等條款及細則約束。倘若您不同意下列條款及細則，請勿瀏覽本網站或使用我們的服務。",
          ],
          en: [
            "These Terms and Conditions constitute a legally binding agreement (the “Agreement”)  between you and us which govern your use of our products, services, Content, tools and information.",
            "By registering an account with AVA, accessing our platform or using our services, you acknowledge that you have read the following information and accept the terms and conditions set out below, and agree to be bound by such terms and conditions. If you do not agree to such terms and conditions below, do not access this website, or use our services.",
          ],
        },
      ],
    },
    {
      id: 3,
      title: [
        {
          ch: "⾵險提⽰",
          en: "Risk Warning",
        },
      ],
      content: [
        {
          ch: [
            "數位資產交易存在重⼤⾵險，數位資產價值可能會因應市況而劇烈波動，您可能須承受重⼤損失。您應根據您的知識、財務狀況和投資⽬標，仔細考慮是否適合進⾏此類交易。",
            "進行交易或投資決定均屬於您本人，您確認您已瞭解投資數位資產的⾵險，並將對您的投資決定及因此產⽣的任何損失或責任承擔全部責任。",
            "您必須細閱本平台之使用守則或手册，包括有關平台內容、顯示、指示及運作，本平台會盡力解答您的問題，但您亦有責任細閱本平台之使用守則或手册。",
          ],
          en: [
            "Digital asset trading involves significant risks. The value of digital assets can fluctuate greatly, potentially leading to substantial losses. You should carefully consider whether such trading is suitable for you based on your knowledge, financial situation and investment objectives.",
            "Trading or investment decision is yours. You acknowledge that you understand risks in relation to digital asset trading and will assume full responsibility for your decisions and any resulting losses or liabilities.",
            "You must read the user guideline or manual of this Platform carefully, including the content, display, instructions and operation of the Platform. The Platform will endeavour to answer any questions you may have, but it is also your responsibility to read the Platform's user guideline or manual carefully.",
          ],
        },
      ],
    },
    {
      id: 4,
      title: [
        {
          ch: "服務",
          en: "Services",
        },
      ],
      content: [
        {
          ch: [
            "我們提供⼀個管理數位資產的平台，包括根據您的指示及以AI 應用程式將您的資⾦分散投資於各個交易所以優化利潤。",
            "我們服務的具體細節，包括任何限制和要求，均詳細列於產品條款中。",
          ],
          en: [
            "We provide a platform for managing digital assets, including diversifying your funds across various exchanges to optimize profits in accordance with your instructions and applications of artificial intelligence.",
            "The specific details of our services, including any limitations and requirements, are detailed in the product terms.",
          ],
        },
      ],
    },
    {
      id: 5,
      title: [
        {
          ch: "費⽤",
          en: "Fees",
        },
      ],
      content: [
        {
          ch: [
            "您同意⽀付使⽤我們服務所產⽣的所有相關費⽤，詳情請⾒我們的相關產品條款中的說明。",
          ],
          en: [
            "You agree to pay all related fees incurred in using our services, as detailed in our relevant product terms.",
          ],
        },
      ],
    },
    {
      id: 6,
      title: [
        {
          ch: "賬⼾管理",
          en: "Account Management",
        },
      ],
      content: [
        {
          ch: [
            "您必須開立並維持您的AVA賬⼾才能使用我們的服務。就閣下賬⼾的開立、運作、關閉及您擁有賬⼾數量，我們保留絕對的權利。",
            "您需對您賬⼾下的所有活動負責，並確保您的賬⼾信息的安全性與機密性。",
          ],
          en: [
            "You must create and maintain your account to access our services. We have the absolute discretion as to the opening, operation, closure of the account and the number of accounts you may hold.",
            "You are responsible for all activities under your account and must ensure the security and confidentiality of your account information.",
          ],
        },
      ],
    },
    {
      id: 7,
      title: [
        {
          ch: "交易",
          en: "Transactions",
        },
      ],
      content: [
        {
          ch: [
            "您可以使用我們提供的服務進⾏交易。我們不保證任何交易能在特定時間內完成。",
            "您應監控您的賬⼾，以識別任何未授權的活動。我們對因未授權交易造成的損失不承擔任何責任。",
          ],
          en: [
            "You may conduct transactions as per the services we provide. We do not guarantee the completion of any transaction within a specific time frame.",
            "You should monitor your account to identify any unauthorized activities. We are not responsible for losses due to unauthorized transactions.",
          ],
        },
      ],
    },
    {
      id: 8,
      title: [
        {
          ch: "安全",
          en: "Security",
        },
      ],
      content: [
        {
          ch: [
            "您有責任保護您的賬⼾和個⼈信息不會未經授權地被查閲、處理、刪除、喪失或使用。",
            "您應經常維護您的賬⼾和登錄憑證的安全措施。",
            "倘若您認為有人可能未經您准許而試圖使用或已經使用您的賬⼾和登錄憑證，或已出現任何其他未經授權的使用情況或保安入侵、損失或盜用的情況，您應立即通知我們。",
          ],
          en: [
            "You are responsible for protecting your account and personal information from unauthorized access, processing, erasure, loss or use.",
            "You should always maintain appropriate security measures for your account and login credentials.",
            "If you believe someone may attempt to use or has used your account and login credentials without your permission, or that any other unauthorised use or security breach, loss or theft has occurred, you should immediately notify us.",
          ],
        },
      ],
    },
    {
      id: 9,
      title: [
        {
          ch: "禁止的行為",
          en: "Prohibited Activity",
        },
      ],
      content: [
        {
          ch: [
            "閣下禁止從事任何我們在絶對酌情決定權下被視為不恰當及╱或將被視為非法的行為或被任何適用於本網站的法律所禁止的行為，包括但不限於：-",
          ],
          en: [
            "You are prohibited from doing any act that we in its absolute discretion may deem to be inappropriate and/or would be deemed to be an unlawful act or is prohibited by any laws applicable to this website including but not limited to:",
          ],
        },
      ],
      detail: [
        {
          ch: [
            {
              detailTitle:
                "利用我們的網站及/或服務進行任何詐欺或其他違法行為；",
            },
            {
              detailTitle:
                "擅自入侵、訪問、使用或試圖入侵、訪問或使用我們的伺服器的任何其他部份以及／或任何數據區；",
            },
            {
              detailTitle:
                "未得我們書面批准﹙除非我們在本網站中另有訂明，或我們另外給予特許﹚而張貼、發佈、傳送、複製、分發或以任何方式利用從本網站獲得的任何資料作商業用途；",
            },
            {
              detailTitle:
                "發布任何被視為違法的、具有恐嚇成份的、誹謗的、欺詐的、淫褻的或其他令人反感的內容，或其他可能侵犯第三方權益 (如私隱權和知識財產權) 的內容；",
            },
            {
              detailTitle:
                "使用任何機器人軟件、蜘蛛軟件、網站搜尋或檢索應用程式或任何其他方法或工具來複製、檢索、存檔我們網站的任何部分。",
            },
          ],
          en: [
            {
              detailTitle:
                "commit fraud or other illegal acts through our website or services;",
            },
            {
              detailTitle:
                "trespass, break into, access, use or attempt to trespass, break into, access or use any other parts of our servers, and/or any data areas for which you have not been authorized by us;",
            },
            {
              detailTitle:
                "post, publish, transmit, reproduce, distribute or in any way exploit any Information obtained through this website for commercial purposes without our prior written permission (unless otherwise specified by us in this website or pursuant to separate licence granted by us);",
            },
            {
              detailTitle:
                "post any content that is being considered as unlawful, threatening, defamatory, fraudulent, obscene or otherwise objectionable, or because it may infringe or violate any other third party’s rights such as privacy and Intellectual Property Rights;",
            },
            {
              detailTitle:
                "use any robot, spider, site search or retrieval application, or any other method or device to copy, retrieve, archive or index any portion of our site.",
            },
          ],
        },
      ],
    },
    {
      id: 10,
      title: [
        {
          ch: "私隱政策",
          en: "Privacy Policy",
        },
      ],
      content: [
        {
          ch: [
            "我們承諾按照我們的私隱政策保護您的個⼈資料。我們亦可能透過本平台收集閣下的個人資料或資訊，包括閣下個人電腦的訊息處理機位址、閣下所在國家及若干個人資料。",
            "請瀏覽我們的私隱政策，以了解我們如何處理及使用該等資料的詳情。",
          ],
          en: [
            "We commit to protecting your personal data in accordance with our Privacy Policy. We may also through this website collect your personal data or information, including the IP address of your personal computer, the country you are from and certain personal details.",
            "Please refer to our Privacy Policy for further information on our handling and use of such information.",
          ],
        },
      ],
    },
    {
      id: 11,
      title: [
        {
          ch: "條款之修改",
          en: "Modification of Terms",
        },
      ],
      content: [
        {
          ch: [
            "我們保留隨時修改本條款的權利。所有修改將通過電⼦郵件或我們的網站通知閣下。凡繼續使⽤我們的服務即表⽰您接受這些新條款。",
          ],
          en: [
            "We reserve the right to modify these terms at any time. Changes will be notified via email or on our website. Continued use of our services indicates your acceptance of the new terms.",
          ],
        },
      ],
    },
    {
      id: 12,
      title: [
        {
          ch: "聲明與保證",
          en: "Representations and Warranties",
        },
      ],
      content: [
        {
          ch: ["凡您使⽤我們的服務，即表示閣下保證及聲明閣下：-"],
          en: [
            "By using our accessing or using services, you represent and warrant to us, at all times, the following: -",
          ],
        },
      ],
      detail: [
        {
          ch: [
            {
              detailTitle:
                "是具備充分權力、權限和能力的個人、公司、法律實體或其他組織，可以使用我們的服務及簽訂本條款並遵守您在本條款中的義務；",
            },
            {
              detailTitle:
                "如果您是法律實體，則您在所屬司法管轄區正式註冊、正式成立並有效存續，並且具有處理事務的全部權利。如果您是個人，則您不少於18歲；",
            },
            {
              detailTitle:
                "如果您是法律實體的員工或代理人並代表其簽署本條款，則您必須獲得作為其代表的正式授權並使實體因簽訂本條款而受其約束；",
            },
            {
              detailTitle:
                "您清楚了解本協議和數位資產交易的性質和風險，並且有能力承擔與本協議和任何數位資產交易相關的所有風險；",
            },
            {
              detailTitle: "您提供的所有資料和陳述均為屬實、完整且不具誤導性；",
            },
            {
              detailTitle:
                "就您使用我們的服務，您有責任確定您須支付的相關稅項（如有），並且您有責任向相關稅務機關繳納、報告並支付正確稅款。",
            },
          ],
          en: [
            {
              detailTitle:
                "you have full power, authority, and capacity to use our services and enter into and deliver, and perform your obligations under these Terms and Conditions;",
            },
            {
              detailTitle:
                "if you are a legal entity, you are duly incorporated, duly organised, and validly existing under the laws of your jurisdiction and have full power to conduct your business. If you are an individual, you are not less than 18 years old;",
            },
            {
              detailTitle:
                "if you act as an employee or agent of a legal entity, and enter into these Terms and Conditions on their behalf, you must be duly authorised to act on behalf of and bind such legal entity for the purposes of entering into these Terms and Conditions;",
            },
            {
              detailTitle:
                "you understand the nature and risks of the subject matter of the Agreement and the digital asset transactions, and are capable of assuming, and do assume, all risks associated with the Agreement and any digital asset transactions;",
            },
            {
              detailTitle:
                "all the information given, and representations made, by you are correct, complete and not misleading;",
            },
            {
              detailTitle:
                "it is your responsibility to determine what, if any, taxes apply to the payments you make or receive, and it is your responsibility to collect, report, and remit the correct tax to the appropriate tax authority.",
            },
          ],
        },
      ],
    },
    {
      id: 13,
      title: [
        {
          ch: "賬⼾終⽌",
          en: "Account Termination",
        },
      ],
      content: [
        {
          ch: [
            "您可以按照規定的程序關閉您的賬⼾。在某些情況下，我們可能會限制賬⼾的關閉。",
          ],
          en: [
            "You may close your account following the specified procedure. Account closure may be restricted under certain circumstances.",
          ],
        },
      ],
    },
    {
      id: 14,
      title: [
        {
          ch: "免責聲明及責任限制",
          en: "Disclaimer and Limitation of Liability",
        },
      ],
      content: [
        {
          ch: [
            "本網站所含內容僅供一般參考。儘管我們致力保持信息更新的及時性和準確性，但我們並不就本網站中任何內容的準確性、完整性、及時性、適用性或有效性作出任何聲明，並且不會對相關內容中的任何錯誤、遺漏、延誤或因顯示或使用內容而產生的任何損失、傷害或損害承擔任何責任。",
            "本網站所包含的內容不是，亦不應理解為買賣任何特定資產或使用任何特定投資策略的要約或建議，或買賣任何特定資產或使用任何特定投資策略的要約或建議邀請。 除非另有說明，AVA均不具有在任何司法管轄區進行受監管活動的許可。",
            "本網站所載內容可能包含AVA以外的其他方控制的鏈接網頁。 我們向您提供超鏈接僅為方便起見，包含任何鏈接並不表示我們對該網站的認可、批准或推薦，或與其運營商的任何關聯。",
            "我們將盡一切努力保持網站正常運行。 但是我們概不對因我們無法控制的技術問題導致網站暫時無法使用負責，亦不會承擔任何責任。",
            "我們對閣下的責任總額上限為閣下在過去12個⽉內⽀付給我們的費⽤。就間接損失包括利潤或機會的損失，我們槪不承擔責任。",
            "AVA向外的所有轉帳服務，僅在每月的5日以及20日執行非轉帳日期間受理的所有轉帳服務。",
          ],
          en: [
            "The Content contained herein is for general information purposes. While we endeavor to keep the information up to date and correct, we make no representations as to the accuracy, completeness, timeliness, suitability, or validity of any information herein and will not be liable for any errors, omissions, or delays in this information or any losses, injuries, or damages arising from its display or use.",
            "The Content contained herein is not, and should not be read as, an offer or recommendation to buy or sell or a solicitation of an offer or recommendation to buy or sell any particular asset or to use any particular investment strategy. Unless otherwise specified, we herein do not hold ourselves as being licensed to carry on regulated activities in any jurisdiction.",
            "The Content herein may contain hyperlinks to websites controlled by parties other than AVA. We are providing these hyperlinks to you only as a convenience, and the inclusion of any link does not imply endorsement, approval or recommendation by us of the site or any association with its operators.",
            "We will make every effort to keep the website up and running smoothly. However, we take no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.",
            "Our liability is limited to the fees you have paid us in the past 12 months. We are not liable for any indirect losses, including but not limited to lost profits or opportunities.",
            "All outward transfer services from AVA are only executed on the 5th and the 20th of each month, accepting all transfer services during non-transfer days.",
          ],
        },
      ],
    },
    {
      id: 15,
      title: [
        {
          ch: "價值單位",
          en: "Unit of Value",
        },
      ],
      content: [
        {
          ch: [
            "除⾮在平台上另有明確標⽰，所有提及或計算的價值單位均以「AVAPoint」作為衡量基準。",
            "「AVAPoint」是我們專⾨為平台交易和服務所設計的計價單位，旨在為⽤⼾提供⼀致和標準化的價值衡量⽅式。",
          ],
          en: [
            "Unless otherwise explicitly indicated on the platform, all mentioned or calculated value units are measured in AVAPoint.",
            "AVAPoint is a unit of valuation specifically designed for platform transactions and services, intended to provide users with a consistent and standardized method of value measurement.",
          ],
        },
      ],
    },
    {
      id: 16,
      title: [
        {
          ch: "知識產權",
          en: "Intellectual Property Rights",
        },
      ],
      content: [
        {
          ch: [
            "載於本網站的資料的所有版權、商標、標誌、專利、知識及其他產權均屬我們所有。任何種類之權利不可為他人特許專有或轉讓予他人，亦不得以其他方式傳送給取覽該等資料的人士。未得我們發出的書面同意，在任何情況下一律不得將本網站或其任何部分所載的資料抄錄、複製或另作派發。",
          ],
          en: [
            "All copyright, trademark, logo, patent, intellectual and other property rights in the information contained herein is owned by us. No rights of any kind are licensed or assigned or shall otherwise pass to persons accessing such information. Under no circumstances should information contained herein or any part of it be copied, reproduced or redistributed without our express written consent.",
          ],
        },
      ],
    },
    {
      id: 17,
      title: [
        {
          ch: "彌償",
          en: "Indemnity",
        },
      ],
      content: [
        {
          ch: [
            "您同意就您違反本使用條款導致的任何及所有申索、損失、法律責任和開支 （包括但不限於律師費），向我們作出一切賠償。",
          ],
          en: [
            "You agree to fully indemnify and keep us fully indemnified from and against all claims, losses, liabilities and expenses (including but not limited to attorney's fees) arising from your violation of these Terms and Conditions.",
          ],
        },
      ],
    },
    {
      id: 18,
      title: [
        {
          ch: "不可抗力",
          en: "Force Majeure",
        },
      ],
      content: [
        {
          ch: [
            "因不可抗力事件導致履行延遲或不能履行時，我們對本條款規定的任何履行延遲或未能履行概不承擔任何責任。",
          ],
          en: [
            "We will not be liable for any delay or failure to perform as required by these Terms and Conditions to the extent the delay or failure is caused by a Force Majeure Event.",
          ],
        },
      ],
    },
    {
      id: 19,
      title: [
        {
          ch: "可分割性",
          en: "Severability",
        },
      ],
      content: [
        {
          ch: [
            "如果本協議中任何一項或幾項約定在某管轄的現行法律下爲不合法的、無效的或不可強制執行的，則該約定在其他管轄的現行法律下的合法性、有效性和可執行性、以及其他約定的合法性、有效性和可執行性，不受其任何影響或損害。",
          ],
          en: [
            "If, at any time, any one or more of the provisions in this Agreement is or are deemed to be invalid, illegal, unenforceable or incapable of performance in any respect, the validity, legality, enforceability or performance of the remaining provisions of this Agreement shall not be affected.",
          ],
        },
      ],
    },
    {
      id: 20,
      title: [
        {
          ch: "法律管限",
          en: "Governing Law",
        },
      ],
      content: [
        {
          ch: [
            "本協議適用於香港，並按該法律解釋。就執行本協議或因本協議引起或與之相關的任何法律程序，雙方不可撤銷且無條件地同意接受香港特別行政區法院的非專屬管轄權。",
          ],
          en: [
            "This Agreement shall be governed and construed in accordance with the laws of Hong Kong and the Parties irrevocably submit to the non-exclusive jurisdiction of the Hong Kong courts.",
          ],
        },
      ],
    },
    {
      id: 21,
      title: [
        {
          ch: "語言",
          en: "Language",
        },
      ],
      content: [
        {
          ch: ["如本網站的中英文內容有任何歧異，概以英文版本為準。"],
          en: [
            "In case of discrepancies between the English and Chinese language content of this Site, the English version shall prevail.",
          ],
        },
      ],
    },
  ];

  return (
    <Wrapper>
      <TitleWrapper>
        <Title>
          <Lang datakey="terms_title" defaultText="Terms Of Service" />
        </Title>
      </TitleWrapper>
      <Container>
        <TabWrapper>
          <TabContainer>
            {tabList.map((data) => (
              <Tab
                activeTab={activeTab === data}
                key={data}
                onClick={() => setActiveTab(data)}
              >
                <TabText activeTab={activeTab === data}>{data}</TabText>
              </Tab>
            ))}
          </TabContainer>
          {/* <ImgWrapper>
            <img src={Banner} />
          </ImgWrapper> */}
        </TabWrapper>
        <ContentWrapper>
          {activeTab === "user" && (
            <Fragment>
              {userTerms.map((data) => (
                <ContentContainer key={data.id}>
                  <TermsTitleWrapper>
                    {data.id}. {data.title[0][language?.toLocaleLowerCase()]}
                  </TermsTitleWrapper>
                  <TermsWrapper>
                    {data.content[0][language?.toLocaleLowerCase()]?.map(
                      (item, index) => (
                        <TermsItem key={index}>{item}</TermsItem>
                      )
                    )}
                  </TermsWrapper>
                  {data?.detail && (
                    <TermsDetailWrapper>
                      {data?.detail[0][language?.toLocaleLowerCase()]?.map(
                        (item, index) => (
                          <Fragment key={index}>
                            <TermsDetailTitle>
                              {item.detailTitle}
                            </TermsDetailTitle>
                            <TermsDetailItem>
                              {item.detailContent}
                            </TermsDetailItem>
                          </Fragment>
                        )
                      )}
                    </TermsDetailWrapper>
                  )}
                </ContentContainer>
              ))}
            </Fragment>
          )}

          {activeTab === "algo" && (
            <Fragment>
              <DefinitionsWrapper>
                {language?.toLocaleLowerCase() === "ch"
                  ? "「AVA」或「我們」是指ACHIEVER ROBOTRADE TECHNOLOGY LIMITED。"
                  : "“AVA” or “We” or “us” are referred to ACHIEVER ROBOTRADE TECHNOLOGY LIMITED."}
              </DefinitionsWrapper>
              {algoTerms.map((data) => (
                <ContentContainer key={data.id}>
                  <TermsTitleWrapper>
                    {data.id}. {data.title[0][language?.toLocaleLowerCase()]}
                  </TermsTitleWrapper>
                  <TermsWrapper>
                    {data?.content?.[0]?.[language?.toLocaleLowerCase()]?.map(
                      (item, index) => (
                        <TermsItem key={index}>{item}</TermsItem>
                      )
                    )}
                  </TermsWrapper>
                  {data?.detail && (
                    <TermsDetailWrapper>
                      {data?.detail[0][language?.toLocaleLowerCase()].map(
                        (item, index) => (
                          <Fragment key={index}>
                            <TermsDetailTitle>
                              {item.detailTitle}
                            </TermsDetailTitle>
                            <TermsDetailItem>
                              {item.detailContent}
                            </TermsDetailItem>
                          </Fragment>
                        )
                      )}
                    </TermsDetailWrapper>
                  )}
                </ContentContainer>
              ))}
            </Fragment>
          )}

          {activeTab === "mining" && (
            <Fragment>
              <DefinitionsWrapper>
                {language?.toLocaleLowerCase() === "ch"
                  ? "「AVA」或「我們」是指ACHIEVER ROBOTRADE TECHNOLOGY LIMITED。"
                  : "“AVA” or “We” or “us” are referred to ACHIEVER ROBOTRADE TECHNOLOGY LIMITED."}
              </DefinitionsWrapper>
              {miningTerms.map((data) => (
                <ContentContainer key={data.id}>
                  <TermsTitleWrapper>
                    {data.id}. {data.title[0][language?.toLocaleLowerCase()]}
                  </TermsTitleWrapper>
                  <TermsWrapper>
                    {data?.content?.[0]?.[language?.toLocaleLowerCase()]?.map(
                      (item, index) => (
                        <TermsItem key={index}>{item}</TermsItem>
                      )
                    )}
                  </TermsWrapper>
                  {data?.detail && (
                    <TermsDetailWrapper>
                      {data?.detail[0][language?.toLocaleLowerCase()].map(
                        (item, index) => (
                          <Fragment key={index}>
                            <TermsDetailTitle>
                              {item.detailTitle}
                            </TermsDetailTitle>
                            <TermsDetailItem>
                              {item.detailContent}
                            </TermsDetailItem>
                          </Fragment>
                        )
                      )}
                    </TermsDetailWrapper>
                  )}
                  <TermsWrapper>
                    {data?.subContent?.[0][language?.toLocaleLowerCase()]?.map(
                      (item, index) => (
                        <TermsItem key={index}>{item}</TermsItem>
                      )
                    )}
                  </TermsWrapper>
                </ContentContainer>
              ))}
            </Fragment>
          )}
        </ContentWrapper>
      </Container>
    </Wrapper>
  );
};

export default TermsOfService;
