import { useEffect, useState } from 'react'
import { API_GetAhftReturnGroup } from '../../../utils/api';
import Swal from 'sweetalert2/dist/sweetalert2';
import { AiOutlineCheckCircle, AiOutlineDown } from 'react-icons/ai';

const ReturnHistory = () => {
  const handleGetReturnHistory = async () => {
    const res = await API_GetAhftReturnGroup();
    if (res?.ResultCode > 990) return console.error(res?.Message);
    if (res?.ResultCode !== 0) return Swal.fire(res?.Message);
    setHistory([...res?.AhftGroups.map((data) => ({ ...data, isOpen: false}))]);
  };

  const handleToggleDetail = (index) => {
    if (openDetailId !== -1 && openDetailId === index) return setOpenDetailId(-1);
    setOpenDetailId(index);
  };

  const [history, setHistory] = useState([]);
  const [openDetailId, setOpenDetailId] = useState(-1);

  useEffect(() => {
    handleGetReturnHistory();
  }, []);

  return (
    <div className='text-[0.8rem] sm:text-[1rem] w-full'>
      <h3 className='py-8'>Return History</h3>
      <div className='hidden xl:block text-[1.2rem] font-bold text-sub'>
        <div className='w-[60vw] flex items-center py-2'>
          <div className='flex-1'>IsReturn</div>
          <div className='flex-[3]'>Address</div>
          <div className='flex-1'>Asset</div>
          <div className='flex-1'>TotalAmount</div>
          <div className='flex-1'>CreateTime</div>
          <div className='flex-[0.5]'></div>
        </div>
      </div>
      <div className='h-[50vh] overflow-y-auto'>
        {history?.length === 0 && <div className='py-8 text-sub font-bold'>No Data</div>}
        {history?.map(({Address, AhftGroupInfo, Asset, CreateTime, IsReturn, TotalReturnAmount}, index) => {
          return (
            <div key={index} className='w-[60vw] flex flex-wrap'>
              <div className={`w-full flex flex-wrap xl:flex-nowrap items-center py-4 border-t cursor-pointer ${index === openDetailId ? 'xl:bg-[#ccc2]' : 'xl:hover:bg-[#ccc2]'}`} onClick={() => handleToggleDetail(index)} >
                <div className='w-full xl:flex-1 flex py-2'>
                  <div className='xl:hidden flex-1 text-left'>IsReturn</div>
                  <div className='flex-[1.5] text-left xl:text-center'>
                    {IsReturn ? <AiOutlineCheckCircle className='xl:mx-auto text-blue text-[1.5rem]' /> : <div className='xl:mx-auto border border-[2px] rounded-full w-[1.32rem] h-[1.32rem]' />}
                  </div>
                </div>
                <div className='w-full xl:flex-[3] text-left flex'>
                  <div className='xl:hidden flex-1 text-left'>Address</div>
                  <div className='flex-[1.5] text-left xl:text-center'>
                    {Address?.length > 35 ? Address.substring(1, 35) + '...' : Address }
                  </div>
                </div>
                <div className='w-full xl:flex-1 flex py-2'>
                  <div className='xl:hidden flex-1 text-left'>Asset</div>
                  <div className='flex-[1.5] text-left xl:text-center'>
                    {Asset}
                  </div>
                </div>
                <div className='w-full xl:flex-1 flex py-2'>
                  <div className='xl:hidden flex-1 text-left'>TotalAmount</div>
                  <div className='flex-[1.5] text-left xl:text-center'>
                    {TotalReturnAmount.toFixed(2)}
                  </div>
                </div>
                <div className='w-full xl:flex-1 flex'>
                  <div className='xl:hidden flex-1 text-left'>CreateTime</div>
                  <div className='flex-[1.5] text-left xl:text-center'>
                    {CreateTime?.split(' ')[0]}
                  </div>
                </div>
                <div className='w-full xl:flex-[0.5] pt-4 xl:pb-4'>
                  <AiOutlineDown className={`mx-auto ${openDetailId === index ? 'rotate-180' : ''}`} />
                </div>
              </div>
              { openDetailId === index
                &&
                <div className='w-full py-4'>
                  <div key={index} className='hidden xl:flex w-[90%] py-4 mx-auto font-bold text-sub'>
                    <div className='flex-[3]'>GroupName</div>
                    <div className='flex-[0.5]'>Period</div>
                    <div className='flex-[2]'>ReturnAmount</div>
                    <div className='flex-1'>ReturyType</div>
                    <div className='flex-1'>EndTime</div>
                  </div>
                  {AhftGroupInfo?.length === 0 && <div className='py-8 text-sub font-bold'>No Data</div>}
                  {AhftGroupInfo?.map(({ GroupName, Period, ReturnAmount, ReturnType, EndTime}, index) => {
                    return (
                      <div key={index} className='py-4 flex flex-col xl:flex-row w-[90%] mx-auto border-t'>
                        <div className='w-full xl:flex-[3] flex'>
                          <div className='flex-1 text-left xl:hidden'>GroupName</div>
                          <div className='flex-[1.5] text-left xl:text-center'>{GroupName === '' ? '未命名的群組' : GroupName}</div>
                        </div>
                        <div className='w-full xl:flex-[0.5] flex'>
                          <div className='flex-1 text-left xl:hidden'>Period</div>
                          <div className='flex-[1.5] text-left xl:text-center'>{Period}</div>
                        </div>
                        <div className='w-full xl:flex-[2] flex'>
                          <div className='flex-1 text-left xl:hidden'>ReturnAmount</div>
                          <div className='flex-[1.5] text-left xl:text-center'>{ReturnAmount}</div>
                        </div>
                        <div className='w-full xl:flex-1 flex'>
                          <div className='flex-1 text-left xl:hidden'>ReturnType</div>
                          <div className='flex-[1.5] text-left xl:text-center'>{ReturnType}</div>
                        </div>
                        <div className='w-full xl:flex-1 flex'>
                          <div className='flex-1 text-left xl:hidden'>EndTime</div>
                          <div className='flex-[1.5] text-left xl:text-center'>{EndTime?.split(' ')[0]}</div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              }
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ReturnHistory
