import styled from "styled-components";
import { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";
import { useState, useRef } from "react";
import Lang from "../../global/Lang";
import Icons from "../../global/icons";
import { useSelector } from "react-redux";
import { selectSystem } from "../../slices/systemSlice";
import { useCallback } from "react";

// Chart.JS
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useEffect } from "react";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

import { Autoplay, Navigation, Pagination } from "swiper";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css";

// styled components
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: min(max(1.25vw, 22px), 24px);
  color: #fcfcfc;
  padding-right: 10px;
  text-transform: capitalize;

  ${RWD_MD(css`
    font-size: min(max(1.145vw, 20px), 22px);
  `)};
`;

const TabWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 20px;

  /* overflow-x: auto; */
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  height: 50px;
`;

const TabItem = styled.div`
  padding: 0.78125vw 1.3vw;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  /* height: 100%; */

  text-align: center;
  /* margin-right: 20px; */
  color: #fcfcfc;
  cursor: pointer;
  border-radius: 8px;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  /* white-space: nowrap; */
  border: ${(props) =>
    props.activeTab
      ? "1px solid #0AECD1"
      : "1px solid rgba(255, 255, 255, 0.15)"};
  font-size: min(max(0.833vw, 14px), 16px);

  ${RWD_MD(css`
    /* width: 150px; */
    padding: 10px 10px;
    text-align: center;
  `)};
`;

const PrevButton = styled.div`
  /* width: 40px;
  height: 40px; */

  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(90deg);

  cursor: pointer;
  &:hover {
    & path {
      stroke: ${(props) => (props.isEnd ? "" : "#0AECD1")};
    }
  }

  ${RWD_LG(css`
    transform: rotate(90deg);
    width: 20px;
    height: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  `)};
`;

const NextButton = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: rotate(-90deg);

  &:hover {
    & path {
      stroke: ${(props) => (props.isEnd ? "" : "#0AECD1")};
    }
  }

  ${RWD_LG(css`
    transform: rotate(-90deg);
    width: 20px;
    height: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  `)};
`;

const ChartWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  /* background-color: #22254a; */
`;

const ChartContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ChartName = styled.div`
  font-size: 1.5625vw;
  padding-bottom: 10px;
`;

const ChartDirections = styled.div`
  font-size: min(max(1.0416vw, 14px), 20px);
  padding-bottom: 15px;
  border-bottom: 1px solid #3a3d5a;
  color: #fcfcfc;
`;

const ChartTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;
  color: #fcfcfc;
`;

const ChartTitle = styled.div`
  font-size: min(max(0.833vw, 14px), 16px);
`;

const TooltipWrapper = styled.div`
  position: relative;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 3px;
  cursor: help;

  &:hover span {
    visibility: visible;
  }
`;

const TooltipContainer = styled.div`
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #c0c0c0;
  padding-top: 1px;
  display: flex;

  svg {
    width: 20px;
    height: 20px;
  }

  & path {
    stroke: #c0c0c0;
  }
`;

const TooltipText = styled.span`
  visibility: hidden;
  width: auto;
  background-color: #54587b;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: min(max(0.833vw, 14px), 16px);
  white-space: nowrap;
  position: absolute;
  left: calc(1.3vw + 15px);
  z-index: 1;
`;

const ChartComponentContainer = styled.div`
  display: flex;
  height: 50vh;
  align-items: center;
  margin-bottom: auto;

  ${RWD_LG(css`
    height: 40vw;
  `)};

  ${RWD_MD(css`
    height: 250px;
  `)};
`;

const TargetTabWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
`;

const TargetTabContainer = styled.div`
  /* width: calc(100% - 45px); */
  display: flex;
  width: 100%;
`;

const TargetTab = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* flex: 1; */

  padding: 0.5vw 1vw;
  border-radius: 8px;
  border: ${(props) =>
    props.activeTab
      ? "1px solid #0AECD1"
      : "1px solid rgba(255, 255, 255, 0.15)"};
  color: #fcfcfc;

  margin-right: 10px;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  cursor: pointer;

  ${RWD_MD(css`
    /* width: 150px; */
    padding: 8px 10px;
    text-align: center;
  `)};
`;

const TargetTabIcon = styled.div`
  width: 25px;
  height: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const TargetTabText = styled.div`
  padding-left: 10px;
`;

// Chart Component
const Chart = ({ chartData }) => {
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },

    scales: {
      x: {
        ticks: {
          color: "#fcfcfc",
        },
        grid: {
          color: function () {
            return "transparent";
          },
        },
      },
      y: {
        border: {
          display: false,
        },
        ticks: {
          color: "#fcfcfc",
          callback: function (value) {
            return `${value} % `;
          },
        },
        grid: {
          color: function () {
            return "rgba(255, 255, 255, 0.3)";
          },
        },
      },
    },
  };
  const labels = Array.from({ length: chartData.length }, (v, i) => i);
  const data = {
    labels,
    datasets: [
      {
        data: chartData.map((data) => (data / 10000) * 100),
        borderColor: "#2F80ED",
        backgroundColor: "#2F80ED",
        pointRadius: 2,
      },
    ],
  };
  return <Line options={options} data={data} />;
};

const Introduce = () => {
  const airobots = useSelector(selectSystem).airobots;
  const [targetList, setTargetList] = useState();

  const [activeTab, setActiveTab] = useState("");
  const [selectTarget, setSelectTarget] = useState("");

  useEffect(() => {
    setTargetList(airobots.filter((data) => data.Id === activeTab)[0]?.Targets);
    // 預設選擇第一個Target
    setSelectTarget(
      airobots.filter((data) => data.Id === activeTab)[0]?.Targets?.[0]?.Target
    );
  }, [activeTab]);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const [swiperView, setSwiperView] = useState(3);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (windowSize[0] <= "1024") {
      if (windowSize[0] <= "430") {
        setSwiperView(3);
      }
    } else {
      setSwiperView(3);
    }
  }, [windowSize]);

  useEffect(() => {
    setActiveTab(airobots.filter((el) => el.RobotData !== null)[0]?.Id);
  }, [airobots]);

  const handleTab = (id) => {
    setActiveTab(id);
  };

  const handleTarget = (target) => {
    setSelectTarget(target);
  };

  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <Wrapper>
      <TabWrapper>
        <PrevButton
          onClick={handlePrev}
          isEnd={sliderRef.current?.swiper.isBeginning}
        >
          <Icons.Arrow />
        </PrevButton>
        <Swiper
          spaceBetween={10}
          slidesPerView={swiperView}
          // className="mySwiper"
          ref={sliderRef}
          modules={[Navigation]}
        >
          {airobots
            .filter((el) => el.RobotData !== null)
            .map((data) => (
              <SwiperSlide key={data.Id}>
                <TabContainer>
                  <TabItem
                    activeTab={data.Id === activeTab}
                    onClick={() => handleTab(data.Id)}
                  >
                    <Lang
                      datakey={data.NameLanguageKey}
                      defaultText={data.NameLanguageKey}
                    />
                  </TabItem>
                </TabContainer>
              </SwiperSlide>
            ))}
          {/* <TabContainer>
          </TabContainer> */}
        </Swiper>
        <NextButton
          onClick={handleNext}
          isEnd={sliderRef.current?.swiper.isEnd}
        >
          <Icons.Arrow />
        </NextButton>
        {/* <TabContainer>
          {airobots
            .filter((el) => el.RobotData !== null)
            .map((data) => (
              <TabItem
                key={data.Id}
                activeTab={data.Id === activeTab}
                onClick={() => handleTab(data.Id)}
              >
                <Lang
                  datakey={data.NameLanguageKey}
                  defaultText={data.NameLanguageKey}
                />
              </TabItem>
            ))}
        </TabContainer> */}
      </TabWrapper>

      <Title>Target</Title>

      <TargetTabWrapper>
        <TargetTabContainer>
          {targetList?.map((data) => (
            <TargetTab
              key={data.Target}
              onClick={() => handleTarget(data.Target)}
              activeTab={data.Target === selectTarget}
            >
              <TargetTabIcon>
                <img src={data.Icon} />
              </TargetTabIcon>
              <TargetTabText>{data.Target}</TargetTabText>
            </TargetTab>
          ))}
        </TargetTabContainer>
      </TargetTabWrapper>

      {airobots
        .filter((data) => data.Id === activeTab)[0]
        ?.Targets?.filter((el) => el.Target === selectTarget)
        .map((item) => (
          <ChartWrapper key={item.Id}>
            <ChartContainer>
              <ChartDirections>
                <Lang
                  datakey={item.SloganLanguageKey}
                  defaultText={item.SloganLanguageKey}
                />
              </ChartDirections>
              <ChartTitleWrapper>
                <ChartTitle>
                  <Lang
                    datakey="finance_linechart"
                    defaultText="Fund change line chart"
                  />
                </ChartTitle>
                <TooltipWrapper>
                  <TooltipContainer>
                    <Icons.Tooltip />
                  </TooltipContainer>
                  <TooltipText>
                    <Lang
                      datakey="finance_linechart"
                      defaultText="Fund change line chart"
                      toolTip={true}
                    />
                  </TooltipText>
                </TooltipWrapper>
              </ChartTitleWrapper>
              <ChartComponentContainer>
                <Chart chartData={item?.RobotData?.split(",") ?? []} />
              </ChartComponentContainer>
            </ChartContainer>
          </ChartWrapper>
        ))}
    </Wrapper>
  );
};

export default Introduce;
