import { Outlet, Navigate } from 'react-router-dom';
import SideMenu from './SideMenu';

// --- plugins
import styled from 'styled-components';
import { routes } from '../../components/Router';

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
`;

const SideMenuContainer = styled.div`
    width: 15vw;
    height: 100%;
    padding: 2rem 0;

    background: #1976d2;
    color: #fff;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
`;

const OutletContainer = styled.div`
    flex: 1;
    padding: 2rem;
    overflow: auto;
`;

const Layout = () => {
    const login = routes.backend_login;

    if (!localStorage.getItem('systemToken')) {
        return <Navigate to={login} />
    }

    return (
        <Container>
            <SideMenuContainer>
                <SideMenu />
            </SideMenuContainer>

            <OutletContainer>
                <Outlet />
            </OutletContainer>
        </Container>
    )
}

export default Layout
