import styled, { css } from "styled-components";
import Icons from "../../global/icons";

import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Wrapper = styled.div`
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  /* border: 1px solid red; */
`;

const PeriodMinedWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 15px;
  justify-content: flex-end;
  cursor: pointer;
`;

const PeriodMinedIcon = styled.div`
  width: 15px;
  height: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  & path {
    fill: ${(props) => (props.isShow ? "#151944" : "#ccc")};
  }

  svg {
    width: 15px;
    height: 15px;
  }
`;

const PeriodMinedText = styled.div`
  padding-left: 5px;
  font-size: min(max(0.9375vw, 12px), 18px);
  color: ${(props) => (props.isShow ? "#151944" : "#ccc")};
`;

const ChartWrapper = styled.div`
  width: 100%;
  /* width: 95%; */
`;

const MiningBarChart = ({ chartData, date, selectDate }) => {
  const [indexDay, setIndexDay] = useState("");

  const [isShow, setIsShow] = useState(true);

  useEffect(() => {
    if (selectDate == "") {
      setIndexDay(date.length - 1);
    } else {
      setIndexDay(date.findIndex((day) => day === selectDate));
    }
  }, [selectDate]);

  const options = {
    responsive: true,
    animation: {
      duration: 0,
    },

    indexAxis: "y",
    scales: {
      x: {
        stacked: true,
        ticks: {
          callback: function (value) {
            return `${value} % `;
          },
        },
      },
      y: {
        stacked: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const calculator = (arr, target) => {
    return arr.map((data, index, array) => {
      let subArray = array.slice(0, index + 1);
      let sum = subArray.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      return sum;
    });
  };
  // 加上總收益 profit 加起來

  const profit = chartData.map((data) => {
    return {
      Date: date,
      Target: data.Target,
      TotalProfitPercent: data.ProfitPercent.map((item) => item * 100).reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      ),
      ProfitPercent: data.ProfitPercent.map((profit) => profit * 100),
    };
  });

  const lastSum = profit
    .map((data) => data.ProfitPercent)
    .map((el) =>
      el
        .slice(0, indexDay)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    );

  const period = profit.map((data) => data.ProfitPercent[indexDay]);

  const total = profit.map((data) => data.TotalProfitPercent);

  const calculat = profit.map(
    (data, index) => total[index] - lastSum[index] - period[index]
  );

  const data = {
    labels: chartData.map((data) => data.Target),

    datasets: [
      {
        backgroundColor: "#151944",
        data: lastSum,
      },
      {
        backgroundColor: isShow ? "#0aecd1" : "#151944",
        data: period,
      },
      {
        backgroundColor: "#151944",
        data: calculat,
      },
    ],
  };

  //   console.log(profit.map((data) => data.ProfitPercent[indexDay]));

  return (
    <Wrapper>
      <Container>
        <PeriodMinedWrapper onClick={() => setIsShow(!isShow)}>
          <PeriodMinedIcon isShow={isShow}>
            <Icons.Check />
          </PeriodMinedIcon>
          <PeriodMinedText isShow={isShow}>Period Mined</PeriodMinedText>
        </PeriodMinedWrapper>

        <ChartWrapper>
          <Bar options={options} data={data} />
        </ChartWrapper>
      </Container>
    </Wrapper>
  );
};

export default MiningBarChart;
