import { FiAlertCircle } from 'react-icons/fi';

const Alert = ({ className, message }) => {
  return (
    <div className={`
      w-full p-3 my-3 border border-alert rounded child:text-alert
      flex items-center
      ${className}
    `}>
      <FiAlertCircle className='mr-3' />
      <span>{message}</span>
    </div>
  )
}

export default Alert
