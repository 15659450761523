import { useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

const Performance = ({ data }) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  const options = {
    // responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Minning Performance',
      },
    },
    // scales: {
    //   y: {
    //     min: 0,
    //     ticks: {
    //       callback: function(value){return value+ "%"}
    //     }
    //   }
    // }
  };

  // - demo data
  // const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  // const data = {
  //   labels,
  //   datasets: [
  //     {
  //       label: 'Dataset 1',
  //       data: labels.map(() => Math.random() * 50),
  //       borderColor: 'rgb(255, 99, 132)',
  //       backgroundColor: 'rgba(255, 99, 132, 0.5)',
  //     },
  //     {
  //       label: 'Dataset 2',
  //       data: labels.map(() => Math.random() * 50),
  //       borderColor: 'rgb(53, 162, 235)',
  //       backgroundColor: 'rgba(53, 162, 235, 0.5)',
  //     },
  //   ],
  // };

  // const { setIsWaitApi, waitingApi, setWaitingApi } = useData();
  // const [labels, setLabels] = useState([]);

  return (
    <div className='py-10'>
      <h3 className='py-2'>Minning Performance</h3>
      <div className='overflow-x-auto'>
        <div className='w-full min-w-[600px]'>
          {/* {console.log(data)} */}
          <Line options={options} data={data ?? { labels: [], datasets: []}} />
        </div>
      </div>
    </div>
  )
}

export default Performance