import { Link, useLocation, matchPath, useNavigate } from 'react-router-dom';
import { useData } from '../../utils/context';

// --- components ---
import Logo from './Logo';

// --- images ---
import light from '../../img/light.png';
import dark from '../../img/dark.png';
import { DashboardNavbar } from './Navbar';
import ToggleTheme from './ToggleTheme';
import { removeAuthToken } from '../../v2/services/common';

// --- dashboard page ---
const Asidebar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    removeAuthToken();
    navigate('/AVA', { replace: true })
  }

  const { pathname } = useLocation();
  const isCurrentPathMatch = path => Boolean(matchPath(path, pathname));

  const { isDarkMode, setIsDarkMode } = useData();


  return (
    <aside className='
      fixed z-20 top-0 left-0 h-screen w-[420px] lg:w-[240px] hidden
      lg:flex lg:flex-col
      bg-white-menu dark:bg-black-menu
    '>
      {/* --- logo --- */}
      <Logo className='w-[100px] mx-auto mt-6 mb-12' />

      {/* --- nav --- */}
      <DashboardNavbar />

      {/* --- others --- */}
      <ul>
        <li className={`
          block px-5 py-3
          border-l border-l-[12px] lg:border-l-[7px] border-transparent
          ${isCurrentPathMatch('/AVA/setting') && 'bg-white dark:bg-black border-l-primary'}
        `}>
          <Link to={`/AVA/setting`} className='uppercase block'>setting</Link>
        </li>
        {/* <li className={`
          block px-5 py-3
          border-l border-l-[12px] lg:border-l-[7px] border-transparent
          ${isCurrentPathMatch('/AVA/help') && 'bg-white dark:bg-black border-l-primary'}
        `}>
          <Link to='/AVA/help' className='uppercase block'>help</Link>
        </li> */}
        <li className={`
          block px-5 py-3
          border-l border-l-[12px] lg:border-l-[7px] border-transparent
        `}>
          <button
            className='uppercase block'
            onClick={handleLogout}
          >
            logout
          </button>
        </li>
        {/* <li
          className='block px-5 py-3 cursor-pointer'
          onClick={() => setIsDarkMode(isDarkMode ? false : true)}
        >
          <ToggleTheme />
        </li> */}
      </ul>
    </aside>
  )
}

export default Asidebar
