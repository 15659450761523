import { FiAlertCircle } from 'react-icons/fi';
import { MdPlaylistAdd } from 'react-icons/md';

export const StyledInput = ({
  id,
  enableEdit = true,
  padding = 'px-4 py-1',
  className = '',
  inputClass = '',
  type = 'text',
  value,
  onChange,
  onFocus,
  placeholder,
}) => {
  if (enableEdit) return (
    <div className={`
      w-full border border-sub-word rounded
      bg-white-main dark:bg-black-main
      ${padding} ${className}
    `}>
      <input
        id={id}
        className={`
          inline-block w-full border-none bg-none focus:outline-none
          ${inputClass}
        `}
        type={type}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={placeholder}
      />
    </div>
  );
  return <StyledLabel value={value} />
};
export const StyledLabel = ({
  className = '',
  padding = '',
  htmlFor = '',
  value,
  prefixIcon,
  suffixIcon,
  onClick,
}) => {
  return (
    <div
      className={`
        w-full flex items-center
        ${padding} ${className}
      `}
      onClick={onClick}
    >
      {!!prefixIcon && <StyledIcon icon={prefixIcon} className='mr-2' />}
      <span>
        {value}
      </span>
      {!!suffixIcon && <StyledIcon icon={suffixIcon} className='ml-2' />}
    </div>
  );
};
export const StyledButton = ({
  enable = true,
  outline = false,
  style = 'primary',
  padding = 'py-2 px-4',
  className = '',
  value,
  onClick,
}) => {
  const styled = `
    ${enable ? 'cursor-pointer' : 'cursor-default pointer-events-none'}
    ${outline ? 'bg-none' : ''}
    ${(enable && outline) ? (style === 'primary' ? 'text-blue' : 'text-sub') : ''}
    ${!outline && (enable ? 'text-reverse' : 'text-gray')}
    ${enable && outline && style === 'primary' ? 'border border-sub-blue dark:border-sub-blue' : ''}
    ${(!enable || (outline && style === 'sub')) ? 'border border-sub-word dark:border-sub-word' : ''}
    ${enable && !outline && style === 'primary' ? 'bg-blue' : ''}
    ${(!enable || (!outline && style === 'sub')) ? 'bg-sub' : ''}
  `;
  return (
    <button
      className={`
        block w-full uppercase rounded
        ${padding} ${className}
        ${styled}
      `}
      onClick={onClick}
    >
      {value}
    </button>
  );
};
export const StyledAlert = ({
  className = '',
  padding = 'px-4 py-2',
  htmlFor,
  value,
  prefixIcon = <FiAlertCircle />,
  suffixIcon = null,
}) => {
  return (
    <div className={`
      flex items-center
      border border-alert text-fail rounded
      ${padding} ${className}
    `}>
      {!!prefixIcon && <StyledIcon icon={prefixIcon} className='mr-2' />}
      <label
        htmlFor={htmlFor}
        className={`${className}`}
      >
        {value}
      </label>
      {!!suffixIcon && <StyledIcon icon={suffixIcon} className='ml-2' />}
    </div>
  );
};
export const StyledCard = ({
  padding = 'px-4 py-2',
  title,
  titleClass = '',
  prefixIcon,
  suffixIcon,
  className = '',
  children,
}) => {
  return (
    <div className={`
      shadow-lightcard dark:shadow-card rounded w-full h-full
      ${padding} ${className}
    `}>
      {
        (!!title || !!prefixIcon || !!suffixIcon)
        &&
        <div className='
          p-1 flex items-center
          border-b border-b-[3px] border-b-sub-blue
        '>
          {!!prefixIcon && <StyledIcon icon={prefixIcon} className='mr-2' />}
          {
            !!title
            &&
            <h3 className={`flex-1 font-bold text-[0.875rem] text-blue ${titleClass}`}>
              {title}
            </h3>
          }
          {!!suffixIcon && <StyledIcon icon={suffixIcon} className='ml-2' />}
        </div>
      }
      {children}
    </div>
  );
};
export const StyledShortcut = ({
  size,
  className = 'uppercase',
  padding = 'p-2',
  onClick,
  icon = <MdPlaylistAdd className='text-[5.5rem] mb-1' />,
  text,
  enable = true,
}) => {
  const customSize = !!size
    ? `w-[${size}px] h-[${size}px]`
    : 'w-[120px] h-[120px]';
  return (
    <div className={`${padding} ${className} w-full h-full flex items-center`}>
      <div className={`
        relative rounded flex justify-center items-center
        ${enable ? 'bg-blue' : 'bg-sub'}
      `}>
        <div className={`
          ${customSize}
          text-reverse flex flex-col  items-center justify-center
        `}>
          <StyledIcon icon={icon} />
          <span className='block text-[0.8rem] font-bold'>
            {text}
          </span>
        </div>

        <div
          className={`
            absolute z-1 top-0 left-0 w-full h-full
            ${enable ? 'cursor-pointer' : 'cursor-pointer pointer-events-none'}
          `}
          onClick={onClick}
        />
      </div>
    </div>
  );
};
export const StyledIcon = ({
  className = '',
  icon
}) => {
  return (
    <span className={`${className}`}>
      {icon}
    </span>
  )
};
export const StyledSelect = ({
  id,
  className = '',
  defaultLabelClass = 'uppercase font-bold mr-2',
  defaultSelectClass = 'text-blue',
  padding = 'px-4 py-1',
  label,
  options,
  selectedValue,
  onChange,
  outline = true,
}) => {
  return (
    <div className={`
      flex items-center rounded
      ${outline && 'border border-sub-word'}
      bg-white-main dark:bg-black-main
      ${padding} ${className}
    `}>
      {
        !!label
        &&
        <label className={`${defaultLabelClass}`}>
          {label}
        </label>
      }
      <select
        className={`
          uppercase w-full text-center 
          bg-none outline-none
          ${defaultSelectClass}
        `}
        value={selectedValue}
        onChange={onChange}
      >
        {
          options?.map(({ text, value }, index) => (
            <option
              id={id}
              key={index}
              value={value}
              className={selectedValue === value ? 'text-blue' : 'text-sub'}
            >
              {text}
            </option>
          ))
        }
      </select>
    </div>
  );
};
export const StyledNoContent = ({
  className
}) => {
  return (
    <div className={`
      w-full h-full flex justify-center items-center
      font-bold text-sub text-[1.2rem]
      ${className}
    `}>
      NO DATA
    </div>
  )
};
export const StyledTag = ({
  isPass,
  passClass = 'bg-pass',
  failClass = 'bg-fail',
  passText = 'Success',
  failText = 'Failed',
  className,
}) => {
  return (
    <span className={`
      inline-block px-2 sm:px-5 my-1 py-1 whitespace-nowrap text-[0.8rem] leading-5 rounded-sm
      text-white dark:text-whtie
      ${isPass ? passClass : failClass} ${className}
    `}>
      {isPass ? passText : failText}
    </span>
  );
};
export const ParseHtml = ({
  html
}) => {
  if (!html) return;
  return (
    html?.split(/<br>|<br \/>/).map((str, index) => {
      if (str.includes('<a href=')) {
        return (
          <a key={index} href={str.match(/(?:^|\s)href=\"(.*?)(?:\/\")/)[1]}>
            {str.match(/(?:\">)(.*?)(?:<\/a)/)[1]}
          </a>
        );
      }
      return <span key={index}>{str}<br /></span>;
    })
  )
};