export const data = {
  en: {
    lang: 'En',
    title: 'Title',
    nav: [
      // {label: 'about', path:'/about'},
      // {label: 'cloud mining', path:'/cloud'},
      {label: 'faq', path:'/faq'},
      {label: 'tutorials', path:'/tutorials'}
    ],
    register: 'register',
    login: 'login',
    seeFeatures: 'see features',
    slogan1: 'Trade',
    slogan2: '>Stake=Hodl',
    bannerText: 'Our performance over the past three years is far better than that from Staking tools and Hodl Strategies.',
    bannerList: [
      'Low Risk.',
      'Divergent Exchange.',
      'Well Performed Strategies.',
      'Bull Market.',
      'Bear Market.',
    ],
    sliderTitle: [
      'Title',
      'Title',
      'Title',
      'Title',
      'Title',
    ],
    faqs: [
      {
        q: 'FAQ Question Number1 ?',
        a: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna elementum in ac eu nulla ac auctor vestibulum fames. Egestas nunc, etiam maecenas dolor pulvinar.'
      },
      {
        q: 'FAQ Question Number2 ?',
        a: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna elementum in ac eu nulla ac auctor vestibulum fames. Egestas nunc, etiam maecenas dolor pulvinar.'
      },
      {
        q: 'FAQ Question Number3 ?',
        a: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna elementum in ac eu nulla ac auctor vestibulum fames. Egestas nunc, etiam maecenas dolor pulvinar.'
      },
      {
        q: 'FAQ Question Number4 ?',
        a: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna elementum in ac eu nulla ac auctor vestibulum fames. Egestas nunc, etiam maecenas dolor pulvinar.'
      },
      {
        q: 'FAQ Question Number5 ?',
        a: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna elementum in ac eu nulla ac auctor vestibulum fames. Egestas nunc, etiam maecenas dolor pulvinar.'
      },
      {
        q: 'FAQ Question Number6 ?',
        a: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna elementum in ac eu nulla ac auctor vestibulum fames. Egestas nunc, etiam maecenas dolor pulvinar.'
      },
    ],
    tutorialCategory: [
      'Binance',
      'Bybit',
    ],
    start: 'start now',
    footer: {
      'Mobile app': [
        'Features',
        'Live share',
        'Video recore'
      ],
      'Commnity': [
        'Featured',
        'Astists',
        'The Portal',
        'Live events'
      ],
      'Company': [
        'About us',
        'Contact us',
        'History'
      ]
    }
  },
  zh: {
    lang: '中',
    title: '標題',
    nav: [
      // {label: '關於', path:'/about'},
      // {label: '雲端運算', path:'/cloud'},
      {label: '常見問題', path:'/faq'},
      {label: '教學', path:'/tutorials'}
    ],
    register: '註冊',
    login: '登入',
    seeFeatures: '功能介紹',
    slogan1: '標語一行',
    slogan2: '可能兩行！',
    bannerText: '一段描述文字，可能有兩行，或有更多航客能三三三三三三三三三三三三三三三三三三三三三三三三三三三三三三三三三三三三三三三三三三行',
    bannerList: [
      '一行描述',
      '一行描述',
      '一行描述',
      '一行描述',
    ],
    sliderTitle: [
      '標題',
      '標題',
      '標題',
      '標題',
      '標題',
    ],
    faqs: [
      {
        q: '問題問題問題1 ?',
        a: '問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答。'
      },
      {
        q: '問題問題問題2 ?',
        a: '問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答。'
      },
      {
        q: '問題問題問題3 ?',
        a: '問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答。'
      },
      {
        q: '問題問題問題4 ?',
        a: '問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答。'
      },
      {
        q: '問題問題問題5 ?',
        a: '問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答。'
      },
      {
        q: '問題問題問題6 ?',
        a: '問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答問題解答。'
      },
    ],
    tutorialCategory: [
      '教學類別一',
      '教學類別二',
      '教學類別三',
    ],
    start: '立即體驗',
    footer: {
      'Mobile app': [
        'Features',
        'Live share',
        'Video recore'
      ],
      'Commnity': [
        'Featured',
        'astists',
        'The Portal',
        'Live events'
      ],
      'Company': [
        'About us',
        'Contact us',
        'History'
      ]
    }
  }
};