import styled, { css } from "styled-components";
import { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { selectSystem } from "../../../slices/systemSlice";
import Icons from "../../../global/icons";
import { routes } from "../../../../components/Router";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";
import Lang from "../../../global/Lang";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;
  width: 100%;
  max-width: 40vw;

  ${RWD_MD(css`
    max-width: 80%;
  `)};

  ${RWD_SM(css`
    max-width: 100%;
  `)};
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LabelEl = styled.label`
  padding-bottom: 5px;
`;

const InputEl = styled.input`
  background: #fcfcfc;
  padding: 1.0416vw 1.5625vw;
  width: 100%;
  border-radius: 100px;
  color: #151944;

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 14px), 16px);
    padding: 10px 20px;
  `)};
`;

const IconContainer = styled.div`
  transform: ${(props) => (props.isOpen ? "scaleY(-1)" : "scaleY(1)")};
  transition: all 0.3s;
`;

const ExchangeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  color: #22254a;
  max-width: 40vw;

  ${RWD_MD(css`
    max-width: 80%;
  `)};

  ${RWD_SM(css`
    max-width: 100%;
  `)};
`;

const SelectWrapper = styled.div`
  position: relative;
  background: #cefbf8;
  /* background: ${(props) =>
    props.colorType === "white"
      ? "#d9d9d9;"
      : "linear-gradient(155.14deg,rgba(255, 255, 255, 0) -2.13%,rgba(255, 255, 255, 0.15) 136.58%)"}; */

  border: 1px solid rgba(255, 255, 255, 0.15);
  border-bottom: ${(props) =>
    props.isOpen
      ? "1px solid transparent"
      : "1px solid 1px solid rgba(255, 255, 255, 0.15)"};
  border-radius: ${(props) => (props.isOpen ? "25px 25px 0 0" : "100px")};
  cursor: pointer;
`;

const SelectItem = styled.div`
  padding: 1.0416vw 1.5625vw;
  width: 100%;
  display: flex;
  justify-content: space-between;

  ${RWD_MD(css`
    /* font-size: min(max(0.833vw, 14px), 16px); */
    padding: 10px 20px;
  `)};
`;

const SelectItemText = styled.div`
  width: 100%;
  text-align: center;
`;

const OptionWrapper = styled.ul`
  position: absolute;
  width: 100%;
  top: 100%;
  background: #cefbf8;
  border-left: 1px solid;
  border-right: 1px solid;
  border-bottom: 1px solid;
  z-index: 5;
  border-color: ${(props) =>
    props.colorType === "white" ? "#d9d9d9;" : "rgba(255, 255, 255, 0.15)"};
  border-radius: 0px 0px 25px 25px;
  overflow: hidden;
`;

const OptionItem = styled.li`
  padding: 10px 20px;
  background: ${(props) =>
    props.colorType === "white"
      ? "#d9d9d9;"
      : "linear-gradient(155.14deg,rgba(255, 255, 255, 0) -2.13%,rgba(255, 255, 255, 0.15) 136.58%)"};
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  cursor: pointer;

  &:hover {
    background-color: #e6fdfb;
    color: #22254a;
  }
`;

const Help = styled.div`
  padding-top: 5px;
  color: #54587b;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.3s linear;
  border-bottom: 1px solid transparent;

  &:hover {
    border-bottom: 1px solid #54587b;
  }
`;

const ApikeyStep = ({
  exchangeId,
  setExchangeId,
  setApiKey,
  setSecretkey,
  setAccount,
  setPassword,
}) => {
  const exchangeData = useSelector(selectSystem).exchanges;

  const [isOpen, setIsOpen] = useState(false);

  const handleSelectExchange = (id) => {
    setExchangeId(id);
    setIsOpen(false);
  };

  const handleGuide = () => {
    if (exchangeId === 1) {
      window.open(
        `${window.location.origin}${routes.ApiKeyBinance_v2}?guide=onboarding`,
        "_blank"
      );
    } else {
      window.open(
        `${window.location.origin}${routes.ApiKeyBybit_v2}?guide=onboarding`,
        "_blank"
      );
    }
  };

  return (
    <Wrapper>
      <Container>
        <ExchangeWrapper>
          {exchangeData && (
            <SelectWrapper onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
              <SelectItem>
                <SelectItemText>
                  {exchangeData
                    .filter((data) => data.ExchangeId === exchangeId)[0]
                    ?.ExchangeName.toUpperCase()}
                </SelectItemText>
                <IconContainer isOpen={isOpen}>
                  <Icons.Arrow />
                </IconContainer>
              </SelectItem>
            </SelectWrapper>
          )}
          {isOpen && (
            <OptionWrapper>
              {exchangeData
                // ?.filter((data) => data.ExchangeId !== 3)
                .map((item) => (
                  <OptionItem
                    value={item?.ExchangeId}
                    key={item?.ExchangeId}
                    onClick={() => handleSelectExchange(item?.ExchangeId)}
                  >
                    {item?.ExchangeName.toUpperCase()}
                  </OptionItem>
                ))}
            </OptionWrapper>
          )}
        </ExchangeWrapper>

        {exchangeId !== 4 && (
          <Help onClick={handleGuide}>If you don't know HOW, click here.</Help>
        )}

        {exchangeId !== 4 ? (
          <InputsWrapper>
            <InputWrapper>
              <LabelEl>
                <Lang datakey="input_apikey" defaultText="Input API Key" />
              </LabelEl>
              <InputEl
                key={exchangeId}
                onChange={(e) => setApiKey(e.target.value)}
              />
            </InputWrapper>
            <InputWrapper>
              <LabelEl>
                <Lang
                  datakey="input_secretkey"
                  defaultText="Input Secret Key"
                />
              </LabelEl>
              <InputEl
                key={exchangeId}
                onChange={(e) => setSecretkey(e.target.value)}
              />
            </InputWrapper>
          </InputsWrapper>
        ) : (
          <InputsWrapper>
            <InputWrapper>
              <LabelEl>
                <Lang datakey="input_apikey" defaultText="Input API Key" />
              </LabelEl>
              <InputEl
                key={exchangeId}
                onChange={(e) => setApiKey(e.target.value)}
              />
            </InputWrapper>
            <InputWrapper>
              <LabelEl>
                <Lang datakey="input_account" defaultText="Input Account" />
              </LabelEl>
              <InputEl
                key={exchangeId}
                onChange={(e) => setAccount(e.target.value)}
              />
            </InputWrapper>
            <InputWrapper>
              <LabelEl>
                <Lang datakey="input_password" defaultText="Input Password" />
              </LabelEl>
              <InputEl
                key={exchangeId}
                onChange={(e) => setPassword(e.target.value)}
              />
            </InputWrapper>
          </InputsWrapper>
        )}
      </Container>
    </Wrapper>
  );
};

export default ApikeyStep;
