import styled, { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";

import { QRCodeCanvas } from "qrcode.react";
import { useSelector } from "react-redux";
import { selectSystem } from "../../../slices/systemSlice";
import { useState, useEffect } from "react";

import { driver } from "driver.js";
import "driver.js/dist/driver.css";

import JoyrideComponent from "../../../global/JoyrideComponent";
import { useLocation, useNavigate } from "react-router-dom";
import Icons from "../../../global/icons";
import Lang from "../../../global/Lang";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  /* height: 100%;
  min-height: 45vh; */
`;

const Title = styled.div`
  font-size: min(max(1.25vw, 22px), 24px);
  color: #fcfcfc;
  padding-bottom: 20px;

  ${RWD_MD(css`
    font-size: min(max(1.0416vw, 18px), 20px);
  `)};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const TransferWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;

  ${RWD_MD(css`
    flex-direction: column;
  `)};
`;

const TransferTypeWrapper = styled.div`
  display: flex;
  width: 30%;
  flex-direction: column;
  gap: 20px;

  ${RWD_MD(css`
    width: 100%;
    flex-direction: row;
  `)};
`;

const TransferTitle = styled.div`
  color: #fcfcfc;
  margin-bottom: 10px;
  font-size: clamp(16px, 1.25vw, 24px);
`;

const TransferInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 1px solid rgba(255, 255, 255, 0.15); */
  min-height: 51vh;
  background-color: #22254a;
`;

const SystemAddressWrapper = styled.div`
  border: ${(props) =>
    props.active ? "1px solid rgba(255, 255, 255, 0.15)" : "1px solid #0aecd1"};
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  cursor: pointer;
  border-radius: 8px;
  padding: 20px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  flex: 1;
`;

const AvapointWrapper = styled.div`
  width: 100%;
  border: ${(props) =>
    props.active ? "1px solid #0aecd1" : "1px solid rgba(255, 255, 255, 0.15)"};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  /* flex-direction: column; */
  color: #fcfcfc;
  height: 100%;
  cursor: pointer;
  border-radius: 8px;
  padding: 20px 20px;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  flex: 1;
`;

const AvapointIcon = styled.div`
  width: clamp(65px, 4.1vw, 80px);
  height: clamp(65px, 4.1vw, 80px);
  display: inline-flex;

  svg {
    width: clamp(65px, 4.1vw, 80px);
    height: clamp(65px, 4.1vw, 80px);
  }

  path {
    fill: #fcfcfc;
  }
`;

const QrcodeContainer = styled.div`
  padding: 20px 0 10px 0;
  margin: auto;
`;

const AddressWrapper = styled.div`
  color: #fcfcfc;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  /* width: 70%; */
  font-size: min(max(1.0416vw, 18px), 20px);
`;

const AddressIcon = styled.div`
  width: clamp(65px, 4.1vw, 80px);
  height: clamp(65px, 4.1vw, 80px);
  display: inline-flex;

  svg {
    width: clamp(65px, 4.1vw, 80px);
    height: clamp(65px, 4.1vw, 80px);
  }

  /* path {
    fill: #fcfcfc;
  } */
`;

const AddresContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fcfcfc;
  flex-direction: column;

  ${RWD_LG(css`
    flex-direction: column;
  `)};
`;

const AddressTitle = styled.div`
  padding-bottom: 20px;
  text-align: center;
  font-size: clamp(16px, 1.25vw, 24px);
`;

const AddressContent = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.15);
  padding: 10px 20px;
  border-radius: 8px;
  /* margin-left: 10px; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;

  ${RWD_MD(css`
    width: 85vw;
    margin-left: 0;
  `)};
`;

const AddressCrypto = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.15);
  padding: 10px 20px;
  border-radius: 8px;
  width: auto;
  text-align: center;
  margin-right: 10px;
  white-space: nowrap;

  ${RWD_LG(css`
    width: auto;
    margin-bottom: 10px;
  `)};
`;

const AmountWrapper = styled.div`
  color: #fcfcfc;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 70%;
`;

const AmountTitle = styled.div`
  padding-bottom: 10px;
  text-align: center;
`;

const Amount = styled.div`
  font-size: min(max(1.5625vw, 28px), 30px);
  font-weight: bold;

  span {
    font-size: min(max(0.833vw, 16px), 18px);
    font-weight: lighter;
  }
`;

const CheckboxContainer = styled.div`
  font-size: min(max(0.833vw, 14px), 16px);
  padding-left: 10px;
  color: #fcfcfc;
  width: 100%;
`;

const CheckboxWrapper = styled.label`
  color: #151944;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 0;
  ${RWD_MD(css`
    justify-content: space-between;
  `)};
`;

const CheckBox = styled.input.attrs({ type: "checkbox" })`
  accent-color: #ccc;
  width: 20px;
  height: 20px;
  /* transform: scale(1.5); */
`;

//ReturnType

const Transfer = ({
  amount,
  setAddress,
  setAddressCheck,
  useavapoints,
  setUseavapoints,
  asset,
  setAsset,
}) => {
  const navigate = useNavigate();
  const depositAdd = useSelector(selectSystem)?.despositAddress;
  const [systmeAddress, setSystemAddress] = useState([]);

  useEffect(() => {
    const data = depositAdd.filter((token) => token?.Crypto === "BEP-20");
    setSystemAddress(data);
    setAddress(data[0]?.Address);
  }, [depositAdd]);

  const { search } = useLocation();
  useEffect(() => {
    const query = new URLSearchParams(search);
    const tutorial = query.get("tutorial");
    if (tutorial === "mining") {
      setJoyrideIsRun(true);
    }
  }, [search]);

  const handleJoyrideCallback = () => {
    navigate({
      pathname: "/v2/mining/",
      search: "?tutorial=mining",
    });
  };

  const [joyrideIsRun, setJoyrideIsRun] = useState(false);
  const joyrideState = [
    {
      target: ".createMining_confirmTransfer",
      content: (
        <Lang
          datakey="mining_tutorial_5-1"
          defaultText="Please transfer the USDT you wish to convert into a Package to this address, and ensure that the transfer chain you select is using BEP-20."
        />
      ),
      placement: "right",
      disableBeacon: true,
      disableOverlayClose: true,
      callbackAction: false,
    },
    {
      target: ".createMining_confirmTransfer",
      content: (
        <Lang
          datakey="mining_tutorial_5-2"
          defaultText="If you have not yet bound a withdrawal address that complies with the BEP-20 standard, the system will require you to bind a withdrawal address first when you click the 'Done' button."
        />
      ),
      placement: "right",
      disableBeacon: true,
      disableOverlayClose: true,
      callbackAction: false,
    },
    {
      target: ".createMining_confirmTransfer",
      content: (
        <Lang
          datakey="mining_tutorial_5-3"
          defaultText="You can deposit the Mining Package immediately, or deposit it later in the 'Waiting' tab under Mining summary."
        />
      ),
      placement: "right",
      disableBeacon: true,
      disableOverlayClose: true,
      callbackAction: true,
      function: handleJoyrideCallback,
    },
  ];

  // Tutorial

  const handleChooseTransferType = (type) => {
    console.log("click");
    if (type === "systemAddress") {
      setUseavapoints(false);
    } else {
      setUseavapoints(true);
    }
  };

  return (
    <Wrapper className="createMining_confirmTransfer">
      <JoyrideComponent
        joyrideState={joyrideState}
        run={joyrideIsRun}
        joyrideType={"mining"}
      />
      <Title>
        <Lang
          datakey="confirmation_transfer"
          defaultText="Confirmation & Transfer"
        />
      </Title>
      <Container>
        <TransferWrapper>
          <TransferTypeWrapper>
            <SystemAddressWrapper
              onClick={() => handleChooseTransferType("systemAddress")}
              active={useavapoints}
            >
              <TransferTitle>Tokens</TransferTitle>
              <AddressIcon>
                <Icons.Point />
              </AddressIcon>

              {/* <AmountWrapper>
              <AmountTitle>Confirm Deposit Amount </AmountTitle>
              <Amount>
                <span>$ </span>
                {amount}
              </Amount>
            </AmountWrapper> */}
            </SystemAddressWrapper>

            {asset === "USDT" && (
              <AvapointWrapper
                onClick={() => handleChooseTransferType("avaPoints")}
                active={useavapoints}
              >
                <TransferTitle>AVA Points</TransferTitle>
                <AvapointIcon>
                  <Icons.Withdawal />
                </AvapointIcon>
                {/* <AmountWrapper>
              <AmountTitle>Confirm Use AVA Points</AmountTitle>
              <Amount>
                <span>$ </span>
                {amount}
              </Amount>
            </AmountWrapper> */}
              </AvapointWrapper>
            )}
          </TransferTypeWrapper>

          <TransferInfoWrapper>
            {!useavapoints && (
              <AddressWrapper>
                <AddressTitle>
                  <Lang datakey="system_address" defaultText="System Address" />
                </AddressTitle>
                <AddresContainer>
                  <AddressCrypto>{systmeAddress[0]?.Crypto}</AddressCrypto>
                  <AddressContent>{systmeAddress[0]?.Address}</AddressContent>
                </AddresContainer>
                <QrcodeContainer>
                  <QRCodeCanvas
                    id="qrcode"
                    size={120}
                    value={systmeAddress[0]?.Address}
                    bgColor={"transparent"}
                    fgColor={"#fcfcfc"}
                  />
                </QrcodeContainer>
              </AddressWrapper>
            )}

            <AmountWrapper>
              <AmountTitle>
                {useavapoints ? (
                  <Lang
                    datakey="confirm_use_ava_points"
                    defaultText="Confirm Use AVA Points"
                  />
                ) : (
                  <Lang
                    datakey="confirm_deposit_amount"
                    defaultText="Confirm Deposit Amount"
                  />
                )}
              </AmountTitle>
              <Amount>
                <span>$ </span>
                {amount}
              </Amount>
            </AmountWrapper>
          </TransferInfoWrapper>
        </TransferWrapper>

        <CheckboxWrapper>
          <CheckBox
            type="checkbox"
            onChange={(e) => setAddressCheck(e.target.checked)}
          ></CheckBox>
          <CheckboxContainer>
            <Lang
              datakey="mining_create_package_confirm"
              defaultText="I am sure the deposit address is correct. If not, the missend
                    transactions are irreversible and will result in the loss of funds."
            />
          </CheckboxContainer>
        </CheckboxWrapper>
      </Container>
    </Wrapper>
  );
};

export default Transfer;
