import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';
import { TextField, Button } from '@mui/material';
import UseSwal from '../../backend/hooks/Lang';

// #region styled
const Container = styled.div`
    width: 100vw;
    height: 100vh;
    overflow: none;
    position: relative;
`;

const LoginContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    padding: 2rem 5rem;
    border: 1px solid #ccc;
    border-radius: 10px;
`;

const LoginTitle = styled.h3`
    text-align: center;
    text-transform: uppercase;
    color: #666;
`;

const LoginForm = styled.form`

`;

const LoginField = styled.div`
    min-width: 20rem;
    padding: .5rem 0;

    > * {
        display: block;
        width: 100%;
    }
`;

const LoginActionContainer = styled.div`
    padding: 1rem 0;
`;
// #endregion

const ChartLogin = ({ allowAccount, setIsLogin }) => {
    const [username, setUsername] = useState(''); // avachart
    const [password, setPassword] = useState(''); // chart_5369@6746

    const LOGIN_ACCOUNT = allowAccount;
    const LOGIN_PASSWORD = 'chart_5369@6746';

    const handleLogin = async (e) => {
        e.preventDefault();
        
        if (username !== LOGIN_ACCOUNT || password !== LOGIN_PASSWORD)
        return UseSwal('帳號或密碼錯誤');
        
        localStorage.setItem('chartLogger', username);
        setIsLogin(true);
    };

  return (
    <LoginContainer>
        <LoginTitle>
            login charts
        </LoginTitle>

        <LoginForm>
            <LoginField>
                <TextField
                    label="username"
                    variant="standard"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
            </LoginField>

            <LoginField>
                <TextField
                    label="password"
                    type="password"
                    variant="standard"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </LoginField>

            <LoginActionContainer>
                <LoginField>
                    <Button
                        variant="contained"
                        onClick={handleLogin}
                        disabled={!username || !password}
                    >
                        登入
                    </Button>
                </LoginField>
            </LoginActionContainer>
        </LoginForm>
    </LoginContainer>
  )
}

export default ChartLogin
