import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  miningPackage: [],
  accountMining: [],
};

// Config slice
export const miningSlice = createSlice({
  name: "mining",
  initialState,
  reducers: {
    setMiningPackage: (state, action) => {
      state.miningPackage = action.payload?.Packages;
    },
    setAccountMining: (state, action) => {
      state.accountMining = action.payload?.Minings;
    },
  },
});

// Export actions
export const { setMiningPackage, setSelectTransferPackage, setAccountMining } =
  miningSlice.actions;

// Export select
export const selectMining = (state) => state.mining;

// Export reducer
export default miningSlice.reducer;
