import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { getAuthToken } from "../common";

// sweetAlert
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { ErrorMySwal } from "../../global/MySwal";
import { getLanguage } from "../common";

const MySwal = withReactContent(Swal).mixin({
  confirmButtonColor: "#22254a",
});

const language = getLanguage();

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_apiv2Host}`,
  prepareHeaders: (headers) => {
    const token = getAuthToken().token;
    if (!!token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const defaultBaseQuery = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.data !== undefined && result.data?.ResultCode !== 0)
    // alert(result.data.Message);

    MySwal.fire({
      title: result.data.Message,
    });

  // if (
  //   result.error?.data?.ResultCode !== 0 &&
  //   result.error?.data?.ResultCode < 990 &&
  //   result.error?.data?.Message !== undefined
  // )
  //   MySwal.fire({
  //     icon: "warning",
  //     title: result.error?.data?.Message,
  //   });

  // alert(result.error?.data?.Message);

  if (result.error?.status === 401 || result.error?.data?.ResultCode === 401) {
    ErrorMySwal({
      title: "請重新登入",
    });
  }

  return result;
};

export default defaultBaseQuery;
