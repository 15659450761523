import styled from "styled-components";
import { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Icons from "../../global/icons";
import Lang from "../../global/Lang";
import { routes } from "../../../components/Router";

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  color: #151944;
`;

const Container = styled.div`
  background-color: #fcfcfc;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  height: 100px;
  padding: 20px;
  /* min-height: 35.416vw; */
  height: auto;
  padding: 2.088vw 4.166vw;
  width: 41.66vw;
  border-radius: 8px;

  ${RWD_LG(css`
    width: 50%;
  `)};

  ${RWD_MD(css`
    width: 60%;
    padding: 20px 40px;
  `)};

  ${RWD_SM(css`
    width: 90%;
    padding: 20px;
  `)};
`;

const IconWrapper = styled.div`
  display: inline-flex;
  width: 100px;
  height: 100px;
  align-items: center;
  justify-content: center;

  svg {
    width: 100px;
    height: 100px;
  }

  & path {
    fill: #e89924;
  }

  ${RWD_SM(css`
    width: 80px;
    height: 80px;
    svg {
      width: 80px;
      height: 80px;
    }
  `)};
`;

const Title = styled.div`
  padding-top: 20px;
  font-size: min(max(1.25vw, 22px), 24px);
  font-weight: bold;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 50px;
  width: 80%;

  ${RWD_SM(css`
    width: 100%;
    /* width: auto; */
    padding-top: 25px;
    /* justify-content: center; */
  `)};
`;

const Button = styled.button`
  width: min(max(7.0833vw, 100px), 135px);
  border-radius: 60px;
  border: 1px solid;
  color: ${(props) => (props.type === "confirm" ? "#fcfcfc" : "#151944")};
  background-color: ${(props) =>
    props.type === "confirm" ? (props.disabled ? "#d9d9d9" : "#151944") : ""};
  cursor: pointer;
  border-color: ${(props) => (props.disabled ? "#d9d9d9" : "#151944")};

  /* &:first-child {
    margin-right: 1.5625vw;
  } */
`;

const ButtonText = styled.div`
  padding: 0.52vw 1.3vw;
  text-align: center;
  font-size: min(max(1.0416vw, 18px), 20px);

  ${RWD_LG(css`
    padding: 8px 18px;
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const CheckboxContainer = styled.div`
  font-size: min(max(0.9375vw, 16px), 18px);
  padding-left: 10px;
`;

const CheckboxWrapper = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  color: ${(props) => (props.verified ? "#151944" : "#D9D9D9")};
`;

const CheckBox = styled.input.attrs({ type: "checkbox" })`
  accent-color: #151944;
  width: 20px;
  height: 20px;
  margin: 0;
`;

const RedirectWrapper = styled.div`
  padding-top: 10px;
  cursor: pointer;
`;

const TelegramWrapper = styled.div`
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const AddAlgoModal = ({ modalData, telegramVerified }) => {
  const navigate = useNavigate();
  const [subscribe, isSubscribe] = useState(false);

  // 導頁到setting頁面 驗證telegram
  const redirect = () => {
    navigate({
      pathname: routes.setting_v2,
      search: "?verified=telegram",
    });
  };
  return (
    <Wrapper>
      <Container>
        <IconWrapper>
          <Icons.Warning />
        </IconWrapper>
        <Title>{modalData[0].title}</Title>
        {modalData[0].checkbox && (
          <TelegramWrapper>
            <CheckboxWrapper verified={telegramVerified}>
              <CheckBox
                type="checkbox"
                disabled={!telegramVerified}
                onChange={(e) => isSubscribe(e.target.checked)}
              ></CheckBox>
              <CheckboxContainer>
                勾選啟動Telegram訊息推播功能
              </CheckboxContainer>
            </CheckboxWrapper>
            {!telegramVerified && (
              <RedirectWrapper onClick={redirect}>
                前往驗證Telegram
              </RedirectWrapper>
            )}
          </TelegramWrapper>
        )}

        {(() => {
          switch (modalData[0].buttonType) {
            case "single":
              return (
                <ButtonWrapper>
                  <Button
                    type={"confirm"}
                    onClick={() => modalData[0].functionList[0](subscribe)}
                  >
                    <ButtonText>
                      <Lang datakey="confirm" defaultText="Confirm" />
                    </ButtonText>
                  </Button>
                </ButtonWrapper>
              );
            case "twice":
              return (
                <ButtonWrapper>
                  <Button
                    onClick={() => modalData[0].functionList[0](subscribe)}
                  >
                    <ButtonText>
                      否{/* <Lang datakey="cancel" defaultText="Cancel" /> */}
                    </ButtonText>
                  </Button>
                  <Button
                    type={"confirm"}
                    // disabled={!isValid}
                    onClick={() => modalData[0].functionList[1](subscribe)}
                  >
                    <ButtonText>
                      是{/* <Lang datakey="confirm" defaultText="Confirm" /> */}
                    </ButtonText>
                  </Button>
                </ButtonWrapper>
              );
            default:
              return;
          }
        })()}
      </Container>
    </Wrapper>
  );
};

export default AddAlgoModal;
