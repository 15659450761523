import { useEffect, useState } from 'react';

// --- components ---
import PolicyCheck from './PolicyCheck';

// --- plugins ---
import { MdOutlineFileCopy } from 'react-icons/md';
import { API_GetTransferQrcode } from '../../utils/api';
import { QRCodeCanvas } from 'qrcode.react';

const Transfer = ({
  currentStep,
  totalStep,
  setValid,
  projectInformation,
  setProjectInfomation
}) => {
  const POLICYS = [
    'I am sure the deposit address is correct. If not, the missend transactions are irreversible and will result in the loss of funds.',
  ];
  const ADDRESS = {
    // ERC20: '0x961510e6c0ad3210cc390cdc878cc1b6d0277fee',
    // TRC20: 'TVLkHDJmgDJ4Wn7DjFpLMMrmDnQS42owzL',
    BEP20: '0x3ba4d9e11a874b76a08c5e5422ad1c861e957b53',
  };
  const Qrcode = () => {
    const uri = ADDRESS[transferID.toUpperCase()];
    return (
      <QRCodeCanvas
        id='qrcode'
        size='120'
        value={uri}
        bgColor={'transparent'}
      />
    )
  };
  // const handleGetTransferQRcode = async () => {
  //   const res = await API_GetTransferQrcode(transferID, ADDRESS[transferID.toUpperCase()]);
  //   if (res.status !== 'success') return console.error('api error');
  //   return setQrcode(res.qr_code);
  // };
  const [investmentAmount, setInvestment] = useState(projectInformation.amount);
  const [transferID, setTransferID] = useState('BEP20');
  const [qrcode, setQrcode] = useState('');

  useEffect(() => {
    if (transferID === null) return;
    // handleGetTransferQRcode();
  }, [transferID]);

  return (
    <>
      {/* title */}
      <h3 className='text-center'>
        Confirmation & Transfer ({`${currentStep}/${totalStep}`})
      </h3>

      {/* Investment Amount */}
      <div className='my-10 max-w-[475px] mx-auto flex flex-wrap justify-between'>
        {/* confirm */}
        <label className='mx-auto'>Confirm Deposit Amount</label>
        {/* <div className='
          relative w-full
          after:content-["$"] after:absolute
          after:left-2 after:top-1/2 after:translate-y-[-50%]
          after:text-[#666]
        '> */}
        <div className='relative w-full'>
          {/* <input
            type='text'
            className='relative w-full pl-5 rounded pointer-events-none'
            value={investmentAmount}
            onChange={e => setInvestment(e.target.value)}
          /> */}
          {/* <label className='block pl-5 py-1 border border-sub-word rounded text-[1.2rem]'> */}
          <label className='block pl-5 py-2 text-[1.2rem] text-center'>
            $ <span className='text-[2rem]'>{investmentAmount}</span>
          </label>
        </div>
        
        {/* transfer ID selection */}
        <div className='mt-10 w-full'>
          <label className='block'>System Address</label>
          <div className='flex'>
            <div
              className={`
                flex-1 text-center cursor-pointer
                border border-1 rounded  bg-primary border-primary
              `}
              onClick={() => setTransferID('BEP20')}
            >
              BEP 20
            </div>
          </div>
        </div>
        
        {/* transfer ID input */}
        <div className='relative w-full mt-1 rounded border border-[#666]'>
          <span className='relative block w-[92%] sm:w-full pl-2 overflow-x-auto sm:pl-5'>
            {ADDRESS[transferID]}
          </span>
          <MdOutlineFileCopy className='
            absolute right-2 top-1/2 translate-y-[-50%] text-[1tem] text-primary cursor-pointer
          ' />
        </div>

        {/* transfer qrcode */}
        <div className='w-full pt-8 flex justify-center'>
          <div className='bg-white dark:bg-white p-4 rounded'>
            <Qrcode />
          </div>
          {/* <img src={`data:image/png;base64, ${qrcode}`} alt='transfer qrcode' /> */}
        </div>

        {/* policy */}
        {
          transferID === ''
          &&
          <span className='inline-block mx-auto mt-2 text-[0.875rem] text-[#666] text-center'>
            Transfer time 15 minutes, handling fee 20 USDT
          </span>
        }
      </div>

      {/* policy */}
      <PolicyCheck data={POLICYS} setValid={setValid} />
    </>
  )
};

export default Transfer;