import { HiDotsVertical } from 'react-icons/hi';

const AlgoStaticTable = ({ data }) => {
  const { labels, datasets } = data;
  return (
    <>
      <div className='relative pb-2'>
        <div className='w-full h-10 bg-sub-white dark:bg-sub-black' />
        <HiDotsVertical className='absolute top-1/2 -translate-y-1/2 right-2' />
      </div>

      <div className='w-full overflow-x-auto'>
        <table className='text-center text-[0.8rem] md:text-[1rem] w-full'>
          <thead>
            <tr className='text-sub px-2'>
              <th className='whitespace-nowrap py-2 pointer-events-none'>
                Algo Name
              </th>
              {labels?.map((label, index) => {
                return (
                  <th key={index} className='whitespace-nowrap pointer-events-none px-2'>
                    {label}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {datasets.map(({ label, data }, index) => {
              return (
                <tr key={index} className='py-4 border-b'>
                  <td className='pointer-events-none py-4'>
                    {label}
                  </td>
                  {data.map((value, index) => {
                    return (
                      <td className={`pointer-events-none ${value < 0 ? 'text-fail' : 'text-pass'}`} key={index}>
                        {value.toFixed(2)}%
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default AlgoStaticTable
