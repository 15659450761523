import { createSlice } from "@reduxjs/toolkit";
import { setLanguage, setLanguageData } from "../services/common";

const initialState = {
  language: "En", // Ch | En
  exchanges: [],
  airobots: [],
  langData: [],
  despositAddress: [],
  txidStatus: [],
  enums: [],
  bchusdt: 0,
  bnbusdt: 0,
  btcusdt: 0,
  cakeusdt: 0,
  ethusdt: 0,
  iotausdt: 0,
  neousdt: 0,
  eosusdt: 0,
  etcusdt: 0,
};

// Config slice
export const systemSlice = createSlice({
  name: "system",
  initialState,
  reducers: {
    setDropdown: (state, action) => {
      state.exchanges = action.payload?.Exchanges;
      state.airobots = action.payload?.AiRobots;
      state.langData = action.payload?.Languages;
      state.despositAddress = action.payload?.AvaPointDespositAddress;
      state.txidStatus = action.payload?.TxidStatus;
      state.enums = action.payload?.Enums;
      setLanguageData(JSON.stringify(action.payload?.Languages));
    },
    setBchusdtPrice: (state, action) => {
      state.bchusdt = (Math.floor(action.payload * 10000) / 10000).toFixed(4);
    },
    setBnbusdtPrice: (state, action) => {
      state.bnbusdt = (Math.floor(action.payload * 10000) / 10000).toFixed(4);
    },
    setBtcusdtPrice: (state, action) => {
      state.btcusdt = (Math.floor(action.payload * 10000) / 10000).toFixed(4);
    },
    setCakeusdtPrice: (state, action) => {
      state.cakeusdt = (Math.floor(action.payload * 10000) / 10000).toFixed(4);
    },
    setEthusdtPrice: (state, action) => {
      state.ethusdt = (Math.floor(action.payload * 10000) / 10000).toFixed(4);
    },
    setIotausdtPrice: (state, action) => {
      state.iotausdt = (Math.floor(action.payload * 10000) / 10000).toFixed(4);
    },
    setNeousdtPrice: (state, action) => {
      state.neousdt = (Math.floor(action.payload * 10000) / 10000).toFixed(4);
    },
    setEosusdtPrice: (state, action) => {
      state.eosusdt = (Math.floor(action.payload * 10000) / 10000).toFixed(4);
    },
    setEtcusdtPrice: (state, action) => {
      state.etcusdt = (Math.floor(action.payload * 10000) / 10000).toFixed(4);
    },
    changeLanguage: (state, action) => {
      state.language = state.language === "Ch" ? "En" : "Ch";
      setLanguage(state.language);
    },
  },
});

// Export actions
export const {
  setDropdown,
  setBchusdtPrice,
  setBnbusdtPrice,
  setBtcusdtPrice,
  setCakeusdtPrice,
  setEthusdtPrice,
  setIotausdtPrice,
  setNeousdtPrice,
  setEosusdtPrice,
  setEtcusdtPrice,
  changeLanguage,
} = systemSlice.actions;

// Export select
export const selectSystem = (state) => state.system;

// Export reducer
export default systemSlice.reducer;
