import styled from "styled-components";
import { useState, useEffect, Fragment } from "react";
import Lang from "../../global/Lang";
import { QRCodeCanvas } from "qrcode.react";
import Icons from "../../global/icons";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";
import { css } from "styled-components";
import { useSendEmailAndTelegramMutation } from "../../services/authApi";
import Loading from "../../components/Loading/Loading";

import ApiKeyPermissionModal from "../../components/ApiKeyPermissionModal";

import { SuccessMySwal, ErrorMySwal } from "../../global/MySwal";

import { LangString } from "../../utils";
import { getLanguage } from "../../services/common";

import Button from "../../global/Buttons";

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
`;

const Container = styled.div`
  background-color: #fcfcfc;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100px;
  height: auto;
  padding: 40px;
  width: 50vw;
  max-width: 650px;
  /* min-width: 500px; */
  border-radius: 8px;

  ${RWD_LG(css`
    width: 70%;

    /* min-width: auto; */
  `)};

  ${RWD_MD(css`
    width: 60%;
    padding: 20px;
  `)};

  ${RWD_SM(css`
    width: 90%;
    padding: 20px;
  `)};
`;

const Title = styled.div`
  color: #151944;
  font-size: min(max(1.5625vw, 28px), 30px);
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 20px;

  ${RWD_MD(css`
    font-size: min(max(1.0416vw, 18px), 20px);
  `)};
`;

const PasswordWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #d9d9d9;
  border-radius: 100px;
`;

const PasswordIcon = styled.button`
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  margin-right: 20px;

  svg {
    width: 30px;
    height: 30px;
  }

  & path {
    stroke: #fcfcfc;
  }

  ${RWD_SM(css`
    width: 25px;
    height: 25px;
    margin-right: 15px;

    svg {
      width: 25px;
      height: 25px;
    }
  `)};
`;

const InputWrapper = styled.div``;

const SubTitle = styled.div`
  color: #151944;
  font-size: min(max(1.145vw, 20px), 22px);
  font-weight: bold;
  padding-bottom: 10px;
  padding-top: 1.3vw;
  display: flex;

  ${RWD_MD(css`
    font-size: min(max(0.9375vw, 16px), 18px);
  `)};
`;

const Input = styled.input`
  background: #d9d9d9;
  padding: 1.0416vw 1.5625vw;
  width: 100%;
  border-radius: 100px;
  color: #151944;

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 14px), 16px);
    padding: 8px 20px;
  `)};
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 50px;
  position: relative;

  button:first-child {
    margin-right: 1.5625vw;
  }

  ${RWD_SM(css`
    padding-top: 25px;
    justify-content: space-between;

    flex-direction: ${(props) => (props.type === "apikey" ? "column" : "row")};
  `)};
`;

// const Button = styled.button`
//   width: min(max(7.0833vw, 100px), 135px);
//   border-radius: 60px;
//   border: 1px solid;
//   color: ${(props) => (props.type === "confirm" ? "#fcfcfc" : "#151944")};
//   background-color: ${(props) =>
//     props.type === "confirm" ? (props.disabled ? "#d9d9d9" : "#151944") : ""};
//   border-color: ${(props) => (props.disabled ? "#d9d9d9" : "#151944")};
// `;

const ButtonText = styled.div`
  padding: 0.52vw 1.3vw;
  text-align: center;
  font-size: min(max(1.0416vw, 18px), 20px);

  ${RWD_LG(css`
    padding: 6px 15px;
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const ErrorMsgWrapper = styled.div`
  padding: 10px 0;
  color: #f05d2f;
`;

const InputContainer = styled.div`
  display: flex;
  background: #d9d9d9;
  border-radius: 100px;
`;

const QrcodeContainer = styled.div`
  padding: 20px 0;
  margin: auto;

  /* ${RWD_SM(css`
    padding: 4vw 0;
  `)}; */
`;

const QrcodeBtn = styled.div`
  color: #151944;
  white-space: nowrap;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: min(max(1.0416vw, 14px), 20px);
  padding-right: 10px;
`;

const UpdateApiKeyWrapper = styled.div`
  background: #151944;
  padding: 10px 0;
  /* padding: 0.833vw; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  ${RWD_MD(css`
    padding: 5px;
    margin-bottom: 10px;
  `)};
`;

const UpdateApiKeyIcon = styled.div`
  max-width: 200px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    max-width: 200px;
    height: 40px;
    /* object-fit: contain; */
  }

  ${RWD_MD(css`
    height: 25px;

    svg {
      height: 25px;
      /* object-fit: contain; */
    }
  `)};
`;

const UpdateApiKeyInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const NoticeWrapper = styled.div`
  /* border: 1px solid red; */
  color: #151944;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  font-size: min(max(0.9375vw, 16px), 18px);

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 14px), 16px);
  `)};

  ${RWD_SM(css`
    align-items: flex-start;
  `)};
`;

const NoticeIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;

  padding-top: 1px;
  display: flex;
  margin-right: 5px;

  svg {
    width: 20px;
    height: 20px;
  }

  & path {
    stroke: #151944;
  }
`;

const AuthWrapper = styled.div`
  color: #151944;
  cursor: pointer;
  font-size: min(max(0.9375vw, 16px), 18px);
  margin-right: auto;

  ${RWD_SM(css`
    padding-bottom: 10px;
  `)};
`;

const ButtonContainer = styled.div`
  ${RWD_SM(css`
    width: 100%;
    display: flex;
    justify-content: space-between;
  `)};
  /* border: 1px solid red; */
`;

const TgNoticeWrapper = styled.div`
  color: #151944;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  font-size: min(max(0.9375vw, 16px), 18px);

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 14px), 16px);
  `)};

  ${RWD_SM(css`
    align-items: flex-start;
  `)};
`;

const AddApiKeyTooltipWrapper = styled.div`
  color: #c0c0c0;
  font-weight: lighter;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: min(max(0.9375vw, 16px), 18px);
  cursor: pointer;
  gap: 5px;
`;

const AddApiKeyTooltipContainer = styled.div`
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;

  padding-top: 1px;
  display: flex;

  svg {
    width: 20px;
    height: 20px;
  }

  & path {
    stroke: #c0c0c0;
  }
`;

const AddApiKeyTooltipText = styled.span`
  transition: all 0.3s linear;
  border-bottom: 1px solid transparent;

  ${AddApiKeyTooltipWrapper} &:hover {
    border-bottom: 1px solid #c0c0c0;
  }
`;

const AddApiKeyTooltipTextUrl = styled.span`
  visibility: hidden;
  width: auto;
  background-color: #54587b;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 0.833vw;
  white-space: nowrap;
  position: absolute;
  left: calc(1.3vw + 15px);
  z-index: 1;
`;

const SettingModal = ({
  modalData,
  setClose,
  submitFun,
  name,
  oldPassword,
  newPassword,
  confirmPassword,
  referanceCode,
  withdrawal,
  telegramId,
  newApikey,
  newSecretkey,
  newIgAccount,
  newIgPassword,
  exchange,
  withdrawlVerifyCode,
}) => {
  // withdrawlVerify
  const [_getWithdrawlVerifyCode, { data, isSuccess, isError, error }] =
    useSendEmailAndTelegramMutation();

  const [imgModalIsOpen, setImgModalIsOpen] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      // 多國語言
      const title = LangString(
        "alert_verificationCode_success",
        "Verification code sent"
      );
      const text = LangString(
        "alert_telegramVerificationCode_info",
        "Please check your email or Telegram to retrieve the verification code."
      );
      const confirmText = LangString("confirm", "Confirm");
      setLoading(false);
      SuccessMySwal({
        title: title,
        text: text,
        showCancelButton: false,
        confirmButtonText: confirmText,
      });
    }

    if (isError) {
      setLoading(false);
      ErrorMySwal({
        title:
          error?.data?.ResultLanguages[getLanguage()] || error?.data?.Message,
      });
    }
  }, [data, isError]);

  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState([
    { type: "old", status: false },
    { type: "new", status: false },
    { type: "confirm", status: false },
  ]);

  const modalDefaultData = [
    {
      type: "name",
      title: <Lang datakey="edit_name" defaultText="Edit Name" />,
      inputList: ["Name"],
      string: <Lang datakey="input_name" defaultText="Input Name" />,
    },
    {
      type: "password",
      title: <Lang datakey="change_password" defaultText="Change Password" />,
      inputList: ["Old Password", "New Password", "Confirm New Password"],
      string: [
        <Lang datakey="input_old_password" defaultText="Input Old Password" />,
        <Lang datakey="input_new_password" defaultText="Input New Password" />,
        <Lang
          datakey="input_confirm_new_password"
          defaultText="Input Confirm New Password"
        />,
      ],
    },
    {
      type: "referance",
      title: "Add Referral Code",
      inputList: ["Referral Code"],
    },
    {
      type: "telegram",
      title: "Verify Telegram ID",
      inputList: ["Verification Code"],
      string: (
        <Lang
          datakey="input_verification_code"
          defaultText="Input Verification Code"
        />
      ),
    },
    {
      type: "withdrawalVerify",
      title: (
        <Lang
          datakey="get_verification_code"
          defaultText="Get Verification Code"
        />
      ),
      inputList: ["Withdrawal Verification Code"],

      // Modify Deposit Address After Verification
    },
    {
      type: "withdrawal",
      title: <Lang datakey="deposit_address" defaultText="Deposit Address" />,
      inputList: ["Your Deposit Address"],
    },
    {
      type: "apikey",
      title: <Lang datakey="update_apikey" defaultText="Update API Key" />,
      inputList: ["API Key", "Secret Key", "Account", "Password"],
      string: [
        <Lang datakey="input_apikey" defaultText="Input API Key" />,
        <Lang datakey="input_secretkey" defaultText="Input Secret Key" />,
        <Lang datakey="input_account" defaultText="Input Account" />,
        <Lang datakey="input_password" defaultText="Input Password" />,
      ],
    },
  ];

  const handleClose = () => {
    setClose(true);
  };

  const handleConfirm = (type) => {
    submitFun(type);
    // handleClose();
  };

  const checkisValid = (type, exchange) => {
    switch (type) {
      case "name":
        return name.trim() !== "";
      case "password":
        return (
          oldPassword.trim() !== "" &&
          newPassword.trim() !== "" &&
          confirmPassword.trim() !== "" &&
          newPassword.trim() === confirmPassword.trim()
        );
      case "telegram":
        return telegramId.trim() !== "";
      case "referance":
        return referanceCode.trim() !== "";
      case "withdrawalVerify":
        return withdrawlVerifyCode.trim() !== "";
      case "withdrawal":
        if (withdrawal.trim() !== "") {
          //  以下是 TRC-20 代幣地址的 Regex：
          // ^T[1-9A-HJ-NP-Za-km-z]{33}$
          // 以下是 BEP-20 代幣地址的 Regex：
          // ^0x[0-9a-fA-F]{40}$
          const Regex = /^0x[0-9a-fA-F]{40}$/;
          return Regex.test(withdrawal.trim());
        }
        return withdrawal.trim() !== "";
      case "apikey":
        return exchange === 4
          ? newApikey.trim() !== "" &&
              newIgAccount.trim() !== "" &&
              newIgPassword.trim()
          : newApikey.trim() !== "" && newSecretkey.trim() !== "";
    }
  };

  const redirectUrl = `${process.env.REACT_APP_apiv2Host}/telegram/callback`;
  const loginUrl = `https://t.me/${
    process.env.REACT_APP_telegramBotName
  }?start=login&redirect_url=${encodeURIComponent(redirectUrl)}`;

  const handleSendVerifyCode = () => {
    window.location.href = loginUrl;
  };

  const handleGetWithdrawVerifyCode = () => {
    setLoading(true);
    _getWithdrawlVerifyCode();
  };

  const handleShowPassword = (data) => {
    setShowPassword(
      showPassword.map((item) => {
        if (item.type === data.split(" ")[0].toLocaleLowerCase()) {
          return {
            ...item,
            status: !item.status,
          };
        } else {
          return item;
        }
      })
    );
  };

  const handleLink = () => {
    window.open(`https://www.bybit.com/invite?ref=YZWO1G`, "_blank");
  };

  return (
    <Wrapper>
      {loading && <Loading show={loading} />}
      {modalDefaultData
        .filter((data) => data.type === modalData.type)
        .map((item) => (
          <Container key={item.type}>
            <Title>
              {item.title}
              {exchange === 2 && (
                <AddApiKeyTooltipWrapper onClick={handleLink}>
                  <AddApiKeyTooltipContainer>
                    <Icons.Tooltip />
                  </AddApiKeyTooltipContainer>
                  <AddApiKeyTooltipText>
                    <Lang
                      datakey="modal_title_addApi"
                      defaultText="Click here to register a Bybit account."
                      toolTip={true}
                    />
                  </AddApiKeyTooltipText>
                </AddApiKeyTooltipWrapper>
              )}
            </Title>
            {modalData.type === "telegram" && (
              <QrcodeContainer>
                <QRCodeCanvas
                  value={loginUrl}
                  bgColor={"transparent"}
                  onClick={handleSendVerifyCode}
                />
              </QrcodeContainer>
            )}

            {modalData.type === "apikey" && (
              <UpdateApiKeyWrapper>
                <UpdateApiKeyIcon>
                  {(() => {
                    switch (exchange) {
                      case 1:
                        return <Icons.Binance_white />;
                      case 2:
                        return <Icons.Bybit_white />;
                      case 3:
                        return <Icons.Bybit_white />;
                      case 4:
                        return <Icons.Ig_white />;

                      default:
                        break;
                    }
                  })()}
                </UpdateApiKeyIcon>
              </UpdateApiKeyWrapper>
            )}

            {modalData.type === "apikey" ? (
              <Fragment>
                <InputWrapper>
                  <SubTitle>{item.string[0]}</SubTitle>
                  <Input
                    onChange={(e) => modalData.funList[0](e.target.value)}
                  ></Input>
                </InputWrapper>
                {exchange !== 4 ? (
                  <UpdateApiKeyInputWrapper>
                    <InputWrapper>
                      <SubTitle>{item.string[1]}</SubTitle>
                      <Input
                        onChange={(e) => modalData.funList[1](e.target.value)}
                      ></Input>
                    </InputWrapper>
                  </UpdateApiKeyInputWrapper>
                ) : (
                  <UpdateApiKeyInputWrapper>
                    <InputWrapper>
                      <SubTitle>{item.string[2]}</SubTitle>
                      <Input
                        onChange={(e) => modalData.funList[2](e.target.value)}
                      ></Input>
                    </InputWrapper>
                    <InputWrapper>
                      <SubTitle>{item.string[3]}</SubTitle>
                      <Input
                        onChange={(e) => modalData.funList[3](e.target.value)}
                      ></Input>
                    </InputWrapper>
                  </UpdateApiKeyInputWrapper>
                )}
              </Fragment>
            ) : (
              item.inputList.map((data, index) => {
                if (data === "Verification Code") {
                  return (
                    <InputWrapper key={index}>
                      <SubTitle>{item.string}</SubTitle>
                      <InputContainer>
                        <Input
                          placeholder={modalData.default}
                          onChange={(e) =>
                            modalData.funList[index](e.target.value)
                          }
                        ></Input>
                        <QrcodeBtn onClick={handleSendVerifyCode}>
                          <ButtonText>
                            <Lang datakey="get_code" defaultText="Get Code" />
                          </ButtonText>
                        </QrcodeBtn>
                      </InputContainer>
                    </InputWrapper>
                  );
                } else if (data === "Withdrawal Verification Code") {
                  return (
                    <InputWrapper key={index}>
                      <SubTitle>
                        <Lang
                          datakey="modify_deposit_address_after_verification"
                          defaultText="Modify Deposit Address After Verification"
                        />
                      </SubTitle>
                      <InputContainer>
                        <Input
                          placeholder={modalData.default}
                          onChange={(e) =>
                            modalData.funList[index](e.target.value)
                          }
                        ></Input>
                        <QrcodeBtn onClick={handleGetWithdrawVerifyCode}>
                          <ButtonText>
                            <Lang datakey="get_code" defaultText="Get Code" />
                          </ButtonText>
                        </QrcodeBtn>
                      </InputContainer>
                      <TgNoticeWrapper>
                        {/* <NoticeIconWrapper>
                          <Icons.Tooltip />
                        </NoticeIconWrapper> */}
                        <Lang
                          datakey="withdrawal_verification_notice"
                          defaultText='Please click "Get Verification Code" to get the
                          verification code (sent via verified Telegram).'
                        />
                      </TgNoticeWrapper>
                    </InputWrapper>
                  );
                } else if (
                  data === "Old Password" ||
                  data === "New Password" ||
                  data === "Confirm New Password"
                ) {
                  return (
                    <InputWrapper key={index}>
                      <SubTitle>{item.string[index]}</SubTitle>
                      <PasswordWrapper>
                        <Input
                          type={
                            showPassword.filter(
                              (item) =>
                                item.type ===
                                data.split(" ")[0].toLocaleLowerCase()
                            )[0].status
                              ? "text"
                              : "password"
                          }
                          placeholder={modalData.default}
                          onChange={(e) =>
                            modalData.funList[index](e.target.value)
                          }
                        ></Input>

                        <PasswordIcon onClick={() => handleShowPassword(data)}>
                          {showPassword[index]?.status ? (
                            <Icons.EyeOpen />
                          ) : (
                            <Icons.EyeClose />
                          )}
                        </PasswordIcon>
                      </PasswordWrapper>
                    </InputWrapper>
                  );
                } else {
                  if (data === "Your Deposit Address") {
                    return (
                      <>
                        <InputWrapper key={index}>
                          <SubTitle>
                            <Lang datakey="input" defaultText="Input" /> {data}
                          </SubTitle>
                          <Input
                            placeholder={modalData.default}
                            onChange={(e) =>
                              modalData.funList[index](e.target.value)
                            }
                          ></Input>
                        </InputWrapper>
                        <NoticeWrapper>
                          <NoticeIconWrapper>
                            <Icons.Tooltip />
                          </NoticeIconWrapper>
                          <Lang
                            datakey="withdrawal_notice"
                            defaultText="Address format only accept BEP-20 only"
                          />
                        </NoticeWrapper>
                      </>
                    );
                  } else if (data === "Name") {
                    return (
                      <>
                        <InputWrapper key={index}>
                          <SubTitle>{item.string}</SubTitle>
                          <Input
                            placeholder={modalData.default}
                            maxLength={24}
                            onChange={(e) =>
                              modalData.funList[index](e.target.value)
                            }
                          ></Input>
                        </InputWrapper>
                        <NoticeWrapper>
                          <NoticeIconWrapper>
                            <Icons.Tooltip />
                          </NoticeIconWrapper>
                          <Lang
                            datakey="setting_name_notice"
                            defaultText="Please enter a maximum of 24 characters for the Name"
                          />
                        </NoticeWrapper>
                      </>
                    );
                  } else {
                    return (
                      <InputWrapper key={index}>
                        <SubTitle>Input {data}</SubTitle>
                        <Input
                          placeholder={modalData.default}
                          onChange={(e) =>
                            modalData.funList[index](e.target.value)
                          }
                        ></Input>
                      </InputWrapper>
                    );
                  }
                }
              })
            )}

            <ButtonWrapper type={modalData.type}>
              {modalData.type === "apikey" && exchange !== 4 && (
                <AuthWrapper onClick={() => setImgModalIsOpen(true)}>
                  <Lang
                    datakey="apikey_auth"
                    defaultText="ApiKey Auth Setting"
                  />
                </AuthWrapper>
              )}

              <ButtonContainer>
                <Button onClickFun={handleClose} type={"cancel"} />
                {/* <Button onClick={handleClose}>
                  <ButtonText>
                    <Lang datakey="cancel" defaultText="Cancel" />
                  </ButtonText>
                </Button> */}
                <Button
                  onClickFun={() => handleConfirm(modalData)}
                  type={"confirm"}
                  disabled={!checkisValid(modalData.type, exchange)}
                />
                {/* <Button
                  type={"confirm"}
                  onClick={() => handleConfirm(modalData)}
                  disabled={!checkisValid(modalData.type)}
                >
                  <ButtonText>
                    <Lang datakey="confirm" defaultText="Confirm" />
                  </ButtonText>
                </Button> */}
              </ButtonContainer>
            </ButtonWrapper>
          </Container>
        ))}
      {imgModalIsOpen && (
        <ApiKeyPermissionModal
          exchange={exchange}
          imgModalIsOpen={imgModalIsOpen}
          setImgModalIsOpen={setImgModalIsOpen}
        />
      )}
    </Wrapper>
  );
};

export default SettingModal;
