import Logo from "../../../images/logo_light.png";
import Pv from "../../../images/hero.svg";

import Features_1 from "../../../images/features_1.jpg";
import Features_2 from "../../../images/features_2.jpg";
import Features_3 from "../../../images/features_3.jpg";
import Features_4 from "../../../images/features_4.jpg";
import Features_5 from "../../../images/features_5.jpg";
import Features_6 from "../../../images/features_6.jpg";

// algo
import Drone from "../../../images/algo/drone.png";
import Howitzer from "../../../images/algo/howitzer.png";
import Rifle from "../../../images/algo/rifle.png";
import Drone_landscape from "../../../images/algo/drone_landscape.png";
import Howitzer_landscape from "../../../images/algo/howitzer_landscape.png";
import Rifle_landscape from "../../../images/algo/rifle_landscape.png";

import Intro_1 from "../../../images/intro_1.svg";
import Intro_2 from "../../../images/intro_2.svg";
import Intro_3 from "../../../images/intro_3.svg";

import Building from "../../../images/building.jpg";

import Member_1 from "../../../images/member_1.jpg";
import Member_2 from "../../../images/member_2.jpg";
import Member_3 from "../../../images/member_3.jpg";
// import Member_4 from "../../../images/member_4.png";
// import Member_5 from "../../../images/member_5.png";
// import Member_6 from "../../../images/member_6.png";

import Plan_1 from "../../../images/plan_1.jpg";
import Plan_2 from "../../../images/plan_2.jpg";
import Plan_3 from "../../../images/plan_3.jpg";
import Plan_4 from "../../../images/plan_4.jpg";

import BgHero from "../../../images/bgHero.png";
import BgFeatures from "../../../images/bgFeatures.png";
import BgMember from "../../../images/bgMember.png";
import BgPlan from "../../../images/bgPlan.png";
import BgCircle from "../../../images/bgCircle.png";
import BgLine from "../../../images/bgLine.png";

import Hand from "../../../images/handPointing.svg";
import User from "../../../images/user.svg";

// import IconDiscord from "../../../images/discord.svg";
// import IconInstagram from "../../../images/instagram.svg";
// import IconTwitter from "../../../images/twitter.svg";
import IconGlobal from "../../../images/globe.svg";
import IconArrow from "../../../images/arrow.svg";
import IconArrowGray from "../../../images/arrow_gray.svg";

import { animated, useSpring } from "react-spring";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../Router";
import {
  useGetLandingAlgosQuery,
  useGetBlockChainNewsPreviewQuery,
} from "../../../v2/services/landingApi";

import { RWD_XL, RWD_LG, RWD_MD, RWD_SM } from "../../../constants/rwd";

// --- plugins ---
import styled, { css, ThemeProvider } from "styled-components";
import Theme from "../../../constants/theme";

import SmoothScroll from "smooth-scroll";
import { Link as ScrollLink } from "react-scroll";

// AOS
import AOS from "aos";
import "aos/dist/aos.css";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import parse from "html-react-parser";
import "../App.css";

import { NewsDateFormat } from "../../../v2/utils";

import AlgoIntroduceModal from "./AlgoIntroduceModal";
import NewsModal from "./NewsModal";
import { AlgoLineChart } from "./AlgoLineChart";

// Hero
import Hero from "./Hero";
import Product from "./Product";
import Reason from "./Reason";
import Testimonials from "./Testimonials";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage, selectSystem } from "../../../v2/slices/systemSlice";
import { getLanguage, setLanguage } from "../../../v2/services/common";

// --- styled components ---
const Wrapper = styled.div`
  font-family: "Poppins", "Noto Sans TC", "sans-serif";
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  height: 100%;
  position: relative;
  overflow: hidden;

  box-sizing: content-box;
  /* overflow: hidden; */

  /* position: relative; */

  /* background-image: radial-gradient(
    circle 610px at 5.2% 51.6%,
    rgba(5, 8, 114, 1) 0%,
    rgba(7, 3, 53, 1) 97.5%
  ); */

  animation: mymove 5s alternate infinite;

  // iOS < 11.2
  padding-left: constant(safe-area-inset-left);
  padding-top: constant(safe-area-inset-top);
  padding-right: constant(safe-area-inset-right);
  /* padding-bottom: constant(safe-area-inset-bottom); */
  // iOS >= 11.2
  padding-left: env(safe-area-inset-left);
  padding-top: env(safe-area-inset-top);
  padding-right: env(safe-area-inset-right);
  /* padding-bottom: env(safe-area-inset-bottom); */

  /* background: linear-gradient(to right, #24243e, #302b63, #0f0c29); */
`;

const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  transition: all 0.3s;
  height: 6.25vw;
  width: 100%;
  position: fixed;
  font-weight: 500;
  animation: mymove 5s alternate infinite;
  box-shadow: ${(props) =>
    props.headerShadow ? "0px 5px 10px rgba(0, 0, 0, 0.05)" : "none"};

  ${RWD_MD(css`
    height: 8vh;
  `)};

  ${RWD_SM(css`
    height: 65px;
  `)};
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 81.88vw;

  ${RWD_MD(css`
    width: 83.33vw;
  `)};
`;

const MenuList = styled(animated.ul)`
  list-style: none;
  display: flex;
  padding: 0;
  transform-origin: left top;

  ${RWD_MD(css`
    position: absolute;
    flex-direction: column;
    margin: 0;
    top: 0;
    right: 0;
    width: 50vw;
    min-height: 100vh;
    height: 100%;
    padding: 10vh 0;
    z-index: 9;
    background-image: linear-gradient(to bottom, #302b63 0%, #151944 100%);
  `)};
`;

const MenuItem = styled.li`
  padding: 0 1.5vw;
  box-sizing: border-box;
  font-size: 1.041667vw;

  a {
    cursor: pointer;
    color: #fff;
    text-decoration: none;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 0;
      height: 4px;
      background-color: ${(props) => props.theme.colors.Green};
      position: absolute;
      right: 0;
      transition: 0.5s;
    }

    &:hover::after {
      width: 100%;
      left: 0;
    }
  }

  ${RWD_MD(css`
    text-align: center;
    padding: 4vw 4vw 4vw 0;
    font-size: 3.88vw;
  `)};
`;

const HamburgerMenuList = styled.div`
  flex-direction: column;
  align-items: center;
  height: 22px;
  width: 30px;
  display: none;
  justify-content: space-between;
  cursor: pointer;
  z-index: 99;
  transition: all 0.3s ease-in-out;
  transition-delay: 0.6s;

  /* transform: ${(props) =>
    props.hamIsOpen ? "rotate(45deg)" : "rotate(0deg)"}; */
  ${RWD_MD(css`
    display: flex;
  `)};
`;

const HamburgerMenuItem = styled.span`
  display: inline-block;
  width: 30px;
  background-color: ${(props) => props.theme.colors.White};
  height: 2px;

  transition: all 0.2s ease-in-out;
  transform-origin: center center;
  &:nth-of-type(1) {
    transition-delay: 0.1s;
    width: 15px;
    align-self: flex-start;
    transform-origin: top;
    /* transform-origin: top right; */
    transform: ${(props) =>
      props.hamIsOpen
        ? "rotate(45deg) translateX(5px) translateY(2px)"
        : "rotate(0deg) translateX(0px) translateY(0px)"};
  }

  :nth-of-type(2) {
    transform-origin: center;
    /* width: ${(props) => (props.hamIsOpen ? "0px" : "30px")}; */
    transform: ${(props) =>
      props.hamIsOpen ? "rotate(-45deg)" : "rotate(0deg)"};
  }

  &:nth-of-type(3) {
    transition-delay: 0.3s;
    width: 15px;
    transform-origin: bottom;
    align-self: flex-end;
    transform: ${(props) =>
      props.hamIsOpen
        ? "rotate(45deg) translateX(-5px) translateY(-1px)"
        : "rotate(0deg) translateX(0px) translateY(0px)"};

    /* transform-origin: bottom right;
    transform: ${(props) =>
      props.hamIsOpen
        ? "rotate(45deg) translateY(0) translateX(0)"
        : "rotate(0) translateY(0) translateX(0)"}; */
  }
`;

const LogoWrapper = styled.div``;

const LogoContainer = styled.div`
  width: 5.729vw;
  display: flex;

  ${RWD_MD(css`
    width: 11vw;
  `)};

  ${RWD_SM(css`
    width: 15.33vw;
  `)};
`;

const LoginButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;

  /* ${RWD_MD(css`
    margin-left: auto;
  `)}; */

  ${RWD_MD(css`
    display: none;
  `)};
`;

const LoginIcon = styled.div`
  display: none;

  /* a {
    display: inline-flex;
    border: 1px solid rebeccapurple;
  } */

  ${RWD_MD(css`
    display: flex;
    width: 7vw;
  `)};

  ${RWD_SM(css`
    width: 8.88vw;
  `)};
`;

const Button = styled.button`
  border-radius: 60px;
  padding: 0.78125vw 2.9166vw;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  font-size: clamp(18px, 1.145vw, 22px);

  border: ${(props) =>
    props.type === "hollow" ? `1px solid ${props.theme.colors.White}` : ""};
  background: ${(props) =>
    props.type === "hollow"
      ? "transparent"
      : "linear-gradient(to bottom, #2F80ED 0%, #5620F0 100%)"};
  color: ${(props) => props.theme.colors.White};
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 2;
  text-decoration: none;

  ${RWD_MD(css`
    /* font-size: 4.166vw; */
    padding: 13px 40px;
  `)};

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 30px;
    z-index: -2;
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: ${(props) => (props.type === "hollow" ? "100%" : "0%")};
    height: ${(props) => (props.type === "hollow" ? "0" : "100%")};
    background: ${(props) =>
      props.type === "hollow"
        ? "white"
        : "linear-gradient(to right, #6a11cb 0%, #2575fc 100%)"};
    transition: all 0.3s;
    border-radius: 30px;
    z-index: -1;
  }

  &:hover {
    color: ${(props) =>
      props.type === "hollow"
        ? props.theme.colors.DarkBlue
        : props.theme.colors.White};
    border: ${(props) =>
      props.type === "hollow" ? "1px solid transparent" : ""};

    &:before {
      width: 100%;
      height: 100%;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  ${RWD_MD(css`
    padding: 13px 40px;
  `)};
`;

const PvSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* height: 100%;
  min-height: calc(100vh - 6.25vw);
  margin-top: 6.25vw; */
  width: 80vw;
  height: 100%;
  min-height: 100vh;
  position: relative;
  z-index: 2;

  /* ${RWD_XL(css`
    width: 90%;
  `)}; */

  ${RWD_LG(css`
    flex-direction: column-reverse;
    justify-content: space-evenly;
  `)};

  ${RWD_MD(css`
    /* 
    margin-top: 8vh;
    min-height: calc(100vh - 8vh); */
    width: 83.33vw;
    flex-direction: column;
    justify-content: center;
  `)};

  ${RWD_SM(css`
    /* border: 1px solid rebeccapurple; */
    /* margin-top: 65px;
    min-height: calc(100vh - 65px); */
  `)};
`;

const PvContent = styled.div`
  color: ${(props) => props.theme.colors.White};
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${RWD_LG(css`
    width: 100%;
  `)};

  ${RWD_MD(css`
    align-items: center;
  `)};
`;

const PvTitle = styled.h1`
  margin: 0;
  /* padding-bottom: 1vh; */
  font-size: 3.125vw;
  font-weight: 500;

  ${RWD_LG(css`
    font-size: 6.5vw;
  `)};

  ${RWD_MD(css`
    /* font-size: clamp(25px, 8vw, 35px); */
    font-size: 6.66vw;
    padding-top: 5vw;
  `)};

  ${RWD_SM(css`
    /* font-size: clamp(25px, 8vw, 35px); */
    padding-top: 13.888vw;
  `)};
`;

const PvSubTitle = styled.h1`
  color: ${(props) => props.theme.colors.Green};
  margin: 0;
  padding-bottom: 1.5625vw;
  font-size: 3.125vw;
  font-family: "Poppins";
  font-weight: 500;

  ${RWD_LG(css`
    font-size: 6.5vw;
  `)};

  ${RWD_MD(css`
    /* font-size: clamp(25px, 8vw, 35px); */
    font-size: 6.66vw;
    padding-bottom: 3.88vw;
  `)};
`;

const PvDetail = styled.p`
  text-align: left;
  padding-bottom: 4.6875vw;
  margin: 0;
  font-size: 1.1455833vw;
  color: ${(props) => props.theme.colors.Grey};
  width: 35vw;

  ${RWD_LG(css`
    font-size: 2vw;
    width: 100%;
  `)};

  ${RWD_MD(css`
    /* font-size: clamp(14px, 1.5vw, 16px); */
    /* font-size: 3.611vw; */
  `)};

  ${RWD_MD(css`
    font-size: 3.611vw;
    text-align: center;
    width: 79.722vw;
    padding-top: 3.88vw;
    padding-bottom: 10.277vw;
  `)};
`;

const PvButtonsWrapper = styled.div`
  display: flex;
  /* justify-content: space-between; */
  width: 100%;

  button:first-child {
    margin-right: 2.5vw;
  }

  ${RWD_MD(css`
    justify-content: space-between;

    a:first-child {
      margin-right: 0;
    }
  `)};
`;

const PvImgWrapper = styled.div`
  width: 45%;
  display: flex;

  ${RWD_LG(css`
    width: 80%;
  `)};

  ${RWD_MD(css`
    display: none;
  `)};
`;

const PvImgMobileWrapper = styled.div`
  display: none;

  ${RWD_MD(css`
    display: flex;
    width: 55vw;
  `)};

  ${RWD_SM(css`
    width: 74.1666vw;
  `)};
`;

const PvBgWrapper = styled.div`
  position: absolute;
  display: flex;
  width: 50%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-25%, -50%);

  img {
    width: 100%;
    height: 100%;
    display: inline-block;
  }

  ${RWD_MD(css`
    width: 100%;
    transform: translate(-50%, -100%);
  `)};
`;

const PvImg = styled.img`
  display: inline-block;
  width: 100%;
  height: 100%;
`;

const IntroSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  position: relative;
  z-index: 1;
  width: 100%;

  ${RWD_LG(css`
    min-height: 100%;
  `)};
`;

const IntroTitleWrapper = styled.div`
  text-align: center;
  color: ${(props) => props.theme.colors.White};
  padding-bottom: 4.629vh;
  padding-top: 6.48vh;

  ${RWD_MD(css`
    padding-top: 22.22vw;
    padding-bottom: 7.22vw;
  `)};
`;

const IntroTitle = styled.h2`
  margin: 0;
  padding-bottom: 10px;
  font-size: 2.0833vw;
  font-weight: 500;

  ${RWD_MD(css`
    /* font-size: clamp(22px, 6vw, 32px); */
    /* 20px */
    font-size: 5.55vw;
    padding-bottom: 4px;
  `)};
`;

const IntroSubTitle = styled.h3`
  color: ${(props) => props.theme.colors.Green};
  margin: 0;
  font-size: 1.25vw;
  font-weight: 400;
  ${RWD_MD(css`
    /* font-size: clamp(16px, 5vw, 22px); */
    /* 14px */
    font-size: 3.88vw;
    width: 72.22vw;
  `)};
`;

const IntroCardsWrapper = styled.div`
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: row;
  grid-gap: 1.5625vw;
  width: 75vw;
  /* border: 1px solid red; */

  ${RWD_LG(css`
    width: 80vw;
  `)};

  ${RWD_MD(css`
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat(1, 1fr);
    width: 83.33vw;
  `)};
`;

const IntroCardContainer = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.5);
  cursor: pointer;
  color: ${(props) => props.theme.colors.White};
  width: auto;

  padding: 1.041667vw;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  filter: drop-shadow(0px 4px 49px rgba(0, 7, 72, 0.12));
  backdrop-filter: blur(12.5px);
  transition: all 0.3s;

  &:hover {
    box-shadow: 0px 0px 10px 1px #0aecd1;
    border-color: #0aecd1;
  }

  ${RWD_MD(css`
    border-radius: 10px;
    margin-bottom: 11.11vw;
    padding: 3.055vw;
    width: 100%;
  `)};
`;

const IntroCardImg = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 10px;
  overflow: hidden;

  ${RWD_LG(css`
    /* height: 40vw; */
  `)};

  ${RWD_MD(css`
    width: 100%;
    border-radius: 5px;
    /* height: 54.44vw; */
  `)};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const IntroCardTitle = styled.div`
  font-size: 1.45833vw;
  padding-top: 1.041667vw;
  padding-bottom: 0.520833vw;
  font-weight: 600;
  display: flex;
  font-family: Montserrat;
  justify-content: space-evenly;
  width: 100%;

  /* ${RWD_LG(css`
    font-size: 2vw;
    padding: 1vh 0;
    white-space: normal;
  `)}; */

  /* ${RWD_MD(css`
    font-size: 2.5vw;
  `)}; */

  ${RWD_MD(css`
    /* 18px */
    font-size: 5vw;
    padding: 20px 0;
    /* padding-bottom: 5.22px; */
  `)};
`;

const IntroCardData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 1px solid red; */
`;

const IntroCardDataTitle = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 500;
`;

const IntroCardDataNum = styled.div`
  font-weight: 900;
  text-shadow: ${({ $minus }) =>
    $minus ? "0px 0px 5px #ce0000" : "0px 0px 5px #12b296"};
  color: ${({ $minus }) => ($minus ? "#f85125" : "#b8fb3c")};

  font-family: Montserrat;
  font-size: clamp(22px, 1.5625vw, 30px);

  ${RWD_MD(css`
    font-size: clamp(22px, 3.255vw, 25px);
  `)};
`;

const IntroCardDataTarget = styled.div`
  font-weight: 900;
  color: #01ffff;
  text-shadow: 0px 0px 5px #00c2cb;
  font-family: Montserrat;
  font-size: clamp(22px, 1.5625vw, 30px);
`;

const IntroCardContent = styled.p`
  width: 75%;
  margin: 0;
  font-size: 1.041667vw;
  font-weight: 400;
  letter-spacing: 0.1px;
  margin: 0;
  text-align: center;

  ${RWD_LG(css`
    width: 85%;
  `)};

  ${RWD_MD(css`
    width: 100%;
    /* font-size: clamp(13px, 1.5vw, 16px); */
    /* 13px */
    font-size: 3.611vw;
  `)};
`;

const IntroCardChart = styled.div`
  width: 100%;
  height: 100px;
`;

const IntroBgWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: -20%;

  img {
    width: 100%;
    height: 100%;
    display: inline-block;
  }

  ${RWD_MD(css`
    top: 0;
  `)};
`;

const BgLineWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 50%;
  /* right: 0;
  top: 20%;
  width: 100%; */

  img {
    width: 100%;
    height: 100%;
    display: inline-block;
  }

  ${RWD_MD(css`
    top: 50%;
    /* border: 1px solid blue; */
  `)};
`;

const BgCircleWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 10%;

  img {
    width: 100%;
    height: 100%;
    display: inline-block;
  }

  ${RWD_MD(css`
    bottom: -300%;
    height: 100%;
    /* border: 1px solid pink; */
  `)};
`;

const NewsSection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 7.8125vw 0;
`;

const NewsTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: ${(props) => props.theme.colors.White};
  padding: clamp(80px, 8.333vw, 160px) 0 clamp(30px, 2.604167vw, 50px) 0;
  width: 81.88vw;
  text-align: center;
`;

const NewsTitle = styled.h2`
  margin: 0;
  font-size: clamp(30px, 2.0833vw, 40px);
  font-weight: 500;

  ${RWD_MD(css`
    font-size: clamp(20px, 4.557vw, 35px);
  `)};
`;

const NewsSubTitle = styled.h3`
  color: ${(props) => props.theme.colors.Green};
  margin-top: 5px;
  font-size: clamp(22px, 1.25vw, 24px);
  font-weight: 400;

  ${RWD_MD(css`
    font-size: clamp(16px, 2.6vw, 20px);
  `)};
`;

const NewsCardWrapper = styled.div`
  width: 81.88vw;
`;

const NewsCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  ${RWD_MD(css`
    flex-direction: column;
    gap: 15px;
  `)};
`;
const NewsCardRowWrapper = styled.div`
  width: calc(65% - 30px);
  display: flex;

  ${RWD_MD(css`
    width: 100%;
  `)};
`;

const NewsCardColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
  width: 35%;

  ${RWD_MD(css`
    width: 100%;
    gap: 15px;
  `)};
`;

const NewsCard = styled.div`
  display: flex;
  flex-direction: column;
  color: #fcfcfc;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  /* background: #151721; */
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  filter: drop-shadow(0px 4px 49px rgba(0, 7, 72, 0.12));
  backdrop-filter: blur(12.5px);
  /* border-radius: 20px; */
  padding: 2vw;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    /* box-shadow: 0px 0px 10px 1px #0aecd1; */
    border-color: #0aecd1;
  }

  ${RWD_MD(css`
    width: auto;
    flex-direction: column;
    padding: 3.055vw;
  `)};
`;

const NewsCardTime = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  font-family: Montserrat;
`;

const NewsCardTitle = styled.div`
  font-size: clamp(24px, 1.45833vw, 28px);
  font-weight: 500;
  padding: 10px 0 15px 0;

  ${RWD_MD(css`
    font-size: clamp(16px, 3.125vw, 24px);
  `)};
`;

const NewsImgWrapper = styled.div`
  width: 20%;
  display: inline-flex;
  min-width: 150px;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  ${RWD_MD(css`
    width: 100%;
  `)};
`;

const NewsCardSubTitle = styled.div`
  font-size: clamp(18px, 1.041667vw, 20px);
  font-weight: 500;
  padding: 10px 0 20px 0;
`;

const NewsCardContentContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 10px;

  ${RWD_MD(css`
    flex-direction: column;
  `)};
`;

const NewsCardContent = styled.div`
  text-align: justify;
  width: 80%;
  color: ${(props) => props.theme.colors.Grey};
  ${RWD_MD(css`
    padding-bottom: 15px;
    width: 100%;
  `)};
`;

const NewsCardCommentWrapper = styled.div`
  display: flex;
  align-self: flex-end;
  margin-top: auto;
  gap: 15px;
`;

const NewsCardComment = styled.div`
  font-size: clamp(14px, 0.8333vw, 16px);
  color: ${({ $status }) =>
    $status !== "positive"
      ? $status === "negative"
        ? "#f05d2f"
        : "#00c2cb"
      : "#1cac2a"};

  ${RWD_SM(css`
    font-size: 12px;
  `)};
`;

const NewsCardReadMoreWrapper = styled.div`
  padding-top: 4.629vh;
`;

const ReasonSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  min-height: calc(100vh - 6.25vw);
  position: relative;
  z-index: 1;
  border: 1px solid rebeccapurple;

  ${RWD_LG(css`
    min-height: 100%;
  `)};
`;

const ReasonTitleWrapper = styled.div`
  color: ${(props) => props.theme.colors.White};
  padding: 8.33vw 0 2.604vw 0;

  ${RWD_MD(css`
    padding: 22.22vw 0 8.33vw 0;
  `)};
`;

const ReasonTitle = styled.h2`
  margin: 0;
  font-weight: 500;
  font-size: 2.0833vw;

  ${RWD_MD(css`
    font-size: 5.55vw;
  `)};
`;

const ReasonContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  /* background: linear-gradient(
    105.42deg,
    rgba(255, 255, 255, 0.11) -3.07%,
    rgba(255, 255, 255, 0.11) 49.04%,
    rgba(255, 255, 255, 0) 97.84%
  ); */
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.25));
  /* border-radius: 40px; */
  padding: 4.53125vw 0;

  /* ${RWD_MD(css`
    flex-direction: column;
    width: 85%;
  `)}; */

  ${RWD_MD(css`
    width: 83.33vw;
    padding: 11.944vw 0;
    border-radius: 20px;
    flex-direction: column;
  `)};
`;

const ReasonContentContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  ${RWD_LG(css`
    width: 70vw;
  `)};

  ${RWD_MD(css`
    width: 74.5vw;
    flex-direction: column;
    /* width: 93.75vw; */
  `)};
`;

const ReasonItem = styled.div`
  color: ${(props) => props.theme.colors.White};
  /* width: 25%; */
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid transparent;
  border-image-slice: 1;
  padding: 30px;
  /* ${RWD_LG(css`
    width: 100%;
  `)}; */

  ${RWD_MD(css`
    width: 100%;
    padding-bottom: 11.11vw;

    &:last-child {
      padding-bottom: 0;
    }
  `)};
`;

const ReasonItemIcon = styled.div`
  height: auto;
  display: inline-flex;
  align-items: center;
  padding: 5px;
  border-radius: 5px;

  img {
    width: 6vw;
    height: 6vw;
  }

  /* ${RWD_LG(css`
    font-size: 10vw;
  `)};

  

  ${RWD_MD(css`
    font-size: 15vw;
  `)}; */

  ${RWD_MD(css`
    img {
      width: 18.88vw;
      height: 18.88vw;
    }
  `)};
`;

const ReasonItemTitle = styled.div`
  font-size: 1.45833vw;
  font-weight: 500;
  padding: 30px 0 14px 0;

  ${RWD_LG(css`
    font-size: 2vw;
  `)};

  ${RWD_MD(css`
    font-size: 3vw;
  `)};

  ${RWD_MD(css`
    font-size: 4.44vw;
    padding: 16px 0 4px 0;
  `)};
`;

const ReasonItemContent = styled.p`
  color: ${(props) => props.theme.colors.Grey};
  letter-spacing: 0.1px;
  margin: 0;
  font-size: 1.041667vw;
  text-align: center;
  /* white-space: nowrap; */

  /* ${RWD_MD(css`
    width: 80%;
  `)}; */

  ${RWD_MD(css`
    font-size: 3.88vw;
    width: 100%;
  `)};
`;

const FaqSection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 clamp(100px, 11.979vw, 230px) 0;
  position: relative;
  z-index: 1;
`;

const FaqTitleWrapper = styled.div`
  color: ${(props) => props.theme.colors.White};
  padding: clamp(80px, 8.333vw, 160px) 0 clamp(30px, 2.604167vw, 50px) 0;
`;

const FaqTitle = styled.h2`
  margin: 0;
  font-family: "Poppins", "Noto Sans TC", sans-serif;
  font-size: clamp(30px, 2.0833vw, 40px);
  font-weight: 500;

  ${RWD_MD(css`
    font-size: clamp(20px, 4.557vw, 35px);
  `)};
`;

const FaqSlidesWrapper = styled.div`
  width: 75vw;
  /* display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 35vh;
  border: 1px solid rebeccapurple; */
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: column;
  grid-gap: 1.5625vw 2.06vw;
  align-items: flex-start;

  ${RWD_MD(css`
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(6, auto);
    grid-gap: 20px 0;
    width: 81.88vw;
  `)};
`;

const FaqSlideContainer = styled.div`
  width: auto;
  text-align: left;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  padding: 17px 19px;
  min-height: 0;
  cursor: pointer;
  transition: all 0.3s;

  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  filter: drop-shadow(0px 4px 49px rgba(0, 7, 72, 0.12));
  backdrop-filter: blur(12.5px);

  ${RWD_MD(css`
    padding: 15px 20px;
  `)};

  &:hover {
    background: radial-gradient(
      circle 610px at 5.2% 51.6%,
      rgba(5, 8, 114, 1) 0%,
      rgba(7, 3, 53, 1) 97.5%
    );
  }
`;

const FaqSlideQuestion = styled.div`
  color: ${(props) => props.theme.colors.Green};
  font-size: clamp(18px, 1.145833vw, 22px);

  ${RWD_MD(css`
    font-size: clamp(15px, 2.34375vw, 18px);
  `)};
`;

const FaqSlideAns = styled.p`
  color: ${(props) => props.theme.colors.White};
  margin: 0;
  font-size: clamp(16px, 1.041667vw, 20px);
  transition: all 0.5s;
  height: ${(props) => (props.isOpen ? "auto" : "0")};
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  padding: ${(props) => (props.isOpen ? "1.041667vw 0 0 0" : " 0")};

  ${RWD_MD(css`
    font-size: clamp(14px, 2.0833vw, 16px);
    padding: ${(props) => (props.isOpen ? "5px 0 0 0" : " 0")};
  `)};
`;

const FaqButtonWrapper = styled.div`
  display: none;
  color: ${(props) => props.theme.colors.White};
  padding-top: 4.27vw;

  ${RWD_MD(css`
    padding-top: 13.88vw;
  `)}
`;

const TeamSection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 7.8125vw 0;
`;

const TeamTitleWrapper = styled.div`
  color: ${(props) => props.theme.colors.White};
  padding: clamp(80px, 8.333vw, 160px) 0 clamp(30px, 2.604167vw, 50px) 0;
`;

const TeamTitle = styled.h2`
  margin: 0;
  font-size: clamp(30px, 2.0833vw, 40px);
  font-weight: 500;

  ${RWD_MD(css`
    font-size: clamp(20px, 4.557vw, 35px);
  `)};
`;

const TeamCardsWrapper = styled.div`
  width: 70vw;

  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: row;
  grid-gap: 5.2vw;

  ${RWD_MD(css`
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat(1, 1fr);
  `)};

  ${RWD_SM(css`
    width: 81.88vw;
  `)};
`;

const TeamCardContainer = styled.div`
  display: flex;
  width: auto;

  /* border: 1px solid rgba(255, 255, 255, 0.2); */

  /* border-radius: 10px; */
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;

  box-sizing: border-box;

  ${RWD_MD(css`
    margin-bottom: 5.5vh;
    /* padding: 5.55vw; */
    flex-direction: column;
    /* border: 1px solid red; */
  `)};
`;

const TeamCardContentWrapper = styled.div`
  text-align: left;
  padding: 15px 18px;
  flex: 1;

  ${RWD_SM(css`
    padding: 15px 20px;
  `)};
`;

const TeamCardImg = styled.div`
  width: 100%;
  height: clamp(200px, 1.5625, 300px);
  display: flex;

  ${RWD_MD(css`
    width: 100%;
  `)};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
`;

const TeamCardName = styled.div`
  color: #48d6d2;
  font-weight: 500;
  padding-bottom: 10px;
  font-size: clamp(18px, 1.25vw, 24px);

  ${RWD_MD(css`
    font-size: clamp(18px, 3.125vw, 24px);
  `)};
`;

const TeamCardIntro = styled.p`
  color: ${(props) => props.theme.colors.White};
  margin: 0;
  font-size: clamp(16px, 0.9375vw, 18px);

  ${RWD_MD(css`
    font-size: clamp(14px, 2.864vw, 22px);
  `)};
`;

const TeamBgWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 20%;
  z-index: -1;
  /* right: 0;
  top: 20%;
  width: 100%; */

  img {
    width: 100%;
    height: 100%;
    display: inline-block;
  }

  ${RWD_MD(css`
    /* border: 1px solid yellow; */
    top: 0;
    height: 100%;
  `)};
`;

const PlanSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1;
  padding-bottom: 11.979vh;

  ${RWD_MD(css`
    min-height: 100%;
    padding-bottom: 27.77vw;
  `)};
`;

const PlanTitleWrapper = styled.div`
  color: #fff;
  padding: 13.54vw 0 5.625vw 0;
  text-align: center;

  ${RWD_MD(css`
    padding: 22.22vw 0 8.33vw 0;
  `)};
`;

const PlanTitle = styled.h2`
  margin: 0;
  /* padding-bottom: 1vh; */
  font-size: 2.0833vw;
  font-weight: 500;
  padding-bottom: 0.5208vw;

  ${RWD_MD(css`
    /* font-size: clamp(22px, 6vw, 32px); */
    font-size: 5.55vw;
    padding-bottom: 8px;
  `)};
`;

const PlanSubTitle = styled.h3`
  color: ${(props) => props.theme.colors.Grey};
  margin: 0;
  font-size: 1.25vw;
  font-weight: 400;
  width: 33.125vw;

  ${RWD_MD(css`
    /* font-size: clamp(16px, 5vw, 22px); */
    /* 14px */
    font-size: 3.88vw;
    width: 72.22vw;
  `)};
`;

const PlanContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 63.28vw;
  min-height: 34.635vw;
  height: 100%;

  ${RWD_LG(css`
    min-height: 39vw;
  `)};

  ${RWD_LG(css`
    width: 65vw;
  `)};

  ${RWD_MD(css`
    flex-direction: column-reverse;
    width: 83.33vw;
  `)};
`;

const PlanProgressWrapper = styled.div`
  width: 20.833vw;
  background-image: url(${BgPlan});
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;

  ${RWD_LG(css`
    width: 25vw;
  `)};

  ${RWD_MD(css`
    width: 100%;
    padding-top: 10vw;
    height: 83.33vw;
  `)};
`;

const PlanProgressContainer = styled.div`
  height: 100%;

  ${RWD_MD(css`
    height: 83.33vw;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  `)};
`;

const PlanProgressList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;

  height: 100%;

  ${RWD_MD(css`
    /* width: 61.11vw; */
    width: 100%;
    height: 83.33vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  `)};

  &::after {
    content: "";
    width: 2px;
    left: calc(50% - 1px);
    position: absolute;
    transition: height 2s;
    z-index: 1;
    height: ${(props) =>
      props.active !== 1
        ? props.active !== 4
          ? 25 * props.active - 12.5 + "%"
          : "100%"
        : "12.5%"};
    top: 0;
    background-color: ${(props) => props.theme.colors.LightGreen};
  }
`;

const PlanProgressCircle = styled.div`
  position: absolute;
  width: 2.0833vw;
  height: 2.0833vw;
  display: block;
  margin: auto;
  border-radius: 50%;
  text-align: center;
  z-index: 4;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
  transition: all 2s;
  background-color: ${(props) =>
    props.active ? "rgba(10, 236, 209, 0.8)" : "transparent"};

  ${RWD_MD(css`
    width: 5.55vw;
    height: 5.55vw;
  `)};
`;

const PlanProgressItem = styled.li`
  color: ${(props) => props.theme.colors.White};
  position: relative;
  height: 25%;

  &::before {
    position: absolute;
    content: "";
    width: ${(props) => (props.active ? "1.041667vw" : "1.5625vw")};
    height: ${(props) => (props.active ? "1.041667vw" : "1.5625vw")};
    display: block;
    margin: auto;
    border-radius: 50%;
    text-align: center;
    z-index: 5;
    top: 50%;
    left: 50%;
    transition: all 2s;
    transform: translate(-50%, -50%);
    background-color: ${(props) =>
      props.active === props.id
        ? "rgba(10, 236, 209, 0.8)"
        : props.active > props.id
        ? props.theme.colors.LightGreen
        : props.theme.colors.DarkGrey};
  }

  ${RWD_MD(css`
    height: calc(83.33vw / 4);
    width: 100%;
    &::before {
      width: ${(props) => (props.active ? "2.77vw" : "2.77vw")};
      height: ${(props) => (props.active ? "2.77vw" : "2.77vw")};
    }
  `)};

  &::after {
    content: "";
    width: 2px;
    left: calc(50% - 1px);
    position: absolute;
    transition: height 2s;
    z-index: 1;
    height: 100%;
    top: 0;

    background-color: ${(props) => props.theme.colors.DarkGrey};
  }
`;

const PlanProgressContentWrapper = styled.div`
  /* padding: 2vw 0; */
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: all 1s;
  position: relative;
  top: 50%;
  transform: translate(0%, -50%);

  color: ${(props) =>
    props.active ? props.theme.colors.Green : props.theme.colors.White};
  cursor: pointer;

  ${RWD_MD(css`
    padding: 0;

    /* width: 200px; */
    padding: 0vw 0;
  `)};

  &::after {
    content: "";
    width: 2px;
    left: calc(50% - 1px);
    position: absolute;
    transition: height 1s;
    z-index: 10;

    /* height: ${(props) => {
      if (props.id === props.active) {
        return "50%";
      } else if (props.id < props.active) {
        return "100%";
      } else {
        return "0%";
      }
    }}; */
    top: 0;
    background-color: rgba(72, 214, 210, 0.5);
  }

  /* position: absolute; */
`;

const PlanProgressTime = styled.div`
  /* width: 45%; */
  font-size: 1.875vw;

  margin: ${(props) =>
    props.direction === "right" ? "0 0 0 auto" : "0 auto 0 0"};
  text-align: ${(props) => (props.direction === "right" ? "left" : "end")};

  /* ${RWD_MD(css`
    width: 40%;
    font-size: 3.5vw;
  `)}; */

  ${RWD_MD(css`
    width: 40%;
    /* 18px */
    font-size: 5vw;
  `)};
`;

const PlanProgressContent = styled.div`
  /* width: 45%; */
  font-size: 1.25vw;
  margin: ${(props) =>
    props.direction === "right" ? "0 0 0 auto" : "0 auto 0 0"};
  text-align: ${(props) => (props.direction === "right" ? "left" : "end")};

  ${RWD_MD(css`
    width: 40%;
    /* width: 100%; */
    font-size: 3.88vw;
    /* font-size: clamp(15px, 4vw, 18px); */
  `)};
`;

const PlanCard = styled(animated.div)`
  color: ${(props) => props.theme.colors.White};
  border-radius: 30px;
  width: 32.29vw;
  padding: 1.5625vw;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  filter: drop-shadow(0px 4px 49px rgba(0, 7, 72, 0.12));
  backdrop-filter: blur(12.5px);

  height: 100%;
  /* transition: all 0.3s; */

  ${RWD_MD(css`
    width: auto;
    flex-direction: column;
    padding: 3.055vw;
    border-radius: 10px;
  `)};
`;

const PlanCardTitle = styled.div`
  font-size: 1.8vw;
  padding: 1.5625vw 0;
  text-align: center;

  /* ${RWD_MD(css`
    font-size: 3vw;
    text-align: left;
    padding-top: 0;
  `)}; */

  ${RWD_MD(css`
    text-align: center;
    font-size: 5vw;
    padding: 8px;
  `)};
`;

const PlanCardImg = styled.div`
  width: 100%;
  height: 12.5vw;
  display: flex;
  border-radius: 15px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* ${RWD_MD(css`
    width: 50%;
    height: 250px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `)}; */

  ${RWD_MD(css`
    width: auto;
    border-radius: 5px;
    height: 54.44vw;
  `)};
`;

const PlanCardContentWrapper = styled.div`
  text-align: left;
  /* ${RWD_MD(css`
    width: 50%;
    margin-left: 3vw;
  `)}; */

  ${RWD_MD(css`
    width: 100%;
    margin: 0;
  `)};
`;

const PlanCardContentContainer = styled.div`
  padding-bottom: 1.5625vw;

  &:last-child {
    padding-bottom: 0;
  }

  ${RWD_MD(css`
    padding-bottom: 10px;
  `)};
`;

const PlanCardSubTitle = styled.div`
  font-size: 1.25vw;
  text-align: center;

  /* ${RWD_MD(css`
    font-size: 2vw;
  `)}; */

  ${RWD_MD(css`
    font-size: 4.44vw;
  `)};
`;

const PlanCardContent = styled.div`
  margin: 0;
  font-size: 1.1458vw;

  color: ${(props) => props.theme.colors.Grey};

  p &:first-child {
    padding-bottom: 30px;
  }

  ${RWD_MD(css`
    font-size: 3.88vw;
  `)};
`;

const PlanCardButtonWrapper = styled.div`
  /* border: 1px solid red; */
  padding-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BannerSection = styled.div`
  height: 15.625vw;
  min-height: 300px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  /* border: 1px solid salmon; */
  overflow: hidden;

  ${RWD_MD(css`
    height: 55.55vw;
  `)};

  /* background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: url(${Building}); */

  /* ${RWD_LG(css`
    height: 20vh;
  `)}; */
`;

const BannerImg = styled.div`
  position: absolute;
  /* background-image: url(${Building}); */
  /* background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  left: 0;
  top: 0;
  height: 1000%;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3),
    url(${Building})
  ); */

  /* position: relative; */
  background-color: #fff;
  height: 200%;
  width: 100%;
  left: 0;
  top: 0;
  display: block;

  &::before {
    content: "";
    background-image: url(${Building});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.6;
  }
`;

const IconWrapper = styled.div`
  color: ${(props) => props.theme.colors.White};
  display: flex;
  align-items: center;
  justify-content: center;
  width: clamp(32px, 2.2291667vw, 44px);

  ${RWD_MD(css`
    /* width: 8.88vw; */
  `)};
`;

const BannerButtonText = styled.div`
  color: ${(props) => props.theme.colors.White};
  /* font-size: clamp(14px, 1.5vw, 16px); */
  font-size: clamp(18px, 1.145vw, 22px);
  position: relative;
  display: inline-block;
  font-weight: 500;
`;

const ContactSection = styled.div`
  display: flex;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 80%;
  padding: 7.1875vw;

  ${RWD_MD(css`
    width: 83.33vw;
    padding: 0 0 13.88vw 0;
  `)};
`;

const ContactTitleWrapper = styled.div`
  color: ${(props) => props.theme.colors.White};
  padding-bottom: 4.629vh;

  ${RWD_MD(css`
    padding: 13.88vw 0 8.33vw 0;
  `)};
`;

const ContactTitle = styled.h2`
  margin: 0;
  padding-bottom: 10px;
  font-family: "Abhaya Libre";
  font-size: 2.5vw;
  font-weight: 500;

  ${RWD_MD(css`
    /* font-size: clamp(22px, 6vw, 32px); */
    font-size: 5.55vw;
  `)};
`;

const ContactSubTitle = styled.h3`
  margin: 0;
  font-size: 1.25vw;
  font-weight: 400;
  color: ${(props) => props.theme.colors.Grey};
  ${RWD_MD(css`
    font-size: 3.88vw;
  `)};
`;

const ContactFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45.1vw;

  ${RWD_MD(css`
    width: 100%;
  `)};
`;

const ContactDataWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  ${RWD_MD(css`
    flex-direction: column-reverse;
  `)};
`;

const ContactEmail = styled.input`
  border-radius: 116.732px;
  padding: 1vw 1.5vw 1vw 2vw;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  margin-right: 1.2625vw;
  font-size: 0.9375vw;
  border: 1px solid transparent;
  background-color: ${(props) => props.theme.colors.Grey};

  ${RWD_MD(css`
    width: 100%;
    padding: 4.722vw 6.66vw;
    border-radius: 4px;
    margin-bottom: 4.44vw;
    font-size: 4.44vw;
  `)};
`;

const ContactName = styled.input`
  border-radius: 116.732px;
  padding: 1vw 1.5vw 1vw 2vw;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  font-size: 0.9375vw;
  align-items: center;
  border: 1px solid transparent;
  background-color: ${(props) => props.theme.colors.Grey};

  /* ${RWD_MD(css`
    padding: 2vw 2vw 2vw 4vw;
  `)}; */

  ${RWD_MD(css`
    width: 100%;
    padding: 4.722vw 6.66vw;
    border-radius: 4px;
    margin-bottom: 4.44vw;
    font-size: 4.44vw;
  `)};
`;

const ContactMessageWrapper = styled.div`
  width: 100%;
  border-radius: 116.732px;
  display: flex;
  background-color: ${(props) => props.theme.colors.Grey};
  margin-top: 1.5625vw;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  overflow: hidden;
  /* border-top: ${(props) => `2px solid ${props.theme.colors.Grey}`}; */

  ${RWD_MD(css`
    height: 100%;
    width: 100%;
    flex-direction: column;
    background-color: transparent;
    margin-top: 0;
    border-radius: 0;
  `)};
`;

const ContactMessage = styled.textarea`
  width: 70%;
  height: auto;
  padding: calc(1vw - 8px) 1.5vw 0 2vw;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  /* width: 100%; */
  font-size: 0.9375vw;
  outline: none;
  border: 0;
  /* width: 80%; */
  border: none;
  border-radius: 116.732px;
  /* border: 1px solid red; */
  /* border: 1px solid transparent; */
  background-color: ${(props) => props.theme.colors.Grey};
  /* border-radius: 116.732px; */
  font-family: "Poppins", "Noto Sans TC", sans-serif;
  resize: none;
  /* border: 1px solid red; */
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  position: absolute;

  /* &::placeholder {
    position: absolute;
    top: 0.5vw;
  } */
  /* ${RWD_MD(css`
    padding: 2vw 2vw 2vw 4vw;
  `)}; */

  ${RWD_MD(css`
    position: static;
    transform: translate(0, 0);
    width: 100%;
    height: 55.55vw;
    border-radius: 4px;
    margin-bottom: 4.44vw;
    font-size: 4.44vw;
    padding: 4.722vw 6.66vw;

    &::placeholder {
      position: static;
    }
  `)};
`;

const ContactButton = styled.div`
  border-radius: 102px;
  padding: 0.8333vw 2.5vw;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.White};
  font-size: 1.041667vw;
  border: ${(props) => `4px solid ${props.theme.colors.Grey}`};
  background: ${(props) =>
    props.disable ? props.theme.colors.DarkGrey : props.theme.colors.Blue};
  cursor: ${(props) => (props.disable ? "default" : "pointer")};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  /* margin: 3px; */
  transition: all 0.3s; /* ${RWD_MD(css`
    padding: 0.5vw 4vw;
  `)}; */
  ${RWD_MD(css`
    border: 0;
    border-radius: 4px;
    height: 13.33vw;
    font-size: 4.44vw;
    width: 100%;
  `)};
`;

const Footer = styled.div`
  width: 100%;
  background: ${(props) => props.theme.colors.Black};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FooterWrapper = styled.div`
  width: 82.2vw;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-items: stretch;
  padding: 2.91666vw 0 0 0;

  ${RWD_MD(css`
    padding: 13.88vw 0 0 0;
    flex-direction: column;
    align-items: center;
  `)};
`;

const FooterListWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${RWD_MD(css`
    flex-direction: column;
    align-items: center;
  `)};
`;

const FooterList = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 5vw;

  ${RWD_MD(css`
    padding-right: 0;
    width: 59.72vw;
    padding-bottom: 11.11vw;
  `)};
`;

const FooterItemWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction};
  transition: all 0.3s;
  height: ${(props) => (props.isOpen ? "auto" : "0")};
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
`;

const FooterItemTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${RWD_MD(css`
    padding-bottom: ${(props) => (props.isOpen ? "20px" : "0")};
  `)};
`;

const FooterItemTitle = styled.div`
  color: ${(props) => props.theme.colors.Green};
  font-size: 1.5vw;
  opacity: 1;
  font-weight: 600;
  padding-bottom: 10px;

  ${RWD_MD(css`
    /* 24px */
    font-size: 6.66vw;
    padding-bottom: 0;

    &:last-child {
      padding-bottom: 20px;
    }
  `)};
`;

const FooterItem = styled.div`
  text-align: left;
  display: flex;
  padding-bottom: 6px;
  color: ${(props) => props.theme.colors.Grey};
  font-size: 1.041667vw;
  letter-spacing: 0.5px;
  font-weight: 400;

  a {
    cursor: pointer;
    text-decoration: none;
    color: ${(props) => props.theme.colors.Grey};
  }

  ${RWD_MD(css`
    /* font-size: clamp(13px, 1vw, 15px); */
    justify-content: space-between;
    font-size: 5vw;
    padding: 0 0 20px 0;

    &:last-child {
      padding-bottom: 0vw;
      /* font-size: clamp(15px, 4vw, 18px); */
    }
  `)};
`;

const FooterIcon = styled.div`
  padding: 0 1vw 0 0;
  width: 2.0833vw;
  display: flex;

  img {
    display: inline-block;
    height: 100%;
    width: 100%;
  }

  ${RWD_MD(css`
    width: 11.11vw;
    padding: 0;

    margin-right: 11.11vw;

    &:last-child {
      margin: 0;
    }
  `)};
`;

const FooterListIcon = styled.div`
  display: none;
  transition: all 0.3s;
  cursor: pointer;
  img {
    display: inline-block;
    height: 100%;
    width: 100%;
  }
  ${RWD_MD(css`
    width: 8.88vw;
    display: flex;
    z-index: 2;
    transform: ${(props) => (props.isOpen ? "scaleY(-1)" : "scaleY(1)")};
  `)};
`;

const FooterSloganWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  overflow: hidden;

  ${RWD_MD(css`
    align-items: center;
    padding-top: 2.77vw;
  `)};
`;

const FooterSloganLogo = styled.div`
  width: 5.729vw;
  display: flex;
  padding-bottom: 1.66vw;

  ${RWD_MD(css`
    width: 32.5vw;
  `)};

  img {
    width: 100%;
    height: 100%;
    display: inline-block;
  }
`;

const FooterSlogan = styled.div`
  color: ${(props) => props.theme.colors.DarkGrey};
  text-align: end;
  font-size: 1.041667vw;
  /* opacity: 0.8; */
  padding-bottom: 2.6vw;

  /* ${RWD_MD(css`
    text-align: end;
    padding: 2vh 0;
    font-size: clamp(13px, 1.5vw, 16px);
  `)}; */

  ${RWD_MD(css`
    /* 16px */
    font-size: 4.44vw;
    padding: 8.88vw 0 13.88vw 0;
  `)};
`;

const FooterSloganContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  &:first-child {
    margin-right: -0.5vw;
  }

  span:first-child {
    padding-right: 0.5vw;
  }

  ${RWD_MD(css`
    flex-direction: column;
    text-align: center;
  `)};
`;

const FooterLanguageWrapper = styled.div`
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  ${RWD_MD(css`
    /* padding: 2vh 0; */

    /* position: absolute; */
  `)};
`;

const FooterLanguage = styled.div`
  padding: 0.5vw 0 0.5vw 1vw;
  font-size: 1.041667vw;
  /* ${RWD_MD(css`
    font-size: clamp(13px, 1.5vw, 16px);
  `)}; */

  ${RWD_MD(css`
    font-size: 5vw;
    padding: 0 7.22vw;
  `)};
`;

const FooterLanguageIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.666vw;
  transition: all 0.3s;
  transform: ${(props) => (props.isOpen ? "scaleY(-1)" : "scaleY(1)")};

  svg {
    transition: all 0.5s;
    transform: ${(props) => (props.isOpen ? "scaleY(-1)" : "scaleY(1)")};
  }

  ${RWD_MD(css`
    width: 8.88vw;
  `)};
`;

const FooterLanguageList = styled(animated.ul)`
  list-style: none;
  padding: 0;
  position: absolute;
  /* bottom: 20%; */
  background: ${(props) => props.theme.colors.White};
  width: auto;
  margin: 0;
  right: 0%;
  /* top: 50%; */
  padding: 1vw 2.5vw;
  border-radius: 10px;
  transform: translate(0%, -60%);
  width: auto;
  /* z-index: -1; */

  ${RWD_MD(css`
    /* font-size: clamp(13px, 1.5vw, 16px); */
    /* padding: 1vw 5vw; */
    width: 51.11vw;
    /* width: auto; */
    padding: 5vw 0;
    left: 50%;
    /* top: 50%; */
    transform: translate(-50%, -60%);
  `)};
`;

const FooterLanguageItem = styled.li`
  color: black;
  font-size: 1.041667vw;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.DarkBlue};
  }

  &:first-child {
    padding-bottom: 1vw;
  }

  ${RWD_MD(css`
    /* font-size: clamp(13px, 1.5vw, 16px); */
    /* padding: 1vw 5vw; */
    font-size: 5.55vw;

    &:first-child {
      padding-bottom: 5vw;
    }
  `)};
`;

const CopyRightWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.Black};
  width: 100%;
`;

const CopyRightContent = styled.span`
  padding: 0 0 2.0833vw 0;
  color: ${(props) => props.theme.colors.DarkGrey};
  font-size: 0.833vw;

  ${RWD_MD(css`
    padding: 8.33vw 0 13.88vw 0;
    font-size: 4.44vw;
  `)};
`;

const LanguageWrapper = styled.div`
  border: 1px solid rebeccapurple;
`;

const TypeB = () => {
  const navigate = useNavigate();
  const [activeAlgo, setActiveAlgo] = useState("");
  const [algoModalIsOpen, setAlgoModalIsOpen] = useState(false);
  const [activeNews, setActiveNews] = useState("");
  const [newsModalIsOpen, setNewsModalIsOpen] = useState(false);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    refreshViewHeight();
  }, []);

  useEffect(() => {
    refreshViewHeight();
  }, [windowSize[0]]);

  const refreshViewHeight = () => {
    const vh = windowSize[1] * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  const handleOpenAlgo = (id) => {
    setActiveAlgo(id);
    setAlgoModalIsOpen(true);
  };

  const handleOpenNews = (id) => {
    setActiveNews(id);
    setNewsModalIsOpen(true);
  };

  const smoothScrollInit = () => {
    const scroll = new SmoothScroll('a[href*="#"]', {
      speed: 1000,
      header: "[data-scroll-header]",
    });
    document.addEventListener("scrollStart", function () {
      setHamIsOpen(false);
    });
  };

  useEffect(() => {
    smoothScrollInit();
    AOS.init();
  }, []);

  // const menuList = [
  //   {
  //     text: "Home",
  //     text_ch: "首頁",
  //   },
  //   {
  //     text: "News",
  //     text_ch: "新聞",
  //   },
  //   {
  //     text: "Products",
  //     text_ch: "產品",
  //   },
  //   {
  //     text: "About Us",
  //     text_ch: "關於我們",
  //   },
  //   {
  //     text: "FAQs",
  //     text_ch: "常見問題",
  //   },
  // ];

  const menuList = [
    {
      anchor: "Home",
      name: [
        {
          ch: "首頁",
          en: "Home",
        },
      ],
    },
    {
      anchor: "News",
      name: [
        {
          ch: "AI 新聞分析資訊",
          en: "AI News Analysis",
        },
      ],
    },
    {
      anchor: "Products",
      name: [
        {
          ch: "產品介紹",
          en: "Products",
        },
      ],
    },
    {
      anchor: "About Us",
      name: [
        {
          ch: "關於我們",
          en: "About Us",
        },
      ],
    },
    {
      anchor: "FAQs",
      name: [
        {
          ch: "常見問題",
          en: "FAQs",
        },
      ],
    },
  ];

  const { data, isSuccess, isError, error, isLoading, refetch } =
    useGetLandingAlgosQuery();

  const {
    data: newsData,
    isSuccess: newsDataIsSuccess,
    isError: newsDataIsError,
    error: newsDataError,
    isLoading: newsDataIsLoading,
    refetch: newsDataRefetch,
  } = useGetBlockChainNewsPreviewQuery();

  const [algoData, setAlgoData] = useState([]);
  const [newsList, setNewsList] = useState([]);

  useEffect(() => {
    if (newsDataIsSuccess) {
      const news = newsData?.BlockChainNews.map((data, index) => {
        return {
          ...data,
          id: index + 1,
          Comment: data.Comment.split("，")
            .map((item) => item.split("：")[1])
            .map((el) => {
              switch (el) {
                case "正面":
                  return "positive";

                case "中性":
                  return "neutral";

                case "負面":
                  return "negative";
              }
            }),
        };
      });

      setNewsList(news);
    }
  }, [newsData]);

  useEffect(() => {
    if (isSuccess) {
      const algoList = data?.LandingAlgos.map((algo, index) => {
        switch (algo.RobotName) {
          case "Rifle":
            return {
              ...algo,
              id: index + 1,
              title: "精準步槍",
              src: Rifle,
              mobileSrc: Rifle_landscape,
              content: [
                "以小博大，追求高風險高回報！",
                "中等時間週期營造穩健收益，讓小資金大展身手",
              ],
            };
          case "Drone":
            return {
              ...algo,
              id: index + 1,
              title: "無人機",
              src: Drone,
              mobileSrc: Drone_landscape,
              content: [
                "如無人機輕盈於雲端，DCA交易機器人於市場間翻滾。",
                "靜心、智投、淡定追求。",
              ],
            };
          case "Howitzer":
            return {
              ...algo,
              id: index + 1,
              title: "榴彈砲",
              src: Howitzer,
              mobileSrc: Howitzer_landscape,
              content: [
                "追蹤戰場上的菁英，進行大範圍的攻擊；",
                "期許得到豐富的戰果，是您長久作戰的必要裝備。",
              ],
            };
        }
      });

      setAlgoData(algoList);
    }
  }, [data]);

  const introList = [
    {
      id: 1,
      src: Rifle,

      target: "BTC",
      title: "精準步槍",
      content: [
        "以小博大，追求高風險高回報！",
        "中等時間週期營造穩健收益，讓小資金大展身手",
      ],
      roi: 41.56,
      profit: +12356.67,
      day: 180,
      winning: 72,
    },
    {
      id: 2,
      src: Drone,

      target: "BTC",
      title: "無人機",
      content: [
        "如無人機輕盈於雲端，DCA交易機器人於市場間翻滾。",
        "靜心、智投、淡定追求。",
      ],
      roi: 123.56,
      profit: +321,
      day: 58,
      winning: 46,
    },
    {
      id: 3,
      src: Howitzer,

      target: "ETH",
      title: "榴彈砲",
      content: [
        "追蹤戰場上的菁英，進行大範圍的攻擊；",
        "期許得到豐富的戰果，是您長久作戰的必要裝備。",
      ],
      roi: 1.56,
      profit: -6.43,
      day: 32,
      winning: 46,
    },
    // {
    //   id: 4,
    //   src: Features_4,
    //   title: "智能分析監控衛星",
    //   content:
    //     "時時觀察，全面掌握戰場消息；即時反應情緒，輔助您做出最佳策略與判斷。",
    // },
    // {
    //   id: 5,
    //   src: Features_5,
    //   title: "攻堅無人機 Algo",
    //   content:
    //     "只在最關鍵的時候，悍不畏死的衝鋒陷陣，一擊斃命。生存——才是戰場第一守則。",
    // },
    // {
    //   id: 6,
    //   src: Features_6,
    //   title: "菁英散彈槍 Algo",
    //   content:
    //     "追蹤戰場上的菁英，進行大範圍的攻擊；期許得到豐富的戰果，是您長久作戰的必要裝備。",
    // },
  ];

  const reasonList = [
    {
      id: 1,
      src: Intro_1,
      title: "低風險與分散資金管理",
      content: "嚴守紀律，精準執行。<br>降低風險，避免交易所流動性不足風險。",
    },
    {
      id: 2,
      src: Intro_2,
      title: "優秀的團隊與策略",
      content:
        "歷經牛熊轉換的千錘百鍊。<br>由資深投資專家規劃，確保獲利最大機會。",
    },
    {
      id: 3,
      src: Intro_3,
      title: "佈局與未來展望",
      content: "腳踏實地卻不拘泥現況。<br>技術與專業並進，佈局未來，搶得先機。",
    },
  ];

  const [faqList, setFaqList] = useState([
    {
      id: 1,
      title: [
        {
          ch: "為什麼需要綁定API金鑰?",
          en: "Why do we need to bind the API key?",
        },
      ],
      content: [
        {
          ch: "系統之追蹤機器人以及投資機器人，是輔助您操盤的工具，因為需要實時獲取錢包內的訊息，所以需要綁定APIKey。",
          en: "The system's tracking and investment robots are tools to assist you in trading. To retrieve real-time information from your wallet, binding the API key is necessary.",
        },
      ],
      isOpen: false,
    },
    {
      id: 2,
      title: [
        {
          ch: "提供APIKey是否有可能被提領?",
          en: "Is it possible for my funds to be withdrawn by providing the API key?",
        },
      ],
      content: [
        {
          ch: "目前各大平台提供之APIKey均有權限的相關設定，可以親自關閉APIKey之提領功能。為避免不必要的糾紛，我們亦會於綁定APIKey時請客戶確認是否關閉權限。",
          en: "Currently, the API keys provided by major platforms have related permission settings. You can personally disable the withdrawal feature of the API key. To avoid unnecessary disputes, we also ask customers to confirm whether they have disabled the permission when binding the API key.",
        },
      ],
      isOpen: false,
    },
    {
      id: 3,
      title: [
        {
          ch: "我要如何獲得分潤獎勵?",
          en: "How can I earn profit sharing rewards?",
        },
      ],
      content: [
        {
          ch: "基於利益共享原則，本平台提供分潤獎勵。用戶(推薦人)讓其他用戶(被推薦人)輸入推薦碼加入平台後，被推薦人若提升為Lv1，當被推薦人結算利潤時，推薦人會獲得相應的利潤分成。",
          en: "Based on the principle of profit sharing, our platform offers profit sharing rewards. When a user (referrer) refers another user (referee) who joins the platform using the referral code, and the referee advances to Lv1, the referrer will receive a corresponding profit share when the referee settles their profits.",
        },
      ],
      isOpen: false,
    },
    {
      id: 4,
      title: [
        {
          ch: "我要如何加入雲端挖礦?",
          en: "How can I join cloud mining?",
        },
      ],
      content: [
        {
          ch: "至雲端挖礦頁面點選加入即可。",
          en: 'Simply go to the cloud mining page and click "Join."',
        },
      ],
      isOpen: false,
    },
    {
      id: 5,
      title: [
        {
          ch: "TXID是什麼? 我填錯TXID要怎麼辦?",
          en: "What is a TXID? What should I do if I enter the wrong TXID?",
        },
      ],
      content: [
        {
          ch: "TXID為區塊鏈特有之轉帳ID，每筆轉帳都有其惟一的TXID，主要用作系統自動對帳。如若填錯TXID，系統會在對帳之後提示您【TXID異常】，此時您就可以再次填入TXID。",
          en: "TXID is a unique transaction ID specific to the blockchain. Every transaction has its unique TXID, mainly used for system automatic reconciliation. If you enter the wrong TXID, the system will prompt you with [TXID exception] after reconciliation, at which point you can re-enter the TXID.",
        },
      ],
      isOpen: false,
    },
    {
      id: 6,
      title: [
        {
          ch: "我要更改返還地址要如何操作?",
          en: "How can I change the return address?",
        },
      ],
      content: [
        {
          ch: "返還地址為轉帳重要依據，不可任意更動，如需更動請來信 【service@avairobot.com】申請。申請格式： 1.申請人帳號：2.申請人手機：3.原始地址：4.更換地址：5.更換地址申請時間：",
          en: "The return address is a crucial basis for transactions and cannot be changed arbitrarily. If you need to change it, please email [service@avairobot.com] to apply. Application format: Applicant account: Applicant phone: Original address: New address: Application time for address change:",
        },
      ],

      isOpen: false,
    },
  ]);

  const [planList, setPlanList] = useState([
    {
      id: 1,
      time: "2022.06",
      name: "NFT應用概念",
      title: "建置NFT應用概念",
      src: Plan_1,
      contentList: [
        {
          title: "機器人使用權",
          // content:
          //   "簡短的說明，文字簡短的說明文字簡短的說明文字簡短的說明文字字，短的說明文字簡短的說明文字。",
        },
        {
          title: "原始碼股權",
          // content:
          //   "簡短的說明，文字簡短的說明文字簡短的說明文字簡短的說明文字字，短的說明文字簡短的說明文字。",
          button: true,
        },
      ],
      isActive: true,
    },
    {
      id: 2,
      time: "2022.12",
      name: "研究與規劃",
      title: "研究與規劃",
      src: Plan_2,
      contentList: [
        {
          title: "白皮書撰寫",
          // content:
          //   "簡短的說明，文字簡短的說明文字簡短的說明文字簡短的說明文字字，短的說明文字簡短的說明文字。",
        },
        {
          title: "技術研究與測試",
          // content:
          //   "簡短的說明，文字簡短的說明文字簡短的說明文字簡短的說明文字字，短的說明文字簡短的說明文字。",
        },
      ],
      isActive: false,
    },
    {
      id: 3,
      time: "2023.10",
      name: "設計與開發",
      title: "設計與開發",
      src: Plan_3,
      contentList: [
        {
          title: "平台及系統設計",
          // content:
          //   "簡短的說明，文字簡短的說明文字簡短的說明文字簡短的說明文字字，短的說明文字簡短的說明文字。",
        },
        {
          title: "開發系統",
          // content:
          //   "簡短的說明，文字簡短的說明文字簡短的說明文字簡短的說明文字字，短的說明文字簡短的說明文字。",
        },
      ],
      isActive: false,
    },
    {
      id: 4,
      time: "2024.04",
      name: "正式上線",
      title: "正式上線",
      src: Plan_4,
      contentList: [
        {
          title: "發行與營運",
          // content:
          //   "簡短的說明，文字簡短的說明文字簡短的說明文字簡短的說明文字字，短的說明文字簡短的說明文字。",
        },
      ],
      isActive: false,
    },
  ]);

  const teamList = [
    {
      id: 1,
      src: Member_1,
      name: "Victor",
      intro: [
        {
          ch: "超過15年的股票以及期貨交易經驗。20年以上的自動化系統工程經驗。",
          en: "Over 15 years of experience in stock and futures trading. Over 20 years of experience in automated system engineering.",
        },
      ],
    },
    {
      id: 2,
      src: Member_2,
      name: "Dennis",
      intro: [
        {
          ch: "超過10年股票、期貨、期權交易經驗。8年以上資產管理經驗。LeadBank 首席財務官",
          en: "Over 10 years of experience in stock, futures, and options trading. More than 8 years of asset management experience. Chief Financial Officer of LeadBank.",
        },
      ],
    },
    {
      id: 3,
      src: Member_3,
      name: "Ray",
      intro: [
        {
          ch: "超過10年股票、期貨、期權交易經驗。8年以上程式交易經驗。中國Tonghai 副總裁",
          en: "Over 10 years of experience in stock, futures, and options trading. More than 8 years of experience in algorithmic trading. Vice President of Tonghai, China.",
        },
      ],
    },
    // {
    //   id: 4,
    //   src: Member_4,
    //   name: "John Smith, CTO",
    //   intro:
    //     "The first new bomber in 30 years cloud const nearly $700m(£569m) each and can carry nuclear and conventional weapons. As expected, specific details of the aircraft reamin shrouded in secrecy",
    // },
    // {
    //   id: 5,
    //   src: Member_5,
    //   name: "David Wu, Analyst",
    //   intro:
    //     "The first new bomber in 30 years cloud const nearly $700m(£569m) each and can carry nuclear and conventional weapons. As expected, specific details of the aircraft reamin shrouded in secrecy",
    // },
    // {
    //   id: 6,
    //   src: Member_6,
    //   name: "Anna Wintor, Analyst",
    //   intro:
    //     "The first new bomber in 30 years cloud const nearly $700m(£569m) each and can carry nuclear and conventional weapons. As expected, specific details of the aircraft reamin shrouded in secrecy",
    // },
  ];

  const [footerList, setFooterList] = useState([
    {
      id: 1,
      isOpen: true,
    },
    {
      id: 2,
      isOpen: true,
    },
  ]);
  const lang = useSelector(selectSystem).language;
  const [languageIsOpen, setLanguageIsOpen] = useState(false);
  const [headerShadow, setHeaderShadow] = useState(false);

  const [progressIsActive, setProgressIsActive] = useState(1);

  const LanguageProps = useSpring({
    bottom: languageIsOpen ? "0%" : "-100%",
    opacity: languageIsOpen ? "1" : "0",
    // zIndex: languageIsOpen ? "1" : "-1",
  });

  const handleSwithLanguage = (e) => {
    e.stopPropagation();
    setLanguageIsOpen(!languageIsOpen);
  };

  const toggleFaq = (id) => {
    setFaqList(
      faqList.map((data) => {
        if (data.id === id) {
          data.isOpen = !data.isOpen;
        } else {
          data.isOpen = false;
        }
        // console.log(data);
        return data;
      })
    );
  };

  const toggleFooterList = (id) => {
    // console.log(id);
    setFooterList(
      footerList.map((data) => {
        if (data.id === id) {
          data.isOpen = !data.isOpen;
        }
        return data;
      })
    );
  };

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setRwd("SM");
    } else {
      setRwd("");
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      let currentScroll = window.scrollY;

      if (currentScroll !== 0) {
        setHamIsOpen(false);
        setHeaderShadow(true);
      } else {
        setHeaderShadow(false);
      }
    };

    const handleResize = () => {
      // console.log(window.innerWidth);

      if (window.innerWidth <= 768) {
        setRwd("SM");
        // console.log(rwd);
      } else {
        setRwd("XL");
        setFooterList(
          footerList.map((data) => {
            return {
              isOpen: true,
              ...data,
            };
          })
        );
      }
      // do magic for resize
    };

    // console.log(footerList);

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    // if (window.innerWidth <= 428) {
    //   setRwd("SM");
    // } else {
    //   setRwd("XL");
    // }

    // console.log(window.innerWidth);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [hamIsOpen, setHamIsOpen] = useState(false);

  const ToggleHamMenu = () => {
    setHamIsOpen(!hamIsOpen);
  };

  const HamburgerMenuProps = useSpring({
    left: hamIsOpen ? "0vw" : "-50vw",
    boxShadow: hamIsOpen
      ? "0px 25px 20px -20px rgba(0, 0, 0, 0.45)"
      : "0px 0px 0px 0px rgba(0, 0, 0, 0)",
  });

  const [props, set] = useSpring(() => ({
    // from: { opacity: 0 },
    // to: { opacity: 1 },
  }));

  useEffect(() => {
    set({
      from: { opacity: "0" },
      to: { opacity: "1" },
      config: { duration: 500 },
    });
  }, [progressIsActive]);

  const [rwd, setRwd] = useState();

  // Form
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const formIsValid = () => {
    if (name.trim() !== "" && email.trim() !== "" && message.trim() !== "") {
      return false;
    } else {
      return true;
    }
  };

  const cta = {
    login: [
      {
        ch: "登入/註冊",
        en: "Login/Register",
      },
    ],
    news: [
      {
        ch: "閱讀更多",
        en: "Read More",
      },
    ],
  };

  const newsSubTitleData = [
    {
      ch: "掌握最新市場動態，了解加密貨幣、區塊鏈技術和經濟趨勢領域的專家見解。",
      en: "Stay updated with expert insights on cryptocurrency, blockchain, and economic trends.",
    },
  ];

  const footerData = [
    {
      ch: "您的理財好夥伴<br>日以繼夜，不眠不休 創造絕佳獲利機會",
      en: "Your financial partner, <br>Working tirelessly around the clock Creating excellent profit opportunities",
    },
  ];

  const handleNewsEllipsis = (value) => {
    return `${value?.substring(0, 150)}...`;
  };

  const dispatch = useDispatch();
  const handleChangeLanguage = () => {
    dispatch(changeLanguage());
  };

  const templateHandler = (data) => {
    if (lang?.toLocaleLowerCase() === "ch") {
      return parse(data.ch);
    } else {
      return parse(data.en);
    }
  };

  return (
    <ThemeProvider theme={Theme}>
      <Wrapper hamIsOpen={hamIsOpen} id="landing">
        <Header headerShadow={headerShadow} data-scroll-header>
          <HeaderContainer>
            <HamburgerMenuList
              hamIsOpen={hamIsOpen}
              onClick={() => ToggleHamMenu()}
            >
              <HamburgerMenuItem hamIsOpen={hamIsOpen} />
              <HamburgerMenuItem hamIsOpen={hamIsOpen} />
              <HamburgerMenuItem hamIsOpen={hamIsOpen} />
            </HamburgerMenuList>
            <LogoWrapper>
              <LogoContainer>
                <img src={Logo} />
              </LogoContainer>
            </LogoWrapper>
            <MenuList style={HamburgerMenuProps}>
              {menuList.map((data) => (
                <MenuItem key={data.anchor}>
                  <ScrollLink to={data.anchor} smooth={true}>
                    {data?.name?.[0]?.[getLanguage()?.toLocaleLowerCase()]}
                  </ScrollLink>
                </MenuItem>
              ))}
            </MenuList>

            <LoginButtonWrapper>
              {/* <Button href="https://www.avairobot.com/AVA/login"> */}
              <ButtonWrapper onClick={() => navigate(routes.login_v2)}>
                <Button>
                  {cta.login[0][getLanguage()?.toLocaleLowerCase()]}
                </Button>
              </ButtonWrapper>
            </LoginButtonWrapper>

            {/* <LoginIcon href="https://www.avairobot.com/AVA/login"> */}
            <LoginIcon onClick={() => navigate(routes.login_v2)}>
              <img src={User} />
            </LoginIcon>
          </HeaderContainer>
        </Header>

        <Hero />

        <NewsSection id="News">
          <NewsTitleWrapper>
            <NewsTitle data-aos="fade-up" data-aos-duration="1000">
              {
                menuList.filter((menu) => menu.anchor === "News")[0].name[0][
                  getLanguage()?.toLocaleLowerCase()
                ]
              }
            </NewsTitle>
            <NewsSubTitle data-aos="fade-up" data-aos-duration="1000">
              {newsSubTitleData[0][getLanguage()?.toLocaleLowerCase()]}
            </NewsSubTitle>
          </NewsTitleWrapper>
          <NewsCardWrapper data-aos="fade-up" data-aos-duration="1000">
            <NewsCardContainer>
              <NewsCardRowWrapper>
                <NewsCard onClick={() => handleOpenNews(1)}>
                  <NewsCardTime>
                    {NewsDateFormat(newsList[0]?.CreateTime)}
                  </NewsCardTime>
                  <NewsCardTitle>{newsList[0]?.Title}</NewsCardTitle>

                  <NewsCardContentContainer>
                    <NewsImgWrapper>
                      <img
                        src={
                          newsList[0]?.ImageUrl
                            ? newsList[0]?.ImageUrl
                            : "https://picsum.photos/1024/1024?random=1"
                        }
                        alt={newsList[0]?.Title}
                      />
                    </NewsImgWrapper>
                    <NewsCardContent>
                      {handleNewsEllipsis(newsList?.[0]?.Content)}
                    </NewsCardContent>
                  </NewsCardContentContainer>

                  <NewsCardCommentWrapper>
                    {newsList[0]?.Comment.map((data, index) => (
                      <NewsCardComment $status={data} key={index}>
                        ＊＊＊＊市場
                      </NewsCardComment>
                    ))}
                  </NewsCardCommentWrapper>
                </NewsCard>
              </NewsCardRowWrapper>

              <NewsCardColumnWrapper>
                {newsList
                  .filter((data) => data.id > 1)
                  .map((item, index) => (
                    <NewsCard
                      key={index}
                      onClick={() => handleOpenNews(item.id)}
                    >
                      <NewsCardTime>
                        {NewsDateFormat(item?.CreateTime)}
                      </NewsCardTime>
                      <NewsCardSubTitle>{item.Title}</NewsCardSubTitle>

                      <NewsCardCommentWrapper>
                        {item.Comment.map((el, index) => (
                          <NewsCardComment $status={el} key={index}>
                            ＊＊＊＊市場
                          </NewsCardComment>
                        ))}
                      </NewsCardCommentWrapper>
                    </NewsCard>
                  ))}
              </NewsCardColumnWrapper>
            </NewsCardContainer>
          </NewsCardWrapper>
          <NewsCardReadMoreWrapper>
            <ButtonWrapper>
              <Button
                type={"hollow"}
                onClick={() =>
                  navigate({
                    pathname: `${routes.login_v2}?redirect=newsdata`,
                  })
                }
              >
                {cta.news[0][getLanguage()?.toLocaleLowerCase()]}
              </Button>
            </ButtonWrapper>
          </NewsCardReadMoreWrapper>
        </NewsSection>
        {/* 
        <IntroSection id="Features">
          <IntroBgWrapper>
            <img src={BgFeatures} />
          </IntroBgWrapper>
          <BgCircleWrapper>
            <img src={BgCircle} />
          </BgCircleWrapper>
          <BgLineWrapper>
            <img src={BgLine} />
          </BgLineWrapper>
          <IntroTitleWrapper data-aos="fade-up" data-aos-duration="1000">
            <IntroTitle>最佳表現</IntroTitle>
            <IntroSubTitle>
              體驗極致的加密貨幣交易機器人，簡單易用。讓你在睡覺時也能賺取利潤。
            </IntroSubTitle>
          </IntroTitleWrapper>
          <IntroCardsWrapper data-aos="fade-up" data-aos-duration="1000">
            {rwd === "SM" ? (
              <Swiper
                spaceBetween={10}
                slidesPerView={1}
                pagination={{ clickable: true }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Pagination]}
              >
                {algoData.map((data, index) => (
                  <SwiperSlide key={index}>
                    <IntroCardContainer
                      key={index}
                      onClick={() => handleOpenAlgo(data.id)}
                    >
                      <IntroCardImg>
                        <img src={data.src}></img>
                      </IntroCardImg>
                      <IntroCardTitle>
                        <IntroCardData>
                          <IntroCardDataTitle>
                            {data.PeriodDays}D 收益率
                          </IntroCardDataTitle>
                          <IntroCardDataNum $minus={data.TotalProfitRate < 0}>
                            {data.TotalProfitRate.toFixed(2)}%
                          </IntroCardDataNum>
                        </IntroCardData>
                        <IntroCardData>
                          <IntroCardDataTitle>標的</IntroCardDataTitle>
                          <IntroCardDataTarget>
                            {data.Target}
                          </IntroCardDataTarget>
                        </IntroCardData>
                      </IntroCardTitle>

                      <IntroCardChart>
                        <AlgoLineChart chartData={data.Data} />
                      </IntroCardChart>
                    </IntroCardContainer>
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <>
                {algoData.map((data, index) => (
                  <IntroCardContainer
                    key={index}
                    onClick={() => handleOpenAlgo(data.id)}
                  >
                    <IntroCardImg>
                      <img src={data.src}></img>
                    </IntroCardImg>
                    <IntroCardTitle>
                      <IntroCardData>
                        <IntroCardDataTitle>
                          {data.PeriodDays}D 收益率
                        </IntroCardDataTitle>
                        <IntroCardDataNum $minus={data.TotalProfitRate < 0}>
                          {data.TotalProfitRate.toFixed(2)}%
                        </IntroCardDataNum>
                      </IntroCardData>
                      <IntroCardData>
                        <IntroCardDataTitle>標的</IntroCardDataTitle>
                        <IntroCardDataTarget>{data.Target}</IntroCardDataTarget>
                      </IntroCardData>
                    </IntroCardTitle>

                    <IntroCardChart>
                      <AlgoLineChart chartData={data.Data} />
                    </IntroCardChart>
                  </IntroCardContainer>
                ))}
              </>
            )}
          </IntroCardsWrapper>
        </IntroSection> */}

        {/* <PvSection id="Home">
          <PvBgWrapper>
            <img src={BgHero} />
          </PvBgWrapper>
          <PvImgWrapper>
            <PvImg src={Pv} />
          </PvImgWrapper>
          <PvContent
            data-aos="fade-left"
            data-aos-delay="300"
            data-aos-duration="1000"
          >
            <PvTitle>智能交易 聰明理財</PvTitle>
            <PvSubTitle>AlgoTrade</PvSubTitle>
            <PvImgMobileWrapper>
              <PvImg src={Pv} />
            </PvImgMobileWrapper>
            <PvDetail>
              使用自動交易機器人掌握加密貨幣市場，時時刻刻為您盯緊局勢，下達專業判斷，無需編碼，設定簡便，註冊後即可體驗使用！
            </PvDetail>
            <PvButtonsWrapper>
              <Button onClick={() => navigate(routes.register_v2)}>
               
                創建帳戶
              </Button>
              <Button type={"hollow"}>
                <a href="#Reason">探索更多</a>
              </Button>
            </PvButtonsWrapper>
          </PvContent>
        </PvSection> */}

        <Reason />

        {/* <ReasonSection id="Reason">
          <ReasonTitleWrapper>
            <ReasonTitle data-aos="fade-up" data-aos-duration="1000">
              為什麼選擇我們的產品
            </ReasonTitle>
          </ReasonTitleWrapper>
          <ReasonContentWrapper>
            <ReasonContentContainer>
              {reasonList.map((data, index) => (
                <ReasonItem
                  key={index}
                  data-aos="flip-left"
                  data-aos-duration="1000"
                >
                  <ReasonItemIcon>
                    <img src={data.src} />
                  </ReasonItemIcon>
                  <ReasonItemTitle>{data.title}</ReasonItemTitle>
                  <ReasonItemContent>{parse(data.content)}</ReasonItemContent>
                </ReasonItem>
              ))}
            </ReasonContentContainer>
          </ReasonContentWrapper>
        </ReasonSection> */}

        <Product />
        <Testimonials windowSize={windowSize} />

        <TeamSection id="About Us">
          <TeamBgWrapper>
            <img src={BgMember} />
          </TeamBgWrapper>
          <TeamTitleWrapper data-aos="fade-up" data-aos-duration="1000">
            <TeamTitle>
              {
                menuList.filter((menu) => menu.anchor === "About Us")[0]
                  .name[0][getLanguage()?.toLocaleLowerCase()]
              }
            </TeamTitle>
          </TeamTitleWrapper>
          <TeamCardsWrapper>
            {rwd === "SM" ? (
              <Swiper
                spaceBetween={10}
                slidesPerView={1}
                pagination={{ clickable: true }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Pagination]}
              >
                {teamList.map((data, index) => (
                  <SwiperSlide key={index}>
                    <TeamCardContainer
                      data-aos="flip-right"
                      data-aos-duration="1000"
                    >
                      <TeamCardImg>
                        <img src={data.src}></img>
                      </TeamCardImg>
                      <TeamCardContentWrapper>
                        <TeamCardName>{data.name}</TeamCardName>
                        <TeamCardIntro>
                          {data.intro[0][getLanguage()?.toLocaleLowerCase()]}
                        </TeamCardIntro>
                      </TeamCardContentWrapper>
                    </TeamCardContainer>
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <>
                {teamList.map((data, index) => (
                  <TeamCardContainer
                    key={index}
                    data-aos="flip-right"
                    data-aos-duration="1000"
                  >
                    <TeamCardImg>
                      <img src={data.src}></img>
                    </TeamCardImg>
                    <TeamCardContentWrapper>
                      <TeamCardName>{data.name}</TeamCardName>
                      <TeamCardIntro>
                        {data.intro[0][getLanguage()?.toLocaleLowerCase()]}
                      </TeamCardIntro>
                    </TeamCardContentWrapper>
                  </TeamCardContainer>
                ))}
              </>
            )}
          </TeamCardsWrapper>
        </TeamSection>

        <FaqSection id="FAQs">
          <FaqTitleWrapper>
            <FaqTitle data-aos="fade-up" data-aos-duration="1000">
              {
                menuList.filter((menu) => menu.anchor === "FAQs")[0].name[0][
                  getLanguage()?.toLocaleLowerCase()
                ]
              }
            </FaqTitle>
          </FaqTitleWrapper>
          <FaqSlidesWrapper data-aos="fade-up" data-aos-duration="1000">
            {faqList.map((data, index) => (
              <FaqSlideContainer key={index} onClick={() => toggleFaq(data.id)}>
                <FaqSlideQuestion>
                  Q{data.id}.{" "}
                  {data.title[0][getLanguage()?.toLocaleLowerCase()]}
                </FaqSlideQuestion>

                <FaqSlideAns isOpen={data.isOpen}>
                  {data.content[0][getLanguage()?.toLocaleLowerCase()]}
                </FaqSlideAns>
                {/* <FaqSlideContainerContent>
                
              </FaqSlideContainerContent> */}
              </FaqSlideContainer>
            ))}
          </FaqSlidesWrapper>
          <FaqButtonWrapper>
            <Button type={"hollow"}>查看更多</Button>
          </FaqButtonWrapper>
        </FaqSection>

        {/* <PlanSection id="Plan">
          <PlanTitleWrapper data-aos="fade-up" data-aos-duration="1000">
            <PlanTitle>產品計畫</PlanTitle>
            <PlanSubTitle>
              發行NFT，建立完善使用分級機制且成為未來發展的後盾，績效創造價值，價值創造更多可能。
            </PlanSubTitle>
          </PlanTitleWrapper>
          <PlanContentWrapper data-aos="fade-up" data-aos-duration="1000">
            <PlanProgressWrapper>
              <PlanProgressContainer>
                <PlanProgressList active={progressIsActive}>
                  {planList.map((plan, index) => (
                    <PlanProgressItem
                      key={index}
                      id={plan.id}
                      active={progressIsActive}
                    >
                      <PlanProgressCircle
                        active={plan.id === progressIsActive}
                      ></PlanProgressCircle>
                      <PlanProgressContentWrapper
                        active={plan.id === progressIsActive}
                        onClick={() => setProgressIsActive(plan.id)}
                      >
                        <PlanProgressTime
                          direction={plan.id % 2 ? "right" : "left"}
                        >
                          {plan.time}
                        </PlanProgressTime>
                        <PlanProgressContent
                          direction={plan.id % 2 ? "right" : "left"}
                        >
                          {plan.name}
                        </PlanProgressContent>
                      </PlanProgressContentWrapper>
                    </PlanProgressItem>
                  ))}
                </PlanProgressList>
              </PlanProgressContainer>
            </PlanProgressWrapper>
            {planList
              .filter((plan) => plan.id === progressIsActive)
              .map((data, index) => (
                <PlanCard key={index} style={props}>
                  <PlanCardImg>
                    <img src={data.src} />
                  </PlanCardImg>

                  <PlanCardContentWrapper>
                    <PlanCardTitle>{data.title}</PlanCardTitle>
                    {data.contentList.map((item, index) => (
                      <PlanCardContentContainer key={index}>
                        <PlanCardSubTitle>{item.title}</PlanCardSubTitle>
                        <PlanCardContent>
                          {item.content}{" "}
                          {item.button && (
                            <PlanCardButtonWrapper>
                              <Button
                                onClick={() => navigate(routes.ch)}
                                type={"hollow"}
                              >
                                查看
                              </Button>
                            </PlanCardButtonWrapper>
                          )}
                        </PlanCardContent>
                      </PlanCardContentContainer>
                    ))}
                  </PlanCardContentWrapper>
                </PlanCard>
              ))}
          </PlanContentWrapper>
        </PlanSection> */}

        <BannerSection>
          <BannerImg></BannerImg>
          <Button onClick={() => navigate(routes.register_v2)}>
            <ButtonWrapper>
              <IconWrapper>
                <img src={Hand} />
              </IconWrapper>
              <BannerButtonText>Start Now</BannerButtonText>
            </ButtonWrapper>
          </Button>
        </BannerSection>

        <ContactSection id="Contact">
          <ContactTitleWrapper data-aos="fade-up" data-aos-duration="1000">
            <ContactTitle>Contact Us</ContactTitle>
            <ContactSubTitle>
              Please fill in the following form. We will contact you at the
              earliest available time.
            </ContactSubTitle>
          </ContactTitleWrapper>
          <ContactFormWrapper data-aos="fade-up" data-aos-duration="1000">
            <ContactDataWrapper>
              <ContactEmail
                placeholder="Your Email"
                onChange={(e) => setEmail(e.target.value)}
              ></ContactEmail>
              <ContactName
                placeholder="Your Name"
                onChange={(e) => setName(e.target.value)}
              ></ContactName>
            </ContactDataWrapper>
            <ContactMessageWrapper>
              <ContactMessage
                placeholder="Message Here"
                onChange={(e) => setMessage(e.target.value)}
              ></ContactMessage>
              <ContactButton disable={formIsValid()}>Enter</ContactButton>
            </ContactMessageWrapper>
          </ContactFormWrapper>
        </ContactSection>

        <Footer>
          <FooterWrapper>
            <FooterListWrapper>
              <FooterList>
                <FooterItemTitleWrapper isOpen={footerList[0].isOpen}>
                  <FooterItemTitle>AVA</FooterItemTitle>
                  <FooterListIcon
                    onClick={() => toggleFooterList(1)}
                    isOpen={footerList[0].isOpen}
                  >
                    <img src={IconArrowGray} />
                  </FooterListIcon>
                </FooterItemTitleWrapper>
                <FooterItemWrapper
                  isOpen={footerList[0].isOpen}
                  direction={"column"}
                >
                  {menuList
                    .filter((el) => el.anchor !== "FAQs")
                    .map((data) => (
                      <FooterItem key={data.anchor}>
                        <ScrollLink to={data.anchor} smooth={true}>
                          {data.name[0][getLanguage()?.toLocaleLowerCase()]}
                        </ScrollLink>
                      </FooterItem>
                    ))}
                </FooterItemWrapper>
              </FooterList>
              <FooterList>
                <FooterItemTitleWrapper isOpen={footerList[1].isOpen}>
                  <FooterItemTitle>Services</FooterItemTitle>
                  <FooterListIcon
                    onClick={() => toggleFooterList(2)}
                    isOpen={footerList[1].isOpen}
                  >
                    <img src={IconArrowGray} />
                  </FooterListIcon>
                </FooterItemTitleWrapper>
                <FooterItemWrapper
                  isOpen={footerList[1].isOpen}
                  direction={"column"}
                >
                  {menuList
                    .filter((el) => el.anchor === "FAQs")
                    .map((data) => (
                      <FooterItem key={data.anchor}>
                        <ScrollLink to={data.anchor} smooth={true}>
                          {data.name[0][getLanguage()?.toLocaleLowerCase()]}
                        </ScrollLink>
                      </FooterItem>
                    ))}
                </FooterItemWrapper>
              </FooterList>
              {/* <FooterList>
                <FooterItemTitleWrapper>
                  <FooterItemTitle>Links</FooterItemTitle>
                </FooterItemTitleWrapper>
                <FooterItemWrapper direction={"rows"} isOpen={true}>
                  <FooterIcon>
                    <img src={IconDiscord} />
                  </FooterIcon>
                  <FooterIcon>
                    <img src={IconTwitter} />
                  </FooterIcon>
                  <FooterIcon>
                    <img src={IconInstagram} />
                  </FooterIcon>
                </FooterItemWrapper>
              </FooterList> */}
            </FooterListWrapper>
            <FooterSloganWrapper>
              <FooterSloganLogo>
                <img src={Logo} />
              </FooterSloganLogo>
              <FooterSlogan>
                <FooterSloganContainer>
                  <span>{templateHandler(footerData[0])}</span>
                </FooterSloganContainer>
              </FooterSlogan>
              <FooterLanguageWrapper onClick={handleChangeLanguage}>
                <FooterLanguageIcon>
                  <img src={IconGlobal} />
                </FooterLanguageIcon>
                <FooterLanguage>
                  {lang?.toLowerCase() === "en" ? "English" : "繁體中文"}
                </FooterLanguage>

                {/* <FooterLanguageIcon isOpen={languageIsOpen}>
                  <img src={IconArrow} />
                </FooterLanguageIcon>
                <FooterLanguageList style={LanguageProps}>
                  <FooterLanguageItem>繁體中文</FooterLanguageItem>
                  <FooterLanguageItem>English</FooterLanguageItem>
                </FooterLanguageList> */}
              </FooterLanguageWrapper>
            </FooterSloganWrapper>
          </FooterWrapper>
        </Footer>

        <CopyRightWrapper>
          <CopyRightContent>© 2023 AVA All Right Reserved</CopyRightContent>
        </CopyRightWrapper>
      </Wrapper>
      {algoModalIsOpen && (
        <AlgoIntroduceModal
          algoData={algoData}
          activeAlgo={activeAlgo}
          setActiveAlgo={setActiveAlgo}
          setAlgoModalIsOpen={setAlgoModalIsOpen}
        />
      )}
      {newsModalIsOpen && (
        <NewsModal
          newsData={newsList}
          activeNews={activeNews}
          setActiveNews={setActiveNews}
          setNewsModalIsOpen={setNewsModalIsOpen}
        />
      )}
    </ThemeProvider>
  );
};

export default TypeB;
