import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import Loading from '../../../v2/components/Loading/Loading';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../Router';
// icons
import Icons from '../../../v2/global/icons';

// images
import Building from '../../../images/building.jpg';
import Hand from '../../../images/handPointing.svg';

// styles
import styled, { css } from "styled-components";
import { RWD_SM, RWD_MD, RWD_LG } from '../../../v2/utils/rwd';

// apis
import { useGetNewsQuery } from '../../../v2/services/getNewsApi';


// ==================== styled components ====================
const Wrapper = styled.main`
    box-sizing: border-box;
    width: 100%;
`;

const HeaderContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:20px 100px;

    ${RWD_SM(css`
        flex-direction: column;
        padding:20px 40px;
    `)};
`;

const HeaderTitle = styled.h1`
    font-size: 2rem;
    color:#fcfcfc;
    letter-spacing: 1.5px;

    ${RWD_SM(css`
        margin:10px 0;
    `)};
`;

const HeaderDate = styled.span`
    font-size: 1rem;
    color:#fcfcfc;
`;

const NoteContentComment = styled.div`
  margin-bottom: 20px;
  padding:20px 100px;

  ${RWD_SM(css`
        padding:20px 40px;
    `)};
`;

const NoteContentTitle = styled.div`
  margin: 10px 0;
  font-weight: 500;
  font-size: clamp(16px, 1.25vw, 24px);
  color: #fcfcfc;
  
`;

const NoteCommentWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  ${RWD_SM(css`
    gap: 10px;
  `)};
`;

const NoteComment = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid;
  border-radius: 20px;
  border-color: ${({ $status }) =>
        $status !== "positive"
            ? $status === "negative"
                ? "#f05d2f"
                : "#00c2cb"
            : "#1cac2a"};
  padding:5px 10px;
`;

const NoteLevelIcon = styled.div`
  display: inline-flex;
  width: clamp(15px, 1.3vw, 25px);
  height: clamp(15px, 1.3vw, 25px);

  svg {
    width: clamp(15px, 1.3vw, 25px);
    height: clamp(15px, 1.3vw, 25px);

    & path {
      fill: ${({ $status }) =>
        $status !== "positive"
            ? $status === "negative"
                ? "#f05d2f"
                : "#00c2cb"
            : "#1cac2a"};
    }
  }
`;

const NoteMarket = styled.div`
  font-size: clamp(14px, 0.9375vw, 18px);
  color: #fcfcfc;
`;

const NoteLevel = styled.div`
  font-size: clamp(14px, 0.9375vw, 18px);
  color: #fcfcfc;
`;

const NoteContentWrapper = styled.div`
  padding:20px 100px;
  ${RWD_SM(css`
        padding:20px 40px;
    `)};
`;

const NewsAndImgContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 10px;

  ${RWD_MD(css`
    margin-bottom: 0px;
  `)};
`;

const NewsImgWrapper = styled.div`
  width: 25%;

  ${RWD_MD(css`
    width: 100%;
  `)};

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`;

const NoteContent = styled.ul`
  color: rgba(255, 255, 255, 0.7);
  padding:40px 0;
  list-style-type: circle;
  list-style-position: inside;
  font-size: min(max(0.9375vw, 15px), 18px);
  text-align: justify;
  flex: 1;
  letter-spacing: 1.5px;
`;

const NoteContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const NoteContentReason = styled.div``;

const NoteReason = styled.div`
  font-size: min(max(0.9375vw, 15px), 18px);
  color: rgba(255, 255, 255, 0.7);
  letter-spacing: 1.5px;
  padding:40px 0;
`;

const BannerSection = styled.div`
  height: 15.625vw;
  min-height: 300px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  /* border: 1px solid salmon; */
  overflow: hidden;

  ${RWD_MD(css`
    height: 55.55vw;
  `)};

  /* background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: url(${Building}); */

  /* ${RWD_LG(css`
    height: 20vh;
  `)}; */
`;

const BannerImg = styled.div`
  position: absolute;
  background-color: #fff;
  height: 200%;
  width: 100%;
  left: 0;
  top: 0;
  display: block;

  &::before {
    content: "";
    background-image: url(${Building});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.6;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  ${RWD_MD(css`
    padding: 13px 40px;
  `)};
`;

const IconWrapper = styled.div`
  color: ${(props) => props.theme.colors.White};
  display: flex;
  align-items: center;
  justify-content: center;
  width: clamp(32px, 2.2291667vw, 44px);

  ${RWD_MD(css`
    /* width: 8.88vw; */
  `)};
`;

const BannerButtonText = styled.div`
  color: ${(props) => props.theme.colors.White};
  font-size: clamp(18px, 1.145vw, 22px);
  position: relative;
  display: inline-block;
  font-weight: 500;
`;

const Button = styled.button`
  border-radius: 60px;
  padding: 0.78125vw 2.9166vw;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  font-size: clamp(18px, 1.145vw, 22px);

  border: ${(props) =>
        props.type === "hollow" ? `1px solid ${props.theme.colors.White}` : ""};
  background: ${(props) =>
        props.type === "hollow"
            ? "transparent"
            : "linear-gradient(to bottom, #2F80ED 0%, #5620F0 100%)"};
  color: ${(props) => props.theme.colors.White};
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 2;
  text-decoration: none;

  ${RWD_MD(css`
    /* font-size: 4.166vw; */
    padding: 13px 40px;
  `)};

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 30px;
    z-index: -2;
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: ${(props) => (props.type === "hollow" ? "100%" : "0%")};
    height: ${(props) => (props.type === "hollow" ? "0" : "100%")};
    background: ${(props) =>
        props.type === "hollow"
            ? "white"
            : "linear-gradient(to right, #6a11cb 0%, #2575fc 100%)"};
    transition: all 0.3s;
    border-radius: 30px;
    z-index: -1;
  }

  &:hover {
    color: ${(props) =>
        props.type === "hollow"
            ? props.theme.colors.DarkBlue
            : props.theme.colors.White};
    border: ${(props) =>
        props.type === "hollow" ? "1px solid transparent" : ""};

    &:before {
      width: 100%;
      height: 100%;
    }
  }
`;


const parseComments = (commentStr) => {
    return commentStr.split("，").map((item) => {
        const [marketName, type] = item.split("：");
        let commentType;

        switch (type) {
            case "正面":
                commentType = "positive";
                break;
            case "中性":
                commentType = "neutral";
                break;
            case "負面":
                commentType = "negative";
                break;
            default:
                commentType = "neutral"; // default case
        }

        return {
            MarketName: marketName,
            Type: commentType,
        };
    });
};

const NewsContent = () => {
    const [newsDetails, setNewsDetails] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate();

    const {
        data: newsData,
        isSuccess: newsDataIsSuccess,
        isLoading: newsDataIsLoading,
        isError: newsDataIsError,
        error: newsDataError,
        isFetching: newsDataIsFetching,
    } = useGetNewsQuery(id);

    useEffect(() => {
        if (newsDataIsSuccess && newsData?.BlockChainNews) {
            const parsedComments = parseComments(newsData.BlockChainNews.Comment);

            setNewsDetails({
                ...newsData.BlockChainNews,
                ParsedComments: parsedComments,
            });
        }
    }, [newsDataIsSuccess, newsData]);

    if (newsDataIsLoading) return <Loading />;
    if (newsDataIsError) return <div>Error loading news</div>;

    return (
        newsDetails && (
            <>
                {/* Helmet for dynamic meta tags */}
                <Helmet>
                    <title>{newsDetails.Title}</title>
                    <meta property="og:title" content={newsDetails.Title} />
                    <meta property="og:description" content={newsDetails.Content.slice(0, 100)} />
                    <meta property="og:image" content={newsDetails.ImageUrl || "https://picsum.photos/1024/1024?random=1"} />
                    <meta property="og:url" content={`https://your-website.com/news/${id}`} />
                    <meta property="og:type" content="article" />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={newsDetails.Title} />
                    <meta name="twitter:description" content={newsDetails.Content.slice(0, 100)} />
                    <meta name="twitter:image" content={newsDetails.ImageUrl || "https://picsum.photos/1024/1024?random=1"} />
                </Helmet>

                {/* Content rendering */}
                <Wrapper>
                    {newsDataIsLoading && <Loading />}
                    {/* 新聞標題及日期 */}
                    <HeaderContent>
                        <HeaderTitle>{newsDetails.Title}</HeaderTitle>
                        <HeaderDate>{new Date(newsDetails.CreateTime).toLocaleDateString()}</HeaderDate>
                    </HeaderContent>

                    {/* 分析評級 */}
                    <NoteContentComment>
                        <NoteContentTitle>分析評級</NoteContentTitle>
                        <NoteCommentWrapper>
                            {newsDetails.ParsedComments.map((comment, index) => (
                                <NoteComment $status={comment.Type} key={index}>
                                    <NoteLevelIcon $status={comment.Type}>
                                        {(() => {
                                            switch (comment.Type) {
                                                case "positive":
                                                    return <Icons.Positive />;
                                                case "neutral":
                                                    return <Icons.Neutral />;
                                                case "negative":
                                                    return <Icons.Negative />;
                                                default:
                                                    return null;
                                            }
                                        })()}
                                    </NoteLevelIcon>
                                    <NoteMarket>{comment.MarketName}</NoteMarket>
                                    <NoteLevel $status={comment.Type}>
                                        {(() => {
                                            switch (comment.Type) {
                                                case "positive":
                                                    return "良好";
                                                case "neutral":
                                                    return "中性";
                                                case "negative":
                                                    return "負面";
                                            }
                                        })()}
                                    </NoteLevel>
                                </NoteComment>
                            ))}
                        </NoteCommentWrapper>
                    </NoteContentComment>

                    {/* 新聞內容區塊 */}
                    <NoteContentWrapper>
                        <NewsAndImgContainer>
                            {newsDetails.ImageUrl ? (
                                <NewsImgWrapper>
                                    <img src={newsDetails.ImageUrl} alt={newsDetails.Title} />
                                </NewsImgWrapper>
                            ) : (
                                <NewsImgWrapper>
                                    <img
                                        src={"https://picsum.photos/1024/1024?random=1"}
                                        alt={newsDetails.Title}
                                    />
                                </NewsImgWrapper>
                            )}
                            <NoteContent>{newsDetails.Content}</NoteContent>
                        </NewsAndImgContainer>

                        <NoteContentContainer>
                            <NoteContentReason>
                                <NoteContentTitle>市場短評</NoteContentTitle>
                                <NoteReason>{newsDetails.CommentReason}</NoteReason>
                            </NoteContentReason>
                        </NoteContentContainer>

                    </NoteContentWrapper>

                    <BannerSection>
                        <BannerImg></BannerImg>
                        <Button onClick={() => navigate(routes.register_v2)}>
                            <ButtonWrapper>
                                <IconWrapper>
                                    <img src={Hand} />
                                </IconWrapper>
                                <BannerButtonText>Read More</BannerButtonText>
                            </ButtonWrapper>
                        </Button>
                    </BannerSection>

                </Wrapper>
            </>
        )
    );
};

export default NewsContent