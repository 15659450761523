// Joyride
import Joyride, {
  ACTIONS,
  EVENTS,
  STATUS,
  BeaconRenderProps,
} from "react-joyride";
import styled, { keyframes } from "styled-components";
import Icons from "./icons";
import { useNavigate } from "react-router-dom";

import { WarningMySwal, SuccessMySwal, ErrorMySwal } from "./MySwal";
import { useUpdateWatchingTutorialMutation } from "../services/accountApi";
import { useEffect } from "react";
import { getLanguage } from "../services/common";
import { LangString } from "../utils";
import Lang from "./Lang";

const TooltipBody = styled.div`
  background: rgba(11, 11, 11, 0.8);
  border: 2px solid #fcfcfc;
  padding: 20px 30px;
  width: 25vw;
  border-radius: 10px;
  position: relative;
  border-style: dashed;
  width: clamp(300px, 25vw, 400px);
`;

const TooltipContent = styled.div`
  /* border: 1px solid; */
  /* color: #22254a; */
  color: #fcfcfc;
`;

const TooltipFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
`;

const TooltipLink = styled.div`
  padding: 5px 0;
  color: #0aecd1;
  cursor: pointer;
`;

const FormattedMessage = styled.div``;

const Button = styled.button`
  padding: 5px 0px;
  display: flex;
  color: #fcfcfc;

  &:first-child {
    color: rgba(255, 255, 255, 0.5);
  }

  &:hover {
    color: #0aecd1;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 15px;
  height: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 15px;
    height: 15px;

    & path {
      stroke: #fcfcfc;
    }
  }
`;

const TooltipProgress = styled.div`
  padding: 0 10px;
`;

const JoyrideComponent = ({
  joyrideState,
  run,
  joyrideType,
  setJoyrideIsRun,
}) => {
  const navigate = useNavigate();

  const [_updateWatchingTutorial, { data, isSuccess, isError, error }] =
    useUpdateWatchingTutorialMutation();

  useEffect(() => {
    if (isSuccess) {
      // 多國語言
      // const title = LangString("alert_update_success", "Update successful");
      const confirmText = LangString("confirm", "Confirm");
      const titleText = LangString("tutorial_complete", "Tutorial complete!");
      SuccessMySwal({
        title: titleText,
        showCancelButton: false,
        confirmButtonText: confirmText,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(`/v2/${joyrideType}`);
        }
      });
    }
    if (isError) {
      ErrorMySwal({
        title:
          error?.data?.ResultLanguages[getLanguage()] || error?.data?.Message,
      });
    }
  }, [data, isError]);

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // if (joyrideState[index]?.callbackAction) {
      //   joyrideState[index].function();
      // }
    } else if ([STATUS.FINISHED].includes(status)) {
      if (joyrideState[index].finished) {
        _updateWatchingTutorial({
          body: {
            TutorialKey: joyrideType.replace(
              /^./,
              joyrideType[0].toUpperCase()
            ),
          },
        });
      } else if (joyrideState[index]?.callbackAction) {
        joyrideState[index].function();
      }
    } else if ([STATUS.SKIPPED].includes(status)) {
      _updateWatchingTutorial({
        body: {
          TutorialKey: joyrideType.replace(/^./, joyrideType[0].toUpperCase()),
        },
      });

      // WarningMySwal({
      //   title: "確定退出新手引導教學",
      //   showCancelButton: true,
      //   cancelButtonText: "cancel",
      //   confirmButtonText: "confirm",
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     console.log(joyrideType.replace(/^./, joyrideType[0].toUpperCase()));

      //   } else {
      //     console.log("退出");
      //     setJoyrideIsRun(true);
      //   }
      // });

      // _updateWatchingTutorial({
      //   body: {
      //     TutorialKey: joyrideType.replace(/^./, joyrideType[0].toUpperCase()),
      //   },
      // });
    }

    // console.groupCollapsed(type);
    // console.log(data);
    // console.groupEnd();
  };

  const Tooltip = ({
    continuous,
    index,
    step,
    backProps,
    skipProps,
    closeProps,
    primaryProps,
    tooltipProps,
  }) => (
    <TooltipBody {...tooltipProps}>
      {continuous && (
        <CloseButton {...closeProps}>
          <Icons.Close />
          <FormattedMessage id="close" />
        </CloseButton>
      )}
      {step.title && <TooltipTitle>{step.title}</TooltipTitle>}
      <TooltipContent>{step.content}</TooltipContent>
      {step.haveLink && (
        <TooltipLink onClick={() => navigate(`/v2/${step.link}`)}>
          {step.linkName}
        </TooltipLink>
      )}

      <TooltipFooter>
        <Button {...skipProps}>
          <Lang datakey="skip_tutorial" defaultText="Skip tutorial" />
          <FormattedMessage id="close" />
        </Button>
        {/* {index > 0 && (
          <Button {...backProps}>
            Back
            <FormattedMessage id="back" />
          </Button>
        )} */}
        {continuous && (
          <Button {...primaryProps}>
            {joyrideState.length > 1 ? (
              <Lang datakey="next" defaultText="Next" />
            ) : (
              <Lang datakey="ok" defaultText="OK" />
            )}
            {joyrideState.length > 1 && (
              <TooltipProgress>
                {index + 1} / {joyrideState.length}
              </TooltipProgress>
            )}
            {/* <FormattedMessage id="next" /> */}
          </Button>
        )}
      </TooltipFooter>
    </TooltipBody>
  );

  return (
    <Joyride
      run={run}
      steps={joyrideState}
      callback={handleJoyrideCallback}
      // stepIndex={currentTooltip}
      // styles={joyrideStyles}
      className="react-joyride__overlay__"
      continuous
      showProgress
      showSkipButton
      tooltipComponent={Tooltip}
      styles={{
        options: {
          arrowColor: "transparent",
          // overlayColor: "rgba(0, 0, 0, 0.5)",
          primaryColor: "#000",
          zIndex: 1100,
          backgroundColor: "#fff",
        },
      }}
    />
  );
};

export default JoyrideComponent;
