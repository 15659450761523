import { useState } from 'react';
import { verifications } from '../../../utils/verifications';

// --- data example ---
// percent: 42.5,
// color: 'rgba(139, 220, 255, 1)',

const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
	var angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

	return {
		x: centerX + (radius * Math.cos(angleInRadians)),
		y: centerY + (radius * Math.sin(angleInRadians))
	};
};

const getDonutChart = (size, innerSize, currencyDetail, handleShowTag, setIsShow) => {
  let totalPercent = 0;

  // temp fixed if only has one is 100% won't show donut
  if (currencyDetail.length === 1) currencyDetail.push({
    ...currencyDetail[0],
    percent: 99.99999
  });

  return currencyDetail.map(({ percent, color }, index) => {
    // 100 percent to 360 circle percent
    percent = percent * 360 / 100;

    const CENTER_X = size / 2,
          CENTER_Y = size / 2,
          RADIUS = size / 2;

    const cx = CENTER_X,
          cy = CENTER_Y,
          radius = RADIUS,
          start_angle = totalPercent,
          end_angle = totalPercent + percent,
          thickness = innerSize;
    
    totalPercent += percent;
    
    // outer path
    const start = polarToCartesian(cx, cy, radius, end_angle),
          end = polarToCartesian(cx, cy, radius, start_angle),
          largeArcFlag = end_angle - start_angle <= 180 ? "0" : "1";
    // inner path
    const cutout_radius = radius - thickness,
          start2 = polarToCartesian(cx, cy, cutout_radius, end_angle),
          end2 = polarToCartesian(cx, cy, cutout_radius, start_angle);
  
    const d = [
      "M", start.x, start.y,
      "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y,
      "L", cx, cy,
      "Z",
      "M", start2.x, start2.y,
      "A", cutout_radius, cutout_radius, 0, largeArcFlag, 0, end2.x, end2.y,
      "L", cx, cy,
      "Z"
    ].join(" ");

    return (
      <path
        key={index}
        fill={color}
        d={d}
        stroke="none"
        fillRule="evenodd"
        className='relative hover:saturate-200 hover:brightness-95'
        onMouseMove={e => handleShowTag(e, index)}
        onMouseEnter={() => setIsShow(true)}
        onMouseLeave={() => setIsShow(false)}
      />
    )
  })
};

const DonutChart = ({ size, innerSize, data }) => {
  const handleShowTag = (e, index) => {
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top - 20;
    setTagLeft(x);
    setTagTop(y);
    setAsset(data[index].currency);
    setVolume(data[index].value);
  }

  const [isShow, setIsShow] = useState(false);
  const [tagLeft, setTagLeft] = useState(0);
  const [tagTop, setTagTop] = useState(0);
  const [asset, setAsset] = useState(0);
  const [volume, setVolume] = useState('');

  return (
    <>
      <svg viewBox={`0 0 ${size} ${size}`} className={`w-[${size}px] h-[${size}px] absolute abs-center`}>
        {getDonutChart(size, innerSize, data, handleShowTag, setIsShow)}
      </svg>
      {
        isShow
        &&
        <div
          className='
            absolute z-10 p-2 text-[0.5rem] 
            bg-[#ccc]/50 dark:bg-[#666]/75 rounded pointer-events-none whitespace-nowrap
          '
          style={{ left: tagLeft, top: tagTop }}
        >
          Asset: {asset}<br />
          Volume: $ {volume.toFixed(2).replace(verifications.currency, ',')}
        </div>
      }
    </>
  )
};

export default DonutChart
