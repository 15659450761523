import styled from "styled-components";
import { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";
import { useState, useEffect } from "react";
import Icons from "../../global/icons";
import { dateTimeFormat } from "../../utils";

import Lang from "../../global/Lang";

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  color: #151944;
`;

const Container = styled.div`
  background-color: #fcfcfc;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 40px;
  width: 80vw;
  max-width: 850px;
  border-radius: 8px;
  position: relative;

  ${RWD_LG(css`
    width: 80%;
  `)};

  ${RWD_MD(css`
    width: 90%;
    /* padding: 20px 40px; */
  `)};

  ${RWD_SM(css`
    padding: 20px;
  `)};
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
`;

const Title = styled.div`
  color: #151944;
  font-size: min(max(1.5625vw, 28px), 30px);
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  ${RWD_MD(css`
    font-size: min(max(1.0416vw, 18px), 20px);
  `)};
`;

const TooltipWrapper = styled.div`
  position: relative;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 3px;
  cursor: help;

  &:hover span {
    visibility: visible;
  }
`;

const TooltipContainer = styled.div`
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #c0c0c0;
  padding-top: 1px;
  display: flex;

  svg {
    width: 20px;
    height: 20px;
  }

  & path {
    stroke: #c0c0c0;
  }
`;

const TooltipText = styled.span`
  visibility: hidden;
  background-color: #54587b;
  color: #fff;
  text-align: left;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: min(max(0.833vw, 14px), 16px);
  white-space: wrap;
  position: absolute;
  left: calc(1.3vw + 20px);

  z-index: 1;
  width: 30vw;

  ${RWD_SM(css`
    top: calc(1.3vw + 20px);
    left: auto;
    width: 55vw;
  `)};
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: 1px solid #151944;

  ${RWD_MD(css`
    overflow-x: auto;
  `)};
`;

const TableContainer = styled.div`
  overflow: auto;
  max-height: 60vh;
  height: 100%;

  ${RWD_MD(css`
    max-height: 50vh;
    min-width: 800px;
  `)};
`;

const TableHeaderWrapper = styled.div`
  padding: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #151944;
  color: #fcfcfc;
  font-size: min(max(0.833vw, 14px), 16px);
  position: sticky;

  ${RWD_MD(css`
    padding: 5px;
    min-width: 800px;
  `)};
`;

const Th = styled.div`
  font-weight: 400;
  text-align: center;
  width: 100%;
`;

const TableItemWrapper = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => (props.index % 2 ? "" : "#D9D9D9")};
`;

const TableItem = styled.div`
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const TableItemText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;

  font-size: min(max(0.833vw, 14px), 16px);
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${(props) => (props.status ? "scaleY(-1)" : "scaleY(1)")};
  cursor: pointer;
  transition: all 0.3s;
`;

const TableDataWrapper = styled.div`
  /* border: 1px solid blue; */
`;

const DetailWrapper = styled.div`
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  padding: 1.5vw;
  border-top: 1px solid #3a3d5a;
  border-bottom: 1px solid #3a3d5a;
  overflow-x: auto;

  ${RWD_MD(css`
    padding: 10px;
  `)};
`;

const DetailTableHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  background: #54587b;
  font-size: min(max(0.833vw, 14px), 16px);

  ${RWD_MD(css`
    min-width: 600px;
  `)};
  /* color: #fcfcfc; */
`;

const DetailTableHeader = styled.div`
  width: 100%;
  text-align: center;
  color: #fcfcfc;
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: min(max(0.833vw, 14px), 16px);

  ${RWD_MD(css`
    min-width: 600px;
  `)};
`;

const DetailItemWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const DeatilItem = styled.div`
  width: 100%;
  padding: 5px;
  text-align: center;
`;

const CloseIcon = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
`;

const Nodata = styled.div`
  text-align: center;
  /* font-weight: bold; */
  font-size: 20px;
  padding: 20px;
`;

const IsReturnIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  & path {
    fill: ${(props) => (props.status ? "" : "#b3b3b3")};
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

const MiningReturnHistory = ({ setHistoryModalIsOpen, data }) => {
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    if (!data) return;
    setTableData(() =>
      data.map((item, index) => ({ ...item, isOpen: false, id: index + 1 }))
    );
  }, [data]);
  const tableHeader = [
    {
      id: 1,
      value: "IsReturn",
      width: "10%",
      styled: "center",
    },
    {
      id: 2,
      value: "Address",
      width: "22%",
    },
    {
      id: 3,
      value: "Asset",
      width: "15%",
    },
    {
      id: 4,
      value: "TotalReturnAmount",
      width: "23%",
    },
    {
      id: 5,
      value: "CreateTime",
      width: "20%",
    },
    {
      id: 6,
      value: "Action",
      width: "10%",
    },
  ];

  const detailHeader = [
    {
      id: 1,
      value: "GroupId",
      width: "15%",
    },
    {
      id: 2,
      value: "GroupName",
      width: "20%",
    },
    {
      id: 3,
      value: "Period",
      width: "15%",
    },
    {
      id: 4,
      value: "ReturnAmount",
      width: "20%",
    },
    {
      id: 5,
      value: "ReturnType",
      width: "15%",
    },
    {
      id: 6,
      value: "EndTime",
      width: "15%",
    },
  ];

  const handleDataOpen = (id) => {
    setTableData(
      tableData.filter((data) => {
        data.isOpen = data.id === id ? !data.isOpen : false;
        return data;
      })
    );
  };
  const handleCancel = () => {
    setHistoryModalIsOpen(false);
  };

  return (
    <Wrapper>
      <Container>
        <TitleWrapper>
          <Title>
            <Lang datakey="return_history" defaultText="Return History" />
          </Title>
          <TooltipWrapper>
            <TooltipContainer>
              <Icons.Tooltip />
            </TooltipContainer>
            <TooltipText>
              <Lang
                datakey="return_history"
                defaultText="For each transfer, this service requires a minimum return of 100 USDT (or 0.01 BTC). If the amount does not reach this threshold, it will be temporarily managed by the AVA platform. Once the user's cumulative transfer amount exceeds 100 USDT (or 0.01 BTC), the transfer will be automatically processed"
                toolTip={true}
              />
            </TooltipText>
          </TooltipWrapper>
        </TitleWrapper>

        <CloseIcon onClick={handleCancel}>
          <Icons.Close />
        </CloseIcon>
        {tableData.length === 0 && (
          <Nodata>
            <Lang datakey="nodata" defaultText="No data available" />
          </Nodata>
        )}
        {tableData.length > 0 && (
          <TableWrapper>
            <TableHeaderWrapper>
              {tableHeader.map((item) => (
                <Th
                  key={item.id}
                  style={{
                    width: `${item.width}`,
                  }}
                >
                  {item.value}
                </Th>
              ))}
            </TableHeaderWrapper>
            <TableContainer>
              {tableData.map((item, index) => (
                <TableDataWrapper>
                  <TableItemWrapper key={item.id} index={index + 1}>
                    {tableHeader.map((el) => (
                      <TableItem
                        key={el.id}
                        style={{
                          width: `${el.width}`,
                          justifyContent: `${el.styled}`,
                        }}
                        onClick={() => handleDataOpen(item.id)}
                      >
                        {(() => {
                          switch (el.value) {
                            case "IsReturn":
                              return (
                                <IsReturnIconWrapper status={item[el.value]}>
                                  <Icons.Success />
                                </IsReturnIconWrapper>
                              );
                            case "CreateTime":
                              return (
                                <TableItemText>
                                  {item[el.value]
                                    ? dateTimeFormat(item[el.value])
                                    : ""}
                                </TableItemText>
                              );

                            case "Action":
                              return (
                                <IconWrapper
                                // onClick={() => handleDataOpen(item.id)}
                                >
                                  <Icons.Arrow />
                                </IconWrapper>
                              );

                            default:
                              return (
                                <TableItemText>{item[el.value]}</TableItemText>
                              );
                          }
                        })()}
                      </TableItem>
                    ))}
                  </TableItemWrapper>
                  {item.isOpen && (
                    <DetailWrapper>
                      <DetailTableHeaderWrapper>
                        {detailHeader.map((item) => (
                          <DetailTableHeader
                            style={{
                              width: `${item.width}`,
                            }}
                          >
                            {item.value}
                          </DetailTableHeader>
                        ))}
                      </DetailTableHeaderWrapper>
                      <DetailContainer>
                        {item?.AhftGroupInfo.map((data) => (
                          <DetailItemWrapper>
                            {detailHeader.map((el) => (
                              <DeatilItem
                                style={{
                                  width: `${el.width}`,
                                }}
                              >
                                {(() => {
                                  switch (el.value) {
                                    case "CreateOrderTime":
                                      return dateTimeFormat(data[el.value]);

                                    case "ReturnType":
                                      switch (data[el.value]) {
                                        case "no":
                                          return "CP";
                                        case "all":
                                          return "WP";
                                        case "interest":
                                          return "WMT";
                                        case "avapoint":
                                          return "MAP";
                                      }
                                    default:
                                      return data[el.value];
                                  }
                                })()}
                              </DeatilItem>
                            ))}
                          </DetailItemWrapper>
                        ))}
                      </DetailContainer>
                    </DetailWrapper>
                  )}
                </TableDataWrapper>
              ))}
            </TableContainer>
          </TableWrapper>
        )}
      </Container>
    </Wrapper>
  );
};

export default MiningReturnHistory;
