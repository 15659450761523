import styled, { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";

import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import Algo from "./components/AvaData/Algo";
import News from "./components/AvaData/News";
import TrendFollowing from "./components/AvaData/TrendFollowing";
import Announcement from "./components/AvaData/Announcement";

import SentimentAnalysis from "./components/MarketData/SentimentAnalysis";
import TrendKeyWords from "./components/MarketData/TrendKeyWords";
import MiningData from "./components/PersonalData/MiningData";

import { useNavigate } from "react-router-dom";
import { routes } from "../../../components/Router";
import Lang from "../../global/Lang";
import { API_DownloadPersonalData } from "../../../utils/api";

const Wrapper = styled.div`
  height: 100%;
  padding-bottom: 100px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

const Title = styled.div`
  font-size: min(max(1.25vw, 22px), 24px);
  color: #fcfcfc;
  padding-right: 10px;
  text-transform: capitalize;

  ${RWD_MD(css`
    font-size: min(max(1.145vw, 20px), 22px);
  `)};
`;

const DownloadButton = styled.div`
  border-radius: 60px;
  background: linear-gradient(to bottom, #2f80ed 0%, #5620f0 100%);
  cursor: pointer;
  text-align: center;
  color: #fcfcfc;
  padding: 0.52vw 1.3vw;
`;

const TabWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  /* overflow-x: auto; */
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;

  ${RWD_SM(css`
    width: 33%;
  `)};
`;

const TabItem = styled.div`
  min-width: 150px;
  background-color: ${(props) => (props.activeTab ? "#22254a" : "")};
  text-align: center;
  padding: 8px 0px;
  border-radius: 8px 8px 0 0;
  color: ${(props) => (props.activeTab ? "#fcfcfc" : "#54587b")};
  cursor: pointer;
  font-size: min(max(0.833vw, 14px), 16px);
  text-transform: capitalize;
  padding: 0.78125vw 1.3vw;
  width: 100%;
  height: 100%;

  ${RWD_SM(css`
    min-width: auto;
    padding: 10px 1.3vw;
    /* margin-right: 5px; */
  `)};
`;

const ContentWrapper = styled.div`
  /* background-color: #22254a; */
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentContainer = styled.div`
  min-height: 55vh;
  width: 100%;

  /* background-color: #22254a; */
`;

const ContentTabWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  /* border-bottom: 1px solid rgba(255, 255, 255, 0.15); */
  align-items: center;
  background-color: #22254a;
  padding-top: 10px;
`;
const ContentTabContainer = styled.div`
  display: flex;
  width: 95%;

  ${RWD_SM(css`
    width: 100%;
    align-items: flex-end;
  `)};
`;

const ContenTabItem = styled.div`
  min-width: 150px;
  border-bottom: ${(props) =>
    props.activeTab ? "2px solid #0AECD1" : "2px solid transparent"};
  text-align: center;
  padding: 8px 0px;
  border-radius: 8px 8px 0 0;
  color: ${(props) => (props.activeTab ? "#fcfcfc" : "#54587b")};
  cursor: pointer;
  font-size: min(max(0.833vw, 14px), 16px);
  text-transform: capitalize;

  ${RWD_SM(css`
    width: 33%;
    min-width: auto;
  `)};
`;

const DataCenter = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeMainTab, setActiveMainTab] = useState(1);
  const [activeContentTab, setActiveContentTab] = useState(1);
  const [tabList, setTabList] = useState([
    {
      name: {
        key: "PersonalData",
        value: (
          <Lang datakey="datacenter_personaldata" defaultText="PersonalData" />
        ),
      },
      id: 1,
      content: [
        {
          name: {
            key: "MiningData",
            value: (
              <Lang datakey="datacenter_miningdata" defaultText="MiningData" />
            ),
          },
          id: 1,
        },
        {
          name: {
            key: "AlgoData",
            value: (
              <Lang datakey="datacenter_algodata" defaultText="AlgoData" />
            ),
          },
          id: 2,
        },
      ],
    },
    {
      name: {
        key: "AvaData",
        value: <Lang datakey="datacenter_avadata" defaultText="AvaData" />,
      },
      id: 2,
      content: [
        {
          name: {
            key: "Announcement",
            value: <Lang datakey="announcement" defaultText="Announcement" />,
          },
          id: 1,
        },
        {
          name: {
            key: "Trend Following",
            value: (
              <Lang datakey="trend_following" defaultText="Trend Following" />
            ),
          },
          id: 2,
        },
      ],
    },
    {
      name: {
        key: "NewsData",
        value: <Lang datakey="datacenter_newsdata" defaultText="NewsData" />,
      },
      id: 3,
      content: [
        {
          name: {
            key: "News",
            value: <Lang datakey="news" defaultText="News" />,
          },
          id: 1,
        },
      ],
    },

    // {
    //   name: {
    //     key: "MarketData",
    //     value: (
    //       <Lang datakey="datacenter_marketdata" defaultText="MarketData" />
    //     ),
    //   },
    //   id: 4,
    //   content: [
    //     {
    //       name: {
    //         key: "Sentiment Analysis",
    //         value: (
    //           <Lang
    //             datakey="sentiment_analysis"
    //             defaultText="Sentiment Analysis"
    //           />
    //         ),
    //       },
    //       id: 1,
    //     },
    //     {
    //       name: {
    //         key: "Trend Key Words",
    //         value: (
    //           <Lang datakey="trend_key_words" defaultText="Trend Key Words" />
    //         ),
    //       },
    //       id: 2,
    //     },
    //   ],
    // },
  ]);

  const { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);

    const redirectTab = query.get("redirect") || query.get("tab");

    if (redirectTab) {
      switch (redirectTab) {
        case "newsdata":
        case "avadata":
        case "personaldata":
          // case "marketdata":
          setActiveMainTab(
            tabList.filter(
              (tab) => tab?.name?.key?.toLowerCase() === redirectTab
            )?.[0]?.id
          );
          break;
        // 暫時先都導頁到dataCenter之後再導頁
        case "algo":
          navigate(routes.algo_v2, { replace: true });
          break;

        default:
          break;
      }
    }
  }, [search]);

  const handleMainTab = (id) => {
    setActiveMainTab(id);
    setActiveContentTab(1);
    // 清除url query
    searchParams.delete("redirect");
    setSearchParams(searchParams);
  };

  return (
    <Wrapper>
      <Container>
        <TitleWrapper>
          <Title>
            <Lang datakey="menu_datacenter" defaultText="DataCenter" />
          </Title>
          {activeMainTab === 1 && activeContentTab === 1 && (
            <DownloadButton onClick={API_DownloadPersonalData}>
              <Lang
                datakey="datacenter_personaldata_download"
                defaultText="Download"
              />
            </DownloadButton>
          )}
        </TitleWrapper>

        <TabWrapper className="some-element2">
          {tabList.map((data) => (
            <TabContainer key={data.id}>
              <TabItem
                activeTab={data.id === activeMainTab}
                onClick={() => handleMainTab(data.id)}
              >
                {data.name.value}
              </TabItem>
            </TabContainer>
          ))}
        </TabWrapper>

        <ContentWrapper>
          <ContentTabWrapper>
            <ContentTabContainer>
              {tabList
                .filter((data) => data.id === activeMainTab)[0]
                .content?.map((item, index) => (
                  <ContenTabItem
                    key={index}
                    activeTab={item.id === activeContentTab}
                    onClick={() => setActiveContentTab(item.id)}
                  >
                    {item.name.value}
                  </ContenTabItem>
                ))}
            </ContentTabContainer>
          </ContentTabWrapper>
          <ContentContainer>
            {(() => {
              switch (activeMainTab) {
                case 1:
                  switch (activeContentTab) {
                    case 1:
                      return <MiningData />;
                    case 2:
                      return <Algo />;
                  }
                case 2:
                  switch (activeContentTab) {
                    case 1:
                      return <Announcement />;

                    case 2:
                      return <TrendFollowing />;
                  }
                case 3:
                  switch (activeContentTab) {
                    case 1:
                      return <News />;
                  }
                // case 4:
                //   switch (activeContentTab) {
                //     case 1:
                //       return <SentimentAnalysis />;
                //     case 2:
                //       return <TrendKeyWords />;
                //     default:
                //       return;
                //   }
                default:
                  return null;
              }
            })()}
          </ContentContainer>
        </ContentWrapper>
      </Container>
    </Wrapper>
  );
};

export default DataCenter;
