import { useNavigate } from 'react-router-dom';
import { verifications } from '../../../utils/verifications';
import { routes } from '../../Router';

const UserCard = ({ data, canClick }) => {
  const { Name, AccountID, DownlineCount, SourceHereAhftHiveBenefit } = data;
  const navigate = useNavigate();
  const handleCheckDownLineUser = () => {
    if (!canClick) return;
    navigate(`${routes.memberships}/${AccountID}`);
  }
  return (
    <div
      className={`
        border border-sub-blue rounded
        ${canClick ? 'cursor-pointer' : 'events-pointers-none cursor-default'}
      `}
      onClick={handleCheckDownLineUser}
    >
      <span className='
        inline-block w-full text-center
        text-blue border-b border-b-sub-blue rounded-t bg-main
      '>
        {Name.split('@')[0]}
      </span>
      <div className='p-4'>
        <div>
          <span className='block text-[0.875rem] font-bold text-blue'>Invite</span>
          <span className='text-[0.875rem]'>
            {DownlineCount}
          </span>
        </div>
        <div className='mt-1'>
          <span className='block text-[0.875rem] font-bold text-blue'>Sharing</span>
          <span className='text-[0.875rem]'>{`$ ${SourceHereAhftHiveBenefit.toFixed(2).replace(verifications.currency, ',')}`}</span>
        </div>
      </div>
    </div>
  );
}

export default UserCard;