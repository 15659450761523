import styled, { css } from "styled-components";
import Calendar from "../../../global/Calendar";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";

import Icons from "../../../global/icons";
import {
  TaiwanIdFormatCheck,
  HongKongIdStartFormatCheck,
  HongKongIdEndFormatCheck,
  TaiwanPassportFormatCheck,
  HongKongPassportCheck,
} from "../../../utils";
import { useEffect, useState } from "react";
import Lang from "../../../global/Lang";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Title = styled.div`
  font-size: min(max(1.25vw, 22px), 24px);
  color: #fcfcfc;
  padding-bottom: 20px;

  ${RWD_MD(css`
    font-size: min(max(1.0416vw, 18px), 20px);
  `)};
`;

const DescriptionWrapper = styled.div`
  color: #fcfcfc;
  padding: 20px 0;
`;

const DescriptionContainer = styled.div``;

const DescriptionTitle = styled.div``;

const DescriptionContent = styled.div``;

const DocumentButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;
  color: #fcfcfc;

  ${RWD_SM(css`
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0px;
  `)};
`;

const Label = styled.label`
  padding: 10px 0;
  font-size: min(max(1.0416vw, 18px), 20px);

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 16px), 18px);
  `)};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${RWD_SM(css`
    padding-bottom: 10px;
  `)};
`;

const InputEl = styled.input`
  font-size: min(max(1.0416vw, 18px), 20px);
  text-align: center;
  /* padding: 1.0416vw 1.5625vw; */
  padding: 10px 20px;
  border: 1px solid;
  border-radius: 8px;
  width: 100%;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  color: #fcfcfc;

  border-color: rgba(255, 255, 255, 0.15);

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 16px), 18px);
  `)};
`;

const IdentityNumberWrapper = styled.div`
  display: grid;
  grid-template-columns: 70% 5% 20% 5%;
  align-items: center;
  color: #fcfcfc;
`;

const IdentityDirection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IdentityNumberInputEl = styled.input`
  font-size: min(max(1.0416vw, 18px), 20px);
  text-align: center;
  /* padding: 1.0416vw 1.5625vw; */
  padding: 10px 20px;
  border: 1px solid;
  border-radius: 8px;
  width: 100%;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  color: #fcfcfc;

  border-color: ${(props) =>
    props.error ? "#F05D2F" : "rgba(255, 255, 255, 0.15)"};

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 16px), 18px);
  `)};
`;

const ErrorMsgWrapper = styled.div`
  padding-top: 5px;
  color: #f05d2f;
  font-size: min(max(0.833vw, 14px), 16px);
  height: 40px;

  /* ${RWD_SM(css`
    padding: 10px 0;
  `)}; */
`;

const PersonalInfo = ({
  setFirstName,
  firstName,
  setMiddleName,
  middleName,
  setLastName,
  lastName,
  setBirth,
  birth,
  setIdentityNumber,
  identityNumber,
  kycType,
  country,
  errorMsg,
  setErrorMsg,
}) => {
  const [hongKongIdStart, setHongKongIdStart] = useState("");
  const [hongKongIdEnd, setHongKongIdEnd] = useState("");

  useEffect(() => {
    if (country == 2) {
      if (kycType == 1) {
        if (!TaiwanIdFormatCheck(identityNumber) && identityNumber !== "") {
          setErrorMsg("身分證格式錯誤");
        } else {
          setErrorMsg("");
        }
      } else {
        if (
          !TaiwanPassportFormatCheck(identityNumber) &&
          identityNumber !== ""
        ) {
          setErrorMsg("護照格式錯誤");
        } else {
          setErrorMsg("");
        }
      }
    }

    if (country == 1) {
      if (kycType == 2) {
        if (!HongKongPassportCheck(identityNumber) && identityNumber !== "") {
          setErrorMsg("護照格式錯誤");
        } else {
          setErrorMsg("");
        }
      }
    }
  }, [identityNumber]);

  useEffect(() => {
    console.log(hongKongIdEnd);
    if (hongKongIdStart) {
      if (
        !HongKongIdStartFormatCheck(hongKongIdStart) &&
        hongKongIdStart !== ""
      ) {
        setErrorMsg("身分證格式錯誤");
      } else {
        setErrorMsg("");
      }
    }

    if (hongKongIdEnd) {
      if (!HongKongIdEndFormatCheck(hongKongIdEnd) && hongKongIdEnd !== "") {
        setErrorMsg("身分證格式錯誤");
      } else {
        setErrorMsg("");
      }
    }

    if (
      HongKongIdStartFormatCheck(hongKongIdStart) &&
      hongKongIdStart !== "" &&
      HongKongIdEndFormatCheck(hongKongIdEnd) &&
      hongKongIdEnd !== ""
    ) {
      setIdentityNumber(`${hongKongIdStart}${hongKongIdEnd}`);
    } else {
      setIdentityNumber("");
    }
  }, [hongKongIdStart, hongKongIdEnd]);

  return (
    <Wrapper>
      <Title>
        <Lang
          datakey="personal_information"
          defaultText="Personal Information"
        />
      </Title>
      <DescriptionWrapper>
        <DescriptionContent>
          <Lang
            datakey="kyc_personal_match"
            defaultText="Please ensure your country of residence matches your vaild ID. Your
          privileges could change based on selection."
          />
        </DescriptionContent>
      </DescriptionWrapper>

      <InputWrapper>
        <InputContainer>
          <Label>
            <Lang datakey="first_name" defaultText="First Name" />
          </Label>
          <InputEl
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </InputContainer>
        <InputContainer>
          <Label>
            <Lang datakey="last_name" defaultText="Last Name" />
          </Label>
          <InputEl
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </InputContainer>
      </InputWrapper>

      <InputWrapper>
        {/* <InputContainer>
          <Label>Middle Name</Label>
          <InputEl
            value={middleName}
            onChange={(e) => setMiddleName(e.target.value)}
          />
        </InputContainer> */}
        <InputContainer>
          <Label>
            <Lang datakey="date_of_birth" defaultText="Date of Birth" />
          </Label>
          <Calendar onChangeFun={setBirth} selectDate={birth} />
        </InputContainer>
        <InputContainer>
          <Label>
            <Lang datakey="identity_number" defaultText="Identity Number" />
          </Label>
          {country == 2 ? (
            // 台灣
            <IdentityNumberInputEl
              error={errorMsg}
              onChange={(e) =>
                setIdentityNumber(e.target.value.replace(/[^A-Za-z0-9]/g, ""))
              }
              value={identityNumber}
              placeholder={kycType == 1 ? "A234567890" : "888800088"}
            />
          ) : (
            // 香港
            <>
              {kycType == 1 ? (
                <IdentityNumberWrapper>
                  <IdentityNumberInputEl
                    error={errorMsg}
                    onChange={(e) =>
                      setHongKongIdStart(
                        e.target.value.replace(/[^A-Za-z0-9]/g, "")
                      )
                    }
                    value={hongKongIdStart}
                    placeholder="Z683365"
                  />
                  <IdentityDirection>(</IdentityDirection>

                  <IdentityNumberInputEl
                    error={errorMsg}
                    onChange={(e) =>
                      setHongKongIdEnd(
                        e.target.value.replace(/[^A-Za-z0-9]/g, "")
                      )
                    }
                    value={hongKongIdEnd}
                    placeholder="5"
                  />
                  <IdentityDirection>)</IdentityDirection>
                </IdentityNumberWrapper>
              ) : (
                <IdentityNumberInputEl
                  error={errorMsg}
                  onChange={(e) =>
                    setIdentityNumber(
                      e.target.value.replace(/[^A-Za-z0-9]/g, "")
                    )
                  }
                  value={identityNumber}
                  placeholder="H00000000"
                />
              )}
            </>
          )}

          <ErrorMsgWrapper>{errorMsg}</ErrorMsgWrapper>
        </InputContainer>
      </InputWrapper>

      {/* <InputWrapper style={{ gridTemplateColumns: "repeat(1, 1fr)" }}>
        <InputContainer>
          <Label>Identity Number</Label>
          {country == 2 ? (
            // 台灣
            <IdentityNumberInputEl
              error={errorMsg}
              onChange={(e) =>
                setIdentityNumber(e.target.value.replace(/[^A-Za-z0-9]/g, ""))
              }
              value={identityNumber}
              placeholder={kycType == 1 ? "A234567890" : "888800088"}
            />
          ) : (
            // 香港
            <>
              {kycType == 1 ? (
                <IdentityNumberWrapper>
                  <IdentityNumberInputEl
                    error={errorMsg}
                    onChange={(e) =>
                      setHongKongIdStart(
                        e.target.value.replace(/[^A-Za-z0-9]/g, "")
                      )
                    }
                    value={hongKongIdStart}
                    placeholder="Z683365"
                  />
                  <IdentityDirection>(</IdentityDirection>

                  <IdentityNumberInputEl
                    error={errorMsg}
                    onChange={(e) =>
                      setHongKongIdEnd(
                        e.target.value.replace(/[^A-Za-z0-9]/g, "")
                      )
                    }
                    value={hongKongIdEnd}
                    placeholder="5"
                  />
                  <IdentityDirection>)</IdentityDirection>
                </IdentityNumberWrapper>
              ) : (
                <IdentityNumberInputEl
                  error={errorMsg}
                  onChange={(e) =>
                    setIdentityNumber(
                      e.target.value.replace(/[^A-Za-z0-9]/g, "")
                    )
                  }
                  value={identityNumber}
                  placeholder="H00000000"
                />
              )}
            </>
          )}

          <ErrorMsgWrapper>{errorMsg}</ErrorMsgWrapper>
        </InputContainer>
      </InputWrapper> */}
    </Wrapper>
  );
};

export default PersonalInfo;
