import styled, { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../../utils/rwd";

import { useGetTrendFollowingDataQuery } from "../../../../services/datacenterApi";

import Icons from "../../../../global/icons";
import Loading from "../../../../components/Loading/Loading";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Line } from "react-chartjs-2";
import { useState, useEffect } from "react";

import Lang from "../../../../global/Lang";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Wrapper = styled.div`
  color: #fcfcfc;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 5px;
  display: flex;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  ${RWD_SM(css`
    flex-direction: column;
  `)};
`;

const ChartAndAssetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* background-color: #22254a; */
  /* border: 1px solid red; */
  width: calc((100% - 5px) / 2);

  ${RWD_SM(css`
    width: 100%;
  `)};
`;

const ChartWrapper = styled.div`
  padding-bottom: 20px;
  background-color: #22254a;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ChartContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 95%;
  padding-top: 15px;
`;

const AssetWrapper = styled.div`
  font-size: min(max(0.833vw, 14px), 16px);
  background-color: #22254a;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
`;

const AssetTabWrapper = styled.div`
  width: 95%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
`;

const AssetTabContainer = styled.div`
  display: flex;
  align-items: center;
`;

const AssetTab = styled.div`
  border-radius: 8px 8px 0 0;
  margin-right: 5px;
  border: ${(props) =>
    props.activeTab
      ? "1px solid rgba(255, 255, 255, 0.15)"
      : "1px solid transparent"};
  cursor: pointer;
  border-bottom: 0;
  background-color: #151944;
`;

const AssetTabItem = styled.div`
  padding: 5px 15px;
  color: #54587b;
  font-size: min(max(0.833vw, 14px), 16px);
  color: ${(props) => (props.activeTab ? "#fcfcfc" : "")};
`;

const AssetContainer = styled.div`
  /* border: 1px solid rgba(255, 255, 255, 0.15); */
  border-bottom: 0px;
  width: 95%;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-top: 0;
  /* background-color: #151944; */
  /* border: 1px solid yellow; */
`;

const AssetHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  background-color: #151944;
`;

const AssetHeaderItem = styled.div`
  /* padding: 10px 5px; */
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;

  color: #54587b;
  color: rgba(255, 255, 255, 0.7);
  /* border-right: 1px solid rgba(255, 255, 255, 0.15); */
`;

const AssetBody = styled.div``;

const AssetBodyContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.index % 2 ? "rgba(255, 255, 255, 0)" : "rgba(255, 255, 255, 0.1 )"};

  /* border-bottom: 1px solid rgba(255, 255, 255, 0.15); */
`;

const AssetBodyItem = styled.div`
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.15); */
  border-right: 1px solid rgba(255, 255, 255, 0.15);

  :last-child {
    border-right: 0px solid rgba(255, 255, 255, 0.15);
  }
`;

const TradeWrapper = styled.div`
  /* border: 1px solid yellow; */
  width: calc((100% - 5px) / 2);
  background-color: #22254a;
  font-size: min(max(0.833vw, 14px), 16px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;

  ${RWD_SM(css`
    width: 100%;
    margin-top: 5px;
  `)};
`;

const TradeContainer = styled.div`
  /* border: 1px solid rgba(255, 255, 255, 0.15); */
  /* background-color: #151944; */
  border-bottom: 0px;
  width: 95%;
  /* border: 1px solid rebeccapurple; */
`;

const TradeHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: #151944; */

  /* border-bottom: 0px; */
  padding: 10px 0;
  color: #54587b;
  width: 95%;
`;

const TradeHeaderItem = styled.div`
  text-align: left;
`;

const TradeItem = styled.div`
  /* border: 1px solid teal; */
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.15); */
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  color: rgba(255, 255, 255, 0.7);
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
`;

const TradeDate = styled.div`
  /* border: 1px solid ridge; */
  width: 35%;
  /* text-align: center; */
`;

const TradeAction = styled.div`
  padding-right: 5px;
  color: ${(props) => (props.action === "sell" ? "#f05d2f" : "#1cac2a")};
`;

const TradeAmount = styled.div`
  padding-right: 5px;
  color: #fcfcfc;
`;

const TradePrice = styled.div`
  padding: 0 5px;
  font-weight: 500;
  color: #fcfcfc;
`;

const TradeAsset = styled.div`
  font-weight: 500;
  color: #fcfcfc;
`;

const TradeContent = styled.div`
  width: 62%;
  display: flex;
  flex-wrap: wrap;
  /* color: rgba(255, 255, 255, 0.6); */
  /* border: 1px solid rebeccapurple; */
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 95%;
  padding: 15px 0;
  position: relative;
`;

const TitleIcon = styled.div`
  width: 25px;
  height: 25px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 25px;
    height: 25px;
  }
`;

const SubTitle = styled.div`
  font-size: min(max(1.0416vw, 18px), 20px);
  color: #fcfcfc;
  padding-left: 10px;

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 16px), 18px);
  `)};
`;

const SubTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: flex-start; */
`;

const SubTitleTime = styled.div`
  font-size: 13px;
  color: #54587b;
  position: absolute;
  top: 70%;
  left: 35px;
`;

const TrendFollowing = () => {
  const [assetList, setAssetList] = useState([]);
  const [orderHistory, setOrderHistory] = useState([]);
  const [chartData, setChartData] = useState([]);

  const { data, error, isError, isSuccess, isLoading } =
    useGetTrendFollowingDataQuery();

  useEffect(() => {
    if (isSuccess) {
      setAssetList(data?.AssetList);
      setOrderHistory(data?.OrderHistory);
      setChartData([
        {
          dateGroup: data?.ChartDateGroup,
          realizePnl: data?.RealizePnl,
          unrealizePnl: data?.UnrealizePnl,
          realizePnlDelta: data?.RealizePnlDelta,
        },
      ]);
    }

    if (isError) {
      console.log(error.data.Message);
    }
  }, [data, isError]);

  const lineChartData = {
    labels: chartData[0]?.dateGroup,
    datasets: [
      {
        data: chartData[0]?.unrealizePnl,

        label: "Unrealized_pnl",
        borderColor: "rgba(255, 99, 132, 1)",
        backgroundColor: "rgba(255, 99, 132, 1)",
      },
      {
        data: chartData[0]?.realizePnl,
        label: "Realized_pnl",
        borderColor: "rgba(54, 162, 235, 1)",
        backgroundColor: "rgba(54, 162, 235, 1)",
      },
      {
        data: chartData[0]?.realizePnlDelta,
        label: "Realized_pnldelta",
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 1)",
      },
    ],
  };

  const lineChartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: false,
      },
      legend: {
        labels: {
          color: "#ccc",
        },
      },
    },

    scales: {
      x: {
        ticks: {
          color: "#fcfcfc",
        },
      },
      y: {
        ticks: {
          color: "#fcfcfc",
          callback: function (value) {
            return `${value} % `;
          },
        },
        grid: {
          color: "rgba(255, 255, 255, 0.15)",
        },
      },
    },
  };

  const assetHeader = [
    {
      index: 1,
      name: (
        <Lang datakey="datacenter_avadata_assetname" defaultText="Asset Name" />
      ),
      value: "Asset",
      width: "20%",
    },
    {
      index: 2,
      name: <Lang datakey="amount" defaultText="Amount" />,
      value: "Amount",
      width: "20%",
    },
    {
      index: 3,
      name: (
        <Lang datakey="datacenter_avadata_avgprice" defaultText="Avg Price" />
      ),
      value: "AvgPrice",
      width: "20%",
    },
    {
      index: 4,
      name: (
        <Lang
          datakey="datacenter_avadata_unrealizedpnl"
          defaultText="Unrealized PNL"
        />
      ),
      value: "UnrealizePnl",
      width: "20%",
    },
    {
      index: 5,
      name: <Lang datakey="datacenter_avadata_cumpnl" defaultText="cum PNL" />,
      value: "CumPnl",
      width: "20%",
    },
  ];

  // const assetBody = [
  //   {
  //     AssetName: "LINK",
  //     Amount: 10,
  //     avg_price: 25,
  //     unrealized_PNL: 200,
  //     cum_PNL: 250,
  //   },
  //   {
  //     AssetName: "EOS",
  //     Amount: 15,
  //     avg_price: 3,
  //     unrealized_PNL: -45,
  //     cum_PNL: 60,
  //   },
  //   {
  //     AssetName: "XTZ",
  //     Amount: 30,
  //     avg_price: 2,
  //     unrealized_PNL: -60,
  //     cum_PNL: 90,
  //   },
  //   {
  //     AssetName: "ANKR",
  //     Amount: 5000,
  //     avg_price: 0.1,
  //     unrealized_PNL: 500,
  //     cum_PNL: 750,
  //   },
  //   {
  //     AssetName: "GRT",
  //     Amount: 800,
  //     avg_price: 0.8,
  //     unrealized_PNL: -640,
  //     cum_PNL: 920,
  //   },
  //   {
  //     AssetName: "KSM",
  //     Amount: 6,
  //     avg_price: 300,
  //     unrealized_PNL: 1800,
  //     cum_PNL: 2100,
  //   },
  //   {
  //     AssetName: "AGIX",
  //     Amount: 300,
  //     avg_price: 0.5,
  //     unrealized_PNL: -150,
  //     cum_PNL: 225,
  //   },
  //   {
  //     AssetName: "ETHW",
  //     Amount: 12,
  //     avg_price: 600,
  //     unrealized_PNL: 7200,
  //     cum_PNL: 8400,
  //   },
  //   {
  //     AssetName: "COMP",
  //     Amount: 4,
  //     avg_price: 350,
  //     unrealized_PNL: -1400,
  //     cum_PNL: 1650,
  //   },
  //   {
  //     AssetName: "BCH",
  //     Amount: 0.4,
  //     avg_price: 20000,
  //     unrealized_PNL: 8000,
  //     cum_PNL: 9200,
  //   },
  //   {
  //     AssetName: "BAT",
  //     Amount: 200,
  //     avg_price: 1.2,
  //     unrealized_PNL: -240,
  //     cum_PNL: 340,
  //   },
  //   {
  //     AssetName: "DYDX",
  //     Amount: 80,
  //     avg_price: 10,
  //     unrealized_PNL: 800,
  //     cum_PNL: 1100,
  //   },
  //   {
  //     AssetName: "CAKE",
  //     Amount: 30,
  //     avg_price: 20,
  //     unrealized_PNL: -600,
  //     cum_PNL: 800,
  //   },
  //   {
  //     AssetName: "NEO",
  //     Amount: 12,
  //     avg_price: 30,
  //     unrealized_PNL: -360,
  //     cum_PNL: 510,
  //   },
  //   {
  //     AssetName: "ZEC",
  //     Amount: 7,
  //     avg_price: 100,
  //     unrealized_PNL: 700,
  //     cum_PNL: 950,
  //   },
  //   {
  //     AssetName: "BAL",
  //     Amount: 14,
  //     avg_price: 25,
  //     unrealized_PNL: -350,
  //     cum_PNL: 480,
  //   },
  //   {
  //     AssetName: "GNO",
  //     Amount: 25,
  //     avg_price: 40,
  //     unrealized_PNL: 1000,
  //     cum_PNL: 1250,
  //   },
  //   {
  //     AssetName: "CHZ",
  //     Amount: 1000,
  //     avg_price: 0.3,
  //     unrealized_PNL: -300,
  //     cum_PNL: 425,
  //   },
  //   {
  //     AssetName: "XLM",
  //     Amount: 350,
  //     avg_price: 0.28,
  //     unrealized_PNL: 98,
  //     cum_PNL: 135,
  //   },
  // ];

  const tradeHeader = [
    {
      index: 1,
      name: (
        <Lang
          datakey="datacenter_trendfollowing_time"
          defaultText="Trading Hours"
        />
      ),
      value: "OrderTime",
      width: "35%",
    },
    {
      index: 2,
      name: (
        <Lang
          datakey="datacenter_trendfollowing_description"
          defaultText="Description Of The Trading Journal"
        />
      ),
      value: "Content",
      width: "62%",
    },
  ];

  const tradeData = [
    {
      date: "2023/07/01",
      content: "購買10 LINK，價格25 USDT",
    },
    {
      date: "2023/07/02",
      content: "購買15 EOS，價格3 USDT",
    },
    {
      date: "2023/07/03",
      content: "購買30 XTZ，價格2 USDT",
    },
    {
      date: "2023/07/04",
      content: "購買5000 ANKR，價格0.1 USDT",
    },
    {
      date: "2023/07/05",
      content: "購買800 GRT，價格0.8 USDT",
    },
    {
      date: "2023/07/06",
      content: "購買6 KSM，價格300 USDT",
    },
    {
      date: "2023/07/07",
      content: "購買300 AGIX，價格0.5 USDT",
    },
    {
      date: "2023/07/08",
      content: "購買12 ETHW，價格600 USDT",
    },
    {
      date: "2023/07/09",
      content: "購買4 COMP，價格350 USDT",
    },
    {
      date: "2023/07/10",
      content: "購買0.4 YFI，價格20000 USDT",
    },
  ];

  const [activeAssetTab, setActiveAssetTab] = useState(1);

  const handleTab = (id) => {
    setActiveAssetTab(id);
  };

  return (
    <Wrapper>
      {isLoading && <Loading />}
      <Container>
        <ContentWrapper>
          <ChartAndAssetWrapper>
            <ChartWrapper>
              <TitleWrapper>
                <TitleIcon>
                  <Icons.DataCenter_Chart />
                </TitleIcon>
                <SubTitleWrapper>
                  <SubTitle>
                    <Lang
                      datakey="datacenter_avadata_profitandloss"
                      defaultText="Profit & Loss Analysis"
                    />
                  </SubTitle>
                </SubTitleWrapper>
                <SubTitleTime>
                  (
                  <Lang
                    datakey="datacenter_avadata_sincetime"
                    defaultText="Since 2023 June 1st"
                  />
                  )
                </SubTitleTime>
              </TitleWrapper>
              <ChartContainer>
                <Line options={lineChartOptions} data={lineChartData} />
              </ChartContainer>
            </ChartWrapper>
            <AssetWrapper>
              <TitleWrapper>
                <TitleIcon>
                  <Icons.DataCenter_List />
                </TitleIcon>
                <SubTitle>
                  <Lang
                    datakey="datacenter_avadata_assetlist"
                    defaultText="Asset List"
                  />
                </SubTitle>
              </TitleWrapper>
              <AssetTabWrapper>
                <AssetTabContainer>
                  <AssetTab
                    activeTab={activeAssetTab === 1}
                    onClick={() => handleTab(1)}
                  >
                    <AssetTabItem activeTab={activeAssetTab === 1}>
                      <Lang
                        datakey="datacenter_avadata_holdasset"
                        defaultText="HoldAsset"
                      />
                    </AssetTabItem>
                  </AssetTab>
                  <AssetTab
                    activeTab={activeAssetTab === 2}
                    onClick={() => handleTab(2)}
                  >
                    <AssetTabItem activeTab={activeAssetTab === 2}>
                      <Lang datakey="history" defaultText="History" />
                    </AssetTabItem>
                  </AssetTab>
                </AssetTabContainer>
              </AssetTabWrapper>
              <AssetContainer>
                <AssetHeader>
                  {assetHeader.map((data) => (
                    <AssetHeaderItem
                      key={data.index}
                      style={{
                        width: `${data.width}`,
                      }}
                    >
                      {data.name}
                    </AssetHeaderItem>
                  ))}
                </AssetHeader>
                <AssetBody>
                  {activeAssetTab === 1
                    ? assetList
                        .filter((item) => item.Amount != 0)
                        .map((data, index) => (
                          <AssetBodyContainer key={index} index={index}>
                            {assetHeader.map((item) => (
                              <AssetBodyItem
                                key={item.index}
                                style={{
                                  width: `${item.width}`,
                                }}
                              >
                                {(() => {
                                  switch (item.value) {
                                    case "Amount":
                                      return data[item.value]?.toFixed(2);
                                    case "AvgPrice":
                                      return data[item.value]?.toFixed(2);
                                    case "CumPnl":
                                      return data[item.value]?.toFixed(2);
                                    case "UnrealizePnl":
                                      return data[item.value]?.toFixed(2);
                                    default:
                                      return data[item.value];
                                  }
                                })()}
                              </AssetBodyItem>
                            ))}
                          </AssetBodyContainer>
                        ))
                    : assetList
                        .filter((item) => item.Amount == 0)
                        .map((data, index) => (
                          <AssetBodyContainer key={index} index={index}>
                            {assetHeader.map((item) => (
                              <AssetBodyItem
                                key={item.index}
                                style={{
                                  width: `${item.width}`,
                                }}
                              >
                                {(() => {
                                  switch (item.value) {
                                    case "Amount":
                                      return data[item.value]?.toFixed(2);
                                    case "AvgPrice":
                                      return data[item.value]?.toFixed(2);
                                    case "CumPnl":
                                      return data[item.value]?.toFixed(2);
                                    case "UnrealizePnl":
                                      return data[item.value]?.toFixed(2);
                                    default:
                                      return data[item.value];
                                  }
                                })()}
                              </AssetBodyItem>
                            ))}
                          </AssetBodyContainer>
                        ))}
                </AssetBody>
              </AssetContainer>
            </AssetWrapper>
          </ChartAndAssetWrapper>
          <TradeWrapper>
            <TitleWrapper>
              <TitleIcon>
                <Icons.DataCenter_Diary />
              </TitleIcon>
              <SubTitle>
                <Lang
                  datakey="datacenter_trendfollowing_tradingjournal"
                  defaultText="Trading Journal"
                />
              </SubTitle>
            </TitleWrapper>
            <TradeHeader>
              {tradeHeader.map((data) => (
                <TradeHeaderItem
                  key={data.index}
                  style={{
                    width: `${data.width}`,
                  }}
                >
                  {data.name}
                </TradeHeaderItem>
              ))}
            </TradeHeader>
            <TradeContainer>
              {orderHistory.map((data, index) => (
                <TradeItem key={index}>
                  <TradeDate>
                    {`${data.OrderTime?.split(" ")[0].split("/")[2]}/${
                      data.OrderTime?.split(" ")[0].split("/")[0]
                    }/${data.OrderTime?.split(" ")[0].split("/")[1]} ${
                      data.OrderTime?.split(" ")[1]
                    }`}
                  </TradeDate>
                  <TradeContent>
                    <TradeAction action={data.Action}>
                      {data.Action === "sell" ? (
                        <Lang datakey="sell" defaultText="Sell" />
                      ) : (
                        <Lang datakey="buy" defaultText="Buy" />
                      )}
                    </TradeAction>
                    <TradeAmount>{data.Amount?.toFixed(2)}</TradeAmount>
                    <TradeAsset>{data.Asset}</TradeAsset>
                    {"，"}
                    <Lang datakey="price" defaultText="Price" />
                    <TradePrice>{data.Price}</TradePrice>
                    {"USDT"}
                    {/* 賣出 1 ASTR，價格0.0455 USDT */}
                  </TradeContent>
                </TradeItem>
              ))}
            </TradeContainer>
          </TradeWrapper>
        </ContentWrapper>
      </Container>
    </Wrapper>
  );
};

export default TrendFollowing;
