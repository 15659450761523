import styled, { css } from "styled-components";
import { RWD_XL, RWD_LG, RWD_MD, RWD_SM, RWD_SS, RWD_XS } from "../utils/rwd";
import { useSelector } from "react-redux";
import { selectAccount } from "../slices/accountSlice";
import { selectSystem } from "../slices/systemSlice";
import { useEffect, useState } from "react";
import Icons from "./icons";
import Lang from "./Lang";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  ${RWD_SM(css`
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  `)};
`;

const Label = styled.label`
  text-align: left;
  width: auto;
  color: #fcfcfc;
  padding: 10px 0;
  text-align: left;
  font-size: clamp(14px, 0.833vw, 16px);
  white-space: nowrap;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  color: ${(props) => (props.colorType === "white" ? "#22254a" : "#fcfcfc")};
  font-size: clamp(14px, 0.833vw, 16px);
`;

const IconContainer = styled.div`
  transform: ${(props) => (props.isOpen ? "scaleY(-1)" : "scaleY(1)")};
  transition: all 0.3s;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 15px;
    height: 15px;
  }
`;

const SelectWrapper = styled.div`
  position: relative;
  background: ${(props) =>
    props.colorType === "white"
      ? "#d9d9d9;"
      : "linear-gradient(155.14deg,rgba(255, 255, 255, 0) -2.13%,rgba(255, 255, 255, 0.15) 136.58%)"};

  border: 1px solid rgba(255, 255, 255, 0.15);
  border-bottom: ${(props) =>
    props.isOpen
      ? "1px solid transparent"
      : "1px solid 1px solid rgba(255, 255, 255, 0.15)"};
  border-radius: ${(props) => (props.isOpen ? "5px 5px 0 0" : "5px")};
  cursor: pointer;
`;

const SelectItem = styled.div`
  padding: 8px 15px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  ${RWD_SM(css`
    padding: 5px 10px;
  `)};
`;

const SelectItemText = styled.div`
  color: #fcfcfc;
`;

const OptionWrapper = styled.ul`
  position: absolute;
  width: 100%;
  top: 100%;
  background: #22254a;
  border-left: 1px solid;
  border-right: 1px solid;
  border-bottom: 1px solid;
  z-index: 5;
  border-color: ${(props) =>
    props.colorType === "white" ? "#d9d9d9;" : "rgba(255, 255, 255, 0.15)"};
  border-radius: 0px 0px 5px 5px;
  overflow: hidden;
`;

const OptionItem = styled.li`
  padding: 8px 15px;
  background: ${(props) =>
    props.colorType === "white"
      ? "#d9d9d9;"
      : "linear-gradient(155.14deg,rgba(255, 255, 255, 0) -2.13%,rgba(255, 255, 255, 0.15) 136.58%)"};
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
    color: #22254a;
  }
`;

const SelectDataCenter = ({ title, onChangeFun, nowValue, type, dataList }) => {
  const [isOpen, setIsOpen] = useState(false);

  //   console.log(nowValue);

  //   console.log(dataList);

  //   useEffect(() => {
  //     if (nowValue && dataList.length > 0) {
  //       setNowSelectText(
  //         dataList.filter((data) => data.Key === nowValue)[0]?.Value
  //       );
  //     }
  //   }, [nowValue]);

  const handleClickItem = (key) => {
    onChangeFun(key);
    setIsOpen(false);
  };

  return (
    <Wrapper>
      <Label>{title}</Label>
      <Container>
        {dataList && (
          <SelectWrapper onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
            <SelectItem>
              <SelectItemText>
                {type === "robot" ? (
                  nowValue === "all" ? (
                    "All"
                  ) : (
                    <Lang
                      datakey={
                        dataList.filter((data) => data.Value === nowValue)[0]
                          ?.Key
                      }
                      defaultText={""}
                    />
                  )
                ) : (
                  dataList.filter((data) => data.Value === nowValue)[0]
                    ?.UnitValue
                )}
                {type !== "robot" &&
                  dataList.filter((data) => data.Value === nowValue)[0]?.Unit}
              </SelectItemText>
              <IconContainer isOpen={isOpen}>
                <Icons.Arrow />
              </IconContainer>
            </SelectItem>
          </SelectWrapper>
        )}

        {isOpen && (
          <OptionWrapper>
            {dataList?.map((item) => (
              <OptionItem
                value={item?.Value}
                key={item?.Key}
                name={type}
                onClick={() => handleClickItem(item?.Value)}
              >
                {type === "robot" ? (
                  item?.Value === "all" ? (
                    "All"
                  ) : (
                    <Lang datakey={item?.Key} defaultText={""} />
                  )
                ) : (
                  item?.UnitValue
                )}
                {type !== "robot" && item?.Unit}
              </OptionItem>
            ))}
          </OptionWrapper>
        )}
      </Container>
    </Wrapper>
  );
};

export default SelectDataCenter;
