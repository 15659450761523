import styled from "styled-components";
import { css } from "styled-components";

import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";

import { LangString } from "../../utils";

import { QRCodeCanvas } from "qrcode.react";
import Lang from "../../global/Lang";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

// sweetAlert
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { useSetAvaPointDepositMutation } from "../../services/avaPointApi";
import { useGetAccountProfileQuery } from "../../services/accountApi";
import { useDispatch, useSelector } from "react-redux";
import { setAccountProfile } from "../../slices/accountSlice";
import { selectSystem } from "../../slices/systemSlice";

import { useGetAlgoRefetchMutation } from "../../services/algoApi";

import Icons from "../../global/icons";

import { AlertMySwal, SuccessMySwal, ErrorMySwal } from "../../global/MySwal";
import { getLanguage } from "../../services/common";

import Button from "../../global/Buttons";

// #region styled

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  color: #151944;
`;

const Container = styled.div`
  background-color: #fcfcfc;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 40px;
  /* padding: 2.088vw 4.166vw; */
  width: 41.66vw;
  max-width: 650px;
  border-radius: 8px;

  ${RWD_LG(css`
    width: 70%;
  `)};

  ${RWD_MD(css`
    padding: 20px 40px;
  `)};

  ${RWD_SM(css`
    width: 90%;
    padding: 20px;
  `)};
`;

const Title = styled.div`
  color: #151944;
  font-size: min(max(1.5625vw, 28px), 30px);
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;

  ${RWD_MD(css`
    font-size: min(max(1.0416vw, 18px), 20px);
  `)};
`;

const SubTitle = styled.div`
  color: #151944;
  font-size: min(max(1.145vw, 20px), 22px);
  font-weight: bold;
  padding: 10px 0;
  display: flex;

  ${RWD_MD(css`
    font-size: min(max(0.9375vw, 16px), 18px);
  `)};
`;

const SystemTokenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #151944;
`;

const SystemToken = styled.div`
  font-size: 1.145vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: min(max(1.145vw, 20px), 22px);

  ${RWD_MD(css`
    font-size: min(max(0.9375vw, 16px), 18px);
  `)};
`;

const CopyIconWrapper = styled.div`
  width: 28px;
  height: 28px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: 28px;
    height: 28px;
  }

  ${RWD_SM(css`
    width: 25px;
    height: 25px;

    svg {
      width: 25px;
      height: 25px;
    }
  `)};
`;

const InputWrapper = styled.div``;

const Input = styled.input`
  background: #d9d9d9;
  padding: 1.0416vw 1.5625vw;
  width: 100%;
  border-radius: 100px;

  ${RWD_MD(css`
    font-size: min(max(0.833vw, 14px), 16px);
    padding: 8px 20px;
  `)};
`;

const QrcodeContainer = styled.div`
  padding: 20px 0 10px 0;
  margin: auto;
`;

const CheckboxContainer = styled.div`
  font-size: min(max(0.833vw, 14px), 16px);
  padding-left: 10px;
`;

const CheckboxWrapper = styled.label`
  color: #151944;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 20px;
`;

const CheckBox = styled.input.attrs({ type: "checkbox" })`
  accent-color: #151944;
  width: 20px;
  height: 20px;
  margin: 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 30px;

  ${RWD_SM(css`
    padding-top: 25px;
    justify-content: space-between;
  `)};
`;

// const Button = styled.button`
//   /* width: min(max(7.0833vw, 100px), 135px); */
//   width: 135px;
//   border-radius: 60px;
//   border: 1px solid;
//   color: ${(props) => (props.type === "confirm" ? "#fcfcfc" : "#151944")};
//   background-color: ${(props) =>
//     props.type === "confirm" ? (props.disabled ? "#d9d9d9" : "#151944") : ""};
//   border-color: ${(props) => (props.disabled ? "#d9d9d9" : "#151944")};

//   &:first-child {
//     margin-right: 1.5625vw;
//   }

//   ${RWD_MD(css`
//     width: 90px;
//   `)};
// `;

// const ButtonText = styled.div`
//   padding: 9px 0;
//   text-align: center;

//   font-size: min(max(1.0416vw, 18px), 20px);

//   ${RWD_MD(css`
//     padding: 6px 15px;
//     font-size: min(max(0.833vw, 14px), 16px);
//   `)};
// `;

// #endregion

const Deposit = ({ setOpen }) => {
  const dispatch = useDispatch();
  const depositAdd = useSelector(selectSystem)?.despositAddress;
  const [searchParams, setSearchParams] = useSearchParams();
  const [depositTokenData, setDepositTokenData] = useState([]);

  useEffect(() => {
    const data = depositAdd.filter((token) => token.Crypto === "BEP-20");
    setDepositTokenData(data);
  }, [depositAdd]);

  const [depositToken, setDepositToken] = useState("");
  const [txid, setTxid] = useState("");
  const [txidChecked, setTxidChecked] = useState(false);

  const { data, error, isError, isSuccess, refetch, isFetching } =
    useGetAccountProfileQuery();

  const [_refetchAlgo, {}] = useGetAlgoRefetchMutation();

  const [
    _setAvaPointDeposit,
    {
      data: setAvaPointDepositData,
      error: setAvaPointDepositError,
      isError: setAvaPointDepositIsError,
      isSuccess: setAvaPointDepositIsSuccess,
    },
  ] = useSetAvaPointDepositMutation();

  useEffect(() => {
    if (isSuccess) {
      dispatch(setAccountProfile(data));
    }
  }, [data, isSuccess, isFetching]);

  const isValid =
    // systemToken.trim() !== "" &&
    depositToken.trim() !== "" &&
    // txid.trim() !== "" &&
    txidChecked;

  useEffect(() => {
    if (setAvaPointDepositIsSuccess) {
      if (txid === "") {
        // 多國語言
        const title = LangString("alert_update_success", "Update successful");
        const confirmText = LangString("confirm", "Confirm");

        SuccessMySwal({
          title: title,
          showCancelButton: false,
          confirmButtonText: confirmText,
        }).then((result) => {
          if (result.isConfirmed) {
            handleCancel();
          }
        });
      } else {
        // 多國語言
        const title = LangString("alert_deposit_success", "Deposit successful");
        const confirmText = LangString("confirm", "Confirm");
        SuccessMySwal({
          title: title,
          showCancelButton: false,
          confirmButtonText: confirmText,
        }).then((result) => {
          if (result.isConfirmed) {
            refetch();
            _refetchAlgo();
            handleCancel();
          }
        });
      }
    }

    if (setAvaPointDepositIsError) {
      ErrorMySwal({
        title:
          setAvaPointDepositError?.data?.ResultLanguages[getLanguage()] ||
          setAvaPointDepositError?.data?.Message,
      }).then((result) => {
        if (result.isConfirmed) {
          handleCancel();
        }
      });
    }
  }, [setAvaPointDepositData, setAvaPointDepositIsError]);

  const handleDepositAVAPoint = async () => {
    await _setAvaPointDeposit({
      body: {
        Amount: depositToken,
        Txid: txid,
        Address: depositTokenData[0].Address,
      },
    });
    // const amount = depositToken;
    // const TXID = txid;

    // if (amount === "") return MySwal.fire("請輸入儲值金額");
    // const res = await API_DepositAVAPoint({ mode: "Insert", amount, TXID });
    // // if (res.ResultCode !== 0) return Swal.fire(res.Message);
    // if (res.ResultCode === 999) return console.error(res.Message);
    // if (res.ResultCode !== 0) return MySwal.fire(res.Message);
    // setData(res);
    // return MySwal.fire({
    //   icon: "success",
    //   title: "Success!",
    // });
  };

  const handleCancel = () => {
    // 請除url query
    searchParams.delete("deposit");
    setSearchParams(searchParams);
    setOpen(false);
  };

  const CopyResString = LangString("alert_copy", "Copy successful");

  const handleCopy = () => {
    navigator.clipboard.writeText(depositTokenData[0]?.Address).then(() => {
      AlertMySwal({
        title: CopyResString,
        showConfimButton: false,
      });
    });
  };

  return (
    <Wrapper>
      <Container>
        <Title>
          <Lang datakey="deposit" defaultText="Deposit" />
        </Title>

        <InputWrapper>
          <SubTitle>
            <Lang datakey="system_token" defaultText="System Token" /> (
            {depositTokenData[0]?.Asset} / {depositTokenData[0]?.Crypto})
          </SubTitle>
          <SystemTokenContainer>
            <SystemToken>{depositTokenData[0]?.Address} </SystemToken>
            <CopyIconWrapper onClick={handleCopy}>
              <Icons.Copy />
            </CopyIconWrapper>
          </SystemTokenContainer>
        </InputWrapper>
        <QrcodeContainer>
          <QRCodeCanvas
            id="qrcode"
            value={depositTokenData[0]?.Address}
            bgColor={"transparent"}
          />
        </QrcodeContainer>
        <InputWrapper>
          <SubTitle>USDT</SubTitle>
          <Input
            defaultValue=""
            onChange={(e) =>
              setDepositToken(e.target.value.replace(/[^0-9.]/g, ""))
            }
            value={depositToken}
          ></Input>
        </InputWrapper>
        <InputWrapper>
          <SubTitle>TXID</SubTitle>
          <Input
            defaultValue=""
            onChange={(e) => setTxid(e.target.value)}
          ></Input>
        </InputWrapper>
        <CheckboxWrapper>
          <CheckBox
            type="checkbox"
            onChange={(e) => setTxidChecked(e.target.checked)}
          ></CheckBox>
          <CheckboxContainer>
            <Lang
              datakey="deposit_txidpolicy"
              defaultText="I am aware that the filled TXID is correct and understand that providing incorrect information may cause accounting delays."
            />
          </CheckboxContainer>
        </CheckboxWrapper>

        <ButtonWrapper>
          <Button onClickFun={handleCancel} type={"cancel"} />
          {/* <Button onClick={handleCancel}>
            <ButtonText>
              <Lang datakey="cancel" defaultText="Cancel" />
            </ButtonText>
          </Button> */}

          <Button
            onClickFun={handleDepositAVAPoint}
            type={"confirm"}
            disabled={!isValid}
          />
          {/* <Button
            type={"confirm"}
            disabled={!isValid}
            onClick={handleDepositAVAPoint}
          >
            <ButtonText>
              <Lang datakey="confirm" defaultText="Confirm" />
            </ButtonText>
          </Button> */}
        </ButtonWrapper>
      </Container>
    </Wrapper>
  );
};

export default Deposit;

// export default function Deposit({ open, setOpen }) {
//   const handleClose = () => {
//     setOpen(false);
//   };

//   return (
//     <Dialog open={open} onClose={handleClose}>
//       <Container>
//         <DialogTitle>Deposit</DialogTitle>
//         <DialogContent>
//           <SystemTokenContainer>
//             <TextField
//               fullWidth
//               margin="dense"
//               variant="standard"
//               label="System Token (USDT/TRC20)"
//               defaultValue="TVLkHDJmgDJ4Wn7DjFpLMMrmDnQS42owzL"
//               InputProps={{
//                 readOnly: true,
//               }}
//             />
//           </SystemTokenContainer>
//           <QrcodeContainer>
//             <QRCodeCanvas
//               id="qrcode"
//               className="hidden md:block"
//               size="100"
//               value="TVLkHDJmgDJ4Wn7DjFpLMMrmDnQS42owzL"
//               bgColor={"transparent"}
//             />
//           </QrcodeContainer>
//           <TextField
//             autoFocus
//             margin="dense"
//             id="name"
//             label="Deposit Token"
//             type="number"
//             fullWidth
//             variant="standard"
//           />
//           <TextField
//             margin="dense"
//             id="name"
//             label="TXID"
//             type="text"
//             fullWidth
//             variant="standard"
//           />
//           <CheckboxContainer>
//             <CheckboxWrapper>
//               <input id="agreement" type="checkbox" />
//             </CheckboxWrapper>
//             <label htmlFor="agreement">
//               我知道填入的TXID是正確的，並清楚了解如果填入錯誤資訊可能會導致帳務延宕。
//             </label>
//           </CheckboxContainer>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose}>Cancel</Button>
//           <Button variant="contained" onClick={handleClose}>
//             Deposit
//           </Button>
//         </DialogActions>
//       </Container>
//     </Dialog>
//   );
// }
