import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accountProfile: {},
  accountAlgo: [],
  accountApikey: [],
  accountBalance: [],
};

// Config slice
export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setAccountProfile: (state, action) => {
      state.accountProfile = action.payload?.Profile;
    },
    setAccountAlgo: (state, action) => {
      state.accountAlgo = action.payload?.Algos;
    },
    clearAccount: (state, action) => {
      state.accountProfile = null;
      state.accountAlgo = null;
    },

    addAccountApikey: (state, action) => {
      state.accountApikey = action.payload?.Apikeys;
    },

    setAccountBalance: (state, action) => {
      state.accountBalance = action?.payload;
    },
  },
});

// Export actions
export const {
  setAccountProfile,
  setAccountAlgo,
  clearAccount,
  addAccountApikey,
  setAccountBalance,
} = accountSlice.actions;

// Export select
export const selectAccount = (state) => state.account;

// Export reducer
export default accountSlice.reducer;
