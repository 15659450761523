import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    account: null,
    chainId: null,
  };

const metamaskSlice = createSlice({
    name: "metamask",
    initialState,
    reducers: {
        setAccountAddress: (state, action) => {
        state.account = action.payload;
    },
        setChainId: (state, action) => {
        state.chainId = action.payload;
    },
    },
});

export const { setAccountAddress, setChainId } = metamaskSlice.actions;

export const selectMetaMaskAccount = (state) => state.metamask.account;
export const selectMetaMaskChainId = (state) => state.metamask.chainId;

export default metamaskSlice.reducer;