import axios from "axios";
import CryptoJS from "crypto-js";

// bybit test url = https://api-testnet.bybit.com

// https://api.bybit.com

const binanceBaseURL = "https://fapi.binance.com";
const binanceRequest = axios.create({
  baseURL: `${binanceBaseURL}`,
});

const bybitBaseURL = "https://api.bybit.com";
const bybitRequest = axios.create({
  baseURL: `${bybitBaseURL}`,
});

// const igBaseURL = "https://api.ig.com/gateway/deal"; // 正式
const igBaseURL = "https://demo-api.ig.com/gateway/deal"; // 測試
const igRequest = axios.create({
  baseURL: `${igBaseURL}`,
});
// const igSessionURL = 'https://demo-api.ig.com/gateway/deal/session'  token過期 post 他

let binanceTimestamp;
let bybitTimestamp;

export const getBinanceBalance = ({ apikey, secretkey }) =>
  binanceRequest
    .get("https://api.binance.com/api/v3/time")
    .then((res) => {
      binanceTimestamp = res.data.serverTime;
    })
    .then(() => {
      const config = {
        headers: {
          "X-MBX-APIKEY": apikey,
        },
      };
      const dataQueryString = `timestamp=${binanceTimestamp}`;
      const signature = CryptoJS.HmacSHA256(
        dataQueryString,
        CryptoJS.enc.Utf8.parse(secretkey)
      ).toString();

      const url = `https://fapi.binance.com/fapi/v2/balance?${dataQueryString}&signature=${signature}`;

      return axios.get(url, config);
    });

export const getBybitBalance = ({ apikey, secretkey }) =>
  bybitRequest
    .get("/v3/public/time")
    .then((res) => {
      bybitTimestamp = res.data.time;
    })
    .then(() => {
      const dataQueryString =
        bybitTimestamp + apikey + "accountType=CONTRACT&coin=USDT";
      const signature = CryptoJS.HmacSHA256(
        dataQueryString,
        CryptoJS.enc.Utf8.parse(secretkey)
      ).toString();
      const config = {
        headers: {
          "X-BAPI-API-KEY": apikey,
          "X-BAPI-TIMESTAMP": bybitTimestamp,
          "X-BAPI-SIGN": signature,
        },
      };
      const url = `${bybitBaseURL}/v5/account/wallet-balance?accountType=CONTRACT&coin=USDT`;
      return axios.get(url, config);
    });

export const getTestBybitBalance = ({ apikey, secretkey }) =>
  bybitRequest
    .get("/v3/public/time")
    .then((res) => {
      bybitTimestamp = res.data.time;
    })
    .then(() => {
      const dataQueryString =
        bybitTimestamp + apikey + "accountType=CONTRACT&coin=USDT";
      const signature = CryptoJS.HmacSHA256(
        dataQueryString,
        CryptoJS.enc.Utf8.parse(secretkey)
      ).toString();
      const config = {
        headers: {
          "X-BAPI-API-KEY": apikey,
          "X-BAPI-TIMESTAMP": bybitTimestamp,
          "X-BAPI-SIGN": signature,
        },
      };
      const url =
        "https://api-testnet.bybit.com/v5/account/wallet-balance?accountType=CONTRACT&coin=USDT";
      return axios.get(url, config);
    });

export const getIgBalance = ({ apikey, secretkey }) =>
  igRequest.get("/accounts", {
    headers: {
      "X-IG-API-KEY": apikey,
      VERSION: 1,
      CST: secretkey.split("|")[0],
      "X-SECURITY-TOKEN": secretkey.split("|")[1],
    },
  });

export const refreshIgToken = ({ apikey, secretkey, account, password }) => {
  const config = {
    headers: {
      "X-IG-API-KEY": apikey,
      VERSION: 1,
    },
  };
  const url = `${igBaseURL}/session`;
  const data = {
    identifier: account,
    password: password,
    encryptedPassword: null,
  };
  return axios.post(url, data, config);
};
