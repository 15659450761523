import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { API_GetRobotDetail ,API_EditGridRobotParams ,API_IsActiveGridRobot,API_StopGridRobot} from '../../../utils/api';
import UseSwal from '../../hooks/Lang'; 

//#region 

const Container = styled.div`
    padding: 1rem;
    background: #fffa;
    position: absolute;

    top : 0;
    right : 0;
    bottom : 0;
    transition: 500ms;
    transform: translateX(100%);

    &.show {
        transition: 500ms;
        transform: translate(0);
    }


    *::-webkit-scrollbar {
        display: none;
    }
    
    input, select, textarea {
        border: 1px solid #48489180;
        border-radius: 4px;
        background: #fffa;
        outline: none;

        &:focus, &:hover {
        border: 1px solid #484891;
        }
    }

    input:read-only {
        border: 1px solid #ccc;
        background: #eee5;
        color: #0005;
        pointer-events: none;
    }

    input[type='checkbox'] {
        accent-color: #7373B9;
    }

    button {
        background: #484891d0;
        color: #fff;
        border: 1px solid #484891d0;
        padding: .5rem .8rem;
        border-radius: 4px;
        transition: 200ms;
        box-shadow: 3px 3px 8px 0px #0003;

        &:hover {
        background: #484891bb;
        }
    }

    button.outline {
        color: #484891d0;
        background: #fff;
        border: 1px solid #484891d0;
        outline: none;
        box-shadow: none;
        &:hover {
        background: #48489130;
        }
    }

    @media screen and (min-width: 1024px) {
        padding: 2rem 4rem;
    }
`;

const DetailContainer = styled.div`
    width: 100%;
    z-index: 2;
    padding: 2rem;
    background: #fffa;
    box-shadow: -5px 5px 10px 0px #0003;
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;

`;

const DetailWrapper = styled.div`
  flex: 1;
  overflow: auto;
`;

const ToggleRobot = styled.label`
  width: 3.5rem;
  height: 2rem;
  border-radius: 500px;
  background: #ccca;
  display: flex;
  align-items: center;
  padding: 0 .25rem;
  cursor: pointer;

  &.enabled {
    background: #484891a0;
    
    ::before {
      transform: translateX(1.5rem);
      box-shadow: -3px 0px 8px 0px #0003;
    }
  }

  &::before {
    content: "";
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100%;
    background: #fff;
    box-shadow: 3px 0px 8px 0px #0003;
    transition: 200ms;
  }

  &.grayed {
    transition: background-color 0.3s, opacity 0.3s;
    cursor: not-allowed; 
  }
`;

const EntrustContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column-reverse;

    @media screen and (min-width: 1024px){
    flex-direction: row;

        > *:last-child {
            flex: 1;
        }
    }
`;

const InputBoxConatiner = styled.div``;

const InputDiv = styled.div``;

const Input = styled.input``;

const SideStatusSelect = styled.select``;

const SideStatusOption = styled.option``;

const DetailItem = styled.div`
  padding: .5rem;

  > *:first-child {
    font-weight: 700;
    color: #484891;
    min-width: 10rem;
  }
  > *:last-child {
    flex: 1;
  }

  input, select, textarea {
    width: 100%;
    padding: .2rem .5rem;
  }

  textarea {
    height: 7rem;
    width: 100%;

    @media screen and (min-width: 1024){
      min-width: 30rem;
    }
  }

  @media screen and (min-width: 1024px){
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
  }
`;

const EntrustTableContainer = styled.div`
  padding: 1rem;
`;

const TableWapperBase = styled.div`
    width: 100%;
    margin-bottom: 1rem;
    overflow: auto;
    box-shadow: -5px 5px 10px 0px #0003;
`;

const TableWrapper = styled(TableWapperBase)``;

const TableLogWrapper = styled(TableWapperBase)`
    height: 500px;
`;

const Table = styled.table`
  width: 100%;
  border: 1px solid #F3F3FA;
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
`;

const Thead = styled.thead`
    background: #A6A6D2;
`;

const TheadTr = styled.tr``

const Tbody = styled.tbody`
    tr, th, td {
        border: 1px solid #F3F3FA;
    }
    th, td {
        padding: .3rem .5rem;
    }
`;

const TbodyTr = styled.tr`
    border: 1px solid #F3F3FA;
    &.selected {
        background: #C7C7E2;
    }
    &:hover {
    background: #C7C7E2;
    }
`;

const Th = styled.th``;

const Td = styled.td``;


const OperationBtnContainer = styled.div`
    text-align: center;
`;

const SaveBtn = styled.button`
    margin: 0 1rem;
`;

const RunningBtn = styled.button`
    border: none;
    padding: 10px 20px;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, opacity 0.3s;
    color: white;
    opacity: 0.5; 
    cursor: not-allowed; 
`;

const CannelBtn = styled.button``;

const StopGridRobotRunningBtn = styled.button`
    margin: 0 1rem;
    background: #e72a4d !important;
`;

//#endregion

const EditGridRobot = ({ robot, close , onDataUpdate }) => {
    const oldParams = robot.GridRobotParams;
    const [isAvailable, setIsAvailable] = useState(robot.GridRobotParams.GridRobotStatus === 2 ? false : true);
    const [editedRobot, setEditedRobot] = useState({ ...robot });
    const [sideStatus, setSideStatus] = useState("");
    const [updateLog , setUpdateLog] = useState([]);
    const [gridRobotDetail , setGridRobotDetail] = useState([]);
    const [newGridRobotStatus , setNewGridRobotStatus] = useState(robot.GridRobotParams.GridRobotStatus);

    useEffect(() => {
        const getRobotDetail = async () => {
            const res = await API_GetRobotDetail(robot.GridRobotId);
            if (res) {
                setGridRobotDetail(res)
                setUpdateLog(res.Log)
            }
        }; 
        getRobotDetail();
    },[])

    const toggleRobot = (e) => 
    {
        const newIsAvailable = e.target.checked;
        setIsAvailable(newIsAvailable)

        const updatedRobotStatus = newIsAvailable == false ? 2 : 1 ;
        
        const updatedRobotParams = {
            GridRobotId: robot.GridRobotId,
            AIRobotId : robot.AIRobotId,
            GridRobotParams: {
                ...robot.GridRobotParams,
                GridRobotStatus: updatedRobotStatus,
            },
            
        };

        const changeStatus = async () => {
            const body = robot.GridRobotId;
            try {
                const res = await API_IsActiveGridRobot(JSON.stringify(body));
                if (res.ResultCode === 999) {
                    return UseSwal(res.Message);
                } else if (res.ResultCode !== 0) {
                    return UseSwal(res.Message);
                } else {
                    UseSwal("更新成功!");
                    onDataUpdate(updatedRobotParams);
                    setNewGridRobotStatus(updatedRobotStatus)
                }
            } catch (error) {
                console.error('更新狀態發生錯誤:', error);
                UseSwal("更新失敗!");
            }
        };
        changeStatus()
        


    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditedRobot(prevState => ({
            ...prevState,
            GridRobotParams: {
                ...prevState.GridRobotParams,
                [name]: value,
            },
        })); 
    };

    const handleSaveAsync = async () => {
        const payload = {
            GridRobotId: robot.GridRobotId,
            OldParams: {...oldParams},
            NewParams: {
                        ...editedRobot.GridRobotParams,
                        GridRobotStatus: newGridRobotStatus
                    },
        };
        
        const resquest = await API_EditGridRobotParams(JSON.stringify(payload));
        if (resquest.ResultCode === 999) return UseSwal(resquest.Message);
        
        if (resquest.ResultCode !== 0) return UseSwal("更新失敗");

        UseSwal("更新成功!");
        const returndata ={
            GridRobotId: robot.GridRobotId,
            AIRobotId : editedRobot.AIRobotId,
            GridRobotParams: {
                ...editedRobot.GridRobotParams,
                GridRobotStatus: newGridRobotStatus,
            },
        }

        onDataUpdate(returndata);
        isAvailable(payload.NewParams.GridRobotStatus === 3 ? false : true);
    }

    const handleStopGridRobotRunning = async () => {
        const body = robot.GridRobotId;
        try{
            const res = await API_StopGridRobot(JSON.stringify(body));
            if (res.ResultCode === 999) {
                return UseSwal(res.Message);
            } else if (res.ResultCode !== 0) {
                return UseSwal(res.Message);
            } else {
                UseSwal("停止成功!");
                setNewGridRobotStatus(1)
                const updatedRobotParams = {
                    GridRobotId: robot.GridRobotId,
                    AIRobotId : robot.AIRobotId,
                    GridRobotParams: {
                        ...robot.GridRobotParams,
                        GridRobotStatus: 1,
                    },
                };

                setIsAvailable(true)
                onDataUpdate(updatedRobotParams);
            }
        }
        catch(error){
            console.error('更新狀態發生錯誤:', error);
            UseSwal("更新失敗!");
        }
    }
    
    return (
        <Container className={robot ? "show" : ""}>
            <DetailContainer>
                <DetailWrapper>
                    <DetailItem>
                        <InputDiv>啟用</InputDiv>
                        <Input
                            id="isAvailable"
                            type="checkbox"
                            checked = {isAvailable}
                            onChange={toggleRobot}
                            disabled={newGridRobotStatus === 3}
                            hidden
                        />
                        <InputDiv>
                            <ToggleRobot
                                htmlFor='isAvailable'
                                className={`
                                            ${isAvailable ? "enabled" : ""} 
                                            ${newGridRobotStatus === 3 ? "grayed" : ""}
                                `}
                            />
                        </InputDiv>
                    </DetailItem>
                    <EntrustContainer>
                        <InputBoxConatiner>
                            <DetailItem>
                                <InputDiv>機器人ID</InputDiv>
                                <Input value={editedRobot.GridRobotId ?? ""} readOnly />
                            </DetailItem>
                            <DetailItem>
                                <InputDiv>機器人對應Algo</InputDiv>
                                <Input value={editedRobot.AIRobotId ?? ""} readOnly />
                            </DetailItem>
                            <DetailItem>
                                <InputDiv>機器人幣種</InputDiv>
                                <Input 
                                    name = "TragetKey"
                                    value={editedRobot.GridRobotParams.TargetKey ?? ""}
                                    onChange={handleChange} 
                                />
                            </DetailItem>
                            <DetailItem>
                                <InputDiv>下單方向限制</InputDiv>
                                <SideStatusSelect 
                                    name="SideStatus" 
                                    value={sideStatus} 
                                    onChange={(e) => {
                                        const { name, value } = e.target;
                                        setSideStatus(value);
                                        setEditedRobot(prevState => ({
                                        ...prevState,
                                        GridRobotParams: {
                                            ...prevState.GridRobotParams,
                                            [name]: value,
                                        },
                                        }));
                                    }}
                                    >
                                    <SideStatusOption value="All">不限制</SideStatusOption>
                                    <SideStatusOption value="OnlyLong">僅做多</SideStatusOption>
                                    <SideStatusOption value="OnlyShort">僅做空</SideStatusOption>
                                </SideStatusSelect>
                            </DetailItem>
                            <DetailItem>
                                <InputDiv>機器人進場上限</InputDiv>
                                <Input 
                                    name='EntryPrice'
                                    value={editedRobot.GridRobotParams.EntryPrice ?? ""} 
                                    onChange={handleChange} 
                                />
                            </DetailItem>
                            <DetailItem>
                                <InputDiv>機器人進場下限</InputDiv>
                                <Input
                                    name='ExitPrice' 
                                    value={editedRobot.GridRobotParams.ExitPrice ?? ""} 
                                    onChange={handleChange} 
                                />
                            </DetailItem>
                            <DetailItem>
                                <InputDiv>機器人網格數量</InputDiv>
                                <Input
                                    name='GridCount'
                                    type="number"
                                    value={editedRobot.GridRobotParams.GridCount ?? ""} 
                                    onChange={handleChange} 
                                />
                            </DetailItem>
                            <DetailItem>
                                <InputDiv>機器人止盈金額</InputDiv>
                                <Input 
                                    name='TakeProfitPrice'
                                    value={editedRobot.GridRobotParams.TakeProfitPrice ?? ""} 
                                    onChange={handleChange} 
                                />
                            </DetailItem>
                            <DetailItem>
                                <InputDiv>機器人止損金額</InputDiv>
                                <Input
                                    name='StopLossPrice' 
                                    value={editedRobot.GridRobotParams.StopLossPrice ?? ""} 
                                    onChange={handleChange} 
                                />
                            </DetailItem>
                        </InputBoxConatiner>
                        <EntrustTableContainer>
                            <TableWrapper>
                                <Table>
                                    <Thead>
                                        <TheadTr>
                                            <Th>機器人創建時間</Th>
                                            <Th>目前倉位方向</Th>
                                            <Th>倉位</Th>
                                        </TheadTr>
                                    </Thead>
                                    <Tbody>
                                        <TbodyTr>
                                            <Td>{gridRobotDetail.CreateTime}</Td>
                                            <Td>{gridRobotDetail.PositionSide}</Td>
                                            {
                                                gridRobotDetail.OrderNumber !== 0 
                                                ? (gridRobotDetail.OrderNumber<0
                                                    ? (<Td>做空 {Math.abs(gridRobotDetail.OrderNumber)}次</Td>)
                                                    :(<Td>做多 {gridRobotDetail.OrderNumber}次</Td>) ) 
                                                : (<Td>目前無開倉</Td>)
                                            }
                                        </TbodyTr>
                                    </Tbody>
                                </Table>
                            </TableWrapper>
                            <TableLogWrapper>
                                <Table>
                                    <Thead>
                                        <TheadTr>
                                            <Th>修改人</Th>
                                            <Th>更新時間</Th>
                                        </TheadTr>
                                    </Thead>
                                    <Tbody>
                                        {updateLog.map((log, index) => (
                                            <TbodyTr key={index}>
                                                <Td>{log.AccountId}</Td>
                                                <Td>{log.UpdateTime}</Td>
                                            </TbodyTr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </TableLogWrapper>
                        </EntrustTableContainer>
                    </EntrustContainer>
                    <OperationBtnContainer>
                        {
                            newGridRobotStatus === 3 
                            ? (<RunningBtn>運行中</RunningBtn>) 
                            :(<SaveBtn onClick={handleSaveAsync}>儲存</SaveBtn>)
                        }
                        <CannelBtn onClick={close}>取消</CannelBtn>
                        {
                            newGridRobotStatus === 3 
                            ? <StopGridRobotRunningBtn  onClick={() =>handleStopGridRobotRunning(editedRobot.GridRobotId)}>停止運行</StopGridRobotRunningBtn>
                            : <></>
                        }
                    </OperationBtnContainer>
                </DetailWrapper>                        
            </DetailContainer>
        </Container>
    );
  
}

export default EditGridRobot
