import styled, { css } from "styled-components";

import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";

import { useEffect, useState } from "react";
import parse from "html-react-parser";
import { getLanguage } from "../../services/common";
import { useSelector } from "react-redux";
import { selectSystem } from "../../slices/systemSlice";

import Step_1 from "../../images/withdrawalGuide/Step_1.jpg";
import Step_2 from "../../images/withdrawalGuide/Step_2.jpg";
import Step_3 from "../../images/withdrawalGuide/Step_3.jpg";
import Step_4_1 from "../../images/withdrawalGuide/Step_4-1.jpg";
import Step_4_2 from "../../images/withdrawalGuide/Step_4-2.jpg";
import Step_4_3 from "../../images/withdrawalGuide/Step_4-3.jpg";

import { useOutletContext } from "react-router-dom";

const Wrapper = styled.div`
  height: 100%;
  padding-bottom: 100px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 20px;
`;

const Title = styled.div`
  font-size: min(max(1.25vw, 22px), 24px);
  color: #fcfcfc;
  padding-right: 10px;
  text-transform: capitalize;

  ${RWD_MD(css`
    font-size: min(max(1.145vw, 20px), 22px);
  `)};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const InstructionsWrapper = styled.div`
  /* text-align: left; */
  padding-bottom: 20px;
  color: #d3d3d3;
`;

const StepWrapper = styled.div`
  width: 100%;
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #22254a;
  margin-bottom: 20px;
`;

const StepBox = styled.div`
  padding: 30px 40px;
  width: 100%;
`;

const StepNumber = styled.div`
  font-size: min(max(2.5vw, 40px), 48px);
  color: #0aecd1;
  padding-right: 10px;
`;

const StepNumberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const StepNumberContainer = styled.div`
  font-size: 13px;
  position: absolute;
  left: 0;
  top: -10px;
  color: rgba(10, 236, 209, 0.5);
`;

const StepImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
`;

const StepImg = styled.div`
  display: flex;
  background-color: #fcfcfc;
  padding: 10px;

  img {
    width: 100%;
  }
`;

const StepImgDescription = styled.div`
  font-size: 15px;
  padding-top: 20px;
  color: #54587b;
`;

const StepTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: baseline;
  border-bottom: 2px solid #3a3d5a;
  font-family: Montserrat;
  color: #fcfcfc;
`;

const StepNote = styled.div`
  padding: 10px 0;
  color: #d3d3d3;
`;

const StepRemark = styled.div``;

const WithdrawalGuide = () => {
  // 關閉Onboarding
  const { onboardingStatus } = useOutletContext();
  onboardingStatus[0]();

  const lang = useSelector(selectSystem).language;
  const [language, setLanguage] = useState("");

  useEffect(() => {
    console.log(lang);
    setLanguage(lang);
  }, [lang]);
  const TitleData = [
    {
      id: 1,
      en: "How to Bind a Withdrawal Address through Bybit.com",
      ch: "如何透過 Bybit.com 綁定Withdrawal地址",
    },
  ];

  const instructionsData = [
    {
      id: 1,
      en: "You can now use your Bybit.com exchange to bind your withdrawal address on the AVA platform. Below are the steps for binding, which you can complete in a few minutes.",
      ch: "您現在可以使用您的Bybit.com交易所於AVA平台綁定您的提領地址。下面是綁定的步驟，幾分鐘內您就可以完成綁定操作。",
    },
  ];

  const stepData = [
    {
      id: 1,
      title: [
        {
          en: 'Go to bybit.com (exchange), find and click the "Deposit" (1) button at the top of the page',
          ch: '進入 bybit.com（交易所），在頁面頂端找到並點擊 "儲值" （1）按鈕',
        },
      ],

      note: [
        {
          en: "",
          ch: "",
        },
      ],
      imgData: [
        {
          img: Step_1,
          imgDescription: [
            {
              en: "",
              ch: "",
            },
          ],
        },
      ],

      remark: "",
    },
    {
      id: 2,
      title: [
        {
          en: 'For the coin to deposit, select "USDT" (2), choose the USDT-BSC (BEP20) chain (3), and click the button to acknowledge (4)',
          ch: '儲值的貨幣選擇 "USDT" （2），鏈選擇USDT-BSC（BEP20）（3），並且點擊已知曉的按鈕（4）',
        },
      ],
      note: "",
      imgData: [
        {
          img: Step_2,
          imgDescription: [
            {
              en: "Be careful in selecting the currency and chain",
              ch: "仔細選擇幣種以及鏈",
            },
          ],
        },
      ],
      remark: "",
    },
    {
      id: 3,
      title: [
        {
          en: "Copy the USDT address (5) to a text file",
          ch: "將USDT地址複製（5）到一個文本文件中",
        },
      ],
      note: "",
      imgData: [
        {
          img: Step_3,
          imgDescription: "",
        },
      ],
      remark: "",
    },
    {
      id: 4,
      title: [
        {
          en: 'Now go to the "Settings" section of your AVA account',
          ch: '現在轉到AVA帳戶的 "設置" 部分',
        },
      ],
      note: [
        {
          en: 'In the "Receiving Address" section, select "Withdrawal". After verifying through a verification code, paste the "USDT Address" you copied into the text file here. Then click "Confirm"',
          ch: '在 "收款地址" 部分選擇 "Withdrawal"，在通過驗證碼驗證之後，將您剛剛在文本文件中複製的 "USDT地址" 貼至此處。然後點擊 "確定"',
        },
      ],
      imgData: [
        {
          img: Step_4_1,
          imgDescription: "",
        },
        {
          img: Step_4_2,
          imgDescription: "",
        },
      ],
      remark: "",
    },
    {
      id: 5,
      title: [
        {
          en: "If everything goes smoothly, you will see that your Withdrawal address has been added",
          ch: "如果一切順利，您將看到您的Withdrawal地址已添加",
        },
      ],
      note: "",
      imgData: [
        {
          img: Step_4_3,
          imgDescription: [
            {
              en: "Withdrawal successfully added. Congratulations!",
              ch: "Withdrawal 已添加成功。恭喜！",
            },
          ],
        },
      ],
      remark: "",
    },
  ];

  return (
    <Wrapper>
      <Container>
        <TitleWrapper>
          <Title>{TitleData[0]?.[language?.toLocaleLowerCase()]}</Title>
        </TitleWrapper>

        <ContentWrapper>
          {instructionsData.map((data) => (
            <InstructionsWrapper>
              <p>{data?.[language?.toLocaleLowerCase()]}</p>
            </InstructionsWrapper>
          ))}

          <StepWrapper>
            {stepData.map((data) => (
              <StepContainer>
                <StepBox>
                  <StepTitle>
                    <StepNumberWrapper>
                      <StepNumberContainer>STEP</StepNumberContainer>
                      <StepNumber>
                        {String(data.id).padStart(2, "0")}
                      </StepNumber>
                    </StepNumberWrapper>

                    <p>{data.title[0]?.[language?.toLocaleLowerCase()]}</p>
                  </StepTitle>
                  <StepNote>
                    {data.note?.[0]?.[language?.toLocaleLowerCase()]}
                  </StepNote>
                  {data.imgData.map((img) => (
                    <StepImgContainer>
                      <StepImg>
                        <img src={img.img} />
                      </StepImg>
                      <StepImgDescription>
                        {
                          img.imgDescription?.[0]?.[
                            language?.toLocaleLowerCase()
                          ]
                        }
                      </StepImgDescription>
                    </StepImgContainer>
                  ))}
                  <StepRemark>
                    {data.remark?.[0]?.[language?.toLocaleLowerCase()]}
                  </StepRemark>
                </StepBox>
              </StepContainer>
            ))}
          </StepWrapper>
        </ContentWrapper>
      </Container>
    </Wrapper>
  );
};

export default WithdrawalGuide;
