import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { API_SubscribeInfoBot } from '../../../utils/api';
import { verifications } from '../../../utils/verifications';
import { routes } from '../../Router';

import { StyledCard } from '../../Global/Ui';

import Swal from 'sweetalert2/dist/sweetalert2';

const InformationBot = ({ data, setData, prices, isLanding=true }) => {
  const handleSubscribe = async (robotId, setIsSubscribe) => {
    if (!data.hasLogin) return navigate(routes.login);

    const res = await API_SubscribeInfoBot({
      InfoBotID: robotId,
      isSubscribe: setIsSubscribe,
    });

    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return Swal.fire(res.Message);

    Swal.fire({
      title: 'Success!',
      icon: 'success'
    }).then(() => {
      setData({
        ...data,
        infoBotDatas: res.infoBotDatas ?? [],
      });
    });
  };
  const checkIsReallyUnsubscribe = (robotId, setIsSubscribe) => {
    Swal.fire({
      title: 'Do you really want to unsubscribe?',
      icon: 'info',
      confirmButtonText: 'Yes',
      showCancelButton: true,
    }).then(result => {
        if (result.isConfirmed) {
          handleSubscribe(robotId, setIsSubscribe);
        }
    });
  };

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!data || !setData || !prices) return;
    setLoading(true);
  }, [data, setData, prices]);

  return (
    <div>
      <h3 className='py-2'>Information Bot</h3>
      <div className='flex flex-wrap'>
        {
          data?.infoBotDatas
          // .filter(({ isSubscribe }) => isSubscribe)
          ?.map(({ info_robot_id, robot_name_en, status, target, open_price, isSubscribe }) => {
            let profitRate;
            if (!!open_price) {
              const profit = prices[target] - open_price;
              profitRate = (profit / open_price) * 100;

              if (status.toLowerCase() === 'openshort') profitRate *= -1;

              profitRate = 
              <span className={profitRate > 0 ? 'text-pass' : 'text-fail'}>
                {profitRate > 0 && '+'}{profitRate.toFixed(2).replace(verifications.currency, ',')} %
              </span>
            }
            else {
              profitRate = <span>N/A</span>;
            }

            return (
              <div
                className={
                  isLanding
                    ? 'w-full sm:w-1/2 md:w-1/3 xl:w-1/5 p-4 cursor-default'
                    : 'w-full sm:w-1/2 xl:w-1/4 2xl:w-1/5 p-4 cursor-default'
                }
                key={info_robot_id}
              >
                <StyledCard
                  title={robot_name_en}
                  titleClass='text-center text-[1.1rem] font-bold'
                >
                  <div className='text-[.8rem] flex flex-col'>
                    {/* status */}
                    <div className='leading-[2.5] flex-1'>
                      <span className='text-sub'>Status: </span>
                      <span className={`capitialize ${status.toLowerCase() === 'analyse' ? 'text-primary' : 'text-pass'}`}>
                        {status}
                      </span>
                    </div>

                    {/* target */}
                    <div>
                      <span className='text-sub'>Target: </span>
                      <span className='upper'>
                        {target}
                      </span>
                    </div>

                    {/* Open price */}
                    <div>
                      <span className='text-sub'>OpenPrice: </span>
                      <span className='upper'>
                        {open_price ?? 'N/A'}
                      </span>
                    </div>

                    {/* Current profit rate */}
                    <div>
                      <span className='text-sub'>CurrentProfitRate: </span>
                      <span className='upper'>
                        {/* +3.56% */}
                        {profitRate}
                      </span>
                    </div>
                  </div>

                  {/* <div className='pt-4 text-sub hover:text-primary cursor-pointer text-center'>
                    Read More
                  </div> */}
                  {
                    isSubscribe
                    ? <div
                        className='pt-4 text-center text-sub hover:text-[#aaa] cursor-pointer'
                        onClick={() => checkIsReallyUnsubscribe(info_robot_id, false)}
                      >
                        Unsubscribe
                      </div>
                    : <div
                        className='pt-4 text-center text-blue hover:text-primary cursor-pointer'
                        onClick={() => handleSubscribe(info_robot_id, true)}
                      >
                        Subscribe
                      </div>
                  }
                </StyledCard>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default InformationBot
