import styled, { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";

import { Select } from "../../../global/Selects";
import Lang from "../../../global/Lang";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 45vh;
`;

const Title = styled.div`
  font-size: min(max(1.25vw, 22px), 24px);
  color: #fcfcfc;
  padding-bottom: 20px;

  ${RWD_MD(css`
    font-size: min(max(1.0416vw, 18px), 20px);
  `)};
`;

const DescriptionWrapper = styled.div`
  color: #fcfcfc;
  padding: 50px 0 20px 0;
`;

const DescriptionContainer = styled.div``;

const DescriptionTitle = styled.div`
  padding-bottom: 10px;
  font-weight: bold;
`;

const DescriptionContent = styled.div`
  font-size: min(max(0.833vw, 14px), 16px);
`;

const DocumentButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;

  ${RWD_SM(css`
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  `)};
`;

const DocumentButton = styled.div`
  padding: 10px;
  width: 100%;
  cursor: pointer;
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${(props) =>
    props.selectDocument
      ? "1px solid #0AECD1"
      : "1px solid rgba(255, 255, 255, 0.15)"};
`;

const DocumentButtonText = styled.div`
  color: #fcfcfc;
`;

const SelectKycType = ({ setCountry, setkycType, kycType, country }) => {
  return (
    <Wrapper>
      <Title>
        <Lang
          datakey="identity_step_selectdocument"
          defaultText="Select Document Type"
        />
      </Title>

      <Select
        group={"KycCountry"}
        title={
          <Lang
            datakey="kyc_country_of_issue"
            defaultText="Country / Region Of Issue"
          />
        }
        onChangeFun={setCountry}
        nowValue={country}
        placeholder={
          <Lang
            datakey="kyc_country_select"
            defaultText="Please select a country or region"
          />
        }
      />
      <DescriptionWrapper>
        <DescriptionContainer>
          <DescriptionTitle>
            <Lang
              datakey="kyc_valid_document"
              defaultText="Use a valid government-issued document"
            />
          </DescriptionTitle>
          <DescriptionContent>
            <Lang
              datakey="kyc_valid_document_notice"
              defaultText="Only the following documents listed below will be accepted, all
              other documents will be rejected."
            />
          </DescriptionContent>
        </DescriptionContainer>
      </DescriptionWrapper>

      <DocumentButtonWrapper>
        <DocumentButton
          onClick={() => setkycType(1)}
          selectDocument={kycType === 1}
        >
          <DocumentButtonText>
            <Lang
              datakey="kyc_idcard"
              defaultText="Government-Issued ID Card"
            />
          </DocumentButtonText>
        </DocumentButton>
        <DocumentButton
          onClick={() => setkycType(2)}
          selectDocument={kycType === 2}
        >
          <DocumentButtonText>
            <Lang datakey="kyc_passport" defaultText="Passport" />
          </DocumentButtonText>
        </DocumentButton>
      </DocumentButtonWrapper>
    </Wrapper>
  );
};

export default SelectKycType;
