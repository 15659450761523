import styled, { css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../utils/rwd";
import Icons from "../../global/icons";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../components/Router";
import { useSelector } from "react-redux";
import { selectSystem } from "../../slices/systemSlice";

import SettingModal from "../Setting/SettingModal";

import {
  useGetMiningPackageQuery,
  useAddMiningMutation,
} from "../../services/miningApi";

import SelectPackage from "./components/SelectPackage";
import Information from "./components/Information";
import Confirmation from "./components/Confirmation";
import Transfer from "./components/Transfer";

import Lang from "../../global/Lang";

import { handleColorType } from "../../utils/ButtonStyle";

import { SuccessMySwal, ErrorMySwal } from "../../global/MySwal";
import { LangString } from "../../utils";

import { getLanguage } from "../../services/common";

import {
  useSetAccountWithdrawalMutation,
  useGetAccountProfileQuery,
} from "../../services/accountApi";
import { useVerifyWithdrawlMutation } from "../../services/authApi";
import { useDispatch } from "react-redux";
import { setAccountProfile } from "../../slices/accountSlice";

import Loading from "../../components/Loading/Loading";

import { Fragment } from "react";

import { useOutletContext } from "react-router-dom";

const Wrapper = styled.div`
  width: 100%;
  /* min-height: calc(100vh - 80px); */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Title = styled.div`
  font-size: min(max(1.25vw, 22px), 24px);
  color: #fcfcfc;
  padding-right: 10px;
  text-transform: capitalize;
  padding-bottom: 20px;
  font-weight: bold;

  ${RWD_MD(css`
    font-size: min(max(1.145vw, 20px), 22px);
  `)};
`;

const ContentWrapper = styled.div`
  /* padding: 30px 35px; */
  /* height: 100%; */
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */

  /* ${RWD_MD(css`
    padding: 20px 25px;
  `)};

  ${RWD_SM(css`
    padding: 20px 15px;
    margin-bottom: 50px;
  `)}; */
`;

const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 1.5625vw;

  width: 100%;
  overflow: hidden;

  ${RWD_LL(css`
    width: 100%;
    position: absolute;
    top: 0;
    margin-right: 0;
  `)};
`;

const ProgressContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  ${RWD_LL(css`
    width: 100%;
  `)};
`;

const ProgressItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${RWD_LL(css`
    width: 100%;
  `)};
`;

const ProgressItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  ${RWD_LL(css`
    align-items: center;
  `)};

  ::before {
    position: absolute;
    content: "";
    border-bottom: 4px solid;
    border-color: ${(props) => (props.status ? "#0AECD1" : "#3A3D5A")};
    width: 50%;
    top: 15px;
    left: 0;
    z-index: 1;

    /* ${RWD_LL(css`
      width: 50%;
    `)}; */
  }

  ::after {
    position: absolute;
    content: "";
    border-bottom: 4px solid;
    border-color: ${(props) => (props.active ? "#0AECD1" : "#3A3D5A")};
    width: 50%;
    top: 15px;
    right: 0%;
    z-index: 1;

    ${RWD_LL(css`
      width: 50%;
    `)};
  }

  :first-child::before {
    content: none;

    ${RWD_LL(css`
      content: "";
    `)};
  }

  :last-child::after {
    content: none;

    ${RWD_LL(css`
      content: "";
    `)};
  }
`;

const ProgressItemText = styled.div`
  padding-top: 5px;
  /* position: absolute; */
  /* top: 100%; */
  text-align: center;
  /* position: absolute; */
  /* white-space: nowrap; */
  /* transform: translateX(calc(50% - 15px)); */

  font-size: min(max(0.833vw, 12px), 16px);
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  color: ${(props) => (props.status ? "#0AECD1" : "#FCFCFC")};
`;

const ProgressItemNumber = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: ${(props) => (props.status ? "#3A3D5A" : "#FCFCFC")};
  background: ${(props) => (props.status ? "#0AECD1" : "#3A3D5A")};
`;

const StepActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* flex-wrap: wrap; */

  width: 100%;
  background-color: #151944;
  padding-bottom: 3.854vw;
  padding-top: 3.854vw;
  margin-top: auto;
  position: relative;

  ${RWD_LL(css`
    padding: 100px 0 20px 0;
    /* flex-direction: column; */
    /* height: 200px; */
    align-items: flex-end;
  `)};
`;

const ButtonWrapper = styled.div`
  display: flex;
  cursor: pointer;

  button:first-child {
    margin-right: 1.5625vw;
  }
`;

const Button = styled.button`
  border: ${({ type }) => handleColorType(type).border};
  border-radius: 60px;
  width: min(max(7.0833vw, 100px), 135px);
  background: ${({ type }) => handleColorType(type).bg};
  text-align: center;
`;

const ButtonText = styled.span`
  font-size: min(max(1.0416vw, 16px), 20px);
  color: #fcfcfc;
  display: inline-flex;
  padding: 0.52vw 1.3vw;
  ${RWD_LG(css`
    padding: 8px 18px;
    font-size: min(max(0.833vw, 14px), 16px);
  `)};
`;

const NoticeWrapper = styled.div`
  color: #fcfcfc;
  padding: 20px 0;
  opacity: 0.7;
`;

const NoticeContainer = styled.div`
  display: flex;
`;

const NoticeContent = styled.div`
  padding-left: 10px;
  font-size: min(max(0.7291vw, 12px), 14px);
`;

const NoticeIcon = styled.div`
  width: 30px;
  height: 30px;

  svg {
    width: 30px;
    height: 20px;
  }

  & path {
    fill: #fcfcfc;
  }
`;

const AddPackage = () => {
  const lang = useSelector(selectSystem).language;
  // const selectData = useSelector(selectSystem).enums;

  // console.log(selectData);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { addMiningStatus } = useOutletContext();

  const {
    data: miningPackageResponse,
    isSuccess: miningPackageResponseIsSuccess,
    isError: miningPackageResponseIsError,
    error: miningPackageResponseError,
    isLoading: miningPackageResponseIsLoading,
    isFetching: miningPackageResponseIsFetching,
  } = useGetMiningPackageQuery();

  const [
    _addMining,
    {
      data: addMiningResponse,
      isSuccess: addMiningResponseIsSuccess,
      isError: addMiningResponseIsError,
      error: addMiningResponseError,
    },
  ] = useAddMiningMutation();

  const [
    _verifytWithdrawal,
    {
      data: verifytWithdrawalResponse,
      isSuccess: verifytWithdrawalIsSuccess,
      isError: verifytWithdrawallIsError,
      error: verifytWithdrawalError,
    },
  ] = useVerifyWithdrawlMutation();

  const [
    _setAccountWithdrawal,
    {
      data: setAccountWithdrawalResponse,
      isSuccess: setAccountWithdrawalIsSuccess,
      isError: setAccountWithdrawalIsError,
      error: setAccountWithdrawalError,
    },
  ] = useSetAccountWithdrawalMutation();

  // 刷新avapoints
  const {
    data: profileData,
    error: profileError,
    isError: profileIsError,
    isSuccess: profileIsSuccess,
    refetch,
    isFetching,
  } = useGetAccountProfileQuery();

  useEffect(() => {
    if (profileIsSuccess) {
      dispatch(setAccountProfile(profileData));
    }
  }, [profileData, profileIsError, isFetching]);

  useEffect(() => {
    if (addMiningResponseIsSuccess) {
      // 多國語言
      const title = LangString("alert_addition_success", "Addition succeeded");
      const confirmText = LangString("confirm", "Confirm");
      SuccessMySwal({
        title: title,
        showCancelButton: false,
        confirmButtonText: confirmText,
      }).then((result) => {
        if (result.isConfirmed) {
          // 新增成功 才把mining table 的tab改為 waiting
          addMiningStatus[1](true);
          // 如果選擇使用 avapoint 新增 package 刷新 avapoints
          if (useavapoints) {
            refetch();
          }
          navigate(routes.mining_v2);
        }
      });
    }

    if (addMiningResponseIsError) {
      ErrorMySwal({
        title:
          addMiningResponseError?.data?.ResultLanguages[getLanguage()] ||
          addMiningResponseError?.data?.Message,
      }).then((result) => {
        if (result.isConfirmed) {
          if (addMiningResponseError?.data?.ResultCode === 325) {
            handleOpenModal("withdrawalVerify");
          } else {
            navigate(routes.mining_v2);
          }
        }
      });
    }
  }, [addMiningResponse, addMiningResponseIsError]);

  const [modalIsOpne, setModalIsOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [withdrawal, setWithdrawal] = useState("");
  const [withdrawlVerifyCode, setWithdrawalVerifyCode] = useState("");

  const handleUpdateWithdrawal = (data) => {
    switch (data) {
      case "withdrawalVerify":
        handleVerifyWidthdrawl();
        break;
      case "withdrawal":
        handleWithdrawal();
        break;

      default:
        break;
    }
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleOpenModal = (type) => {
    setModalIsOpen(true);
    switch (type) {
      case "withdrawalVerify":
        setModalData({
          type: type,
          value: [withdrawlVerifyCode],
          funList: [setWithdrawalVerifyCode],
        });
        break;
      case "withdrawal":
        setModalData({
          type: type,
          value: [withdrawal],
          funList: [setWithdrawal],
        });
        break;
    }

    return;
  };

  const handleWithdrawal = async () => {
    _setAccountWithdrawal({
      body: {
        WithdrawalAddress: withdrawal,
      },
    });
  };

  const handleVerifyWidthdrawl = async () => {
    _verifytWithdrawal({
      body: {
        VerifyCode: withdrawlVerifyCode,
      },
    });
  };

  useEffect(() => {
    if (verifytWithdrawalIsSuccess) {
      // 多國語言
      const title = LangString(
        "alert_verification_success",
        "Verification successful"
      );
      const confirmText = LangString("confirm", "Confirm");
      SuccessMySwal({
        title: title,
        showCancelButton: false,
        confirmButtonText: confirmText,
      });
      setModalIsOpen(false);
      handleOpenModal("withdrawal");
    }

    if (verifytWithdrawallIsError) {
      ErrorMySwal({
        title:
          verifytWithdrawalError?.data?.ResultLanguages[getLanguage()] ||
          verifytWithdrawalError?.data?.Message,
      });
    }
  }, [verifytWithdrawalResponse, verifytWithdrawallIsError]);

  useEffect(() => {
    if (setAccountWithdrawalIsSuccess) {
      // 多國語言
      const title = LangString("alert_update_success", "Update successful");
      const confirmText = LangString("confirm", "Confirm");
      SuccessMySwal({
        title: title,
        showCancelButton: false,
        confirmButtonText: confirmText,
      });

      setModalIsOpen(false);
    }

    if (setAccountWithdrawalIsError) {
      ErrorMySwal({
        title:
          setAccountWithdrawalError?.data?.ResultLanguages[getLanguage()] ||
          setAccountWithdrawalError?.data?.Message,
      });
    }
  }, [setAccountWithdrawalResponse, setAccountWithdrawalIsError]);

  const [activeStep, setActiveStep] = useState(0);
  // Information step
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");
  const [riskType, setRiskType] = useState("");
  const [error, setError] = useState("");
  const [asset, setAsset] = useState("USDT");

  // SelectPackage step
  const [packageData, setPackageData] = useState([]);
  const [packageId, setPackageId] = useState("");

  const [riskTypeData, setRiskTypeData] = useState([]);

  // SettingPackage step
  const [returnType, setReturnType] = useState("");
  // Confirmation step
  const [address, setAddress] = useState("");
  const [addressCheck, setAddressCheck] = useState("");

  const [selectCheck, setSelectCheck] = useState("");
  const [returnCheck, setReturnCheck] = useState("");
  const [transferCheck, setTransferCheck] = useState("");
  const [feeCheck, setFeeCheck] = useState("");

  // 使用 avapoints
  const [useavapoints, setUseavapoints] = useState(false);

  const informationIsValid = packageId !== "" && riskType !== "";

  const selectPackageIsValid =
    name !== "" && amount !== "" && returnType !== "" && error === "";

  const settingPackageIsValid = addressCheck;

  // const confirmIsValid =
  //   selectCheck && returnCheck && transferCheck && feeCheck;

  const confirmIsValid = feeCheck;

  useEffect(() => {
    if (miningPackageResponseIsSuccess) {
      // 過濾 IsAvailable = true 的 package
      setPackageData(
        miningPackageResponse?.Packages.filter((data) => data.IsAvailable)
      );
    }

    if (miningPackageResponseIsError) {
      console.log(miningPackageResponseError?.data.Message);
    }
  }, [
    miningPackageResponse,
    miningPackageResponseIsError,
    miningPackageResponseIsLoading,
  ]);

  const stepDirection = [
    // "選擇Package",
    // "設定Package",
    // "確認Package",
    // "Package轉帳",
    <Lang
      datakey="addpackage_step_selectpackage"
      defaultText="Select A Package"
    />,
    <Lang
      datakey="addpackage_step_configurepackage"
      defaultText="Configure The Package"
    />,
    <Lang
      datakey="addpackage_step_confirmpackage"
      defaultText="Confirm The Package"
    />,
    <Lang
      datakey="addpackage_step_transferpackage"
      defaultText="Transfer For The Package"
    />,
  ];

  const handleCancel = () => {
    navigate(routes.mining_v2);
  };

  const handleBackStep = () => {
    if (activeStep === 0) {
      return;
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  const handleNextStep = () => {
    setActiveStep(activeStep + 1);
  };

  // useEffect(() => {
  //   console.log(typeof selectData);
  //   console.log(selectData.length);
  //   if (selectData.length > 0) {
  //     const selectDataList = Object.entries(
  //       selectData?.filter((list) => list?.GroupName === "PackageRiskType")?.[0]
  //         ?.Items
  //     ).map((item) => item[1]);

  //     const whiteList = selectDataList.map((type) => {
  //       if (
  //         miningPackageResponse?.Packages.filter(
  //           (data) => data.Type === type && data.IsAvailable
  //         ).length > 0
  //       ) {
  //         return type;
  //       } else {
  //         return;
  //       }
  //     });

  //     setRiskTypeData(
  //       Object.entries(
  //         selectData?.filter(
  //           (list) => list?.GroupName === "PackageRiskType"
  //         )?.[0]?.Items
  //       ).filter(function (event) {
  //         return whiteList.indexOf(event[1]) > -1;
  //       })
  //     );
  //   }
  // }, [selectData]);

  // 取得 riskType 之後過濾符合且 IsAvailable = true 的 riskType 的 packageData
  // useEffect(() => {
  //   setPackageData(
  //     allPackageData.filter(
  //       (data) => data.Type === riskType && data.IsAvailable
  //     )
  //   );
  // }, [riskType]);

  // 判斷最低金額
  useEffect(() => {
    if (asset === "USDT") {
      if (Number(amount) < 1000 && amount !== "") {
        setError(
          lang?.toLocaleLowerCase() === "en"
            ? "Minimum transaction amount is 1000, please adjust the amount."
            : "每筆交易的最低限額為1000，請調整金額。"
        );
      } else {
        setError("");
      }
    } else {
      if (Number(amount) < 0.0001 && amount !== "") {
        setError(
          lang?.toLocaleLowerCase() === "en"
            ? "Minimum transaction amount is 0.0001, please adjust the amount."
            : "每筆交易的最低限額為0.0001，請調整金額。"
        );
      } else {
        setError("");
      }
    }
  }, [amount, lang]);

  const handleAddMining = () => {
    _addMining({
      body: {
        Name: name,
        Amount: amount,
        PackageId: packageId,
        ReturnType: returnType,
        Address: address,
        Asset: asset,
        UseAvaPoints: useavapoints,
      },
    });
  };

  //  換頁置頂
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  return (
    <Wrapper>
      <Title>
        <Lang datakey="mining_create_package" defaultText="Create Package" />
      </Title>
      {miningPackageResponseIsLoading && <Loading />}

      {miningPackageResponse && packageData.length > 0 && (
        <Fragment>
          {activeStep === 0 && (
            <SelectPackage
              riskType={riskType}
              setRiskType={setRiskType}
              riskTypeData={riskTypeData}
              setRiskTypeData={setRiskTypeData}
              packageData={packageData}
              packageId={packageId}
              setPackageId={setPackageId}
              setActiveStep={setActiveStep}
              asset={asset}
              setAsset={setAsset}
            />
          )}
          {activeStep === 1 && (
            <Information
              name={name}
              setName={setName}
              amount={amount}
              setAmount={setAmount}
              error={error}
              returnType={returnType}
              setReturnType={setReturnType}
              setActiveStep={setActiveStep}
              asset={asset}
            />
          )}
          {activeStep === 2 && (
            <Confirmation
              setSelectCheck={setSelectCheck}
              setReturnCheck={setReturnCheck}
              setTransferCheck={setTransferCheck}
              setFeeCheck={setFeeCheck}
              setActiveStep={setActiveStep}
              feeCheck={feeCheck}
            />
          )}
          {activeStep === 3 && (
            <Transfer
              amount={amount}
              setAddress={setAddress}
              setAddressCheck={setAddressCheck}
              useavapoints={useavapoints}
              setUseavapoints={setUseavapoints}
              asset={asset}
              setAsset={setAsset}
            />
          )}

          <NoticeWrapper>
            <NoticeContainer>
              <NoticeIcon>
                <Icons.Warning />
              </NoticeIcon>
              <NoticeContent>
                <Lang
                  datakey="mining_create_package_notice"
                  defaultText=" Need to know Computer-Processed Personal Data Protection Law."
                />
              </NoticeContent>
            </NoticeContainer>
          </NoticeWrapper>
        </Fragment>
      )}

      <StepActionWrapper className="createMining_progress">
        {activeStep !== 0 && (
          <ButtonWrapper style={{ marginRight: "auto" }}>
            <Button type="stroke" onClick={() => handleBackStep()}>
              <ButtonText>
                <Lang datakey="laststep" defaultText="Back" />
              </ButtonText>
            </Button>
          </ButtonWrapper>
        )}
        <ProgressWrapper>
          <ProgressContainer>
            {Array.from({ length: 4 }, (v, i) => (
              <ProgressItem
                key={i}
                status={activeStep >= i}
                active={
                  activeStep > i || activeStep == stepDirection.length - 1
                }
              >
                <ProgressItemWrapper>
                  <ProgressItemNumber status={activeStep >= i}>
                    {i + 1}
                  </ProgressItemNumber>
                  <ProgressItemText status={activeStep >= i}>
                    {stepDirection[i]}
                  </ProgressItemText>
                </ProgressItemWrapper>
              </ProgressItem>
            ))}
          </ProgressContainer>
        </ProgressWrapper>

        {(() => {
          switch (activeStep) {
            case 0:
              return (
                <ButtonWrapper>
                  <Button type="storke" onClick={() => handleCancel()}>
                    <ButtonText>
                      <Lang datakey="cancel" defaultText="Cancel" />
                    </ButtonText>
                  </Button>
                  <Button
                    disabled={!informationIsValid}
                    type={informationIsValid ? "fill" : "disabled"}
                    onClick={() => handleNextStep()}
                  >
                    <ButtonText>
                      <Lang datakey="nextstep" defaultText="Next" />
                    </ButtonText>
                  </Button>
                </ButtonWrapper>
              );
            case 1:
              return (
                <ButtonWrapper>
                  <Button type="storke" onClick={() => handleCancel()}>
                    <ButtonText>
                      <Lang datakey="cancel" defaultText="Cancel" />
                    </ButtonText>
                  </Button>
                  <Button
                    disabled={!selectPackageIsValid}
                    type={selectPackageIsValid ? "fill" : "disabled"}
                    onClick={() => handleNextStep()}
                  >
                    <ButtonText>
                      <Lang datakey="nextstep" defaultText="Next" />
                    </ButtonText>
                  </Button>
                </ButtonWrapper>
              );
            case 2:
              return (
                <ButtonWrapper>
                  <Button type="storke" onClick={() => handleCancel()}>
                    <ButtonText>
                      <Lang datakey="cancel" defaultText="Cancel" />
                    </ButtonText>
                  </Button>
                  <Button
                    disabled={!confirmIsValid}
                    type={confirmIsValid ? "fill" : "disabled"}
                    onClick={() => handleNextStep()}
                  >
                    <ButtonText>
                      <Lang datakey="nextstep" defaultText="Next" />
                    </ButtonText>
                  </Button>
                </ButtonWrapper>
              );

            case 3:
              return (
                <ButtonWrapper>
                  <Button type="storke" onClick={() => handleCancel()}>
                    <ButtonText>
                      <Lang datakey="cancel" defaultText="Cancel" />
                    </ButtonText>
                  </Button>
                  <Button
                    onClick={() => handleAddMining()}
                    disabled={!settingPackageIsValid}
                    type={settingPackageIsValid ? "fill" : "disabled"}
                  >
                    <ButtonText>
                      <Lang datakey="done" defaultText="Done" />
                    </ButtonText>
                  </Button>
                </ButtonWrapper>
              );
          }
        })()}
      </StepActionWrapper>
      {modalIsOpne && (
        <SettingModal
          modalData={modalData}
          setClose={handleCloseModal}
          withdrawal={withdrawal}
          withdrawlVerifyCode={withdrawlVerifyCode}
          submitFun={() => handleUpdateWithdrawal(modalData.type)}
        />
      )}
    </Wrapper>
  );
};

export default AddPackage;
