import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { verifications } from '../../../utils/verifications';
import { routes } from '../../Router';
import { StyledAlert, StyledButton, StyledInput } from '../../Global/Ui';

// --- components ---
import Alert from '../../Global/Alert';
import ToggleTheme from '../../Global/ToggleTheme';
import Logo from '../../Global/Logo';
import Loading from '../../Global/Loading';

// --- plugins ---
// icon
import { IoAlertCircleOutline } from 'react-icons/io5';
// sweetalert
import Swal from 'sweetalert2/dist/sweetalert2.js';

// --- api ---
import { API_ForgetPassword } from '../../../utils/api';

const Forgot = () => {
  // --- handler ---
  const handleResetPassword = async (e) => {
    e.preventDefault();
    const res = await API_ForgetPassword({ email, verifyCode });
    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return Swal.fire(res.Message);

    handleSetNewPassword();
  };
  const handleSetNewPassword = async () => {
    const res = await API_ForgetPassword({ email, password });
    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return Swal.fire(res.Message);

    Swal.fire({
      icon: 'success',
      title: 'reset successd, back to login.',
    }).then(() => {
      navigate('/AVA/login');
    });
  };
  const handleSendMail = async (e) => {
    e.preventDefault();
    const res = await API_ForgetPassword({ email });
    if (res.ResultCode === 999) return console.error(res.Message);
    if (res.ResultCode !== 0) return Swal.fire(res.Message);

    Swal.fire('Please check your email and get verification code!');
  };
  const handleGoToLogin = (e) => {
    e.preventDefault();
    navigate(routes.login);
  };

  // --- router ---
  const navigate = useNavigate();

  // --- states ---
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [verifyCode, setVerifyCode] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [canReset, setCanReset] = useState(false);

  // --- effect ---
  useEffect(() => {
    setError('');
    if (!email) return setCanReset(false);
    if (!verifications.email.test(email)) {
      setError({ email: 'Please check email format' });
      return setCanReset(false);
    }
    if (!password) return setCanReset(false);
    if (!confirmPassword) return setCanReset(false);
    if (password !== confirmPassword) {
      setError({ error, password: 'different with password' });
      return setCanReset(false);
    }
    if (!verifyCode) return setCanReset(false);
    setCanReset(true);
  }, [email, password, confirmPassword, verifyCode]);

  // --- components ---
  if (loading) return <Loading />
  return (
    <div className='relative w-full min-h-screen pt-20'>
      <div className='w-full font-[Montserrat] py-5'>
        {/* --- logo --- */}
        <div className='h-[100px] min-w-[320px] max-w-[700px] w-[80%] mx-auto text-center'>
          <Logo />
        </div>

        {/* --- error message --- */}
        {/* <div className='min-w-[320px] max-w-[700px] w-[80%] mx-auto mt-5'>
          {error && <StyledAlert value={error} />}
        </div> */}
        
        {/* --- Register form --- */}
        <form className='min-w-[320px] max-w-[700px] w-[80%] mx-auto mt-5'>
          <div className='
            w-full py-5 bg-sub-white dark:bg-black tracking-[-0.015rem]
            border border-sub-button rounded
          '>
            {/* title */}
            <h2 className='w-[80%] mx-auto text-[1.5rem]'>
              Forgot
            </h2>

            <div className='w-[80%] mx-auto mt-5'>
              {error.verify && <Alert message={error.verify} />}
            </div>

            {/* email */}
            <div className='w-[80%] mx-auto mt-5'>
              <label className='block pl-3 font-regular'>
                Email Address & Verification
              </label>
              <StyledInput
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder='Email Address'
                className='w-full px-3 border border-sub-word bg-white dark:bg-black-main'
              />
              {error.email && <p className='text-alert'>{error.email}</p>}
            </div>

            {/* verify code */}
            <div className='w-[80%] mx-auto mt-5'>
              <label className='block pl-3 font-regular'>
                Email Verify Code
              </label>

              <div className='relative w-full'>
                <div
                  className='
                    absolute right-2 top-1/2 translate-y-[-50%]
                    flex items-center child:text-sub-word cursor-pointer
                  '
                  onClick={handleSendMail}
                >
                  <span>
                    GET Code
                  </span>
                  <IoAlertCircleOutline className='text-[1.3rem] ml-2' />
                </div>

                <StyledInput
                  id='verifyCode'
                  type='verifyCode'
                  value={verifyCode}
                  onChange={e => setVerifyCode(e.target.value)}
                  placeholder='Verify Code'
                  className='w-full px-3 border border-sub-word bg-white dark:bg-black-main'
                />
              </div>
            </div>

            {/* password */}
            <div className='w-[80%] mx-auto mt-5'>
              <label htmlFor='password' className='block pl-3 font-regular'>
                Password
              </label>
              <StyledInput
                id='password'
                type='password'
                value={password}
                onChange={e => setPassword(e.target.value)}
                placeholder='Create Password'
                className='w-full px-3 border border-sub-word bg-white dark:bg-black-main'
              />
            </div>

            {/* confirm password */}
            <div className='w-[80%] mx-auto mt-5'>
              <label htmlFor='password' className='block pl-3 font-regular'>
                Confirm Password
              </label>
              <StyledInput
                id='confirmPassword'
                type='password'
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
                placeholder='Confirm Password'
                className='w-full px-3 border border-sub-word bg-white dark:bg-black-main'
              />
              {error.password && <p className='text-alert'>{error.password}</p>}
            </div>

            {/* Next Step & Login buttons */}
            <div className='w-[80%] mx-auto pt-10 pb-5 sm:flex justify-between'>
              {/* Next Step */}
              <StyledButton
                className='w-full sm:w-[48%]'
                value='reset'
                onClick={handleResetPassword}
              />
              <StyledButton
                className='w-full sm:w-[48%] mt-2 sm:mt-0'
                value='login'
                outline={true}
                onClick={handleGoToLogin}
              />
            </div>

            {/* home */}
            <div className='w-[80%] mx-auto'>
              <div className='w-full text-center py-1'>
                <Link
                  to='/AVA/'
                  className='text-blue'
                >
                  Home
                </Link>
              </div>
            </div>
          </div>
        </form>

        {/* --- notice --- */}
        <div className='
          min-w-[320px] max-w-[622px] w-[80%] mx-auto font-[Roboto] text-[1rem]
          text-sub-word
        '>
          <div className='flex items-center mt-5 mb-3'>
            <IoAlertCircleOutline className='mr-4 text-[1.5rem]' />
            <p className='text-[1rem]'>Notice:</p>
          </div>

          <div className='w-full'>
            <ol className='pl-10'>
              <li>
                1. Need to know <span className='text-blue cursor-pointer'>Computer-Processed Personal Data Protection Law</span>.
              </li>
            </ol>
          </div>
        </div>
      </div>

      {/* toggle theme */}
      <div className='fixed left-10 bottom-8 cursor-pointer'>
        <ToggleTheme />
      </div>
    </div>
  )
}

export default Forgot
